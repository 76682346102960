import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { useEffect, useState } from 'react';
import { IActiveItem } from 'components/pageLayouts/gamblingSystems/statistics/gamblingStatistics';
import OutcombetTable from './freeSpins/outcombet/outcombetTable';
import SlotegratorTable from './freeSpins/slotegrator/slotegratorTable';

interface ClientFreeSpinsProps {
  activeItem?: IActiveItem;
  clientId: string;
}

export const ClientFreeSpins = ({ activeItem }: ClientFreeSpinsProps) => {
  const [value, setValue] = useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue('1');
  }, [activeItem]);

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="client-free-spins">
            <Tab label="outcombet" value="1" />
            <Tab label="slotegrator" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <OutcombetTable />
        </TabPanel>
        <TabPanel value="2">
          <SlotegratorTable />
        </TabPanel>
      </TabContext>
    </Box>
  );
};
