import { useApiErrors } from 'hooks/errors/useApiErrors';
import { useStorage } from 'hooks/authHooks/useStorage';
import { useState } from 'react';
import { generateHeaders, joinMessage } from 'utils/utils';
import GameProviderSettingsService from 'services/gameProviderSettings.service';
import { IProviderSettings } from 'types/providers';

export const useGameProvidersSettings = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [gameSetting, setGameSetting] = useState<IProviderSettings[]>([]);
  const [error, setError] = useState('');
  const { logout, userToken } = useStorage();
  const { errorHandler } = useApiErrors();
  const initHeaders = generateHeaders(userToken());

  const createGameProviderSettings: any = async (
    body: any,
    providerId: string,
    headers = initHeaders,
  ) => {
    setIsLoading(true);
    try {
      const {
        data: { data, status },
      } = await GameProviderSettingsService.createProviderSettings(headers, body, providerId);
      setIsLoading(false);
      setError('');
      return { status, data };
    } catch (err: any) {
      if (err.response) {
        const {
          response: {
            data: { error, message, status },
          },
        } = err;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          logout();
        } else if (!needLogout && newHeader) {
          return await createGameProviderSettings(body, providerId, newHeader);
        } else {
          console.log('LOG____>', message);
          setError(joinMessage(error));
        }
      }
    }
  };

  const createGameProviderSubSettings: any = async (
    body: any,
    providerId: string,
    settingId: string,
    headers = initHeaders,
  ) => {
    setIsLoading(true);
    try {
      const {
        data: { data, status },
      } = await GameProviderSettingsService.createProviderSubSettings(
        headers,
        body,
        providerId,
        settingId,
      );
      setIsLoading(false);
      setError('');
      return { status, data };
    } catch (err: any) {
      if (err.response) {
        const {
          response: {
            data: { error, message, status },
          },
        } = err;
        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          logout();
        } else if (!needLogout && newHeader) {
          return await createGameProviderSubSettings(body, providerId, settingId, newHeader);
        } else {
          console.log('LOG____>', message);
          setError(joinMessage(error));
        }
      }
    }
  };

  const getGameProviderSettings: any = async (
    providerId: string,
    siteId: string,
    headers = initHeaders,
  ) => {
    try {
      const {
        data: { data },
      } = await GameProviderSettingsService.getProviderSettings(headers, providerId, siteId);

      setGameSetting(data);
      setIsLoading(false);
    } catch (error: any) {
      if (error.response) {
        const {
          response: {
            data: { message, status },
          },
        } = error;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          logout();
        } else if (!needLogout && newHeader) {
          return await getGameProviderSettings(providerId, siteId, newHeader);
        } else {
          console.log('LOG____>', message);
          setError(joinMessage(error));
        }
      }
    }
  };

  const getAllSettings: any = async (siteId: string, query: string, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: {
          data: { gameProviderSettings },
          status,
        },
      } = await GameProviderSettingsService.getAllSettings(headers, siteId, query);
      if (status === 200) {
        return { status, data: gameProviderSettings };
      }
    } catch (err: any) {
      if (err.response) {
        const {
          response: {
            data: { error, message, status },
          },
        } = err;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          logout();
        } else if (!needLogout && newHeader) {
          return await getAllSettings(siteId, query, newHeader);
        } else {
          console.log('LOG____>', message);
          setError(joinMessage(error));
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getAllSettingsByGroup: any = async (siteId: string, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await GameProviderSettingsService.getAllSettingsByGroup(headers, siteId);
      if (status === 200) {
        return { status, data };
      }
    } catch (err: any) {
      if (err.response) {
        const {
          response: {
            data: { error, message, status },
          },
        } = err;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          logout();
        } else if (!needLogout && newHeader) {
          return await getAllSettingsByGroup(siteId, newHeader);
        } else {
          console.log('LOG____>', message);
          setError(joinMessage(error));
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const updateGameProviderSettings: any = async (
    providerId: string,
    settingId: string,
    body: any,
    headers = initHeaders,
  ) => {
    try {
      setIsLoading(true);
      const {
        data: { status },
      } = await GameProviderSettingsService.updateProviderSettings(
        headers,
        providerId,
        settingId,
        body,
      );
      setIsLoading(false);
      return { status };
    } catch (err: any) {
      if (err.response) {
        const {
          response: {
            data: { error, message, status },
          },
        } = err;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          logout();
        } else if (!needLogout && newHeader) {
          return await updateGameProviderSettings(providerId, settingId, body, newHeader);
        } else {
          console.log('LOG____>', message);
          setError(joinMessage(error));
        }
        setIsLoading(false);
      }
    }
  };

  return {
    gameSetting,
    getAllSettings,
    getAllSettingsByGroup,
    isLoading,
    updateGameProviderSettings,
    createGameProviderSettings,
    createGameProviderSubSettings,
    error,
    resetError: () => setError(''),
  };
};
