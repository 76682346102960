import { useState } from 'react';
import { generateHeaders } from 'utils/utils';
import { useStorage } from '../authHooks/useStorage';
import {
  prepareDataForSelectPages,
  prepareTableData,
  preparedColumnNames,
} from 'utils/helpers/dataHelper/prepareDataHelper';
import { useApiErrors } from 'hooks/errors/useApiErrors';
import { IGotoPage } from 'types/sites';
import ClientService from 'services/client.service';
import { userBalanceChangeHistorytColumns } from 'utils/helpers/columnsNameOrdering';

export const useTransactions = () => {
  const { errorHandler } = useApiErrors();
  const { logout, userToken } = useStorage();
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [transactionsHistory, setTransactionsHistory] = useState([]);
  const [columns, setColumns] = useState<string[] | []>([]);
  const [pageLists, setPageLists] = useState<IGotoPage[] | []>([]);
  const [error, setError] = useState(null);

  const initHeaders = generateHeaders(userToken());

  const getAllTransactions: any = async (
    clientId: string,
    query: string,
    headers = initHeaders,
  ) => {
    try {
      const {
        data: {
          data: { siteBalanceChanges, totalPages, totalItems },
        },
      } = await ClientService.getClientAllBalanceChanges(headers, clientId, query);
      const preparedData = prepareTableData(siteBalanceChanges, 'clients');
      const columns = preparedColumnNames(preparedData, userBalanceChangeHistorytColumns);
      const pageLists = prepareDataForSelectPages(totalPages);
      setTransactionsHistory(preparedData);
      setColumns(columns);
      setTotalPages(totalPages);
      setTotalItems(totalItems);
      setPageLists(pageLists as IGotoPage[]);
      setIsLoading(false);
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getAllTransactions(clientId, query, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    }
  };

  return {
    transactionsHistory,
    getAllTransactions,
    pageLists,
    columns,
    isLoading,
    totalPages,
    totalItems,
    error,
  };
};
