import { GridValueGetterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import FilterField from 'components/filter/columnFilter';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { IFilterData } from 'types/accounts';
import { columnsName, ROW_PER_PAGE } from 'constants/common';
import { dateFormat, isEmpty, makeQueryUrl } from 'utils/utils';
import { Box, Button, TablePagination } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setResetAll } from 'redux/actions/commonActions';
import { useFinance } from 'hooks/finances/useFinance';
import { useTranslation } from 'react-i18next';
import { IItem } from 'components/pageLayouts/finance/financialLogs/finacialLogsPage';
import LoaderWithBg from 'components/loader/loader';
import styles from './table.module.scss';

interface DynamicTableProps {
  item: IItem;
  outSideFilterData: IFilterData | object;
}

export default function DynamicTable({ item, outSideFilterData }: DynamicTableProps) {
  const { t: financeWithdrawalsT } = useTranslation('financeWithdrawals');
  const { t: commonT } = useTranslation('common');
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROW_PER_PAGE);
  const {
    isLoading,
    columns: columnNames,
    totalItems,
    depositList,
    getPaymentProvidersAllPayIns,
  } = useFinance();
  const [filterData, setFilterData] = useState<IFilterData | object>(outSideFilterData);
  const [sortData, setSortData] = useState<IFilterData | object>({});

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleFilter = (name: string, value: string) => {
    setFilterData((filterby) => ({ ...filterby, [name]: value }));
  };

  const handleSortBy = (sortBy: string, sortDir: string) => {
    setSortData({ [sortBy]: sortDir });
  };

  const handleResetAll = () => {
    dispatch(setResetAll(true));
    setFilterData({});
    setSortData({});
  };

  const generateDinamicColumns = useMemo(() => {
    const columns = columnNames.map((el) => {
      if (el === columnsName.CREATED_AT || el === columnsName.DATE) {
        return {
          field: el,
          width: Math.ceil(window.innerWidth / columnNames.length),
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isCamelCase
            />
          ),
          valueGetter: (params: GridValueGetterParams) =>
            dateFormat(params.row.createdAt || params.row.date),
        };
      } else if (el === columnsName.IMAGE) {
        return {
          field: el,
          width: 100,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isCamelCase
              isDisabled
            />
          ),
          renderCell: (params: GridValueGetterParams) => <img src={params.row.image} width="80" />,
        };
      }
      return {
        field: el,
        width: el === columnsName.ID ? 100 : Math.ceil(window.innerWidth / columnNames.length),
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => (
          <FilterField
            fieldName={el}
            onBlur={(value) => handleFilter(el, value)}
            onSort={handleSortBy}
            sortData={sortData}
            isCamelCase
          />
        ),
      };
    });

    return [...columns];
  }, [columnNames, sortData]);

  const columns = generateDinamicColumns as GridColDef[];

  useEffect(() => {
    if (item.id) {
      const query = makeQueryUrl({ ...filterData, ...outSideFilterData });
      const sortBy = Object.entries(sortData)[0];

      let paramsQuery = isEmpty(sortData)
        ? query
        : `${query}&sortBy=${sortBy[0]}&sortDir=${sortBy[1]}`;

      paramsQuery += `&page=${page + 1}&pageSize=${rowsPerPage}&providerId=${item.id}`;

      getPaymentProvidersAllPayIns(paramsQuery);
    }
  }, [filterData, sortData, page, item, rowsPerPage, outSideFilterData]);

  useEffect(() => {
    if (item.id) {
      if (!isEmpty(filterData)) handleResetAll();
    }
  }, [item]);

  return (
    <div style={{ width: '100%' }}>
      <Box className={styles.filter_box}>
        <div className={styles.filter_btn}>
          {Object.values(filterData).join() || Object.values(sortData).join() ? (
            <Button className={styles.reset_all_btn} variant="contained" onClick={handleResetAll}>
              {financeWithdrawalsT('resetAll')}
            </Button>
          ) : null}
        </div>
      </Box>
      {isLoading ? <LoaderWithBg isOpen={isLoading} /> : null}
      <>
        <TablePagination
          component="div"
          count={totalItems}
          page={page}
          labelRowsPerPage={commonT('rowsPerPage')}
          labelDisplayedRows={({ from, to, count }) =>
            `${from >= to ? to : from}-${to} ${commonT('of')} ${count}`
          }
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <DataGrid
          autoHeight
          rows={depositList}
          columns={columns}
          hideFooterPagination={true}
          initialState={{
            columns: {
              columnVisibilityModel: {
                // Hide column currency, the other columns will remain visible
                image: false,
              },
            },
          }}
          sx={{
            '.MuiDataGrid-iconButtonContainer': {
              visibility: 'visible',
            },
            '.MuiDataGrid-sortIcon': {
              opacity: 'inherit !important',
            },
            '.MuiDataGrid-cell': {
              '&:last-child': {
                justifyContent: 'center !important',
              },
            },
            '.MuiDataGrid-footerContainer': {
              display: 'none',
            },
          }}
        />
      </>
    </div>
  );
}
