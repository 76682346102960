import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Box, Button, Typography } from '@mui/material';
import common from 'styles/common.module.scss';
import { useTranslation } from 'react-i18next';
import { STORAGE_KEYS, localStorageService } from 'utils/storageService';

dayjs.extend(utc);

interface DatePickerValueProps {
  onShow: (valueFrom: Dayjs | null, valueTo: Dayjs | null) => void;
  bntText?: string;
  isSmall?: string;
  needDefaultDate?: boolean;
  customHeight?: number;
  dateTo?: Dayjs | null | undefined;
  dateFrom?: Dayjs | null | undefined;
  isValidChanges?: (val: boolean) => void;
}

const style = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  fontSize: '13px',
};

export default function DateTimePickerValue({
  onShow,
  bntText,
  isValidChanges,
  isSmall,
  customHeight,
  needDefaultDate,
  dateTo,
  dateFrom,
}: DatePickerValueProps) {
  const { t: clientDetailsT } = useTranslation('clientsDetails');
  const [valueFrom, setValueFrom] = useState<Dayjs | null>(dateFrom ?? dayjs().subtract(1, 'week'));
  const [valueTo, setValueTo] = useState<Dayjs | null>(dateTo ?? dayjs().add(1, 'day'));
  const [errorFrom, setErrorFrom] = useState('');
  const [errorTo, setErrorTo] = useState('');

  const hnadleOnFind = () => {
    onShow(valueFrom, valueTo);
  };

  const handleOnChange = (newValue: Dayjs | null, name: string) => {
    if (name === 'from') {
      setValueFrom(newValue);
      onShow(newValue, valueTo);
    } else {
      setValueTo(newValue);
      onShow(valueFrom, newValue);
    }
    setErrorFrom('');
    setErrorTo('');
  };

  const handleDateFrom = (newValue: Dayjs | null) => {
    if (!bntText && !errorFrom && !errorTo) {
      if (valueTo && newValue && newValue <= valueTo) {
        if (isValidChanges) {
          isValidChanges(true);
        }
      } else {
        setErrorFrom(clientDetailsT('invalidDateRange'));
        if (isValidChanges) {
          isValidChanges(false);
        }
      }
    }
  };

  const handleDateTo = (newValue: Dayjs | null) => {
    if (!bntText && !errorFrom && !errorTo) {
      if (valueFrom && newValue && valueFrom <= newValue) {
        if (isValidChanges) {
          isValidChanges(true);
        }
      } else {
        setErrorTo(clientDetailsT('invalidDateRange'));
        if (isValidChanges) {
          isValidChanges(false);
        }
      }
    }
  };

  useEffect(() => {
    if (needDefaultDate) {
      hnadleOnFind();
    }
  }, [needDefaultDate]);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={`${localStorageService.read(STORAGE_KEYS.LANGUAGE_KEY)}`}
    >
      <Box sx={style}>
        <DemoContainer components={['DatePicker', 'DatePicker']}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: `${customHeight ? customHeight : 90}px`,
            }}
          >
            <DateTimePicker
              format="DD/MM/YYYY HH:mm"
              ampm={false}
              slotProps={{
                layout: { sx: { textTransform: 'capitalize' } },
                textField: { size: isSmall ? 'small' : undefined },
              }}
              label={clientDetailsT('dateFrom')}
              value={valueFrom}
              onChange={(newValue) => handleOnChange(newValue, 'from')}
              onAccept={(newValue) => handleDateFrom(newValue)}
            />
            <Typography
              color={common.mainwarning}
              sx={{ mt: 0.3, fontSize: 12, opacity: 0.7, textIndent: '5px', textAlign: 'left' }}
            >
              {errorFrom}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: `${customHeight ? customHeight : 90}px`,
            }}
          >
            <DateTimePicker
              format="DD/MM/YYYY HH:mm"
              label={clientDetailsT('dateTo')}
              slotProps={{
                layout: { sx: { textTransform: 'capitalize' } },
                textField: { size: isSmall ? 'small' : undefined },
              }}
              value={valueTo}
              ampm={false}
              onChange={(newValue) => handleOnChange(newValue, 'to')}
              onAccept={(newValue) => handleDateTo(newValue)}
            />
            <Typography
              color={common.mainwarning}
              sx={{ mt: 0.3, fontSize: 12, opacity: 0.7, textIndent: '5px', textAlign: 'left' }}
            >
              {errorTo}
            </Typography>
          </Box>
        </DemoContainer>
        {bntText ? (
          <Button variant="contained" sx={{ mt: 3, mb: 2, ml: 3 }} onClick={hnadleOnFind}>
            {bntText}
          </Button>
        ) : null}
      </Box>
    </LocalizationProvider>
  );
}
