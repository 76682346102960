import { create } from 'lodash';

export const clientsListColumns = {
  id: 0,
  login: 1,
  balances: 3,
  phone: 4,
  email: 5,
  createdAt: 6,
};

export const usersBonusStatisticstColumns = {
  id: 0,
  user: 1,
};

export const usersCashbackStatisticstColumns = {
  id: 0,
  user: 1,
};

export const userBalanceChangeHistorytColumns = {
  id: 0,
  createdAt: 1,
  info: 2,
  balance: 3,
  oldBalance: 4,
  newBalance: 5,
};
export const userWithdrawColumns = {
  id: 0,
  date: 1,
  amount: 2,
  status: 3,
  comment: 4,
  log: 5,
};

export const userGameHistoryColumns = {
  providerName: 3,
  gameName:4,
  startedAt: 5,
  startAmount: 6,
  endedAt: 7,
  endAmount: 8,
};

export const financeLogColumns = {
  id: 0,
  user: 1,
  site: 2,
  amount: 4,
  createdAt: 5,
  requisites: 6,
  paymentDate: 7,
  status: 8,
  operationId: 9,
};

export const financeDepositColumns = {
  id: 0,
  createdAt: 1,
  balanceDiff: 2,
  user: 3,
  site: 4,
};

export const statisticsfinanceProviderColumns = {
  payinCount:1,
  payinFeeCount:2
};
