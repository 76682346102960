import { GridValueGetterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import FilterField from 'components/filter/columnFilter';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { IFilterData } from 'types/accounts';
import { columnsName, common, dialog, ROW_PER_PAGE } from 'constants/common';
import { dateFormat, isEmpty, makeQueryUrl } from 'utils/utils';
import { Box, Button, Modal, TablePagination, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setResetAll } from 'redux/actions/commonActions';
import { useMessage } from 'hooks/message/useMessage';
import Dialogs from 'components/dialog/dialogs';
import styles from 'components/tables/table.module.scss';
import { IEditMessage, IMessage } from 'types/clients';
import LoaderWithBg from 'components/loader/loader';
import MessageCreateEdit from 'components/modal/messageCreateEdit';
import ConversationHistory from './conversationHistory';
import { IconClose } from 'components/closeButton/closeButton';
import { useTranslation } from 'react-i18next';
import { style } from 'components/modal/modal';

interface ClientsMessagesProps {
  clientId: string;
}

export default function ClientsMessages({ clientId }: ClientsMessagesProps) {
  const theme = useTheme();
  const { t } = useTranslation('common');
  const { t: clientDetailsT } = useTranslation('clientsDetails');
  const title = `${t(common.CREATE)} ${clientDetailsT('conversation')}`;
  const [openModal, setOpenModal] = useState(false);
  const [editedItem, setEditedItem] = useState<IEditMessage>({} as IEditMessage);
  const [activeItem, setActiveItem] = useState<IMessage>({} as IMessage);
  const dispatch = useDispatch();
  const {
    isLoading,
    getUserAllMessages,
    totalPages,
    columns: columnNames,
    deleteUserMessage,
    totalItems,
  } = useMessage(clientId);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROW_PER_PAGE);
  const [filterData, setFilterData] = useState<IFilterData | object>({});
  const [sortData, setSortData] = useState<IFilterData | object>({});
  const [open, setOpen] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const { messages } = useSelector<any>((state) => state.clients) as any;

  const handleOpen = () => setOpenModal(true);

  const handleOnDelete = async (messageId: string) => {
    const result = await deleteUserMessage(clientId, messageId);
    if (result.status === 200) {
      setOpen(false);
    }
  };

  const handleClose = () => {
    setOpenModal(false);
    setEditedItem({} as IEditMessage);
  };

  const handleRouteToHistory = () => {
    setShowHistory(true);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleFilter = (name: string, value: string) => {
    setFilterData((filterby) => ({ ...filterby, [name]: value }));
  };

  const handleSortBy = (sortBy: string, sortDir: string) => {
    setSortData({ [sortBy]: sortDir });
  };

  const handleResetAll = () => {
    getUserAllMessages(clientId);
    dispatch(setResetAll(true));
    setFilterData({});
    setSortData({});
  };

  const actionFields = [
    {
      field: 'Operation',
      headerName: 'Operation',
      width: Math.ceil(window.innerWidth / (columnNames.length + 2)),
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridValueGetterParams) => {
        if (!params.row.isViewed) {
          return (
            <Button variant="contained" onClick={handleRouteToHistory} sx={{ mr: 2 }}>
              {clientDetailsT('history')}
            </Button>
          );
        }
      },
    },
  ];

  const generateDinamicColumns = useMemo(() => {
    const columns = columnNames.map((el) => {
      if (el === columnsName.CREATED_AT) {
        return {
          field: el,
          width: Math.ceil(window.innerWidth / (columnNames.length + 2)),
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={columnsName.CREATED.toLocaleUpperCase()}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
            />
          ),
          valueGetter: (params: GridValueGetterParams) => dateFormat(params.row.createdAt),
        };
      } else if (el === 'isViewed') {
        return {
          field: el,
          width: 150,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={'Viewed'.toLocaleUpperCase()}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isDisabled
            />
          ),
          valueGetter: (params: GridValueGetterParams) =>
            `${params.row.isViewed ? t(common.YES) : t(common.NO)}`,
        };
      }

      return {
        field: el,
        width:
          el === columnsName.ID ? 100 : Math.ceil(window.innerWidth / (columnNames.length + 2)),
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => (
          <FilterField
            fieldName={el.toLocaleUpperCase()}
            onBlur={(value) => handleFilter(el, value)}
            onSort={handleSortBy}
            sortData={sortData}
          />
        ),
      };
    });

    return [...columns, ...actionFields];
  }, [columnNames, sortData, messages]);

  const columns = generateDinamicColumns as GridColDef[];

  useEffect(() => {
    const query = makeQueryUrl(filterData);
    const sortBy = Object.entries(sortData)[0];

    let paramsQuery = isEmpty(sortData)
      ? query
      : `${query}&sortBy=${sortBy[0]}&sortDir=${sortBy[1]}`;

    paramsQuery += `&page=${page + 1}&pageSize=${rowsPerPage}`;
    getUserAllMessages(clientId, paramsQuery);
  }, [clientId, filterData, sortData, page, rowsPerPage]);

  return (
    <Box sx={{ width: '100%' }}>
      {isLoading ? (
        <LoaderWithBg isOpen={isLoading} />
      ) : (
        <>
          {!showHistory ? (
            <>
              <Box className={styles.filter_box}>
                <div className={styles.filter_btn}>
                  <Box sx={{ mt: 2, mb: 2 }}>
                    <Button variant="contained" onClick={handleOpen}>
                      {title}
                    </Button>
                  </Box>
                  {Object.values(filterData).join() || Object.values(sortData).join() ? (
                    <Button
                      className={styles.reset_all_btn}
                      variant="contained"
                      onClick={handleResetAll}
                    >
                      {t('ressetAll')}
                    </Button>
                  ) : null}
                </div>
              </Box>
              <TablePagination
                component="div"
                count={totalItems}
                page={page}
                labelRowsPerPage={t('rowsPerPage')}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from >= to ? to : from}-${to} ${t('of')} ${count}`
                }
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              <DataGrid
                autoHeight
                rows={messages}
                columns={columns}
                hideFooterPagination={true}
                sx={{
                  '.MuiDataGrid-iconButtonContainer': {
                    visibility: 'visible',
                  },
                  '.MuiDataGrid-sortIcon': {
                    opacity: 'inherit !important',
                  },
                  '.MuiDataGrid-cell': {
                    '&:last-child': {
                      justifyContent: 'center !important',
                    },
                  },
                  '.MuiDataGrid-footerContainer': {
                    display: 'none',
                  },
                }}
              />

              {openModal && (
                <Modal open={openModal} onClose={handleClose} sx={{ minWidth: 400 }}>
                  <Box
                    sx={{
                      ...style,
                      bgcolor: `${
                        theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff'
                      }`,
                    }}
                  >
                    <IconClose onClose={handleClose} />
                    <MessageCreateEdit
                      onClose={handleClose}
                      item={editedItem}
                      clientId={clientId}
                      mode={!isEmpty(editedItem) ? 'edit' : 'send'}
                    />
                  </Box>
                </Modal>
              )}
              <Dialogs
                id={activeItem.id}
                title={t(dialog.WANT_DELETE)}
                content={`Message of this user ${activeItem.login}`}
                isOpen={open}
                onClose={() => setOpen(false)}
                onConfirm={handleOnDelete}
              />
            </>
          ) : (
            <ConversationHistory />
          )}
        </>
      )}
    </Box>
  );
}
