import { actionTypes } from 'redux/constants/actionTypes';

export const setClientsData = (clients) => {
  return {
    type: actionTypes.SET_CLIENTS_LIST,
    payload: clients,
  };
};

export const setCurrentUserData = (user) => {
  return {
    type: actionTypes.SET_CURRENT_USER,
    payload: user,
  };
};


export const setMessagessData = (data) => {
  return {
    type: actionTypes.SET_MESSAGES_LIST,
    payload: data,
  };
};

export const setLabelsData = (data) => {
  return {
    type: actionTypes.SET_LABELS,
    payload: data,
  };
};


export const setSocialLinks = (data) => {
  return {
    type: actionTypes.SET_SOCIAL_LINKS_DATA,
    payload: data
  }
}

export const addSocialLink = (data) => {
  return {
    type: actionTypes.ADD_SOCIAL_LINK,
    payload: data
  }
}

export const deleteSocialLink = (data) => {
  return {
    type: actionTypes.REMOVE_SOCIAL_LINK,
    payload: data
  }
}