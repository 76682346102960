import { useState } from 'react';
import { generateHeaders, joinMessage } from 'utils/utils';
import { useStorage } from '../authHooks/useStorage';
import {
  prepareDataForSelectPages,
  prepareTableData,
  preparedColumnNames,
} from 'utils/helpers/dataHelper/prepareDataHelper';
import { useDispatch } from 'react-redux';
import { setClientsData, setCurrentUserData } from 'redux/actions/clientsActions';
import { useApiErrors } from 'hooks/errors/useApiErrors';
import ClientService from 'services/client.service';
import { IClient, IGameHistory, IHistory } from 'types/clients';
import { IGotoPage } from 'types/sites';
import { updateInProcessUsersCount } from 'redux/actions/sitesActions';
import { clientsListColumns, userGameHistoryColumns } from 'utils/helpers/columnsNameOrdering';

export const useClients = () => {
  const dispatch = useDispatch();

  const { errorHandler } = useApiErrors();
  const { logout, userToken } = useStorage();
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [clientHistory, setClientHistory] = useState<IHistory[]>([]);
  const [clientGameHistory, setClientGameHistory] = useState<IGameHistory[]>([]);
  const [columns, setColumns] = useState<string[] | []>([]);
  const [pageLists, setPageLists] = useState<IGotoPage[] | []>([]);
  const [error, setError] = useState('');
  const [client, setClient] = useState<IClient>({} as IClient);
  const [clientsWithStatusWaiting, setClientsWithStatusWaiting] = useState([]);
  const initHeaders = generateHeaders(userToken());

  const getClientsData: any = async (query: string, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: {
          data: { users, totalPages, totalItems },
        },
      } = await ClientService.getAllClients(headers, query);
      let preparedData = [];
      let pageLists: any = [];

      if (users.length) {
        preparedData = prepareTableData(users, 'clients');
        pageLists = prepareDataForSelectPages(totalPages);
        const columns = preparedColumnNames(preparedData, clientsListColumns);
        setColumns(columns);
      }
      dispatch(setClientsData(preparedData));
      setTotalPages(totalPages);
      setPageLists(pageLists as IGotoPage[]);
      setTotalItems(totalItems);
    } catch (err: any) {
      const {
        response: {
          data: { error, message, status },
        },
      } = err;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getClientsData(query, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(joinMessage(error));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getClientHistory: any = async (id: string, query = '', headers = initHeaders) => {
    try {
      const {
        data: {
          data: { userAuthorizationHistory, totalPages, totalItems },
        },
      } = await ClientService.getClientHistory(headers, id, query);
      let preparedData = [];
      let pageLists: any = [];

      if (userAuthorizationHistory.length) {
        preparedData = prepareTableData(userAuthorizationHistory, 'clientHistory');
        const columns = preparedColumnNames(preparedData);
        pageLists = prepareDataForSelectPages(totalPages);
        setColumns(columns);
      }
      setTotalPages(totalPages);
      setPageLists(pageLists);
      setTotalItems(totalItems);
      setIsLoading(false);
      setClientHistory(preparedData);
    } catch (err: any) {
      const {
        response: {
          data: { error, message, status },
        },
      } = err;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getClientHistory(id, query, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(joinMessage(error));
      }
    }
  };

  const requiredVerification: any = async (id: string, body: any, headers = initHeaders) => {
    setIsLoading(true);
    try {
      const {
        data: { data },
      } = await ClientService.requiredVerification(headers, id, body);
      setIsLoading(false);
      setClient(data);
      dispatch(setCurrentUserData(data));
    } catch (error: any) {
      const {
        response: {
          data: { status, message },
        },
      } = error;
      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await requiredVerification(id, body, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(joinMessage(error));
      }
    }
  };

  const getAllClientsByStatus: any = async (query?: string, headers = initHeaders) => {
    setIsLoading(true);
    try {
      const {
        data: { data, status },
      } = await ClientService.getAllClientsByVerifyStatus(headers, 'inProcess', query);
      if (status === 200) {
        setIsLoading(false);
        dispatch(updateInProcessUsersCount(data.totalItems));
        setClientsWithStatusWaiting(data.users);
        return { data, status };
      }
    } catch (error: any) {
      console.log(error);
      const {
        response: {
          data: { message, status },
        },
      } = error;
      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        console.log(query);
        return await getAllClientsByStatus(query, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getClientById: any = async (id: string, headers = initHeaders) => {
    setIsLoading(true);
    try {
      const {
        data: { data },
        status,
      } = await ClientService.getClientById(headers, id);
      if (data && data.label) {
        if (data.label.id === null) {
          data.label = { ...data.label, id: undefined };
        } else {
          data.label = { ...data.label, id: data.label.id + '' };
        }
      }
      setIsLoading(false);
      dispatch(setCurrentUserData(data));
      setClient(data);
      return { status, data };
    } catch (error: any) {
      const {
        response: {
          data: { message, status, error: err },
        },
      } = error;

      if (status === 404 || status === 400) {
        setClient({} as IClient);
        setIsLoading(false);
        setError(message);
      }
      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getClientById(id, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    }
  };

  const editClient: any = async (id: string, body: any, headers = initHeaders) => {
    setIsLoading(true);
    try {
      const {
        data: { data, status },
      } = await ClientService.editClient(headers, id, body);
      if (status === 200) {
        dispatch(setCurrentUserData(data));
        setClient(data);
        setIsLoading(false);
        return { data, status };
      }
    } catch (err: any) {
      const {
        response: {
          data: { error, message, status },
        },
      } = err;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await editClient(id, body, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(joinMessage(error));
      }
    }
  };

  const getClientGameHistory: any = async (id: string, query: string, headers = initHeaders) => {
    setIsLoading(true);
    try {
      const {
        data: {
          data: { gameSessions, totalItems, totalPages },
          status,
        },
      } = await ClientService.getClientGamesHistory(headers, id, query);
      if (status === 200) {
        const preparedData = prepareTableData(gameSessions, 'gameSessions');
        const columns = preparedColumnNames(preparedData, userGameHistoryColumns);
        const pageLists = prepareDataForSelectPages(totalPages);
        setColumns(columns);
        setClientGameHistory(gameSessions);
        setPageLists(pageLists as IGotoPage[]);
        setTotalItems(totalItems);
      }
    } catch (err: any) {
      const {
        response: {
          data: { error, message, status },
        },
      } = err;
      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getClientGameHistory(id, query, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(joinMessage(error));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const closeUserGameSession: any = async (
    userId: string,
    sessionId: string,
    headers = initHeaders,
  ) => {
    setIsLoading(true);
    try {
      const {
        data: { data, status },
      } = await ClientService.closeUserGameSession(headers, userId, sessionId);
      if (status === 200) {
        return { status, data };
      }
    } catch (err: any) {
      const {
        response: {
          data: { error, message, status },
        },
      } = err;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await closeUserGameSession(userId, sessionId, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(joinMessage(error));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const editConfirmedStatus: any = async (id: string, body: any, headers = initHeaders) => {
    setIsLoading(true);
    try {
      const {
        data: { data, status },
      } = await ClientService.updateConfirmedStatus(headers, id, body);
      if (status === 200) {
        setIsLoading(false);
        setClient(data);
        return { data, status };
      }
    } catch (err: any) {
      const {
        response: {
          data: { error, message, status },
        },
      } = err;
      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await editConfirmedStatus(id, body, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(joinMessage(error));
      }
    }
  };

  const verificationApproveOrRejected: any = async (
    vStatus: string,
    id: string,
    body: any,
    headers = initHeaders,
  ) => {
    setIsLoading(true);
    try {
      const {
        data: { data, status },
      } = await ClientService.approveOrRejectVerification(headers, id, vStatus, body);

      if (status === 200) {
        setClient(data);
        dispatch(setCurrentUserData(data));
      }

      return { data, status };
    } catch (err: any) {
      const {
        response: {
          data: { error, message, status },
        },
      } = err;
      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await verificationApproveOrRejected(vStatus, id, body, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(joinMessage(error));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const updateGameHistory = () => {
    const index = clientGameHistory?.findIndex((el: IGameHistory) => el?.isAlive);
    clientGameHistory[index].isAlive = false;
    setClientGameHistory([...clientGameHistory]);
  };

  return {
    getClientHistory,
    getAllClientsByStatus,
    clientsWithStatusWaiting,
    getClientById,
    clientHistory,
    clientGameHistory,
    getClientGameHistory,
    closeUserGameSession,
    pageLists,
    columns,
    isLoading,
    totalPages,
    client,
    error,
    getClientsData,
    editClient,
    editConfirmedStatus,
    totalItems,
    requiredVerification,
    verificationApproveOrRejected,
    updateClientGameHistory: () => updateGameHistory(),
  };
};
