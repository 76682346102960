import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface FiltersProps {
  onSelect: (name: string, value: string) => void;
  isEdit?: boolean;
  initValue?: string;
}

const channelsListMocked = [
  { name: 'aaaa', id: '1' },
  { name: 'bbb', id: '2' },
];
export default function DropDown({ onSelect, isEdit, initValue }: FiltersProps) {
  const [data, setData] = useState(isEdit ? initValue : '');
  const { t } = useTranslation('common');
  const { t: telegramT } = useTranslation('telegram');

  const handleChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setData(value);
    onSelect('channels', value);
  };

  return (
    <Box sx={{ minWidth: 400, mt: 1 }}>
      <FormControl fullWidth size="small">
        <InputLabel id="demo-simple-select-label">{telegramT('channels')}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={data}
          label={telegramT('channels')}
          onChange={handleChange}
        >
          {isEdit && <MenuItem value={initValue}>{initValue}</MenuItem>}
          <MenuItem value="">
            <em>{t('none')}</em>
          </MenuItem>
          {channelsListMocked.map((el) => {
            return (
              <MenuItem value={el.id} key={el.id}>
                {el.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}
