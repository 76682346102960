import { Modal, Box, Button, TextField, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import cx from 'classnames';
import { isAlphanumeric } from 'utils/validations/validationTypes';
import common from 'styles/common.module.scss';
import { CleintVerificationUI } from './verifyClient/verification';
import { style } from 'components/modal/modal';
import { IconClose } from 'components/closeButton/closeButton';
import { useTranslation } from 'react-i18next';
import { IVerifiedCleintImage } from 'components/pageLayouts/client/awaitIdentifications/identifications';
import styles from './clientIdentifications.module.scss';

interface CLientIdentificationProps {
  verifiedImg?: IVerifiedCleintImage;
  wrapperClassName?: string;
  wrapperClassContent?: string;
  handleConfirm: (decision: string, decisionInput: string) => void;
}

export const ClientIdentification = ({
  verifiedImg,
  wrapperClassName,
  wrapperClassContent,
  handleConfirm,
}: CLientIdentificationProps) => {
  const theme = useTheme();
  const { t: clientDetailsT } = useTranslation('clientsDetails');
  const { t } = useTranslation('common');
  const [decisionInput, setDecisionInput] = useState<string>('');
  const [isInputError, setIsInputError] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [adminDecision, setAdminDecision] = useState<string | null>(null);

  const handleClose = () => {
    setOpenModal(false);
    setIsInputError(false);
    setAdminDecision('');
  };

  const handleOnClick = (type: string) => {
    setAdminDecision(type);
    setOpenModal(true);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.code === 'Space') event.preventDefault();
  };

  const handleOnInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    type: string | null,
  ) => {
    const { value } = e.target;
    if (type === 'confirm') {
      if (isAlphanumeric(value)) {
        setDecisionInput(value.trim());
        setIsInputError(false);
      } else {
        e.preventDefault();
      }
    } else {
      setDecisionInput(value.trim());
      setIsInputError(false);
    }
  };

  const handleConfirmModal = (decision: string) => {
    if (decisionInput != '') {
      handleConfirm?.(decision, decisionInput);
      setOpenModal(false);
    } else {
      setIsInputError(true);
    }
  };

  const renderVerificationComponent = (decision: string | null) => {
    return (
      <>
        <IconClose onClose={handleClose} />
        <Typography variant="subtitle1" className={styles.title}>
          {decision === 'reject' ? clientDetailsT('rejectTitle') : clientDetailsT('confirmTitle')}
        </Typography>
        <TextField
          margin="normal"
          fullWidth
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => handleOnInputChange(e, decision)}
          onKeyDown={decision === 'confirm' ? handleKeyDown : undefined}
          className={cx({ [styles.error]: isInputError })}
          error={isInputError}
          helperText={!isInputError ? '' : t('required')}
        />
        <Box className={styles.actions} sx={{ mt: 2 }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: `${decision === 'reject' ? common.red : common.green}`,
            }}
            onClick={() => handleConfirmModal(decision ?? '')}
          >
            {decision === 'reject' ? clientDetailsT('reject') : clientDetailsT('confirm')}
          </Button>
        </Box>
      </>
    );
  };

  return (
    <>
      <CleintVerificationUI
        verifiedImg={{ ...verifiedImg }}
        onClick={handleOnClick}
        wrapperClassName={wrapperClassName}
        wrapperClassContent={wrapperClassContent}
      />
      {openModal && (
        <Modal open={openModal} onClose={handleClose} sx={{ minWidth: 400 }}>
          <Box
            sx={{
              ...style,
              bgcolor: `${
                theme.palette.mode === 'dark' ? theme.palette.background.paper : common.mainwhite
              }`,
            }}
          >
            {renderVerificationComponent(adminDecision)}
          </Box>
        </Modal>
      )}
    </>
  );
};
