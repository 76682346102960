import { actionTypes } from 'redux/constants/actionTypes';

export const setSitesData = (sites) => {
  return {
    type: actionTypes.SET_SITES,
    payload: sites,
  };
};

export const setCurrencies = (currenciesList) => {
  return {
    type: actionTypes.SET_CURRENCIES,
    payload: currenciesList,
  };
};

export const setCurrentSiteData = (site) => {
  return {
    type: actionTypes.SET_CURRENT_SITE,
    payload: site,
  };
};

export const setSiteCategories = (categories) => {
  return {
    type: actionTypes.SET_SITE_CATEGORIES,
    payload: categories,
  };
};

export const setSiteCategoryGames = (games) => {
  return {
    type: actionTypes.SET_CATEGORY_GAMES,
    payload: games,
  };
};

export const setProviderGames = (games) => {
  return {
    type: actionTypes.SET_PROVIDER_GAMES,
    payload: games,
  };
};

export const addProviderGames = (games) => {
  return {
    type: actionTypes.ADD_PROVIDER_GAMES,
    payload: games,
  };
};

export const addCategoryGames = (games) => {
  return {
    type: actionTypes.ADD_CATEGORY_GAMES,
    payload: games,
  };
};

export const updateCategoryGames = (id) => {
  return {
    type: actionTypes.UPDATE_CATEGORY_GAMES,
    payload: id,
  };
};

export const setSiteSettings = (settings) => {
  return {
    type: actionTypes.SET_SITE_SETTINGS,
    payload: settings,
  };
};

export const updateInProcessUsersCount = (count) => {
  return {
    type: actionTypes.UPDATE_INPROCESS_USERS_COUNT,
    payload: count,
  };
};

export const decreaseInProcessUsersCount = (count) => {
  return {
    type: actionTypes.DECREASE_INPROCESS_USERS_COUNT,
    payload: count,
  };
};

export const setPromotionPromoCode = (promo) => {
  return {
    type: actionTypes.SET_PROMOTION_PROMO,
    payload: promo,
  };
};

export const addCreatedPromotionPromoCode = (promo) => {
  return {
    type: actionTypes.ADD_CREATED_PROMOTION_PROMO,
    payload: promo,
  };
};

export const updatePromoCodes = (promo) => {
  return {
    type: actionTypes.UPDATE_PROMO_CODES,
    payload: promo,
  };
};

export const setSitePaymentProvidersSettings = (settings) => {
  return {
    type: actionTypes.SET_SITE_PAYMENT_PROVIDERS_SETTINGS,
    payload: settings,
  };
};

export const updateSitePaymentProvidersSettings = (settings) => {
  return {
    type: actionTypes.UPDATE_SITE_PAYMENT_PROVIDERS_SETTINGS,
    payload: settings,
  };
};
