import {
  Box,
  TextField,
  Typography,
  Button,
  Modal,
  useTheme,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  IconButton,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useEffect, useState } from 'react';
import { useClients } from 'hooks/clients/useClients';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs, { Dayjs } from 'dayjs';
import common from 'styles/common.module.scss';
import Result from 'components/alert/alert';
import EditUserStatus from 'components/modal/editUserStatus';
import moment from 'moment';
import { useLabels } from 'hooks/clients/useLabels';
import cx from 'classnames';
import { IconClose } from 'components/closeButton/closeButton';
import { useTranslation } from 'react-i18next';
import Dialogs from 'components/dialog/dialogs';
import { dialog } from 'constants/common';
import { CleintVerificationUI } from 'components/identification/verifyClient/verification';
import { STORAGE_KEYS, localStorageService } from 'utils/storageService';
import { isAlphanumeric } from 'utils/validations/validationTypes';
import ClientSocialLinks from './clientSocialLinks';
import { useClientSocial } from 'hooks/clients/useClientSocial';
import { useSelector } from 'react-redux';
import { style } from 'components/modal/modal';
import styles from './clientInfoPage.module.scss';

function ClientMainInfo() {
  const { t: clientDetailsT } = useTranslation('clientsDetails');
  const { t: clientsT } = useTranslation('clients');
  const { t } = useTranslation('common');
  const { getLabelsList } = useLabels();
  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);
  const [isInputError, setIsInputError] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const {
    error,
    editClient,
    editConfirmedStatus,
    requiredVerification,
    verificationApproveOrRejected,
    getAllClientsByStatus,
  } = useClients();
  const { getSocialLinksData, removeSocialLink, isLoading: isLoadingForSocial } = useClientSocial();
  const [userLabel, setUserLabel] = useState<undefined | string>();
  const [verifiedImg, setVerifiedImg] = useState({ frontView: '', rearView: '' });
  const [adminDecision, setAdminDecision] = useState<string | null>(null);
  const [decisionInput, setDecisionInput] = useState<string>('');
  const [userDateBrth, setUserDateBrth] = useState<Dayjs | null>(null);
  const [title, setTitle] = useState({ title: '', type: '' });
  const [isEmailConfirmed, setIsEmailConfirmed] = useState<any>(undefined);
  const [isPhoneConfirmed, setIsPhoneConfirmed] = useState<any>(undefined);
  const [btnText, setBtnText] = useState('');
  const [content, setContent] = useState<any>(undefined);
  const [forWhat, setForWhat] = useState('');
  const [verifyStatus, setVerifyStatus] = useState<any>('');
  const { socialLinks, labels }: any = useSelector<any>((state) => state.clients);
  const user = useSelector<any>((state) => state.currentUser) as any;
  const { role: mainRole } = useSelector((state: any) => state.siteRole) as { role: '' };

  const handleOnChangeDate = (newValue: dayjs.Dayjs | null) => {
    setUserDateBrth(newValue);
    setShowBtn(true);
  };

  const getSocialData = async (id: string) => {
    await getSocialLinksData(id);
  };

  const getLabelData = async () => {
    await getLabelsList();
  };

  useEffect(() => {
    setVerifiedImg((verifiedImg) => ({
      ...verifiedImg,
      frontView: user.frontView,
      rearView: user.rearView,
    }));
    setUserDateBrth(user.birthdayAt);
    setDecisionInput(user?.unverifiedReason ?? user?.passportNumber ?? '');
    setVerifyStatus(user?.verificationStatus);

    if (user.id && !socialLinks.length) getSocialData(user.id);
  }, [user]);

  useEffect(() => {
    if (!isLoadingForSocial) getLabelData();
  }, [isLoadingForSocial]);

  const saveBirthDay = async () => {
    const result = await editClient(user.id, { birthdayAt: userDateBrth });
    if (result.status === 200) {
      setShowBtn(false);
    }
  };

  const changeDialogStatus = (type: string) => {
    if (type === 'email') {
      !user.isEmailConfirmed
        ? setTitle({ title: 'confirmation', type: clientDetailsT('userEmail') })
        : setTitle({ title: 'unConfirmation', type: clientDetailsT('userEmail') });
      setIsEmailConfirmed(user.isEmailConfirmed);
      setIsPhoneConfirmed(undefined);
      setBtnText('yes');
      setContent(undefined);
      setForWhat('forConfirmation');
    } else if (type === 'phone') {
      !user.isPhoneConfirmed
        ? setTitle({ title: 'confirmation', type: clientDetailsT('phoneNum') })
        : setTitle({ title: 'unConfirmation', type: clientDetailsT('phoneNum') });
      setIsEmailConfirmed(undefined);
      setIsPhoneConfirmed(user.isPhoneConfirmed);
      setContent(undefined);
      setBtnText('yes');
      setForWhat('forConfirmation');
    } else {
      setForWhat('forVerification');
      setBtnText('yesSendRequest');
      setTitle({ title: dialog.CLINET_VARIFICATION, type: '' });
      setContent(dialog.CLINET_VARIFICATION_CONTENT);
      setIsEmailConfirmed(undefined);
      setIsPhoneConfirmed(undefined);
    }
    setOpenDialog(true);
  };

  const handleOnChangeUserStatus = () => {
    setOpenModal(true);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.code === 'Space') event.preventDefault();
  };

  const handleOnInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    type: string | null,
  ) => {
    const { value } = e.target;
    if (type === 'confirm') {
      if (isAlphanumeric(value)) {
        setDecisionInput(value.trim());
        setIsInputError(false);
      } else {
        e.preventDefault();
      }
    } else {
      setDecisionInput(value.trim());
      setIsInputError(false);
    }
  };

  const handleClose = () => {
    setOpenModal(false);
    setIsInputError(false);
    setAdminDecision('');
  };

  const sendRequestForVerification = async () => {
    const body = { verificationStatus: 'required' };
    await requiredVerification(user.id, body);
  };

  const handleOnClick = (type: string) => {
    setAdminDecision(type);
    setOpenModal(true);
  };

  const changeConfirmedStatus = async (isConfirmed?: boolean, type?: string) => {
    const body =
      type === 'email' ? { isEmailConfirmed: isConfirmed } : { isPhoneConfirmed: isConfirmed };

    await editConfirmedStatus(user.id, body);
  };

  const removeLink = (linkId: string) => {
    removeSocialLink(linkId);
  };

  const handleConfirmModal = async (decision: string) => {
    if (decisionInput != '') {
      const data =
        decision === 'approve'
          ? {
              passportNumber: decisionInput,
            }
          : {
              unverifiedReason: decisionInput,
            };

      const result = await verificationApproveOrRejected(decision, user.id, data);

      if (result.status === 200) {
        await getAllClientsByStatus();
      }
      handleClose();
    } else {
      setIsInputError(true);
    }
  };

  const handleChange = async (event: SelectChangeEvent, prevData: string) => {
    const { value } = event.target;
    const val = value === '' ? null : value;
    if (val || prevData) {
      const result = await editClient(user.id, { labelId: val });
      if (result.status === 200) {
        setUserLabel(value);
      }
    }
  };

  const renderVerificationComponent = (decision: string | null) => {
    return (
      <>
        <IconClose onClose={handleClose} />
        <Typography variant="subtitle1" className={styles.title}>
          {decision === 'reject' ? clientDetailsT('rejectTitle') : clientDetailsT('confirmTitle')}
        </Typography>
        <TextField
          margin="normal"
          fullWidth
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => handleOnInputChange(e, decision)}
          onKeyDown={decision === 'confirm' ? handleKeyDown : undefined}
          className={cx({ [styles.error]: isInputError })}
        />
        <Box className={styles.actions} sx={{ mt: 2 }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: `${decision === 'reject' ? common.red : common.green}`,
            }}
            onClick={() => handleConfirmModal(decision ?? '')}
          >
            {decision === 'reject' ? clientDetailsT('reject') : clientDetailsT('confirm')}
          </Button>
        </Box>
      </>
    );
  };

  return (
    <>
      <Box className={styles.main_info}>
        <Box className={styles.row}>
          <Typography variant="subtitle1" className={styles.title}>
            {clientDetailsT('login')}
          </Typography>
          <TextField
            margin="normal"
            disabled
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            value={user?.login}
          />
        </Box>
        {mainRole && mainRole === 'main' ? (
          <Box className={styles.row}>
            <Typography variant="subtitle1" className={styles.title}>
              {clientDetailsT('password')}
            </Typography>
            <TextField
              margin="normal"
              disabled
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              value={user?.password}
            />
          </Box>
        ) : null}
        <Box className={styles.row}>
          <Typography variant="subtitle1" className={styles.title}>
            {clientDetailsT('email')}
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <TextField
              margin="normal"
              size="small"
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              value={user?.email}
            />
            <IconButton aria-label="confirmed or not" onClick={() => changeDialogStatus('email')}>
              <ModeEditIcon />
            </IconButton>
            {user.isEmailConfirmed && <CheckCircleIcon sx={{ mt: 2.5, color: common.green }} />}
          </Box>
        </Box>
        <Box className={cx(styles.row, { [styles.balances]: true })}>
          <Box sx={{ flexBasis: '20%', maxWidth: 400, minWidth: 258 }}>
            <Typography variant="subtitle1" className={styles.title}>
              {clientDetailsT('balance')}
            </Typography>
          </Box>
          <Box className={styles.balance_item}>
            {user?.balances?.map(({ type, amount }: any, index: number) => {
              return (
                <Box key={index}>
                  <Typography
                    component="span"
                    className={styles.title}
                    sx={{
                      borderRight: 'none !important',
                    }}
                  >
                    <Typography className={styles.first} component="span">
                      {type}:
                    </Typography>

                    <Typography sx={{ ml: 1 }} component="span" color={'grey'}>
                      {amount}
                    </Typography>
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box className={styles.row}>
          <Typography variant="subtitle1" className={styles.title}>
            {clientDetailsT('phone')}
          </Typography>
          <TextField
            margin="normal"
            disabled
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            value={user?.phone}
          />
          <IconButton aria-label="verify or no" onClick={() => changeDialogStatus('phone')}>
            <ModeEditIcon />
          </IconButton>
          {user.isPhoneConfirmed && <CheckCircleIcon sx={{ color: common.green }} />}
        </Box>
        <Box className={styles.row}>
          <Typography variant="subtitle1" className={styles.title}>
            {clientsT('currency')}
          </Typography>
          <TextField
            margin="normal"
            disabled
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            value={user?.currency}
          />
        </Box>
        <Box className={styles.row}>
          <Typography variant="subtitle1" className={styles.title}>
            {clientDetailsT('profit')}
          </Typography>
          <TextField
            margin="normal"
            disabled
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            value={user?.profit}
          />
        </Box>
        <Box className={styles.row}>
          <Typography variant="subtitle1" className={styles.title}>
            {clientDetailsT('cashbackSum')}
          </Typography>
          <TextField
            margin="normal"
            disabled
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            value={user?.cashbackSum}
          />
        </Box>
        <Box className={styles.row}>
          <Typography variant="subtitle1" className={styles.title}>
            {clientDetailsT('depositCashback')}
          </Typography>
          <TextField
            margin="normal"
            disabled
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            value={user?.depositCashback}
          />
        </Box>
        <Box className={styles.row}>
          <Typography variant="subtitle1" className={styles.title}>
            {clientDetailsT('levelGroup')}
          </Typography>
          <TextField
            margin="normal"
            disabled
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            value={user?.levelGroup?.name}
          />
        </Box>
        <Box className={styles.row}>
          <Typography variant="subtitle1" className={styles.title}>
            {clientDetailsT('birthDay')}
          </Typography>

          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={`${localStorageService.read(STORAGE_KEYS.LANGUAGE_KEY)}`}
          >
            <DemoContainer components={['DatePicker']}>
              <DatePicker
                format="DD/MM/YYYY"
                className={styles.picker}
                value={dayjs(user.birthdayAt ? user.birthdayAt : userDateBrth)}
                defaultValue={dayjs(user.birthdayAt ? user.birthdayAt : userDateBrth)}
                views={['year', 'month', 'day']}
                onChange={(newValue) => handleOnChangeDate(newValue)}
                slotProps={{
                  layout: {
                    sx: {
                      textTransform: 'capitalize',
                      color: 'white',
                    },
                  },
                }}
              />
            </DemoContainer>
          </LocalizationProvider>

          {showBtn ? (
            <Button variant="contained" sx={{ ml: 3 }} onClick={saveBirthDay}>
              {t('save')}
            </Button>
          ) : null}
        </Box>

        <Box className={styles.row}>
          <Typography variant="subtitle1" className={styles.title}>
            {clientDetailsT('label')}
          </Typography>
          <FormControl size="small">
            <InputLabel id="label-id">{clientDetailsT('selectLabel')}</InputLabel>
            <Select
              sx={{ minWidth: 200, mr: 3 }}
              labelId="label-id"
              id="label"
              label={clientDetailsT('selectLabel')}
              value={userLabel ? userLabel : user?.label?.id}
              onChange={(e) => handleChange(e, userLabel ? userLabel : user?.label?.id)}
            >
              <MenuItem value="">
                <em>{clientsT('none')}</em>
              </MenuItem>
              {labels?.map((item: any, index: number) => {
                return (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>

        <Box className={styles.row}>
          <Typography variant="subtitle1" className={styles.title}>
            {clientDetailsT('created')}
          </Typography>
          <TextField
            margin="normal"
            disabled
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            value={moment(user?.createdAt).format('DD/MM/Y HH:mm:ss')}
          />
        </Box>
        <Box className={styles.row}>
          <Typography variant="subtitle1" className={styles.title}>
            {clientDetailsT('lastLogin')}
          </Typography>
          <TextField
            margin="normal"
            disabled
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            value={moment(user?.lastActiveAt).format('DD/MM/Y HH:mm:ss')}
          />
        </Box>

        {socialLinks &&
          socialLinks.map((elm: any, i: any) => {
            return (
              <Box className={styles.row} key={i}>
                <Typography variant="subtitle1" className={styles.title}>
                  {elm.type + ' ' + clientDetailsT('link')}
                </Typography>
                <ClientSocialLinks removeLink={removeLink} linkInfo={elm} clientId={user.id} />
              </Box>
            );
          })}

        <Box className={styles.row}>
          <Typography variant="subtitle1" className={styles.title}>
            {clientDetailsT('userStatus')}
          </Typography>

          <Box sx={{ display: 'flex', height: '100%', width: '50%' }}>
            <Typography variant="subtitle1" className={styles.title}>
              <Typography color={user?.isBlocked ? common.mainwarning : common.success}>
                {user?.isBlocked ? clientDetailsT('blocked') : clientDetailsT('active')}
              </Typography>
            </Typography>
            <Button variant="contained" onClick={handleOnChangeUserStatus}>
              {clientDetailsT('changeStatus')}
            </Button>
          </Box>
        </Box>
        <Box className={cx(styles.row, { [styles.verified]: verifyStatus === 'inProcess' })}>
          <Typography variant="subtitle1" className={styles.title}>
            {clientDetailsT('verification')}
          </Typography>
          {verifyStatus === 'required' || verifyStatus === 'inProcess' ? (
            <CleintVerificationUI
              status={verifyStatus}
              verifiedImg={{ ...verifiedImg }}
              onClick={handleOnClick}
            />
          ) : verifyStatus === 'verified' ? (
            <Box className={styles.verifiedInfoArea}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="subtitle1" className={styles.successed}>
                  {clientDetailsT('confirmedClient')} <DoneIcon sx={{ ml: 1, color: 'inherit' }} />
                </Typography>
                <Typography className={styles.verifiedInfoText}>
                  {clientDetailsT('passportData')}:({decisionInput})
                </Typography>
              </Box>
              <Button variant="contained" onClick={() => changeDialogStatus('verification')}>
                {clientDetailsT('reguestVerification')}
              </Button>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', m: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'baseline', ml: 2 }}>
                {verifyStatus === 'rejected' ? (
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography color="warning" variant="subtitle1" className={styles.rejected}>
                      {clientDetailsT('rejectedCleint')}
                      <CloseIcon sx={{ ml: 1, color: 'inherit' }} />
                    </Typography>
                    <Typography className={styles.rejectedInfo}>({decisionInput})</Typography>
                  </Box>
                ) : null}
                <Button
                  variant="contained"
                  color="success"
                  sx={{ height: '40px' }}
                  onClick={() => changeDialogStatus('verification')}
                >
                  {clientDetailsT('reguestVerification')}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
        {/* <!-- TODO: refactoring this Dialog component when the forVerification case will be ready  --> */}
        <Dialogs
          id={user.id}
          title={clientDetailsT(title.title, { type: title.type })}
          isEmailConfirmed={isEmailConfirmed}
          isPhoneConfirmed={isPhoneConfirmed}
          content={clientDetailsT(content)}
          btnText={clientDetailsT(btnText)}
          isOpen={openDialog}
          onClose={() => setOpenDialog(false)}
          onChange={forWhat === 'forConfirmation' ? changeConfirmedStatus : undefined}
          onConfirm={forWhat === 'forVerification' ? sendRequestForVerification : undefined}
        />

        {openModal && (
          <Modal open={openModal} onClose={handleClose} sx={{ minWidth: 400 }}>
            <Box
              sx={{
                ...style,
                bgcolor: `${
                  theme.palette.mode === 'dark' ? theme.palette.background.paper : common.mainwhite
                }`,
              }}
            >
              {adminDecision ? (
                renderVerificationComponent(adminDecision)
              ) : (
                <>
                  <IconClose onClose={handleClose} />
                  <EditUserStatus
                    title={clientDetailsT('changeUserStatus')}
                    onClose={handleClose}
                    userCurrency={user.currency}
                    user={{
                      isBlocked: user.isBlocked,
                      blockMessage: user.blockMessage,
                      blockedUntil: user.blockedUntil,
                      clientBlockMessage: user.clientBlockMessage,
                    }}
                    clientId={user.id}
                  />
                </>
              )}
            </Box>
          </Modal>
        )}
      </Box>
      {error && <Result style={{ mb: 2 }} message={error} type={'error'} isShow hasAction />}
    </>
  );
}

export default ClientMainInfo;
