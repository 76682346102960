import { GridValueGetterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import FilterField from 'components/filter/columnFilter';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { IFilterData } from 'types/accounts';
import { columnsName, common, dialog, ROW_PER_PAGE } from 'constants/common';
import moment from 'moment';
import { isEmpty, makeQueryUrl } from 'utils/utils';
import {
  Box,
  Button,
  Modal,
  TablePagination,
  useTheme,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setResetAll } from 'redux/actions/commonActions';
import Dialogs from 'components/dialog/dialogs';
import LoaderWithBg from 'components/loader/loader';
import CreatePromotionPromocode from 'components/modal/createPromotionPromocode';
import SitesList from 'components/filter/siteLists';
import { useAccounts } from 'hooks/accounts/useAccounts';
import { IconClose } from 'components/closeButton/closeButton';
import { usePromoCodes } from 'hooks/sites/usePromoCodes';
import { style } from 'components/modal/modal';
import { updatePromoCodes } from 'redux/actions/sitesActions';
import styles from 'components/tables/table.module.scss';

interface IActivePromo {
  id: string;
  login: string;
}

export default function PromotionTable() {
  const { t } = useTranslation('common');
  const { t: promoCodesT } = useTranslation('promoCodes');
  const theme = useTheme();
  const dispatch = useDispatch();
  const title = `${t(common.CREATE)} ${promoCodesT('promocode')}`;
  const {
    isLoading,
    columns: columnNames,
    totalItems,
    getAllPromotionPromoCodes,
    deletePromoCode,
  } = usePromoCodes();
  const { isLoading: isLoadingManagers, managerList } = useAccounts('manager', true);
  const [openModal, setOpenModal] = useState(false);
  const [activeItem, setActiveItem] = useState<IActivePromo>({ id: '', login: '' });
  const [expireStatus, setExpireStatus] = useState('');
  const [status, setStatus] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROW_PER_PAGE);
  const [filterData, setFilterData] = useState<IFilterData>({} as IFilterData);
  const [sortData, setSortData] = useState<IFilterData | object>({});
  const [open, setOpen] = useState(false);
  const [manager, setManager] = useState('');
  const promotionPromo = useSelector<any>((state) => state.promotionPromo) as any;

  const handleOnDelete = async (id: string) => {
    const result = await deletePromoCode(id);
    if (result?.status === 200) {
      dispatch(updatePromoCodes(result.data));
    }
  };

  const onButtonClick = (id: string) => {
    setActiveItem({ id, login: '' });
    setOpen(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleFilter = (name: string, value: string) => {
    setFilterData((filterby) => ({ ...filterby, [name]: value }));
  };

  const handleSortBy = (sortBy: string, sortDir: string) => {
    setSortData({ [sortBy]: sortDir });
  };

  const handleResetAll = () => {
    dispatch(setResetAll(true));
    setFilterData({});
    setSortData({});
    setManager('');
    setExpireStatus('');
    setStatus('');
  };

  const generateDinamicColumns = useMemo(() => {
    const columns = columnNames.map((el) => {
      if (el === columnsName.CREATED_AT || el === columnsName.END_DATE) {
        return {
          field: el,
          minWidth: el.length * 6 + 140,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isCamelCase
            />
          ),
          valueGetter: (params: GridValueGetterParams) =>
            `${(el === columnsName.CREATED_AT
              ? moment(params.row.createdAt)
              : moment(params.row.endDate)
            ).format('DD/MM/Y HH:mm:ss')}`,
        };
      } else if (el === columnsName.SITE) {
        return {
          field: el,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isDisabled
            />
          ),
          valueGetter: (params: GridValueGetterParams) => params.row.site.name,
        };
      } else if (el === columnsName.ACCOUNT) {
        return {
          field: el,
          minWidth: el.length * 6 + 140,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isDisabled
            />
          ),
          valueGetter: (params: GridValueGetterParams) => params.row.account.login,
          // `${params.row.isActive ? t(common.YES) : t(common.NO)}`,
        };
      } else if (el === columnsName.IS_USED) {
        return {
          field: el,
          disableColumnMenu: true,
          minWidth: el.length * 6 + 100,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isDisabled
            />
          ),
          valueGetter: (params: GridValueGetterParams) =>
            `${params.row.isUsed ? t(common.YES) : t(common.NO)}`,
        };
      } else if (el === columnsName.USER) {
        return {
          field: el,
          minWidth: el.length * 6 + 120,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isCamelCase
              isDisabled
            />
          ),
          renderCell: (params: GridValueGetterParams) => (
            <Link
              color={'text.primary'}
              component="a"
              target="blank"
              onClick={() => {
                window.open(`/clients/details/${params.row.user?.id}`);
              }}
            >
              {params.row.user?.name}
            </Link>
          ),
        };
      }
      return {
        field: el,
        minWidth: el.length * 6 + 80,
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => (
          <FilterField
            fieldName={el}
            onBlur={(value) => handleFilter(el, value)}
            onSort={handleSortBy}
            sortData={sortData}
            isCamelCase
          />
        ),
      };
    });

    const actionFields = columns.length
      ? [
          {
            field: t('action'),
            headerName: '',
            width: 150,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params: GridValueGetterParams) => (
              <Button
                sx={{ display: 'flex' }}
                fullWidth
                variant="contained"
                onClick={() => onButtonClick(params.row.id)}
              >
                {t('delete')}
              </Button>
            ),
          },
        ]
      : [];

    return [...columns, ...actionFields];
  }, [columnNames, sortData, promotionPromo]);

  const columns = generateDinamicColumns as GridColDef[];

  const handleStatusChange = (e: SelectChangeEvent, name: string) => {
    const { value } = e.target;
    if (name === 'expired') {
      setExpireStatus(value);
    } else {
      if (value) {
        setFilterData((filterby) => ({ ...filterby, isUsed: +value === 2 }));
      } else {
        if (filterData?.isUsed !== undefined) {
          delete filterData.isUsed;
          setFilterData({ ...filterData });
        }
      }
      setStatus(value);
    }
  };

  const handleManagerChange = (e: SelectChangeEvent) => {
    const { value } = e.target;
    setFilterData((filterby) => ({ ...filterby, accountId: value }));
    setManager(value);
  };

  useEffect(() => {
    const query = makeQueryUrl(filterData);
    const sortBy = Object.entries(sortData)[0];

    let paramsQuery = isEmpty(sortData)
      ? query
      : `${query}&sortBy=${sortBy[0]}&sortDir=${sortBy[1]}`;

    paramsQuery += `&page=${page + 1}&pageSize=${rowsPerPage}`;
    if (!isLoadingManagers) getAllPromotionPromoCodes(paramsQuery);
  }, [filterData, sortData, page, rowsPerPage, isLoadingManagers]);

  return (
    <div style={{ width: '100%' }}>
      <>
        <Box className={styles.filter_box}>
          <div className={styles.filter_btn}>
            {Object.values(filterData).join() || Object.values(sortData).join() ? (
              <Button sx={{ mr: 3 }} variant="contained" onClick={handleResetAll}>
                {t('ressetAll')}
              </Button>
            ) : null}
            <Button sx={{ m: 3 }} variant="contained" onClick={handleOpen}>
              {title}
            </Button>
          </div>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ mr: 6 }}>
            <SitesList onSelectSite={handleFilter} initValue={isEmpty(filterData)} />
          </Box>
          <Box sx={{ minWidth: 200, mr: 3 }}>
            <FormControl sx={{ width: 300, mr: 3 }} size="small">
              <InputLabel id="manager-label">{promoCodesT('selectManager')}</InputLabel>
              <Select
                labelId="manager-label"
                id="manager-select"
                label={promoCodesT('selectManager')}
                value={manager}
                onChange={handleManagerChange}
              >
                <MenuItem value="">
                  <em>{t('none')}</em>
                </MenuItem>
                {managerList.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.id}>
                      {item.login}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: 100, mr: 3 }}>
            <FormControl sx={{ width: 300, mr: 3 }} size="small">
              <InputLabel id="expire-type">{promoCodesT('expireStatus')}</InputLabel>
              <Select
                id="expire-type"
                label={promoCodesT('expireStatus')}
                value={expireStatus}
                onChange={(e) => handleStatusChange(e, 'expired')}
              >
                <MenuItem value="">
                  <em>{t('none')}</em>
                </MenuItem>
                {[
                  { id: 1, name: 'expired' },
                  { id: 2, name: 'active' },
                ].map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.id}>
                      {promoCodesT(item.name)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: 100, mr: 3 }}>
            <FormControl sx={{ width: 300, mr: 3 }} size="small">
              <InputLabel id="status-type">{promoCodesT('status')}</InputLabel>
              <Select
                id="status-type"
                label={promoCodesT('status')}
                value={status}
                onChange={(e) => handleStatusChange(e, 'status')}
              >
                <MenuItem value="">
                  <em>{t('none')}</em>
                </MenuItem>
                {[
                  { id: 1, name: 'new' },
                  { id: 2, name: 'activeted' },
                ].map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.id}>
                      {promoCodesT(item.name)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        {isLoading ? (
          <LoaderWithBg isOpen={isLoading} />
        ) : (
          <>
            <TablePagination
              component="div"
              count={totalItems}
              page={page}
              labelRowsPerPage={t('rowsPerPage')}
              labelDisplayedRows={({ from, to, count }) =>
                `${from >= to ? to : from}-${to} ${t('of')} ${count}`
              }
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <DataGrid
              autoHeight
              rows={promotionPromo}
              columns={columns}
              hideFooterPagination={true}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    currency: false,
                    useCount: false,
                    minDepositBorder: false,
                  },
                },
              }}
              sx={{
                '.MuiDataGrid-iconButtonContainer': {
                  visibility: 'visible',
                },
                '.MuiDataGrid-sortIcon': {
                  opacity: 'inherit !important',
                },
                '.MuiDataGrid-cell': {
                  '&:last-child': {
                    justifyContent: 'center !important',
                  },
                },
                '.MuiDataGrid-footerContainer': {
                  display: 'none',
                },
              }}
            />
          </>
        )}
        {openModal && (
          <Modal open={openModal} onClose={handleClose} sx={{ minWidth: 400 }}>
            <Box
              sx={{
                ...style,
                bgcolor: `${
                  theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff'
                }`,
              }}
            >
              <CreatePromotionPromocode
                onClose={handleClose}
                mode={'create'}
                managerList={managerList}
              />
              <IconClose onClose={handleClose} />
            </Box>
          </Modal>
        )}
        <Dialogs
          id={activeItem.id}
          title={t(dialog.WANT_DELETE)}
          content={''}
          isOpen={open}
          onClose={() => setOpen(false)}
          btnText={t('yes')}
          onConfirm={handleOnDelete}
        />
      </>
    </div>
  );
}
