import {
  Box,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { makeStyles } from '@mui/styles';
import { useGame } from 'hooks/game/useGame';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoaderWithBg from 'components/loader/loader';
import noImage from 'assets/image/no-image.png';
import UploadButtons from 'components/imageUpload/imageUpload';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useProviders } from 'hooks/providers/useProviders';

interface UploadImageProps {
  providerId: string;
}

const useStyles = makeStyles(() => ({
  paper: {
    display: 'flex',
    marginBottom: '16px',
    height: '40px',
    border: 'thin solid white',
    backgroundColor: 'unset!important',
  },
}));

function GamblingSystemProviderGames({ providerId }: UploadImageProps) {
  const { t } = useTranslation('common');
  const { t: siteT } = useTranslation('siteSettings');
  const { getAllSubProviders } = useProviders();
  const { getAllGameProvider, uploadGameImage, isLoading, pageCount } = useGame();
  const [hasMore, setHasMore] = useState(true);
  const [selectedProviderId, setSelectedProviderId] = useState('');
  const [subProviders, setSubProviders] = useState<any[]>();

  const classes = useStyles();
  const [name, setName] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');
  const [page, setPage] = useState(1);
  const [gamesImagesList, setGamesImagesList] = useState<any>([]);

  const setNameValue = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;
    setName(value);
  };

  const handleChangePage = () => {
    page < pageCount ? setPage((prevPage) => prevPage + 1) : setHasMore(false);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const onImageUpload = async (imageUrl: string | Blob, id?: string) => {
    const res = await uploadGameImage(id, providerId, imageUrl);
    if (res?.status === 200) {
      const temp = _.cloneDeep(gamesImagesList);
      const findElement = temp.find((el: any) => el.id === id);
      if (findElement) {
        findElement.image = res?.data?.image ?? '';
      }
      setGamesImagesList(temp);
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setSelectedProviderId(value);
    setPage(1);
  };

  const getData = async (providerId: string) => {
    const res = await getAllSubProviders(providerId);
    if (res?.status === 200) {
      setSubProviders(res.data);
    }
  };

  useEffect(() => {
    getData(providerId);
  }, [providerId]);

  useEffect(() => {
    const delay = 500;
    const timeoutId = setTimeout(() => {
      setDebouncedValue(name);
      setPage(1);
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [name]);

  useEffect(() => {
    const fetchData = async () => {
      const queryProvider = selectedProviderId ? `&subProviderId=${selectedProviderId}` : '';
      const queryGameName = debouncedValue ? `&name=${debouncedValue}` : '';
      const paramsQuery = `?page=${page}${queryProvider}${queryGameName}`;

      const response = await getAllGameProvider(providerId, `${paramsQuery}&pageSize=10`);
      const newData = response.data.games;

      if (newData.length) {
        if (!debouncedValue && page !== 1) {
          setGamesImagesList((prevArray: any) => [...prevArray, ...newData]);
          setHasMore([...gamesImagesList, ...newData].length < response.data.totalItems);
        } else {
          page !== 1
            ? setGamesImagesList([...gamesImagesList, ...newData])
            : setGamesImagesList([...newData]);

          setHasMore([...newData].length < response.data.totalItems);
        }
      } else {
        setGamesImagesList([]);
        setHasMore(false);
      }
    };

    if (!isLoading) fetchData();
  }, [providerId, page, debouncedValue, selectedProviderId]);

  return (
    <>
      {isLoading ? <LoaderWithBg isOpen={isLoading} /> : null}
      <Box sx={{ display: 'flex' }}>
        <FormControl size="small">
          <InputLabel id="provider-label">{siteT('providers')}</InputLabel>
          <Select
            sx={{ minWidth: 180, mr: 3 }}
            labelId="provider-label"
            id="provider-select"
            label={siteT('providers')}
            value={selectedProviderId}
            onChange={handleChange}
          >
            <MenuItem value="">
              <em>{t('none')}</em>
            </MenuItem>
            {subProviders?.map((item, index) => {
              return (
                <MenuItem key={index} value={item.id}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Paper onSubmit={handleSubmit} className={classes.paper} component="form">
          <InputBase
            size="small"
            fullWidth
            sx={{ m: 2 }}
            placeholder={t('searchGame')}
            value={name}
            onChange={(e) => setNameValue(e)}
            inputProps={{ 'aria-label': 'search game' }}
          />
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
      </Box>
      <Box
        id={'scrollableDiv'}
        sx={{
          maxHeight: '900px',
          overflowY: 'auto',
          minWidth: '600px',
          padding: '0 8px',
        }}
      >
        <InfiniteScroll
          style={{ padding: '0 8px' }}
          scrollableTarget="scrollableDiv"
          dataLength={gamesImagesList?.length}
          next={handleChangePage}
          hasMore={hasMore}
          loader={isLoading ? <Box sx={{ textAlign: 'center' }}>{t('loading')}</Box> : null}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr)',
                gap: '10px',
              }}
            >
              {gamesImagesList?.map((elm: any, i: number) => {
                return (
                  <Box key={i} sx={{ textAlign: 'center' }}>
                    <img
                      src={elm.image ?? noImage}
                      width="250px"
                      height="200px"
                      onError={({ currentTarget }) => (currentTarget.src = noImage)}
                    />
                    <Typography>{elm.name} </Typography>
                    <Typography> ({elm.subProvider})</Typography>
                    <Box>
                      <UploadButtons
                        onImageUpload={onImageUpload}
                        keys={elm.id}
                        needFormDataImage
                      />
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </InfiniteScroll>
      </Box>
    </>
  );
}

export default GamblingSystemProviderGames;
