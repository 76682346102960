// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Container, IconButton } from '@mui/material';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ImageIcon from '@mui/icons-material/Image';
import common from 'styles/common.module.scss';
import styles from './imageUpload.module.scss';


interface UploadButtonsProps {
  initialImage?: string | null;
  onImageUpload?: (url: string, id?: string, key?: string) => void;
  onError?: (hasError: boolean, name: string) => void;
  forChat?: boolean;
  width?: string;
  height?: string;
  hideError?: boolean;
  keys?: string;
  btnText?: string;
  needFormDataImage?: boolean;
  needFormDataImageWithPreview?: boolean;
  onExternalErrorMessage?: (name: string, message: string) => void;
  withIconBtn?: boolean;
}

export default function UploadButtons({
  needFormDataImage,
  needFormDataImageWithPreview,
  initialImage,
  withIconBtn,
  keys,
  onImageUpload,
  onError,
  onExternalErrorMessage,
  forChat,
  width,
  height,
  hideError,
  btnText,
}: UploadButtonsProps) {
  const { t } = useTranslation('common');
  const [imageUrl, setImageUrl] = useState<string | ArrayBuffer | null>(initialImage);
  const [errorMsg, setErrorMsg] = useState('');

  const validateSelectedFile = (selectedFile) => {
    const MAX_FILE_SIZE = 1024; // 5MB

    if (!selectedFile) {
      setErrorMsg(t('chooseFile'));
      onError?.(true, 'image');
      setIsSuccess(false);
      return;
    }

    const fileSizeKiloBytes = selectedFile.size / 1024;
    if (
      selectedFile.type !== 'image/png' &&
      selectedFile.type !== 'image/svg+xml' &&
      selectedFile.type !== 'image/jpeg'
    ) {
      setErrorMsg(t('fileDoesNotSupport'));
      if (hideError) {
        onExternalErrorMessage?.('image', 'fileDoesNotSupport');
      } else {
        onError?.(true, 'image');
      }
      return;
    }

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      setErrorMsg(t('fileSizeIsGreaterThanMax'));
      if (hideError) {
        onExternalErrorMessage?.('image', 'fileSizeIsGreaterThanMax');
      } else {
        onError?.(true, 'image');
      }
      return;
    }
    onError?.(false, 'image');
    setErrorMsg('');
    return true;
  };

  const handleFileUpload = (event: any) => {
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader) {
        if (validateSelectedFile(file)) {
          if (needFormDataImage) {
            const formData = new FormData();
            formData.append('image', file);
            if (needFormDataImageWithPreview) {
              setImageUrl(reader?.result);
              onImageUpload?.(formData, reader?.result, keys);
            } else {
              onImageUpload?.(formData, keys);
            }
          } else {
            onImageUpload?.(reader?.result, keys);
          }
        }
      }
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    setImageUrl(initialImage);
  }, [initialImage]);

  return (
    <Container className={styles.container}>
      <Stack direction="row" alignItems="center" justifyContent={'center'} spacing={2}>
        <label htmlFor={keys ? `upload-image-${keys}` : 'upload-image'}>
          {!forChat ? (
            withIconBtn ? (
              <IconButton aria-label="delete" size="large" component="span">
                <ImageIcon sx={{ mt: 1, color: common.mainBlue }} />
              </IconButton>
            ) : (
              <Button variant="contained" component="span">
                {btnText ? btnText : t('uploadImage')}
              </Button>
            )
          ) : !imageUrl ? (
            <IconButton aria-label="delete" size="large" component="span">
              <ImageIcon sx={{ mt: 1, color: common.mainBlue }} />
            </IconButton>
          ) : null}
          <input
            id={keys ? `upload-image-${keys}` : 'upload-image'}
            hidden
            accept="image/*"
            type="file"
            onChange={handleFileUpload}
          />
        </label>
        {imageUrl && (
          <img
            src={imageUrl}
            alt="Uploaded Image"
            width={width ? width : ''}
            height={height ? height : '50'}
          />
        )}
      </Stack>
      {!hideError ? (
        <Typography className={styles.error} component="span">
          {errorMsg}
        </Typography>
      ) : null}
    </Container>
  );
}
