import {
  TextField,
  Button,
  Modal,
  useTheme,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { FIELD_NAME } from 'types/sitesSettings';
import { useTranslation } from 'react-i18next';
import { hasEmptyProps } from 'utils/utils';
import { isValidUrlWithPrefix, minLength } from 'utils/validations/validationTypes';
import { common as CONSTATNT } from 'constants/common';
import common from 'styles/common.module.scss';
import {
  IProviderSettings,
  IProviderSettingsCurrency,
  IProviderSubSettings,
} from 'types/providers';
import { IconClose } from 'components/closeButton/closeButton';
import { style } from 'components/modal/modal';
import { ICurrency } from 'hooks/currency/useCurrency';
import { useSelector } from 'react-redux';
import { IEditSite } from 'types/sites';
import styles from './providerSettingItem.module.scss';

export const initialData = {
  baseURL: '',
  key: '',
  token: '',
  name: '',
} as IProviderSettings;

export const initialSubData = {
  name: '',
  currency: {} as IProviderSettingsCurrency,
};

interface ProviderSettingItemProps {
  item: IProviderSettings;
  btnText: string;
  onSave?: (data: IProviderSettings) => void;
  onCreate?: (data: any) => void;
  onSubCreate?: (data: IProviderSubSettings, id: number, settingId: number) => void;
  mode: string;
  needCurrency?: boolean;
  providerId?: number;
}

const fields = {
  [FIELD_NAME.NAME]: true,
  [FIELD_NAME.MERCHANT_ID]: true,
  [FIELD_NAME.MERCHANT_KEY]: true,
  [FIELD_NAME.URL]: true,
  [FIELD_NAME.CURRENCY]: true,
};

function ProviderSettingItem({
  item,
  btnText,
  onSave,
  onCreate,
  mode,
  onSubCreate,
  providerId,
}: ProviderSettingItemProps) {
  const { t: siteSettingsT } = useTranslation('siteSettings');
  const theme = useTheme();
  const { t: validationT } = useTranslation('validations');
  const { t: commonT } = useTranslation('common');
  const [openModal, setOpenModal] = useState(false);
  const [validFields, setValidFields] = useState({ ...fields });
  const [data, setData] = useState<IProviderSettings>(initialData);
  const [currencylist, serCurrencylist] = useState<ICurrency[]>([]);
  const [subData, setSubData] = useState<IProviderSubSettings>(initialSubData);

  const site = useSelector<any>((state) => state.currentSite) as IEditSite;

  const renderSubSetting = (elem: any, i: number, t: any) => {
    return (
      <Box key={i} sx={{ display: 'flex', justifyContent: 'space-around' }}>
        <TextField
          margin="normal"
          id="setting_Name"
          label={t('name')}
          required
          variant="standard"
          InputLabelProps={{
            shrink: true,
          }}
          value={elem.name}
        />
        <TextField
          margin="normal"
          id="setting_Name"
          label={t('currencyName')}
          required
          variant="standard"
          InputLabelProps={{
            shrink: true,
          }}
          value={currencylist.filter((el) => el.id === elem.currencyId)?.[0]?.code}
        />

        <Button variant="contained" sx={{ mt: 3, mb: 2 }} disabled={!validFields[FIELD_NAME.NAME]}>
          {commonT('edit')}
        </Button>
      </Box>
    );
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>, mode: string) => {
    event.preventDefault();
    if (providerId !== 3) {
      const submitData = !hasEmptyProps(data) ? { ...data } : null;

      if (submitData !== null) {
        if (mode === CONSTATNT.CREATE) {
          onCreate?.(submitData);
        } else {
          onSave?.(submitData);
        }
      }
    } else {
      if (mode === CONSTATNT.CREATE) {
        onCreate?.(data);
      }
    }
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: string,
  ) => {
    const { value } = e.target;
    const newData = { ...data, [name]: value };
    setData(newData);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;
    const item = currencylist.filter((el) => el.id === +value)?.[0];

    const newData = { ...subData, currency: { id: item.id + '', code: item.code ?? '' } };
    setSubData(newData);
  };

  const handleOnSubChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: string,
  ) => {
    const { value } = e.target;
    const newSubData = { ...subData, [name]: value };
    setSubData(newSubData);
  };

  const handleOnSubCreate = async () => {
    onSubCreate?.(subData, item.providerId, item.id);
    setOpenModal(false);
    setSubData({} as IProviderSubSettings);
  };

  const validate = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, name: string) => {
    const { value } = e.target;
    setValidFields((fields) => ({
      ...fields,
      [name]: name === FIELD_NAME.URL ? isValidUrlWithPrefix(value) : minLength(1)(value),
    }));
  };

  useEffect(() => {
    setData(item);
    if (item.providerId === 3) {
      if (site?.currencies && site.currencies.length) serCurrencylist([...site.currencies]);
    }
  }, [item]);
  return (
    <>
      <Box component="form" onSubmit={(e) => handleSubmit(e, mode)} sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          id="setting_Name"
          label={siteSettingsT('name')}
          fullWidth
          required
          variant="standard"
          InputLabelProps={{
            shrink: true,
          }}
          value={data.name}
          onChange={(e) => handleOnChange(e, FIELD_NAME.NAME)}
          onBlur={(e) => validate(e, FIELD_NAME.NAME)}
          error={!validFields[FIELD_NAME.NAME]}
          helperText={validFields[FIELD_NAME.NAME] ? '' : validationT('minLength', { length: 4 })}
        />
        {providerId !== 3 ? (
          <TextField
            margin="normal"
            id="merchant_id"
            label={siteSettingsT('merchantId')}
            fullWidth
            required
            variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
            value={data.token}
            onChange={(e) => handleOnChange(e, FIELD_NAME.MERCHANT_ID)}
            onBlur={(e) => validate(e, FIELD_NAME.MERCHANT_ID)}
            error={!validFields[FIELD_NAME.MERCHANT_ID]}
            helperText={
              validFields[FIELD_NAME.MERCHANT_ID] ? '' : validationT('minLength', { length: 2 })
            }
          />
        ) : null}
        {providerId !== 3 ? (
          <TextField
            margin="normal"
            id="merchant_key"
            label={siteSettingsT('merchantKey')}
            fullWidth
            required
            variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
            value={data.key}
            onChange={(e) => handleOnChange(e, FIELD_NAME.MERCHANT_KEY)}
            onBlur={(e) => validate(e, FIELD_NAME.MERCHANT_KEY)}
            error={!validFields[FIELD_NAME.MERCHANT_KEY]}
            helperText={
              validFields[FIELD_NAME.MERCHANT_KEY] ? '' : validationT('minLength', { length: 2 })
            }
          />
        ) : null}

        <TextField
          margin="normal"
          id="url"
          label="URL"
          fullWidth
          required
          variant="standard"
          InputLabelProps={{
            shrink: true,
          }}
          value={data.baseURL}
          onChange={(e) => handleOnChange(e, FIELD_NAME.URL)}
          onBlur={(e) => validate(e, FIELD_NAME.URL)}
          error={!validFields[FIELD_NAME.URL]}
          helperText={validFields[FIELD_NAME.URL] ? '' : validationT('invalidUrl')}
        />
        <Box>
          <Button
            fullWidth
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={
              !validFields[FIELD_NAME.NAME] ||
              !validFields[FIELD_NAME.URL] ||
              !validFields[FIELD_NAME.MERCHANT_ID] ||
              !validFields[FIELD_NAME.MERCHANT_KEY]
            }
          >
            {siteSettingsT(btnText)}
          </Button>
        </Box>
        {item?.providerId === 3 ? (
          <Box>
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, backgroundColor: common.green }}
              onClick={() => setOpenModal(true)}
            >
              {siteSettingsT('createSub')}
            </Button>
          </Box>
        ) : null}
      </Box>
      <Box className={styles.subSetting}>
        {item?.groups?.length
          ? item?.groups.map((el, i) => {
              return renderSubSetting(el, i, siteSettingsT);
            })
          : null}
      </Box>
      {openModal && (
        <Modal open={openModal} onClose={() => setOpenModal(false)} sx={{ minWidth: 400 }}>
          <Box
            sx={{
              ...style,
              bgcolor: `${
                theme.palette.mode === 'dark' ? theme.palette.background.paper : common.mainwhite
              }`,
            }}
          >
            <TextField
              margin="normal"
              id="setting_Name"
              label={siteSettingsT('name')}
              fullWidth
              required
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
              value={subData.name}
              onChange={(e) => handleOnSubChange(e, FIELD_NAME.SUB_NAME)}
              onBlur={(e) => validate(e, FIELD_NAME.SUB_NAME)}
              error={!validFields[FIELD_NAME.SUB_NAME]}
              helperText={
                validFields[FIELD_NAME.NAME] ? '' : validationT('minLength', { length: 4 })
              }
            />
            {!currencylist.length ? (
              <Typography>{commonT('loading')}</Typography>
            ) : (
              <FormControl sx={{ display: 'flex' }}>
                <RadioGroup
                  row
                  name="currency"
                  value={subData?.currency?.id}
                  onChange={handleCheckboxChange}
                >
                  {currencylist.map((el: any, index: number) => {
                    return (
                      <FormControlLabel
                        key={index}
                        value={el.id}
                        control={<Radio />}
                        label={el.code}
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
            )}
            <Box>
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={!validFields[FIELD_NAME.NAME]}
                onClick={handleOnSubCreate}
              >
                {siteSettingsT('createSub')}
              </Button>
            </Box>

            <IconClose onClose={() => setOpenModal(false)} />
          </Box>
        </Modal>
      )}
    </>
  );
}

export default ProviderSettingItem;
