import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import DateTimePickerValue from 'components/datePicker/dateTimeRangePicker';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { FIELD_NAME, IAddBonusesFilterData } from 'types/clients';
import AddBonusesTable from './addBonusesTable';
import { useTranslation } from 'react-i18next';
import { useSites } from 'hooks/sites/useSites';
import { isValidValueForAddBonusForClients } from 'utils/helpers/inputHelper';

interface FiltersForAddBonusesProps {
  chosenSiteId: string;
  initalValue?: string;
  reset?: () => void;
}

const fields = {
  [FIELD_NAME.MAX_PROFIT]: true,
  [FIELD_NAME.DATE_FROM]: true,
  [FIELD_NAME.DATE_TO]: true,
  [FIELD_NAME.MIN_SUM]: true,
  [FIELD_NAME.MAX_SUM]: true,
  [FIELD_NAME.CLIENT_LEVEL]: true,
};

const initialData = {
  date: null,
  minDepositSum: '',
  clientsLevel: '',
  currency: '',
};

function FiltersForAddBonuses({ chosenSiteId, reset }: FiltersForAddBonusesProps) {
  const { t: validationT } = useTranslation('validations');
  const { t: addBonusForClientsT } = useTranslation('addBonusForClients');
  const { getSiteById, siteCurrencies } = useSites();
  const [formData, setFormData] = useState<IAddBonusesFilterData>(initialData);
  const [clientsLevel, setClientsLevel] = useState('');
  const [isValid, setIsValid] = useState(true);

  const [validFields, setValidFields] = useState({ ...fields });
  const [isSend, setIsSend] = useState(false);
  const [clicked, setClicked] = useState<boolean>(false);

  const handleOnShow = (valueFrom: Dayjs | null, valueTo: Dayjs | null) => {
    const data = { ...formData, ['date']: { valueFrom, valueTo } };
    setValidFields((fields) => ({
      ...fields,
      [FIELD_NAME.DATE_FROM]: true,
      [FIELD_NAME.DATE_TO]: true,
    }));
    setFormData(data);
  };

  useEffect(() => {
    if (chosenSiteId) {
      getSiteById(chosenSiteId);
      const data = { ...formData, [FIELD_NAME.CURRENCY]: '' };
      setFormData(data);
    }
  }, [chosenSiteId]);

  const isValidChanges = (val: boolean) => {
    setIsValid(val);
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: string,
  ) => {
    const { value } = e.target;

    setValidFields((fields) => ({
      ...fields,
      [name]: true,
    }));
    const data = { ...formData, [name]: value };
    setFormData(data);
  };

  const handleChange = (e: SelectChangeEvent, name: string) => {
    const { value } = e.target;
    const data = { ...formData, [name]: value };
    setValidFields((fields) => ({
      ...fields,
      [name]: true,
    }));
    setClientsLevel(value);
    setFormData(data);
  };
  const ressetAllFilter = () => {
    const valueFrom = dayjs().subtract(1, 'day') as Dayjs;
    const valueTo = dayjs() as Dayjs;
    const data = { ...initialData, ['date']: { valueFrom, valueTo } };
    setFormData(data);
    if (reset) {
      reset();
    }
  };

  const validate = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, name: string) => {
    const { value } = e.target;
    setValidFields((fields) => ({
      ...fields,
      [name]: isValidValueForAddBonusForClients(
        value,
        name,
        formData?.maxDepositSum,
        formData?.minDepositSum,
      ),
    }));
  };

  const isValidData = (data: IAddBonusesFilterData) => {
    if (data.clientsLevel === '') {
      setValidFields((fields) => ({
        ...fields,
        [FIELD_NAME.CLIENT_LEVEL]: false,
      }));
    }
    if (data.date.valueFrom >= data.date.valueTo) {
      setValidFields((fields) => ({
        ...fields,
        [FIELD_NAME.DATE_FROM]: false,
        [FIELD_NAME.DATE_TO]: false,
      }));
    }

    return (
      data.minDepositSum !== '' &&
      data.clientsLevel !== '' &&
      data.date.valueFrom < data.date.valueTo &&
      data.currency !== ''
    );
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isValidData(formData)) {
      setClicked((clicked) => !clicked);
      setIsSend(true);
    } else {
      setIsSend(false);
    }
  };

  return (
    <Box>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ display: 'flex', flexWrap: 'wrap', gap: 4, mt: 2 }}
      >
        <DateTimePickerValue
          isSmall="small"
          onShow={handleOnShow}
          isValidChanges={isValidChanges}
          customHeight={40}
        />
        <TextField
          label={addBonusForClientsT('minDepositSum')}
          variant="outlined"
          required
          type="number"
          inputMode="numeric"
          value={formData.minDepositSum}
          size="small"
          onChange={(e) => handleOnChange(e, FIELD_NAME.MIN_SUM)}
          onBlur={(e) => validate(e, FIELD_NAME.MIN_SUM)}
          error={!validFields[FIELD_NAME.MIN_SUM]}
          helperText={validFields[FIELD_NAME.MIN_SUM] ? '' : validationT('minSumValidation')}
          sx={{
            mt: 1,
            minWidth:
              addBonusForClientsT('minDepositSum').length * 11 < 231
                ? 231
                : addBonusForClientsT('maxDepositSum').length * 11,
          }}
        />

        <TextField
          label={addBonusForClientsT('maxDepositSum')}
          variant="outlined"
          type="number"
          size="small"
          inputMode="numeric"
          value={formData.maxDepositSum ?? ''}
          error={!validFields[FIELD_NAME.MAX_SUM]}
          onBlur={(e) => validate(e, FIELD_NAME.MAX_SUM)}
          helperText={validFields[FIELD_NAME.MAX_SUM] ? '' : validationT('maxSumValidation')}
          onChange={(e) => handleOnChange(e, FIELD_NAME.MAX_SUM)}
          sx={{
            mt: 1,
            minWidth:
              addBonusForClientsT('maxDepositSum').length * 11 < 231
                ? 231
                : addBonusForClientsT('maxDepositSum').length * 11,
          }}
        />
        <FormControl sx={{ mt: 1, minWidth: 231 }} size="small">
          <InputLabel id="selectClientsLevel">
            {addBonusForClientsT('selectClientsLevel')}
          </InputLabel>
          <Select
            labelId="selectClientsLevel"
            onChange={(e) => handleChange(e as SelectChangeEvent, FIELD_NAME.CLIENT_LEVEL)}
            label={addBonusForClientsT('selectClientsLevel')}
            value={formData.clientsLevel}
            required
          >
            <MenuItem value={'all'}>All</MenuItem>
            <MenuItem value={'1'}>1</MenuItem>
            <MenuItem value={'2'}>2</MenuItem>
            <MenuItem value={'3'}>3</MenuItem>
          </Select>
        </FormControl>
        <FormControl
          sx={{ mt: 1, minWidth: 231 }}
          size="small"
          disabled={chosenSiteId != '' ? false : true}
        >
          <InputLabel id="selectCurrency">{addBonusForClientsT('selectCurrency')}</InputLabel>
          <Select
            labelId="selectCurrency"
            onChange={(e) => handleChange(e as SelectChangeEvent, FIELD_NAME.CURRENCY)}
            label={addBonusForClientsT('selectCurrency')}
            value={chosenSiteId ? formData?.currency : ''}
            required
          >
            {chosenSiteId &&
              siteCurrencies.map((elm, i) => {
                return (
                  <MenuItem value={elm.code} key={i}>
                    {elm.code}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <TextField
          label={addBonusForClientsT('maxProfit')}
          type="number"
          size="small"
          inputMode="numeric"
          variant="outlined"
          value={formData.maxProfitInPeriod ?? ''}
          onChange={(e) => handleOnChange(e, FIELD_NAME.MAX_PROFIT)}
          onBlur={(e) => validate(e, FIELD_NAME.MAX_PROFIT)}
          error={!validFields[FIELD_NAME.MAX_PROFIT]}
          helperText={validFields[FIELD_NAME.MAX_PROFIT] ? '' : validationT('minSumValidation')}
          sx={{ mt: 1, minWidth: addBonusForClientsT('maxProfit').length * 11 }}
        />
        <Button
          type="submit"
          variant="contained"
          size="small"
          sx={{ width: 150, height: 40, mt: 1 }}
          disabled={
            !validFields[FIELD_NAME.DATE_FROM] ||
            !validFields[FIELD_NAME.DATE_TO] ||
            !validFields[FIELD_NAME.MAX_SUM] ||
            !validFields[FIELD_NAME.MIN_SUM] ||
            !validFields[FIELD_NAME.CLIENT_LEVEL] ||
            !validFields[FIELD_NAME.MAX_PROFIT] ||
            !isValid ||
            !formData.minDepositSum ||
            chosenSiteId === ''
          }
        >
          {addBonusForClientsT('searchButton')}
        </Button>
      </Box>
      {isSend && <AddBonusesTable isSend={clicked} ressetAll={ressetAllFilter} />}
    </Box>
  );
}

export default FiltersForAddBonuses;
