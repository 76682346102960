import { useStorage } from 'hooks/authHooks/useStorage';
import { useApiErrors } from 'hooks/errors/useApiErrors';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrencies } from 'redux/actions/sitesActions';
import CurrencyService from 'services/currency.service';
import { generateHeaders } from 'utils/utils';

export interface ICurrency {
  id: number;
  name: string;
  code: string;
  createdAt?: string;
  deletedAt?: string | null;
  updatedAt?: string | null;
}
export const useCurrency = () => {
  const dispatch = useDispatch();
  const { errorHandler } = useApiErrors();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currencylist, setCurrencyList] = useState<ICurrency[] | []>([]);
  const [error, setError] = useState(null);

  const { logout, userToken } = useStorage();
  const initHeaders = generateHeaders(userToken());

  const getCurrency: any = async (headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: {
          data: { currencies },
        },
      } = await CurrencyService.getAll(headers);
      setCurrencyList(currencies);
      dispatch(setCurrencies(currencies));
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;
      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getCurrency(newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, error, currencylist, getCurrency };
};
