import { LineChart } from '@mui/x-charts/LineChart';

const years = [
  '01-10-2023',
  '02-10-2023',
  '03-10-2023',
  '04-10-2023',
  '05-10-2023',
  '06-10-2023',
  '07-10-2023',
];

const FranceGDPperCapita = [20, 50, 10, 0, 0, 5, 3];

const UKGDPperCapita = [5, 10, 0, 0, 20, 2, 12];

const GermanyGDPperCapita = [1, 56, 2, 12, 20, 80, 13];

const CasinoMagic = [56, 2, 0, 71, 33, 8, 9];

const JackpotQuest = [10, 0, 0, 22, 85, 23, 12];

export default function Chart() {
  return (
    <LineChart
      tooltip={{
        trigger: 'axis',
      }}
      sx={{
        '& .MuiLineElement-root': {
          strokeDasharray: '10 5',
          strokeWidth: 2,
        },
        '& .MuiChartsTooltip-root': {
          fill: 'none',
          color: '#fffff',
          //   backgroundColor: 'red',
          display: 'none!important',
        },
      }}
      xAxis={[
        {
          id: 'Years',
          data: years,
          scaleType: 'point',
          label: 'Days Range: October 01 - October 07',
          //   valueFormatter: (date) => date.getDate(),
        },
      ]}
      yAxis={[
        {
          label: 'Registered user amount',
          //   valueFormatter: (date) => date.getDate(),
        },
      ]}
      series={[
        {
          id: 'JackpotQuest',
          label: 'JackpotQuest',
          data: JackpotQuest,
          //   stack: 'total',
          // area: true,
          showMark: false,
        },
        {
          id: 'France',
          label: 'SpinWager',
          data: FranceGDPperCapita,
          //   stack: 'total',
          // area: true,
          showMark: false,
        },
        {
          id: 'Germany',
          label: 'LuckyClover',
          data: GermanyGDPperCapita,
          //   stack: 'total',
          // area: true,
          showMark: false,
        },
        {
          id: 'United Kingdom',
          label: 'EmeraldLounge',
          data: UKGDPperCapita,
          //   stack: 'total',
          // area: true,
          showMark: false,
        },
        {
          id: 'CasinoMagic',
          label: 'CasinoMagic',
          data: CasinoMagic,
          //   stack: 'total',
          // area: true,
          showMark: false,
        },
      ]}
      width={1200}
      height={400}
      //   margin={{ left: 70 }}
    />
  );
}
