import { actionTypes } from 'redux/constants/actionTypes';

export const setCurrentId = (payload) => {
  return {
    type: actionTypes.SET_CURRENT_ID,
    payload,
  };
};

export const setResetAll = (payload) => {
  return {
    type: actionTypes.SET_RESET_ALL,
    payload,
  };
};
