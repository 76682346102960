import { combineReducers } from 'redux';
import { accountsReducer } from './accountsReducer';
import { clientsReducer } from './clientsReducer';
import {
  sitesReducer,
  currentSiteReducer,
  categoriesReducer,
  providerGamesReducer,
  siteSettingsReducer,
  currencyReducer
} from './sitesReducer';
import { currentIdReducer, resetAllReducer } from './commonReducer';
import { levelsReducer } from './levelsReducer';
import { transactionsReducer, transactionsActionsReducer, transactionsWithdrawalsReducer  } from './transactionsReducer';
import { wheelsReducer } from './wheelsReducer';
import { promotionPromoCodeReducer } from './promoCodesReducer';
import { bonusesReducer } from './bonsuesReducer';
import { userReducer } from './userReducer';
import {roleReducer}  from './loginReducer';


const reducers = combineReducers({
  allAccounts: accountsReducer,
  allSites: sitesReducer,
  currencies : currencyReducer,
  currentSite: currentSiteReducer,
  currentId: currentIdReducer,
  resetAll: resetAllReducer,
  siteCategories: categoriesReducer,
  siteSettings: siteSettingsReducer,
  providerGames: providerGamesReducer,
  clients: clientsReducer,
  levels: levelsReducer,
  transactions: transactionsReducer,
  financeWithdrawals: transactionsWithdrawalsReducer,
  transactionsActions: transactionsActionsReducer,
  wheels: wheelsReducer,
  bonuses: bonusesReducer,
  promotionPromo: promotionPromoCodeReducer,
  currentUser:userReducer,
  siteRole: roleReducer
});

export default reducers;
