import { Box } from '@mui/material';
import { useSites } from 'hooks/sites/useSites';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SelectDropDown from './selectDropDown';
import { ISites } from 'types/sites';

interface SitesListProps {
  onSelectSite: (name: string, value: string) => void;
  isEdit?: boolean;
  initValue?: string;
  width?: number | string;
  margin?: string;
}

enum EFilter {
  SITEID = 'siteId',
}

export default function SitesList({
  onSelectSite,
  margin,
  initValue,
  width,
  isEdit,
}: SitesListProps) {
  const { t: siteSettingsT } = useTranslation('siteSettings');
  const { getAllSites } = useSites();
  const [defaultValueId, setDefaultValueId] = useState();

  const customWidth = width !== undefined ? width : 160;
  const customMargin = margin !== undefined ? margin : '0 0 24px 0';
  const { sites } = useSelector<any>((state) => state.allSites) as any;

  const getSites = async () => {
    await getAllSites();
  };

  useEffect(() => {
    if (!sites.length) {
      getSites();
    } else {
      const id = sites.filter((el: ISites) => el.id === 1)?.[0]?.id;
      setDefaultValueId(id);
    }
  }, [sites]);

  return (
    <Box sx={{ width: customWidth, margin: customMargin, alignSelf: 'center' }}>
      <SelectDropDown
        margin={customMargin}
        isEdit={isEdit}
        initValue={initValue}
        defaultValueId={defaultValueId}
        onSelect={onSelectSite}
        label={siteSettingsT('sites')}
        list={sites}
        filtrBy={EFilter.SITEID}
        itemKey={'name'}
      />
    </Box>
  );
}
