import { Dayjs } from 'dayjs';
import { ISite } from './signInForm';

export interface IAccountTableData {
  login: string;
  password: string;
  phone: string;
  email: string;
  role: string;
  siteIds?: ISite[];
}

export interface IEditAccount {
  currentId: string;
  closeModal: () => void;
  role: roleTypes;
}

export interface IAccount {
  login?: string;
  password?: string;
  phone?: string;
  email?: string;
  isActive?: boolean;
  role?: string;
  createdAt?: string;
  siteIds?: number[] | [];
}
export type roleTypes = 'support' | 'agent' | 'main' | 'manager' | 'operator' | '';

export interface IFilterData {
  id?: string;
  siteId?: string;
  from?: Dayjs | null;
  to?: Dayjs | null;
  isUsed?: string | boolean;
  statusId?: string;
  [key: string]: string | undefined | Dayjs | null | boolean;
}

export enum FIELD_NAME {
  LOGIN = 'login',
  EMAIL = 'email',
  PASSWORD = 'password',
  CODE = 'twoFactorToken',
  PHONE = 'phone',
  ROLE = 'role',
  ISACTIVE = 'isActive',
}
