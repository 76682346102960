import { AlertColor, Box, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import Result from 'components/alert/alert';
import { useTranslation } from 'react-i18next';

interface SitesSupportForLanguageProps {
  languageCode: string;
  saveSitesSupport: () => void;
  updateSitesSupport: (languageCode: string, SitesSupport: any) => void;
  handleOnClose: () => void;
  result: string;
  type: AlertColor;
  selectedItem: any;
}

function SitesSupportForLanguage({
  languageCode,
  updateSitesSupport,
  saveSitesSupport,
  handleOnClose,
  result,
  type,
  selectedItem,
}: SitesSupportForLanguageProps) {
  const { t: siteSettingsT } = useTranslation('siteSettings');
  const [contentState, setContentState] = useState(
    selectedItem ? selectedItem.sitesSupport : undefined,
  );

  const onContentStateChange = (contentState: any) => {
    setContentState(contentState);
    updateSitesSupport(languageCode, contentState);
  };

  useEffect(() => {
    if (selectedItem) {
      setContentState(selectedItem.sitesSupport);
    }
  }, [selectedItem]);

  return (
    <Box>
      <Editor
        editorStyle={{
          borderBottom: '2px solid gray',
          borderRight: '2px solid gray',
          borderLeft: '2px solid gray',
          padding: '5px',
          backgroundColor: 'white',
          color: 'black',
          minHeight: 300,
          maxHeight: 600,
        }}
        toolbarStyle={{
          color: 'black',
        }}
        onContentStateChange={onContentStateChange}
        contentState={contentState}
      />
      {result && (
        <Result
          style={{ mt: 2 }}
          message={result}
          type={type}
          isShow
          hasAction
          onClose={handleOnClose}
        />
      )}
      <Button
        sx={{ mt: 3 }}
        style={{ float: 'right', width: 150 }}
        variant="contained"
        onClick={saveSitesSupport}
      >
        {siteSettingsT('save')}
      </Button>
    </Box>
  );
}

export default SitesSupportForLanguage;
