// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Box, Button, Checkbox, TablePagination, TextField, Typography } from '@mui/material';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';
import FilterField from 'components/filter/columnFilter';
import { useEffect, useMemo, useState } from 'react';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { STORAGE_KEYS, localStorageService } from 'utils/storageService';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useSelector } from 'react-redux';
import { useClients } from 'hooks/clients/useClients';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { columnsName, ROW_PER_PAGE, SUCCESS } from 'constants/common';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeQueryUrl } from 'utils/utils';
import { IFilterData } from 'types/accounts';
import { isEmpty } from 'lodash';
import { FIELD_NAME, IAddClientsBonuses } from 'types/clients';
import { isValidValueForAddBonusForClients } from 'utils/helpers/inputHelper';
import Result from 'components/alert/alert';
import common from 'styles/common.module.scss';

interface addBonusesTableProps {
  isSend: boolean;
  ressetAll: () => void;
}

const fields = {
  [FIELD_NAME.TIME_CONFIRMATION]: true,
  [FIELD_NAME.PERCENT_VAL]: true,
};

const initialData = {
  timeConfirmation: null,
  percentVal: '',
  clientsId: [],
  messageForClients: null,
};

function AddBonusesTable({ isSend, ressetAll }: addBonusesTableProps) {
  const { t } = useTranslation('common');
  const { t: clientDetailsT, i18n } = useTranslation('clientsDetails');
  const { t: validationT } = useTranslation('validations');
  const { t: addBonusForClientsT } = useTranslation('addBonusForClients');
  const [formData, setFormData] = useState<IAddClientsBonuses>(initialData);
  const [clientsId, setClientsId] = useState<any[]>([]);
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(ROW_PER_PAGE);
  const [filterData, setFilterData] = useState<IFilterData | object>({});
  const [sortData, setSortData] = useState<IFilterData | object>({});
  const [bonusIsSend, setBonusIsSend] = useState(false);
  const [validFields, setValidFields] = useState({ ...fields });

  const { columns: columnNames, getClientsData, totalItems } = useClients();
  const navigate = useNavigate();
  const clientsList = useSelector<any>((state) => state.clients) as {
    clients: any[];
  };
  const { clients: data } = clientsList;

  useEffect(() => {
    setBonusIsSend(false);
    setFormData(initialData);
    setClientsId([]);
  }, [isSend]);

  useEffect(() => {
    const query = makeQueryUrl(filterData);
    const sortBy = Object.entries(sortData)[0];

    let paramsQuery = isEmpty(sortData)
      ? query
      : `${query}&sortBy=${sortBy[0]}&sortDir=${sortBy[1]}`;

    paramsQuery += `&page=${page + 1}&pageSize=${rowsPerPage}`;

    getClientsData(paramsQuery);
  }, [filterData, sortData, page, rowsPerPage]);

  const onContentStateChange = (contentState: any) => {
    const data = { ...formData, [FIELD_NAME.MESSAGE_FOR_CLIENTS]: contentState };
    setFormData(data);
  };

  const isValidData = (data: IAddClientsBonuses) => {
    if (data.timeConfirmation && data.timeConfirmation < dayjs()) {
      setValidFields((fields) => ({
        ...fields,
        [FIELD_NAME.TIME_CONFIRMATION]: false,
      }));
    }

    return data.timeConfirmation && data.timeConfirmation > dayjs() && data.percentVal !== '';
  };

  const validate = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, name: string) => {
    const { value } = e.target;
    setValidFields((fields) => ({
      ...fields,
      [name]: isValidValueForAddBonusForClients(value, name),
    }));
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    key: string,
  ) => {
    setValidFields((fields) => ({
      ...fields,
      [key]: true,
    }));
    const { value } = e.target;
    const data = { ...formData, [key]: value };
    setFormData(data);
  };

  const handleOnChange = (newValue: Dayjs | null) => {
    if (newValue) {
      validFields[FIELD_NAME.TIME_CONFIRMATION] = true;
      const data = { ...formData, [FIELD_NAME.TIME_CONFIRMATION]: newValue };
      setFormData(data);
    }
  };

  const handleDate = (newValue: Dayjs | null) => {
    if (newValue) {
      validFields[FIELD_NAME.TIME_CONFIRMATION] = true;
      const data = { ...formData, [FIELD_NAME.TIME_CONFIRMATION]: newValue };
      setFormData(data);
    }
  };

  const handleClick = () => {
    if (isValidData(formData)) {
      /**TODO: write logic for sending data WHen BE will be ready */
      setBonusIsSend(true);
      ressetAll();
    }
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
    // setResetGoToPage((prev) => !prev);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const actionFields = [
    {
      field: 'Choose Clients',
      headerName: 'Choose Clients',
      width: Math.ceil(window.innerWidth / columnNames.length),
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridValueGetterParams) => (
        <Checkbox
          checked={clientsId.includes(params.row.id) ? false : true}
          onChange={() => {
            if (clientsId.includes(params.row.id)) {
              const newList = clientsId.filter((el) => {
                return el != params.row.id;
              });
              const data = {
                ...formData,
                [FIELD_NAME.CLIENTS_ID]: newList,
              };
              setFormData(data);
              setClientsId((clientsId) =>
                clientsId.filter((el) => {
                  return el != params.row.id;
                }),
              );
            } else {
              clientsId.push(params.row.id);
              const data = {
                ...formData,
                [FIELD_NAME.CLIENTS_ID]: clientsId,
              };
              setFormData(data);
            }
          }}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      ),
    },
  ];

  const generateDinamicColumns = useMemo(() => {
    const columns = columnNames.map((el) => {
      if (el === columnsName.CREATED_AT) {
        return {
          field: el,
          width: Math.ceil(window.innerWidth / (columnNames.length + 1)),
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => <FilterField fieldName={columnsName.CREATED.toLocaleUpperCase()} />,
          valueGetter: (params: GridValueGetterParams) =>
            `${moment(params.row.createdAt).format('DD/MM/Y HH:mm:ss')}`,
        };
      }
      return {
        field: el,
        width: el === columnsName.ID ? 100 : Math.ceil(window.innerWidth / columnNames.length),
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => <FilterField fieldName={el.toLocaleUpperCase()} />,
        valueGetter: (params: GridValueGetterParams): any =>
          el === 'site'
            ? `${params.row[el].name}`
            : el === 'phone' && params.row[el] === null
            ? ''
            : `${params.row[el]}`,
      };
    });
    if (columns.length) {
      return [...actionFields, ...columns];
    }
    return [...columns];
  }, [columnNames, clientsId]);

  const columns = generateDinamicColumns as GridColDef[];

  const uploadImagOnEditor = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const dataURL = reader.result;
        resolve({ data: { link: dataURL } });
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <Box>
      {!bonusIsSend ? (
        <Box>
          <Box sx={{ display: 'flex', gap: 4, flexWrap: 'wrap', mt: 5 }}>
            <Box>
              <Editor
                autofocus
                localization={{
                  locale: i18n.language,
                }}
                placeholder={`   ${addBonusForClientsT('messageForClients')}`}
                editorStyle={{
                  borderBottom: '2px solid gray',
                  borderRight: '2px solid gray',
                  borderLeft: '2px solid gray',
                  overflowY: 'auto',
                  marginBottom: 10,
                  height: 200,
                  maxWidth: 1010,
                }}
                toolbarStyle={{
                  color: 'black',
                  width: 1000,
                }}
                toolbar={{
                  image: {
                    previewImage: true,
                    uploadCallback: uploadImagOnEditor,
                    alt: { present: true, mandatory: false },
                  },
                }}
                onContentStateChange={onContentStateChange}
              />
            </Box>
            <Box sx={{ display: 'flex', height: '90px' }}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={`${localStorageService.read(STORAGE_KEYS.LANGUAGE_KEY)}`}
              >
                <DateTimePicker
                  ampm={false}
                  slotProps={{
                    layout: { sx: { textTransform: 'capitalize' } },
                    textField: { size: 'small' },
                  }}
                  value={formData.timeConfirmation}
                  label={addBonusForClientsT('bonusConfirmationTime')}
                  onChange={(newValue) => handleOnChange(newValue)}
                  onAccept={(newValue) => handleDate(newValue)}
                  disablePast
                />
              </LocalizationProvider>
              <Typography
                color={common.mainwarning}
                sx={{ mt: 0.3, fontSize: 12, opacity: 0.7, textIndent: '5px', textAlign: 'left' }}
              >
                {validFields[FIELD_NAME.TIME_CONFIRMATION]
                  ? ''
                  : clientDetailsT('invalidDateRange')}
              </Typography>

              <TextField
                sx={{ ml: 4 }}
                label={addBonusForClientsT('percent')}
                required
                size="small"
                value={formData.percentVal}
                type="number"
                inputMode="numeric"
                onChange={(e) => handleChange(e, FIELD_NAME.PERCENT_VAL)}
                onBlur={(e) => validate(e, FIELD_NAME.PERCENT_VAL)}
                error={!validFields[FIELD_NAME.PERCENT_VAL]}
                helperText={
                  validFields[FIELD_NAME.PERCENT_VAL] ? '' : validationT('percentValidation')
                }
              />
              <Button
                type="submit"
                variant="contained"
                onClick={handleClick}
                disabled={
                  !validFields[FIELD_NAME.TIME_CONFIRMATION] ||
                  !validFields[FIELD_NAME.PERCENT_VAL] ||
                  !formData.percentVal ||
                  !formData.timeConfirmation
                }
                sx={{ ml: 4, height: 40, width: 150 }}
                size="small"
              >
                {addBonusForClientsT('addBonus')}
              </Button>
            </Box>
          </Box>
          <Box>
            <Box>
              <TablePagination
                component="div"
                count={totalItems}
                page={page}
                labelRowsPerPage={t('rowsPerPage')}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from >= to ? to : from}-${to} ${t('of')} ${count}`
                }
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              <DataGrid
                autoHeight
                rows={data}
                columns={columns}
                hideFooterPagination={true}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      currency: false,
                    },
                  },
                }}
                sx={{
                  mt: 5,
                  '.MuiDataGrid-iconButtonContainer': {
                    visibility: 'visible',
                  },
                  '.MuiDataGrid-sortIcon': {
                    opacity: 'inherit !important',
                  },
                  '.MuiDataGrid-cell': {
                    '&:last-child': {
                      justifyContent: 'center !important',
                    },
                  },
                  '.MuiDataGrid-footerContainer': {
                    display: 'none',
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
      ) : (
        <Result style={{ mt: 2 }} message={t('savedSuccess')} type={SUCCESS} isShow hasAction />
      )}
    </Box>
  );
}

export default AddBonusesTable;
