import { Box, TextField, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { FIELD_NAME, ICreateSite } from 'types/sites';
import { useSites } from 'hooks/sites/useSites';
import Result from 'components/alert/alert';
import SitesList from 'components/filter/siteLists';
import { useTranslation } from 'react-i18next';

interface CreateChannelProps {
  title: string;
  onClose: () => void;
}

export const initialData = {
  name: '',
  title: '',
  url: '',
  currencyIds: [],
  languageIds: [],
  isActive: true,
};

const style = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
};

function CreateChannel({ title, onClose }: CreateChannelProps) {
  const { t: telegramT } = useTranslation('telegram');
  const [formData, setFormData] = useState<ICreateSite>({ ...initialData });
  const { error } = useSites();

  const handleOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: string,
  ) => {
    const { value } = e.target;
    const data = { ...formData, [name]: value };
    setFormData(data);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onClose();
  };

  const handleFilter = (name: string, value: string) => {
    console.log({ name, value }, 'handleFilter');
  };

  return (
    <>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        {title}
      </Typography>
      <Box sx={style}>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <SitesList onSelectSite={handleFilter} />
          <Box>
            <TextField
              required
              fullWidth
              margin="normal"
              name="userName"
              label={telegramT('userNameOfChannel')}
              variant="standard"
              onChange={(e) => handleOnChange(e, FIELD_NAME.URL)}
            />
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'flex-start',
                fontSize: '12px',
                opacity: '0.5',
              }}
              component="div"
            >
              ({telegramT('withoutOfSymbol')})
            </Typography>
          </Box>
          {error && <Result style={{ mb: 2 }} message={error} type={'error'} isShow />}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Button fullWidth type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
              {telegramT('addChannel')}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default CreateChannel;
