import CreateAccountModal from 'components/modal/createAccountModal';
import AccountsTable from 'components/tables/accoutsTable';
import { roleTypes } from 'types/accounts';

interface AdminPageProps {
  role: roleTypes;
}
function AccountsPage({ role }: AdminPageProps) {
  return (
    <>
      <CreateAccountModal role={role} />
      <AccountsTable role={role} />
    </>
  );
}

export default AccountsPage;
