import { GridValueGetterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import FilterField from 'components/filter/columnFilter';
import { useClients } from 'hooks/clients/useClients';
import { GridColDef } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { IFilterData } from 'types/accounts';
import { columnsName, ROW_PER_PAGE } from 'constants/common';
import moment from 'moment';
import { isEmpty, makeQueryUrl } from 'utils/utils';
import { Box, useTheme } from '@mui/material';
import LoaderWithBg from 'components/loader/loader';
import { useDispatch } from 'react-redux';
import { setResetAll } from 'redux/actions/commonActions';
import { useTranslation } from 'react-i18next';

interface ClientsHistoryProps {
  clientId: string;
}

export default function ClientsDublicates({ clientId }: ClientsHistoryProps) {
  /**TDOD all this file need to change when BE will be ready */
  const { t } = useTranslation('common');
  const { t: clientsT } = useTranslation('clients');
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    isLoading,
    columns: columnNames,
    pageLists,
    totalItems,
    getClientHistory,
    clientHistory,
  } = useClients();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROW_PER_PAGE);
  const [resetGoToPage, setResetGoToPage] = useState(false);
  const [filterData, setFilterData] = useState<IFilterData | object>({});
  const [sortData, setSortData] = useState<IFilterData | object>({});

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (newPage >= +pageLists[pageLists.length - 1].val) {
      setPage(+pageLists[pageLists.length - 1].val);
    } else {
      setPage(newPage);
    }
    setResetGoToPage((prev) => !prev);
  };

  const handleFilter = (name: string, value: string) => {
    setFilterData((filterby) => ({ ...filterby, [name]: value }));
  };

  const handleSortBy = (sortBy: string, sortDir: string) => {
    setSortData({ [sortBy]: sortDir });
  };

  const handleResetAll = () => {
    getClientHistory(clientId);
    dispatch(setResetAll(true));
    setFilterData({});
    setSortData({});
  };

  const handleSelectPage = (page: string) => {
    setPage(+page - 1);
  };

  const generateDinamicColumns = useMemo(() => {
    const columns = columnNames.map((el) => {
      if (el === columnsName.LOGINDATE) {
        return {
          field: el,
          width: Math.ceil(window.innerWidth / (columnNames.length + 1)),
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={columnsName.LOGIN_DATE.toLocaleUpperCase()}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
            />
          ),
          valueGetter: (params: GridValueGetterParams) =>
            `${moment(params.row.createdAt).format('DD/MM/Y HH:mm:ss')}`,
        };
      } else if (el === 'data') {
        return {
          field: el,
          width: Math.ceil(window.innerWidth / 3),
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el.toLocaleUpperCase()}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isDisabled
            />
          ),
          valueGetter: (params: GridValueGetterParams) => JSON.stringify(params.row[el]),
        };
      } else if (el === columnsName.IPADDRESS) {
        return {
          field: columnsName.IP_ADDRESS.toLocaleUpperCase(),
          width: Math.ceil(window.innerWidth / 3),
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={columnsName.IP_ADDRESS.toLocaleUpperCase()}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
            />
          ),
          valueGetter: (params: GridValueGetterParams) => JSON.stringify(params.row[el]),
        };
      }

      return {
        field: el,
        width: el === columnsName.ID ? 100 : Math.ceil(window.innerWidth / columnNames.length),
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => (
          <FilterField
            fieldName={el.toLocaleUpperCase()}
            onBlur={(value) => handleFilter(el, value)}
            onSort={handleSortBy}
            sortData={sortData}
          />
        ),
      };
    });

    return [...columns];
  }, [columnNames, sortData]);

  const columns = generateDinamicColumns as GridColDef[];

  useEffect(() => {
    const query = makeQueryUrl(filterData);
    const sortBy = Object.entries(sortData)[0];

    let paramsQuery = isEmpty(sortData)
      ? query
      : `${query}&sortBy=${sortBy[0]}&sortDir=${sortBy[1]}`;

    paramsQuery += `&page=${page + 1}&pageSize=${rowsPerPage}`;
    getClientHistory(clientId, paramsQuery);
  }, [clientId, filterData, sortData, page, rowsPerPage]);

  return (
    <div style={{ width: '100%' }}>
      {isLoading ? (
        <LoaderWithBg isOpen={isLoading} />
      ) : (
        <Box style={{ width: '100%' }}>
          Client Dublicates
          {/* <Box className={styles.filter_box}>
            <div className={styles.filter_btn}>
              <Box sx={{ display: 'flex', mt: 2, mb: 2 }}>
                <DropDownSelect
                  totalPages={pageLists}
                  onSelectPage={handleSelectPage}
                  reset={resetGoToPage}
                />
                {Object.values(filterData).join() || Object.values(sortData).join() ? (
                  <Button
                    className={styles.reset_all_btn}
                    variant="contained"
                    onClick={handleResetAll}
                  >
                    {clientsT('ressetAll')}
                  </Button>
                ) : null}
              </Box>
            </div>
          </Box>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            labelRowsPerPage={t('rowsPerPage')}
            labelDisplayedRows={({ from, to, count }) =>
              `${from >= to ? to : from}-${to} ${t('of')} ${count}`
            }
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <DataGrid
            autoHeight
            getRowHeight={() => 'auto'}
            rows={clientHistory}
            columns={columns}
            hideFooterPagination={true}
            sx={{
              '.MuiDataGrid-iconButtonContainer': {
                visibility: 'visible',
              },
              '.MuiDataGrid-sortIcon': {
                opacity: 'inherit !important',
              },
              '.MuiDataGrid-cell': {
                '&:last-child': {
                  justifyContent: 'center !important',
                },
              },
              '.MuiDataGrid-footerContainer': {
                display: 'none',
              },
            }}
          /> */}
        </Box>
      )}
    </div>
  );
}
