export const actionTypes = {
  SET_ACCOUNTS_DATA: 'SET_ACCOUNTS_DATA',
  SET_ROLE: 'SET_ROLE',
  ADD_ACCOUNT_DATA: 'ADD_ACCOUNT_DATA',
  DECREASE_INPROCESS_USERS_COUNT: 'DECREASE_INPROCESS_USERS_COUNT',
  SET_SITES: 'SET_SITES',
  SET_CURRENCIES: 'SET_CURRENCIES',
  SET_SITE_SETTINGS: 'SET_SITE_SETTINGS',
  UPDATE_INPROCESS_USERS_COUNT: 'UPDATE_INPROCESS_USERS_COUNT',
  SET_CURRENT_SITE: 'SET_CURRENT_SITE',
  SET_CURRENT_ID: 'SET_CURRENT_ID',
  SET_RESET_ALL: 'SET_RESET_ALL',
  SET_SITE_CATEGORIES: 'SET_SITE_CATEGORIES',
  SET_CATEGORY_GAMES: 'SET_CATEGORY_GAMES',
  SET_PROVIDER_GAMES: 'SET_PROVIDER_GAMES',
  ADD_PROVIDER_GAMES: 'ADD_PROVIDER_GAMES',
  ADD_CATEGORY_GAMES: 'ADD_CATEGORY_GAMES',
  UPDATE_CATEGORY_GAMES: 'UPDATE_CATEGORY_GAMES',
  SET_CLIENTS_LIST: 'SET_CLIENTS_LIST',
  SET_LEVELS_LIST: 'SET_LEVELS_LIST',
  SET_TRANSACTIONS:'SET_TRANSACTIONS',
  SET_FINANCE_WITHDRAWALS:'SET_FINANCE_WITHDRAWALS',
  UPDATE_FINANCE_WITHDRAWALS:'UPDATE_FINANCE_WITHDRAWALS',
  SET_WHEELS_LIST: 'SET_WHEELS_LIST',
  UPDATE_LEVEL_DATA: 'UPDATE_LEVEL_DATA',
  ADD_WHEEL: 'ADD_WHEEL',
  ADD_CREATED_LEVEL: 'ADD_CREATED_LEVEL',
  SET_MESSAGES_LIST: 'SET_MESSAGES_LIST',
  SET_LABELS: 'SET_LABELS',
  SET_PROMOTION_PROMO: 'SET_PROMOTION_PROMO',
  ADD_CREATED_PROMOTION_PROMO: 'ADD_CREATED_PROMOTION_PROMO',
  UPDATE_PROMO_CODES: 'UPDATE_PROMO_CODES',
  SET_BONUSES_LIST: 'SET_BONUSES_LIST',
  CREATED_BONUS: 'CREATED_BONUSES',
  UPDATE_BONUS_DATA: 'UPDATE_BONUS_DATA',
  DELETE_BONUS_DATA: 'DELETE_BONUS_DATA',
  SET_LEVELS_DATA_WITHOUT_PAGINATION: 'SET_LEVELS_DATA_WITHOUT_PAGINATION',
  SET_SOCIAL_LINKS_DATA: 'SET_SOCIAL_LINKS_DATA',
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  ADD_SOCIAL_LINK: 'ADD_SOCIAL_LINK',
  REMOVE_SOCIAL_LINK: 'REMOVE_SOCIAL_LINK',
  SET_TRANSACTIONS_ACTIONS: 'SET_TRANSACTIONS_ACTIONS',
  SET_SITE_PAYMENT_PROVIDERS_SETTINGS: 'SET_SITE_PAYMENT_PROVIDERS_SETTINGS',
  UPDATE_SITE_PAYMENT_PROVIDERS_SETTINGS: 'UPDATE_SITE_PAYMENT_PROVIDERS_SETTINGS',
};
