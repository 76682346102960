import { Box } from '@material-ui/core';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import UploadButtons from 'components/imageUpload/imageUpload';
import { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';
import { FIELD_NAME, ILevel } from 'types/sites';
import { IBonusCreating } from 'types/sitesSettings';
import { isValidForSite } from 'utils/helpers/inputHelper';
import common from 'styles/common.module.scss';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import dayjs, { Dayjs } from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { useSiteBonuses } from 'hooks/sites/useSiteBonuses';
import { useSelector } from 'react-redux';
import { useLevels } from 'hooks/sites/useLevels';

interface CreateSiteBonusProps {
  siteId: string;
  item: any;
  mode: string;
  onClose: () => void;
}

const fields = {
  [FIELD_NAME.TITLE]: true,
  [FIELD_NAME.IMAGE]: true,
  [FIELD_NAME.BANNER_IMAGE]: true,
  [FIELD_NAME.BONUS_WEIDGER]: true,
  [FIELD_NAME.WEIDGER_DATE]: true,
};

function CreateSiteBonus({ siteId, item, mode, onClose }: CreateSiteBonusProps) {
  const { t } = useTranslation('common');
  const { t: siteSettingsT } = useTranslation('siteSettings');
  const { t: validationT } = useTranslation('validations');
  const [validFields, setValidFields] = useState({ ...fields });
  const [contentState, setContentState] = useState<any>();
  const [formData, setFormData] = useState<IBonusCreating>({} as IBonusCreating);
  const [pendingImageUrl, setPendingImageUrl] = useState<string | Blob>('');
  const [pendingBannerImageUrl, setPendingBannerImageUrl] = useState<string | Blob>('');
  const { getAllLevelsWithoutPagination } = useLevels();
  const { createBonus, editeBonus, setImageOrBannerImageForBonus } = useSiteBonuses();
  const levelsList = useSelector<any>((state) => state.levels) as ILevel[];

  const onContentStateChange = (contentState: any) => {
    setContentState(contentState);
  };

  const handleOnSwicth = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setFormData({ ...formData, ['isAvailable']: checked });
  };

  const removeImage = (name: string) => {
    if (name === 'mainImage') {
      setPendingImageUrl('');
    } else {
      setPendingBannerImageUrl('');
    }
    const data = { ...formData, [name]: '' };
    setFormData(data);
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent<string | null>,
    key: string,
  ) => {
    const { value } = e.target;
    let newData;
    if (key == 'levelGroup') {
      newData = { ...formData, [key]: { id: value } };
    } else {
      newData = { ...formData, [key]: value };
    }
    setFormData(newData);
  };

  const dateTimePickerChange = (newValue: Dayjs | null) => {
    if (newValue) {
      const data = { ...formData, [FIELD_NAME.WEIDGER_DATE]: newValue };
      setFormData(data);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const body = {
      title: formData.title,
      description: contentState ?? null,
      levelGroupId: formData?.levelGroup?.id,
      weidger: +formData.weidger,
      isAvailable: formData.isAvailable ?? true,
      weidgerDate: formData.weidgerDate ?? null,
    };

    try {
      let result;
      if (mode === 'edit') {
        result = await editeBonus(siteId, item?.id, body);
      } else {
        result = await createBonus(siteId, body);
      }
      if (result?.status === 200 || result?.status === 201) {
        if (pendingImageUrl) {
          await sendImages(result.data.id, 'main');
        }
        if (pendingBannerImageUrl) {
          await sendImages(result.data.id, 'banner');
        }
      }
      onClose();
    } catch (error: any) {
      console.log(error, 'error');
    }
  };

  const onImageUpload = async (imageUrl: string | Blob, prevUrl?: string, key?: string) => {
    if (key === 'bannerImage') {
      setPendingBannerImageUrl(imageUrl);
      setFormData((formData) => ({ ...formData, bannerImage: prevUrl ?? '' }));
    } else {
      setPendingImageUrl(imageUrl);
      setFormData((formData) => ({ ...formData, mainImage: prevUrl ?? '' }));
    }
  };

  const sendImages = async (bonusId: string, type: string) => {
    await setImageOrBannerImageForBonus(
      siteId,
      bonusId,
      type,
      type === 'main' ? pendingImageUrl : pendingBannerImageUrl,
    );
  };

  const validate = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, name: string) => {
    const { value } = e.target;
    setValidFields((fields) => ({
      ...fields,
      [name]: isValidForSite(value, name),
    }));
  };

  useEffect(() => {
    if (item.id !== undefined) {
      setFormData({ ...item });
    }
    getAllLevelsWithoutPagination();
  }, [item]);

  return (
    <Box>
      <Typography variant="h6" component="h2">
        {t(mode)}
      </Typography>
      <Box component={'form'} onSubmit={handleSubmit} style={{ marginTop: 15 }}>
        <TextField
          margin="normal"
          id="title"
          label={siteSettingsT(FIELD_NAME.TITLE)}
          fullWidth
          required
          aria-required
          value={formData?.title ?? ''}
          variant="standard"
          InputLabelProps={{
            shrink: true,
          }}
          error={!validFields[FIELD_NAME.TITLE]}
          onBlur={(e) => validate(e, FIELD_NAME.TITLE)}
          onChange={(e) => handleOnChange(e, 'title')}
          helperText={validFields[FIELD_NAME.TITLE] ? '' : validationT('siteNameValidation')}
        />
        <Editor
          editorStyle={{
            borderBottom: '2px solid gray',
            borderRight: '2px solid gray',
            borderLeft: '2px solid gray',
            padding: '5px',
            backgroundColor: 'white',
            color: 'black',
            minHeight: 300,
            maxHeight: 600,
          }}
          toolbarStyle={{
            color: 'black',
            marginTop: 15,
          }}
          onContentStateChange={onContentStateChange}
          contentState={formData?.description}
        />
        <TextField
          margin="normal"
          type="number"
          id="bonusWeidger"
          label={siteSettingsT('weidger')}
          variant="standard"
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ width: '300px' }}
          value={formData.weidger}
          onChange={(e) => handleOnChange(e, FIELD_NAME.BONUS_WEIDGER)}
          onBlur={(e) => validate(e, FIELD_NAME.BONUS_WEIDGER)}
          error={!validFields[FIELD_NAME.BONUS_WEIDGER]}
          helperText={validFields[FIELD_NAME.BONUS_WEIDGER] ? '' : validationT('minValue')}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DateTimePicker']}>
            <DateTimePicker
              ampm={false}
              sx={{ width: '300px', mt: 2, mb: 2 }}
              slotProps={{
                layout: { sx: { textTransform: 'capitalize' } },
                textField: { size: 'small' },
              }}
              label={siteSettingsT('weidgerDate')}
              disablePast
              value={formData.weidgerDate ? dayjs(formData.weidgerDate) : null}
              onChange={(newValue) => dateTimePickerChange(newValue)}
            />
          </DemoContainer>
        </LocalizationProvider>

        <FormGroup sx={{ display: 'flex', flexDirection: 'column' }}>
          <FormControlLabel
            sx={{
              width: '5px',
            }}
            control={
              <Switch
                checked={formData?.isAvailable ?? true}
                inputProps={{ 'aria-label': 'controlled' }}
                onChange={(e) => handleOnSwicth(e)}
              />
            }
            label={
              formData?.isAvailable ?? true
                ? siteSettingsT('available')
                : siteSettingsT('unavailable')
            }
          />
        </FormGroup>

        <Box>
          <FormControl sx={{ minWidth: '200px', mt: 2, mb: 2 }} size="small">
            <InputLabel id="levelsList-select-label">{siteSettingsT('clientsLevel')}</InputLabel>
            <Select
              labelId="levelsList-select-label"
              id="levelsList-select"
              required
              value={formData?.levelGroup?.id ?? ''}
              onChange={(e) => handleOnChange(e, 'levelGroup')}
              label={siteSettingsT('clientsLevel')}
            >
              {levelsList.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>

        <Box style={{ display: 'flex', gap: 10, alignItems: 'center', flexWrap: 'wrap' }}>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <UploadButtons
              onImageUpload={(url, prevUrl, key) => onImageUpload(url, prevUrl, key)}
              keys="image"
              needFormDataImage
              needFormDataImageWithPreview
              initialImage={formData?.mainImage ?? null}
            />
            {formData?.mainImage ? (
              <DeleteForeverIcon
                sx={{ color: common.mainwarning, fontSize: 32, cursor: 'pointer' }}
                onClick={() => removeImage('mainImage')}
              />
            ) : null}
          </Box>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <UploadButtons
              onImageUpload={(url, prevUrl, key) => onImageUpload(url, prevUrl, key)}
              keys="bannerImage"
              needFormDataImage
              needFormDataImageWithPreview
              initialImage={formData?.bannerImage ?? null}
              btnText={t('uploadBannerImage')}
            />
            {formData?.bannerImage ? (
              <DeleteForeverIcon
                sx={{ color: common.mainwarning, fontSize: 32, cursor: 'pointer' }}
                onClick={() => removeImage('bannerImage')}
              />
            ) : null}
          </Box>
        </Box>
        <Button
          sx={{ mt: 3 }}
          style={{ float: 'right', width: 150 }}
          variant="contained"
          type="submit"
          disabled={
            !validFields[FIELD_NAME.TITLE] ||
            !validFields[FIELD_NAME.BONUS_WEIDGER] ||
            !validFields[FIELD_NAME.WEIDGER_DATE]
          }
        >
          {siteSettingsT('save')}
        </Button>
      </Box>
    </Box>
  );
}

export default CreateSiteBonus;
