import { actionTypes } from 'redux/constants/actionTypes';

const initial = {
  sites: [],
};

const initCurrenciesList = [];

const initCurrentSite = {
  isActive: '',
  name: '',
  title: '',
  url: '',
  currencyIds: [],
};

const initCategories = {
  categories: [
    {
      id: '',
      title: '',
      image: null,
      isMobile: false,
      isDesktop: false,
    },
  ],
  categoryGames: [],
};

const initProviderGames = {
  providerGames: [],
};

const initSettings = {
  siteSettings: {},
  paymentProviders: [],
};

export const sitesReducer = (state = initial, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_SITES:
      return { ...state, sites: payload ?? [] };
    default:
      return state;
  }
};

export const currencyReducer = (state = initCurrenciesList, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_CURRENCIES:
      return payload;
    default:
      return state;
  }
};

export const providerGamesReducer = (state = initProviderGames, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_PROVIDER_GAMES:
      return { ...state, providerGames: payload ?? [] };
    case actionTypes.ADD_PROVIDER_GAMES:
      return { ...state, providerGames: [...state.providerGames, ...payload] ?? [] };
    default:
      return state;
  }
};

export const categoriesReducer = (state = initCategories, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_SITE_CATEGORIES:
      return { ...state, categoryGames: [], categories: payload ?? [] };
    case actionTypes.SET_CATEGORY_GAMES:
      return { ...state, categoryGames: payload ?? [] };
    case actionTypes.ADD_CATEGORY_GAMES:
      return { ...state, categoryGames: [...state.categoryGames, ...payload] ?? [] };
      case actionTypes.UPDATE_CATEGORY_GAMES:
      return { ...state, categoryGames: state.categoryGames.filter(el => (el.id + '').split('_')?.[0] !== payload ) ?? [] };
    default:
      return state;
  }
};

export const currentSiteReducer = (state = initCurrentSite, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_CURRENT_SITE:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const siteSettingsReducer = (state = initSettings, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_SITE_SETTINGS:
      return { ...state, siteSettings: payload };
    case actionTypes.UPDATE_INPROCESS_USERS_COUNT:
      return { ...state, siteSettings: { inProcessUsersCount: `${payload}` } };
    case actionTypes.DECREASE_INPROCESS_USERS_COUNT:
      return {
        ...state,
        siteSettings: { inProcessUsersCount: state.siteSettings.inProcessUsersCount - 1 },
      };
    case actionTypes.SET_SITE_PAYMENT_PROVIDERS_SETTINGS:
      return { ...state, paymentProviders: payload };
    case actionTypes.UPDATE_SITE_PAYMENT_PROVIDERS_SETTINGS:
      return {
        ...state,
        paymentProviders: state.paymentProviders.map((elm) =>
          elm.id === payload.id ? (elm = payload) : elm,
        ),
      };
    default:
      return state;
  }
};
