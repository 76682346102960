import {
  Box,
  Button,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useState } from 'react';
import Result from 'components/alert/alert';
import { useClients } from 'hooks/clients/useClients';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs, { Dayjs } from 'dayjs';
import EmptyTextarea from 'components/textArea/textArea';
import common from 'styles/common.module.scss';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import styles from './modal.module.scss';
import { STORAGE_KEYS, localStorageService } from 'utils/storageService';

interface EditUserStatusProps {
  title: string;
  onClose: () => void;
  user: any;
  clientId: string;
  userCurrency: string;
}

export const initialData = {
  isBlocked: false,
  blockMessage: null,
  blockedUntil: null,
  clientBlockMessage: null,
};

const style = {
  mt: 2,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
};

function EditUserStatus({ title, onClose, user, userCurrency, clientId }: EditUserStatusProps) {
  const { t: clientDetailsT } = useTranslation('clientsDetails');
  const { t: validationT } = useTranslation('validations');
  const { editClient, error } = useClients();
  const [data, setData] = useState(user.isBlocked ? '1' : '2');
  const [formData, setFormData] = useState({ ...initialData, ...user });
  const [, setDate] = useState<Dayjs | null>(null);
  const [isValidDate, setIsValidDate] = useState(!(moment(user.blockedUntil) < moment()));
  const [validationError, setValidationError] = useState({
    clientBlockMessage: true,
  });
  const neesRussionText = userCurrency !== 'EUR' && userCurrency !== 'USD';

  const handleOnChangeTextArea = (val: string, key: string) => {
    setValidationError({ clientBlockMessage: true });

    if (key === 'clientBlockMessage') {
      const str = neesRussionText
        ? val.replace(/[^a-zA-Z]/g, '')
        : val.replace(/[^a-zA-Z0-9]/g, '');
      const isMatched = neesRussionText
        ? /^\p{Script=Cyrillic}*$/u.test(str)
        : /^[a-z][a-z0-9]*$/i.test(str);

      setValidationError({ clientBlockMessage: isMatched });
    }
    const data = { ...formData, [key]: val };
    setFormData(data);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let body = {};
    if (!formData.isBlocked) {
      body = { isBlocked: formData.isBlocked };
    } else {
      body = { ...formData };
    }

    const result = await editClient(clientId, body);

    if (result?.status === 200) {
      onClose();
    }
  };

  const handleOnChangeDate = (newValue: dayjs.Dayjs | null) => {
    const data = { ...formData, blockedUntil: newValue };
    setIsValidDate(true);
    setDate(newValue);
    setFormData(data);
  };

  const handleChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    const data = { ...formData, isBlocked: value === '1' };
    setData(value);
    setFormData(data);
  };

  return (
    <>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        {title}
      </Typography>
      <Box sx={style}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ mt: 1, width: '100%', maxWidth: '450px' }}
        >
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">{clientDetailsT('selectStatus')}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={data}
              label={clientDetailsT('selectStatus')}
              onChange={handleChange}
            >
              {[
                { id: '1', name: 'blocked' },
                { id: '2', name: 'active' },
              ].map((el) => {
                return (
                  <MenuItem value={el.id} key={el.id}>
                    {clientDetailsT(el.name)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {data !== '2' ? (
            <>
              <Box sx={{ mt: 2, mb: 2 }}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={`${localStorageService.read(STORAGE_KEYS.LANGUAGE_KEY)}`}
                >
                  <DemoContainer components={['DatePicker']}>
                    <DatePicker
                      label={clientDetailsT('untilSelectedDate')}
                      value={formData.blockedUntil ? dayjs(formData.blockedUntil) : null}
                      slotProps={{
                        layout: { sx: { textTransform: 'capitalize' } },
                      }}
                      disablePast
                      views={['year', 'month', 'day']}
                      onChange={(newValue) => handleOnChangeDate(newValue)}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                {!isValidDate ? (
                  <Typography
                    color={common.mainwarning}
                    sx={{
                      mt: 0.3,
                      fontSize: 12,
                      opacity: 0.7,
                      textIndent: '5px',
                      textAlign: 'left',
                    }}
                  >
                    {validationT('clientBlockedDateValidation')}
                  </Typography>
                ) : null}
              </Box>
              <Box className={styles.textArea}>
                <Typography
                  variant="subtitle1"
                  sx={{ flexBasis: '20%', fontSize: '14px', mr: 2, opacity: '0.5' }}
                >
                  {clientDetailsT('reason')} *
                </Typography>
                <EmptyTextarea
                  isModal
                  defaultValue={formData.blockMessage ?? ''}
                  onChange={(val) => handleOnChangeTextArea(val, 'blockMessage')}
                />
              </Box>
              <Box className={styles.textArea} sx={{ alignItems: 'center' }}>
                <Typography variant="subtitle1" sx={{ fontSize: '14px', mr: 2, opacity: '0.5' }}>
                  {clientDetailsT('messageToClient')} *
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: '11px',
                    opacity: '0.5',
                    color: common.mainwarning,
                  }}
                >
                  {neesRussionText ? clientDetailsT('onRus') : clientDetailsT('onEng')}
                </Typography>
              </Box>
              <Box className={styles.textArea}>
                <EmptyTextarea
                  isModal
                  defaultValue={formData.clientBlockMessage ?? ''}
                  onChange={(val) => handleOnChangeTextArea(val, 'clientBlockMessage')}
                  error={
                    validationError.clientBlockMessage
                      ? ''
                      : neesRussionText
                      ? clientDetailsT('onRus')
                      : clientDetailsT('onEng')
                  }
                />
              </Box>
            </>
          ) : null}
          {error && <Result style={{ mb: 2 }} message={error} type={'error'} isShow />}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Button
              fullWidth
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={!validationError.clientBlockMessage}
            >
              {clientDetailsT('save')}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default EditUserStatus;
