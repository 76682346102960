import http from '../http-common';

class SitePercentsDataService {

  getAllFinancePercents = (headers, siteId) => {
    return http.get(`/site/${siteId}/finance-percents`, { headers });
  };

  getAllProviderPercents = (headers, siteId) => {
    return http.get(`/site/${siteId}/game-percents`, { headers });
  };

  updateFinancePercents = (headers, siteId, body) => {
    return http.put(`/site/${siteId}/finance-percents`, body, { headers });
  }

  updateProviderPercents = (headers, siteId, body) => {
    return http.put(`/site/${siteId}/game-percents`, body, { headers });
  }

}

const SitePercentsService = new SitePercentsDataService();

export default SitePercentsService;
