import { actionTypes } from 'redux/constants/actionTypes';

export const setFinanceWithdrawals = (list) => {
  return {
    type: actionTypes.SET_FINANCE_WITHDRAWALS,
    payload: list,
  };
};

export const updateFinanceWithdrawals = (item) => {
  return {
    type: actionTypes.UPDATE_FINANCE_WITHDRAWALS,
    payload: item,
  };
};
 