import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  TablePagination,
} from '@mui/material';
import DropDownSelect from 'components/select/select';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { IFilterData } from 'types/accounts';
import { setResetAll } from 'redux/actions/commonActions';
import { bonusStatus, columnsName, ROW_PER_PAGE } from 'constants/common';
import SitesList from 'components/filter/siteLists';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import FilterField from 'components/filter/columnFilter';
import { makeQueryUrl, isEmpty, dateFormat } from 'utils/utils';
import CurrencyList from 'components/filter/currencyLists';
import { useSites } from 'hooks/sites/useSites';
import styles from '../table.module.scss';

const bonusStatusObj = [
  { id: 1, value: bonusStatus.USED },
  { id: 2, value: bonusStatus.ACTIVE },
  { id: 3, value: bonusStatus.CANCELED },
  { id: 4, value: bonusStatus.NEW },
];

interface BonusesTableProps {
  clientId?: string;
}

function BonusesTable({ clientId }: BonusesTableProps) {
  const { t } = useTranslation('common');
  const { t: promoCodesT } = useTranslation('promoCodes');
  const [status, setStatus] = useState('');
  const [page, setPage] = useState(0);
  const [filterData, setFilterData] = useState<IFilterData>({});
  const [rowsPerPage, setRowsPerPage] = useState(ROW_PER_PAGE);
  const [sortData, setSortData] = useState<IFilterData | object>({});
  const [resetGoToPage, setResetGoToPage] = useState(false);
  const dispatch = useDispatch();
  const {
    columns: columnNames,
    siteList: data,
    getSitesAllUsersBonuses,
    pageLists,
    totalItems,
  } = useSites();

  const handleStatusChange = (e: SelectChangeEvent, name: string) => {
    const { value } = e.target;

    if (value) {
      setFilterData((filterby) => ({ ...filterby, [name]: value }));
    } else {
      if (filterData?.[name] !== undefined) {
        delete filterData?.[name];
        setFilterData({ ...filterData });
      }
    }
    setStatus(value);
  };

  const handleSelectPage = (page: string) => {
    setPage(+page - 1);
  };

  const handleResetAll = () => {
    dispatch(setResetAll(true));
    setFilterData({ siteId: filterData.siteId });
    setSortData({});
    setStatus('');
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortBy = (sortBy: string, sortDir: string) => {
    setSortData({ [sortBy]: sortDir });
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (newPage >= +pageLists[pageLists.length - 1].val) {
      setPage(+pageLists[pageLists.length - 1].val);
    } else {
      setPage(newPage);
    }
    setResetGoToPage((prev) => !prev);
  };

  const handleFilter = (name: string, value: string) => {
    setFilterData((filterby) => ({ ...filterby, [name]: value }));
  };

  useEffect(() => {
    if (filterData.siteId) {
      const query = makeQueryUrl(filterData, 'siteId');
      const sortBy = Object.entries(sortData)[0];

      let paramsQuery = isEmpty(sortData)
        ? query
        : `${query}&sortBy=${sortBy[0]}&sortDir=${sortBy[1]}`;

      paramsQuery += `&page=${page + 1}&pageSize=${rowsPerPage}`;
      if (clientId) {
        paramsQuery += `&userId=${clientId}`;
      }

      getSitesAllUsersBonuses(filterData.siteId, paramsQuery);
    }
  }, [filterData, sortData, page, rowsPerPage, clientId]);

  const generateDinamicColumns = useMemo(() => {
    const columns = columnNames.map((el) => {
      if (el == columnsName.USER) {
        return {
          field: el,
          width: el.length * 6 + 150,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isCamelCase
            />
          ),
          renderCell: (params: GridValueGetterParams) => (
            <Link
              color={'text.primary'}
              component="a"
              target="blank"
              onClick={() => {
                window.open(`/clients/details/${params.row.user.id}`);
              }}
            >
              {params.row.user.login}
            </Link>
          ),
        };
      } else if (el === columnsName.CREATED_AT || el === columnsName.DATE) {
        return {
          field: el,
          minWidth: el.length * 6 + 140,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isCamelCase
            />
          ),
          valueGetter: (params: GridValueGetterParams) =>
            dateFormat(params.row.createdAt || params.row.date),
        };
      }
      return {
        field: el,
        width: el === columnsName.ID ? 100 : el.length * 6 + 150,
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => (
          <FilterField
            fieldName={el}
            onBlur={(value) => handleFilter(el, value)}
            onSort={handleSortBy}
            sortData={sortData}
            isCamelCase
            isDisabled={el === columnsName.BALANCE_TYPE}
          />
        ),
      };
    });
    return [...columns];
  }, [columnNames, sortData]);

  const columns = generateDinamicColumns as GridColDef[];

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box className={styles.filter_box}>
          <div className={styles.filter_btn}>
            <Box sx={{ display: 'flex', mt: 2, mb: 2 }}>
              <DropDownSelect
                totalPages={pageLists}
                onSelectPage={handleSelectPage}
                reset={resetGoToPage}
              />
              {Object.values(filterData).join() || Object.values(sortData).join() ? (
                <Button
                  className={styles.reset_all_btn}
                  variant="contained"
                  onClick={handleResetAll}
                >
                  {t('ressetAll')}
                </Button>
              ) : null}
            </Box>
          </div>
        </Box>
        <Box sx={{ display: 'flex', minWidth: 100 }}>
          <Box>
            <SitesList
              onSelectSite={handleFilter}
              margin="0 24px 24px 0"
              initValue={isEmpty(filterData)}
            />
          </Box>
          <Box sx={{ minWidth: 100, mr: 3 }}>
            <CurrencyList onSelectSite={handleFilter} initValue={isEmpty(filterData)} />
          </Box>
          <Box sx={{ minWidth: 100, mr: 3 }}>
            <FormControl sx={{ width: 300, mr: 3 }} size="small">
              <InputLabel id="status-type">{promoCodesT('status')}</InputLabel>
              <Select
                id="status-type"
                label={promoCodesT('status')}
                value={status}
                onChange={(e) => handleStatusChange(e, 'status')}
              >
                <MenuItem value="">
                  <em>{t('none')}</em>
                </MenuItem>
                {bonusStatusObj.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.value}>
                      {promoCodesT(item.value)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <TablePagination
          component="div"
          count={totalItems}
          page={page}
          labelRowsPerPage={t('rowsPerPage')}
          labelDisplayedRows={({ from, to, count }) =>
            `${from >= to ? to : from}-${to} ${t('of')} ${count}`
          }
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <DataGrid
          autoHeight
          rows={data}
          columns={columns}
          hideFooterPagination={true}
          initialState={{
            columns: {
              columnVisibilityModel: {
                userId: false,
              },
            },
          }}
          sx={{
            '.MuiDataGrid-iconButtonContainer': {
              visibility: 'visible',
            },
            '.MuiDataGrid-sortIcon': {
              opacity: 'inherit !important',
            },
            '.MuiDataGrid-cell': {
              '&:last-child': {
                justifyContent: 'center !important',
              },
            },
            '.MuiDataGrid-footerContainer': {
              display: 'none',
            },
          }}
        />
      </Box>
    </>
  );
}

export default BonusesTable;
