import { actionTypes } from 'redux/constants/actionTypes';

const initial = { role: '' };

export const roleReducer = (state = initial, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_ROLE:
      return { ...state, role: payload };
    default:
      return state;
  }
};
