import http from '../http-common';

class SiteDataService {
  create = async (headers, body) => {
    const { data } = await http.post('/site', body, { headers });
    return data;
  };

  getAll = (headers) => http.get('/site/all', { headers });

  getOneById = (headers, id) => {
    return http.get(`/site/${id}`, { headers });
  };

  getSiteByQuery = (headers, query = '') => {
    return http.get(`/site/all?${query}`, { headers });
  };

  editSite = (headers, id, body) => {
    return http.put(`/site/${id}`, body, { headers });
  };

  createSiteCategory = async (headers, siteId, body) => {
    const { data } = await http.post(`/site/${siteId}/category`, body, { headers });
    return data;
  };

  editCategory = (headers, siteId, categoryId, body) => {
    return http.put(`/site/${siteId}/category/${categoryId}`, body, { headers });
  };

  putGamesOnDrag = (headers, siteId, categoryId, body, query = '') => {
    return http.put(`/site/${siteId}/category/${categoryId}/games?${query}`, body, { headers });
  };

  changeGamesDevice = (headers, siteId, categoryId, body) => {
    return http.put(`/site/${siteId}/category/${categoryId}/games/platform`, body, { headers });
  };

  deleteCategory = (headers, siteId, categoryId) => {
    return http.delete(`/site/${siteId}/category/${categoryId}`, { headers });
  };

  deleteCategoryGame = (headers, siteId, categoryId, gameId) => {
    return http.delete(`/site/${siteId}/category/${categoryId}/games/${gameId}`, { headers });
  };

  getAllGamesByCategoryId = (headers, siteId, categoryId, query = '') => {
    return http.get(
      `/site/${siteId}/category/${categoryId}/games/all?sortBy=order&sortDir=ASC${query}`,
      { headers },
    );
  };

  getGameOfDay = (headers, siteId) => {
    return http.get(`/site/${siteId}/game-of-day`, { headers });
  };

  updateGameOfDay = (headers, siteId, body) => {
    return http.post(`/site/${siteId}/game-of-day`, body, { headers });
  };

  getAllCategoryBySiteId = (headers, siteId) => {
    return http.get(`/site/${siteId}/category/all?sortBy=order&sortDir=ASC&pageSize=50`, { headers });
  };

  saveSiteCategoryGames = async (headers, siteId, categoryId, body) => {
    const { data } = await http.post(`/site/${siteId}/category/${categoryId}/games/order`, body, {
      headers,
    });
    return data;
  };

  saveSiteCategories = async (headers, siteId, body) => {
    const { data } = await http.post(`/site/${siteId}/category/order`, body, { headers });
    return data;
  };

  getAboutUsForSite = (headers, siteId) => {
    return http.get(`/site/${siteId}/about`, { headers });
  };

  updateAboutUsForSite = (headers, siteId, body) => {
    return http.put(`/site/${siteId}/about`, body, { headers });
  };

  getSitesSupportForSite = (headers, siteId) => {
    return http.get(`/site/${siteId}/sitesSupport`, { headers });
  };

  updateSitesSupportForSite = (headers, siteId, body) => {
    return http.put(`/site/${siteId}/sitesSupport`, body, { headers });
  };

  getSiteSettings = (headers) => {
    return http.get(`/site/info`, { headers });
  };

  getSiteBonuses = (headers, siteId, query = '') => {
    return http.get(`/site/${siteId}/bonuses?${query}`, { headers });
  };

  getSitesAllUsersBonuses= (headers, siteId, query = '') => {
    return http.get(`/site/${siteId}/user-bonuses?${query}`, { headers });
  };

  getSiteBonusById = (headers, siteId, bonusId) => {
    return http.get(`/site/${siteId}/bonuses/${bonusId}`, { headers });
  };

  createSiteBonus = (headers, siteId, body) => {
    return http.post(`/site/${siteId}/bonuses`, body, { headers });
  };

  editSiteBonus = (headers, siteId, bonusId, body) => {
    return http.put(`/site/${siteId}/bonuses/${bonusId}`, body, { headers });
  };

  deleteBonus = (headers, siteId, bonusId) => {
    return http.delete(`/site/${siteId}/bonuses/${bonusId}`, { headers });
  };

  uploadImageOrBannerImageForBonus = (headers, siteId, bonusId, imageType, body) => {
    return http.put(`/site/${siteId}/bonuses/${bonusId}/image/${imageType}`, body, { headers });
  };

  getSiteStatistics = (headers, siteId, query = '') => {
    return http.get(`/site/${siteId}/statistics${query}`, { headers });
  };

  getAllPayInStatistics = (headers, siteId, query = '') => {
    return http.get(`/site/${siteId}/statistics/pay-in-transactions?sortBy=amount&sortDir=DESC&${query}`, { headers });
  };

  getAllPayOutStatistics = (headers, siteId, query = '') => {
    return http.get(`/site/${siteId}/statistics/pay-out-transactions?sortBy=amount&sortDir=DESC&${query}`, { headers });
  };

  getCashbackLists = (headers, siteId, query = '') => {
    return http.get(`/site/${siteId}/cashback?${query}`, { headers });
  };
}

const SiteService = new SiteDataService();

export default SiteService;
