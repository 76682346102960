import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { useEffect, useState } from 'react';
import { IActiveItem } from 'components/pageLayouts/gamblingSystems/statistics/gamblingStatistics';
import DepositsTable from 'components/tables/client/depositsTable';
import WithdrawTable from 'components/tables/client/withdrawTable';
import { BalanceSettings } from './financeSettings/balance/balanceSettings';
import { ProfitFromClient } from './financeSettings/profit/profitFromClient';
import { useTranslation } from 'react-i18next';
import { tabPermissionsHorizontal } from 'permissions/tabs';
import { useSelector } from 'react-redux';

interface ClientFinancesProps {
  activeItem?: IActiveItem;
  clientId: string;
}

const style = {
  overflow: 'hidden',
  overflowY: 'auto',
  overflowX: 'hidden',
  p: 0,
  paddingTop: 3,
  width: 'inherit',
};

export const ClientFinances = ({ activeItem, clientId }: ClientFinancesProps) => {
  const { t: clientDetailsT } = useTranslation('clientsDetails');
  const [value, setValue] = useState('1');

  const { role: mainRole } = useSelector((state: any) => state.siteRole) as { role: '' };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue('1');
  }, [activeItem]);

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {tabPermissionsHorizontal.finance.map((el, index) => {
              if (el.role?.includes(mainRole) || el.role?.[0] === '*') {
                return <Tab key={index} label={clientDetailsT(el.name)} value={`${index + 1}`} />;
              }
            })}
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ ...style }}>
          <BalanceSettings />
        </TabPanel>
        <TabPanel value="2">
          <DepositsTable clientId={clientId} />
        </TabPanel>
        <TabPanel value="3">
          <WithdrawTable clientId={clientId} />
        </TabPanel>
        <TabPanel value="4">
          <ProfitFromClient clientId={clientId} title={clientDetailsT('profitFromClient')} />
        </TabPanel>
        
      </TabContext>
    </Box>
  );
};
