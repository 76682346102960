import { useStorage } from 'hooks/authHooks/useStorage';
import { useApiErrors } from 'hooks/errors/useApiErrors';
import { useState } from 'react';
import FinanceService from 'services/finance.service';
import { generateHeaders } from 'utils/utils';

export const usePayInPayOut = () => {
  const [totalPages, setTotalPages] = useState<number>(1);
  const { logout, userToken } = useStorage();
  const { errorHandler } = useApiErrors();
  const [dataList, setDataList] = useState([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const initHeaders = generateHeaders(userToken());

  const getAllPayIns: any = async (siteId: string, query: string, headers = initHeaders) => {
    try {
      setIsLoading(true);

      const {
        data: {
          data: { sitePayInMethods, totalPages, totalItems },
        },
      } = await FinanceService.getAllPayIns(headers, siteId, query);
      setTotalPages(totalPages);
      setDataList(sitePayInMethods);
      setTotalItems(totalItems);
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};

      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getAllPayIns(siteId, query, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };


  const getAllPayOuts: any = async (siteId: string, query: string, headers = initHeaders) => {
    try {
      setIsLoading(true);

      const {
        data: {
          data: { sitePayOutMethods, totalPages, totalItems },
        },
      } = await FinanceService.getAllPayOuts(headers, siteId, query);

      setTotalPages(totalPages);
      setDataList(sitePayOutMethods);
      setTotalItems(totalItems);
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};

      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getAllPayOuts(siteId, query, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const updatePayInMinMax: any = async (
    siteId: string,
    id: string,
    body: any,
    headers = initHeaders,
  ) => {
    try {
      setIsLoading(true);

      const {
        data: {
          data: { updatedSettings },
          status,
        },
      } = await FinanceService.updatePayInMinMax(headers, siteId, id, body);
      return { data: updatedSettings, status };
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};

      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await updatePayInMinMax(siteId, id, body, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };


  const updatePayOutMinMax: any = async (
    siteId: string,
    id: string,
    body: any,
    headers = initHeaders,
  ) => {
    try {
      setIsLoading(true);

      const {
        data: {
          data: { updatedSettings },
          status,
        },
      } = await FinanceService.updatePayOutMinMax(headers, siteId, id, body);
      return { data: updatedSettings, status };
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};

      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await updatePayOutMinMax(siteId, id, body, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const updateImage: any = async (
    siteId: string,
    id: string,
    body: any,
    headers = { ...initHeaders, 'Content-Type': 'multipart/form-data' },
  ) => {
    setIsLoading(true);

    try {
      const {
        data: { data, status },
      } = await FinanceService.updateImageForPaymentMethod(headers, id, body);
      if (status === 200) {
        return { data, status };
      }
    } catch (err: any) {
      const {
        responce: {
          data: { message, status },
        },
      } = err;
      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await updateImage(siteId, id, body, {
          ...newHeader,
          'Content-Type': 'multipart/form-data',
        });
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const updateImagePayOut: any = async (
    siteId: string,
    id: string,
    body: any,
    headers = { ...initHeaders, 'Content-Type': 'multipart/form-data' },
  ) => {
    setIsLoading(true);

    try {
      const {
        data: { data, status },
      } = await FinanceService.updateImageForPaymentMethodPayOut(headers, id, body);
      if (status === 200) {
        return { data, status };
      }
    } catch (err: any) {
      const {
        responce: {
          data: { message, status },
        },
      } = err;
      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await updateImagePayOut(siteId, id, body, {
          ...newHeader,
          'Content-Type': 'multipart/form-data',
        });
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    updateImage,
    getAllPayIns,
    getAllPayOuts,
    updatePayInMinMax,
    updatePayOutMinMax,
    updateImagePayOut,
    totalItems,
    error,
    totalPages,
    dataList,
  };
};
