import http from '../http-common';

class WheelsDataService {
  createWheelsGroup = async (headers, body) => {
    return await http.post(`wheel/settings/groups`, body, { headers });
  };

  getAllWheelsGroups = (headers) => {
    return http.get(`/wheel/settings/groups`, { headers });
  };

  getSiteWheelSetting = (headers, siteId)=> {
    return http.get(`/wheel/settings?siteId=${siteId}`, { headers });
  }

  saveWheelsSetting = async (headers, body) => {
    return await http.post(`wheel/settings`, body, { headers });
  };
}

const WheelsService = new WheelsDataService();

export default WheelsService;
