import { Box, Button, TablePagination } from '@mui/material';
import { useProviders } from 'hooks/providers/useProviders';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GridValueGetterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import FilterField from 'components/filter/columnFilter';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import { columnsName, ROW_PER_PAGE } from 'constants/common';
import { IFilterData } from 'types/accounts';
import { isEmpty, makeQueryUrl } from 'utils/utils';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setResetAll } from 'redux/actions/commonActions';
import styles from 'components/tables/table.module.scss';

const prepareData = (rowData: any) => {
  const result = [];
  for (const key in rowData) {
    result.push({ [key]: rowData[key] });
  }
  return result;
};

export const rendermetaCell = (data: any) => {
  const cellData = prepareData(data);
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, minHeight: '50px' }}>
      {cellData.length ? (
        cellData.map((el: any, index: number) => {
          return <Box key={index}>{JSON.stringify(el)}</Box>;
        })
      ) : (
        <Box>-</Box>
      )}
    </Box>
  );
};

export default function GameLogs() {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const params = useParams();
  const { getGameLogs, totalItems, pageLists, columns: columnNames } = useProviders(false);
  const [logsList, selLogsList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROW_PER_PAGE);
  const [filterData, setFilterData] = useState<IFilterData | object>({});
  const [sortData, setSortData] = useState<IFilterData | object>({});

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleResetAll = () => {
    dispatch(setResetAll(true));
    setFilterData({});
    setSortData({});
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (newPage >= +pageLists[pageLists.length - 1].val) {
      setPage(+pageLists[pageLists.length - 1].val);
    } else {
      setPage(newPage);
    }
  };

  const handleFilter = (name: string, value: string) => {
    setFilterData((filterby) => ({ ...filterby, [name]: value }));
  };

  const handleSortBy = (sortBy: string, sortDir: string) => {
    setSortData({ [sortBy]: sortDir });
  };

  const getLogs = async (id: string, query: string) => {
    const result = await getGameLogs(id, query);
    if (result) {
      selLogsList(result.data);
    }
  };

  const generateDinamicColumns = useMemo(() => {
    const columns = columnNames.map((el) => {
      if (el === columnsName.CREATED_AT) {
        return {
          field: el,
          width: Math.ceil(window.innerWidth / (columnNames.length + 1)),
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isCamelCase
            />
          ),
          valueGetter: (params: GridValueGetterParams) =>
            `${moment(params.row.createdAt).format('DD/MM/Y HH:mm:ss')}`,
        };
      } else if (el === columnsName.META_DATA) {
        return {
          field: el,
          width: Math.ceil(window.innerWidth / (columnNames.length + 1)),
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isCamelCase
              isDisabled
            />
          ),
          renderCell: (params: GridValueGetterParams) => rendermetaCell(params.row.metadata),
        };
      }
      return {
        field: el,
        width:
          el === columnsName.ID
            ? 100
            : el === 'denomination' || el === 'balanceType'
            ? 150
            : Math.ceil(window.innerWidth / (columnNames.length + 2)),
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => (
          <FilterField
            fieldName={el}
            onBlur={(value) => handleFilter(el, value)}
            onSort={handleSortBy}
            sortData={sortData}
            isCamelCase
          />
        ),
        valueGetter: (params: GridValueGetterParams): any =>
          el === 'site' ? `${params.row[el].name}` : `${params.row[el]}`,
      };
    });
    return [...columns];
  }, [columnNames, sortData]);

  const columns = generateDinamicColumns as GridColDef[];

  useEffect(() => {
    if (params.sessionId) {
      const query = makeQueryUrl(filterData);
      const sortBy = Object.entries(sortData)[0];
      let paramsQuery = isEmpty(sortData)
        ? query
        : `${query}&sortBy=${sortBy[0]}&sortDir=${sortBy[1]}`;
      paramsQuery += `&page=${page + 1}&pageSize=${rowsPerPage}`;
      getLogs(params.sessionId, paramsQuery);
    }
  }, [filterData, sortData, page, rowsPerPage, params.sessionId]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box className={styles.filter_box}>
        <div className={styles.filter_btn}>
          <Box sx={{ display: 'flex', mt: 2, mb: 2 }}>
            {Object.values(filterData).join() || Object.values(sortData).join() ? (
              <Button className={styles.reset_all_btn} variant="contained" onClick={handleResetAll}>
                {t('ressetAll')}
              </Button>
            ) : null}
          </Box>
        </div>
      </Box>
      <TablePagination
        component="div"
        count={totalItems}
        page={page}
        labelRowsPerPage={t('rowsPerPage')}
        labelDisplayedRows={({ from, to, count }) =>
          `${from >= to ? to : from}-${to} ${t('of')} ${count}`
        }
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <DataGrid
        autoHeight
        getRowHeight={() => 'auto'}
        rows={logsList}
        columns={columns}
        hideFooterPagination={true}
        sx={{
          '.MuiDataGrid-iconButtonContainer': {
            visibility: 'visible',
          },
          '.MuiDataGrid-sortIcon': {
            opacity: 'inherit !important',
          },
          '.MuiDataGrid-cell': {
            '&:last-child': {
              justifyContent: 'center !important',
            },
          },
          '.MuiDataGrid-footerContainer': {
            display: 'none',
          },
        }}
      />
    </Box>
  );
}
