// import { any } from '@mui/x-data-grid/models/params/gridCellParams';
import FilterField from 'components/filter/columnFilter';
import { useClients } from 'hooks/clients/useClients';
import { useEffect, useMemo, useState } from 'react';
import { IFilterData } from 'types/accounts';
import { columnsName, common, ROW_PER_PAGE } from 'constants/common';
import { dateFormat, isEmpty, makeQueryUrl } from 'utils/utils';
import SitesList from 'components/filter/siteLists';
import {
  Box,
  Button,
  Link,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TablePagination,
} from '@mui/material';
// import DropDownSelect from 'components/select/select';
import { useDispatch, useSelector } from 'react-redux';
import { setResetAll } from 'redux/actions/commonActions';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLabels } from 'hooks/clients/useLabels';
import CurrencyList from 'components/filter/currencyLists';
import TransactionList from 'components/filter/trasactionLists';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import styles from '../table.module.scss';

export default function ClientsTable() {
  const { t } = useTranslation('common');
  const { t: clientsT } = useTranslation('clients');
  const { t: clientDetailsT } = useTranslation('clientsDetails');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { columns: columnNames, pageLists, getClientsData, totalItems, isLoading } = useClients();
  const { getLabelsList, allLabels } = useLabels();
  const [page, setPage] = useState(0);
  const [resetGoToPage, setResetGoToPage] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(ROW_PER_PAGE);

  const clietsList = useSelector<any>((state) => state.clients) as {
    clients: any[];
  };

  const { clients: data } = clietsList;
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedLabel, setSelectedLabel] = useState('');
  const [filterData, setFilterData] = useState<IFilterData | object>({});
  const [sortData, setSortData] = useState<IFilterData | object>({ createdAt: 'DESC' });
  const [isVerified, setIsVerified] = useState(false);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (newPage >= +pageLists[pageLists.length - 1].val) {
      setPage(+pageLists[pageLists.length - 1].val);
    } else {
      setPage(newPage);
    }
    setResetGoToPage((prev) => !prev);
  };

  const handleFilter = (name: string, value: string) => {
    setFilterData((filterby) => ({ ...filterby, [name]: value }));
    setPage(0);
  };

  const handleSortBy = (sortBy: string, sortDir: string) => {
    setSortData({ [sortBy]: sortDir });
  };

  const handleResetAll = () => {
    dispatch(setResetAll(true));
    setFilterData({});
    setSortData({});
    setSelectedStatus('');
    setSelectedLabel('');
    setIsVerified(false);
  };

  const handleSelectPage = (page: string) => {
    setPage(+page - 1);
  };

  const handleOpenClients = () => {
    navigate('/labels');
  };

  const actionFields = [
    {
      headerName: '',
      field: 'actions',
      width: 120,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: any) => (
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            window.open(`/clients/details/${params?.row?.id}`, '_blank');
          }}
        >
          {clientsT('moreDetails')}
        </Button>
      ),
    },
  ];

  const generateDinamicColumns = useMemo(() => {
    const columns = columnNames.map((el) => {
      if (el === columnsName.CREATED_AT) {
        return {
          field: el,
          width: Math.ceil(window.innerWidth / (columnNames.length + 1)),
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isCamelCase
            />
          ),
          valueGetter: (params: string) => dateFormat(params),
        };
      } else if (el === 'isBlocked') {
        return {
          field: el,
          width: 120,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) =>
                handleFilter(
                  el,
                  value === t(common.YES)
                    ? common.TRUE
                    : value === t(common.NO)
                    ? common.FALSE
                    : value,
                )
              }
              onSort={handleSortBy}
              sortData={sortData}
              isCamelCase
            />
          ),
          valueGetter: (params: any) => `${params ? t(common.YES) : t(common.NO)}`,
        };
      } else if (el == columnsName.LOGIN) {
        return {
          field: el,
          width: Math.ceil(window.innerWidth / (columnNames.length + 1)),
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isCamelCase
            />
          ),
          renderCell: (params: any) => (
            <Link
              color={'text.primary'}
              component="a"
              target="blank"
              onClick={() => {
                window.open(`/clients/details/${params?.row?.id}`);
              }}
            >
              {params?.row?.login}
            </Link>
          ),
        };
      } else if (el === 'balances') {
        return {
          field: el,
          minWidth: 140,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isDisabled
              isCamelCase
            />
          ),
          renderCell: (params: any) => {
            return (
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: 'auto',
                  minWidth: '130px',
                  padding: '4px',
                }}
              >
                <Box style={{ display: 'flex', minWidth: '130px' }}>
                  {`Wallet: ${
                    params?.value.filter((el: any) => el.type === 'wallet')?.[0]?.amount
                  } `}
                </Box>
                <Box style={{ display: 'flex', minWidth: '130px' }}>
                  {`Game: ${params?.value.filter((el: any) => el.type === 'game')?.[0]?.amount} `}
                </Box>
                <Box style={{ display: 'flex', minWidth: '130px' }}>
                  {`Bonus: ${params?.value.filter((el: any) => el.type === 'bonus')?.[0]?.amount} `}
                </Box>
              </Box>
            );
          },
        };
      }
      return {
        field: el,
        width:
          el === columnsName.ID
            ? 50
            : el === 'site'
            ? 120
            : Math.ceil(window.innerWidth / (columnNames.length + 2)),
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => (
          <FilterField
            fieldName={el}
            onBlur={(value) => handleFilter(el, value)}
            onSort={handleSortBy}
            sortData={sortData}
            isCamelCase
          />
        ),
        valueGetter: (params: any): any =>
          el === 'site' ? `${params?.name}` : el === 'phone' && params === null ? '' : params,
      };
    });

    if (columns.length) {
      return [...columns, ...actionFields];
    }
    return [...columns];
  }, [columnNames, sortData]);

  const columns = generateDinamicColumns as GridColDef[];

  const handleLabelChange = (e: SelectChangeEvent) => {
    const { value } = e.target;
    setFilterData((filterby) => ({ ...filterby, labelId: value }));
    setSelectedLabel(value);
  };

  const handleIsVerifiedStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked;

    value
      ? setFilterData((filterby) => ({ ...filterby, isVerificationPassed: value }))
      : setFilterData((filterby) => ({ ...filterby, isVerificationPassed: '' }));
    setIsVerified(value);
  };

  const handleStatusChange = (e: SelectChangeEvent) => {
    const { value } = e.target;
    if (value) {
      setFilterData((filterby) => ({ ...filterby, isBlocked: +value === 1 }));
    } else {
      setFilterData((filterby) => ({ ...filterby, isBlocked: '' }));
    }
    setSelectedStatus(value);
  };

  useEffect(() => {
    if (!isEmpty(filterData)) {
      const query = makeQueryUrl(filterData, 'siteId');
      const sortBy = Object.entries(sortData)[0];

      let paramsQuery = isEmpty(sortData)
        ? query
        : `${query}&sortBy=${sortBy[0]}&sortDir=${sortBy[1]}`;

      paramsQuery += `&page=${page + 1}&pageSize=${rowsPerPage}`;

      getClientsData(paramsQuery);
    }
  }, [filterData, sortData, page, rowsPerPage]);

  useEffect(() => {
    if (!isLoading && !allLabels.length) getLabelsList();
  }, [isLoading]);

  return (
    <div style={{ width: '100%' }}>
      <Box className={styles.filter_box}>
        <div className={styles.filter_btn}>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Button variant="contained" onClick={handleOpenClients}>
              {clientsT('clientLabels')}
            </Button>
          </Box>
          <Box sx={{ display: 'flex' }}>
            {Object.values(filterData).join() || Object.values(sortData).join() ? (
              <Button
                className={styles.reset_all_btn}
                sx={{ mb: 2 }}
                variant="contained"
                onClick={handleResetAll}
              >
                {t('ressetAll')}
              </Button>
            ) : null}
          </Box>
        </div>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ mr: 1 }}>
          <SitesList onSelectSite={handleFilter} initValue={isEmpty(filterData)} />
        </Box>
        <Box sx={{ minWidth: 100, mr: 1 }}>
          <CurrencyList onSelectSite={handleFilter} />
        </Box>
        <Box sx={{ minWidth: 140, mr: 1 }}>
          <FormControl fullWidth size="small">
            <InputLabel id="client-status-label">{clientsT('clientStatus')}</InputLabel>
            <Select
              labelId="client-status-label"
              id="status-select"
              label={clientsT('clientStatus')}
              value={selectedStatus}
              onChange={handleStatusChange}
            >
              <MenuItem value="">
                <em>{clientsT('none')}</em>
              </MenuItem>
              {[
                { id: 1, name: `${clientDetailsT('blocked')}` },
                { id: 2, name: `${clientDetailsT('active')}` },
              ].map((item, index) => {
                return (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ minWidth: 150, mr: 1 }}>
          <FormControl fullWidth size="small">
            <InputLabel id="client-labels">{clientsT('clientLabels')}</InputLabel>
            <Select
              labelId="client-labels"
              id="label-select"
              label={clientsT('clientLabels')}
              value={selectedLabel}
              onChange={handleLabelChange}
            >
              <MenuItem value="">
                <em>{clientsT('none')}</em>
              </MenuItem>
              {allLabels.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        {/* <Box sx={{ minWidth: 100, mr: 1 }}>
          <TransactionList onSelectSite={handleFilter} initValue={isEmpty(filterData)} />
        </Box> */}
        <Box sx={{ minWidth: 100, mr: 1 }}>
          <FormControlLabel
            value="start"
            control={
              <Checkbox
                checked={isVerified}
                onChange={handleIsVerifiedStatusChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={clientsT('isVerified')}
            labelPlacement="start"
          />
        </Box>
      </Box>
      {/* <Box sx={{ float: 'right' }}>
        <DropDownSelect
          totalPages={pageLists}
          onSelectPage={handleSelectPage}
          reset={resetGoToPage}
        />
      </Box> */}
      <TablePagination
        component="div"
        count={totalItems}
        page={page}
        labelRowsPerPage={t('rowsPerPage')}
        labelDisplayedRows={({ from, to, count }) =>
          `${from >= to ? to : from}-${to} ${t('of')} ${count}`
        }
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <DataGridPro
        getRowHeight={() => 'auto'}
        rows={data}
        columns={columns}
        hideFooterPagination={true}
        getRowClassName={() => styles.custom_row}
        initialState={{
          columns: {
            columnVisibilityModel: {
              // Hide column currency, the other columns will remain visible
              currency: false,
              password: false,
            },
          },
        }}
        sx={{
          '.MuiDataGrid-iconButtonContainer': {
            visibility: 'visible',
          },
          '.MuiDataGrid-sortIcon': {
            opacity: 'inherit !important',
          },
          '.MuiDataGrid-cell': {
            display: 'flex',
            alignItems: 'center',
            '&:last-child': {
              justifyContent: 'center !important',
            },
          },
          '.MuiDataGrid-footerContainer': {
            display: 'none',
          },
        }}
      />
      <TablePagination
        component="div"
        rowsPerPageOptions={[]}
        count={totalItems}
        page={page}
        labelRowsPerPage={t('rowsPerPage')}
        labelDisplayedRows={({ from, to, count }) =>
          `${from >= to ? to : from}-${to} ${t('of')} ${count}`
        }
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
      />
    </div>
  );
}
