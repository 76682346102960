import { isArray } from 'lodash';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export const isEmpty = (obj) => {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const generateHeaders = (token, headers = {}) => {
  return { ...headers, Authorization: `Bearer ${token}` };
};

export const joinMessage = (messageArr = []) => {
  let res = '';
  if (isArray(messageArr)) {
    for (let el of messageArr) {
      if (el.message !== undefined) {
        res += ', ' + el.message;
      } else {
        res += ', ' + el;
      }
    }
  }
  return res.replace(/^. /, '');
};

export const makeQueryUrl = (filterData, escapePropName = '') => {
  let queryString = '';
  for (const el in filterData) {
    if (filterData[el] !== '' && el !== escapePropName) {
      queryString += `&${el}=${filterData[el]}`;
    }
  }
  return queryString ? queryString.replace(/^. /, '') : '';
};

export const hasEmptyProps = (objData) => {
  return !!Object.values(objData).filter((el) => el === '').length;
};

export const dateFormat = (data) => {
  if (data) {
    return dayjs(data).tz("Asia/Yerevan").format("YYYY-MM-DD HH:mm:ss");
  }
  return '_';
};
