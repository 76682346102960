import dayjs, { Dayjs } from 'dayjs';
import { Box } from '@mui/system';
import { sitesSettings } from 'constants/common';
import SitesList from 'components/filter/siteLists';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useRef, useState } from 'react';
import { useSites } from 'hooks/sites/useSites';
import StatisticsResult from './statisticsResult/statisticsResult';
import DateTimePickerValue from 'components/datePicker/dateTimeRangePicker';
import LoaderWithBg from 'components/loader/loader';

export interface IStatisticSite {
  profit: number;
  whiteProfit: number;
  activeUsers: number;
  playedGames: number;
  cashbackSum: number;
  newUsers: number;
  financeProviders: any[];
  gameProviders: any[];
  balance: number;
  payOutAmount: number;
  payInAmount: number;
  promocodes: any;
  forAmount?: number;
  bonusesAmount?: number;
  siteFinanceFeeAmount: number;
  siteGamesFeeAmount: number;
}

export default function Statistics() {
  const [timeData, setTimeData] = useState<any>(null);
  const [siteId, setSiteId] = useState('');
  const [siteStatistics, setSiteStatistics] = useState<IStatisticSite | null>(null);
  const [isValidDateValue, setIsValidDateValue] = useState(true);
  const [isFinding, setIsFinding] = useState(false);
  const { getSiteStatistics } = useSites();
  const { t } = useTranslation('common');
  const dateRef = useRef({
    from: null,
    to: null,
  });
  const handleOnShow = (valueFrom: Dayjs | null, valueTo: Dayjs | null) => {
    if (valueFrom && valueTo) {
      const data = { from: valueFrom, to: valueTo };
      setTimeData(data);
    }
  };

  const handleFilter = (name: string, value: string) => {
    !value ? (setSiteStatistics(null), setSiteId(value)) : setSiteId(value);
  };

  const handleOnFind = async () => {
    let query;
    setIsFinding(true);
    if (isValidDateValue) {
      query = '?' + new URLSearchParams(timeData).toString();
      const data = await getSiteStatistics(siteId, query);
      dateRef.current = { ...timeData };
      if (data) {
        setSiteStatistics(data);
        setIsFinding(false);
      }
    }
  };

  const isValidDateChanges = (value: boolean) => {
    setIsValidDateValue(value);
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <SitesList margin="4px 24px 24px 0" onSelectSite={handleFilter} />
        <DateTimePickerValue
          isValidChanges={isValidDateChanges}
          isSmall="small"
          onShow={handleOnShow}
          needDefaultDate
          dateFrom={dayjs().hour(9).minute(0).second(0).millisecond(0)}
          dateTo={dayjs().add(1, 'day').hour(9).minute(0).second(0).millisecond(0)}
        />
        <Button
          variant="contained"
          sx={{ ml: 3, mt: 1, height: '40px', alignContent: 'center' }}
          disabled={!siteId || !isValidDateValue}
          onClick={handleOnFind}
        >
          {t(sitesSettings.SHOW_STATISTICS)}
        </Button>
      </Box>
      {siteStatistics && (
        <StatisticsResult
          siteId={siteId}
          timeData={dateRef.current}
          siteStatistics={siteStatistics}
        />
      )}
      {isFinding ? <LoaderWithBg isOpen={isFinding} /> : null}
    </>
  );
}
