import http from '../http-common';

class LabelDataService {

  createLabel = async (headers, body) => {
    const { data: { data }, status } = await http.post(`/user-label/`, body, { headers });
    return { ...data, status };
  };

  getAllLabels = (headers, query = '') => {
    return http.get(`/user-label/all/?${query}`, { headers });
  };

  getLabelById = (headers, id) => {
    return http.get(`/user-label/${id}`, { headers });
  };

  editLabel = (headers, id, body) => {
    return http.put(`/user-label/${id}`, body, { headers });
  };

  deleteLabel = (headers, id) => {
    return http.delete(`/user-label/${id}`, { headers });
  };

  getLabelsList = (headers) => {
    return http.get(`/users/labels`, { headers });
  }

}

const LabelService = new LabelDataService();

export default LabelService;
