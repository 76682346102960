import http from '../http-common';

class MessageDataService {
 
  createUserMessage = async (headers, id, body) => {
    const { data:{data}, status } = await http.post(`/users/${id}/message`, body, {headers});
    return {...data, status};
  };

  getUserAllMessages = (headers, id, query='') => {
    return http.get(`/users/${id}/message/all/?${query}`, { headers });
  };

  updateUserMessage = (headers, id, messageId, body) => {
    return http.put(`/users/${id}/message/${messageId}`, body, { headers });
  };

  deleteUserMessage = (headers, id, messageId) => {
    return http.delete(`/users/${id}/message/${messageId}`, { headers });
  };
  
}

const MessageService = new MessageDataService();

export default MessageService;
