import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ISites } from 'types/sites';

interface SelectDropDownProps {
  onSelect: (name: string, value: string) => void;
  isEdit?: boolean;
  initValue?: string;
  width?: number | string;
  margin?: string;
  label: string;
  filtrBy: string;
  list: any;
  itemKey: string;
  withTranslation?: boolean;
  defaultValueId?: string;
}

export default function SelectDropDown({
  onSelect,
  list,
  isEdit,
  margin,
  initValue,
  width,
  filtrBy,
  label,
  itemKey,
  withTranslation,
  defaultValueId,
}: SelectDropDownProps) {
  const { t } = useTranslation('common');
  const [data, setData] = useState(isEdit ? initValue : '');

  const handleChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setData(value);
    onSelect(filtrBy, value);
  };
  const customWidth = width !== undefined ? width : 160;
  const customMargin = margin !== undefined ? margin : '0 0 24px 0';

  useEffect(() => {
    if (initValue) setData('');
  }, [initValue]);

  useEffect(() => {
    if (defaultValueId) {
      setData(defaultValueId);
      onSelect(filtrBy, defaultValueId);
    }
  }, [defaultValueId]);

  return (
    <Box sx={{ width: customWidth, margin: customMargin }}>
      <FormControl fullWidth size="small">
        <InputLabel id={`${filtrBy}-label`}>{label}</InputLabel>
        <Select
          labelId={`${filtrBy}-label`}
          id={`${filtrBy}-select`}
          label={label}
          value={data}
          onChange={(e) => handleChange(e)}
        >
          <MenuItem value="">
            <em>{t('none')}</em>
          </MenuItem>
          {list?.map((item: any, index: number) => {
            return (
              <MenuItem key={index} value={item.id}>
                {withTranslation ? t(item[itemKey]) : item[itemKey]}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}
