import { actionTypes } from 'redux/constants/actionTypes';

const initial = {
  accounts: [
    {
      id: '',
      login: '',
      password: '',
      phone: '',
      email: '',
      role: '',
      siteIds: [],
    },
  ],
};

export const accountsReducer = (state = initial, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_ACCOUNTS_DATA:
      return { ...state, accounts: payload ?? [] };
    case actionTypes.ADD_ACCOUNT_DATA:
      return { ...state, accounts: [payload, ...state.accounts] };
    default:
      return state;
  }
};
