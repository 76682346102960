import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { ArrowCircleLeftOutlined, ArrowCircleRightOutlined } from '@mui/icons-material';
import { columnsName, common } from 'constants/common';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './columnFilter.module.scss';

interface ColumnFilterProps {
  fieldName: string;
  onBlur?: (filterValue: string) => void;
  onSort?: (fieldName: string, sortDir: string) => void;
  sortData?: object;
  isDisabled?: boolean;
  isCamelCase?: boolean;
  style?: object;
}
const detectFieldSorting = (fieldName: string, sortData?: object) => {
  if (sortData) {
    if (fieldName === 'ACTIVE') {
      return Object.keys(sortData).includes('isActive');
    }
    if (fieldName === 'CREATED AT') {
      return Object.keys(sortData).includes('createdAt');
    }
    return Object.keys(sortData).includes(fieldName.toLowerCase());
  }
};

export default function ColumnFilter({
  fieldName,
  sortData,
  onBlur,
  onSort,
  isDisabled,
  isCamelCase,
  style,
}: ColumnFilterProps) {
  const [inputValue, setInputValue] = useState<string>('');
  const { t } = useTranslation('columns');
  const [isAsc, setSortBy] = useState<boolean | null>(null);
  const resetAll = useSelector<any>((state) => state.resetAll);
  const isCurrentFieldSorted = detectFieldSorting(fieldName, sortData);

  useEffect(() => {
    if (resetAll) {
      setInputValue('');
      setSortBy(null);
    }
  }, [resetAll]);

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;
    setInputValue(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      onBlur?.(inputValue);
    }
  };

  const handleOnBlur = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;
    onBlur?.(value);
  };

  const handleOnClick = () => {
    setSortBy((prev) => !prev);
  };

  useEffect(() => {
    if (isAsc !== null) {
      const sortDir = isAsc ? common.ASC : common.DESC;
      const sortBy =
        fieldName.toLocaleUpperCase() === columnsName.CREATED.toLocaleUpperCase()
          ? columnsName.CREATED_AT
          : fieldName.toLocaleUpperCase() === columnsName.ACTIVE.toLocaleUpperCase()
          ? columnsName.IS_ACTIVE
          : fieldName.toLocaleUpperCase() === columnsName.BLOCKED.toLocaleUpperCase()
          ? columnsName.IS_BLOCKED
          : isCamelCase
          ? fieldName
          : fieldName.toLocaleLowerCase();
      onSort?.(sortBy, sortDir);
    }
  }, [isAsc]);

  const renderSortIcon = (isAsc: boolean | null, isCurrentFieldSorted: boolean | undefined) => {
    if (isAsc && isCurrentFieldSorted) {
      return (
        <ArrowCircleLeftOutlined
          onClick={handleOnClick}
          className={cx(styles.asc, { [styles.sorted]: isCurrentFieldSorted })}
        />
      );
    }

    return <ArrowCircleRightOutlined onClick={handleOnClick} className={cx(styles.desc)} />;
  };

  const inlineStyle = style
    ? { background: 'none', opacity: 1, width: 'auto', ...style }
    : { background: 'none', opacity: 1, width: 'auto' };
  return (
    <>
      {!isDisabled ? renderSortIcon(isAsc, isCurrentFieldSorted) : null}
      <TextField
        className={styles.formFilter}
        disabled={isDisabled}
        fullWidth
        name={t(fieldName)}
        label={t(fieldName)}
        value={inputValue}
        onKeyDown={(e) => handleKeyDown(e)}
        onChange={(e) => handleOnChange(e)}
        onBlur={(e) => handleOnBlur(e)}
        variant="filled"
        size="small"
        InputProps={{
          disableUnderline: true,
          style: { ...inlineStyle },
        }}
        title={t(fieldName)}
      />
    </>
  );
}
