import { GridValueGetterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import FilterField from 'components/filter/columnFilter';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { IFilterData } from 'types/accounts';
import { columnsName, ROW_PER_PAGE } from 'constants/common';
import common from 'styles/common.module.scss';
import { Box, Button, TablePagination, Typography } from '@mui/material';
import DropDownSelect from 'components/select/select';
import LoaderWithBg from 'components/loader/loader';
import { useTransactions } from 'hooks/clients/useTransactions';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { dateFormat, makeQueryUrl } from 'utils/utils';
import { useDispatch } from 'react-redux';
import { setResetAll } from 'redux/actions/commonActions';
import styles from '../table.module.scss';

interface TransactionsTableProps {
  clientId: string;
}

export default function TransactionsTable({ clientId }: TransactionsTableProps) {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const {
    isLoading,
    transactionsHistory,
    columns: columnNames,
    pageLists,
    getAllTransactions,
    totalItems,
  } = useTransactions();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [filterData, setFilterData] = useState<IFilterData | object>({});
  const [sortData, setSortData] = useState<IFilterData | object>({});

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleResetAll = () => {
    dispatch(setResetAll(true));
    setFilterData({});
    setSortData({});
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleFilter = (name: string, value: string) => {
    setFilterData((filterby) => ({ ...filterby, [name]: value }));
  };

  const handleSortBy = (sortBy: string, sortDir: string) => {
    setSortData({ [sortBy]: sortDir });
  };

  const handleSelectPage = (page: string) => {
    setPage(+page - 1);
  };

  useEffect(() => {
    const query = makeQueryUrl(filterData);
    const sortBy = Object.entries(sortData)[0];

    let paramsQuery = isEmpty(sortData)
      ? query
      : `${query}&sortBy=${sortBy[0]}&sortDir=${sortBy[1]}`;

    paramsQuery += `&page=${page + 1}&pageSize=${rowsPerPage}`;
    getAllTransactions(clientId, paramsQuery);
  }, [filterData, page, sortData, rowsPerPage]);

  const generateDinamicColumns = useMemo(() => {
    const columns = columnNames.map((el) => {
      if (el === columnsName.CREATED_AT) {
        return {
          field: el,
          width: el.length * 6 + 110,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isCamelCase
            />
          ),
          valueGetter: (params: GridValueGetterParams) => dateFormat(params.row.createdAt),
        };
      } else if (el === 'balanceDiff') {
        return {
          field: el,
          width: el.length * 6 + 110,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isDisabled
              isCamelCase
            />
          ),
          renderCell: (params: GridValueGetterParams) => {
            return (
              <Typography
                color={params.row.balanceDiff < 0 ? common.mainwarning : common.success}
                component="div"
              >
                {params.row.balanceDiff}
              </Typography>
            );
          },
        };
      } else if (el === 'info') {
        return {
          field: el,
          width: el.length * 6 + 200,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isDisabled
              isCamelCase
            />
          ),
          renderCell: (params: GridValueGetterParams) => {
            return (
              <Box
                style={{ minHeight: '60px', display: 'flex', alignItems: 'center' }}
                component="div"
              >
                {params?.row?.info?.includes('Withdraw') ? t('withdraw') : params?.row?.info ?? ''}
              </Box>
            );
          },
        };
      }

      return {
        field: el,
        width: el === columnsName.ID ? 110 : el.length * 6 + 140,
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => {
          return (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isDisabled
              isCamelCase
            />
          );
        },
      };
    });
    return [...columns];
  }, [columnNames, sortData]);

  const columns = generateDinamicColumns as GridColDef[];

  return (
    <div style={{ width: '100%' }}>
      {isLoading ? (
        <LoaderWithBg isOpen={isLoading} />
      ) : (
        <>
          <Box className={styles.filter_box}>
            <div className={styles.filter_btn}>
              <Box sx={{ display: 'flex', mt: 2, mb: 2 }}>
                <DropDownSelect totalPages={pageLists} onSelectPage={handleSelectPage} />
                {Object.values(filterData).join() || Object.values(sortData).join() ? (
                  <Button
                    className={styles.reset_all_btn}
                    variant="contained"
                    onClick={handleResetAll}
                  >
                    {t('ressetAll')}
                  </Button>
                ) : null}
              </Box>
            </div>
          </Box>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            labelRowsPerPage={t('rowsPerPage')}
            labelDisplayedRows={({ from, to, count }) =>
              `${from >= to ? to : from}-${to} ${t('of')} ${count}`
            }
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <DataGrid
            autoHeight
            rows={transactionsHistory}
            columns={columns}
            hideFooterPagination={true}
            getRowHeight={() => 'auto'}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  // Hide column currency, the other columns will remain visible
                  id: false,
                  userId: false,
                  user: false,
                },
              },
            }}
            getRowClassName={(params) =>
              params?.row?.info?.includes('[game]') ||
              params?.row?.info?.includes('[wallet]') ||
              params?.row?.info?.includes('[bonus]')
                ? styles.green_row
                : params?.row?.info?.includes('Game')
                ? styles.pink_row
                : params?.row?.info?.includes('Wheel Bonus') &&
                  !params?.row?.info?.includes('CANCELED')
                ? styles.orange_row
                : params?.row?.info?.includes('Bonus') && !params?.row?.info?.includes('CANCELED')
                ? styles.cyan_row
                : params?.row?.info?.includes('CANCELED')
                ? styles.red
                : ''
            }
            sx={{
              '.MuiDataGrid-iconButtonContainer': {
                visibility: 'visible',
              },
              '.MuiDataGrid-sortIcon': {
                opacity: 'inherit !important',
              },
              '.MuiDataGrid-cell': {
                '&:last-child': {
                  justifyContent: 'center !important',
                },
              },
              '.MuiDataGrid-footerContainer': {
                display: 'none',
              },
            }}
          />
          <TablePagination
            component="div"
            rowsPerPageOptions={[]}
            count={totalItems}
            page={page}
            labelRowsPerPage={t('rowsPerPage')}
            labelDisplayedRows={({ from, to, count }) =>
              `${from >= to ? to : from}-${to} ${t('of')} ${count}`
            }
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
          />
        </>
      )}
    </div>
  );
}
