import { useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import MainSettings from './mainSettings';
// import PaidSettings from './paidSetttings';
import { useTranslation } from 'react-i18next';

interface WheelSettingsProps {
  siteId: string;
}

function WheelSettings({ siteId }: WheelSettingsProps) {
  const { t: siteSettingsT } = useTranslation('siteSettings')
  const [value, setValue] = useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label={siteSettingsT('tabItemMainSettings')} value="1" />
              {/* <Tab label={siteSettingsT('tabItemWheelPaidSettings')} value="2" /> */}
            </TabList>
          </Box>
          <TabPanel value="1">
            <MainSettings siteId={siteId}/>
          </TabPanel>
          {/* <TabPanel value="2">
            <PaidSettings />
          </TabPanel> */}
        </TabContext>
      </Box>
    </>
  );
}

export default WheelSettings;
