import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import CreateAccount from 'components/createAccount/createAccount';
import { Box, useTheme } from '@mui/material';
import { roleTypes } from 'types/accounts';
import { IconClose } from 'components/closeButton/closeButton';
import { useTranslation } from 'react-i18next';
import styles from './modal.module.scss';
import { style } from './modal';

interface AccountModalProps {
  role: roleTypes | '';
}

export default function AccountModal({ role }: AccountModalProps) {
  const { t: accountRolesT } = useTranslation('accountRoles');
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={styles.btn_add}>
      <Button sx={{ mt: 2, mb: 3 }} variant="contained" onClick={handleOpen}>
        {accountRolesT('add')} {accountRolesT(role)}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            bgcolor: `${theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff'}`,
          }}
        >
          <IconClose onClose={handleClose} />
          <CreateAccount role={role} onClose={handleClose} />
        </Box>
      </Modal>
    </div>
  );
}
