import http from '../http-common';

class ProviderDataService {
  getAllProviders = (headers) => http.get(`/game-providers/`, { headers });

  getGameLogs = (headers, sessionId, query) => http.get(`/game-providers/session/${sessionId}/transactions/all?${query}`, { headers });

  getAllGamesByProvider = (headers, providerId, categoryId, query = '') =>
    http.get(
      `/game-providers/${providerId}/games/all?sortBy=order&sortDir=ASC&categoryId=${categoryId}${query}`,
      { headers },
    );

  getAllSubProviders = (headers, providerId) =>
    http.get(`/game-providers/${providerId}/sub-providers`, { headers });

  getProviderGamesOnScroll = (headers, providerId, categoryId, page = 1) => {
    return http.get(
      `/game-providers/${providerId}/games/all?sortBy=order&sortDir=ASC&categoryId=${categoryId}&page=${page}`,
      { headers },
    );
  };

  getOneByName = (headers, name) => {
    return http.get(`/game-providers/provider/${name}`, { headers });
  };

  uploadProviderImage = (headers, providerId, body) => {
    return http.put(`/game-providers/sub-providers/${providerId}/image`, body, { headers });
  };

}

const ProviderService = new ProviderDataService();

export default ProviderService;
