import { AlertColor, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import Result from 'components/alert/alert';
import { useTranslation } from 'react-i18next';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';

interface TermsProps {
  value: string;
  onSave: () => void;
  onEdit: (code: string, term: any) => void;
  result: string;
  handleOnClose: () => void;
  type: AlertColor;
  selectedItem: any;
}

const style = {
  borderBottom: '2px solid gray',
  borderRight: '2px solid gray',
  borderLeft: '2px solid gray',
  padding: '5px',
  backgroundColor: 'white',
  color: 'black',
  minHeight: 300,
  maxHeight: 600,
};

function TermsLanguage({
  onSave,
  onEdit,
  result,
  handleOnClose,
  type,
  selectedItem,
  value,
}: TermsProps) {
  const { t: siteSettingsT } = useTranslation('siteSettings');
  const [contentState, setContentState] = useState(selectedItem ? selectedItem.term : undefined);

  const handleOnClick = () => {
    onSave();
  };

  const onContentStateChange = (contentState: any) => {
    setContentState(contentState);
    onEdit(value, contentState);
  };

  const uploadImagOnEditor = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const dataURL = reader.result;
        resolve({ data: { link: dataURL } });
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    if (selectedItem) {
      setContentState(selectedItem.term);
    }
  }, [selectedItem]);

  return (
    <>
      <Editor
        editorStyle={style}
        toolbarStyle={{
          color: 'black',
        }}
        toolbar={{
          image: {
            previewImage: true,
            uploadCallback: uploadImagOnEditor,
            alt: { present: true, mandatory: false },
          },
        }}
        onContentStateChange={onContentStateChange}
        contentState={selectedItem ? selectedItem.term : contentState}
      />

      {result && (
        <Result
          style={{ mt: 2 }}
          message={result}
          type={type}
          isShow
          hasAction
          onClose={handleOnClose}
        />
      )}
      <Button
        sx={{ mt: 3 }}
        style={{ float: 'right', width: 150 }}
        variant="contained"
        onClick={handleOnClick}
      >
        {siteSettingsT('save')}
      </Button>
    </>
  );
}

export default TermsLanguage;
