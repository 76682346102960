import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import { cloneDeep } from 'lodash';
import { StrictModeDroppable } from '../../games/strictModeDroppable';
import { Box, Button, FormControlLabel, FormGroup, Switch } from '@mui/material';
import { InputCell } from 'components/inputCell/inputCell';
import { useTranslation } from 'react-i18next';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import common from 'styles/common.module.scss';
import UploadButtons from 'components/imageUpload/imageUpload';
import { usePayInPayOut } from 'hooks/finances/usePayInPayOut';
import { sitesSettings } from 'constants/common';
import TransitionAlerts from 'components/alert/transitionAlert/transitionAlert';
import LoaderWithBg from 'components/loader/loader';
import { ICurrencyMethods } from 'types/sites';
import styles from './sitePaymentMethods.module.scss';

interface IImageBlob {
  id: string;
  url: string;
  prevUrl: string;
}

interface SitePaymentMethodsProps {
  columnNames: string[];
  btnText: string;
  siteId: string;
  type?: string;
}

export type modeType = 'edit' | 'delete';

function SitePaymentMethods({ type, columnNames, btnText, siteId }: SitePaymentMethodsProps) {
  const {
    isLoading,
    dataList,
    updateImage,
    getAllPayIns,
    updatePayInMinMax,
    getAllPayOuts,
    updatePayOutMinMax,
    updateImagePayOut,
  } = usePayInPayOut();
  const { t: siteSettingsT } = useTranslation('siteSettings');
  const { t: commonT } = useTranslation('common');
  const [updatedItem, setUpdatedItem] = useState({ message: '', itemId: '', type: '' });
  const [isItemActive, setItemIsActive] = useState({ id: '', isActive: false });
  const [currentImage, setCurrentImage] = useState<IImageBlob>({ id: '', url: '', prevUrl: '' });
  const [paymentList, setPaymentList] = useState<ICurrencyMethods[]>([]);

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const items = Array.from(paymentList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setPaymentList(items);
  };

  const hnadleOnSave = () => {
    /**TODO: write logic */
    console.log(paymentList, 'add');
  };

  const handleOnSwicth = async (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const { checked } = e.target;
    const body = { isActive: checked };
    const res =
      type === sitesSettings.DEPOSIT_TITLE
        ? await updatePayInMinMax(siteId, id, body)
        : await updatePayOutMinMax(siteId, id, body);

    if (res.status === 200) {
      const findItem = paymentList.filter((el) => el.id === +id);

      if (findItem.length) {
        findItem[0].isActive = checked;
      }
      setItemIsActive({ id, isActive: checked });
    }
  };

  const handleOnChange = async (data: any) => {
    const body = { [data.type]: data.amount };

    const res =
      type === sitesSettings.DEPOSIT_TITLE
        ? await updatePayInMinMax(siteId, data.payId, body)
        : await updatePayOutMinMax(siteId, data.payId, body);
    if (res.status === 200) {
      setUpdatedItem({ message: commonT('savedSuccess'), itemId: data.id, type: data.type });
    }
  };

  const onImageUpload = async (data: string, prevUrl: string | undefined, id: string) => {
    if (id) {
      setCurrentImage({ id, prevUrl: prevUrl ?? '', url: data });
    }
  };

  const handleOnSaveImage = async (id: string) => {
    const res =
      type === sitesSettings.DEPOSIT_TITLE
        ? await updateImage(siteId, currentImage.id, currentImage.url)
        : await updateImagePayOut(siteId, currentImage.id, currentImage.url);
    if (res.status === 200) {
      const findItem = paymentList.filter((el) => el.id === +id);
      if (findItem.length) {
        findItem[0].image = res.data;
      }
      setCurrentImage({ id: '', url: '', prevUrl: '' });
    }
  };

  const removeImage = () => {
    setCurrentImage({ id: '', url: '', prevUrl: '' });
  };

  const resetUpdatedData = () => {
    setUpdatedItem({ message: '', itemId: '', type: '' });
  };

  useEffect(() => {
    if (type === sitesSettings.DEPOSIT_TITLE) {
      getAllPayIns(siteId);
    } else if (type === sitesSettings.WITHDRAW_TITLE) {
      getAllPayOuts(siteId);
    }
  }, [type]);

  useEffect(() => {
    if (dataList.length) {
      const list = cloneDeep(dataList);
      setPaymentList(list);
    }
  }, [dataList]);

  return (
    <Box>
      {isLoading ? <LoaderWithBg isOpen={isLoading} /> : null}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="contained" sx={{ mt: 3, mb: 2, mr: 4 }} onClick={hnadleOnSave}>
          {btnText}
        </Button>
      </Box>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <StrictModeDroppable droppableId="menuItems">
          {(provided) => (
            <table
              className={styles.menuItems}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {columnNames.map(
                (
                  item:
                    | string
                    | number
                    | boolean
                    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                    | Iterable<React.ReactNode>
                    | React.ReactPortal
                    | null
                    | undefined,
                  index: React.Key | null | undefined,
                ) => {
                  return <th key={index}>{siteSettingsT(item as string).toUpperCase()}</th>;
                },
              )}
              {paymentList.map(
                (
                  {
                    id,
                    methodname,
                    system,
                    isActive,
                    sitePayInMethods,
                    image,
                    methodId,
                    sitePayOutMethods,
                  }: any,
                  index: number,
                ) => {
                  return (
                    <Draggable key={id} draggableId={id + ''} index={index}>
                      {(provided) => (
                        <tr
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <td>{methodname}</td>
                          <td>{system}</td>
                          <td>
                            <InputCell
                              type="minValue"
                              onChange={handleOnChange}
                              siteCurrencies={
                                type === sitesSettings.DEPOSIT_TITLE
                                  ? sitePayInMethods
                                  : sitePayOutMethods
                              }
                              id={id}
                            />
                            <TransitionAlerts
                              message={updatedItem.message}
                              isOpen={
                                +updatedItem.itemId === +id && updatedItem.type === 'minValue'
                              }
                              resetUpdatedData={resetUpdatedData}
                            />
                          </td>
                          <td>
                            <InputCell
                              type="maxValue"
                              onChange={handleOnChange}
                              siteCurrencies={
                                type === sitesSettings.DEPOSIT_TITLE
                                  ? sitePayInMethods
                                  : sitePayOutMethods
                              }
                              id={id}
                            />
                            <TransitionAlerts
                              message={updatedItem.message}
                              isOpen={
                                +updatedItem.itemId === +id && updatedItem.type === 'maxValue'
                              }
                              resetUpdatedData={resetUpdatedData}
                            />
                          </td>
                          <td>
                            <Box className={styles.imgUpload_part}>
                              <UploadButtons
                                needFormDataImage
                                needFormDataImageWithPreview
                                withIconBtn
                                keys={index + ''}
                                onImageUpload={(url, prevUrl) =>
                                  onImageUpload(url, prevUrl, methodId)
                                }
                                initialImage={
                                  currentImage.id === methodId ? currentImage.prevUrl : image
                                }
                              />

                              {currentImage.id === methodId && currentImage.url ? (
                                <>
                                  <DeleteForeverIcon
                                    sx={{
                                      color: common.mainwarning,
                                      fontSize: 28,
                                      cursor: 'pointer',
                                    }}
                                    onClick={removeImage}
                                  />
                                  <Button
                                    sx={{ ml: 3, mr: 3, minWidth: '100px' }}
                                    variant="contained"
                                    onClick={() => handleOnSaveImage(id)}
                                  >
                                    {siteSettingsT('save')}
                                  </Button>
                                </>
                              ) : null}
                            </Box>
                          </td>
                          <td>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={
                                      isItemActive.id === id ? isItemActive.isActive : isActive
                                    }
                                    onChange={(e) => handleOnSwicth(e, id)}
                                  />
                                }
                                label={siteSettingsT('active')}
                              />
                            </FormGroup>
                          </td>
                        </tr>
                      )}
                    </Draggable>
                  );
                },
              )}
              {provided.placeholder}
            </table>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    </Box>
  );
}

export default SitePaymentMethods;
