import { useApiErrors } from 'hooks/errors/useApiErrors';
import { useStorage } from 'hooks/authHooks/useStorage';
import { useEffect, useState } from 'react';
import { generateHeaders } from 'utils/utils';
import ProviderService from 'services/provider.service';
import { IProviders } from 'types/providers';
import {
  prepareDataForSelectPages,
  prepareTableData,
  preparedColumnNames,
} from 'utils/helpers/dataHelper/prepareDataHelper';
import { IGotoPage } from 'types/sites';

export const useProviders = (forceRequest = true) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [gameLogs, setGameLogs] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [columns, setColumns] = useState<string[] | []>([]);
  const [pageLists, setPageLists] = useState<IGotoPage[] | []>([]);
  const [providersList, setProvidersList] = useState<IProviders[]>([]);
  const [error, setError] = useState('');

  const { logout, userToken } = useStorage();
  const { errorHandler } = useApiErrors();
  const initHeaders = generateHeaders(userToken());

  useEffect(() => {
    if (forceRequest) getAllProviders();
  }, [forceRequest]);

  const getAllGamesByProvider: any = async (
    providerId: string,
    categoryId: string,
    query: string,
    headers = initHeaders,
  ) => {
    try {
      const {
        data: { data },
      } = await ProviderService.getAllGamesByProvider(headers, providerId, categoryId, query);
      return { data: data.games, loading: false };
    } catch (error: any) {
      if (error.response) {
        const {
          response: {
            data: { message, status },
          },
        } = error;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          logout();
        } else if (!needLogout && newHeader) {
          return await getAllGamesByProvider(providerId, categoryId, query, newHeader);
        } else {
          console.log('LOG____>', message);
          setError(message);
        }
      }
    }
  };

  const getGameLogs: any = async (sessionId: string, query: string, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: {
          data: { gameTransactions, totalItems, totalPages },
          status,
        },
      } = await ProviderService.getGameLogs(headers, sessionId, query);
      if (status === 200) {
        setGameLogs(gameTransactions);
        setTotalPages(totalPages);
        setTotalItems(totalItems);
        const preparedData = prepareTableData(gameTransactions, 'gameLogs');
        const columns = preparedColumnNames(preparedData);
        const pageLists = prepareDataForSelectPages(totalPages);
        setColumns(columns);
        setPageLists(pageLists as IGotoPage[]);
        return { status, data: gameTransactions };
      }
    } catch (error: any) {
      if (error.response) {
        const {
          response: {
            data: { message, status },
          },
        } = error;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          // TODO: need to discuss with BE 
         //logout();
        } else if (!needLogout && newHeader) {
          return await getGameLogs(sessionId, query, newHeader);
        } else {
          console.log('LOG____>', message);
          setError(message);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };
  const getAllProviders: any = async (headers = initHeaders) => {
    try {
      const {
        data: { data, status },
      } = await ProviderService.getAllProviders(headers);
      if (status === 200) {
        setProvidersList(data);
      }
    } catch (error: any) {
      if (error.response) {
        const {
          response: {
            data: { message, status },
          },
        } = error;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          logout();
        } else if (!needLogout && newHeader) {
          return await getAllProviders(newHeader);
        } else {
          console.log('LOG____>', message);
          setError(message);
        }
      }
    }
  };

  const getAllSubProviders: any = async (provider: string, headers = initHeaders) => {
    setIsLoading(true);
    try {
      const {
        data: { data, status },
      } = await ProviderService.getAllSubProviders(headers, provider);
      return { data, loading: false, status };
    } catch (error: any) {
      if (error.response) {
        const {
          response: {
            data: { message, status },
          },
        } = error;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          logout();
        } else if (!needLogout && newHeader) {
          return await getAllSubProviders(provider, newHeader);
        } else {
          console.log('LOG____>', message);
          setError(message);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const uploadProviderImage: any = async (
    providerId: number,
    body: any,
    headers = { ...initHeaders, 'Content-Type': 'multipart/form-data' },
  ) => {
    setIsLoading(true);

    try {
      const {
        data: { data, status },
      } = await ProviderService.uploadProviderImage(headers, providerId, body);
      if (status === 200) {
        return { data, status };
      }
    } catch (err: any) {
      const {
        response: {
          data: { message, status },
        },
      } = err;
      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await uploadProviderImage(providerId, body, {
          ...newHeader,
          'Content-Type': 'multipart/form-data',
        });
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getChunkGamesByProvider: any = async (
    provider: string,
    categoryId: string,
    page: number,
    headers = initHeaders,
  ) => {
    try {
      const {
        data: { data },
      } = await ProviderService.getProviderGamesOnScroll(headers, provider, categoryId, page);
      return { data: data.games, loading: false };
    } catch (error: any) {
      if (error.response) {
        const {
          response: {
            data: { message, status },
          },
        } = error;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          logout();
        } else if (!needLogout && newHeader) {
          return await getChunkGamesByProvider(provider, categoryId, page, newHeader);
        } else {
          console.log('LOG____>', message);
          setError(message);
        }
      }
    }
  };

  const getProviderByName: any = async (id: string, headers = initHeaders) => {
    try {
      const {
        data: { data },
      } = await ProviderService.getOneByName(headers, id);
      /** TODO write logic  */
      console.log(data, 'getProviderByName');
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};

      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getProviderByName(id, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    }
  };

  return {
    gameLogs,
    columns,
    getAllProviders,
    getProviderByName,
    getAllGamesByProvider,
    getChunkGamesByProvider,
    getAllSubProviders,
    uploadProviderImage,
    providersList,
    getGameLogs,
    totalPages,
    totalItems,
    pageLists,
    isLoading,
    error,
  };
};
