import { GridValueGetterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import FilterField from 'components/filter/columnFilter';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { IFilterData } from 'types/accounts';
import { columnsName, ROW_PER_PAGE } from 'constants/common';
import { Box, Link, TablePagination, Typography } from '@mui/material';
import LoaderWithBg from 'components/loader/loader';
import { useTranslation } from 'react-i18next';
import { dateFormat, makeQueryUrl } from 'utils/utils';
import { isEmpty } from 'lodash';
import _color from 'styles/common.module.scss';
import { FIELD_NAME } from 'types/sites';
import { usePromoCodes } from 'hooks/sites/usePromoCodes';

interface ListPromoTableProps {
  id: string;
  type: string;
}

export default function ListPromoTable({ id, type }: ListPromoTableProps) {
  const { t } = useTranslation('common');
  const { t: siteT } = useTranslation('sites');
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(ROW_PER_PAGE);
  const [filterData, setFilterData] = useState<IFilterData | object>({});
  const [sortData, setSortData] = useState<IFilterData | object>({});
  const {
    getAllMultiUserAppliedPromoCode,
    columns: columnNames,
    promoAppliedUsersList: list,
    pageLists,
    totalItems,
  } = usePromoCodes();

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (newPage >= +pageLists[pageLists.length - 1].val) {
      setPage(+pageLists[pageLists.length - 1].val);
    } else {
      setPage(newPage);
    }
  };

  const handleFilter = (name: string, value: string) => {
    setFilterData((filterby) => ({ ...filterby, [name]: value }));
  };

  const handleSortBy = (sortBy: string, sortDir: string) => {
    setSortData({ [sortBy]: sortDir });
  };

  const generateDinamicColumns = useMemo(() => {
    const columns = columnNames.map((el) => {
      if (el === columnsName.USER) {
        return {
          field: el,
          minWidth: el.length * 6 + 140,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isCamelCase
              isDisabled
            />
          ),
          renderCell: (params: GridValueGetterParams) => (
            <Link
              color={'text.primary'}
              component="a"
              target="blank"
              onClick={() => {
                window.open(`/clients/details/${params.row.user.id}`);
              }}
            >
              {params.row.user.login}
            </Link>
          ),
        };
      } else if (el === columnsName.ACTIVATED_AT) {
        return {
          field: el,
          minWidth: el.length * 6 + 140,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isCamelCase
              isDisabled
            />
          ),
          valueGetter: (params: GridValueGetterParams) => `${dateFormat(params.row.activatedAt)}`,
        };
      }
      return {
        field: el,
        width: el === columnsName.ID ? 100 : el.length * 6 + 140,
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => (
          <FilterField
            fieldName={el}
            onBlur={(value) => handleFilter(el, value)}
            onSort={handleSortBy}
            sortData={sortData}
            isCamelCase
            isDisabled
          />
        ),
      };
    });
    return [...columns];
  }, [columnNames, sortData, pageLists]);

  const columns = generateDinamicColumns as GridColDef[];

  const getData = async (type: string, paramsQuery: string) => {
    let res;
    switch (type) {
      case FIELD_NAME.PROMO_USERS:
        res = await getAllMultiUserAppliedPromoCode(id, paramsQuery);
        break;
      default:
    }
    if (res?.status === 200) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const query = makeQueryUrl(filterData);
    const sortBy = Object.entries(sortData)[0];

    let paramsQuery = isEmpty(sortData)
      ? query
      : `${query}&sortBy=${sortBy[0]}&sortDir=${sortBy[1]}`;
    paramsQuery += `&page=${page + 1}&pageSize=${rowsPerPage}`;

    getData(type, paramsQuery);
  }, [filterData, sortData, page, rowsPerPage, type]);

  return (
    <div style={{ width: '100%' }}>
      {isLoading ? (
        <LoaderWithBg isOpen={isLoading} />
      ) : (
        <>
          <TablePagination
            sx={{ mt: 2, mb: 2 }}
            component="div"
            count={totalItems}
            page={page}
            labelRowsPerPage={t('rowsPerPage')}
            labelDisplayedRows={({ from, to, count }) =>
              `${from >= to ? to : from}-${to} ${t('of')} ${count}`
            }
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <DataGrid
            autoHeight
            rows={list}
            columns={columns}
            hideFooterPagination={true}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  userId: false,
                  id: false,
                },
              },
            }}
            sx={{
              '.MuiDataGrid-footerContainer': {
                display: 'none',
              },
            }}
          />
        </>
      )}
    </div>
  );
}
