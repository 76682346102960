import { actionTypes } from 'redux/constants/actionTypes';

const initial = [];
const initialWithdrawals = [];
const initialActionsList = null;

export const transactionsReducer = (state = initial, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_TRANSACTIONS:
      return [...payload];
    default:
      return state;
  }
};

export const transactionsWithdrawalsReducer = (state = initialWithdrawals, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_FINANCE_WITHDRAWALS:
      return [...payload];
    case actionTypes.UPDATE_FINANCE_WITHDRAWALS:
      return state.map((el) =>
        el.id === payload.id
          ? { ...el, statusId: payload.statusId, status: payload.status, logs: payload.logs }
          : { ...el },
      );
    default:
      return state;
  }
};

export const transactionsActionsReducer = (state = initialActionsList, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_TRANSACTIONS_ACTIONS:
      return [...payload];
    default:
      return state;
  }
};
