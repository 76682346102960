import { GridValueGetterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import FilterField from 'components/filter/columnFilter';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { IFilterData } from 'types/accounts';
import { columnsName, dialog, common, ROW_PER_PAGE } from 'constants/common';
import { dateFormat, isEmpty, makeQueryUrl } from 'utils/utils';
import { Box, Button, Link, Modal, TablePagination, useTheme } from '@mui/material';
import DropDownSelect from 'components/select/select';
import { useDispatch, useSelector } from 'react-redux';
import { setResetAll } from 'redux/actions/commonActions';
import SitesList from 'components/filter/siteLists';
import { useTranslation } from 'react-i18next';
import CurrencyList from 'components/filter/currencyLists';
import { useTransaction } from 'hooks/finances/useTransaction';
import Dialogs from 'components/dialog/dialogs';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import _color from 'styles/common.module.scss';
import TransactionList from 'components/filter/trasactionLists';
import { useBalances } from 'hooks/clients/useBalances';
import ApproveClientWithdraw from 'components/modal/approveWithdraw/approveClientWithdraw';
import { hasPermission } from 'permissions/actions';
import { trim } from 'lodash';
import styles from '../table.module.scss';
import { style } from 'components/modal/modal';

const statusIconsObj = {
  2: <CheckCircleIcon sx={{ color: _color.green }} />,
  5: <CheckCircleIcon sx={{ color: _color.green }} />,
  3: <CancelIcon sx={{ color: _color.mainwarning }} />,
  4: <CancelIcon sx={{ color: _color.orange }} />,
} as { [x: number]: any };

enum EButtons {
  TRASFER_FROM_SITE = 'site_transfer',
}

const dialogContentObj = {
  approve: {
    title: dialog.APPROVE_TEXT,
    btnText: common.APPROVE,
    color: _color.green,
  },
  cancel: {
    title: dialog.CANCEL_TEXT,
    btnText: common.YES,
    color: _color.mainwarning,
  },
  Excel: {
    title: '',
    btnText: '',
    color: _color.lightBlue,
  },
  Telegram: {
    title: '',
    btnText: '',
    color: _color.lightBlue,
  },
  cancel_return: {
    title: dialog.CANCEL_REFUND_TEXT,
    btnText: common.YES,
    color: _color.orange,
  },
} as { [x: string]: any };

const initial = {
  id: '',
  item: { amount: '' },
  title: '',
  btnText: '',
  placeholder: '',
};

const actionListWithoutOpenModal = ['5', '6'];

export default function WithdrawTable() {
  const { t: financeWithdrawalsT } = useTranslation('financeWithdrawals');
  const { t: commonT } = useTranslation('common');
  const theme = useTheme();
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROW_PER_PAGE);

  const { isLoading: isLoadingActions, getAllTransactionStatuses } = useBalances();
  const {
    isLoading,
    columns: columnNames,
    pageLists,
    totalItems,
    getAllPayOutTransactions,
    updateTransactionStatus,
  } = useTransaction();

  const [activeItem, setActiveItem] = useState(initial);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [actionId, setActionId] = useState('');
  const [resetGoToPage, setResetGoToPage] = useState(false);
  const [filterData, setFilterData] = useState<IFilterData | object>({});
  const [sortData, setSortData] = useState<IFilterData | object>({});
  const actionsList = useSelector<any>((state) => state.transactionsActions) as any;
  const withdrawalsList = useSelector<any>((state) => state.financeWithdrawals) as any;
  const { role: mainRole } = useSelector((state: any) => state.siteRole) as { role: '' };
  const hasPermissions = hasPermission.actionsList.includes(mainRole);

  const getList = async () => {
    await getAllTransactionStatuses();
  };

  const actionsWithoutOpenModal = async (id: string, actionId: string) => {
    await updateTransactionStatus(id, actionId);
  };

  useEffect(() => {
    if (!actionsList) getList();
  }, [actionsList]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (newPage >= +pageLists[pageLists.length - 1].val) {
      setPage(+pageLists[pageLists.length - 1].val);
    } else {
      setPage(newPage);
    }
    setResetGoToPage((prev) => !prev);
  };

  const handleFilter = (name: string, value: string) => {
    setFilterData((filterby) => ({ ...filterby, [name]: value }));
  };

  const handleSortBy = (sortBy: string, sortDir: string) => {
    setSortData({ [sortBy]: sortDir });
  };

  const handleResetAll = () => {
    dispatch(setResetAll(true));
    setFilterData({});
    setSortData({});
  };

  const handleSelectPage = (page: string) => {
    setPage(+page - 1);
  };

  const resetActiveItem = () => {
    setOpen(false);
    setTimeout(() => {
      setActiveItem(initial);
    }, 0);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const onCloseModalApprove = () => {
    console.log('onCloseModalApprove');
  };

  const handleOnConfirm = async (id: string) => {
    await updateTransactionStatus(id, actionId);

    resetActiveItem();
  };

  const handleOnAction = (type: string, item: any, actionId: string) => {
    if (hasPermissions) {
      if (!actionListWithoutOpenModal.includes(actionId + '')) {
        if (type !== EButtons.TRASFER_FROM_SITE) {
          setActiveItem((data) => ({
            ...data,
            id: item.id,
            item,
            title: financeWithdrawalsT(dialogContentObj[type].title),
            btnText: commonT(dialogContentObj[type].btnText),
          }));
          setOpen(true);
        } else {
          setOpenModal(true);
          setActiveItem((data) => ({
            ...data,
            item,
          }));
        }
        setActionId(actionId);
      } else {
        actionsWithoutOpenModal(item.id, actionId);
      }
    }
  };

  useEffect(() => {
    const query = makeQueryUrl(filterData);
    const sortBy = Object.entries(sortData)[0];

    let paramsQuery = isEmpty(sortData)
      ? query
      : `${query}&sortBy=${sortBy[0]}&sortDir=${sortBy[1]}`;

    paramsQuery += `&page=${page + 1}&pageSize=${rowsPerPage}`;
    if (!isLoadingActions || actionsList) getAllPayOutTransactions(paramsQuery);
  }, [filterData, sortData, page, rowsPerPage, isLoadingActions]);

  const actionFields = [
    {
      headerName: '',
      field: 'actions',
      width: 300,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridValueGetterParams) => {
        if (params.row.statusId === 1) {
          return (
            <Box className={styles.withdrow_actions}>
              {actionsList?.map((el: any, index: number) => {
                return (
                  <Button
                    key={index}
                    fullWidth
                    sx={{ backgroundColor: dialogContentObj[el.action]?.color, m: 0.5 }}
                    variant="contained"
                    onClick={() => handleOnAction(el.action, params.row, el.id)}
                  >
                    {commonT(el.action)}
                  </Button>
                );
              })}
            </Box>
          );
        }
        return <Box>{statusIconsObj[params.row.statusId]}</Box>;
      },
    },
  ];

  const generateDinamicColumns = useMemo(() => {
    const columns = columnNames.map((el) => {
      if (el === columnsName.CREATED_AT || el === columnsName.DATE) {
        return {
          field: el,
          minWidth: el.length * 6 + 100,

          // width: Math.ceil(window.innerWidth / columnNames.length),
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isCamelCase
            />
          ),
          valueGetter: (params: GridValueGetterParams) =>
            dateFormat(params.row.createdAt || params.row.date),
        };
      } else if (el === 'comment') {
        return {
          field: el,
          minWidth: el.length * 6 + 120,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isCamelCase
            />
          ),
          valueGetter: (params: GridValueGetterParams) => {
            return trim(params?.row?.comment).length === 16
              ? trim(params?.row?.comment)
                  .replace(/(.{4})/g, '$1-')
                  .replace(/-$/, '')
              : params.row.comment;
          },
        };
      } else if (el == columnsName.USER) {
        return {
          field: el,
          minWidth: el.length * 6 + 100,
          // width: Math.ceil(window.innerWidth / (columnNames.length - 2)),
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isCamelCase
            />
          ),
          renderCell: (params: GridValueGetterParams) => (
            <Link
              color={'text.primary'}
              component="a"
              target="blank"
              onClick={() => {
                window.open(`/clients/details/${params.row.userId}`);
              }}
            >
              {params.row.user}
            </Link>
          ),
        };
      } else if (el == columnsName.LOGS) {
        return {
          field: el,
          width: el.length * 6 + 280,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isCamelCase
              isDisabled
            />
          ),
          renderCell: (params: GridValueGetterParams) => {
            if (params.row.logs.length) {
              return (
                <Box
                  sx={{
                    p: 1,
                    width: '100%',
                    height: '100%',
                  }}
                >
                  {params.row.logs.map((elem: any, index: number) => {
                    return (
                      <Box key={index}>
                        <Box
                          sx={{
                            color: elem?.value?.includes('Excel')
                              ? 'red'
                              : elem?.value?.includes('Telegram')
                              ? 'blue'
                              : 'green',
                          }}
                        >
                          {elem.value}
                        </Box>
                        <Box>{dateFormat(elem.createdAt)}</Box>
                      </Box>
                    );
                  })}
                </Box>
              );
            }
            return <>{}</>;
          },
        };
      }
      return {
        field: el,
        width: el === columnsName.ID ? 100 : el.length * 6 + 110,
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => (
          <FilterField
            fieldName={el}
            onBlur={(value) => handleFilter(el, value)}
            onSort={handleSortBy}
            sortData={sortData}
            isCamelCase
            isDisabled={el === columnsName.BALANCE_TYPE}
          />
        ),
      };
    });
    if (hasPermissions) {
      return [...columns, ...actionFields];
    }

    return [...columns];
  }, [columnNames, sortData]);

  const columns = generateDinamicColumns as GridColDef[];

  return (
    <div style={{ width: '100%' }}>
      {!isLoading || !isLoadingActions ? (
        <>
          <Box className={styles.filter_box}>
            <div className={styles.filter_btn}>
              <Box sx={{ display: 'flex', mt: 2, mb: 2 }}>
                <DropDownSelect
                  totalPages={pageLists}
                  onSelectPage={handleSelectPage}
                  reset={resetGoToPage}
                />
                {Object.values(filterData).join() || Object.values(sortData).join() ? (
                  <Button
                    className={styles.reset_all_btn}
                    variant="contained"
                    onClick={handleResetAll}
                  >
                    {commonT('resetAll')}
                  </Button>
                ) : null}
              </Box>
            </div>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ minWidth: 100, mr: 3 }}>
              <SitesList onSelectSite={handleFilter} initValue={isEmpty(filterData)} />
            </Box>

            <Box sx={{ minWidth: 100, mr: 3 }}>
              <TransactionList
                onSelectSite={handleFilter}
                defaultValue={'1'}
                initValue={isEmpty(filterData)}
              />
            </Box>
            <Box sx={{ minWidth: 100, mr: 3 }}>
              <CurrencyList onSelectSite={handleFilter} initValue={isEmpty(filterData)} />
            </Box>
          </Box>
          <>
            <TablePagination
              component="div"
              count={totalItems}
              page={page}
              labelRowsPerPage={commonT('rowsPerPage')}
              labelDisplayedRows={({ from, to, count }) =>
                `${from >= to ? to : from}-${to} ${commonT('of')} ${count}`
              }
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <DataGrid
              autoHeight
              getRowHeight={() => 'auto'}
              rows={withdrawalsList}
              columns={columns}
              hideFooterPagination={true}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    statusId: false,
                    userId: false,
                  },
                },
              }}
              sx={{
                '.MuiDataGrid-iconButtonContainer': {
                  visibility: 'visible',
                },
                '.MuiDataGrid-sortIcon': {
                  opacity: 'inherit !important',
                },
                '.MuiDataGrid-cell': {
                  '&:last-child': {
                    justifyContent: 'center !important',
                  },
                },
                '.MuiDataGrid-footerContainer': {
                  display: 'none',
                },
              }}
            />
            <TablePagination
              component="div"
              rowsPerPageOptions={[]}
              count={totalItems}
              page={page}
              labelRowsPerPage={commonT('rowsPerPage')}
              labelDisplayedRows={({ from, to, count }) =>
                `${from >= to ? to : from}-${to} ${commonT('of')} ${count}`
              }
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
            />
          </>
        </>
      ) : (
        <Box> {commonT('loading')}</Box>
      )}
      <Dialogs
        id={activeItem.id}
        title={activeItem.title}
        withForm
        btnText={activeItem.btnText}
        placeholder={activeItem.placeholder}
        isOpen={open}
        onClose={resetActiveItem}
        onConfirm={handleOnConfirm}
      />
      {openModal && (
        <Modal open={openModal} onClose={handleClose} sx={{ minWidth: 400 }}>
          <Box
            sx={{
              ...style,
              bgcolor: `${
                theme.palette.mode === 'dark' ? theme.palette.background.paper : _color.mainwhite
              }`,
            }}
          >
            <ApproveClientWithdraw amount={activeItem.item?.amount} onClose={onCloseModalApprove} />
          </Box>
        </Modal>
      )}
    </div>
  );
}
