import { Box, TextField, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { useSites } from 'hooks/sites/useSites';
import { IChannel } from 'components/tables/channelsTable';
import Result from 'components/alert/alert';
import SitesList from 'components/filter/siteLists';
import { useTranslation } from 'react-i18next';

interface EditChannelProps {
  title: string;
  onClose: () => void;
  item: IChannel | object;
}

export const initialData = {
  name: '',
  siteName: '',
  id: '',
};

const style = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
};

function EditChannel({ title, onClose, item }: EditChannelProps) {
  const { t: telegramT } = useTranslation('telegram');
  const { t } = useTranslation('common');
  const [formData, setFormData] = useState<IChannel>({ ...initialData, ...item });
  const { error } = useSites();

  const handleOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: string,
  ) => {
    const { value } = e.target;
    const data = { ...formData, [name]: value };
    setFormData(data);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onClose();
  };

  const handleFilter = (name: string, value: string) => {
    console.log({ name, value }, 'handleFilter');
  };

  return (
    <>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        {title}
      </Typography>
      <Box sx={style}>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <SitesList onSelectSite={handleFilter} isEdit initValue={formData?.siteName ?? ''} />
          <Box>
            <TextField
              required
              value={formData?.name ?? ''}
              fullWidth
              margin="normal"
              name="userName"
              label={telegramT('userNameOfChannel')}
              variant="standard"
              onChange={(e) => handleOnChange(e, 'userName')}
            />
          </Box>
          {error && <Result style={{ mb: 2 }} message={error} type={'error'} isShow />}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Button fullWidth type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
              {t('save')}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default EditChannel;
