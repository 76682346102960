import {
  Box,
  TextField,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Result from 'components/alert/alert';
import UploadButtons from 'components/imageUpload/imageUpload';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useSiteCategory } from 'hooks/sites/useSiteCategory';
import { ISiteCategory } from 'types/sites';
import styles from './modal.module.scss';

interface CreateCategoryProps {
  title: string;
  siteId: string;
  onClose: () => void;
}

const initialData = {
  title: '',
  image: null,
  isMobile: false,
  isDesktop: false,
  isPrimaryShow: false,
};

const style = {
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
};

function CreateCategory({ title, onClose, siteId }: CreateCategoryProps) {
  const { t: siteSettingsT } = useTranslation('siteSettings');
  const { createSiteCategory, getAllCategoryForSite, error } = useSiteCategory();
  const [formData, setFormData] = useState<ISiteCategory>({ ...initialData });

  const handleOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: string,
  ) => {
    const { value } = e.target;
    const data = { ...formData, [name]: value };
    setFormData(data);
  };

  const removeImage = () => {
    const data = { ...formData, image: null };
    setFormData(data);
  };

  const handleOnCheck = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    const { checked } = e.target;
    const data = { ...formData, [name]: checked };
    setFormData(data);
  };

  const onImageUpload = (data: string) => {
    setFormData((formData) => ({ ...formData, image: data }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (siteId !== undefined) {
      const result = await createSiteCategory({ ...formData }, siteId);
      if (result?.status === 201) {
        await getAllCategoryForSite(siteId);
        onClose();
      }
    }
  };

  return (
    <>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        {title}
      </Typography>
      <Box sx={style}>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
          <Box>
            <TextField
              fullWidth
              required
              margin="normal"
              name="title"
              label={siteSettingsT('title')}
              variant="standard"
              onChange={(e) => handleOnChange(e, 'title')}
            />
            <Box className={styles.content_part}>
              <UploadButtons onImageUpload={onImageUpload} initialImage={formData?.image ?? null} />
              {formData?.image ? (
                <DeleteForeverIcon
                  sx={{ color: 'text.primary', fontSize: 32, cursor: 'pointer' }}
                  onClick={removeImage}
                />
              ) : null}
            </Box>
          </Box>
          <FormGroup aria-label="position" row>
            <Box sx={{ mr: 2, mt: 1, fontSize: 14 }}>{siteSettingsT('devicesShow')}</Box>
            <FormControlLabel
              sx={{ mr: 3 }}
              control={
                <Checkbox
                  onChange={(e) => handleOnCheck(e, 'isMobile')}
                  sx={{
                    '& .MuiSvgIcon-root': { fontSize: 14, color: 'text.primary' },
                  }}
                />
              }
              label={<InstallMobileIcon />}
              labelPlacement="end"
            />
            <FormControlLabel
              sx={{ mr: 3 }}
              control={
                <Checkbox
                  onChange={(e) => handleOnCheck(e, 'isDesktop')}
                  sx={{
                    '& .MuiSvgIcon-root': { fontSize: 14, color: 'text.primary' },
                  }}
                />
              }
              label={<InstallDesktopIcon />}
              labelPlacement="end"
            />
          </FormGroup>
          {error && <Result style={{ mb: 2 }} message={error} type={'error'} isShow />}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Button fullWidth type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
              {siteSettingsT('addCategoryButton')}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default CreateCategory;
