export interface IFormData {
  login: string;
  password: string;
  twoFactorToken: string;
}

export interface IErrors {
  [key: string]: string;
}

export enum FIELD_NAME {
  LOGIN = 'login',
  EMAIL = 'email',
  PASSWORD = 'password',
  CODE = 'twoFactorToken',
}

export interface ISite {
  id: string;
  name: string;
  [key: string]: string;
}

export interface IUserSite {
  id: number;
}
