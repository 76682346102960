import { MainThemeContext } from 'context/mainThemeContext';
import { useContext, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { SignInPage } from 'components/pageLayouts/signIn/signInPage';
import { useAuthentication } from 'hooks/authHooks/useAuthentication';
import NoMatch from 'components/notFound/notFound';
import { routing } from 'constants/routing';
import MainContainer from 'components/pageLayouts/main/mainPage';
import { Box, CssBaseline, ThemeProvider, Typography } from '@mui/material';
import { STORAGE_KEYS, localStorageService } from 'utils/storageService';
import { dark } from 'styles/theme/dark';
import { light } from 'styles/theme/light';
import { useDispatch, useSelector } from 'react-redux';
import { setRole } from 'redux/actions/loginActions';
import { routesAndPermissions } from 'permissions/routes';
import './App.css';

interface SensitiveRouteProps {
  role?: string[];
  hasToken: boolean;
  children: React.ReactNode;
}

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { hasToken } = useAuthentication();
  const { mode, toggleDarkMode } = useContext(MainThemeContext);
  const defaultTheme = mode === 'light' ? light : dark;
  const { role: mainRole } = useSelector((state: any) => state.siteRole) as { role: '' };

  const SensitiveRoute = ({ hasToken, children, role }: SensitiveRouteProps) => {
    if (!hasToken || !mainRole) return <SignInPage />;
    if (role?.includes(mainRole)) {
      return <>{children}</>;
    }
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          textTransform: 'uppercase',
          color: '#b80909',
        }}
      >
        <Typography component="h1">ACCESS DENIed</Typography>
      </Box>
    );
  };

  useEffect(() => {
    if (localStorageService.read(STORAGE_KEYS.MODE) !== mode) {
      toggleDarkMode?.();
    }
    if (!mainRole) {
      const role = localStorageService.read(STORAGE_KEYS.ROLE);
      dispatch(setRole(role));
    }
    if (!hasToken) {
      navigate(routing.home);
    }
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Routes>
        {routesAndPermissions.map(({ path, componentName, role }, index) => (
          <Route
            key={index}
            path={path}
            element={
              <SensitiveRoute hasToken={hasToken} role={role}>
                <MainContainer componentName={componentName} />
              </SensitiveRoute>
            }
          />
        ))}
        <Route path={routing.notFound} element={<NoMatch />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
