import { actionTypes } from 'redux/constants/actionTypes';

const initial = [];

export const bonusesReducer = (state = initial, { type, payload }) => {
    switch (type) {
        case actionTypes.SET_BONUSES_LIST:
            return [...payload];
        case actionTypes.CREATED_BONUS:
            return [payload, ...state];
        case actionTypes.UPDATE_BONUS_DATA:
            return state.map(el => el.id === payload.id ? { ...el, ...payload } : { ...el })
        case actionTypes.DELETE_BONUS_DATA:
            return state.filter(el => el.id !== payload.id)
        default:
            return state;
    }
};
