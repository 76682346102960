import { useState } from 'react';
import { generateHeaders } from 'utils/utils';
import { useStorage } from '../authHooks/useStorage';
import { useApiErrors } from 'hooks/errors/useApiErrors';
import { IGotoPage } from 'types/sites';
import ClientService from 'services/client.service';
import {
  prepareDataForSelectPages,
  prepareTableData,
  preparedColumnNames,
} from 'utils/helpers/dataHelper/prepareDataHelper';

export const useClientGameSettings = () => {
  const { errorHandler } = useApiErrors();
  const { logout, userToken } = useStorage();
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [columns, setColumns] = useState<string[] | []>([]);
  const [pageLists, setPageLists] = useState<IGotoPage[] | []>([]);
  const [settingsList, setClientGameSettingsList] = useState([]);
  const [error, setError] = useState('');

  const initHeaders = generateHeaders(userToken());

  const saveClientGameSettings: any = async (
    clientId: string,
    body: any,
    headers = initHeaders,
  ) => {
    try {
      const {
        data: { data, status },
      } = await ClientService.saveClientGameSettings(headers, clientId, body);
      return { data, status };
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await saveClientGameSettings(clientId, body, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getClientCurrentGameSettings: any = async (clientId: string, headers = initHeaders) => {
    try {
      const {
        data: { data, status },
      } = await ClientService.getClientGameSettings(headers, clientId);
      if (status === 200) {
        return { data, status };
      }
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getClientCurrentGameSettings(clientId, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getClientGameSettingsList: any = async (
    clientId: string,
    query: string,
    headers = initHeaders,
  ) => {
    try {
      const {
        data: {
          data: { userSettingChanges, totalPages, totalItems },
          status,
        },
      } = await ClientService.getClientGameSettingsList(headers, clientId, query);

      const preparedData = prepareTableData(userSettingChanges, 'clientHistory');
      const columns = preparedColumnNames(preparedData);
      const pageLists = prepareDataForSelectPages(totalPages);
      setTotalPages(totalPages);
      setPageLists(pageLists as IGotoPage[]);
      setTotalItems(totalItems);
      setColumns(columns);
      setClientGameSettingsList(preparedData);
      if (status === 200) {
        return { data: userSettingChanges, status };
      }
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getClientGameSettingsList(clientId, query, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    saveClientGameSettings,
    getClientCurrentGameSettings,
    getClientGameSettingsList,
    isLoading,
    settingsList,
    totalItems,
    columns,
    totalPages,
    pageLists,
    error,
  };
};
