import { Button, AlertColor, Modal, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { IconClose } from 'components/closeButton/closeButton';
import Result from 'components/alert/alert';
import common from 'styles/common.module.scss';
import { style } from 'components/modal/modal';
import { useTranslation } from 'react-i18next';
import { useGameProvidersSettings } from 'hooks/providers/useGameProvidersSettings';
import ProviderSettingItem, { initialData } from './providerSettingItem';
import { IProviderSettings, IProviderSubSettings } from 'types/providers';
import cloneDeep from 'lodash/cloneDeep';
import LoaderWithBg from 'components/loader/loader';
import { SUCCESS, common as CONSTATNT, ERROR } from 'constants/common';
import styles from './gamblingSystemSettings.module.scss';

interface ProviderSettingProps {
  providerId: string;
  siteId: string;
}

function ProviderSetting({ providerId, siteId }: ProviderSettingProps) {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const {
    getAllSettings,
    error,
    isLoading,
    updateGameProviderSettings,
    createGameProviderSettings,
    createGameProviderSubSettings,
    resetError,
  } = useGameProvidersSettings();
  let timeOutId: ReturnType<typeof setTimeout>;
  const [success, setSuccess] = useState('');
  const [type, setType] = useState<AlertColor>(SUCCESS);
  const [gameSetting, setGameSetting] = useState<IProviderSettings[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const continueResult = (result: any) => {
    if (result?.status === 200) {
      setSuccess(t('savedSuccess'));
      setType(SUCCESS);
      timeOutId = setTimeout(() => {
        setSuccess('');
      }, 3000);
    } else {
      setType(ERROR);
    }
  };

  const handleOnClose = () => {
    resetError();
    setSuccess('');
  };

  const handleOnSave = async (submitData: IProviderSettings) => {
    const body = {
      baseURL: submitData.baseURL,
      key: submitData.key,
      name: submitData.name,
      token: submitData.token,
    };
    const result = await updateGameProviderSettings(providerId, submitData.id, body);
    continueResult(result);
  };

  const handleOnCreate = async (submitData: IProviderSettings) => {
    const result = await createGameProviderSettings({ ...submitData, siteId }, providerId);
    if (result?.status === 200) {
      setOpenModal(false);
      const temp = cloneDeep(gameSetting);
      setGameSetting([{ ...result.data }, ...temp]);
      setSuccess(t('savedSuccess'));
      setType(SUCCESS);
      timeOutId = setTimeout(() => {
        setSuccess('');
      }, 3000);
    } else {
      setType(ERROR);
    }
  };

  const handleOnSubCreate = async (
    submitData: IProviderSubSettings,
    providerId: number,
    settingId: number,
  ) => {
    const res = await createGameProviderSubSettings(submitData, providerId, settingId);
    if (res?.status === 200) {
      getData(providerId + '', siteId);
    }
    getData(providerId + '', siteId);
  };

  const getData = async (providerId: string, siteId: string) => {
    const query = `&providerId=${providerId}`;
    const result = await getAllSettings(siteId, query);
    if (result) {
      setGameSetting(result.data);
    }
  };

  useEffect(() => {
    getData(providerId, siteId);

    return () => clearTimeout(timeOutId);
  }, [providerId, siteId]);

  return (
    <>
      {isLoading ? <LoaderWithBg whithoutBg isOpen={isLoading} /> : null}
      <Box className={styles.main}>
        {+providerId !== 3 ? (
          <Box className={styles.actions}>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => setOpenModal(true)}
            >
              {t(CONSTATNT.CREATE)}
            </Button>{' '}
          </Box>
        ) : null}

        <Box className={styles.content}>
          {gameSetting.map((el: any, index: number) => {
            return (
              <Box className={styles.from_list} key={index}>
                <ProviderSettingItem
                  mode={CONSTATNT.EDIT}
                  item={el}
                  btnText={'saveSettings'}
                  onSave={handleOnSave}
                  onSubCreate={handleOnSubCreate}
                />
              </Box>
            );
          })}
        </Box>
        {success || error ? (
          <Result
            style={{ mb: 2, mt: 4 }}
            message={success || error}
            type={type}
            isShow
            hasAction
            onClose={handleOnClose}
          />
        ) : null}
        {openModal && (
          <Modal open={openModal} onClose={() => setOpenModal(false)} sx={{ minWidth: 400 }}>
            <Box
              sx={{
                ...style,
                bgcolor: `${
                  theme.palette.mode === 'dark' ? theme.palette.background.paper : common.mainwhite
                }`,
              }}
            >
              <ProviderSettingItem
                mode={CONSTATNT.CREATE}
                item={initialData}
                btnText={CONSTATNT.CREATE}
                onCreate={handleOnCreate}
                onSubCreate={handleOnSubCreate}
                providerId={+providerId}
              />
              <IconClose onClose={() => setOpenModal(false)} />
            </Box>
          </Modal>
        )}
      </Box>
    </>
  );
}

export default ProviderSetting;
