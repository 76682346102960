import { Box, Divider, IconButton, Typography } from '@mui/material';
import { MouseEvent, useEffect, useState } from 'react';
import { ClientIdentification } from 'components/identification/clientIdentification';
import InfoIcon from '@mui/icons-material/Info';
import cx from 'classnames';
import SitesList from 'components/filter/siteLists';
import { IFilterData } from 'types/accounts';
import { isEmpty } from 'utils/utils';
import { useTranslation } from 'react-i18next';
import LoaderWithBg from 'components/loader/loader';
import CloseIcon from '@mui/icons-material/Close';
import { useClients } from 'hooks/clients/useClients';
import InfiniteScroll from 'react-infinite-scroll-component';
import { cloneDeep } from 'lodash';
import { useDispatch } from 'react-redux';
import { decreaseInProcessUsersCount } from 'redux/actions/sitesActions';
import styles from './identifications.module.scss';

export interface IVerifiedCleintImage {
  frontView: string;
  rearView: string;
}

export const AwaitIdentifications = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const [clientList, setClientList] = useState<any>([]);
  const [activeItemId, setActiveItemId] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [canLoadSites, setCanLoadSites] = useState(false);
  const [filterData, setFilterData] = useState<IFilterData | object>({});

  const { getAllClientsByStatus, verificationApproveOrRejected, isLoading } = useClients();

  const openClientDetails = (e: MouseEvent<HTMLButtonElement>, id: number) => {
    e.stopPropagation();
    window.open(`/clients/details/${id}`, '_self');
  };

  const handleOpenDetails = (id: number) => {
    setActiveItemId(id + '');
  };

  useEffect(() => {
    const fetchData = async () => {
      const paramsQuery = `&page=${page}&pageSize=70`;
      const response = await getAllClientsByStatus(paramsQuery);
      if (response?.status === 200) {
        if (!canLoadSites) {
          setCanLoadSites(true);
        }
        const newData = cloneDeep(response?.data.users);

        if (newData.length) {
          setClientList((prevArray: any) => [...prevArray, ...newData]);
          setHasMore([...clientList, ...newData].length < response.data.totalItems);
        }
      }
    };

    fetchData();
  }, [page]);

  const handleOnConfirm = async (decision: string, decisionInput: string) => {
    const data =
      decision === 'approve'
        ? {
            passportNumber: decisionInput,
          }
        : {
            unverifiedReason: decisionInput,
          };
    const result = await verificationApproveOrRejected(decision, activeItemId, data);

    if (result.status === 200) {
      setClientList((prevList: any) => {
        dispatch(decreaseInProcessUsersCount());
        return prevList.filter((elm: any) => elm.id !== result.data.id);
      });
    }
  };

  const handleFilter = (name: string, value: string) => {
    setFilterData((filterby) => ({ ...filterby, [name]: value }));
  };

  const handleChangePage = async () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handleClose = () => {
    setActiveItemId('');
  };

  return (
    <>
      {isLoading ? <LoaderWithBg isOpen={isLoading} /> : null}
      <Box className={styles.content}>
        {canLoadSites ? (
          <Box sx={{ m: 2 }}>
            <SitesList onSelectSite={handleFilter} initValue={isEmpty(filterData)} />
          </Box>
        ) : null}
        <InfiniteScroll
          style={{ minWidth: '600px', padding: '0 8px' }}
          scrollableTarget="scrollableDiv"
          dataLength={clientList?.length}
          next={handleChangePage}
          hasMore={hasMore}
          /*TO DO*/
          loader={null}
        >
          <Box className={styles.clients_list}>
            {clientList.map(({ id, login, site, frontView, rearView }: any, index: any) => {
              return (
                <Box key={index} className={cx(styles.activeClient)}>
                  <Box
                    className={cx(styles.client, { [styles.active]: id === +activeItemId })}
                    onClick={() => (id !== +activeItemId ? handleOpenDetails(id) : null)}
                  >
                    <Box className={styles.title}>
                      <IconButton onClick={(e) => openClientDetails(e, id)}>
                        <InfoIcon />
                      </IconButton>
                      <Typography component="p">
                        <Typography component="span" className={styles.label}>
                          {t('login')}:
                        </Typography>
                        {` ${login} (${site.name})`}
                      </Typography>
                    </Box>
                    <Divider />
                  </Box>
                  <Box>
                    {id === +activeItemId ? (
                      <Box sx={{ display: 'flex', position: 'relative', flexDirection: 'column' }}>
                        <ClientIdentification
                          handleConfirm={handleOnConfirm}
                          verifiedImg={{ frontView: frontView, rearView: rearView }}
                          wrapperClassName={styles.details}
                          wrapperClassContent={styles.detailsContent}
                        />
                        <IconButton
                          sx={{ position: 'absolute', top: '0', right: '16px' }}
                          onClick={handleClose}
                        >
                          <CloseIcon
                            sx={{
                              color: '#2a2e5c',
                              width: '16px',
                              height: '16px',
                            }}
                          />
                        </IconButton>
                      </Box>
                    ) : null}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </InfiniteScroll>
      </Box>
    </>
  );
};
