import { Box } from '@material-ui/core';
import {
  Button,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import {  useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FIELD_NAME } from 'types/sites';
import Result from 'components/alert/alert';
import { IReturns } from 'types/sitesSettings';
import { isValidForSite } from 'utils/helpers/inputHelper';
import { useWheels } from 'hooks/sites/useWheels';

interface CreateReturnsProps {
  mode: string;
  onClose: () => void;
}

const fields = {
  [FIELD_NAME.NAME]: true,
  [FIELD_NAME.MAX_AMOUNT]: true,
  [FIELD_NAME.MIN_AMOUNT]: true,
  [FIELD_NAME.DEFAULT]: true,
};

function CreateReturns({ mode, onClose }: CreateReturnsProps) {
  const { t } = useTranslation('common');
  const { t: siteSettingsT } = useTranslation('siteSettings');
  const { t: validationT } = useTranslation('validations');
  const [validFields, setValidFields] = useState({ ...fields });
  const [formData, setFormData] = useState<IReturns>({} as IReturns);
  const { createWheelsGroup, error } = useWheels();

  const handleOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent<string | null>,
    key: string,
  ) => {
    const { value } = e.target;
    const newData = { ...formData, [key]: value };
    setFormData(newData);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      let result;
      if (mode === 'edit') {
        /**TODO edit later */
      } else {
        result = await createWheelsGroup(formData);
      }
      if (result?.status === 200 || result?.status === 201) {
        onClose();
      }
    } catch (error: any) {
      console.log(error, 'error');
    }
  };


  const validate = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, name: string) => {
    const { value } = e.target;
    setValidFields((fields) => ({
      ...fields,
      [name]: name === FIELD_NAME.MIN_AMOUNT ? formData.maxAmount !== undefined ?
        isValidForSite(value, name) && +value < +formData.maxAmount : isValidForSite(value, name) :
        name === FIELD_NAME.MAX_AMOUNT ? formData.minAmount !== undefined ?
          isValidForSite(value, name) && +value > +formData.minAmount : isValidForSite(value, name) : isValidForSite(value, name),
    }));
  };

  return (
    <Box>
      <Typography variant="h6" component="h2">
        {t(mode)}
      </Typography>
      <Box component={'form'} onSubmit={handleSubmit} style={{ marginTop: 15 }}>
        <TextField
          margin="normal"
          id="title"
          label={siteSettingsT(FIELD_NAME.NAME)}
          fullWidth
          required
          aria-required
          value={formData?.name ?? ''}
          variant="standard"
          InputLabelProps={{
            shrink: true,
          }}
          error={!validFields[FIELD_NAME.NAME]}
          onBlur={(e) => validate(e, FIELD_NAME.NAME)}
          onChange={(e) => handleOnChange(e, FIELD_NAME.NAME)}
          helperText={validFields[FIELD_NAME.NAME] ? '' : validationT('siteNameValidation')}
        />
        <TextField
          margin="normal"
          id="minValue"
          type="number"
          label={siteSettingsT(FIELD_NAME.MIN_AMOUNT)}
          fullWidth
          required
          aria-required
          value={formData?.minAmount ?? ''}
          variant="standard"
          InputLabelProps={{
            shrink: true,
          }}
          error={!validFields[FIELD_NAME.MIN_AMOUNT]}
          onBlur={(e) => validate(e, FIELD_NAME.MIN_AMOUNT)}
          onChange={(e) => handleOnChange(e, FIELD_NAME.MIN_AMOUNT)}
          helperText={validFields[FIELD_NAME.MIN_AMOUNT] ? '' : validationT('minSumValidation')}
        />
        <TextField
          margin="normal"
          type="number"
          id="minValue"
          label={siteSettingsT(FIELD_NAME.MAX_AMOUNT)}
          fullWidth
          required
          aria-required
          value={formData?.maxAmount ?? ''}
          variant="standard"
          InputLabelProps={{
            shrink: true,
          }}
          error={!validFields[FIELD_NAME.MAX_AMOUNT]}
          onBlur={(e) => validate(e, FIELD_NAME.MAX_AMOUNT)}
          onChange={(e) => handleOnChange(e, FIELD_NAME.MAX_AMOUNT)}
          helperText={validFields[FIELD_NAME.MAX_AMOUNT] ? '' : validationT('maxSumValidation')}
        />
        <TextField
          margin="normal"
          type="number"
          required
          id="defaultValue"
          label={siteSettingsT('defaultValue')}
          variant="standard"
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ width: '250px' }}
          value={formData.defaultAmount}
          onChange={(e) => handleOnChange(e, FIELD_NAME.DEFAULT)}
          onBlur={(e) => validate(e, FIELD_NAME.DEFAULT)}
          error={!validFields[FIELD_NAME.DEFAULT]}
          helperText={validFields[FIELD_NAME.DEFAULT] ? '' : validationT('greaterThan', { value: 0 })}
        />
        <Button
          sx={{ mt: 3 }}
          style={{ float: 'right', width: 150 }}
          variant="contained"
          type="submit"
          disabled={
            !validFields[FIELD_NAME.NAME] || !validFields[FIELD_NAME.MAX_AMOUNT] 
            || !validFields[FIELD_NAME.MIN_AMOUNT] || !validFields[FIELD_NAME.DEFAULT]} 
        >
          {siteSettingsT('create')}
        </Button>
      </Box>
      {error && <Result style={{ mb: 2, mt:2 }} message={error} type={'error'} isShow />}
    </Box>
  );
}

export default CreateReturns;
