import { ThemeProvider } from '@emotion/react';
import { CssBaseline, createTheme } from '@mui/material';
import { blueGrey, grey } from '@mui/material/colors';
import SignInForm from 'components/signInForm/signInForm';

const light = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: blueGrey[200],
    },
    background: {
      // default: blueGrey[200],
      paper: blueGrey[50],
    },
    text: {
      primary: grey[800],
      secondary: grey[500],
    },
  },
});

export const SignInPage = () => {
  return (
    <ThemeProvider theme={light}>
      <CssBaseline />
      <SignInForm />
    </ThemeProvider>
  );
};
