import { Switch, Typography } from '@mui/material';
import { MainThemeContext } from 'context/mainThemeContext';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './switcher.module.scss';

const style = {
  margin: '24px!important',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  fontSize: '13px!important',
  justifyContent: 'center',
};

function Switcher() {
  const { t } = useTranslation('common');
  const { mode, toggleDarkMode } = useContext(MainThemeContext);
  const label = { inputProps: { 'aria-label': 'controlled' } };
  const isDark = mode === 'dark';
  return (
    <div>
      <Typography component="h6" variant="h5" sx={style}>
        {isDark ? t('toLight') : t('toDark')}
        <Switch className={styles.switcher} {...label} onChange={toggleDarkMode} />
      </Typography>
    </div>
  );
}

export default Switcher;
