import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useProviders } from 'hooks/providers/useProviders';
import React, { useState } from 'react';
import GamblingSystemProviderGameNames from './uploadImage/gamblingUpload';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: '0 24px', flexBasis: '60%' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function GamblingGameNames() {
  const [value, setValue] = useState(0);
  const { providersList } = useProviders();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Box style={{ width: '200px' }}>
        <Tabs value={value} onChange={handleChange} orientation="vertical" variant="scrollable">
          {providersList.map(({ name }, i) => {
            return <Tab key={i} label={name} {...a11yProps(0)} sx={{ alignItems: 'start' }} />;
          })}
        </Tabs>
      </Box>
      <Box sx={{ margin: '0 auto' }}>
        {providersList.map(({ id }, index) => {
          return (
            <CustomTabPanel key={id} index={index} value={value}>
              <GamblingSystemProviderGameNames providerId={id} />
            </CustomTabPanel>
          );
        })}
      </Box>
    </Box>
  );
}

export default GamblingGameNames;
