import { Box, Button, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import cx from 'classnames';
import Dialogs from 'components/dialog/dialogs';
import { useTranslation } from 'react-i18next';
import common from 'styles/common.module.scss';
import styles from './verification.module.scss';

interface CleintVerificationUIProps {
  status?: any;
  onClick?: (name: string) => void;
  verifiedImg?: any;
  wrapperClassName?: string;
  wrapperClassContent?: string;
}

export const CleintVerificationUI = ({
  status,
  onClick,
  verifiedImg,
  wrapperClassName,
  wrapperClassContent,
}: CleintVerificationUIProps) => {
  const theme = useTheme();
  const { t: clientDetailsT } = useTranslation('clientsDetails');
  const [showImage, setShowImage] = useState('');
  const [open, setOpen] = useState(false);

  const handleOnClick = (type: string) => {
    onClick?.(type);
  };

  const openImage = (type: string) => {
    setShowImage(verifiedImg[type]);
    setOpen(true);
  };

  if (status === 'required') {
    return <Box className={styles.result}> {clientDetailsT('statusPending')} ...</Box>;
  }

  return (
    <Box className={cx(styles.clientVerified, wrapperClassContent)}>
      <Box>
        <Typography
          variant="subtitle1"
          className={styles.title}
          sx={{
            color: `${theme.palette.mode === 'dark' ? common.mainwhite : common.mainblack}`,
          }}
        >
          {clientDetailsT('titleVerify')}
        </Typography>
      </Box>

      <Box
        sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
        className={cx(wrapperClassName)}
      >
        <Box>
          <img
            src={verifiedImg.frontView}
            style={{ objectFit: 'contain', width: '50px', height: '50px' }}
            onClick={() => openImage('frontView')}
          />

          <img
            src={verifiedImg.rearView}
            style={{ objectFit: 'contain', width: '50px', height: '50px' }}
            onClick={() => openImage('rearView')}
          />
        </Box>
        <Box sx={{ ml: 1, display: 'flex' }}>
          <Button color="success" variant="contained" onClick={() => handleOnClick('approve')}>
            {clientDetailsT('confirm')}
          </Button>
          <Button
            variant="contained"
            sx={{ ml: 1, backgroundColor: '#db0a1c', color: 'white' }}
            onClick={() => handleOnClick('reject')}
          >
            {clientDetailsT('reject')}
          </Button>
        </Box>
      </Box>
      {showImage ? (
        <Dialogs
          imageContent={showImage}
          isOpen={open}
          onClose={() => setOpen(false)}
          forChat={true}
        />
      ) : null}
    </Box>
  );
};
