import { useState } from 'react';
import { generateHeaders, joinMessage } from 'utils/utils';
import { useStorage } from '../authHooks/useStorage';
import { useApiErrors } from 'hooks/errors/useApiErrors';
import LevelService from 'services/levels.service';
import {
  prepareDataForSelectPages,
  prepareTableData,
  preparedColumnNames,
} from 'utils/helpers/dataHelper/prepareDataHelper';
import { IGotoPage } from 'types/sites';
import { ILevel } from 'types/sites';
import { useDispatch } from 'react-redux';
import { setLevelsData, updateLevelData, addCreatedLevel } from 'redux/actions/levelsActions';

export const useLevels = () => {
  const { errorHandler } = useApiErrors();
  const dispatch = useDispatch();
  const { logout, userToken } = useStorage();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [columns, setColumns] = useState<string[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [levelsList, setLevelsList] = useState<ILevel[]>([]);
  const [pageLists, setPageLists] = useState<IGotoPage[]>([]);

  const initHeaders = generateHeaders(userToken());

  const getAllLevels: any = async (query?: string, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: {
          data: { userLevelGroups, totalItems, totalPages },
          status,
        },
      } = await LevelService.getAllLevels(headers, query);
      const preparedData = prepareTableData(userLevelGroups, 'siteLevels');
      const columns = preparedColumnNames(preparedData);
      const pageLists = prepareDataForSelectPages(totalPages);
      setPageLists(pageLists as IGotoPage[]);
      setColumns(columns);
      setTotalPages(totalPages);
      setTotalItems(totalItems);
      setLevelsList(userLevelGroups);
      dispatch(setLevelsData(userLevelGroups));

      return { status };
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getAllLevels(query, newHeader);
      } else {
        console.log('LOG____>getAllLevels', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getLevelById: any = async (id: string, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await LevelService.getLevelById(headers, id);

      return { status, data };
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getLevelById(id, newHeader);
      } else {
        console.log('LOG____>getLevelById', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getAllLevelsWithoutPagination: any = async (headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data },
        status,
      } = await LevelService.getAllLevelsWithoutPagination(headers);
      dispatch(setLevelsData(data));

      return { status };
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getAllLevelsWithoutPagination(newHeader);
      } else {
        console.log('LOG____>getAllLevelsWithoutPafination', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const editLevel: any = async (id: string, body: any, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await LevelService.editLevel(headers, id, body);
      if (status === 200) {
        dispatch(updateLevelData(data));
        return { status, data };
      }
    } catch (err: any) {
      const {
        response: {
          data: { message, status, error },
        },
      } = err;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await editLevel(id, body, newHeader);
      } else {
        console.log('LOG____>editLevel', message);
        setError(joinMessage(error));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const createLevel: any = async (body: any, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await LevelService.createLevel(headers, body);
      dispatch(addCreatedLevel(data));
      return { status, data };
    } catch (err: any) {
      const {
        response: {
          data: { message, status, error },
        },
      } = err;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await createLevel(body, newHeader);
      } else {
        console.log('LOG____>createLevel', message);
        setError(joinMessage(error));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const uploadLevelImage: any = async (
    id: string,
    body: any,
    headers = { ...initHeaders, 'Content-Type': 'multipart/form-data' },
  ) => {
    setIsLoading(true);

    try {
      const {
        data: { data, status },
      } = await LevelService.uploadLevelImage(headers, id, body);
      if (status === 200) {
        return { data, status };
      }
    } catch (err: any) {
      const {
        response: {
          data: { message, status },
        },
      } = err;
      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await uploadLevelImage(id, body, {
          ...newHeader,
          'Content-Type': 'multipart/form-data',
        });
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    getAllLevels,
    editLevel,
    createLevel,
    columns,
    isLoading,
    levelsList,
    totalPages,
    totalItems,
    pageLists,
    getAllLevelsWithoutPagination,
    getLevelById,
    uploadLevelImage,
    resetError: () => setError(''),
    error,
  };
};
