import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import Result from 'components/alert/alert';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { useTranslation } from 'react-i18next';
import SitesList from 'components/filter/siteLists';
import { useSites } from 'hooks/sites/useSites';
import common from 'styles/common.module.scss';
import { usePromoCodes } from 'hooks/sites/usePromoCodes';
import dayjs, { Dayjs } from 'dayjs';
import { FIELD_NAME } from 'types/sites';
import { isValidForPromo } from 'utils/helpers/inputHelper';
import DateTimePickerValue from 'components/datePicker/dateTimeRangePicker';

interface CreateMultiUserPromotionProps {
  mode: string;
  onClose: () => void;
}

const fields = {
  [FIELD_NAME.CODE]: true,
  [FIELD_NAME.AMOUNT]: true,
  [FIELD_NAME.COUNT]: true,
  [FIELD_NAME.MIN_BORDER_OF_DEPOSIT]: true,
};

export const initialData = {
  currencyId: '',
  amount: '',
  usersCount: '',
  code: '',
  isPromotion: true,
  minDepositBorder: null,
};

const style = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
};

function CreateMultiUserPromotion({ onClose, mode }: CreateMultiUserPromotionProps) {
  const code = (Math.random() + 1).toString(36).substring(2, 7);
  const { getSiteById, siteCurrencies } = useSites();
  const [isValidDateValue, setIsValidDateValue] = useState(true);
  const { t } = useTranslation('common');
  const { t: validationT } = useTranslation('validations');
  const { t: promoCodesT } = useTranslation('promoCodes');
  const [selectedSiteId, setSelectedSiteId] = useState('');
  const [formData, setFormData] = useState({ ...initialData, code });
  const [isSetDateEnd, setIsSetDateEnd] = useState(false);
  const [withDateRange, setWithDateRange] = useState(true);
  const [withMinDepositBorder, setWithMinDepositBorder] = useState(false);
  const [valueFrom, setValueFrom] = useState<Dayjs | null>(null);
  const [validFields, setValidFields] = useState({ ...fields });
  const { error, createPromotionPromoCodes } = usePromoCodes();

  const isValidDateChanges = (value: boolean) => {
    setIsValidDateValue(value);
  };

  const validate = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, name: string) => {
    const { value } = e.target;
    setValidFields((fields) => ({
      ...fields,
      [name]: isValidForPromo(value, name),
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let body = {};
    const isValid = !withMinDepositBorder ? !!formData.minDepositBorder : true;
    if (isValidDateValue) {
      if (isSetDateEnd && valueFrom) {
        body = { ...formData, endDate: valueFrom };
      } else {
        body = { ...formData };
      }
      if (isValid) {
        const result = await createPromotionPromoCodes(body, selectedSiteId);
        if (result?.status === 201) {
          onClose();
        }
      } else {
        setValidFields((fields) => ({
          ...fields,
          [FIELD_NAME.MIN_BORDER_OF_DEPOSIT]: false,
        }));
      }
    }
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: string,
  ) => {
    const { value } = e.target;
    const data = { ...formData, [name]: value };
    setFormData(data);
  };

  const handleOnSwicth = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const { checked } = e.target;
    setIsSetDateEnd(checked);
  };

  const handleOnShow = (valueFrom: Dayjs | null | string, valueTo: Dayjs | null | string) => {
    if (valueFrom && valueTo) {
      const data = { fromDepositDate: valueFrom, toDepositDate: valueTo };
      setFormData((formData) => ({ ...formData, ...data }));
    }
  };

  const handleOnCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    if (!checked) {
      const data = { ...formData, fromDepositDate: null, toDepositDate: null };
      setFormData(data);
    }
    setWithDateRange(checked);
  };

  const handleOnCheckMinDeposit = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    if (checked) {
      const data = {
        ...formData,
        minDepositBorder: null,
        fromDepositDate: null,
        toDepositDate: null,
      };
      setFormData(data);
    }
    setWithMinDepositBorder(checked);
  };

  const handleOnChangeDateTime = (newValue: Dayjs | null) => {
    setValueFrom(newValue);
  };

  const handleFilter = async (name: string, id: string) => {
    if (id) {
      await getSiteById(id);
    }
    setSelectedSiteId(id);
  };

  const handleOnRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = event.target;
    const data = { ...formData, currencyId: id };
    setFormData(data);
  };

  useEffect(() => {
    if (siteCurrencies.length === 1) {
      const data = { ...formData, currencyId: siteCurrencies[0].id + '' };
      setFormData(data);
    }
  }, [siteCurrencies]);

  return (
    <>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        {t(mode)} {promoCodesT('multiuserPromocodes')}
      </Typography>
      <Box sx={style}>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
          <Box>
            <Box sx={{ mb: 2, mt: 2 }}>
              <SitesList onSelectSite={handleFilter} width={'100%'} />
              {siteCurrencies.length && selectedSiteId ? (
                <Box
                  sx={{
                    display: 'flex',
                    input: { margin: '2px' },
                    width: '100%',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  {siteCurrencies.map((el: any, index: number) => {
                    return (
                      <Box sx={{ mr: 1, display: 'flex', alignItems: 'center' }} key={index}>
                        <input
                          type="radio"
                          name="gameOfDay"
                          id={el.id}
                          onChange={handleOnRadioChange}
                          checked={+formData.currencyId === +el.id || siteCurrencies.length === 1}
                        />
                        <Typography variant="subtitle1" sx={{ ml: 1, fontSize: '13px' }}>
                          {el.code}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              ) : selectedSiteId ? (
                <Typography
                  variant="subtitle1"
                  color={common.mainwarning}
                  sx={{ ml: 2, fontSize: '13px', opacity: 0.8 }}
                >
                  {promoCodesT('noCurrencyHas')}
                </Typography>
              ) : null}
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                value={formData.code ?? ''}
                fullWidth
                margin="normal"
                name="code"
                label={promoCodesT(FIELD_NAME.CODE)}
                variant="standard"
                InputLabelProps={{ shrink: true }}
                placeholder="Mag555"
                onChange={(e) => handleOnChange(e, FIELD_NAME.CODE)}
                onBlur={(e) => validate(e, FIELD_NAME.CODE)}
                error={!validFields[FIELD_NAME.CODE]}
                helperText={
                  validFields[FIELD_NAME.CODE] ? '' : validationT('lengthMustBe', { length: 5 })
                }
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                type="number"
                value={formData.usersCount ?? ''}
                fullWidth
                margin="normal"
                name="count"
                label={promoCodesT('clientCount')}
                variant="standard"
                placeholder="20"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => handleOnChange(e, FIELD_NAME.COUNT)}
                onBlur={(e) => validate(e, FIELD_NAME.COUNT)}
                error={!validFields[FIELD_NAME.COUNT]}
                helperText={
                  validFields[FIELD_NAME.COUNT] ? '' : validationT('greaterThan', { value: 0 })
                }
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                type="number"
                value={formData.amount ?? ''}
                fullWidth
                margin="normal"
                name="amount"
                label={promoCodesT(FIELD_NAME.AMOUNT)}
                variant="standard"
                placeholder="100"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => handleOnChange(e, FIELD_NAME.AMOUNT)}
                onBlur={(e) => validate(e, FIELD_NAME.AMOUNT)}
                error={!validFields[FIELD_NAME.AMOUNT]}
                helperText={
                  validFields[FIELD_NAME.AMOUNT] ? '' : validationT('greaterThan', { value: 0 })
                }
              />
            </Box>
            <FormGroup>
              <FormControlLabel
                sx={{ mr: 3 }}
                control={
                  <Checkbox
                    checked={withMinDepositBorder}
                    onChange={handleOnCheckMinDeposit}
                    sx={{
                      '& .MuiSvgIcon-root': { fontSize: 14, color: 'text.primary' },
                    }}
                  />
                }
                label={promoCodesT('withMinDepositBorder')}
                labelPlacement="end"
              />
            </FormGroup>
            <Box sx={{ mb: 2 }}>
              {!withMinDepositBorder ? (
                <TextField
                  type="number"
                  value={formData.minDepositBorder ?? ''}
                  fullWidth
                  margin="normal"
                  name="amount"
                  label={promoCodesT('minBorderOfDeposit')}
                  variant="standard"
                  placeholder="100"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => handleOnChange(e, FIELD_NAME.MIN_BORDER_OF_DEPOSIT)}
                  onBlur={(e) => validate(e, FIELD_NAME.MIN_BORDER_OF_DEPOSIT)}
                  error={!validFields[FIELD_NAME.MIN_BORDER_OF_DEPOSIT]}
                  helperText={
                    validFields[FIELD_NAME.MIN_BORDER_OF_DEPOSIT]
                      ? ''
                      : validationT('greaterThan', { value: 0 })
                  }
                />
              ) : null}
            </Box>
            {!withMinDepositBorder ? (
              <>
                <FormGroup>
                  <FormControlLabel
                    sx={{ mr: 3 }}
                    control={
                      <Checkbox
                        checked={withDateRange}
                        onChange={handleOnCheck}
                        sx={{
                          '& .MuiSvgIcon-root': { fontSize: 14, color: 'text.primary' },
                        }}
                      />
                    }
                    label={promoCodesT('withDateRange')}
                    labelPlacement="end"
                  />
                </FormGroup>
                <Box sx={{ minWidth: 200, mr: 3 }}>
                  {withDateRange ? (
                    <DateTimePickerValue
                      isValidChanges={isValidDateChanges}
                      isSmall="small"
                      onShow={handleOnShow}
                      dateFrom={dayjs().subtract(1, 'year')}
                      needDefaultDate
                    />
                  ) : null}
                </Box>
              </>
            ) : null}
            <Box sx={{ mb: 2 }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isSetDateEnd}
                      onChange={(e) => handleOnSwicth(e, 'isSpecific')}
                    />
                  }
                  label={promoCodesT('setEndDateTime')}
                />
              </FormGroup>
              {isSetDateEnd ? (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']}>
                    <DateTimePicker
                      label={promoCodesT('endDate')}
                      value={valueFrom}
                      ampm={false}
                      onChange={(newValue) => handleOnChangeDateTime(newValue)}
                      disablePast
                      slotProps={{
                        textField: { size: 'small' },
                        layout: {
                          sx: {
                            textTransform: 'capitalize',
                            color: 'white',
                          },
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              ) : null}
            </Box>
          </Box>
          {error && <Result style={{ mb: 2 }} message={error} type={'error'} isShow />}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Button
              fullWidth
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={
                !selectedSiteId ||
                !validFields[FIELD_NAME.CODE] ||
                !validFields[FIELD_NAME.AMOUNT] ||
                formData.amount === '' ||
                formData.code === '' ||
                formData.usersCount === ''
              }
            >
              {t(mode)}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default CreateMultiUserPromotion;
