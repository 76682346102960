import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { useEffect, useState } from 'react';
import { IActiveItem, IItem } from './gamblingStatistics';
import CardImage from 'components/card/card';
import SitesList from 'components/filter/siteLists';

interface RightContentProps {
  activeItem: IActiveItem;
}

interface ContentProps {
  itemList: IItem[] | [];
  isPopular?: boolean;
}
const style = {
  overflow: 'hidden',
  overflowY: 'auto',
  overflowX: 'hidden',
  padding: 3,
  minWidth: '450px',
};
const Content = ({ itemList, isPopular }: ContentProps) => {
  return (
    <Box sx={style}>
      {itemList?.map((item, index) => {
        return (
          <Box key={index}>
            <CardImage item={item} isPopular={isPopular} />
          </Box>
        );
      })}
    </Box>
  );
};

const dynamicKey: { [key: string]: string } = {
  '1': 'populars',
  '2': 'profits',
  '3': 'deprofits',
};

export const RightContent = ({ activeItem }: RightContentProps) => {
  const [value, setValue] = useState('1');
  const [activeTabData, setActiveTabData] = useState(activeItem.populars);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    changeTabData(newValue);
  };
  const handleFilter = (name: string, value: string) => {
    // setFilterData((filterby) => ({ ...filterby, [name]: value }));
  };

  const changeTabData = (value: string) => {
    const props = dynamicKey[value];
    const active = activeItem[props] as unknown as IItem[];
    setActiveTabData([...active]);
  };

  useEffect(() => {
    setValue('1');
    changeTabData('1');
  }, [activeItem]);

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <SitesList onSelectSite={handleFilter} margin="8px 16px 32px 0" />
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Most Popular" value="1" />
            <Tab label="Profitable" value="2" />
            <Tab label="Unprofitable" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Content itemList={activeTabData} isPopular />
        </TabPanel>
        <TabPanel value="2">
          <Content itemList={activeTabData} />
        </TabPanel>
        <TabPanel value="3">
          <Content itemList={activeTabData} />
        </TabPanel>
      </TabContext>
    </Box>
  );
};
