import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styles from './notFound.module.scss';
import { routing } from 'constants/routing';

export default function NotFound() {
  const navigate = useNavigate();

  const handleOnClick = () => {
    // TODO: navigate to the correct url considering is user logged in or no
    navigate(-1);
  };

  return (
    <div className={styles.container}>
      <h1> Ooops! Page not found</h1>
      <Button variant="contained" onClick={handleOnClick}>
        Go Back
      </Button>
    </div>
  );
}
