import { useState } from 'react';
import { generateHeaders, joinMessage } from 'utils/utils';
import { useStorage } from '../authHooks/useStorage';
import { useApiErrors } from 'hooks/errors/useApiErrors';
import {
  prepareDataForSelectPages,
  prepareTableData,
  preparedColumnNames,
} from 'utils/helpers/dataHelper/prepareDataHelper';
import { IGotoPage, IPromoUsers } from 'types/sites';
import { ILevel } from 'types/sites';
import { useDispatch } from 'react-redux';
import PromoCodeService from 'services/promo.service';
import { addCreatedPromotionPromoCode, setPromotionPromoCode } from 'redux/actions/sitesActions';

export const usePromoCodes = () => {
  const { errorHandler } = useApiErrors();
  const dispatch = useDispatch();
  const { logout, userToken } = useStorage();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [columns, setColumns] = useState<string[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [promotionPromoList, setPromotionPromoList] = useState<ILevel[]>([]);
  const [promoAppliedUsersList, setPromoAppliedUsersList] = useState<IPromoUsers[]>([]);
  const [pageLists, setPageLists] = useState<IGotoPage[]>([]);

  const initHeaders = generateHeaders(userToken());

  
  const getAllMultiUserPromoCode: any = async (query?: string, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: {
          data: { promoCodes, totalItems, totalPages },
          status,
        },
      } = await PromoCodeService.getAllMultiUserPromoCode(headers, query);
      const preparedData = prepareTableData(promoCodes, 'promotionMulti');
      const columns = preparedColumnNames(preparedData);
      const pageLists = prepareDataForSelectPages(totalPages);
      setPageLists(pageLists as IGotoPage[]);
      setColumns(columns);
      setTotalPages(totalPages);
      setTotalItems(totalItems);
      setPromotionPromoList(promoCodes);
      dispatch(setPromotionPromoCode(promoCodes));
      return { status };
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getAllMultiUserPromoCode(query, newHeader);
      } else {
        console.log('LOG____>getAllLevels', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getAllMultiUserAppliedPromoCode: any = async (promoId: string, query?: string, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: {
          data: { userPromotions, totalItems, totalPages },
          status,
        },
      } = await PromoCodeService.getAllMultiUserAppliedPromoCode(headers, promoId, query);
      const preparedData = prepareTableData(userPromotions, '');
      const columns = preparedColumnNames(preparedData);
      const pageLists = prepareDataForSelectPages(totalPages);
      setPageLists(pageLists as IGotoPage[]);
      setColumns(columns);
      setTotalPages(totalPages);
      setTotalItems(totalItems);
      setPromoAppliedUsersList(userPromotions);
      return { status };
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getAllMultiUserAppliedPromoCode(promoId, query, newHeader);
      } else {
        console.log('LOG____>getAllLevels', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getAllPromotionPromoCodes: any = async (query?: string, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: {
          data: { promoCodes, totalItems, totalPages },
          status,
        },
      } = await PromoCodeService.getAllPromotionPromoCode(headers, query);
      const preparedData = prepareTableData(promoCodes, 'promotion');
      const columns = preparedColumnNames(preparedData);
      const pageLists = prepareDataForSelectPages(totalPages);
      setPageLists(pageLists as IGotoPage[]);
      setColumns(columns);
      setTotalPages(totalPages);
      setTotalItems(totalItems);
      setPromotionPromoList(promoCodes);
      dispatch(setPromotionPromoCode(promoCodes));
      return { status };
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getAllPromotionPromoCodes(query, newHeader);
      } else {
        console.log('LOG____>getAllLevels', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const createPromotionPromoCodes: any = async (
    body: any,
    sideId: string,
    headers = initHeaders,
  ) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await PromoCodeService.createPromotionPromoCode(headers, sideId, body);
      dispatch(addCreatedPromotionPromoCode(data));
      return { status, data };
    } catch (err: any) {
      const {
        response: {
          data: { message, status, error },
        },
      } = err;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await createPromotionPromoCodes(body, sideId, newHeader);
      } else {
        console.log('LOG____>createLevel', message);
        setError(joinMessage(error));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const deletePromoCode: any = async (
    id: string,
    headers = initHeaders,
  ) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await PromoCodeService.deletePromoCode(headers, id);
      return { status, data };
    } catch (err: any) {
      const {
        response: {
          data: { message, status, error },
        },
      } = err;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await deletePromoCode(id, newHeader);
      } else {
        console.log('LOG____>createLevel', message);
        setError(joinMessage(error));
      }
    } finally {
      setIsLoading(false);
    }
  };
  
  return {
    createPromotionPromoCodes,
    getAllMultiUserAppliedPromoCode,
    getAllPromotionPromoCodes,
    getAllMultiUserPromoCode,
    deletePromoCode,
    columns,
    isLoading,
    promotionPromoList,
    promoAppliedUsersList,
    totalPages,
    totalItems,
    pageLists,
    resetError: () => setError(''),
    error,
  };
};
