import http from '../http-common';

class FinanceProvidersDataService {
  getPaymentProviderBalance = (headers, id) => {
    return http.get(`/finance-providers/${id}/balance`, { headers });
  };
}

const FinanceProvidersService = new FinanceProvidersDataService();

export default FinanceProvidersService;
