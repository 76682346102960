import http from '../http-common';

class BalanceDataService {
  updateClientBalance = (headers, id, body, query = '') => {
    return http.put(`/users/${id}/balance/?${query}`, body, { headers });
  };

  updateClientBonus = (headers, id, body) => {
    return http.post(`/users/${id}/bonus`, body, { headers });
  };

  getClientAllBalance = (headers, id, query = '') => {
    return http.get(`/users/${id}/balance/changes/all?isWithdraw=false${query}`, { headers });
  };

  getClientAllWithdraw = (headers, id, query = '') => {
    return http.get(`/users/${id}/balance/changes/all?isWithdraw=true&isAnnulation=false${query}`, { headers });
  };

  getAllWithDrawChanges = (headers, query = '') => {
    return http.get(`/balance/changes/all?isWithdraw=true${query}`, { headers });
  };

  getAllDepositChanges = (headers, siteId, query = '') => {
    return http.get(`/site/${siteId}/balance-changes?isRealDeposits=true&isDeposit=true${query}`, { headers });
  };

  getAllBalance = (headers) => {
    return http.get(`/balance/all`, { headers });
  };

  getAllStatus = (headers) => {
    return http.get(`/balance/status/all`, { headers });
  };

  getProfit = (headers, query = '') => {
    return http.get(`/balance/profit?${query}`, { headers });
  };
}

const BalanceService = new BalanceDataService();

export default BalanceService;
