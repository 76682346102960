import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { ICurrency } from 'hooks/currency/useCurrency';
import { useEffect, useState } from 'react';
import styles from './checkbox.module.scss';
import { ILanguage } from 'hooks/language/useLanguage';

interface CheckboxesProps {
  isLanguage?: boolean;
  idList?: number[];
  onChange?: (id: number) => void;
  list?: ICurrency[] | ILanguage[];
}
export const CurrencyCheckboxes = ({ idList, list, isLanguage, onChange }: CheckboxesProps) => {
  const [listOfIds, setListOfIds] = useState(idList ? [...idList] : []);

  const handleOnChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = +event.currentTarget.id;
    onChange?.(id);
  };

  useEffect(() => {
    if (idList) setListOfIds([...idList]);
  }, [idList]);

  return (
    <FormGroup sx={{ mt: 2, mb: 2 }} className={styles.currencies}>
      {list?.map(({ name, id, code }, index) => {
        return (
          <FormControlLabel
            sx={{ minWidth: 'max-content' }}
            key={index}
            control={
              <Checkbox
                checked={listOfIds?.includes(+id)}
                id={id + ''}
                onChange={handleOnChecked}
                sx={{ '& .MuiSvgIcon-root': { fontSize: 16 }, m: 0, p: 0.5 }}
                inputProps={{ readOnly: true }}
              />
            }
            label={isLanguage ? name : code}
          />
        );
      })}
    </FormGroup>
  );
};
