// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

/** TODO: write all properties with types */
import { useState, useEffect, useRef } from 'react';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  Box,
  Paper,
  IconButton,
  InputBase,
  useTheme,
  Modal,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { StrictModeDroppable } from '../strictModeDroppable';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import { grey } from '@mui/material/colors';
import Dialogs from 'components/dialog/dialogs';
import noImage from 'assets/image/no-image.png';
import { useSelector } from 'react-redux';
import Result from 'components/alert/alert';
import { useDragGame } from 'hooks/game/useDragGame';
import { setSiteCategoryGames, updateCategoryGames } from 'redux/actions/sitesActions';
import { useDispatch } from 'react-redux';
import LoaderWithBg from 'components/loader/loader';
import { useTranslation } from 'react-i18next';
import { SUCCESS } from 'constants/common';
import { makeStyles } from '@mui/styles';
import GameSearch from 'components/gameComposerSearch/gameSearch';
import { style } from 'components/modal/modal';
import styles from './gameSettings.module.scss';

const useStyles = makeStyles(() => ({
  paper: {
    display: 'flex',
    marginBottom: '16px',
    height: '40px',
    border: 'thin solid white',
    backgroundColor: 'unset!important',
  },
}));

const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);
  return result;
};

const grid = 8;

const getListStyle = () => ({
  padding: grid,
  width: '30%',
});
const initBodyForRequest = {
  categoryGames: [],
  isGameDesktop: null,
  isGameMobile: null,
  exceptionsMobile: [],
  exceptionsDesktop: [],
};

interface IGameSettingsProps {
  siteId: string;
  currentCategory: any;
  activeItem;
  select: any;
  resetCategoryGames: () => void;
  resetProviderGames: () => void;
  onScrollLeftSide: (id: string) => void;
  onScrollRightSide: () => void;
  hasMoreRightSide: boolean;
  hasMoreLeftSide: boolean;
  isLoadingLeftSide: boolean;
  isLoadingRightSide: boolean;
  closeEdit: () => void;
  onQueryChange: (val: string, name: string) => void;
}

const GameSettings = ({
  resetProviderGames,
  siteId,
  currentCategory,
  activeItem,
  subProviders,
  onScrollLeftSide,
  onScrollRightSide,
  resetCategoryGames,
  hasMoreLeftSide,
  hasMoreRightSide,
  isLoadingLeftSide,
  isLoadingRightSide,
  closeEdit,
  onQueryChange,
}: IGameSettingsProps) => {
  let timeOutId: ReturnType<typeof setTimeout>;
  const orderRef = useRef({ hasOrdered: false });
  const deviceRef = useRef({ hasChangedDevice: false });

  const { t } = useTranslation('siteSettings');
  const { t: common } = useTranslation('common');
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    error,
    isLoading,
    putGamesInCategory,
    deleteCategoryGame,
    getGameOfDay,
    saveSiteCategoryGames,
    updateGameOfDay,
    changeGamesDevice,
  } = useDragGame();
  const [selectedProvider, setSelectedProvider] = useState('all');
  const [subFilterCheckBox, setSubFilterCheckBox] = useState('');
  const [selectedProviderId, setSelectedProviderId] = useState('');
  const [exeptionsList, setExeptionsList] = useState([]);
  const [gamesList, setGamesList] = useState([]);
  const [open, setOpen] = useState(false);
  const [requestBody, setRequestBody] = useState(initBodyForRequest);
  const [success, setSuccess] = useState('');
  const [type, setType] = useState<AlertColor>(SUCCESS);
  const [gameOfDayItem, setGameOfDayItem] = useState({ id: '', gameName: '' });
  const [isChecked, setIsChecked] = useState({ allMobile: null, allDesktop: null });
  const categoryGames = useSelector<any>((state) => state.siteCategories.categoryGames);
  const providerGames = useSelector<any>((state) => state.providerGames.providerGames);
  const [selectedDevice, setSelectedDevice] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const classes = useStyles();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const getItemStyle = (isDragging: boolean, draggableStyle) => ({
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? (theme.palette.mode === 'dark' ? grey[500] : grey[200]) : '#b2bec4',
    ...draggableStyle,
  });

  const move = async (
    siteId,
    source,
    droppableSource,
    droppableDestination,
    selectedProvider,
    selectedDevice,
    activeItem,
    exeptionsList,
    gamesList,
  ) => {
    const sourceClone = Array.from(source);

    const prevGameId =
      (categoryGames[droppableDestination.index - 1]?.id ?? '') !== ''
        ? +(categoryGames[droppableDestination.index - 1]?.id + '').split('_')?.[0]
        : null;
    const lastGameId =
      (categoryGames[droppableDestination.index]?.id ?? '') !== ''
        ? +(categoryGames[droppableDestination.index]?.id + '').split('_')?.[0]
        : null;

    if (selectedProvider === 'all') {
      const body = {
        isAllSelected: true,
        exceptions: [...exeptionsList],
        games: [],
        providerId: activeItem,
        subProviderId: null,
        order:
          prevGameId === null && lastGameId === null
            ? null
            : {
                firstElement: prevGameId,
                lastElement: lastGameId,
              },
      };
      const res = await putGamesInCategory(siteId, currentCategory.id, body);
      if (res?.status === 200) {
        resetCategoryGames();
        resetProviderGames();
      }
    } else if (
      (selectedDevice !== '' || selectedProvider !== '') &&
      (gamesList.length || exeptionsList.length || subFilterCheckBox)
    ) {
      if (gamesList.length) {
        const body = {
          isAllSelected: false,
          exceptions: [...exeptionsList],
          games: [...gamesList],
          providerId: activeItem,
          subProviderId: selectedProviderId ? selectedProviderId : null,
          isMobile: selectedDevice
            ? selectedDevice === 'Mobile'
              ? !!selectedDevice
              : false
            : null,
        };
        const gameOrder = [prevGameId, ...gamesList, lastGameId];

        const res = await putGamesInCategory(siteId, currentCategory.id, body);
        if (res?.status === 200) {
          if (prevGameId || (lastGameId && !isLoading)) {
            await saveSiteCategoryGames(siteId, currentCategory.id, { gameOrder });
          }

          for (const id of gamesList) {
            const item = sourceClone.filter((el) => el.id == id);
            categoryGames.splice(droppableDestination.index, 0, item[0]);
          }

          const items = categoryGames.map((element) => {
            element = {
              ...element,
              id: (element.id + '').split('_')?.[0] + `_${activeItem}`,
              isGameMobile: false,
              isGameDesktop: true,
            };
            return element;
          });
          resetCategoryGames();
        }
      } else {
        const body = {
          isAllSelected: false,
          exceptions: [...exeptionsList],
          games: [...gamesList],
          providerId: activeItem,
          subProviderId: selectedProviderId ? selectedProviderId : null,
          isMobile: selectedDevice
            ? selectedDevice === 'Mobile'
              ? !!selectedDevice
              : false
            : null,
          order:
            prevGameId === null && lastGameId === null
              ? null
              : {
                  firstElement: prevGameId,
                  lastElement: lastGameId,
                },
        };

        const res = await putGamesInCategory(siteId, currentCategory.id, body);
        if (res?.status === 200) {
          // const { data } = res;

          // const items = data.map((element) => {
          //   element = {
          //     ...element,
          //     id: (element.id + '').split('_')?.[0] + `_${activeItem}`,
          //     isGameMobile: false,
          //     isGameDesktop: true,
          //   };
          //   return element;
          // });
          // dispatch(setSiteCategoryGames(items));
          resetCategoryGames();
          resetProviderGames();
        }
      }
    } else {
      const removed = { ...sourceClone[droppableSource.index] };

      const body = {
        isAllSelected: false,
        exceptions: [],
        games: [removed.id],
        providerId: activeItem,
        subProviderId: null,
      };
      const gameOrder = [prevGameId, removed.id, lastGameId];
      const res = await putGamesInCategory(siteId, currentCategory.id, body);
      if (res?.status === 200) {
        if (prevGameId || (lastGameId && !isLoading)) {
          await saveSiteCategoryGames(siteId, currentCategory.id, { gameOrder });
        }
        categoryGames.splice(droppableDestination.index, 0, removed);

        const items = categoryGames.map((element) => {
          element = {
            ...element,
            id: (element.id + '').split('_')?.[0] + `_${activeItem}`,
            isGameMobile: false,
            isGameDesktop: true,
          };
          return element;
        });
        dispatch(setSiteCategoryGames(items));
      }
    }
  };

  const id2List = {
    droppable: 'items',
    droppable2: 'selected',
  };

  const getList = (id: any) => {
    if (id2List[id] === 'selected') {
      return [...categoryGames];
    }
    return [...providerGames];
  };

  const onDragEnd = async (result: DropResult) => {
    try {
      const { source, destination } = result;
      if (!destination) {
        return;
      }

      if (source.droppableId === destination.droppableId) {
        const items = reorder(getList(source.droppableId), source.index, destination.index);
        if (source.droppableId === 'droppable2') {
          orderRef.current = { hasOrdered: true };

          dispatch(setSiteCategoryGames([...items]));
        }
      } else {
        if (source.droppableId !== 'droppable2') {
          await move(
            siteId,
            getList(source.droppableId),
            source,
            destination,
            selectedProvider,
            selectedDevice,
            activeItem.id,
            exeptionsList,
            gamesList,
          );
          setExeptionsList([]);
          setGamesList([]);
        }
      }
    } catch (err) {
      /**TODO: write logic */
      console.log(err, 'catch-error');
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    if (value) {
      const subProvider = subProviders.filter((el) => el.id === value)[0];
      setSelectedProvider(subProvider.name);
      setSubFilterCheckBox(true);
    } else {
      setSelectedProvider(value);
    }
    const query = value ? `&subProviderId=${value}` : '';

    setSelectedProviderId(value);
    setExeptionsList([]);
    onQueryChange(query, 'subProvider');
  };

  const handleChangeDevice = (event: SelectChangeEvent) => {
    const { value } = event.target;
    const query = value ? (value === 'Mobile' ? `&isMobile=true` : '&isMobile=false') : '';
    setSelectedDevice(value);
    setSubFilterCheckBox(true);
    setExeptionsList([]);
    setGamesList([]);
    onQueryChange(query, 'device');
  };

  const handleOnChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    if (checked) {
      setSelectedProvider('all');
    } else {
      setSelectedProvider('');
    }
    setExeptionsList([]);
    setGamesList([]);
  };

  const handleOnCheckedSub = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setSubFilterCheckBox(checked);
    setExeptionsList([]);
    setGamesList([]);
  };

  const handleOnCheckedDeviceAll = async (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string,
  ) => {
    if (!deviceRef.current.hasChangedDevice) {
      deviceRef.current.hasChangedDevice = true;
    }

    const { checked } = event.target;
    const key = name === 'allDesktop' ? 'isGameDesktop' : 'isGameMobile';
    const exceptionArrayKey = name === 'allDesktop' ? 'exceptionsDesktop' : 'exceptionsMobile';
    const temp = _.cloneDeep(categoryGames);
    temp.map((el) => {
      el[key] = checked;
      return el;
    });

    setIsChecked((prev) => ({ ...prev, [name]: checked }));
    const categoryGamesList = requestBody.categoryGames.map((el) => ({ ...el, [key]: checked }));

    if (!checked) {
      setRequestBody((state) => ({
        ...state,
        [key]: checked,
        categoryGames: categoryGamesList,
        [exceptionArrayKey]: [],
      }));
    } else {
      setRequestBody((state) => ({ ...state, categoryGames: categoryGamesList, [key]: checked }));
    }
    dispatch(setSiteCategoryGames(temp));
  };

  const handleOnCheckedDevice = async (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string,
    id: string | null,
  ) => {
    if (!deviceRef.current.hasChangedDevice) {
      deviceRef.current.hasChangedDevice = true;
    }
    const { checked } = event.target;
    const originId = +(id + '').split('_')[0];
    const temp = _.cloneDeep(categoryGames);
    const body = { ...requestBody };
    const exceptionArrayKey = name === 'isGameDesktop' ? 'exceptionsDesktop' : 'exceptionsMobile';
    const selectedItem = temp.filter((el) => el.id === id)[0];

    if (id) {
      selectedItem[name] = checked;
      if (body[name]) {
        if (!checked) {
          if (!body[exceptionArrayKey].includes(originId)) {
            const categoryGamesList = requestBody.categoryGames.map((el) => {
              if (el.gameId === originId) {
                return { ...el, [name]: !body[name] };
              }
              return el;
            });

            setRequestBody((state) => ({
              ...state,
              categoryGames: categoryGamesList,
              [exceptionArrayKey]: [...state[exceptionArrayKey], originId],
            }));
          }
        } else {
          const index = body[exceptionArrayKey].findIndex((el) => el === originId);
          body[exceptionArrayKey].splice(index, 1);
          const categoryGamesList = requestBody.categoryGames.map((el) => {
            if (el.gameId === originId) {
              return { ...el, [name]: body[name] };
            }
            return el;
          });
          setRequestBody((state) => ({
            ...state,
            categoryGames: categoryGamesList,
            [exceptionArrayKey]: [...state[exceptionArrayKey]],
          }));
        }
      } else {
        const item = {
          gameId: originId,
          isGameDesktop: selectedItem.isGameDesktop,
          isGameMobile: selectedItem.isGameMobile,
        };

        const index = body.categoryGames.findIndex((el) => el?.gameId === originId);
        if (index === -1) {
          setRequestBody((state) => ({
            ...state,
            categoryGames: [...state.categoryGames, item],
          }));
        } else {
          body.categoryGames[index] = item;
          setRequestBody((state) => ({
            ...state,
            categoryGames: [...state.categoryGames],
          }));
        }
      }
    }

    dispatch(setSiteCategoryGames(temp));
  };

  const handleOnCheckedProvider = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const { checked } = event.target;

    if (subFilterCheckBox) {
      if (!checked) {
        if (!exeptionsList.includes(id)) {
          setExeptionsList((list) => [...list, id]);
        }
      } else {
        const index = exeptionsList.findIndex((el) => el === id);
        exeptionsList.splice(index, 1);
        setExeptionsList([...exeptionsList]);
      }
    } else if (selectedProvider === 'all') {
      if (!checked) {
        if (!exeptionsList.includes(id)) {
          setExeptionsList((list) => [...list, id]);
        }
      } else {
        const index = exeptionsList.findIndex((el) => el === id);
        exeptionsList.splice(index, 1);
        setExeptionsList([...exeptionsList]);
      }
    } else {
      if (checked) {
        if (!gamesList.includes(id)) {
          setGamesList((list) => [...list, id]);
        }
      } else {
        const index = gamesList.findIndex((el) => el === id);
        gamesList.splice(index, 1);
        setGamesList([...gamesList]);
      }
    }
  };

  const handleOnGameOfDay = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { id } = event.target;
    const result = await getGameOfDay(siteId);
    if (result?.status === 200) {
      setOpen(true);
      setGameOfDayItem((item) => ({ ...item, id }));

      const { data } = result;
      if (!data) {
        setGameOfDayItem((item) => ({ ...item, gameName: '' }));
      } else {
        if (data.category) {
          setGameOfDayItem((item) => ({
            ...item,
            gameName: t('hasGameOfDayWithCategory', {
              name: `${data.name}(${data.subprovider})`,
              category: data.category,
            }),
          }));
        } else {
          setGameOfDayItem((item) => ({
            ...item,
            gameName: t('hasGameOfDay', { name: `${data.name}(${data.subprovider})` }),
          }));
        }
      }
    }
  };

  const confirmDialog = async (id: string) => {
    const res = await updateGameOfDay(siteId, { gameOfDay: (id + '').split('_')[0] });
    if (res?.status === 200) {
      const temp = _.cloneDeep(categoryGames);
      const item = temp.filter((el) => (el.id + '').split('_')[0] === (id + '').split('_')[0]);
      const itemIsGameOfDay = temp.filter((el) => el.isGameOfDay);
      if (item.length) {
        item[0].isGameOfDay = true;
      }
      if (itemIsGameOfDay.length) {
        itemIsGameOfDay[0].isGameOfDay = false;
      }
      dispatch(setSiteCategoryGames(temp));
    }
  };

  const handleOnClose = () => {
    setOpen(false);
  };

  const handleOnSave = async () => {
    const temp = _.cloneDeep(categoryGames);
    let orderSaveResult;
    let changeDeviceSaveResult;

    if (orderRef.current.hasOrdered) {
      const gameOrder = temp.map((el) => +(el.id + '').split('_')[0]);
      orderSaveResult = await saveSiteCategoryGames(siteId, currentCategory.id, { gameOrder });
      orderRef.current.hasOrdered = false;
    }
    if (deviceRef.current.hasChangedDevice) {
      changeDeviceSaveResult = await changeGamesDevice(siteId, currentCategory.id, requestBody);
      deviceRef.current.hasChangedDevice = false;
    }

    if (orderSaveResult?.status === 200 || changeDeviceSaveResult?.status === 200) {
      setSuccess(common('savedSuccess'));
      setType(SUCCESS);
      setIsChecked({ allMobile: null, allDesktop: null });
      timeOutId = setTimeout(() => {
        setSuccess('');
      }, 4000);
    }
  };

  const handleOnClickDelete = async (id) => {
    const originId = (id + '').split('_')?.[0];

    const res = await deleteCategoryGame(1, currentCategory.id, originId);
    if (res?.status === 200) {
      if (categoryGames.length === 1) {
        dispatch(setSiteCategoryGames([]));
      }

      dispatch(updateCategoryGames(originId));
      //resetCategoryGames();
      resetProviderGames();
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleGameBySearch = async (gameIdes: string[]) => {
    const body = {
      isAllSelected: false,
      exceptions: [],
      games: [...gameIdes],
      providerId: activeItem,
      subProviderId: null,
      isMobile: null,
    };
    const gameOrder = [null, ...gameIdes, (categoryGames[0].id + '').split('_')?.[0]];

    const res = await putGamesInCategory(siteId, currentCategory.id, body);

    if (res?.status === 200) {
      const result = await saveSiteCategoryGames(siteId, currentCategory.id, { gameOrder });
      if (result?.status === 200) {
        setOpenModal(false);
        resetCategoryGames();
      }
    }
  };

  useEffect(() => {
    setSelectedDevice('');
    setSelectedProviderId('');
    setSelectedProvider('');
    return () => clearTimeout(timeOutId);
  }, [activeItem, subProviders]);

  return (
    <>
      {isLoading ? (
        <LoaderWithBg isOpen={isLoading || isLoadingLeftSide || isLoadingRightSide} />
      ) : null}

      <Box sx={{ display: 'flex', flexDirection: 'column', flexBasis: '100%' }}>
        <Box>
          <Paper onSubmit={handleSubmit} className={classes.paper} component="form">
            <InputBase
              size="small"
              fullWidth
              sx={{ m: 2 }}
              placeholder={common('searchGame')}
              value={name}
              onFocus={() => setOpenModal(true)}
              disabled={openModal}
              inputProps={{ 'aria-label': 'search game' }}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: `${
              theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff'
            }`,
            paddingBottom: '24px',
            paddingLeft: '24px',
          }}
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <Box
              sx={{
                alignItems: 'center',
                justifyContent: 'space-around',
                display: 'flex',
                flexDirection: 'column',
                paddingTop: '12px',
              }}
            >
              <Box
                sx={{
                  minWidth: 200,
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  display: 'flex',
                  width: '100%',
                  padding: '0 6px',
                  margin: '4px 0',
                }}
              >
                {!((selectedProvider && selectedProvider !== 'all') || selectedDevice) ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedProvider === 'all' && !selectedDevice}
                        onChange={handleOnChecked}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 16 }, m: 0, p: 0.5 }}
                        inputProps={{ readOnly: true }}
                        disabled={
                          (selectedProvider && selectedProvider !== 'all') || selectedDevice
                        }
                      />
                    }
                    label={common('selectAll')}
                  />
                ) : null}

                {selectedProviderId || selectedDevice ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={subFilterCheckBox}
                        onChange={handleOnCheckedSub}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 16 }, m: 0, p: 0.5 }}
                        inputProps={{ readOnly: true }}
                      />
                    }
                    label={common('selectAll')}
                  />
                ) : null}

                <FormControl size="small">
                  <InputLabel id="provider-label">{t('providers')}</InputLabel>
                  <Select
                    sx={{ minWidth: 180, mr: 3 }}
                    labelId="provider-label"
                    id="provider-select"
                    label={t('providers')}
                    value={selectedProviderId}
                    onChange={handleChange}
                  >
                    <MenuItem value="">
                      <em>{common('none')}</em>
                    </MenuItem>
                    {subProviders.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl size="small">
                  <InputLabel id="device-label">{t('device')}</InputLabel>
                  <Select
                    sx={{ minWidth: 150, mr: 3 }}
                    id="device-select"
                    label={t('device')}
                    onChange={handleChangeDevice}
                    value={selectedDevice}
                  >
                    <MenuItem value="">
                      <em>{common('none')}</em>
                    </MenuItem>
                    {['Mobile', 'Lobby'].map((item, index) => {
                      return (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
              <StrictModeDroppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    id="scrollableDiv"
                    ref={provided.innerRef}
                    style={{
                      ...getListStyle(snapshot.isDraggingOver),
                      width: '600px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      minHeight: 591,
                      height: 591,
                      overflow: 'hidden',
                      overflowY: 'auto',
                    }}
                  >
                    <InfiniteScroll
                      style={{ minWidth: '600px', padding: '0 8px' }}
                      scrollableTarget="scrollableDiv"
                      dataLength={providerGames?.length}
                      next={() =>
                        onScrollLeftSide?.(
                          selectedProviderId || (selectedDevice ? selectedDevice : 'no-mobile'),
                        )
                      }
                      hasMore={hasMoreLeftSide}
                      loader={
                        isLoadingLeftSide ? (
                          <Box sx={{ textAlign: 'center' }}>{common('loading')}</Box>
                        ) : null
                      }
                      endMessage={
                        !hasMoreLeftSide ? (
                          <p style={{ textAlign: 'center' }}>
                            <b>{t('noMoreData')}</b>
                          </p>
                        ) : null
                      }
                    >
                      {providerGames?.map((item, index) => (
                        <Draggable
                          key={item?.id + ''}
                          draggableId={item?.id + ''}
                          index={index}
                          isDragDisabled={
                            item.isCategoryIncluded ||
                            !!categoryGames?.filter((el) => el.id == item.id + `_${activeItem.id}`)
                              ?.length ||
                            !!categoryGames?.filter((el) => el.id == item.id)?.length
                          }
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...getItemStyle(snapshot.isDragging, provided.draggableProps.style),
                                // width: '100%',
                                cursor:
                                  item.isCategoryIncluded ||
                                  categoryGames?.filter(
                                    (el) => el.id == item.id + `_${activeItem.id}`,
                                  )?.length ||
                                  categoryGames?.filter((el) => el.id == item.id)?.length
                                    ? 'no-drop'
                                    : 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                opacity:
                                  item.isCategoryIncluded ||
                                  categoryGames?.filter(
                                    (el) => el.id == item.id + `_${activeItem.id}`,
                                  )?.length ||
                                  categoryGames?.filter((el) => el.id == item.id)?.length
                                    ? '0.5'
                                    : '1',
                              }}
                            >
                              {selectedProvider === item.subProvider ||
                              selectedProvider === 'all' ||
                              selectedDevice ? (
                                selectedProviderId || selectedDevice ? (
                                  <FormControlLabel
                                    id={item.id + ''}
                                    sx={{ mr: 2, color: '#4caf50' }}
                                    control={
                                      <Checkbox
                                        checked={
                                          subFilterCheckBox
                                            ? !exeptionsList.includes(item.id)
                                            : gamesList.includes(item.id)
                                        }
                                        onChange={(e) => handleOnCheckedProvider(e, item.id)}
                                        sx={{
                                          '& .MuiSvgIcon-root': {
                                            fontSize: 14,
                                            color: 'text.primary',
                                          },
                                        }}
                                        disabled={
                                          item.isCategoryIncluded ||
                                          categoryGames?.filter(
                                            (el) => el.id == item.id + `_${activeItem.id}`,
                                          )?.length ||
                                          categoryGames?.filter((el) => el.id == item.id)?.length
                                        }
                                      />
                                    }
                                    label={''}
                                  />
                                ) : (
                                  <FormControlLabel
                                    id={item.id + ''}
                                    sx={{ mr: 2, color: '#4caf50' }}
                                    control={
                                      <Checkbox
                                        checked={
                                          ((selectedProvider === item.subProvider ||
                                            selectedProvider === 'all') &&
                                            !exeptionsList.includes(item.id) &&
                                            !categoryGames?.filter(
                                              (el) => el.id === item.id + `_${activeItem.id}`,
                                            )?.length) ||
                                          (selectedDevice
                                            ? item.isMobile &&
                                              !exeptionsList.includes(item.id) &&
                                              !categoryGames?.filter(
                                                (el) => el.id === item.id + `_${activeItem.id}`,
                                              )?.length
                                            : null)
                                        }
                                        onChange={(e) => handleOnCheckedProvider(e, item.id)}
                                        sx={{
                                          '& .MuiSvgIcon-root': {
                                            fontSize: 14,
                                            color: 'text.primary',
                                          },
                                        }}
                                        disabled={
                                          item.isCategoryIncluded ||
                                          categoryGames?.filter(
                                            (el) => el.id == item.id + `_${activeItem.id}`,
                                          )?.length ||
                                          categoryGames?.filter((el) => el.id == item.id)?.length
                                        }
                                      />
                                    }
                                    label={''}
                                  />
                                )
                              ) : null}
                              <img
                                src={item.image}
                                width="50"
                                onError={({ currentTarget }) => (currentTarget.src = noImage)}
                              />
                              <Typography sx={{ ml: 2 }}>
                                {/* {`${item.id}-${item.name}(${item.subProvider})`} */}
                                {`${item.name}(${item.subProvider})`}
                              </Typography>
                              {item.isMobile ? (
                                <span className={styles.has_free}>mobile</span>
                              ) : null}
                              {!item.isMobile ? (
                                <span className={styles.has_lobby}>lobby</span>
                              ) : null}
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </InfiniteScroll>
                    {provided.placeholder}
                  </div>
                )}
              </StrictModeDroppable>
            </Box>
            <StrictModeDroppable droppableId="droppable2">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={{
                    ...getListStyle(snapshot.isDraggingOver),
                    paddingTop: '12px',
                    minWidth: 600,
                    flexBasis: '60%',
                    height: 660,
                  }}
                >
                  <Box sx={{ display: 'flex' }}>
                    <Typography sx={{ m: 2, fontWeight: 'bolder' }}>
                      {currentCategory.title}
                    </Typography>
                    {categoryGames?.length ? (
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          sx={{ mr: 4 }}
                          control={
                            <Checkbox
                              checked={isChecked.allMobile}
                              onChange={(e) => handleOnCheckedDeviceAll(e, 'allMobile')}
                            />
                          }
                          label={<InstallMobileIcon />}
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          sx={{ mr: 3 }}
                          control={
                            <Checkbox
                              checked={isChecked.allDesktop}
                              onChange={(e) => handleOnCheckedDeviceAll(e, 'allDesktop')}
                            />
                          }
                          label={<InstallDesktopIcon />}
                          labelPlacement="end"
                        />
                      </FormGroup>
                    ) : null}
                  </Box>
                  <Box className={styles.menuItem_content} id="scrollableDivCategory">
                    <InfiniteScroll
                      style={{ minWidth: '600px', padding: '6px' }}
                      scrollableTarget="scrollableDivCategory"
                      dataLength={categoryGames?.length}
                      next={onScrollRightSide}
                      hasMore={hasMoreRightSide}
                      loader={
                        isLoadingRightSide ? (
                          <Box sx={{ textAlign: 'center' }}>{common('loading')}</Box>
                        ) : null
                      }
                      endMessage={
                        !hasMoreRightSide && categoryGames?.length > 8 ? (
                          <p style={{ textAlign: 'center' }}>
                            <b>{t('noMoreGames')}</b>
                          </p>
                        ) : !categoryGames?.length ? (
                          <p style={{ textAlign: 'center' }}>
                            <b>{`${t('dragAndDrop')} "${currentCategory.title}"`}</b>
                          </p>
                        ) : null
                      }
                    >
                      {categoryGames?.map((item, index) => (
                        <Draggable
                          key={item.id + `_${activeItem}`}
                          draggableId={item.id + ''}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...getItemStyle(snapshot.isDragging, provided.draggableProps.style),
                                padding: '14px',
                                height: 'inherit',
                              }}
                            >
                              <div className={styles.actions}>
                                <img
                                  src={item.image ?? noImage}
                                  width="50"
                                  onError={({ currentTarget }) => (currentTarget.src = noImage)}
                                />
                                <Typography sx={{ ml: 2, flexBasis: '30%' }}>
                                  {`${item.name}(${item.subProvider ?? 'name'})`}
                                  {/* {`${item.id}-${item.name}(${item.subProvider})`} */}
                                </Typography>

                                <FormGroup aria-label="position" sx={{ flexWrap: 'nowrap' }} row>
                                  {item.isMobile ? (
                                    <FormControlLabel
                                      disabled={!item.isMobile}
                                      sx={{ mr: 3 }}
                                      control={
                                        <Checkbox
                                          checked={
                                            isChecked.allMobile
                                              ? !requestBody.exceptionsMobile.includes(
                                                  +(item.id + '').split('_')[0],
                                                )
                                              : item.isGameMobile
                                          }
                                          onChange={(e) =>
                                            handleOnCheckedDevice(e, 'isGameMobile', item.id)
                                          }
                                        />
                                      }
                                      label={<InstallMobileIcon />}
                                      labelPlacement="end"
                                    />
                                  ) : (
                                    <Box sx={{ minWidth: '64px', mr: 3 }}> </Box>
                                  )}

                                  <FormControlLabel
                                    sx={{ mr: 3 }}
                                    control={
                                      <Checkbox
                                        checked={
                                          isChecked.allDesktop
                                            ? !requestBody.exceptionsDesktop.includes(
                                                +(item.id + '').split('_')[0],
                                              )
                                            : item.isGameDesktop
                                        }
                                        onClick={(e) =>
                                          handleOnCheckedDevice(e, 'isGameDesktop', item.id)
                                        }
                                      />
                                    }
                                    label={<InstallDesktopIcon />}
                                    labelPlacement="end"
                                  />
                                </FormGroup>
                                <Box sx={{ display: 'flex', input: { margin: 0 } }}>
                                  <input
                                    type="radio"
                                    checked={item.isGameOfDay}
                                    name="gameOfDay"
                                    id={item.id}
                                    onChange={handleOnGameOfDay}
                                  />
                                  <Typography variant="subtitle1" sx={{ ml: 2, fontSize: '13px' }}>
                                    {t('gameOfDayTitle')}
                                  </Typography>
                                </Box>
                                <Button
                                  sx={{ color: 'white' }}
                                  id={item.id}
                                  onClick={() => handleOnClickDelete(item.id)}
                                >
                                  <DeleteForeverIcon />
                                </Button>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </InfiniteScroll>
                  </Box>
                  {provided.placeholder}
                </div>
              )}
            </StrictModeDroppable>
          </DragDropContext>
        </Box>
        {(success || error) && (
          <Result style={{ mt: 2 }} message={success || error} type={type} isShow />
        )}
        <Box className={styles.actions_btn}>
          <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2, mr: 4 }} onClick={closeEdit}>
            {common('back')}
          </Button>
          <Button
            fullWidth
            onClick={handleOnSave}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={!orderRef.current.hasOrdered && !deviceRef.current.hasChangedDevice}
          >
            {common('save')}
          </Button>
        </Box>
      </Box>
      {!isLoading ? (
        <Dialogs
          id={gameOfDayItem.id}
          title={t('gameOfDayTitle')}
          content={gameOfDayItem.gameName ? gameOfDayItem.gameName : t('gameOfDayContent')}
          isOpen={open}
          onClose={handleOnClose}
          onConfirm={confirmDialog}
          btnText={common('yes')}
        />
      ) : null}
      {openModal ? (
        <Modal open={openModal} onClose={handleClose} sx={{ minWidth: 400 }}>
          <Box
            sx={{
              ...style,
              minWidth: '600px',
              minHeight: '500px',
              bgcolor: `${
                theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white'
              }`,
            }}
          >
            <GameSearch providerId={activeItem.id} onAdd={handleGameBySearch} />
          </Box>
        </Modal>
      ) : null}
    </>
  );
};

export default GameSettings;
