import { useState } from 'react';
import { generateHeaders, joinMessage } from 'utils/utils';
import { useStorage } from '../authHooks/useStorage';
import { useApiErrors } from 'hooks/errors/useApiErrors';
import {
  prepareDataForSelectPages,
  prepareTableData,
  preparedColumnNames,
} from 'utils/helpers/dataHelper/prepareDataHelper';
import { IGotoPage } from 'types/sites';
import { useDispatch } from 'react-redux';
import WheelsService from 'services/wheels.service';
import { addWheel, setWheelsData } from 'redux/actions/wheelsActions';

export const useWheels = () => {
  const { errorHandler } = useApiErrors();
  const dispatch = useDispatch();
  const { logout, userToken } = useStorage();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [columns, setColumns] = useState<string[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [pageLists, setPageLists] = useState<IGotoPage[]>([]);

  const initHeaders = generateHeaders(userToken());

  const getSiteWheelSetting: any = async (siteId: string, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: {
          data,
          status,
        },
      } = await WheelsService.getSiteWheelSetting(headers, siteId);
      if (status === 200) {
        return { status, data };
      }
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getSiteWheelSetting(siteId, newHeader);
      } else {
        console.log('LOG____>getSiteWheelSetting', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getAllWheelsGroups: any = async (headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: {
          data,
          status,
        },
      } = await WheelsService.getAllWheelsGroups(headers);
      if (status === 200) {
        const preparedData = prepareTableData(data, 'wheelsGroups');
        const columns = preparedColumnNames(preparedData);
        const pageLists = prepareDataForSelectPages(totalPages);
        setPageLists(pageLists as IGotoPage[]);
        setColumns(columns);
        setTotalPages(totalPages);
        setTotalItems(totalItems);
        dispatch(setWheelsData(data));
        return { status };
      }
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getAllWheelsGroups(newHeader);
      } else {
        console.log('LOG____>getAllWheelsGroups', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const createWheelsGroup: any = async (body: any, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await WheelsService.createWheelsGroup(headers, body);
      dispatch(addWheel(data));
      return { status };
    } catch (err: any) {
      const {
        response: {
          data: { message, status, error },
        },
      } = err;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await createWheelsGroup(body, newHeader);
      } else {
        console.log('LOG____>createWheelsGroup', message);
        setError(joinMessage(error));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const saveWheelsSetting: any = async (body: any, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await WheelsService.saveWheelsSetting(headers, body);
      return { status, data };
    } catch (err: any) {
      const {
        response: {
          data: { message, status, error },
        },
      } = err;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await saveWheelsSetting(body, newHeader);
      } else {
        console.log('LOG____>createWheelsGroup', message);
        setError(joinMessage(error));
      }
    } finally {
      setIsLoading(false);
    }
  };


  return {
    createWheelsGroup,
    getAllWheelsGroups,
    getSiteWheelSetting,
    saveWheelsSetting,
    columns,
    isLoading,
    totalPages,
    totalItems,
    pageLists,
    error,
  };
};
