import { Box, TextareaAutosize, Typography } from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import { styled } from '@mui/system';
import { useEffect, useState } from 'react';
import common from 'styles/common.module.scss';

interface EmptyTextareaProps {
  defaultValue?: string;
  onChange: (val: string) => void;
  isModal?: boolean;
  error?: string;
}

const StyledTextarea = styled(TextareaAutosize)(
  ({ theme }) => `
  width: 420px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 12px;
  border-radius: 12px;
  color: ${theme.palette.mode === 'dark' ? theme.palette.text.primary : grey[900]};
  background: ${theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff'};
  border: 0.5px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[200]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[500] : blue[200]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

export default function EmptyTextarea({
  defaultValue,
  onChange,
  isModal,
  error,
}: EmptyTextareaProps) {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (defaultValue !== undefined) setValue(defaultValue);
  }, [defaultValue]);

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;
    setValue(value);
    onChange(value);
  };

  return (
    <Box>
      <StyledTextarea
        sx={{
          width: isModal ? '100%' : null,
          borderRadius: '4px',
          ml: 1,
          borderColor: `${error ? common.mainwarning : 'unset'}`,
        }}
        aria-label="empty textarea"
        value={value}
        onChange={handleOnChange}
      />
      {error ? (
        <Typography variant="subtitle1" sx={{ fontSize: '12px', color: common.mainwarning }}>
          {error}
        </Typography>
      ) : null}
    </Box>
  );
}
