import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { ReactNode, useEffect, useState } from 'react';
import styles from './modal.module.scss';

export const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 500,
  boxShadow: 24,
  p: 3,
  maxHeight: 'calc(100vh - 50px)',
  height: 'inherit',
  overflow: 'auto',
  overflowX: 'hidden',
  minHeight: '200px',
};

interface IModalProps {
  title?: string;
  children: ReactNode;
  isOpen: boolean;
}

export default function Modals({ isOpen, title, children }: IModalProps) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setOpen(false);
  }, [isOpen]);

  return (
    <div className={styles.btn_add}>
      <Button sx={{ m: 3 }} variant="contained" onClick={handleOpen}>
        {title}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{children}</Box>
      </Modal>
    </div>
  );
}
