import { TabContext, TabList } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { useFinance } from 'hooks/finances/useFinance';
import { useEffect, useState } from 'react';
import Provider from './provider/provider';
import { useDispatch } from 'react-redux';
import { setSitePaymentProvidersSettings } from 'redux/actions/sitesActions';

interface IPaymentProviderSettingsProps {
  siteId: string;
}

export interface IProviderSettings {
  isPrimary: boolean;
  primaryActiveDate: string;
  primaryPayinLimit: string;
  [key: string]: any;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function PaymentProvidersSettings({ siteId }: IPaymentProviderSettingsProps) {
  const [value, setValue] = useState('1');
  const [tabLists, setTabLists] = useState([] as IProviderSettings[]);
  const { getAllPaymentProviders } = useFinance();
  const dispatch = useDispatch();

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ padding: '0 24px', flexBasis: '60%', margin: 'auto' }}>{children}</Box>
        )}
      </div>
    );
  }

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const getData = async () => {
    const params = `?siteId=${siteId}`;
    const result = await getAllPaymentProviders(params);

    if (result?.status) {
      setTabLists(result?.data ?? []);
      dispatch(setSitePaymentProvidersSettings(result.data));
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {tabLists.map((elm: any, i: number) => {
              return <Tab key={i} label={elm?.name} value={i + 1 + ''} />;
            })}
          </TabList>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
          {tabLists.map(({ id }: any, index: number) => {
            return (
              <CustomTabPanel key={id} index={index + 1} value={+value}>
                <Provider providerId={id} index={index} />
              </CustomTabPanel>
            );
          })}
        </Box>
      </TabContext>
    </Box>
  );
}

export default PaymentProvidersSettings;
