export const tabPermissionsHorizontal = {
  finance: [
    {
      name: 'balanceSettings',
      role: ['*'],
    },
    {
      name: 'deposit',
      role: ['*'],
    },
    {
      name: 'withdraw',
      role: ['*'],
    },
    {
      name: 'profit',
      role: ['main'],
    },
  ],
};

export const tabPermissionsVertical = {
  userDetails: [
    { index: 0, name: 'mainInfo', role: ['*'], style: { minWidth: '255px' } },
    { index: 1, name: 'financialSettings', role: ['*'], style: null },
    { index: 2, name: 'gameHistory', role: ['*'], style: null },
    { index: 3, name: 'messagesAndConversation', role: ['*'], style: null },
    { index: 4, name: 'gamblingSettings', role: ['main'], style: null },
    { index: 5, name: 'authorizationHistory', role: ['*'], style: null },
    { index: 6, name: 'balanceChangeHistory', role: ['*'], style: null },
    { index: 7, name: 'freeSpins', role: ['*'], style: null },
    { index: 8, name: 'dublicates', role: ['*'], style: null },
    { index: 9, name: 'wheelBonuses', role: ['*'], style: null },
    { index: 10, name: 'bonuses', role: ['*'], style: null },
  ],
};
