import { useState } from 'react';
import { generateHeaders } from 'utils/utils';
import { useStorage } from '../authHooks/useStorage';
import { useApiErrors } from 'hooks/errors/useApiErrors';
import BalanceService from 'services/balance.service';
import {
  prepareDataForSelectPages,
  prepareTableData,
  preparedColumnNames,
} from 'utils/helpers/dataHelper/prepareDataHelper';
import { IGotoPage } from 'types/sites';
import FinanceService from 'services/finance.service';
import FinanceProvidersService from 'services/financeProviders.service';
import { financeDepositColumns, financeLogColumns } from 'utils/helpers/columnsNameOrdering';

export const useFinance = () => {
  const { errorHandler } = useApiErrors();
  const { logout, userToken } = useStorage();
  const [columns, setColumns] = useState<string[] | []>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [balanceSum, setBalanceSum] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [pageLists, setPageLists] = useState<IGotoPage[] | []>([]);
  const [withdrawList, setWithdrawList] = useState<any[]>([]);
  const [depositList, setDepositList] = useState<any[]>([]);

  const [error, setError] = useState('');

  const initHeaders = generateHeaders(userToken());

  const getAllWithDrawChanges: any = async (query: string, headers = initHeaders) => {
    if (!isLoading) setIsLoading(true);
    try {
      const {
        data: {
          data: { balanceChanges, totalPages, totalItems },
        },
      } = await BalanceService.getAllWithDrawChanges(headers, query);
      let preparedData = [];
      let pageLists: any = [];

      if (balanceChanges.length) {
        preparedData = prepareTableData(balanceChanges, 'clients');
        pageLists = prepareDataForSelectPages(totalPages);
        const columns = preparedColumnNames(preparedData);
        setColumns(columns);
      }
      setTotalPages(totalPages);
      setTotalItems(totalItems);
      setPageLists(pageLists as IGotoPage[]);
      setWithdrawList(preparedData);
      setIsLoading(false);
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getAllWithDrawChanges(query, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    }
  };

  const getAllDepositChanges: any = async (
    siteId: string,
    query: string,
    headers = initHeaders,
  ) => {
    if (!isLoading) setIsLoading(true);
    try {
      const {
        data: {
          data: { siteBalanceChanges, totalPages, totalItems, balanceSum },
        },
      } = await BalanceService.getAllDepositChanges(headers, siteId, query);
      let preparedData = [];
      let pageLists: any = [];

      if (siteBalanceChanges.length) {
        preparedData = prepareTableData(siteBalanceChanges, 'clients');
        pageLists = prepareDataForSelectPages(totalPages);
        const columns = preparedColumnNames(preparedData, financeDepositColumns);
        setColumns(columns);
      }
      setTotalPages(totalPages);
      setBalanceSum(balanceSum);
      setTotalItems(totalItems);
      setPageLists(pageLists as IGotoPage[]);
      setDepositList(preparedData);
      setIsLoading(false);
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getAllDepositChanges(siteId, query, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    }
  };

  const getAllPaymentProviders: any = async (query?: string, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await FinanceService.getAllFinanceProviders(headers, query);
      if (status === 200) {
        return { status, data };
      }
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getAllPaymentProviders(newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getAllFinanceProvidersWithMethods: any = async (headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await FinanceService.getAllFinanceProvidersWithMethods(headers);
      if (status === 200) {
        return { status, data };
      }
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getAllFinanceProvidersWithMethods(newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const updatePaymentProviderSettings: any = async (
    body: any,
    providerId: string,
    headers = initHeaders,
  ) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await FinanceService.updatePaymentProvidersSettings(headers, providerId, body);
      if (status === 200) {
        return { status, data };
      }
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await updatePaymentProviderSettings(newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getPaymentProvidersAllPayIns: any = async (query: string, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: {
          data: { payInTransactions, totalPages, totalItems },
        },
      } = await FinanceService.getFinanceProviderAllPayIns(headers, query);
      let preparedData = [];
      let pageLists: any = [];
      if (payInTransactions.length) {
        preparedData = prepareTableData(payInTransactions, 'providerPayIns');
        pageLists = prepareDataForSelectPages(totalPages);
        const columns = preparedColumnNames(preparedData, financeLogColumns);
        setColumns(columns);
      }
      setTotalPages(totalPages);
      setTotalItems(totalItems);
      setPageLists(pageLists as IGotoPage[]);
      setDepositList(preparedData);
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getPaymentProvidersAllPayIns(query, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getPaymentProviderBalance: any = async (id: string, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await FinanceProvidersService.getPaymentProviderBalance(headers, id);
      if (status === 200) {
        return { status, data };
      }
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getPaymentProviderBalance(id, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    getAllWithDrawChanges,
    getAllPaymentProviders,
    getPaymentProvidersAllPayIns,
    getAllFinanceProvidersWithMethods,
    getPaymentProviderBalance,
    columns,
    balanceSum,
    totalPages,
    pageLists,
    error,
    isLoading,
    withdrawList,
    depositList,
    totalItems,
    getAllDepositChanges,
    updatePaymentProviderSettings,
  };
};
