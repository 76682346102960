export enum FIELD_NAME {
  WHEEL_AMOUNT_LIMIT = 'amountLimit',
  MERCHANT_ID = 'token',
  WITHDRAW_WEIDGER = 'withdraw_weidger',
  DEPOSIT_WEIDGER = 'deposit_weidger',
  BONUS_WEIDGER = 'bonus_weidger',
  MERCHANT_KEY = 'key',
  URL = 'baseURL',
  SHOP_ID = 'shop_id',
  PASSWORD = 'password',
  HOST = 'host',
  SECRET_1 = 'secret_1',
  SECRET_2 = 'secret_2',
  APIKEY = 'apiKey',
  MINVALUE = 'minValue',
  MAXVALUE = 'maxValue',
  NAME = 'name',
  USER_MIN_DEPOSIT = 'userMinDeposit',
  USER_MONTH_DEPOSIT = 'userMonthDeposit',
  PRIMARY_PAYIN_LIMIT = 'primaryPayinLimit',
  PRIMARY_ACTIVE_DATE = 'primaryActiveDate',
  CURRENCY = "currency",
  SUB_NAME = "name"
}

export interface IBonusCreating {
  /**TODO: replace any with correct type */
  levelGroup: any;
  levelGroupId: string;
  title: string;
  description: any;
  bannerImage: string;
  mainImage: string;
  weidger: number;
  isAvailable: boolean;
  weidgerDate: any;
}

export interface IReturns {
  id: string;
  name: string;
  defaultAmount: string;
  minAmount: string;
  maxAmount: number;
}

export interface IWheelSetting {
  amountLimit: string;
  groupId: string;
  userMinDeposit: string;
  userMonthDeposit: string;
  values: string[];
}
