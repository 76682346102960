import { GridValueGetterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import FilterField from 'components/filter/columnFilter';
import { useClients } from 'hooks/clients/useClients';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { IFilterData } from 'types/accounts';
import { columnsName, dialog, ROW_PER_PAGE } from 'constants/common';
import { dateFormat, isEmpty, makeQueryUrl } from 'utils/utils';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TablePagination,
} from '@mui/material';
import DropDownSelect from 'components/select/select';
import Dialogs from 'components/dialog/dialogs';
import { useTranslation } from 'react-i18next';
import { useProviders } from 'hooks/providers/useProviders';
import { useDispatch } from 'react-redux';
import { setResetAll } from 'redux/actions/commonActions';
import dayjs from 'dayjs';
import { Typography } from '@material-ui/core';
import styles from '../table.module.scss';

interface GameHistoryTableProps {
  clientId: string;
}
export default function GameHistoryTable({ clientId }: GameHistoryTableProps) {
  const { t } = useTranslation('common');
  const { t: clientsT } = useTranslation('clients');
  const { t: clientDetailsT } = useTranslation('clientsDetails');
  const dispatch = useDispatch();
  const {
    isLoading,
    clientGameHistory,
    closeUserGameSession,
    columns: columnNames,
    pageLists,
    getClientGameHistory,
    updateClientGameHistory,
    totalItems,
  } = useClients();
  const { providersList, getAllProviders } = useProviders(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROW_PER_PAGE);
  const [selectedProvider, setSelectedProvider] = useState('');
  const [action, setAction] = useState('');
  const [activeId, setActiveId] = useState('');
  const [filterData, setFilterData] = useState<IFilterData | object>({});
  const [resetGoToPage, setResetGoToPage] = useState(false);
  const [sortData, setSortData] = useState<IFilterData | object>({});

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (newPage >= +pageLists[pageLists.length - 1].val) {
      setPage(+pageLists[pageLists.length - 1].val);
    } else {
      setPage(newPage);
    }
    setResetGoToPage((prev) => !prev);
  };

  const handleFilter = (name: string, value: string) => {
    setFilterData((filterby) => ({ ...filterby, [name]: value }));
  };

  const handleSortBy = (sortBy: string, sortDir: string) => {
    setSortData({ [sortBy]: sortDir });
  };

  const handleResetAll = () => {
    dispatch(setResetAll(true));
    setFilterData({});
    setSortData({});
    setSelectedProvider('');
  };

  const handleSelectPage = (page: string) => {
    setPage(+page - 1);
  };

  const onButtonClick = (id: string, action: string) => {
    if (action === 'showLog') {
      window.open(`/game/logs/${id}`, '_blank');
    } else {
      setAction(action);
      setActiveId(id);
    }
  };

  const actionFields = [
    {
      width: 160,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <>
            {params.row.isAlive ? (
              <Button
                sx={{ display: 'flex', backgroundColor: '#b80909', fontSize: '0.8rem' }}
                fullWidth
                variant="contained"
                onClick={() => onButtonClick(params.row.id, 'closeSession')}
              >
                {clientDetailsT('closeSession')}
              </Button>
            ) : (
              <Button
                sx={{ display: 'flex', fontSize: '0.8rem' }}
                fullWidth
                variant="contained"
                onClick={() => onButtonClick(params.row.id, 'showLog')}
              >
                {clientDetailsT('gameLog')}
              </Button>
            )}
          </>
        );
      },
    },
  ];

  const generateDinamicColumns = useMemo(() => {
    const columns = columnNames.map((el) => {
      if (el === columnsName.STARTED_AT || el === columnsName.END_AT) {
        return {
          field: el,
          width: Math.ceil(window.innerWidth / (columnNames.length + 1)),
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isCamelCase
            />
          ),
          valueGetter: (params: GridValueGetterParams) =>
            `${
              el === columnsName.STARTED_AT
                ? dateFormat(params.row.startedAt)
                : params.row.endedAt
                ? dateFormat(params.row.endedAt)
                : ''
            }`,
        };
      } else if (el === 'gameName') {
        return {
          field: el,
          width: Math.ceil(window.innerWidth / (columnNames.length + 1)),
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isCamelCase
            />
          ),
          renderCell: (params: GridValueGetterParams) => (
            <Box>
              <Typography>{params.row.gameName} </Typography>
              <img src={params.row.gameImage} width="40" />
            </Box>
          ),
        };
      }
      return {
        field: el,
        width:
          el === columnsName.ID
            ? 100
            : el === 'denomination' || el === 'balanceType'
            ? 150
            : Math.ceil(window.innerWidth / (columnNames.length + 2)),
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => (
          <FilterField
            fieldName={el}
            onBlur={(value) => handleFilter(el, value)}
            onSort={handleSortBy}
            sortData={sortData}
            isCamelCase
          />
        ),
        valueGetter: (params: GridValueGetterParams): any =>
          el === 'site' ? `${params.row[el].name}` : `${params.row[el]}`,
      };
    });
    return [...columns, ...actionFields];
  }, [columnNames, sortData]);

  const columns = generateDinamicColumns as GridColDef[];

  const handleProviderChange = (e: SelectChangeEvent) => {
    const { value } = e.target;
    setFilterData((filterby) => ({ ...filterby, providerId: value }));
    setSelectedProvider(value);
  };

  const handleOnDelete = async (id: string) => {
    const result = await closeUserGameSession(clientId, id);
    if (result) {
      if (!result.data.isAlive) updateClientGameHistory();
    }
  };

  const handleOnCloseDialog = () => {
    setAction('');
  };

  useEffect(() => {
    const query = makeQueryUrl(filterData);
    const sortBy = Object.entries(sortData)[0];

    let paramsQuery = isEmpty(sortData)
      ? query
      : `${query}&sortBy=${sortBy[0]}&sortDir=${sortBy[1]}`;

    paramsQuery += `&page=${page + 1}&pageSize=${rowsPerPage}`;

    getClientGameHistory(clientId, paramsQuery);
  }, [filterData, sortData, page, rowsPerPage, clientId]);

  useEffect(() => {
    if (!isLoading && !providersList.length) {
      getAllProviders();
    }
  }, [isLoading, providersList]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box className={styles.filter_box}>
        <div className={styles.filter_btn}>
          <Box sx={{ display: 'flex', mt: 2, mb: 2 }}>
            <DropDownSelect
              totalPages={pageLists}
              onSelectPage={handleSelectPage}
              reset={resetGoToPage}
            />
            {Object.values(filterData).join() || Object.values(sortData).join() ? (
              <Button className={styles.reset_all_btn} variant="contained" onClick={handleResetAll}>
                {t('ressetAll')}
              </Button>
            ) : null}
          </Box>
        </div>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ minWidth: 200, mr: 3 }}>
          <FormControl sx={{ width: 300, mr: 3 }} size="small">
            <InputLabel id="currency-label">{clientDetailsT('allSystems')}</InputLabel>
            <Select
              labelId="currency-label"
              id="currency-select"
              label={clientDetailsT('allSystems')}
              value={selectedProvider}
              onChange={handleProviderChange}
            >
              <MenuItem value="">
                <em>{clientsT('none')}</em>
              </MenuItem>
              {providersList.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <TablePagination
        component="div"
        count={totalItems}
        page={page}
        labelRowsPerPage={t('rowsPerPage')}
        labelDisplayedRows={({ from, to, count }) =>
          `${from >= to ? to : from}-${to} ${t('of')} ${count}`
        }
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <DataGrid
        autoHeight
        rows={clientGameHistory}
        columns={columns}
        hideFooterPagination={true}
        getRowHeight={() => 'auto'}
        initialState={{
          columns: {
            columnVisibilityModel: {
              // Hide column currency, the other columns will remain visible
              providerId: false,
              gameImage: false,
              gameUUID: false,
            },
          },
        }}
        sx={{
          '.MuiDataGrid-iconButtonContainer': {
            visibility: 'visible',
          },
          '.MuiDataGrid-sortIcon': {
            opacity: 'inherit !important',
          },
          '.MuiDataGrid-cell': {
            '&:last-child': {
              justifyContent: 'center !important',
            },
          },
          '.MuiDataGrid-footerContainer': {
            display: 'none',
          },
        }}
      />
      <Dialogs
        id={activeId}
        title={t(dialog.CLOSE_SESSION)}
        isOpen={!!activeId && action === 'closeSession'}
        onClose={handleOnCloseDialog}
        onConfirm={handleOnDelete}
        btnText={t('yes')}
      />
    </Box>
  );
}
