import { ICurrency } from 'hooks/currency/useCurrency';
import { ILanguage } from 'hooks/language/useLanguage';

export interface ISites {
  createdAt: string;
  deletedAt: string | null;
  disabledText: null;
  id: number;
  metaDescription: string;
  metaName: string;
  name: string;
  title: string;
  updatedAt: string | null;
  url: string;
}

export interface ICreateSite {
  name: string;
  title: string;
  url: string;
  currencyIds: number[];
  languageIds: number[];
  isActive: boolean;
}

export interface IEditSite {
  name: string;
  title: string;
  url: string;
  isActive: boolean;
  metaDescription: string | null;
  metaName: string | null;
  disabledText: string | null;
  cashbackPercent: number | null;
  isPayoutsVisible: boolean;
  isPayinsVisible: boolean;
  isBonusesVisible: boolean;
  currencyIds: number[];
  languageIds: number[];
  currencies?: ICurrency[];
  languages?: ILanguage[];
  phoneConfirmMethod: string[];
  isWheelEnabled: boolean;
  canAgentWithdraw: boolean;
  [key: string]: any;
}

export enum FIELD_NAME {
  NAME = 'name',
  URL = 'url',
  TITLE = 'title',
  DISPLAY_DEPOSIT_HISTORY = 'isPayinsVisible',
  DISPLAY_BONUS_HISTORY = 'isBonusesVisible',
  DISPLAY_WITHDRAW_HISTORY = 'isPayoutsVisible',
  DISABLE_TEXT = 'disabledText',
  IS_ACTIVE = 'isActive',
  META_NAME = 'metaName',
  META_DESCRIPTION = 'metaDescription',
  CASHBACK_PERCENT = 'cashbackPercent',
  IS_WHEEL_ENABLE = 'isWheelEnabled',
  CAN_AGENT_WITHDRAW = 'canAgentWithdraw',
  PHONE_CONFIRM_METHOD = 'phoneConfirmMethod',
  CALL = 'call',
  SMS = 'sms',
  MIN_DEPOSIT = 'minDepositBorder',
  MAX_DEPOSIT = 'maxDepositBorder',
  AMOUNT = 'amount',
  IMAGE = 'image',
  BANNER_IMAGE = 'bannerImage',
  BONUS_WEIDGER = 'weidger',
  WEIDGER_DATE = 'weidgerDate',
  CREDIT_BONUS_AMOUNT = 'creditBonusAmount',
  CODE = 'code',
  COUNT = 'usersCount',
  ADDITIONAL_BONUS = 'additionalBonus',
  PROMO_WEIDGER = 'weidger',
  MIN_AMOUNT = 'minAmount',
  MAX_AMOUNT = 'maxAmount',
  DEFAULT= 'defaultAmount',
  DEPOSIT = 'deposit',
  WITHDRAW = 'withdraw',
  WITHDRAW_BORDER='withdrawBorder',
  LIST_OF_USERS_BONUSES = 'listOfUsersBonuses',
  LIST_OF_USERS_WHEEL = 'listOfUsersWheel',
  LIST_OF_NEW_USERS = 'listOfNewUsers',
  LIST_OF_USERS_CASHBACKS= 'listOfUsersCashbacks',
  MIN_BORDER_OF_DEPOSIT = 'minDepositBorder',
  PROMO_USERS = 'promoUsersLists'
}

export interface ISiteCategory {
  title: string;
  image: string | null;
  isMobile: boolean;
  isDesktop: boolean;
  isPrimaryShow: boolean;
}

export interface ILevel {
  createdAt: string;
  id: string;
  name: string;
  [key: string]: string | null | number | boolean;
}

 export interface IPromoUsers {
  createdAt: string;
  id: string;
  name: string;
}

export interface ISiteCategoryItem {
  createdAt?: null | string;
  deletedAt?: null | string;
  id: string;
  image: null | string;
  isDesktop?: boolean;
  isMobile?: boolean;
  gamesCount: number;
  order?: number;
  siteId?: string;
  title: string;
  updatedAt?: null | string;
  name?: string;
}

export enum MODE {
  EDIT = 'edit',
  EDIT_CONTENT = 'editContent',
  DELETE = 'delete',
  CREATE = 'create',
}

export interface IGotoPage {
  val: string;
  id: number | null;
}

export interface ICurrencyMethods {
  id: number;
  currencyId: number;
  image: string;
  isActive: boolean;
  currencyName: string;
  currencyCode: string;
  minValue: number;
  maxValue: number;
  [x: string]: number | string | null | boolean;
}
