import http from "../http-common";

class GameImageSettingsDataService {
    getAllProviderGame = (headers, providerId, query) => {
        return http.get(`/game-providers/${providerId}/games/all${query}`, { headers })
    }

    uploadGameImage = (headers, gameId, providerId,  body) => {
            return http.put(`/game-providers/${providerId}/games/${gameId}/image`, body, { headers });
    }
}

const GameImageSettingsService = new GameImageSettingsDataService();


export default GameImageSettingsService