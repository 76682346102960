import { Box, TextField, Button, Typography } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import common from 'styles/common.module.scss';
import { useState } from 'react';
import { FIELD_NAME, roleTypes } from 'types/accounts';
import { saveAs } from 'file-saver';
import { IAccountTableData } from 'types/accounts';
import { useSelector } from 'react-redux';
import { useUser } from 'hooks/user/useUser';
import { ISites } from 'types/sites';
import { useTranslation } from 'react-i18next';
import Result from 'components/alert/alert';
import { isValidValueForEditAddAccount } from 'utils/helpers/inputHelper';
import styles from './createAccount.module.scss';

interface CreateAccountProps {
  role: roleTypes | '';
  onClose: () => void;
}

export const initialData = {
  login: '',
  password: '',
  phone: '',
  email: '',
  role: '',
  siteIds: [],
};

const style = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
};

const fields = {
  [FIELD_NAME.LOGIN]: true,
  [FIELD_NAME.PASSWORD]: true,
  [FIELD_NAME.EMAIL]: true,
  [FIELD_NAME.PHONE]: true,
};
function CreateAccount({ onClose, role }: CreateAccountProps) {
  const { createUser, error } = useUser(role);
  const [qrImageUrl, setQrImageUrl] = useState('');
  const [formData, setFormData] = useState<IAccountTableData>({ ...initialData, role });
  const { t: accountRolesT } = useTranslation('accountRoles');
  const { t: validationT } = useTranslation('validations');
  const [validFields, setValidFields] = useState({ ...fields });

  const sitesData = useSelector<any>((state) => state.allSites) as {
    sites: ISites[];
  };
  const { sites: siteList } = sitesData;

  const handleOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: string,
  ) => {
    const { value } = e.target;
    const data = { ...formData, [name]: value };
    setFormData(data);
  };

  const handleOnChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = event.currentTarget.id;

    const data = { ...formData, siteIds: [...(formData?.siteIds ?? []), id] };
    setFormData(data as IAccountTableData);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!formData?.siteIds?.length) {
      delete formData.siteIds;
    }
    const result = await createUser(formData);
    if (result?.url) setQrImageUrl(result.url);
  };

  const downloadImage = (url: string) => {
    saveAs(url, 'qr.png');
  };

  const validate = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, name: string) => {
    const { value } = e.target;
    setValidFields((fields) => ({
      ...fields,
      [name]: isValidValueForEditAddAccount(value, name),
    }));
  };

  return (
    <>
      {qrImageUrl ? (
        <Box sx={{ m: 4, ...style }}>
          <Typography sx={{ m: 2, ...style }} color={common.success} variant="h6" component="h2">
            {`${accountRolesT('accountWasAdded')}`.toLocaleUpperCase()}
          </Typography>
          <img src={qrImageUrl} className={styles.qr_iamge} />
          <Button onClick={() => downloadImage(qrImageUrl)}>{accountRolesT('downladQr')}</Button>
        </Box>
      ) : (
        <>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {accountRolesT('add')} {accountRolesT(role)}
          </Typography>
          <Box sx={style}>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                id="login"
                label={accountRolesT('loginInput')}
                fullWidth
                required
                variant="standard"
                onChange={(e) => handleOnChange(e, FIELD_NAME.LOGIN)}
                onBlur={(e) => validate(e, FIELD_NAME.LOGIN)}
                error={!validFields[FIELD_NAME.LOGIN]}
                helperText={
                  validFields[FIELD_NAME.LOGIN] ? '' : validationT('minLength', { length: 8 })
                }
              />
              <TextField
                required
                fullWidth
                margin="normal"
                name="password"
                label={accountRolesT('passwordInput')}
                type="password"
                variant="standard"
                onChange={(e) => handleOnChange(e, FIELD_NAME.PASSWORD)}
                onBlur={(e) => validate(e, FIELD_NAME.PASSWORD)}
                error={!validFields[FIELD_NAME.PASSWORD]}
                helperText={
                  validFields[FIELD_NAME.PASSWORD] ? '' : validationT('minLength', { length: 8 })
                }
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="phone"
                label={accountRolesT('phoneInput')}
                type="text"
                variant="standard"
                onChange={(e) => handleOnChange(e, FIELD_NAME.PHONE)}
                onBlur={(e) => validate(e, FIELD_NAME.PHONE)}
                error={!validFields[FIELD_NAME.PHONE]}
                helperText={validFields[FIELD_NAME.PHONE] ? '' : validationT('invalidPhone')}
              />
              <TextField
                margin="normal"
                id="email"
                label={accountRolesT('emailInput')}
                fullWidth
                required
                variant="standard"
                onChange={(e) => handleOnChange(e, FIELD_NAME.EMAIL)}
                onBlur={(e) => validate(e, FIELD_NAME.EMAIL)}
                error={!validFields[FIELD_NAME.EMAIL]}
                helperText={validFields[FIELD_NAME.EMAIL] ? '' : validationT('invalidEmail')}
              />
              <Box className={styles.checkbox}>
                <Typography variant="subtitle1" sx={{ mt: 2 }}>
                  {accountRolesT('chooseTheSite')}
                </Typography>
                <FormGroup sx={{ ml: 1 }} className={styles.checkbox_list}>
                  {siteList.map(({ name, id }, index) => {
                    return (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            id={id + ''}
                            onChange={handleOnChecked}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 16 }, m: 0, p: 0.5 }}
                          />
                        }
                        label={name}
                      />
                    );
                  })}
                </FormGroup>
              </Box>
              {error && <Result style={{ mb: 2 }} message={error} type={'error'} isShow />}
              <Box className={styles.active}>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={
                    !validFields[FIELD_NAME.LOGIN] ||
                    !validFields[FIELD_NAME.PASSWORD] ||
                    !validFields[FIELD_NAME.PHONE] ||
                    !validFields[FIELD_NAME.EMAIL]
                  }
                >
                  {accountRolesT('add')}
                </Button>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}

export default CreateAccount;
