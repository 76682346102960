import { Box, Button, Modal, useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import FilterField from 'components/filter/columnFilter';
import { IconClose } from 'components/closeButton/closeButton';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { columnsName } from 'constants/common';
import moment from 'moment';
import CreateReturns from './createReturns/returnsCreate';
import { useWheels } from 'hooks/sites/useWheels';
import { useSelector } from 'react-redux';
import { IReturns } from 'types/sitesSettings';
import LoaderWithBg from 'components/loader/loader';
import { style } from 'components/modal/modal';


function ReturnsTable() {
  const theme = useTheme();
  const { t: siteSettingsT } = useTranslation('siteSettings');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { isLoading, getAllWheelsGroups, columns: columnNames } = useWheels();
  const wheelsGroups = useSelector<any>((state) => state.wheels) as IReturns[];

  const handleClose = () => {
    setOpenModal(false);
  };

  const onButtonClick = (mode: string, id?: string) => {
    /** We'll use id when we need edit mode */
    if (mode === 'create') {
      setOpenModal(true);
    }
  };

  const generateDinamicColumns = useMemo(() => {
    const columns = columnNames.map((el) => {
      if (el === columnsName.CREATED_AT) {
        return {
          field: el,
          width: Math.ceil(window.innerWidth / columnNames.length),
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              isDisabled
            />
          ),
          valueGetter: (params: GridValueGetterParams) =>
            `${moment(params.row.createdAt).format('DD/MM/Y HH:mm:ss')}`,
        };
      }
      return {
        field: el,
        width: el === columnsName.ID ? 100 : 200,
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => (
          <FilterField
            fieldName={el}
            isDisabled
            isCamelCase
          />
        )
      };
    });

    return [...columns];
  }, [columnNames]);

  const columns = generateDinamicColumns as GridColDef[];

  useEffect(() => {
    getAllWheelsGroups();
  }, []);

  return (
    <Box>
      {isLoading ? (
        <LoaderWithBg isOpen={isLoading} />
      ) : (<>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            sx={{ mt: 3, mb: 2, mr: 4 }}
            onClick={() => onButtonClick('create')}
          >
            {siteSettingsT('addReturn')}
          </Button>
        </Box>
        <DataGrid
          autoHeight
          rows={wheelsGroups}
          columns={columns}
          hideFooterPagination={true}
          sx={{
            '.MuiDataGrid-iconButtonContainer': {
              visibility: 'visible',
            },
            '.MuiDataGrid-sortIcon': {
              opacity: 'inherit !important',
            },
            '.MuiDataGrid-cell': {
              '&:last-child': {
                justifyContent: 'center !important',
              },
            },
            '.MuiDataGrid-footerContainer': {
              display: 'none',
            },
          }}
        />
        {openModal && (
          <Modal open={openModal} onClose={handleClose}>
            <Box
              sx={{
                ...style,
                width: 'calc(100%-600px)',
                minWidth: '500px',
                bgcolor: `${theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff'}`,
              }}
            >
              <CreateReturns
                mode={'create'}
                onClose={handleClose}
              />
              <IconClose onClose={handleClose} />
            </Box>
          </Modal>
        )}</>)}
    </Box>
  );
}

export default ReturnsTable;
