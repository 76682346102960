import { routing } from 'constants/routing';
import { useNavigate } from 'react-router-dom';
import { STORAGE_KEYS, localStorageService } from 'utils/storageService';

export const useStorage = () => {
  const navigate = useNavigate();

  const logout = () => {
    localStorageService.remove(STORAGE_KEYS.ACCESS_TOKEN);
    localStorageService.remove(STORAGE_KEYS.REFRESH_TOKEN);
    localStorageService.remove(STORAGE_KEYS.ROLE);
    return navigate(routing.home);
  };
  const userToken = () => localStorageService.read(STORAGE_KEYS.ACCESS_TOKEN);

  const userRefresh = () => localStorageService.read(STORAGE_KEYS.REFRESH_TOKEN);

  const setLanguage = (key: string) => {
    localStorageService.put(STORAGE_KEYS.LANGUAGE_KEY, key);
  };

  const setStorageData = (data: { accessToken: string; refreshToken: string; account: any }) => {
    /**TODO write dynamic one */
    localStorageService.put(STORAGE_KEYS.ACCESS_TOKEN, data.accessToken);
    localStorageService.put(STORAGE_KEYS.REFRESH_TOKEN, data.refreshToken);
    localStorageService.put(STORAGE_KEYS.ACCOUNT, data.account);
    if (!localStorageService.read(STORAGE_KEYS.ROLE)) {
      localStorageService.put(STORAGE_KEYS.ROLE, data.account.role ?? null);
    }

  };

  const setOpenData = (isOpen: boolean) => {
    localStorageService.put(STORAGE_KEYS.IS_OPEN, isOpen);
  };

  const setOpenItemData = (isOpen: string | null) => {
    localStorageService.put(STORAGE_KEYS.OPENED_ITEM_INDEX, isOpen);
  };

  return {
    logout,
    userToken,
    userRefresh,
    setStorageData,
    setOpenData,
    setOpenItemData,
    setLanguage,
  };
};
