import http from '../http-common';

class PermissionsDataService {
  getAll = (id, headers) => {
    return http.get(`/permission/entity/account/${id}`, { headers });
  };

  update = (headers, body) => {
    return http.put('/permission/entity/action', body, { headers });
  };

  delete = (headers, id) => {
    return http.delete(`/permission/entity/action/${id}`, { headers });
  };
}

const PermissionsService = new PermissionsDataService();

export default PermissionsService;
