import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useState } from 'react';
import { RightContent } from './rightContent';
import { useProviders } from 'hooks/providers/useProviders';
import styles from './gamblingStatistics.module.scss';

export interface IItem {
  id: string;
  img: string;
  gameName: string;
  playedCount: number;
}

export interface IActiveItem {
  [key: string]: string | number | IItem[] | [];
  id: string;
  name: string;
  gameCount: number;
  populars: IItem[] | [];
  profits: IItem[] | [];
  deprofits: IItem[] | [];
}

const menuItems_mock = [
  {
    id: '1',
    name: 'Belatra',
    gameCount: 2000,
    populars: [
      {
        id: '1',
        gameName: 'Shining Crown',
        img: 'https://newstatic.adjarabet.com/static/desktop/cms-nova/img/games/common-new/554490_en.jpg',
        playedCount: 1245,
      },
      {
        id: '2',
        gameName: 'Red Poker',
        img: 'https://www.inventiva.co.in/wp-content/uploads/2022/09/JB-COMP-ONLINE-CASINO-DEC-15-2.webp',
        playedCount: 2455,
      },
      {
        id: '3',
        gameName: 'Zodiac Wheels',
        img: 'https://newstatic.adjarabet.com/static/desktop/cms-nova/img/games/common-new/557935_en.jpg',
        playedCount: 1224,
      },
    ],

    profits: [
      {
        id: '1',
        gameName: 'Holmes',
        img: 'https://newstatic.adjarabet.com/static/desktop/cms-nova/img/games/common-new/550131_en.jpg',
        playedCount: 1245,
      },
      {
        id: '2',
        gameName: 'Wizardry 8',
        img: 'https://media.istockphoto.com/id/469867872/photo/jack-and-ace.jpg?s=2048x2048&w=is&k=20&c=sR5TjJxSl709Afq1y1zELy2DnuCbdUafn28wdEf_Gzs=',
        playedCount: 2455,
      },
      {
        id: '2',
        gameName: 'Titan Quest',
        img: 'https://newstatic.adjarabet.com/static/desktop/cms-nova/img/games/common-new/559293_en.jpg',
        playedCount: 1224,
      },
      {
        id: '11',
        gameName: 'Holmes',
        img: 'https://newstatic.adjarabet.com/static/desktop/cms-nova/img/games/common-new/550131_en.jpg',
        playedCount: 1245,
      },
      {
        id: '21',
        gameName: 'Wizardry 8',
        img: 'https://media.istockphoto.com/id/469867872/photo/jack-and-ace.jpg?s=2048x2048&w=is&k=20&c=sR5TjJxSl709Afq1y1zELy2DnuCbdUafn28wdEf_Gzs=',
        playedCount: 2455,
      },
      {
        id: '21',
        gameName: 'Titan Quest',
        img: 'https://newstatic.adjarabet.com/static/desktop/cms-nova/img/games/common-new/559293_en.jpg',
        playedCount: 1224,
      },
      {
        id: '12',
        gameName: 'Holmes',
        img: 'https://newstatic.adjarabet.com/static/desktop/cms-nova/img/games/common-new/550131_en.jpg',
        playedCount: 1245,
      },
      {
        id: '22',
        gameName: 'Wizardry 8',
        img: 'https://media.istockphoto.com/id/469867872/photo/jack-and-ace.jpg?s=2048x2048&w=is&k=20&c=sR5TjJxSl709Afq1y1zELy2DnuCbdUafn28wdEf_Gzs=',
        playedCount: 2455,
      },
      {
        id: '22',
        gameName: 'Titan Quest',
        img: 'https://newstatic.adjarabet.com/static/desktop/cms-nova/img/games/common-new/559293_en.jpg',
        playedCount: 1224,
      },
      {
        id: '13',
        gameName: 'Holmes',
        img: 'https://newstatic.adjarabet.com/static/desktop/cms-nova/img/games/common-new/550131_en.jpg',
        playedCount: 1245,
      },
      {
        id: '23',
        gameName: 'Wizardry 8',
        img: 'https://media.istockphoto.com/id/469867872/photo/jack-and-ace.jpg?s=2048x2048&w=is&k=20&c=sR5TjJxSl709Afq1y1zELy2DnuCbdUafn28wdEf_Gzs=',
        playedCount: 2455,
      },
      {
        id: '233',
        gameName: 'Titan Quest',
        img: 'https://newstatic.adjarabet.com/static/desktop/cms-nova/img/games/common-new/559293_en.jpg',
        playedCount: 1224,
      },
    ],
    deprofits: [
      {
        id: '1',
        gameName: 'Book of Dead',
        img: 'https://newstatic.adjarabet.com/static/desktop/cms-nova/img/games/common-new/557946_en.jpg',
        playedCount: 1245,
      },
      {
        id: '2',
        gameName: 'Extra Stars',
        img: 'https://newstatic.adjarabet.com/static/desktop/cms-nova/img/games/common-new/554799_en.jpg',
        playedCount: 2455,
      },
      {
        id: '2',
        gameName: 'Wild dragon',
        img: 'https://newstatic.adjarabet.com/static/desktop/cms-nova/img/games/common-new/559339_en.jpg',
        playedCount: 1224,
      },
    ],
  },
  {
    id: '2',
    name: 'Greentube',
    gameCount: 1800,
    populars: [
      {
        id: '1',
        gameName: 'Village',
        img: 'https://assets1.ignimgs.com/2017/04/24/97-1493052954353_640w.jpg',
        playedCount: 1245,
      },
      {
        id: '2',
        gameName: 'Green Poker',
        img: 'https://newstatic.adjarabet.com/static/desktop/cms-nova/img/games/common-new/554466_en.jpg',
        playedCount: 2455,
      },
      {
        id: '2',
        gameName: 'Amazons Battle',
        img: 'https://newstatic.adjarabet.com/static/desktop/cms-nova/img/games/common-new/554497_en.jpg',
        playedCount: 1224,
      },
    ],

    profits: [
      {
        id: '1',
        gameName: '40 Super Hot',
        img: 'https://newstatic.adjarabet.com/static/desktop/cms-nova/img/games/common-new/554473_en.jpg',
        playedCount: 1245,
      },
      {
        id: '2',
        gameName: 'Dice Rolls',
        img: 'https://newstatic.adjarabet.com/static/desktop/cms-nova/img/games/common-new/552348_en.jpg',
        playedCount: 2455,
      },
      {
        id: '2',
        gameName: 'Ozwins Jackpots',
        img: 'https://newstatic.adjarabet.com/static/desktop/cms-nova/img/games/common-new/556450_en.jpg',
        playedCount: 1224,
      },
    ],
    deprofits: [
      {
        id: '1',
        gameName: 'Crazy monkey',
        img: 'https://newstatic.adjarabet.com/static/desktop/cms-nova/img/games/common-new/554916_en.jpg',
        playedCount: 1245,
      },
      {
        id: '2',
        gameName: 'Red Poker',
        img: 'https://newstatic.adjarabet.com/static/desktop/cms-nova/img/games/common-new/557252_en.jpg',
        playedCount: 2455,
      },
      {
        id: '2',
        gameName: 'Monkey Poker',
        img: 'https://newstatic.adjarabet.com/static/desktop/cms-nova/img/games/common-new/552043_en.jpg',
        playedCount: 1224,
      },
    ],
  },
];

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: '0 24px', flexBasis: '60%' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function GamblingStatistics() {
  const { providersList } = useProviders();
  const [value, setValue] = useState(0);
  const [activeProvider, setActiveProvider] = useState<IActiveItem>(menuItems_mock[0]);

  /**TODO: remove thees comments, after BE be ready */

  // const [activeId, setActiveId] = useState<string>(menuItems_mock[0].id);

  // const handleOnClick = (id: string) => {
  //   const item = menuItems_mock.filter((el) => el.id === id);
  //   setActiveProvider(item?.[0] ?? {});
  //   setActiveId(id);
  // };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Box className={styles.container}>
      <Box className={styles.leftSide}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            orientation="vertical"
            variant="scrollable"
          >
            {providersList.map(({ id, name }, index) => {
              return (
                <Tab key={index} label={name} {...a11yProps(0)} sx={{ alignItems: 'start' }} />
              );
            })}
          </Tabs>
        </Box>
      </Box>
      <Box className={styles.rightSide}>
        {providersList.map(({ id }, index) => {
          return (
            <CustomTabPanel key={id} value={value} index={index}>
              <RightContent activeItem={activeProvider} />
            </CustomTabPanel>
          );
        })}
      </Box>
    </Box>
  );
}

export default GamblingStatistics;
