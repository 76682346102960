// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import GameSettings from '../gameSettings/gameSettings';
import { useProviders } from 'hooks/providers/useProviders';
import { setProviderGames, addProviderGames } from 'redux/actions/sitesActions';
import { useDispatch } from 'react-redux';
import { useDragGame } from 'hooks/game/useDragGame';

interface GameProvidersProps {
  currentItem: ISiteCategoryItem;
  closeEdit: () => void;
  siteId: string;
}

export default function GameProviders({ currentItem, closeEdit, siteId }: GameProvidersProps) {
  const dispatch = useDispatch();
  const { getAllGamesByCategoryId, getChunkGamesByCategoryId } = useDragGame();
  const { providersList, getChunkGamesByProvider, getAllSubProviders, getAllGamesByProvider } =
    useProviders();
  const [isLoadingLeftSide, setIsLoadingLeftSide] = useState(false);
  const [isLoadingRightSide, setIsLoadingRightSide] = useState(false);
  const [subProviders, setSubProviders] = useState([]);
  const [requestQuery, setRequestQuery] = useState({ subProvider: '', device: '' });
  const [hasMoreLeftSide, setHasMoreLeftSide] = useState(true);
  const [hasMoreRightSide, setHasMoreRightSide] = useState(true);
  const [activeItem, setActiveItem] = useState(null);

  const pageNumRef = useRef(1);
  const pageNumRefRightSide = useRef(1);

  const getData = async () => {
    const result = await getChunkGamesByProvider(activeItem?.id, currentItem.id, 1);
    if (result) {
      const { data, loading } = result;
      if (data.length) {
        dispatch(setProviderGames(data));
        setIsLoadingLeftSide(loading);
        setHasMoreLeftSide(true);
      } else {
        setHasMoreLeftSide(false);
      }
    }
  };

  const handleOnClick = (item: any) => {
    pageNumRef.current = 1;
    if (item.id !== activeItem.id) {
      setActiveItem(item);
      setHasMoreLeftSide(true);
    }
  };

  const getSubProviderGames = async (query: string, resetList = false) => {
    if (query && activeItem?.id) {
      const result = await getAllGamesByProvider(activeItem?.id, currentItem.id, `${query}`);
      if (result) {
        const { data, loading } = result;
        if (data.length) {
          if (resetList) {
            dispatch(setProviderGames(data));
          } else {
            dispatch(addProviderGames(data));
          }
          setIsLoadingLeftSide(loading);
          setHasMoreLeftSide(true);
        } else {
          setHasMoreLeftSide(false);
        }
      }
    }
  };

  const handleQeueryChange = (val: string, name: string) => {
    pageNumRef.current = 1;
    setIsLoadingLeftSide(true);
    setRequestQuery((tempQuery) => ({ ...tempQuery, [name]: val }));
  };

  useEffect(() => {
    dispatch(setProviderGames([]));
    getSubProviderGames(
      `${requestQuery.subProvider}${requestQuery.device}&page=${pageNumRef.current}`,
      true,
    );
  }, [requestQuery]);

  useEffect(() => {
    getCategoryGames(siteId, currentItem.id);
  }, [siteId, currentItem]);

  useEffect(() => {
    pageNumRef.current = 1;
    dispatch(setProviderGames([]));
    const getSubProviderList = async () => {
      setIsLoadingLeftSide(true);
      const result = await getAllSubProviders(activeItem?.id);
      if (result) {
        const { data, status } = result;
        if (status === 200) {
          setSubProviders(data);
          getData();
        }
      }
    };
    if (activeItem && activeItem.id && currentItem.id) {
      getSubProviderList();
    }
  }, [activeItem]);

  const handleOnScrollLeft = (id: string) => {
    pageNumRef.current += 1;
    if (id) {
      getSubProviderGames(
        `${requestQuery.subProvider}${requestQuery.device}&page=${pageNumRef.current}`,
      );
    } else {
      getData();
    }
  };

  const getCategoryGames = async (siteId, categoryId, query = '&page=1', resetList = false) => {
    const result = await getAllGamesByCategoryId(activeItem?.id, siteId, categoryId, query);
    if (result) {
      const { data, loading } = result;

      if (data?.length) {
        setIsLoadingRightSide(loading);
        setHasMoreRightSide(!resetList);
      } else {
        setHasMoreRightSide(false);
      }
    }
  };

  const getChunkCategoryGames = async (siteId, categoryId, query) => {
    const result = await getChunkGamesByCategoryId(siteId, categoryId, query);
    if (result) {
      const { data, loading } = result;

      if (data?.length) {
        setIsLoadingRightSide(loading);
        setHasMoreRightSide(true);
      } else {
        setHasMoreRightSide(false);
      }
    }
  };

  const handleOnScrollRight = () => {
    if (!isLoadingRightSide) {
      pageNumRefRightSide.current += 1;
      getChunkCategoryGames(siteId, currentItem.id, `&page=${pageNumRefRightSide.current}`);
    }
  };

  const resetCategoryGames = () => {
    if (!isLoadingRightSide) {
      getCategoryGames(
        siteId,
        currentItem.id,
        `&page=1&pageSize=${pageNumRefRightSide.current * 10}`,
      );
    }
  };

  const resetProviderGames = () => {
    if (!isLoadingLeftSide) {
      getSubProviderGames(
        `${requestQuery.subProvider}${requestQuery.device}&page=1&pageSize=${
          pageNumRef.current * 10
        }`,
        true,
      );
    }
  };

  useEffect(() => {
    if (!activeItem) {
      setActiveItem(providersList[0]);
    }
  }, [providersList]);

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <CssBaseline />
      <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', minHeight: '500px' }}>
        <List sx={{ mr: 2, flexBasis: '20%' }}>
          {providersList.map((item: any, index: number) => (
            <ListItem
              key={index}
              disablePadding
              sx={{
                bgcolor: `${activeItem?.name === item.name ? 'background.paper' : 'none'}`,
              }}
            >
              <ListItemButton onClick={() => handleOnClick(item)}>
                <ListItemText
                  primary={item.name}
                  sx={{
                    color: `${activeItem?.name !== item.name ? 'text.primary' : 'text.secondary'}`,
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <GameSettings
          siteId={siteId}
          activeItem={activeItem}
          currentCategory={currentItem}
          subProviders={subProviders}
          onScrollLeftSide={!isLoadingLeftSide ? handleOnScrollLeft : null}
          onScrollRightSide={!isLoadingRightSide ? handleOnScrollRight : null}
          isLoadingLeftSide={isLoadingLeftSide}
          isLoadingRightSide={isLoadingRightSide}
          hasMoreLeftSide={hasMoreLeftSide}
          hasMoreRightSide={hasMoreRightSide}
          closeEdit={closeEdit}
          onQueryChange={handleQeueryChange}
          resetCategoryGames={resetCategoryGames}
          resetProviderGames={resetProviderGames}
        />
      </Box>
    </Box>
  );
}
