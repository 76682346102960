import { Box } from '@mui/material';
import { useCurrency } from 'hooks/currency/useCurrency';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SelectDropDown from './selectDropDown';

interface CurrencyListProps {
  onSelectSite: (name: string, value: string) => void;
  isEdit?: boolean;
  initValue?: string;
  width?: number | string;
  margin?: string;
}

enum EFilter {
  CURRENCY_ID = 'currencyId',
}

export default function CurrencyList({
  onSelectSite,
  margin,
  initValue,
  width,
}: CurrencyListProps) {
  const { t: financeDepositT } = useTranslation('financeDeposit');
  const { getCurrency } = useCurrency();

  const customWidth = width !== undefined ? width : 160;
  const customMargin = margin !== undefined ? margin : '0 0 24px 0';
  const currencies = useSelector<any>((state) => state.currencies) as any;

  const getCurrencyList = async () => {
    await getCurrency();
  };

  useEffect(() => {
    if (!currencies.length) getCurrencyList();
  }, [currencies]);

  return (
    <Box sx={{ width: customWidth, margin: customMargin }}>
      <SelectDropDown
        initValue={initValue}
        onSelect={onSelectSite}
        label={financeDepositT('selectCurrency')}
        list={currencies}
        filtrBy={EFilter.CURRENCY_ID}
        itemKey={'code'}
      />
    </Box>
  );
}
