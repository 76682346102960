import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SelectDropDown from './selectDropDown';
import { useBalances } from 'hooks/clients/useBalances';

interface TransactionListProps {
  onSelectSite: (name: string, value: string) => void;
  isEdit?: boolean;
  initValue?: string;
  width?: number | string;
  margin?: string;
  defaultValue?: string;
}

enum EFilter {
  STATUS_ID = 'statusId',
}

export default function TransactionList({
  onSelectSite,
  margin,
  initValue,
  defaultValue,
  width,
}: TransactionListProps) {
  const { t: financeWithdrawalsT } = useTranslation('financeWithdrawals');
  const { getAllStatus } = useBalances();
  const [defaultValueId, setDefaultValueId] = useState();
  const customWidth = width !== undefined ? width : 160;
  const customMargin = margin !== undefined ? margin : '0 0 24px 0';
  const statusList = useSelector<any>((state) => state.transactions) as any;

  const getList = async () => {
    await getAllStatus();
  };

  useEffect(() => {
    if (!statusList.length) {
      getList();
    } else {
      if (defaultValue) {
        const id = statusList.filter((el: any) => +el.id === +defaultValue)?.[0]?.id;
        setDefaultValueId(id);
      }
    }
  }, [statusList, defaultValue]);

  return (
    <Box sx={{ width: customWidth, margin: customMargin }}>
      <SelectDropDown
        defaultValueId={defaultValueId}
        initValue={initValue}
        onSelect={onSelectSite}
        label={financeWithdrawalsT('statusOfTransactions')}
        list={statusList}
        filtrBy={EFilter.STATUS_ID}
        itemKey={'value'}
        withTranslation
      />
    </Box>
  );
}
