import { actionTypes } from 'redux/constants/actionTypes';

export const setWheelsData = (wheels) => {
  return {
    type: actionTypes.SET_WHEELS_LIST,
    payload: wheels,
  };
};

export const addWheel = (wheel) => {
  return {
    type: actionTypes.ADD_WHEEL,
    payload: wheel,
  };
};
