import { GridValueGetterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import FilterField from 'components/filter/columnFilter';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { IFilterData } from 'types/accounts';
import { columnsName, ROW_PER_PAGE } from 'constants/common';
import { Box, Link, TablePagination, Typography } from '@mui/material';
import LoaderWithBg from 'components/loader/loader';
import { useTranslation } from 'react-i18next';
import { dateFormat, makeQueryUrl } from 'utils/utils';
import { isEmpty } from 'lodash';
import _color from 'styles/common.module.scss';
import { useSites } from 'hooks/sites/useSites';
import dayjs from 'dayjs';
import cx from 'classnames';
import { ITimeData } from '../statisticsResult/statisticsResult';
import { FIELD_NAME } from 'types/sites';
import moment from 'moment';
import styles from './listTable.module.scss';

interface ListTableProps {
  siteId: string;
  type: string;
  timeData: ITimeData;
}

export default function ListTable({ siteId, timeData, type }: ListTableProps) {
  const { t } = useTranslation('common');
  const { t: siteT } = useTranslation('sites');
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(ROW_PER_PAGE);
  const [filterData, setFilterData] = useState<IFilterData | object>({});
  const [sortData, setSortData] = useState<IFilterData | object>({});
  const {
    getAllPayInStatistics,
    getAllPayOutStatistics,
    columns: columnNames,
    siteList: list,
    getSitesAllUsersBonuses,
    pageLists,
    totalItems,
    usersCount,
    getCashbacks,
  } = useSites();

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (newPage >= +pageLists[pageLists.length - 1].val) {
      setPage(+pageLists[pageLists.length - 1].val);
    } else {
      setPage(newPage);
    }
  };

  const handleFilter = (name: string, value: string) => {
    setFilterData((filterby) => ({ ...filterby, [name]: value }));
  };

  const handleSortBy = (sortBy: string, sortDir: string) => {
    setSortData({ [sortBy]: sortDir });
  };

  const generateDinamicColumns = useMemo(() => {
    const columns = columnNames.map((el) => {
      if (el === columnsName.USER) {
        return {
          field: el,
          minWidth: el.length * 6 + 140,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isCamelCase
              isDisabled
            />
          ),
          renderCell: (params: GridValueGetterParams) => (
            <Link
              color={'text.primary'}
              component="a"
              target="blank"
              onClick={() => {
                window.open(`/clients/details/${params.row.user.id}`);
              }}
            >
              {params.row.user.login}
            </Link>
          ),
        };
      } else if (el === columnsName.DAY) {
        return {
          field: el,
          minWidth: el.length * 6 + 120,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isCamelCase
              isDisabled
            />
          ),
          valueGetter: (params: GridValueGetterParams) => moment(params.row.day).format('DD/MM/Y'),
        };
      } else if (el === columnsName.USE_DATE) {
        return {
          field: el,
          minWidth: el.length * 6 + 120,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isCamelCase
              isDisabled
            />
          ),
          valueGetter: (params: GridValueGetterParams) => dateFormat(params.row.useDate),
        };
      } else if (el === columnsName.CREATED_AT) {
        return {
          field: el,
          minWidth: el.length * 6 + 140,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isCamelCase
              isDisabled
            />
          ),
          valueGetter: (params: GridValueGetterParams) =>
            `${dayjs(params.row.createdAt).format('D/MM/YYYY HH:mm:ss')}`,
        };
      }
      return {
        field: el,
        width: el === columnsName.ID ? 100 : el.length * 6 + 140,
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => (
          <FilterField
            fieldName={el}
            onBlur={(value) => handleFilter(el, value)}
            onSort={handleSortBy}
            sortData={sortData}
            isCamelCase
            isDisabled
          />
        ),
        renderCell:
          type === FIELD_NAME.DEPOSIT || type === FIELD_NAME.WITHDRAW
            ? (params: GridValueGetterParams) => (
                <Box
                  className={cx(styles.amount, { [styles.withdraw]: type === FIELD_NAME.WITHDRAW })}
                >
                  {params.row.amount}
                </Box>
              )
            : null,
      };
    });
    return [...columns];
  }, [columnNames, sortData, pageLists]);

  const columns = generateDinamicColumns as GridColDef[];

  const getData = async (type: string, paramsQuery: string) => {
    let res;
    switch (type) {
      case FIELD_NAME.DEPOSIT:
        res = await getAllPayInStatistics(siteId, paramsQuery);
        break;
      case FIELD_NAME.WITHDRAW:
        res = await getAllPayOutStatistics(siteId, paramsQuery);
        break;
      case FIELD_NAME.LIST_OF_USERS_CASHBACKS:
        res = await getCashbacks(siteId, `${paramsQuery}&isUsed=true`);
        break;
      default:
        res = await getSitesAllUsersBonuses(siteId, paramsQuery);
        break;
    }
    if (res?.status === 200) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const query = `from=${timeData.from}&to=${timeData.to}` + makeQueryUrl(filterData);
    const sortBy = Object.entries(sortData)[0];

    let paramsQuery = isEmpty(sortData)
      ? query
      : `${query}&sortBy=${sortBy[0]}&sortDir=${sortBy[1]}`;
    paramsQuery += `&page=${page + 1}&pageSize=${rowsPerPage}`;

    getData(type, paramsQuery);
  }, [filterData, sortData, page, rowsPerPage, type]);

  return (
    <div style={{ width: '100%' }}>
      {isLoading ? (
        <LoaderWithBg isOpen={isLoading} />
      ) : (
        <>
          <Box>
            <Typography
              sx={{ m: 2, textAlign: 'center', fontWeight: 'bolder', maxWidth: 400 }}
              variant="body1"
            >
              {siteT('dynamicTitle', { type: siteT(`${type}SubTitle`) })}{' '}
              {dayjs(timeData.from).format('DD/MM/YYYY HH:mm')} -{' '}
              {dayjs(timeData.to).format('DD/MM/YYYY HH:mm')}
            </Typography>
          </Box>

          <TablePagination
            sx={{ mt: 2, mb: 2 }}
            component="div"
            count={totalItems}
            page={page}
            labelRowsPerPage={t('rowsPerPage')}
            labelDisplayedRows={({ from, to, count }) =>
              `${from >= to ? to : from}-${to} ${t('of')} ${count}`
            }
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Box>
            <Typography sx={{ textAlign: 'left', mb: 2, fontWeight: 'bolder' }} variant="h6">
              {siteT('users')}: {usersCount}
            </Typography>
          </Box>
          <DataGrid
            autoHeight
            rows={list}
            columns={columns}
            hideFooterPagination={true}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  userId: false,
                  id: false,
                  isUsed: false,
                },
              },
            }}
            sx={{
              '.MuiDataGrid-footerContainer': {
                display: 'none',
              },
            }}
          />
        </>
      )}
    </div>
  );
}
