import { AlertColor, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { IEditSite } from 'types/sites';
import SitesSupportForLanguage from './sitesSupportForLanguage/sitesSupportForLanguage';
import { useSelector } from 'react-redux';
import { useSitesSupport } from 'hooks/sitesSupport/sitesSupport';
import { useTranslation } from 'react-i18next';
import { SUCCESS } from 'constants/common';

interface SitesSupportProps {
  siteId: string;
}

function SitesSupportProps({ siteId }: SitesSupportProps) {
  const { t } = useTranslation('common');
  const { languages } = useSelector<any>((state) => state.currentSite) as IEditSite;
  const { sitesSupportForSite, updateSiteSitesSupport, resetError, error } =
    useSitesSupport(siteId);
  const [value, setValue] = useState(languages ? languages[0].code : '');
  const [success, setSuccess] = useState('');
  const [type, setType] = useState<AlertColor>(SUCCESS);
  const [sitesSupportList, setSitesSupportList] = useState([]);
  const [selectedItem, setSelectedItem] = useState();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const saveSitesSupport = async () => {
    const body: { id: number; sitesSupport: any }[] = [];
    sitesSupportList?.map((elm: any) => {
      if (elm.sitesSupport) {
        body.push({ id: elm.id, sitesSupport: elm.sitesSupport });
      }
    });

    console.log(body, 'saveSitesSupport-Body');
 
  };

  const updateSitesSupport = (languageCode: string, sitesSettings: any) => {
    setSitesSupportList((sitesSupportList: any) =>
      sitesSupportList.map((elm: any) =>
        elm.code === languageCode ? { ...elm, term: sitesSettings } : elm,
      ),
    );
  };

  const handleOnClose = () => {
    setSuccess('');
    setType(SUCCESS);
    resetError();
  };

  useEffect(() => {
    if (sitesSupportForSite) {
      const clonedSupports = cloneDeep(sitesSupportForSite);
      setSitesSupportList(clonedSupports);
      setSelectedItem(clonedSupports[0]);
    }
  }, [sitesSupportForSite]);

  useEffect(() => {
    const filterData = sitesSupportList.filter((elm: any) => elm.code === value);
    setSelectedItem(filterData[0]);
    handleOnClose();
  }, [value]);

  return (
    <>
      <TabContext value={value}>
        <TabList onChange={handleChange}>
          {languages?.map((elm, i) => {
            return <Tab key={i} label={elm.name} value={elm.code} />;
          })}
        </TabList>
        <TabPanel value={value}>
          <SitesSupportForLanguage
            languageCode={value}
            updateSitesSupport={updateSitesSupport}
            saveSitesSupport={saveSitesSupport}
            selectedItem={selectedItem}
            handleOnClose={handleOnClose}
            result={success || error}
            type={type}
          />
        </TabPanel>
      </TabContext>
    </>
  );
}

export default SitesSupportProps;
