import { actionTypes } from 'redux/constants/actionTypes';

const initial = [];

export const levelsReducer = (state = initial, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_LEVELS_LIST:
      return [...payload];
      case actionTypes.ADD_CREATED_LEVEL:
        return [payload, ...state];
    case actionTypes.UPDATE_LEVEL_DATA:
       return state.map(el=> el.id === payload.id ? {...el, ...payload} : {...el} )
    default:
      return state;
  }
};
