import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import Switcher from 'components/themeSwitcher/switcher';
import styles from './accountMenu.module.scss';
import { FormControl, Radio, RadioGroup, Typography } from '@mui/material';
import { useStorage } from 'hooks/authHooks/useStorage';
import { useTranslation } from 'react-i18next';
import enFlag from './languageIcons/english.svg';
import ruFlag from './languageIcons/russian.svg';
import { localStorageService, STORAGE_KEYS } from 'utils/storageService';

interface AccountMenuProps {
  languageValue: string | null;
}

export default function AccountMenu({ languageValue }: AccountMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [value, setValue] = React.useState(languageValue ? languageValue : 'en');
  const [login, setLogin] = React.useState('');
  const { t, i18n } = useTranslation('accountMenu');
  const { setLanguage } = useStorage();

  const open = Boolean(anchorEl);
  const { logout } = useStorage();

  const handleLogOut = () => {
    // setAnchorEl(null);
    logout();
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const languageValue = (event.target as HTMLInputElement).value;
    i18n.changeLanguage(languageValue);
    setValue((event.target as HTMLInputElement).value);
    setLanguage(languageValue);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    const account = localStorageService.read(STORAGE_KEYS.ACCOUNT);
    setLogin(account?.login ?? '******');
  }, []);

  return (
    <>
      <Box
        className={styles.container}
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          justifyContent: 'flex-end',
          paddingRight: '30px',
        }}
      >
        <Switcher />
        <Tooltip title={login}>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32, textTransform: 'uppercase' }}>
              {login.substring(0, 1)}
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {/* <MenuItem>
          <Avatar /> {t('getSites')}
        </MenuItem>
        <MenuItem>
          <Avatar /> {t('myAccount')}
        </MenuItem> */}
        {/* <Divider /> */}
        {/* <MenuItem>
          <FormGroup>
            <Typography>{t('notifications')}</Typography>
            <FormControlLabel control={<Checkbox />} label={t('deposit')} />
            <FormControlLabel control={<Checkbox />} label={t('withdraw')} />
          </FormGroup>
        </MenuItem> */}
        {/* <MenuItem>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          {t('settings')}
        </MenuItem> */}
        <MenuItem>
          <FormControl sx={{ display: 'flex' }}>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={handleChange}
            >
              <FormControlLabel
                value="en"
                control={<Radio />}
                label={<img src={enFlag} style={{ marginTop: 5 }} />}
              />
              <FormControlLabel
                value="ru"
                control={<Radio />}
                label={<img src={ruFlag} style={{ marginTop: 5 }} />}
              />
            </RadioGroup>
          </FormControl>
        </MenuItem>
        <MenuItem onClick={handleLogOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t('logout')}
        </MenuItem>
      </Menu>
    </>
  );
}
