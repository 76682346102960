// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { routeSwitcher } from 'constants/common';
import { routing as path } from 'constants/routing';

export const routesAndPermissions = [
  {
    path: path.home,
    role: ['support', 'operator', 'main', 'agent'],
    isSensitive: false,
    componentName: routeSwitcher.WELCOME_PAGE_COMMON,
  },
  {
    path: path.admin,
    role: ['main'],
    isSensitive: true,
    componentName: routeSwitcher.WELCOME_PAGE,
  },
  {
    path: `${path.permissions}/:id`,
    role: ['main'],
    isSensitive: false,
    componentName: routeSwitcher.PERMISSON_MANAGMENT,
  },
  {
    path: path.client,
    role: ['main', 'support', 'operator', 'agent'],
    isSensitive: false,
    componentName: routeSwitcher.CLIENT_LISTS,
  },
  {
    path: `${path.clientDetails}/:clientId`,
    role: ['main', 'support', 'operator', 'agent'],
    isSensitive: false,
    componentName: routeSwitcher.CLIENT_DETAILS,
  },
  {
    path: `${path.gameLogs}/:sessionId`,
    role: ['main'],
    isSensitive: false,
    componentName: routeSwitcher.GAME_LOGS,
  },
  {
    path: path.sites,
    role: ['main', 'agent'],
    isSensitive: false,
    componentName: routeSwitcher.SITE_PAGE,
  },
  {
    path: path.addBonusesForClients,
    role: ['main', 'agent'],
    isSensitive: false,
    componentName: routeSwitcher.ADD_BONUS,
  },
  {
    path: path.identifications,
    role: ['main', 'agent'],
    isSensitive: false,
    componentName: routeSwitcher.AWAIT_IDENTIFICATIONS,
  },
  {
    path: `${path.sitesSettings}/:siteId`,
    role: ['main', 'agent'],
    isSensitive: false,
    componentName: routeSwitcher.SITE_SETTINGS,
  },
  {
    path: `${path.account}/:account`,
    role: ['main'],
    isSensitive: false,
    componentName: routeSwitcher.ACCOUNT_PAGE,
  },
  {
    path: path.statistics,
    role: ['main'],
    isSensitive: false,
    componentName: routeSwitcher.STATISTICS,
  },
   {
    path: path.cashbacks,
    role: ['main', 'support', 'operator', 'agent'],
    isSensitive: false,
    componentName: routeSwitcher.CASHBACKS,
  },
  {
    path: path.channel,
    role: ['main', 'agent'],
    isSensitive: false,
    componentName: routeSwitcher.CHANNEL,
  },
  {
    path: path.raffle,
    role: ['main', 'agent'],
    isSensitive: false,
    componentName: routeSwitcher.RAFFLE,
  },
  {
    path: path.deposit,
    role: ['main', 'support', 'operator', 'agent'],
    isSensitive: false,
    componentName: routeSwitcher.DEPOSIT,
  },
  {
    path: path.withdrawals,
    role: ['main', 'support', 'operator', 'agent'],
    isSensitive: false,
    componentName: routeSwitcher.WITHDRAWALS,
  },
  {
    path: path.bonuses,
    role: ['main', 'support', 'operator', 'agent'],
    isSensitive: false,
    componentName: routeSwitcher.BONUSES,
  },
  {
    path: path.gamblingStatistics,
    role: ['main'],
    isSensitive: false,
    componentName: routeSwitcher.GAMBLING_STATISTICS,
  },
  {
    path: path.gameSettings,
    role: ['main', 'agent'],
    isSensitive: false,
    componentName: routeSwitcher.GAME_SETTINGS,
  },
   {
    path: path.gameNames,
    role: ['main', 'agent'],
    isSensitive: false,
    componentName: routeSwitcher.GAME_NAMES,
  },
  {
    path: path.providerSettings,
    role: ['main', 'agent'],
    isSensitive: false,
    componentName: routeSwitcher.PROVIDER_SETTINGS,
  },
  {
    path: path.promotion,
    role: ['main', 'agent'],
    isSensitive: false,
    componentName: routeSwitcher.PROMOTION,
  },
  {
    path: path.multiuser,
    role: ['main', 'agent'],
    isSensitive: false,
    componentName: routeSwitcher.MULTIUSER,
  },
  {
    path: `${path.multiuser}/:account`,
    role: ['main', 'agent'],
    isSensitive: false,
    componentName: routeSwitcher.MULTIUSER_LIST,
  },
  {
    path: path.labels,
    role: ['main'],
    isSensitive: false,
    componentName: routeSwitcher.LABELS,
  },
  {
    path: path.financeLogs,
    role: ['main', 'support', 'operator', 'agent'],
    isSensitive: false,
    componentName: routeSwitcher.FINANCE_LOGS,
  },
];
