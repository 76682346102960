import http from '../http-common';

class FinanceDataService {
  getAllPayIns = (headers, siteId, query = '') => {
    return http.get(`/site/${siteId}/finance/pay-in?${query}`, { headers });
  };

  updatePayInMinMax = (headers, siteId, id, body) => {
    return http.put(`/site/${siteId}/finance/pay-in-settings/${id}`, body, { headers });
  };

  updateImageForPaymentMethod = (headers, id, body) => {
    return http.put(`/finance-providers/pay-in-methods/${id}/image`, body, { headers });
  };

  getAllPayOuts = (headers, siteId, query = '') => {
    return http.get(`/site/${siteId}/finance/pay-out?${query}`, { headers });
  };

  getAllFinanceProviders = (headers, query = '') => {
    return http.get(`/finance-providers${query}`, { headers });
  };

  getAllFinanceProvidersWithMethods = (headers) => {
    return http.get(`/finance-providers/pay-in/methods`, { headers });
  };

  getFinanceProviderAllPayIns = (headers, query = '') => {
    return http.get(`/finance-providers/pay-in/transactions/all?${query}`, { headers });
  };

  updatePayOutMinMax = (headers, siteId, id, body) => {
    return http.put(`/site/${siteId}/finance/pay-out-settings/${id}`, body, { headers });
  };

  updateImageForPaymentMethodPayOut = (headers, id, body) => {
    return http.put(`/finance-providers/pay-out-methods/${id}/image`, body, { headers });
  };

  updatePaymentProvidersSettings = (headers, providerId, body) => {
    return http.put(`/finance-providers/${providerId}`, body, { headers });
  };
}

const FinanceService = new FinanceDataService();

export default FinanceService;
