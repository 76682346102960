import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Pagination,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { makeStyles } from '@mui/styles';
import { useGame } from 'hooks/game/useGame';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoaderWithBg from 'components/loader/loader';
import noImage from 'assets/image/no-image.png';
import { useProviders } from 'hooks/providers/useProviders';
import { grey } from '@mui/material/colors';
import styles from './searchGame.module.scss';

interface GameSearchProps {
  providerId: string;
  onAdd?: (data: string[]) => void;
}

const useStyles = makeStyles(() => ({
  paper: {
    display: 'flex',
    marginBottom: '16px',
    height: '40px',
    border: 'thin solid white',
    backgroundColor: 'unset!important',
  },
}));

function GameSearch({ providerId, onAdd }: GameSearchProps) {
  const { t } = useTranslation('common');
  const { t: siteT } = useTranslation('siteSettings');
  const { getAllSubProviders } = useProviders();
  const { getAllGameProvider, isLoading } = useGame();
  const [totalPage, setTotalPage] = useState(1);
  const [selectedProviderId, setSelectedProviderId] = useState('');
  const [subProviders, setSubProviders] = useState<any[]>();
  const [name, setName] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');
  const [showMessage, setShowMessage] = useState('');
  const [listOfIds, setListOfIds] = useState<string[]>([]);

  const [page, setPage] = useState(1);
  const [gamesImagesList, setGamesImagesList] = useState<any>([]);
  const classes = useStyles();

  const setNameValue = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;
    setName(value);
  };

  const handleOnPagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleOnChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = event.currentTarget.id;
    setListOfIds((ides) => [...ides, id]);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const addGamesToCategory = () => {
    onAdd?.(listOfIds);
  };

  const handleChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setSelectedProviderId(value);
    setPage(1);
  };

  const getData = async (providerId: string) => {
    const res = await getAllSubProviders(providerId);
    if (res?.status === 200) {
      setSubProviders(res.data);
    }
  };

  useEffect(() => {
    getData(providerId);
  }, [providerId]);

  useEffect(() => {
    const delay = 500;
    const timeoutId = setTimeout(() => {
      setDebouncedValue(name);
      setPage(1);
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [name]);

  useEffect(() => {
    const fetchData = async () => {
      const queryGameName = debouncedValue ? `&name=${debouncedValue}` : '';
      if (queryGameName) {
        const queryProvider = selectedProviderId ? `&subProviderId=${selectedProviderId}` : '';
        const paramsQuery = `?page=${page}${queryProvider}${queryGameName}`;

        const response = await getAllGameProvider(providerId, `${paramsQuery}&pageSize=10`);
        const newData = response.data.games;

        if (newData.length) {
          setShowMessage('');
          if (!debouncedValue && page !== 1) {
            setGamesImagesList((prevArray: any) => [...prevArray, ...newData]);
          } else {
            setGamesImagesList([...newData]);
          }
        } else {
          setShowMessage('nothingFound');
          setGamesImagesList([]);
        }

        setTotalPage(response.data.totalPages);
      } else {
        setGamesImagesList([]);
        setListOfIds([]);
      }
    };

    if (!isLoading) fetchData();
  }, [providerId, page, debouncedValue, selectedProviderId]);

  return (
    <>
      {isLoading ? <LoaderWithBg isOpen={isLoading} /> : null}
      <Box sx={{ display: 'flex' }}>
        <FormControl size="small">
          <InputLabel id="provider-label">{siteT('providers')}</InputLabel>
          <Select
            sx={{ minWidth: 180, mr: 3 }}
            labelId="provider-label"
            id="provider-select"
            label={siteT('providers')}
            value={selectedProviderId}
            onChange={handleChange}
          >
            <MenuItem value="">
              <em>{t('none')}</em>
            </MenuItem>
            {subProviders?.map((item, index) => {
              return (
                <MenuItem key={index} value={item.id}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Paper onSubmit={handleSubmit} className={classes.paper} component="form">
          <InputBase
            size="small"
            fullWidth
            sx={{ m: 2 }}
            placeholder={t('searchGame')}
            value={name}
            onChange={(e) => setNameValue(e)}
            inputProps={{ 'aria-label': 'search game' }}
          />
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gap: '10px',
            mb: 2,
          }}
        >
          {gamesImagesList?.map((elm: any, i: number) => {
            return (
              <Box key={i} sx={{ textAlign: 'center' }} className={styles.items}>
                <img
                  src={elm.image ?? noImage}
                  width="auto"
                  style={{ maxWidth: '80px', maxHeight: '100px' }}
                  onError={({ currentTarget }) => (currentTarget.src = noImage)}
                />

                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <FormControlLabel
                    sx={{ minWidth: 'max-content' }}
                    key={i}
                    control={
                      <Checkbox
                        checked={listOfIds?.includes(elm.id + '')}
                        id={elm.id}
                        onChange={handleOnChecked}
                        sx={{ m: 0, '& .MuiSvgIcon-root': { fontSize: 16 } }}
                        inputProps={{ readOnly: true }}
                      />
                    }
                    label={''}
                  />
                  <Typography sx={{ fontSize: '12px' }}>{elm.name} </Typography>
                  <Typography sx={{ fontSize: '12px' }}> ({elm.subProvider})</Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
        <Box sx={{ width: '100%' }}>
          {page < totalPage && gamesImagesList.length ? (
            <>
              <hr />
              <Stack className={styles.showMore} spacing={2}>
                <Pagination
                  count={totalPage}
                  // className={classes.ul}
                  hideNextButton
                  hidePrevButton
                  page={page}
                  onChange={handleOnPagination}
                />
              </Stack>
            </>
          ) : null}
          {showMessage ? (
            <Typography
              sx={{ mt: 2, mb: 2, display: { xs: 'none', md: 'block' }, color: grey[400] }}
              textAlign={'center'}
            >
              {t(showMessage)}
            </Typography>
          ) : (selectedProviderId || debouncedValue) && gamesImagesList.length ? (
            <Box sx={{ display: 'flex' }}>
              <Button
                fullWidth
                color="success"
                sx={{ margin: '4px auto', width: '260px' }}
                variant="contained"
                onClick={addGamesToCategory}
              >
                {siteT('addToCategory')}
              </Button>
            </Box>
          ) : null}
        </Box>
      </Box>
    </>
  );
}

export default GameSearch;
