import {
  AlertColor,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { IGamblingForm, IGroups } from 'types/clients';
import common from 'styles/common.module.scss';
import { useTranslation } from 'react-i18next';
import { useGameProvidersSettings } from 'hooks/providers/useGameProvidersSettings';
import { useClients } from 'hooks/clients/useClients';
import LoaderWithBg from 'components/loader/loader';
import { IGameSettingsProviders, IProviderGameSettings } from 'types/providers';
import Result from 'components/alert/alert';
import { ERROR, SUCCESS } from 'constants/common';
import { useClientGameSettings } from 'hooks/clients/useClientGameSettings';
import ClientGameSettingsHistoryTable from 'components/tables/client/clientGameSettingsHistoryTable';
import styles from './gamblingSettingsForm.module.scss';

interface GamblingSettingsFormProps {
  clientId: string;
}

const style = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  width: 'inherit',
};

const initData = {
  gameSettingsList: [],
  reason: '',
  isAutochange: false,
};

function GamblingSettingsForm({ clientId }: GamblingSettingsFormProps) {
  const { getClientById } = useClients();
  const { isLoading, getAllSettingsByGroup } = useGameProvidersSettings();
  const {
    isLoading: isLoadingClientData,
    saveClientGameSettings,
    error,
    getClientCurrentGameSettings,
  } = useClientGameSettings();
  const [providerSettingsList, setProviderSettingsList] = useState<IGameSettingsProviders[]>([]);
  const { t: clientDetailsT } = useTranslation('clientsDetails');
  const { t: clientsT } = useTranslation('clients');
  const { t } = useTranslation('common');
  const [isRequired, setIsRequired] = useState(false);
  const [success, setSuccess] = useState('');
  const [type, setType] = useState<AlertColor>(SUCCESS);
  const [formData, setFormData] = useState<IGamblingForm>(initData);
  const [needUpdate, setNeedUpdate] = useState<boolean>(false);
  let timeOutId: ReturnType<typeof setTimeout>;

  const handleOnSwicth = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const { checked } = e.target;

    const data = { ...formData, [key]: checked };
    setFormData(data);
  };

  const handleChange = (event: SelectChangeEvent, providerId: string) => {
    const { value } = event.target;
    const findedItem = formData?.gameSettingsList?.filter(
      (el: any) => +el.providerId === +providerId,
    );
    if (findedItem?.length) {
      findedItem[0].settings = [{ providerId, id: value }];
    }
    setFormData({ ...formData });
  };

  const handleChangeGroup = (event: SelectChangeEvent, providerId: string) => {
    const { value } = event.target;
    const findedItem = formData?.gameSettingsList?.filter(
      (el: any) => +el.providerId === +providerId,
    );
    if (findedItem?.length) {
      findedItem[0].groups = [{ settingId: findedItem[0].groups[0]?.settingId, id: value }];
    }
    setFormData({ ...formData });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!formData.reason) {
      setIsRequired(true);
    } else {
      const gameSettingsList = formData?.gameSettingsList?.map((el) => {
        if (!el.groups) {
          return { settingId: el.settings[0].id, groupId: null };
        } else return { settingId: el.groups[0].settingId, groupId: el.groups[0].id };
      });

      const body = { ...formData, gameSettingsList };
      const result = await saveClientGameSettings(clientId, body);
      if (result?.status === 200) {
        setSuccess(t('savedSuccess'));
        setType(SUCCESS);
        /**Need get data again as saveing data is different*/
        setNeedUpdate(true);

        timeOutId = setTimeout(() => {
          setSuccess('');
          setNeedUpdate(false);
        }, 2000);
      } else {
        setType(ERROR);
      }
    }
  };

  const handleOnChangeTextArea = (val: string, key: string) => {
    const data = { ...formData, [key]: val };
    setFormData(data);
    setIsRequired(false);
  };

  const getData = async (clientId: string) => {
    const result = await getClientById(clientId);
    if (result?.status === 200) {
      const siteId = result?.data?.site?.id;
      const res = await getAllSettingsByGroup(siteId);
      if (res?.status) {
        setProviderSettingsList(res.data);
      }
    }
  };

  const getClientCurrentSettings = async (clientId: string) => {
    const result = await getClientCurrentGameSettings(clientId);
    if (result?.status) {
      setFormData((data) => ({ ...data, ...result.data }));
    }
  };

  useEffect(() => {
    getData(clientId);
    return () => {
      clearTimeout(timeOutId);
    };
  }, [clientId]);

  useEffect(() => {
    if (!isLoading && providerSettingsList.length) getClientCurrentSettings(clientId);
  }, [isLoading]);

  return (
    <>
      <Box className={styles.form_box}>
        <Box sx={style}>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
            {isLoadingClientData ? (
              <LoaderWithBg isOpen={isLoadingClientData} />
            ) : (
              providerSettingsList?.map((el: any, index: number) => {
                if (el.groups === null) {
                  return (
                    <Box key={index} sx={{ minWidth: 100, mt: 3, mb: 3 }}>
                      <FormControl sx={{ width: '100%' }} size="small">
                        <InputLabel id="demo-simple-select-label">{el.providerName}</InputLabel>
                        <Select
                          required
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={
                            formData?.gameSettingsList?.filter(
                              (item: any) => item.providerId === el.providerId,
                            )?.[0]?.settings[0].id ?? ''
                          }
                          label="otdacha"
                          fullWidth
                          variant="standard"
                          onChange={(e) => handleChange(e, el.providerId)}
                        >
                          <MenuItem value="">
                            <em>{clientsT('none')}</em>
                          </MenuItem>
                          {el.settings.map((item: IProviderGameSettings) => {
                            return (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  );
                } else {
                  return (
                    <Box key={index} sx={{ minWidth: 100, mt: 3, mb: 3 }}>
                      <FormControl sx={{ width: '100%' }} size="small">
                        <InputLabel id="demo-simple-select-label">{el.providerName}</InputLabel>
                        <Select
                          required
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={
                            formData?.gameSettingsList?.filter(
                              (item: any) => +item.providerId === +el.providerId,
                            )?.[0]?.groups?.[0]?.id ?? ''
                          }
                          label="otdacha"
                          fullWidth
                          variant="standard"
                          onChange={(e) => handleChangeGroup(e, el.providerId)}
                        >
                          <MenuItem value="">
                            <em>{clientsT('none')}</em>
                          </MenuItem>
                          {el.groups.map((item: IGroups) => {
                            return (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  );
                }
              })
            )}

            <Box className={styles.active}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.isAutochange}
                      onChange={(e) => handleOnSwicth(e, 'isAutochange')}
                    />
                  }
                  label={`${clientDetailsT('canAutoChange')} ${
                    formData.isAutochange ? t('yes') : t('no')
                  } `}
                />
              </FormGroup>
            </Box>

            <Box className={styles.textArea}>
              <Typography variant="subtitle1" sx={{ fontSize: '14px', mr: 2, opacity: '0.5' }}>
                {clientDetailsT('reasonOfChanging')}
              </Typography>
              <textarea
                defaultValue={formData.reason}
                style={{
                  width: '100%!important',
                  height: '100px',
                  padding: '10px',
                  marginTop: '10px',
                  borderRadius: '10px',
                }}
                onChange={(event) => handleOnChangeTextArea(event.target.value, 'reason')}
              />
              {isRequired ? (
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'baseline',
                    justifyContent: 'flex-start',
                    fontSize: '12px',
                    opacity: '0.5',
                    color: !isRequired ? 'unset' : common.mainwarning,
                  }}
                  component="div"
                >
                  ({clientDetailsT('reasonIsRequired')})
                </Typography>
              ) : null}
            </Box>
            {(success || error) && (
              <Result style={{ mb: 2 }} message={success || error} type={type} isShow />
            )}
            <Box
              sx={{
                width: '100%',
              }}
            >
              <Button
                type="submit"
                variant="contained"
                sx={{
                  justifyContent: 'flex-end',
                  float: 'inline-end',
                  m: 2,
                }}
                disabled={isRequired || success !== ''}
              >
                {clientDetailsT('save')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        {!isLoadingClientData ? (
          <ClientGameSettingsHistoryTable clientId={clientId} needUpdate={needUpdate} />
        ) : null}
      </Box>
    </>
  );
}

export default GamblingSettingsForm;
