import { useState } from 'react';
import { generateHeaders } from 'utils/utils';
import { useStorage } from '../authHooks/useStorage';
import { useApiErrors } from 'hooks/errors/useApiErrors';
import {
  prepareDataForSelectPages,
  prepareTableData,
  preparedColumnNames,
} from 'utils/helpers/dataHelper/prepareDataHelper';
import { IGotoPage } from 'types/sites';
import TransactionService from 'services/transaction.service';
import { useDispatch } from 'react-redux';
import { setFinanceWithdrawals, updateFinanceWithdrawals } from 'redux/actions/financeWithdrawals';

export const useTransaction = () => {
  const { errorHandler } = useApiErrors();
  const dispatch = useDispatch();
  const { logout, userToken } = useStorage();
  const [columns, setColumns] = useState<string[] | []>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [balanceSum, setBalanceSum] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [pageLists, setPageLists] = useState<IGotoPage[] | []>([]);
  const [withdrawList, setWithdrawList] = useState<any[]>([]);
  const [error, setError] = useState('');

  const initHeaders = generateHeaders(userToken());

  const getAllPayOutTransactions: any = async (query: string, headers = initHeaders) => {
    try {
      const {
        data: {
          data: { payOutTransactions, totalPages, totalItems, balanceSum },
        },
      } = await TransactionService.getAllPayOutTransactions(headers, query);

      const preparedData = prepareTableData(payOutTransactions, 'transitionPayOut');
      const pageLists = prepareDataForSelectPages(totalPages);
      const columns = preparedColumnNames(preparedData);
      setColumns(columns);
      setTotalPages(totalPages);
      setTotalItems(totalItems);
      setBalanceSum(balanceSum);
      setPageLists(pageLists as IGotoPage[]);
      setWithdrawList(preparedData);
      dispatch(setFinanceWithdrawals(preparedData));
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getAllPayOutTransactions(query, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const updateTransactionStatus: any = async (
    id: string,
    actionId: string,
    headers = initHeaders,
  ) => {
    try {
      const {
        data: { data, status },
      } = await TransactionService.updateClientTransactionStatus(headers, id, actionId);

      dispatch(updateFinanceWithdrawals(data));
      return { status };
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await updateTransactionStatus(id, actionId, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    getAllPayOutTransactions,
    updateTransactionStatus,
    balanceSum,
    columns,
    totalPages,
    pageLists,
    error,
    isLoading,
    withdrawList,
    totalItems,
  };
};
