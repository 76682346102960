import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import _ from 'lodash';
import { useGame } from 'hooks/game/useGame';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoaderWithBg from 'components/loader/loader';
import { useProviders } from 'hooks/providers/useProviders';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

interface UploadImageProps {
  providerId: string;
}

function GamblingSystemProviderGameNames({ providerId }: UploadImageProps) {
  const { t } = useTranslation('common');
  const { t: siteT } = useTranslation('siteSettings');
  const { getAllSubProviders } = useProviders();
  const { getAllGameProvider, isLoading } = useGame();
  const [selectedProviderId, setSelectedProviderId] = useState('');
  const [subProviders, setSubProviders] = useState<any[]>();
  const [page, setPage] = useState(1);
  const [gamesImagesList, setGamesImagesList] = useState<any>([]);
  const [totalPage, setTotalPage] = useState(1);

  const generateDinamicColumns = useMemo(() => {
    return [
      {
        field: 'name',
        width: 300,
        disableColumnMenu: true,
        sortable: false,
      },
    ];
  }, []);

  const columns = generateDinamicColumns as GridColDef[];

  const handleChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setSelectedProviderId(value);
    setPage(1);
  };

  const getData = async (providerId: string) => {
    const res = await getAllSubProviders(providerId);
    if (res?.status === 200) {
      setSubProviders(res.data);
    }
  };

  const handleOnClickMore = (
    event: React.ChangeEvent<unknown>,
    totalPages: number,
    type: string,
  ) => {
    if (type === 'next') {
      if (totalPages > 1) {
        setPage((value) => (value + 1 <= totalPages ? value + 1 : value));
      }
    } else {
      setPage((value) => (value - 1 > 0 ? value - 1 : value));
    }
  };

  useEffect(() => {
    getData(providerId);
  }, [providerId]);

  useEffect(() => {
    const fetchData = async () => {
      const queryProvider = selectedProviderId ? `&subProviderId=${selectedProviderId}` : '';
      const paramsQuery = `?page=${page}${queryProvider}`;
      const res = await getAllGameProvider(providerId, `${paramsQuery}&pageSize=20`);
      if (res?.status === 200) {
        const newData = res.data.games;
        setGamesImagesList(newData);
        setTotalPage(res.data.totalPages);
      }
    };

    if (!isLoading) fetchData();
  }, [providerId, page, selectedProviderId]);

  return (
    <>
      {isLoading ? <LoaderWithBg isOpen={isLoading} /> : null}
      <Box sx={{ display: 'flex' }}>
        <FormControl size="small">
          <InputLabel id="provider-label">{siteT('providers')}</InputLabel>
          <Select
            sx={{ minWidth: 180, mr: 3 }}
            labelId="provider-label"
            id="provider-select"
            label={siteT('providers')}
            value={selectedProviderId}
            onChange={handleChange}
          >
            <MenuItem value="">
              <em>{t('none')}</em>
            </MenuItem>
            {subProviders?.map((item, index) => {
              return (
                <MenuItem key={index} value={item.id}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>

      <Box
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: '10px',
          marginTop: '20px',
        }}
      >
        {page < totalPage ? (
          <Stack spacing={2}>
            <Button onClick={(e) => handleOnClickMore(e, totalPage, 'prev')}>
              {t('prev')} {page} /{totalPage}{' '}
            </Button>
          </Stack>
        ) : null}
        <DataGrid
          getRowHeight={() => 'auto'}
          rows={gamesImagesList}
          columns={columns}
          hideFooterPagination={true}
          initialState={{
            columns: {
              columnVisibilityModel: {},
            },
          }}
          sx={{
            '.MuiDataGrid-iconButtonContainer': {
              visibility: 'visible',
            },
            '.MuiDataGrid-sortIcon': {
              opacity: 'inherit !important',
            },
            '.MuiDataGrid-cell': {
              '&:last-child': {
                justifyContent: 'center !important',
              },
            },
            '.MuiDataGrid-footerContainer': {
              display: 'none',
            },
            '.MuiDataGrid-row': {
              minHeight: '30px!important',
            },
          }}
        />
        {page < totalPage ? (
          <Stack spacing={2}>
            <Button onClick={(e) => handleOnClickMore(e, totalPage, 'next')}>
              {t('showMore')} {page} /{totalPage}{' '}
            </Button>
          </Stack>
        ) : null}
      </Box>
    </>
  );
}

export default GamblingSystemProviderGameNames;
