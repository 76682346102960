import {
  Box,
  TextField,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import { useState } from 'react';
import Result from 'components/alert/alert';
import UploadButtons from 'components/imageUpload/imageUpload';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useSiteCategory } from 'hooks/sites/useSiteCategory';
import { ISiteCategory, ISiteCategoryItem } from 'types/sites';
import { useTranslation } from 'react-i18next';
import styles from './modal.module.scss';

interface EditCategoryProps {
  title: string;
  siteId: string;
  item: ISiteCategoryItem;
  onClose: () => void;
}

const initialData = {
  title: '',
  image: '',
  isMobile: false,
  isDesktop: false,
  isPrimaryShow: false,
};

const style = {
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
};

function EditCategory({ title, onClose, siteId, item }: EditCategoryProps) {
  const { t: siteSettingsT } = useTranslation('siteSettings');
  const { saveCategory, getAllCategoryForSite, error } = useSiteCategory();
  const [formData, setFormData] = useState<ISiteCategory>({ ...initialData, ...item });

  const handleOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: string,
  ) => {
    const { value } = e.target;
    const data = { ...formData, [name]: value };
    setFormData(data);
  };

  const removeImage = () => {
    const data = { ...formData, image: null };
    setFormData(data);
  };

  const handleOnCheck = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    const { checked } = e.target;
    const data = { ...formData, [name]: checked };
    setFormData(data);
  };

  const onImageUpload = (data: string) => {
    setFormData((formData) => ({ ...formData, image: data }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const body = {
      title: formData.title,
      image: formData.image,
      isMobile: formData.isMobile,
      isDesktop: formData.isDesktop,
      isPrimaryShow: formData.isPrimaryShow,
    };

    const result = await saveCategory(siteId, item.id, body);
    if (result?.status === 200) {
      await getAllCategoryForSite(siteId);
      onClose();
    }
  };

  return (
    <>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        {title}
      </Typography>
      <Box sx={style}>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
          <Box>
            <TextField
              fullWidth
              required
              margin="normal"
              name="title"
              label={siteSettingsT('title')}
              variant="standard"
              value={formData.title}
              onChange={(e) => handleOnChange(e, 'title')}
            />
            <Box className={styles.content_part}>
              <UploadButtons onImageUpload={onImageUpload} initialImage={formData?.image ?? null} />
              {formData?.image ? (
                <DeleteForeverIcon
                  sx={{ color: '#b80909', fontSize: 30, cursor: 'pointer' }}
                  onClick={removeImage}
                />
              ) : null}
            </Box>
          </Box>
          <FormGroup aria-label="position" row>
            <Box sx={{ mr: 2, mt: 1, fontSize: 14 }}>{siteSettingsT('devicesShow')}</Box>
            <FormControlLabel
              sx={{ mr: 3 }}
              control={
                <Checkbox
                  checked={formData.isMobile}
                  onChange={(e) => handleOnCheck(e, 'isMobile')}
                  sx={{
                    '& .MuiSvgIcon-root': { fontSize: 14, color: 'text.primary' },
                  }}
                />
              }
              label={<InstallMobileIcon />}
              labelPlacement="end"
            />
            <FormControlLabel
              sx={{ mr: 3 }}
              control={
                <Checkbox
                  checked={formData.isDesktop}
                  onChange={(e) => handleOnCheck(e, 'isDesktop')}
                  sx={{
                    '& .MuiSvgIcon-root': { fontSize: 14, color: 'text.primary' },
                  }}
                />
              }
              label={<InstallDesktopIcon />}
              labelPlacement="end"
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              sx={{ mr: 3 }}
              control={
                <Checkbox
                  checked={formData.isPrimaryShow}
                  onChange={(e) => handleOnCheck(e, 'isPrimaryShow')}
                  sx={{
                    '& .MuiSvgIcon-root': { fontSize: 14, color: 'text.primary' },
                  }}
                />
              }
              label={siteSettingsT('showOnHomePage')}
              labelPlacement="end"
            />
          </FormGroup>

          {error && <Result style={{ mb: 2 }} message={error} type={'error'} isShow />}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Button fullWidth type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
              {siteSettingsT('save')}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default EditCategory;
