import { GridValueGetterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import FilterField from 'components/filter/columnFilter';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { IFilterData } from 'types/accounts';
import { columnsName, common, ROW_PER_PAGE } from 'constants/common';
import { isEmpty, makeQueryUrl } from 'utils/utils';
import { Box, Button, Modal, TablePagination, useTheme } from '@mui/material';
import { useSites } from 'hooks/sites/useSites';
import { useNavigate } from 'react-router-dom';
import { routing } from 'constants/routing';
import { ISites } from 'types/sites';
import { useDispatch, useSelector } from 'react-redux';
import CreateSite from 'components/modal/createSiteModal';
import LoaderWithBg from 'components/loader/loader';
import { setResetAll } from 'redux/actions/commonActions';
import { IconClose } from 'components/closeButton/closeButton';
import { useTranslation } from 'react-i18next';
import { isArray } from 'lodash';
import { ILanguage } from 'hooks/language/useLanguage';
import dayjs from 'dayjs';
import styles from './siteTable.module.scss';
import { style } from 'components/modal/modal';

export default function SitesTable() {
  const { t } = useTranslation('common');
  const { t: sitesT } = useTranslation('sites');
  const { isLoading, columns: columnNames, pageLists, queryParamsQuery, totalItems } = useSites();

  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState<IFilterData | object>({});
  const [sortData, setSortData] = useState<IFilterData | object>({});
  const [rowsPerPage, setRowsPerPage] = useState(ROW_PER_PAGE);

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (newPage >= +pageLists[pageLists.length - 1].val) {
      setPage(+pageLists[pageLists.length - 1].val);
    } else {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sitesData = useSelector<any>((state) => state.allSites) as {
    sites: ISites[];
  };
  const { sites: data } = sitesData;
  const title = `${t(common.ADD)} ${t(common.NEW)} ${t(common.SITE)}`;

  const handleClose = () => {
    setOpenModal(false);
  };
  const handleOpen = () => setOpenModal(true);

  const onButtonClick = (id: string) => {
    navigate(`${routing.sitesSettings}/${id}`);
  };

  const handleFilter = (name: string, value: string) => {
    setFilterData((filterby) => ({ ...filterby, [name]: value }));
  };

  const handleSortBy = (sortBy: string, sortDir: string) => {
    setSortData({ [sortBy]: sortDir });
  };

  const handleResetAll = () => {
    dispatch(setResetAll(true));
    setFilterData({});
    setSortData({});
  };

  const generateLanguages = (list: ILanguage) => {
    let res = '';
    if (isArray(list)) {
      for (const el of list) {
        res += ', ' + el.code;
      }
    }
    return res.replace(/^. /, '');
  };

  const actionFields = [
    {
      headerName: '',
      field: 'actions',
      width: 200,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridValueGetterParams) => (
        <Button fullWidth variant="contained" onClick={() => onButtonClick(params.row.id)}>
          {sitesT('settings')}
        </Button>
      ),
    },
  ];

  const generateDinamicColumns = useMemo(() => {
    const columns = columnNames.map((el) => {
      if (el === columnsName.CREATED_AT) {
        return {
          field: el,
          width: el.length * 6 + 110,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isCamelCase
            />
          ),
          valueGetter: (params: GridValueGetterParams) =>
            `${dayjs(params.row.createdAt).format('D/MM/YYYY HH:mm:ss')}`,
        };
      }
      if (el === columnsName.CURRENCIES) {
        return {
          field: el,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isDisabled
              isCamelCase
            />
          ),
          valueGetter: (params: GridValueGetterParams) =>
            `${params.row.currencies?.map((el: any) => {
              let curr = '';
              curr = curr + ' ' + el?.code.toLowerCase();
              return curr;
            })}`,
        };
      }
      if (el === columnsName.IS_ACTIVE) {
        return {
          field: el,
          disableColumnMenu: true,
          width: el.length * 6 + 110,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) =>
                handleFilter(
                  el,
                  value === t(common.YES)
                    ? common.TRUE
                    : value === t(common.NO)
                    ? common.FALSE
                    : value,
                )
              }
              onSort={handleSortBy}
              sortData={sortData}
              isCamelCase
            />
          ),
          valueGetter: (params: GridValueGetterParams) =>
            `${params.row.isActive ? t(common.YES) : t(common.NO)}`,
        };
      }

      return {
        field: el,
        width: el.length * 6 + 150,
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => (
          <FilterField
            fieldName={el}
            onBlur={(value) => handleFilter(el, value)}
            onSort={handleSortBy}
            sortData={sortData}
            isDisabled={el === columnsName.LANGUAGES}
            isCamelCase
          />
        ),
        valueGetter: (params: GridValueGetterParams): any =>
          el === columnsName.LANGUAGES
            ? params.row[el].length
              ? generateLanguages(params.row[el])
              : ''
            : `${params.row[el]}`,
      };
    });
    return [...columns, ...actionFields];
  }, [columnNames, sortData]);

  const columns = generateDinamicColumns as GridColDef[];

  useEffect(() => {
    const query = makeQueryUrl(filterData);
    const sortBy = Object.entries(sortData)[0];

    let paramsQuery = isEmpty(sortData)
      ? query
      : `${query}&sortBy=${sortBy[0]}&sortDir=${sortBy[1]}`;

    paramsQuery += `&page=${page + 1}&pageSize=${rowsPerPage}`;

    queryParamsQuery(paramsQuery);
  }, [filterData, sortData, page, rowsPerPage]);

  return (
    <div style={{ width: '100%' }}>
      {isLoading ? (
        <LoaderWithBg isOpen={isLoading} />
      ) : (
        <>
          <Box
            sx={{ mb: 3, justifyContent: 'flex-end', alignItems: 'center' }}
            className={styles.filter_box}
          >
            <div className={styles.filter_btn}>
              <Box sx={{ mt: 2, mb: 2 }}>
                <Button variant="contained" onClick={handleOpen}>
                  {title}
                </Button>
              </Box>
              <Box sx={{ display: 'flex' }}>
                {Object.values(filterData).join() || Object.values(sortData).join() ? (
                  <Button
                    className={styles.reset_all_btn}
                    variant="contained"
                    onClick={handleResetAll}
                  >
                    {t('sites:resetAll')}
                  </Button>
                ) : null}
              </Box>
            </div>
          </Box>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            labelRowsPerPage={t('rowsPerPage')}
            labelDisplayedRows={({ from, to, count }) =>
              `${from >= to ? to : from}-${to} ${t('of')} ${count}`
            }
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <DataGrid
            autoHeight
            rows={data}
            columns={columns}
            hideFooterPagination={true}
            sx={{
              width: '100%',
              overflow: 'auto',
              '.MuiDataGrid-iconButtonContainer': {
                visibility: 'visible',
              },
              '.MuiDataGrid-sortIcon': {
                opacity: 'inherit !important',
              },
              '.MuiDataGrid-footerContainer': {
                display: 'none',
              },
            }}
          />

          {openModal && (
            <Modal
              open={openModal}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  ...style,
                  width: 'calc(100%-600px)',
                  minWidth: '500px',
                  bgcolor: `${
                    theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff'
                  }`,
                }}
              >
                <CreateSite onClose={handleModalClose} title={title} />
                <IconClose onClose={handleClose} />
              </Box>
            </Modal>
          )}
        </>
      )}
    </div>
  );
}
