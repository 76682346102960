import { IBreadItem } from 'components/menu/mainMenu';

export enum STORAGE_KEYS {
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken',
  ROLE = 'role',
  MODE = 'mode',
  IS_OPEN = 'menuOpened',
  OPENED_ITEM_INDEX = 'openedMenuItemIndex',
  ACTIVE_MENU = 'activeMenu',
  ACTIVE_SUB_MENU = 'activeSubMenu',
  LANGUAGE_KEY = 'languageKey',
  TAB_VALUE = 'tabValue',
  ACCOUNT = 'account',
}

type StorageData = {
  [STORAGE_KEYS.ACCESS_TOKEN]: string;
  [STORAGE_KEYS.REFRESH_TOKEN]: string;
  [STORAGE_KEYS.ACTIVE_MENU]: IBreadItem;
  [STORAGE_KEYS.ACTIVE_SUB_MENU]: IBreadItem;
  [STORAGE_KEYS.MODE]: string;
  [STORAGE_KEYS.IS_OPEN]: boolean | null;
  [STORAGE_KEYS.OPENED_ITEM_INDEX]: string | null;
  [STORAGE_KEYS.LANGUAGE_KEY]: string | null;
  [STORAGE_KEYS.TAB_VALUE]: string | null;
  [STORAGE_KEYS.ROLE]: string | null;
  [STORAGE_KEYS.ACCOUNT]: any;
};

export class StorageService {
  private storage: Storage;

  constructor(storage: Storage) {
    this.storage = storage;
  }

  read<T extends STORAGE_KEYS>(key: T): StorageData[T] | null {
    const data = this.storage.getItem(key);
    if( data === 'undefined')  return this.storage.clear();
    return data ? JSON.parse(data) : null;
  }

  put<T extends STORAGE_KEYS>(key: T, data: StorageData[T]): void {
    this.storage.setItem(key, JSON.stringify(data));
  }

  remove(key: STORAGE_KEYS): void {
    this.storage.removeItem(key);
  }
}

const storageService = new StorageService(localStorage);

export const localStorageService = storageService;
