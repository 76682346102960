import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography,
  AlertColor,
  Checkbox,
} from '@mui/material';
import { useEffect, useState } from 'react';
import Result from 'components/alert/alert';
import common from 'styles/common.module.scss';
import { useTranslation } from 'react-i18next';
import { ERROR, SUCCESS } from 'constants/common';
import { FIELD_NAME } from 'types/balances';
import { isGreaterThan } from 'utils/validations/validationTypes';
import { useBalances } from 'hooks/clients/useBalances';
import { useSelector } from 'react-redux';
import styles from './balanceForm.module.scss';

interface DepositFormProps {
  title: string;
  action: string;
  selectData?: any[];
  isTransfer?: boolean;
  isBonus?: boolean;
  btnTitle: string;
  data: any;
}

interface IForm {
  comment: string;
  amount: null;
  reasonId?: string;
  typeId?: string;
  toTypeId?: string;
  fromTypeId?: string;
  name?: string;
  weiger?: string;
  isNative?: boolean;
}

const style = {
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  textAlign: 'center',
  width: '100%',
};

const fields = {
  [FIELD_NAME.AMOUNT]: true,
  [FIELD_NAME.WEIGER]: true,
  [FIELD_NAME.REASON_ID]: true,
  [FIELD_NAME.TYPE_ID]: true,
  [FIELD_NAME.COMMENT]: true,
  [FIELD_NAME.TO]: true,
};

const helperObjData = {
  2: 'game',
  3: 'wallet',
  1: 'bonus',
  0: '',
} as { [x: string]: string };

function DepositForm({ title, isBonus, action, btnTitle, isTransfer, data }: DepositFormProps) {
  const { t: clientsT } = useTranslation('clients');
  const { t: columnsT } = useTranslation('columns');
  const { t: clientDetailsT } = useTranslation('clientsDetails');
  const { t: validationT } = useTranslation('validations');
  const [formData, setFormData] = useState<IForm>({} as IForm);
  const [validFields, setValidFields] = useState({ ...fields });
  const [result, setResult] = useState('');
  const [isBonusOn, setIsBonusOn] = useState(false);
  const [isBtnCLicked, setIsBtnClicked] = useState(false);

  const [type, setType] = useState<AlertColor>(SUCCESS);
  const { error, resetError, updateClientBalance, updateBonusBalance } = useBalances();
  const { role: mainRole } = useSelector((state: any) => state.siteRole) as { role: '' };
  const [isNative, setIsNative] = useState(!!mainRole && mainRole === 'support');

  const onSubmit = async (body: any, isBonus = false) => {
    let result;
    setIsBtnClicked(true);
    if (isBonus) {
      result = await updateBonusBalance(data.user.id, body);
    } else {
      result = await updateClientBalance(data.user.id, body);
    }

    if (result?.status) {
      setResult(SUCCESS);
      setType(SUCCESS);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!isBtnCLicked) {
      if (btnTitle === 'anulate') {
        if (formData.typeId === undefined || formData.typeId === '') {
          setValidFields((fields) => ({
            ...fields,
            [FIELD_NAME.TYPE_ID]: false,
          }));
        } else {
          onSubmit({ ...formData, action, isAnnulation: true });
        }
      } else if (btnTitle === 'deposit') {
        if (isBonus && isBonusOn) {
          const body = {
            amount: formData.amount,
            name: formData.name,
            weiger: formData.weiger,
            comment: formData.comment,
            isActive: true,
          };
          onSubmit(body, true);
        } else {
          onSubmit({ ...formData, action });
        }
      } else if (isTransfer) {
        if (formData.fromTypeId && formData.toTypeId) {
          //**TODO write request when BE to be ready */
          console.log(formData, 'isTransfer');
          // onSubmit();
        }
      } else {
        if (formData.typeId === undefined || formData.typeId === '') {
          setValidFields((fields) => ({
            ...fields,
            [FIELD_NAME.TYPE_ID]: false,
          }));
        } else {
          onSubmit({ ...formData, action });
        }
      }
    }
  };

  const handleOnSwicth = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const { checked } = e.target;
    let data;
    if (!checked) {
      data = {
        ...formData,
        typeId: isNative || (!!mainRole && mainRole === 'support') ? '3' : isBonus ? '' : '2',
      };
    } else {
      data = { ...formData, typeId: '1' };
    }
    if (!!mainRole && mainRole === 'support') {
      setIsNative(true);
    }
    setFormData(data);
    setIsBonusOn(checked);
  };

  const handleOnClose = () => {
    if (!!mainRole && mainRole === 'support') {
      setFormData({ typeId: '3', isNative: true } as IForm);
    } else {
      setFormData({} as IForm);
      setIsNative(false);
      setIsBonusOn(false);
    }
    setIsBtnClicked(false);
    setResult('');
    resetError();
  };

  const handleChange = (event: SelectChangeEvent, name: string) => {
    const { value } = event.target;

    const data = { ...formData, [name]: value };
    setFormData(data);
    setValidFields((fields) => ({
      ...fields,
      [name]: true,
    }));
  };

  const handleChangeTransfer = (event: SelectChangeEvent, name: string) => {
    const { value } = event.target;
    if (name === FIELD_NAME.TO && formData.fromTypeId) {
      const data = { ...formData, [name]: value };
      setFormData(data);
      setValidFields((fields) => ({
        ...fields,
        [name]: true,
      }));
    } else if (name === FIELD_NAME.FROM) {
      const data = { ...formData, [name]: value };
      setFormData(data);
      setValidFields((fields) => ({
        ...fields,
        [FIELD_NAME.TO]: true,
      }));
    } else {
      setValidFields((fields) => ({
        ...fields,
        [name]: false,
      }));
    }
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: string,
  ) => {
    const { value } = e.target;
    const data = { ...formData, [name]: value };
    setFormData(data);
    setResult('');
    setType(SUCCESS);
    resetError();
  };

  const handleOnChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    if (checked) {
      const data = { ...formData, typeId: '3', isNative: checked };
      setFormData(data);
    } else {
      const data = { ...formData, typeId: '', isNative: checked };
      setFormData(data);
    }
    setIsNative(checked);
  };

  const checkUserAmount = (value: string) => {
    const userbalance =
      data?.user?.balances?.filter(
        (el: any) => el.type === helperObjData[formData?.fromTypeId ?? '0'],
      )?.[0]?.amount ?? null;
    if (userbalance) {
      const amount = userbalance.split(' ')[0];
      return +value <= +amount && +value > 0;
    }
    return false;
  };

  const validate = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, name: string) => {
    const { value } = e.target;
    if (value !== '') {
      setValidFields((fields) => ({
        ...fields,
        [name]: isTransfer ? checkUserAmount(value) : isGreaterThan(0)(value),
      }));
    }
  };

  useEffect(() => {
    if (error) {
      setResult(error);
      setType(ERROR);
    }
  }, [error]);

  useEffect(() => {
    if (btnTitle === 'deposit' && isBonus && !isBonusOn) {
      if (!!mainRole && mainRole === 'support') {
        const data = { ...formData, typeId: '3', isNative: true };
        setFormData(data);
      }
    }
  }, []);

  return (
    <>
      <Box className={styles.form_box}>
        <Typography sx={{ fontSize: 16 }}>{title}</Typography>
        {isBonus ? (
          <Box className={styles.isBonus}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch checked={isBonusOn} onChange={(e) => handleOnSwicth(e, 'isBonus')} />
                }
                label={clientDetailsT('isBonus')}
              />
            </FormGroup>
          </Box>
        ) : null}
        {!isBonusOn && btnTitle === 'deposit' ? (
          <Box className={styles.isBonus}>
            <FormControlLabel
              sx={{ minWidth: 'max-content' }}
              control={
                <Checkbox
                  checked={isNative}
                  onChange={handleOnChecked}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 16 }, m: 0, p: 0.5 }}
                  inputProps={{ readOnly: true }}
                />
              }
              label={'Депозит через X-Pay'}
            />
          </Box>
        ) : null}

        <Box sx={style}>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ mt: isBonus ? 0 : 1, display: 'flex', width: '100%' }}
          >
            <Box className={styles.left}>
              <TextField
                sx={{ mb: 2, minWidth: 120 }}
                className={styles.input}
                required
                value={formData.amount ?? ''}
                fullWidth
                margin="normal"
                type="number"
                name="amount"
                label={clientDetailsT('amount')}
                variant="standard"
                onChange={(e) => handleOnChange(e, FIELD_NAME.AMOUNT)}
                onBlur={(e) => validate(e, FIELD_NAME.AMOUNT)}
                error={!validFields[FIELD_NAME.AMOUNT]}
                helperText={
                  validFields[FIELD_NAME.AMOUNT]
                    ? ''
                    : isTransfer
                    ? validationT('greaterThanAndSmall', { value: 0 })
                    : validationT('greaterThan', { value: 0 })
                }
              />
              {isBonus ? (
                <Box sx={{ display: 'flex' }}>
                  {formData?.typeId !== '1' ? (
                    <>
                      <FormControl fullWidth size="small">
                        <InputLabel id="provider-label">
                          {clientDetailsT('selectReason')}
                        </InputLabel>
                        <Select
                          fullWidth
                          sx={{ minWidth: formData?.typeId === '1' ? 190 : 100 }}
                          id="provider-select"
                          label={clientDetailsT('selectReason')}
                          value={formData.reasonId ? formData.reasonId : ''}
                          onChange={(e) => handleChange(e, FIELD_NAME.REASON_ID)}
                          error={!validFields[FIELD_NAME.REASON_ID]}
                        >
                          <MenuItem value="">
                            <em>{clientsT('none')}</em>
                          </MenuItem>
                          {data.reasonsList?.map((item: any, index: number) => {
                            return (
                              <MenuItem key={index} value={item.id}>
                                {item.text}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <FormControl size="small" sx={{ ml: 2, mr: 6 }}>
                        <InputLabel id="provider-label">{clientDetailsT('select')}</InputLabel>
                        <Select
                          required
                          sx={{ minWidth: 120, mr: 3, textTransform: 'capitalize' }}
                          id="provider-select"
                          label={clientDetailsT('select')}
                          value={formData?.typeId ? formData?.typeId : ''}
                          onChange={(e) => handleChange(e, FIELD_NAME.TYPE_ID)}
                          error={!validFields[FIELD_NAME.TYPE_ID]}
                          disabled={isNative}
                        >
                          <MenuItem value="">
                            <em>{clientsT('none')}</em>
                          </MenuItem>
                          {data.balanceList?.map((item: any, index: number) => {
                            if (+item.id !== 1) {
                              return (
                                <MenuItem
                                  sx={{ textTransform: 'capitalize' }}
                                  key={index}
                                  value={item.id}
                                >
                                  {item.name}
                                </MenuItem>
                              );
                            }
                          })}
                        </Select>
                      </FormControl>
                    </>
                  ) : (
                    <Box sx={{ mr: 3, display: 'flex' }}>
                      <TextField
                        className={styles.input}
                        sx={{ mb: 2, minWidth: '120px' }}
                        value={formData.name ?? ''}
                        fullWidth
                        margin="normal"
                        name="comment"
                        label={columnsT('name')}
                        variant="standard"
                        onChange={(e) => handleOnChange(e, FIELD_NAME.NAME)}
                      />
                      <TextField
                        type="number"
                        className={styles.input}
                        sx={{ ml: 1, minWidth: '120px' }}
                        value={formData.weiger ?? ''}
                        fullWidth
                        margin="normal"
                        name="comment"
                        label={columnsT('weidger')}
                        variant="standard"
                        onChange={(e) => handleOnChange(e, FIELD_NAME.WEIGER)}
                        onBlur={(e) => validate(e, FIELD_NAME.WEIGER)}
                        error={!validFields[FIELD_NAME.WEIGER]}
                        helperText={
                          validFields[FIELD_NAME.WEIGER]
                            ? ''
                            : validationT('greaterThan', { value: 1 })
                        }
                      />
                    </Box>
                  )}
                </Box>
              ) : (
                <Box>
                  {isTransfer ? (
                    <Box className={styles.transfer}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="transfer-from">{clientDetailsT('from')}</InputLabel>
                        <Select
                          fullWidth
                          required
                          sx={{ minWidth: 120, mr: 3, textTransform: 'capitalize', maxWidth: 150 }}
                          id="transfer-from"
                          label={clientDetailsT('from')}
                          value={formData?.fromTypeId ? formData?.fromTypeId : ''}
                          onChange={(e) => handleChangeTransfer(e, FIELD_NAME.FROM)}
                        >
                          <MenuItem value="">
                            <em>{clientsT('none')}</em>
                          </MenuItem>
                          {data.balanceList?.map((item: any, index: number) => {
                            return (
                              <MenuItem
                                sx={{ textTransform: 'capitalize' }}
                                key={index}
                                value={item.id}
                              >
                                {item.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <Box className={styles.user_balance_amount}>
                          {formData?.fromTypeId ? (
                            <Typography component="span"> Balance: </Typography>
                          ) : null}

                          {data?.user?.balances?.filter(
                            (el: any) => el.type === helperObjData[formData?.fromTypeId ?? '0'],
                          )?.[0]?.amount ?? ''}
                        </Box>
                      </FormControl>
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <FormControl fullWidth size="small" sx={{ ml: 2 }}>
                          <InputLabel id="transfer-to">{clientDetailsT('to')}</InputLabel>
                          <Select
                            fullWidth
                            required
                            sx={{
                              minWidth: 120,
                              mr: 4,
                              textTransform: 'capitalize',
                              maxWidth: 150,
                            }}
                            id="transfer-to"
                            label={clientDetailsT('to')}
                            value={formData?.toTypeId ? formData?.toTypeId : ''}
                            onChange={(e) => handleChangeTransfer(e, FIELD_NAME.TO)}
                          >
                            <MenuItem value="">
                              <em>{clientsT('none')}</em>
                            </MenuItem>
                            {data.balanceList?.map((item: any, index: number) => {
                              if (item.id !== formData?.fromTypeId)
                                return (
                                  <MenuItem
                                    sx={{ textTransform: 'capitalize' }}
                                    key={index}
                                    value={item.id}
                                  >
                                    {item.name}
                                  </MenuItem>
                                );
                            })}
                          </Select>
                        </FormControl>
                        <Box sx={{ fontSize: 10, color: common.mainwarning }}>
                          {validFields[FIELD_NAME.TO] ? '' : validationT('cooseFrom')}
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <FormControl fullWidth size="small">
                      <InputLabel id="provider-label">{clientDetailsT('select')}</InputLabel>
                      <Select
                        fullWidth
                        required
                        sx={{ minWidth: 190, mr: 3, textTransform: 'capitalize' }}
                        id="provider-select"
                        label={clientDetailsT('select')}
                        value={formData?.typeId ? formData?.typeId : ''}
                        onChange={(e) => handleChange(e, FIELD_NAME.TYPE_ID)}
                        error={!validFields[FIELD_NAME.TYPE_ID]}
                      >
                        <MenuItem value="">
                          <em>{clientsT('none')}</em>
                        </MenuItem>
                        {data.balanceList?.map((item: any, index: number) => {
                          return (
                            <MenuItem
                              sx={{ textTransform: 'capitalize' }}
                              key={index}
                              value={item.id}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                </Box>
              )}
            </Box>
            <Box className={styles.right}>
              <TextField
                className={styles.input}
                sx={{ mb: 2 }}
                value={formData.comment ?? ''}
                fullWidth
                margin="normal"
                name="comment"
                label={clientDetailsT('comment')}
                variant="standard"
                onChange={(e) => handleOnChange(e, FIELD_NAME.COMMENT)}
              />
              <Button
                fullWidth
                type="submit"
                variant="contained"
                sx={{
                  minWidth: '120px',
                  color: common.white,
                  bgcolor: isBonus
                    ? common.mainBlue
                    : isTransfer
                    ? common.green
                    : common.mainwarning,
                }}
                disabled={!validFields[FIELD_NAME.AMOUNT] || !validFields[FIELD_NAME.TYPE_ID]}
              >
                {isBonus && formData.typeId === '1'
                  ? clientDetailsT('giveBonus')
                  : clientDetailsT(btnTitle)}
              </Button>
            </Box>
          </Box>
        </Box>
        {result && (
          <Result
            style={{ mb: 2, mt: 2 }}
            message={result}
            type={type}
            isShow
            hasAction
            onClose={handleOnClose}
          />
        )}
      </Box>
    </>
  );
}

export default DepositForm;
