export const routing = {
  home: '/',
  admin: '/admin',
  account: '/account',
  client: '/clients',
  gameLogs: '/game/logs',
  clientDetails: '/clients/details',
  identifications: '/clients/identifications',
  sites: '/sites',
  statistics: '/statistics',
  channel: 'telegram/channels',
  raffle: 'telegram/raffles',
  sitesSettings: '/sites/settings',
  permissions: '/permissions',
  labels: '/labels',
  gamblingStatistics: '/gambling/statistics',
  gameSettings: '/gambling/gameSettings',
  gameNames: '/gambling/gameNames',
  providerSettings: '/gambling/providerSettings',
  deposit: '/finance/deposits',
  cashbacks:'/finance/cashbacks',
  withdrawals: '/finance/withdrawals',
  financeLogs: '/finance/logs',
  promotion: '/promocode/promotion',
  multiuser: '/promocode/multiuser',
  notFound: '*',
  bonuses: '/finance/bonuses',
  addBonusesForClients: '/clients/addBonuses',
};

export const sensetiveRouts = [routing.admin];
