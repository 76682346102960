import { GridValueGetterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import FilterField from 'components/filter/columnFilter';
import { useAccounts } from 'hooks/accounts/useAccounts';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { IAccountTableData, IFilterData, roleTypes } from 'types/accounts';
import { columnsName, common, ROW_PER_PAGE } from 'constants/common';
import moment from 'moment';
import { isEmpty, makeQueryUrl } from 'utils/utils';
import SitesList from 'components/filter/siteLists';
import { Box, Button, Link, Modal, TablePagination, useTheme } from '@mui/material';
import DropDownSelect from 'components/select/select';
import EditAccount from 'components/editAccount/editAccount';
import DetailsAccount from 'components/detailsAccount/detailsAccount';
import LoaderWithBg from 'components/loader/loader';
import { useDispatch, useSelector } from 'react-redux';
import { setResetAll } from 'redux/actions/commonActions';
import { IconClose } from 'components/closeButton/closeButton';
import { useTranslation } from 'react-i18next';
import styles from './table.module.scss';
import { style } from 'components/modal/modal';

export interface DataTableProps {
  role: roleTypes;
}

export default function AccountsTable({ role }: DataTableProps) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    isLoading,
    getAccountsData,
    totalItems,
    columns: columnNames,
    pageLists,
    queryParamsQuery,
  } = useAccounts(role);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROW_PER_PAGE);
  const accountsData = useSelector<any>((state) => state.allAccounts) as {
    accounts: IAccountTableData[];
  };
  const { accounts: data } = accountsData;

  const [openModal, setOpenModal] = useState(false);
  const [currentId, setCurrentId] = useState('');
  const [modalMode, setModalMode] = useState('');
  const [filterData, setFilterData] = useState<IFilterData | object>({});
  const [sortData, setSortData] = useState<IFilterData | object>({});
  const [resetGoToPage, setResetGoToPage] = useState(false);
  const { t } = useTranslation('common');
  const { t: accountRolesT } = useTranslation('accountRoles');

  const handleClose = () => {
    setOpenModal(false);
    setModalMode('');
    setCurrentId('');
    getAccountsData();
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onButtonClick = (id: string, mode: string) => {
    setCurrentId(id);
    setModalMode(mode);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (newPage >= +pageLists[pageLists.length - 1].val) {
      setPage(+pageLists[pageLists.length - 1].val);
    } else {
      setPage(newPage);
    }
    setResetGoToPage((prev) => !prev);
  };

  const handleFilter = (name: string, value: string) => {
    setFilterData((filterby) => ({ ...filterby, [name]: value }));
  };

  const handleSortBy = (sortBy: string, sortDir: string) => {
    setSortData({ [sortBy]: sortDir });
  };

  const handleResetAll = () => {
    getAccountsData();
    dispatch(setResetAll(true));
    setFilterData({});
    setSortData({});
  };

  const handleSelectPage = (page: string) => {
    setPage(+page - 1);
  };

  const actionFields = [
    {
      field: 'Manage Permissions',
      headerName: 'Manage Permissions',
      width: 150,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridValueGetterParams) => (
        <Link color={'text.primary'} href={`/permissions/${params.row.id}`}>
          {accountRolesT('manage')}
        </Link>
      ),
    },
    {
      field: 'details',
      headerName: 'Details',
      width: 160,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridValueGetterParams) => (
        <Button
          fullWidth
          variant="contained"
          onClick={() => onButtonClick(params.row.id, 'details')}
        >
          {accountRolesT('details')}
        </Button>
      ),
    },
    {
      field: 'edit',
      headerName: 'Edit',
      width: 160,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridValueGetterParams) => (
        <Button fullWidth variant="contained" onClick={() => onButtonClick(params.row.id, 'edit')}>
          {accountRolesT('edit')}
        </Button>
      ),
    },
  ];

  const generateDinamicColumns = useMemo(() => {
    const columns = columnNames.map((el) => {
      if (el === columnsName.CREATED_AT) {
        return {
          field: el,
          width: Math.ceil(window.innerWidth / (columnNames.length + 3)),
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={columnsName.CREATED.toLocaleUpperCase()}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
            />
          ),
          valueGetter: (params: GridValueGetterParams) =>
            `${moment(params.row.createdAt).format('DD/MM/Y HH:mm:ss')}`,
        };
      } else if (el === columnsName.IS_ACTIVE) {
        return {
          field: el,
          width: 150,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={columnsName.ACTIVE.toLocaleUpperCase()}
              onBlur={(value) =>
                handleFilter(
                  el,
                  value === t(common.YES)
                    ? common.TRUE
                    : value === t(common.NO)
                    ? common.FALSE
                    : value,
                )
              }
              onSort={handleSortBy}
              sortData={sortData}
            />
          ),
          valueGetter: (params: GridValueGetterParams) =>
            `${params.row.isActive ? t(common.YES) : t(common.NO)}`,
        };
      }

      return {
        field: el,
        width: el === columnsName.ID ? 100 : Math.ceil(window.innerWidth / columnNames.length),
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => (
          <FilterField
            fieldName={el.toLocaleUpperCase()}
            onBlur={(value) => handleFilter(el, value)}
            onSort={handleSortBy}
            sortData={sortData}
          />
        ),
      };
    });
    return [...columns, ...actionFields];
  }, [columnNames, sortData]);

  const columns = generateDinamicColumns as GridColDef[];

  useEffect(() => {
    const query = makeQueryUrl(filterData);
    const sortBy = Object.entries(sortData)[0];

    let paramsQuery = isEmpty(sortData)
      ? query
      : `${query}&sortBy=${sortBy[0]}&sortDir=${sortBy[1]}`;

    paramsQuery += `&page=${page + 1}&pageSize=${rowsPerPage}`;

    if (!isLoading) queryParamsQuery(paramsQuery);
  }, [filterData, sortData, page, rowsPerPage]);

  useEffect(() => {
    if (currentId) setOpenModal(true);
  }, [currentId]);

  return (
    <div style={{ width: '100%' }}>
      {isLoading ? (
        <LoaderWithBg isOpen={isLoading} />
      ) : (
        <>
          <Box className={styles.filter_box}>
            <div className={styles.filter_btn}>
              <Box sx={{ display: 'flex' }}>
                <DropDownSelect
                  totalPages={pageLists}
                  onSelectPage={handleSelectPage}
                  reset={resetGoToPage}
                />
                {Object.values(filterData).join() || Object.values(sortData).join() ? (
                  <Button
                    className={styles.reset_all_btn}
                    variant="contained"
                    onClick={handleResetAll}
                  >
                    {t('ressetAll')}
                  </Button>
                ) : null}
              </Box>
            </div>
            <SitesList onSelectSite={handleFilter} initValue={isEmpty(filterData)} />
          </Box>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            labelRowsPerPage={t('rowsPerPage')}
            labelDisplayedRows={({ from, to, count }) =>
              `${from >= to ? to : from}-${to} ${t('of')} ${count}`
            }
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <DataGrid
            autoHeight
            rows={data}
            columns={columns}
            hideFooterPagination={true}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  role: false,
                },
              },
            }}
            sx={{
              '.MuiDataGrid-iconButtonContainer': {
                visibility: 'visible',
              },
              '.MuiDataGrid-sortIcon': {
                opacity: 'inherit !important',
              },
              '.MuiDataGrid-cell': {
                '&:last-child': {
                  justifyContent: 'center !important',
                },
              },
              '.MuiDataGrid-footerContainer': {
                display: 'none',
              },
            }}
          />

          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                ...style,
                bgcolor: `${
                  theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff'
                }`,
              }}
            >
              {modalMode === 'edit' ? (
                <EditAccount role={role} closeModal={handleClose} currentId={currentId} />
              ) : (
                <DetailsAccount role={role} currentId={currentId} closeModal={handleClose} />
              )}
              <IconClose onClose={handleClose} />
            </Box>
          </Modal>
        </>
      )}
    </div>
  );
}
