import { TextField, Button } from '@mui/material';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { FIELD_NAME } from 'types/sitesSettings';
import { useTranslation } from 'react-i18next';
import styles from './paymentSettings.module.scss';

const initialData = {
  shop_id: '',
  password: '',
  host: '',
};

const mock = {
  shop_id: '012345',
  password: '12345678',
  host: 'en.wikipedia.org',
};

function Piastrix() {
  const { t: siteSettingsT } = useTranslation('siteSettings');
  const [data, setData] = useState({ ...initialData, ...mock });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const submitData = { ...data };
    console.log(submitData, 'dataSubmit');
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: string,
  ) => {
    const { value } = e.target;
    const newData = { ...data, [name]: value };

    setData(newData);
  };

  return (
    <>
      <Box className={styles.main}>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            id="shop_id"
            label={siteSettingsT('shopId')}
            fullWidth
            required
            variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
            value={data.shop_id}
            onChange={(e) => handleOnChange(e, FIELD_NAME.SHOP_ID)}
          />
          <TextField
            margin="normal"
            id="password"
            label={siteSettingsT('password')}
            fullWidth
            required
            variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
            value={data.password}
            onChange={(e) => handleOnChange(e, FIELD_NAME.PASSWORD)}
          />
          <TextField
            margin="normal"
            id="host"
            label={siteSettingsT('host')}
            fullWidth
            required
            variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
            value={data.host}
            onChange={(e) => handleOnChange(e, FIELD_NAME.HOST)}
          />
          <Box className={styles.actions}>
            <Button fullWidth type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
              {siteSettingsT('saveSettings')}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Piastrix;
