import { useApiErrors } from 'hooks/errors/useApiErrors';
import { useStorage } from 'hooks/authHooks/useStorage';
import { useState } from 'react';
import { generateHeaders, joinMessage } from 'utils/utils';
import SiteService from 'services/site.service';
import { addCategoryGames, setSiteCategoryGames } from 'redux/actions/sitesActions';
import { useDispatch } from 'react-redux';

export const useDragGame = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState('');

  const { logout, userToken } = useStorage();
  const { errorHandler } = useApiErrors();
  const initHeaders = generateHeaders(userToken());

  const putGamesInCategory: any = async (
    siteId: string,
    categoryId: string,
    body: any,
    query: string,
    headers = initHeaders,
  ) => {
    try {
      setIsLoading(true);

      const {
        data: { data, status },
      } = await SiteService.putGamesOnDrag(headers, siteId, categoryId, body, query);
      setIsLoading(false);
      return { data, status, loading: false };
    } catch (error: any) {
      if (error.response) {
        const {
          response: {
            data: { message, status },
          },
        } = error;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          logout();
        } else if (!needLogout && newHeader) {
          return await putGamesInCategory(siteId, categoryId, body, query, newHeader);
        } else {
          console.log('LOG____>', message);
          setError(message);
        }
      }
    }
  };
  const changeGamesDevice: any = async (
    siteId: string,
    categoryId: string,
    body: any,
    headers = initHeaders,
  ) => {
    try {
      setIsLoading(true);

      const {
        data: { data, status },
      } = await SiteService.changeGamesDevice(headers, siteId, categoryId, body);
      setIsLoading(false);
      return { data, status, loading: false };
    } catch (error: any) {
      if (error.response) {
        const {
          response: {
            data: { message, status },
          },
        } = error;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          logout();
        } else if (!needLogout && newHeader) {
          return await changeGamesDevice(siteId, categoryId, body, newHeader);
        } else {
          console.log('LOG____>', message);
          setError(message);
        }
      }
    }
  };

  const getAllGamesByCategoryId: any = async (
    providerId: string,
    siteId: string,
    categoryId: string,
    query: string,
    headers = initHeaders,
  ) => {
    try {
      setIsLoading(true);

      const {
        data: { data },
      } = await SiteService.getAllGamesByCategoryId(headers, siteId, categoryId, query);
      setIsLoading(false);
      const preparedData = data.game_categories.length
        ? data.game_categories.map((el: any) => ({
            ...el,
            id: el.id + `_${providerId}`,
          }))
        : [];
      if (data.game_categories.length) {
        dispatch(setSiteCategoryGames(preparedData));
      }
      return { data: preparedData, loading: false };
    } catch (error: any) {
      if (error.response) {
        const {
          response: {
            data: { message, status },
          },
        } = error;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          logout();
        } else if (!needLogout && newHeader) {
          return await getAllGamesByCategoryId(siteId, categoryId, query, newHeader);
        } else {
          console.log('LOG____>', message);
          setError(message);
        }
      }
    }
  };

  const getChunkGamesByCategoryId: any = async (
    siteId: string,
    categoryId: string,
    query: string,
    headers = initHeaders,
  ) => {
    try {
      setIsLoading(true);

      const {
        data: { data },
      } = await SiteService.getAllGamesByCategoryId(headers, siteId, categoryId, query);
      setIsLoading(false);
      if (data.game_categories.length) {
        dispatch(addCategoryGames(data.game_categories));
      }
      return { data: data.game_categories, loading: false };
    } catch (error: any) {
      if (error.response) {
        const {
          response: {
            data: { message, status },
          },
        } = error;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          logout();
        } else if (!needLogout && newHeader) {
          return await getChunkGamesByCategoryId(siteId, categoryId, query, newHeader);
        } else {
          console.log('LOG____>', message);
          setError(message);
        }
      }
    }
  };

  const deleteCategoryGame: any = async (
    siteId: string,
    categoryId: string,
    gameId: string,
    headers = initHeaders,
  ) => {
    setIsLoading(true);

    try {
      const {
        data: { status },
      } = await SiteService.deleteCategoryGame(headers, siteId, categoryId, gameId);

      if (status === 200) {
        setIsLoading(false);

        return { status };
      }
    } catch (err: any) {
      const {
        response: {
          data: { error, status, message },
        },
      } = err;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};

      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await deleteCategoryGame(siteId, categoryId, gameId, newHeader);
      } else {
        const errorMessage = joinMessage(error);
        console.log('LOG____joinedMessage_>', errorMessage);
        setError(errorMessage);
      }
    }
  };

  const getGameOfDay: any = async (siteId: string, headers = initHeaders) => {
    setIsLoading(true);

    try {
      const {
        data: { data, status },
      } = await SiteService.getGameOfDay(headers, siteId);

      if (status === 200) {
        setIsLoading(false);

        return { data, status };
      }
    } catch (err: any) {
      const {
        response: {
          data: { error, status, message },
        },
      } = err;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};

      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getGameOfDay(siteId, newHeader);
      } else {
        const errorMessage = joinMessage(error);
        console.log('LOG____joinedMessage_>', errorMessage);
        setError(errorMessage);
      }
    }
  };

  const updateGameOfDay: any = async (siteId: string, body: any, headers = initHeaders) => {
    setIsLoading(true);

    try {
      const {
        data: { data, status },
      } = await SiteService.updateGameOfDay(headers, siteId, body);
      console.log(data, 'updateGameOfDay');
      if (status === 200) {
        setIsLoading(false);
        return { status };
      }
    } catch (err: any) {
      const {
        response: {
          data: { error, status, message },
        },
      } = err;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};

      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await updateGameOfDay(siteId, body, newHeader);
      } else {
        const errorMessage = joinMessage(error);
        console.log('LOG____joinedMessage_>', errorMessage);
        setError(errorMessage);
      }
    }
  };

  const saveSiteCategoryGames: any = async (
    siteId: string,
    categoryId: string,
    body: any,
    headers = initHeaders,
  ) => {
    setIsLoading(true);

    try {
      const { status } = await SiteService.saveSiteCategoryGames(headers, siteId, categoryId, body);
      if (status === 200) {
        setIsLoading(false);
        return { status };
      }
    } catch (err: any) {
      const {
        response: {
          data: { error, status, message },
        },
      } = err;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};

      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await saveSiteCategoryGames(siteId, categoryId, body, newHeader);
      } else {
        const errorMessage = joinMessage(error);
        console.log('LOG____joinedMessage_>', errorMessage);
        setError(errorMessage);
      }
    }
  };

  return {
    getGameOfDay,
    saveSiteCategoryGames,
    getAllGamesByCategoryId,
    getChunkGamesByCategoryId,
    changeGamesDevice,
    updateGameOfDay,
    putGamesInCategory,
    deleteCategoryGame,
    isLoading,
    error,
  };
};
