import {
  Box,
  TextField,
  Button,
  Typography,
  Switch,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { FIELD_NAME } from 'types/accounts';
import { IEditAccount } from 'types/accounts';
import { SiteCheckboxes } from 'components/checkbox/sites';
import { useUser } from 'hooks/user/useUser';
import { useTranslation } from 'react-i18next';
import Result from 'components/alert/alert';
import { isValidValueForEditAddAccount } from 'utils/helpers/inputHelper';
import LoaderWithBg from 'components/loader/loader';
import styles from './editAccount.module.scss';

const fields = {
  [FIELD_NAME.LOGIN]: true,
  [FIELD_NAME.PASSWORD]: true,
  [FIELD_NAME.EMAIL]: true,
  [FIELD_NAME.PHONE]: true,
};

const style = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
};

function EditAccount({ currentId, closeModal, role }: IEditAccount) {
  const { t: accountRolesT } = useTranslation('accountRoles');
  const { t: validationT } = useTranslation('validations');
  const { user, getUser, saveUser, error, isLoading } = useUser(role);
  const [formData, setFormData] = useState({ ...user });
  const [validFields, setValidFields] = useState({ ...fields });

  const handleOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: string,
  ) => {
    const { value } = e.target;
    const data = { ...formData, [name]: value };
    setFormData(data);
  };

  const handleOnSwicth = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    const data = { ...formData, isActive: checked };
    setFormData(data);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const submitData = { ...user, ...formData };
    const res = await saveUser(currentId, submitData);
    const { status } = res ?? {};
    if (status === 200) {
      closeModal();
    }
  };

  const validate = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, name: string) => {
    const { value } = e.target;
    setValidFields((fields) => ({
      ...fields,
      [name]: isValidValueForEditAddAccount(value, name),
    }));
  };

  const handleCheckboxChange = (id: number) => {
    const checkListtemp = formData.siteIds ? [...formData.siteIds] : [];
    const old = user?.siteIds ? [...user.siteIds] : [];
    const merged = !checkListtemp.length ? [...checkListtemp, ...old] : [...checkListtemp];

    if (merged.includes(id)) {
      const index = merged.findIndex((el) => el == id);
      merged.splice(index, 1);
      const data = { ...formData, siteIds: [...merged] };
      setFormData(data);
    } else {
      merged.push(+id);
      const data = { ...formData, siteIds: [...merged] };
      setFormData(data);
    }
  };

  useEffect(() => {
    getUser(currentId);
  }, [currentId]);

  useEffect(() => {
    if (!isLoading) {
      setFormData({ ...user });
    }
  }, [isLoading]);

  return (
    <Box sx={style}>
      {!isLoading ? (
        <>
          {accountRolesT('editUser')}:{currentId}
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              id="login"
              label={accountRolesT('loginInput')}
              fullWidth
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
              value={formData?.login ?? user?.login}
              onChange={(e) => handleOnChange(e, FIELD_NAME.LOGIN)}
              onBlur={(e) => validate(e, FIELD_NAME.LOGIN)}
              error={!validFields[FIELD_NAME.LOGIN]}
              helperText={
                validFields[FIELD_NAME.LOGIN] ? '' : validationT('minLength', { length: 6 })
              }
            />
            <TextField
              fullWidth
              margin="normal"
              name="password"
              label={accountRolesT('passwordInput')}
              variant="standard"
              value={formData?.password ?? user?.password}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => handleOnChange(e, FIELD_NAME.PASSWORD)}
              onBlur={(e) => validate(e, FIELD_NAME.PASSWORD)}
              error={!validFields[FIELD_NAME.PASSWORD]}
              helperText={
                validFields[FIELD_NAME.PASSWORD] ? '' : validationT('minLength', { length: 8 })
              }
            />
            <TextField
              margin="normal"
              fullWidth
              name="phone"
              label={accountRolesT('phoneInput')}
              type="text"
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
              value={formData?.phone ?? user?.phone}
              onChange={(e) => handleOnChange(e, FIELD_NAME.PHONE)}
              onBlur={(e) => validate(e, FIELD_NAME.PHONE)}
              error={!validFields[FIELD_NAME.PHONE]}
              helperText={validFields[FIELD_NAME.PHONE] ? '' : validationT('invalidPhone')}
            />
            <TextField
              margin="normal"
              id="email"
              label={accountRolesT('emailInput')}
              fullWidth
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
              value={formData?.email ?? user?.email}
              onChange={(e) => handleOnChange(e, FIELD_NAME.EMAIL)}
              onBlur={(e) => validate(e, FIELD_NAME.EMAIL)}
              error={!validFields[FIELD_NAME.EMAIL]}
              helperText={validFields[FIELD_NAME.EMAIL] ? '' : validationT('invalidEmail')}
            />
            <Box className={styles.active}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!(formData?.isActive ?? user?.isActive)}
                      onChange={handleOnSwicth}
                    />
                  }
                  label={accountRolesT('active')}
                />
              </FormGroup>
            </Box>
            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              {accountRolesT('chooseTheSite')}
            </Typography>
            <Box className={styles.sites}>
              <SiteCheckboxes
                siteIds={formData?.siteIds ?? user?.siteIds}
                onChange={handleCheckboxChange}
              />
            </Box>
            {error && <Result style={{ mb: 2 }} message={error} type={'error'} isShow />}
            <Box className={styles.actions}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={
                  !validFields[FIELD_NAME.LOGIN] ||
                  !validFields[FIELD_NAME.PASSWORD] ||
                  !validFields[FIELD_NAME.PHONE] ||
                  !validFields[FIELD_NAME.EMAIL]
                }
              >
                {accountRolesT('save')}
              </Button>
            </Box>
          </Box>
        </>
      ) : (
        <LoaderWithBg isOpen={isLoading} />
      )}
    </Box>
  );
}

export default EditAccount;
