import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import SitesCategoryItemList, { modeType } from '../categoryItems/categoryItems';
import EditCategoryItemContent from '../editCategoryItemContent/editCategoryItemContent';
import { useSiteCategory } from 'hooks/sites/useSiteCategory';
import { useSelector } from 'react-redux';
import { ISiteCategoryItem, MODE } from 'types/sites';

interface GameComposerProps {
  siteId: string;
}

const GameComposer = ({ siteId }: GameComposerProps) => {
  const { getAllCategoryForSite } = useSiteCategory();
  const categoryData = useSelector<any>((state) => state.siteCategories) as any;
  const [currentItem, setCurrrentItem] = useState<ISiteCategoryItem | null>(null);

  const handleAction = (item: ISiteCategoryItem, mode: modeType) => {
    if (mode === MODE.EDIT_CONTENT) {
      setCurrrentItem(item);
    }
  };

  const handleClose = () => {
    setCurrrentItem(null);
  };

  useEffect(() => {
    getAllCategoryForSite(siteId);
  }, [siteId]);


  return (
    <Box>
      {!currentItem ? (
        <SitesCategoryItemList
          onAction={handleAction}
          categoryList={categoryData.categories}
          siteId={siteId}
        />
      ) : (
        <EditCategoryItemContent
          siteId={siteId}
          currentItem={currentItem}
          closeEdit={handleClose}
        />
      )}
    </Box>
  );
};

export default GameComposer;
