import GameProviders from './provider';
import { ISiteCategoryItem } from 'types/sites';

interface EditMenuItemProps {
  closeEdit: () => void;
  siteId: string;
  currentItem: ISiteCategoryItem;
}

const EditMenuItemContent = ({ closeEdit, currentItem, siteId }: EditMenuItemProps) => {
  return <GameProviders siteId={siteId} currentItem={currentItem} closeEdit={closeEdit} />;
};

export default EditMenuItemContent;
