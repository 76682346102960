import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

interface TransitionAlertsProps {
  message: string;
  isOpen: boolean;
  resetUpdatedData: () => void;
}

export default function TransitionAlerts({
  message,
  isOpen,
  resetUpdatedData,
}: TransitionAlertsProps) {
  const [open, setOpen] = useState(isOpen);

  const closeAlert = () => {
    setOpen(false);
    resetUpdatedData();
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Box sx={{ width: '100%' }}>
      <Collapse in={open}>
        <Alert
          action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={closeAlert}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {message}
        </Alert>
      </Collapse>
    </Box>
  );
}
