import { useEffect, useState } from 'react';
import AboutUs from './aboutUs/aboutUs';
import AddBonuses from './addBonuses/bonusesTable';
import Box from '@mui/material/Box';
import { ClientsLevels } from './clientsLevels/clientsLevels';
import SitePaymentMethods from './sitePaymentMethods/settings/sitePaymentMethods';
import EditSite from '../edit/editSitePage';
import GamblingSystem from './gamblingSystem/settings/gablingSystemSettings';
import GameComposer from './games/composer/gameComposer';
import PaymentSettings from './payment/settings/paymentSettings';
import SitePercentage from './percentage/settings/percentage';
import SitesSupportInfo from './sitesSupportInfo/sitesSupportInfo';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Terms from './terms/settings/terms';
import WheelSettings from 'components/pageLayouts/sites/settings/wheel/settings/settingsPage';
import { isEmpty } from 'utils/utils';
import { sitesSettings } from 'constants/common';
import { tabList } from './tabs';
import { useParams } from 'react-router-dom';
import { useSites } from 'hooks/sites/useSites';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReturnsTable from './siteReturns/returnsTable';
import PaymentProvidersSettings from './paymentProviders/paymentProviderSettings';

function SiteSettings() {
  const theme = useTheme();
  const params = useParams();
  const { getSiteById } = useSites();
  const [value, setValue] = useState('1');
  const [paramsId, setParamsId] = useState('');

  const { t: commonT } = useTranslation('common');
  const { t: siteSettingsT } = useTranslation('siteSettings');

  useEffect(() => {
    if (!isEmpty(params)) {
      const { siteId } = params;
      getSiteById(siteId ?? '');
      setParamsId(siteId ?? '');
    }
  }, [params]);

  const mockedColumns = [
    'tableColumnName',
    'tableColumnSystem',
    'tableColumnMinAmount',
    'tableColumnMaxAmount',
    'tableColumnPreview',
    'tableCloumnStatus',
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  /** TODO: need mapping Tabs with mocked Data */
  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleChange}
              sx={{
                '.MuiTabs-flexContainer': {
                  flexWrap: 'wrap',
                  width: '90vw',
                },
                '.MuiTabs-indicator': {
                  display: 'none',
                },
                'button.Mui-selected': {
                  borderBottom: `2px solid ${
                    theme.palette.mode === 'dark'
                      ? theme.palette.background.paper
                      : theme.palette.background.paper
                  }`,
                },
              }}
            >
              {tabList.map((tab: string, index: number) => {
                return <Tab key={index} label={siteSettingsT(tab)} value={`${index + 1}`} />;
              })}
            </TabList>
          </Box>
          <TabPanel value="1">
            <EditSite />
          </TabPanel>
          <TabPanel value="2">
            <GameComposer siteId={paramsId} />
          </TabPanel>
          <TabPanel value="3">
            <WheelSettings siteId={paramsId} />
          </TabPanel>
          <TabPanel value="4">
            <GamblingSystem siteId={paramsId} />
          </TabPanel>
          <TabPanel value="5">
            <PaymentSettings />
          </TabPanel>
          <TabPanel value="6">
            <SitePaymentMethods
              type={sitesSettings.DEPOSIT_TITLE}
              siteId={paramsId}
              columnNames={mockedColumns}
              btnText={commonT(sitesSettings.BTN_TEXT)}
            />
          </TabPanel>
          <TabPanel value="7">
            <SitePaymentMethods
              type={sitesSettings.WITHDRAW_TITLE}
              siteId={paramsId}
              columnNames={mockedColumns}
              btnText={commonT(sitesSettings.BTN_TEXT)}
            />
          </TabPanel>
          <TabPanel value="8">
            <SitePercentage siteId={paramsId} />
          </TabPanel>
          <TabPanel value="9">
            <Terms siteId={paramsId} />
          </TabPanel>
          <TabPanel value="10">
            <ClientsLevels siteId={paramsId} />
          </TabPanel>
          <TabPanel value="11">
            <AddBonuses siteId={paramsId} />
          </TabPanel>
          <TabPanel value="12">
            <AboutUs siteId={paramsId} />
          </TabPanel>
          <TabPanel value="13">
            <SitesSupportInfo siteId={paramsId} />
          </TabPanel>
          <TabPanel value="14">
            <ReturnsTable />
          </TabPanel>
          <TabPanel value="15">
            <PaymentProvidersSettings siteId={paramsId} />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}

export default SiteSettings;
