import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Tooltip from '@mui/material/Tooltip';
import { ReactNode, useState } from 'react';
import { primaryMenu, secondaryMenu } from './menuMock';
import { useTranslation } from 'react-i18next';
import { STORAGE_KEYS, localStorageService } from 'utils/storageService';
import { useStorage } from 'hooks/authHooks/useStorage';
import { Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './mainMenu.module.scss';
import { useDispatch } from 'react-redux';
import { setRole } from 'redux/actions/loginActions';

const drawerWidth = 200;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(3)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

interface MainMenuProps {
  children: ReactNode;
  isOpen: boolean | null;
  openedItemIndex: string | null;
}

export interface IBreadItem {
  link: string;
  menu: string;
}

interface BreadcrumbsProps {
  menu: IBreadItem | null;
  subMenu?: IBreadItem | null;
}

function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
  localStorageService.put(STORAGE_KEYS.ACTIVE_SUB_MENU, { link: '', menu: '' });
  localStorageService.put(STORAGE_KEYS.ACTIVE_MENU, { link: '', menu: '' });
}

function BasicBreadcrumbs({ menu, subMenu }: BreadcrumbsProps) {
  const { t } = useTranslation('common');
  const { t: mainMenuT } = useTranslation('mainMenu');
  const { role: mainRole } = useSelector((state: any) => state.siteRole) as { role: '' };

  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link style={{ color: 'white' }} to={mainRole && mainRole === 'main' ? '/admin' : '/'}>
          {t('home')}
        </Link>
        {menu?.menu ? <Typography color="inherit">{mainMenuT(menu?.menu)}</Typography> : null}
        {subMenu?.menu ? <Typography color="inherit">{mainMenuT(subMenu?.menu)}</Typography> : null}
      </Breadcrumbs>
    </div>
  );
}

export default function MainMenu({ children, isOpen, openedItemIndex }: MainMenuProps) {
  const theme = useTheme();
  const { t } = useTranslation('mainMenu');
  const [open, setOpen] = useState(!!isOpen);
  const dispatch = useDispatch();
  const [openedIndex, setOpenedIndex] = useState(openedItemIndex);
  const { siteSettings } = useSelector<any>((state) => state.siteSettings) as any;
  const { role: mainRole } = useSelector((state: any) => state.siteRole) as { role: '' };

  const { setOpenData, setOpenItemData } = useStorage();

  const handleDrawerOpen = () => {
    setOpenData(true);
    setOpen(true);
  };

  const handleClick = (id: string, menuType: string) => {
    if (id === openedIndex) {
      setOpenItemData('');
      setOpenedIndex('');
    } else {
      let selectedItem = [];
      selectedItem = primaryMenu.filter((el) => el.id === id);

      if (selectedItem[0]?.subMenu) {
        setOpen(true);
        setOpenData(true);
      }
      setOpenItemData(id);
      setOpenedIndex(id);
    }

    let item = [];
    if (menuType === 'primary') {
      item = primaryMenu.filter((el) => el.id === id);
    } else {
      item = secondaryMenu.filter((el) => el.id === id);
    }

    localStorageService.put(STORAGE_KEYS.ACTIVE_MENU, { menu: item[0]?.menu, link: '' });
    localStorageService.put(STORAGE_KEYS.ACTIVE_SUB_MENU, { menu: '', link: '' });
  };

  const handleDrawerClose = () => {
    setOpenData(false);
    setOpen(false);
    setOpenItemData('');
    setOpenedIndex('');
  };

  useEffect(() => {
    const value = localStorageService.read(STORAGE_KEYS.OPENED_ITEM_INDEX);

    if (!mainRole) {
      const role = localStorageService.read(STORAGE_KEYS.ROLE);
      dispatch(setRole(role));
    }
    if (value) {
      setOpenedIndex(value);
    }
  }, []);

  const handleSubMenuClick = (menu: string, link: string) => {
    localStorageService.put(STORAGE_KEYS.ACTIVE_SUB_MENU, { link, menu });
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <BasicBreadcrumbs
            menu={localStorageService.read(STORAGE_KEYS.ACTIVE_MENU)}
            subMenu={localStorageService.read(STORAGE_KEYS.ACTIVE_SUB_MENU)}
          />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {primaryMenu.map(({ menu, icon, subMenu, id, link, role }, index) => {
            return (
              <span key={index}>
                {!subMenu ? (
                  role.includes(mainRole) ? (
                    <ListItem
                      key={`main${index}`}
                      disablePadding
                      sx={{
                        display: 'block',
                        span: {
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        },
                      }}
                      title={t(menu)}
                    >
                      <Link to={link} color={'inherit'}>
                        <ListItemButton
                          sx={{
                            minHeight: 18,
                            justifyContent: open ? 'initial' : 'center',
                          }}
                          onClick={() => handleClick(id, 'primary')}
                        >
                          <Tooltip title={t(menu)} placement="right">
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 1 : 'auto',
                                justifyContent: 'flex-start',
                              }}
                            >
                              {React.createElement(icon)}
                            </ListItemIcon>
                          </Tooltip>
                          <ListItemText
                            primary={t(menu)}
                            sx={{
                              opacity: open ? 1 : 0,
                              span: {
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              },
                            }}
                          />
                        </ListItemButton>
                      </Link>
                    </ListItem>
                  ) : null
                ) : (
                  <>
                    {role.includes(mainRole) ? (
                      <ListItemButton
                        key={`sub${index}`}
                        onClick={() => handleClick(id, 'primary')}
                      >
                        <Tooltip title={t(menu)} placement="right">
                          <ListItemIcon>{React.createElement(icon)}</ListItemIcon>
                        </Tooltip>
                        <ListItemText
                          primary={t(menu)}
                          sx={{
                            span: {
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            },
                          }}
                        />
                        {openedIndex === id ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                    ) : null}
                    {subMenu?.map(({ menu, icon, link, role }, index) => {
                      if (role.includes(mainRole)) {
                        return (
                          <Collapse
                            key={`subItem${index}`}
                            in={openedIndex === id}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              <Link to={link} color={'inherit'} title={t(menu)}>
                                <ListItemButton
                                  sx={{ pl: 4 }}
                                  onClick={() => handleSubMenuClick(menu, link)}
                                >
                                  <Tooltip title={t(menu)} placement="right">
                                    <ListItemIcon>{React.createElement(icon)}</ListItemIcon>
                                  </Tooltip>
                                  <>
                                    <ListItemText
                                      primary={t(menu)}
                                      sx={{
                                        span: {
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                        },
                                      }}
                                    />
                                    {menu === 'awaitingIdentification' &&
                                    siteSettings.inProcessUsersCount !== undefined ? (
                                      <Box className={styles.identificationIcon}>
                                        {siteSettings.inProcessUsersCount}
                                      </Box>
                                    ) : null}
                                  </>
                                </ListItemButton>
                              </Link>
                            </List>
                          </Collapse>
                        );
                      }
                    })}
                  </>
                )}
              </span>
            );
          })}
        </List>
        <Divider />
        <List>
          {secondaryMenu.map(({ id, menu, icon, link, role }, index) => {
            if (role.includes(mainRole)) {
              return (
                <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                  <Link to={link} color={'inherit'}>
                    <ListItemButton
                      sx={{
                        minHeight: 18,
                        justifyContent: open ? 'initial' : 'center',
                      }}
                      onClick={() => handleClick(id, 'secondary')}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 1 : 'auto',
                          justifyContent: 'center',
                        }}
                      >
                        {React.createElement(icon)}
                      </ListItemIcon>
                      <ListItemText primary={t(menu)} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                  </Link>
                </ListItem>
              );
            }
          })}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: isOpen ? 'calc(100vw - 204px)' : '100%', maxWidth: 2560 }}
      >
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}
