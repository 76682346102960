import { Box, TextField, Typography, IconButton, Button, SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import _color from 'styles/common.module.scss';
import { useTranslation } from 'react-i18next';
import { useFinance } from 'hooks/finances/useFinance';
import Result from 'components/alert/alert';
import { Select, OptionGroup, Option } from './styles';
import styles from '../modal.module.scss';

interface ApproveClientWithdrawProps {
  amount: string;
  onClose: () => void;
}
interface IWithdraw {
  twoFactorWithdraw: string;
  paymentProviderId: string;
  methodId: string;
  amount: string;
}

const initialData = {
  twoFactorWithdraw: '',
  paymentProviderId: '',
  methodId: '',
  amount: '',
};

const style = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
};

function ApproveClientWithdraw({ amount, onClose }: ApproveClientWithdrawProps) {
  const { t } = useTranslation('common');
  const [paymentMethodList, setPaymentMethodList] = useState<any>([]);
  const [balance, setBalance] = useState<any[]>([]);
  const [formData, setFormData] = useState<IWithdraw>({ ...initialData, amount });
  const { isLoading, error, getAllFinanceProvidersWithMethods, getPaymentProviderBalance } =
    useFinance();

  const unstyledSelectGrouping = (list: any) => {
    return (
      <Select placeholder={t('paymentSystems')}>
        <Option value="">
          <em>{t('none')}</em>
        </Option>
        {list?.map((elem: any, index: number) => {
          return (
            <OptionGroup label={elem?.provider.name} key={index}>
              {elem?.provider?.methods?.map((el: any) => {
                return (
                  <Option
                    value={el.id}
                    key={el.id}
                    onClick={() => handleOnSelect(elem.provider.id, el.id)}
                  >
                    {el.name}
                  </Option>
                );
              })}
            </OptionGroup>
          );
        })}
      </Select>
    );
  };

  const getData = async () => {
    const result = await getAllFinanceProvidersWithMethods();
    if (result?.status) {
      setPaymentMethodList(result?.data ?? []);
    }
  };

  const handleOnClick = async (id: string) => {
    const result = await getPaymentProviderBalance(id);

    if (result?.status) {
      setBalance(result?.data);
    }
  };

  const handleOnSelect = (paymentProviderId: string, methodId: string) => {
    const data = { ...formData, paymentProviderId, methodId };

    setFormData(data);
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: string,
  ) => {
    const { value } = e.target;
    const data = { ...formData, [name]: value };

    setFormData(data);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onClose();
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
        {t('sendAmount', { amount })}
      </Typography>
      <Box sx={style}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            mb: 2,
          }}
        >
          {isLoading ? (
            t('loading')
          ) : (
            <>
              {paymentMethodList?.map((el: any, index: number) => {
                return (
                  <Button
                    key={index}
                    sx={{ backgroundColor: _color.blue, m: 0.5 }}
                    variant="contained"
                    onClick={() => handleOnClick(el?.provider?.id ?? '')}
                  >
                    {t('requestBalance', { name: el?.provider?.name ?? '' })}
                  </Button>
                );
              })}
            </>
          )}
        </Box>

        {balance.length ? (
          <Box className={styles.balance}>
            <IconButton
              aria-label="close"
              size="small"
              onClick={() => {
                setBalance([]);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
            {balance.map((el: any, index: number) => {
              return (
                <Typography key={index} sx={{ m: 1, color: 'white' }}>
                  {t('availableBalance', { balance: `${el.balance}  ${el.currencyName}` })}
                </Typography>
              );
            })}
          </Box>
        ) : null}

        <Box component="form" onSubmit={handleSubmit}>
          <Box className={styles.content_part}>
            <TextField
              fullWidth
              margin="normal"
              name="title"
              label={t('codeVerifyPaymentApprove')}
              variant="standard"
              onChange={(e) => handleOnChange(e, 'twoFactorWithdraw')}
            />
          </Box>
          <Box sx={{ minWidth: 400, mt: 1, mb: 1 }}>
            <Typography variant="h6">{t('selectMethod')}</Typography>
            {unstyledSelectGrouping(paymentMethodList)}
          </Box>
          {error && <Result style={{ mb: 2 }} message={error} type={'error'} isShow />}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Button
              fullWidth
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2, backgroundColor: _color.green }}
            >
              {t('approve')}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ApproveClientWithdraw;
