import { generateHeaders, joinMessage } from 'utils/utils';
import { useEffect, useState } from 'react';

import SiteService from 'services/site.service';
import { useApiErrors } from 'hooks/errors/useApiErrors';
import { useStorage } from 'hooks/authHooks/useStorage';

export const useAboutUs = (id: string) => {
  const { errorHandler } = useApiErrors();
  const [aboutUsForSite, setAboutUsForSite] = useState(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { logout, userToken } = useStorage();

  const initHeaders = generateHeaders(userToken());

  const getSiteAboutUs: any = async (siteId: string, headers = initHeaders) => {
    try {
      const {
        data: { data, status },
      } = await SiteService.getAboutUsForSite(headers, siteId);
      if (status === 200) {
        setAboutUsForSite(data);
        setIsLoading(false);
      }
      return { data, status };
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;
      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getSiteAboutUs(newHeader, siteId);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    }
  };

  const updateSiteAboutUs: any = async (body: any, siteId: string, headers = initHeaders) => {
    setIsLoading(true);
    try {
      const {
        data: { data, status },
      } = await SiteService.updateAboutUsForSite(headers, siteId, body);
      if (status === 200) {
        setIsLoading(false);
        setAboutUsForSite(data);
      }
      return { data, status };
    } catch (err: any) {
      const {
        response: {
          data: { status, message, error },
        },
      } = err;
      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await updateSiteAboutUs(newHeader, siteId, body);
      } else {
        console.log('LOG____>', message);
        setError(joinMessage(error));
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSiteAboutUs(id);
  }, []);

  return {
    isLoading,
    error,
    aboutUsForSite,
    getSiteAboutUs,
    updateSiteAboutUs,
    resetError: () => setError(''),
  };
};
