import { useStorage } from 'hooks/authHooks/useStorage';
import { useApiErrors } from 'hooks/errors/useApiErrors';
import { useEffect, useState } from 'react';
import TermsService from 'services/terms.service';
import { generateHeaders, joinMessage } from 'utils/utils';

export interface ITerms {
  id: number;
  language: string;
  code: string;
  siteId: number;
  term: any;
}

export const useTerms = (id: string) => {
  const { errorHandler } = useApiErrors();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState('');
  const [termsList, setTermsList] = useState<ITerms[] | []>([]);

  const { logout, userToken } = useStorage();
  const initHeaders = generateHeaders(userToken());

  const getAllTerms: any = async (id: string, headers = initHeaders) => {
    try {
      const {
        data: { data },
      } = await TermsService.getAllTerms(headers, id);

      setTermsList(data);
      setIsLoading(false);
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;
      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getAllTerms(id, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    }
  };

  const updateTermsData: any = async (body: any, id: string, headers = initHeaders) => {
    setIsLoading(true);
    try {
      const {
        data: { data, status },
      } = await TermsService.updateTermsData(headers, id, body);

      if (status === 200) {
        setIsLoading(false);
        setTermsList([...data]);

        return { status };
      }
    } catch (err: any) {
      const {
        response: {
          data: { status, message, error },
        },
      } = err;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await updateTermsData(body, id, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(joinMessage(error));
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllTerms(id);
  }, []);

  return {
    getAllTerms,
    updateTermsData,
    isLoading,
    error,
    termsList,
    resetError: () => setError(''),
  };
};
