import { useApiErrors } from 'hooks/errors/useApiErrors';
import { useStorage } from 'hooks/authHooks/useStorage';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { generateHeaders, joinMessage } from 'utils/utils';
import SiteService from 'services/site.service';
import { ISiteCategory } from 'types/sites';
import { setSiteCategories } from 'redux/actions/sitesActions';

export const useSiteCategory = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [categoryList, setCategoryList] = useState([]);
  const [error, setError] = useState('');

  const { logout, userToken } = useStorage();
  const { errorHandler } = useApiErrors();
  const initHeaders = generateHeaders(userToken());

  const getAllCategoryForSite: any = async (siteId: string, headers = initHeaders) => {
    try {
      const {
        data: { data },
      } = await SiteService.getAllCategoryBySiteId(headers, siteId);
      setCategoryList(data.siteCategories);
      dispatch(setSiteCategories(data.siteCategories));
    } catch (err: any) {
      if (err.response) {
        const {
          response: {
            data: { error, message, status },
          },
        } = err;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          logout();
        } else if (!needLogout && newHeader) {
          return await getAllCategoryForSite(siteId, newHeader);
        } else {
          console.log('LOG____>', message);
          setError(joinMessage(error));
        }
      }
    }
  };

  const createSiteCategory: any = async (
    body: ISiteCategory,
    id: string,
    headers = initHeaders,
  ) => {
    try {
      const { status } = await SiteService.createSiteCategory(headers, id, body);
      if (status === 201) {
        return { status };
      }
    } catch (error: any) {
      if (error.response) {
        const {
          response: {
            data: { message, status, error: errorMessage },
          },
        } = error;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          logout();
        } else if (!needLogout && newHeader) {
          return await createSiteCategory(body, id, newHeader);
        } else {
          console.log('LOG____joinedMessage_>', joinMessage(errorMessage));
          setError(joinMessage(errorMessage));
        }
      }
    }
  };

  const saveCategory: any = async (
    siteId: string,
    categoryId: string,
    body: any,
    headers = initHeaders,
  ) => {
    try {
      const {
        data: { status },
      } = await SiteService.editCategory(headers, siteId, categoryId, body);
      if (status === 200) {
        return { status };
      }
    } catch (err: any) {
      const {
        response: {
          data: { error, status, message },
        },
      } = err;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};

      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await saveCategory(siteId, categoryId, body, newHeader);
      } else {
        const errorMessage = joinMessage(error);
        console.log('LOG____joinedMessage_>', errorMessage);
        setError(errorMessage);
      }
    }
  };

  const saveSiteCategories: any = async (siteId: string, body: any, headers = initHeaders) => {
    try {
      const { status } = await SiteService.saveSiteCategories(headers, siteId, body);
      if (status === 200) {
        return { status };
      }
    } catch (err: any) {
      const {
        response: {
          data: { error, status, message },
        },
      } = err;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};

      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await saveSiteCategories(siteId, body, newHeader);
      } else {
        const errorMessage = joinMessage(error);
        console.log('LOG____joinedMessage_>', errorMessage);
        setError(errorMessage);
      }
    }
  };

  const deleteCategory: any = async (siteId: string, categoryId: string, headers = initHeaders) => {
    try {
      const {
        data: { status },
      } = await SiteService.deleteCategory(headers, siteId, categoryId);
      if (status === 200) {
        return { status };
      }
    } catch (err: any) {
      const {
        response: {
          data: { error, status, message },
        },
      } = err;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};

      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await deleteCategory(siteId, categoryId, newHeader);
      } else {
        const errorMessage = joinMessage(error);
        console.log('LOG____joinedMessage_>', errorMessage);
        setError(errorMessage);
      }
    }
  };

  return {
    categoryList,
    getAllCategoryForSite,
    createSiteCategory,
    deleteCategory,
    saveSiteCategories,
    saveCategory,
    isLoading,
    error,
  };
};
