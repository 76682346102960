import { useStorage } from 'hooks/authHooks/useStorage';
import { useApiErrors } from 'hooks/errors/useApiErrors';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addAccountData } from 'redux/actions/accountsActions';
import AccountService from 'services/account.service';
import { IAccount, IAccountTableData, roleTypes } from 'types/accounts';
import { prepareItemData } from 'utils/helpers/dataHelper/prepareDataHelper';
import { generateHeaders, joinMessage } from 'utils/utils';

export const useUser = (roleName: roleTypes | '') => {
  const role = roleName;
  const { userToken, logout } = useStorage();
  const dispatch = useDispatch();
  const { errorHandler } = useApiErrors();
  const [user, setUser] = useState<IAccount>();
  const [error, setError] = useState<string>('');
  const [isLoading, setILoading] = useState(true);
  const initHeaders = generateHeaders(userToken());

  const getUser: any = async (id: string, headers = initHeaders) => {
    try {
      const {
        data: { data },
      } = await AccountService.getAllById(headers, id);
      const prepareData = prepareItemData(data, role) as IAccount;
      setUser(prepareData);
      setILoading(false);
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;
      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getUser(id, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    }
  };

  const saveUser: any = async (id: string, body: any, headers = initHeaders) => {
    try {
      const {
        data: { data, status },
      } = await AccountService.editAccount(headers, id, body);
      if (status === 200) {
        setUser(prepareItemData(data, role) as IAccount);
        return { status };
      }
    } catch (err: any) {
      const {
        response: {
          data: { error, message, status },
        },
      } = err;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await saveUser(id, body, newHeader);
      } else {
        const errorMessage = joinMessage(error);
        console.log('LOG____joinMessage>', errorMessage);
        setError(errorMessage);
      }
    }
  };

  const createUser: any = async (body: IAccountTableData, headers = initHeaders) => {
    try {
      const data = await AccountService.create(headers, body);
      if (data.status === 201) {
        dispatch(addAccountData(data.account));
        return { url: data.QR };
      } else {
        setError('Something went wrong');
      }
    } catch (err: any) {
      const {
        response: {
          data: { error, message, status },
        },
      } = err;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await createUser(body, newHeader);
      } else {
        setError(joinMessage(error));
      }
    }
  };

  return {
    user,
    isLoading,
    createUser,
    getUser,
    saveUser,
    error,
  };
};
