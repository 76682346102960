import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  AlertColor,
} from '@mui/material';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { FIELD_NAME, IReturns, IWheelSetting } from 'types/sitesSettings';
import Result from 'components/alert/alert';
import { useTranslation } from 'react-i18next';
import { useWheels } from 'hooks/sites/useWheels';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import { ERROR, SUCCESS } from 'constants/common';
import styles from './wheel.module.scss';

const initialData = {
  amountLimit: '',
  groupId: '',
  userMinDeposit: '',
  userMonthDeposit: '',
  values: [],
};

interface MainSettingsProps {
  siteId: string;
}

function MainSettings({ siteId }: MainSettingsProps) {
  const { t } = useTranslation('common');
  const { t: siteSettingsT } = useTranslation('siteSettings');
  const [wheelData, setWheelData] = useState<IWheelSetting>({ ...initialData });
  const [success, setSuccess] = useState('');
  const [type, setType] = useState<AlertColor>(SUCCESS);
  const { getSiteWheelSetting, getAllWheelsGroups, saveWheelsSetting, error } = useWheels();
  let timeOutId: ReturnType<typeof setTimeout>;

  const wheelsGroups = useSelector<any>((state) => state.wheels) as IReturns[];

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const submitData = { ...wheelData };

    const result = await saveWheelsSetting(submitData);
    if (result?.status === 201) {
      setSuccess(t('savedSuccess'));
      setType(SUCCESS);

      timeOutId = setTimeout(() => {
        setSuccess('');
      }, 2000);
    } else {
      setType(ERROR);
    }
  };

  const handleSelectChange = (e: SelectChangeEvent) => {
    const { value } = e.target;
    const data = { ...wheelData, groupId: value };

    setWheelData(data);
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: string,
  ) => {
    const { value } = e.target;
    const data = { ...wheelData, [name]: value };

    setWheelData(data);
  };

  const handleOnChangeWheelItems = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    index: number,
  ) => {
    const { value } = e.target;
    wheelData.values.splice(index, 1, value);
    const data = { ...wheelData, values: wheelData.values };

    setWheelData(data);
  };



  const getData = async (siteId: string) => {
    const result = await getSiteWheelSetting(siteId);
    if (result) {
      setWheelData({ ...result?.data })
    }
  }

  const getAllWiheelsGroup = async () => {
    const result = await getAllWheelsGroups();
    if (result) {
      getData(siteId)
    }
  }

  useEffect(() => {
    getAllWiheelsGroup();
    return () => {
      clearTimeout(timeOutId);
    };
  }, [])

  return (
    <>
      <Box className={styles.main}>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            id="limit"
            label={siteSettingsT('wheelLimit')}
            fullWidth
            variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
            value={wheelData.amountLimit}
            onChange={(e) => handleOnChange(e, FIELD_NAME.WHEEL_AMOUNT_LIMIT)}
          />
          <Box sx={{ minWidth: 100, mt: 3, mb: 3 }}>
            <FormControl sx={{ width: '100%' }} size="small">
              <InputLabel id="demo-simple-select-label">
                {siteSettingsT('paybackSelect')}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={wheelData.groupId}
                label="otdacha"
                fullWidth
                variant="standard"
                onChange={handleSelectChange}
              >
                {wheelsGroups.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <TextField
            fullWidth
            margin="normal"
            name="minPerCustomer"
            label={siteSettingsT('minimumIncome')}
            variant="standard"
            value={wheelData.userMinDeposit}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => handleOnChange(e, FIELD_NAME.USER_MIN_DEPOSIT)}
          />
          <TextField
            margin="normal"
            fullWidth
            name="minWithDraw"
            label={siteSettingsT('minimumMonthlyDeposit')}
            variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
            value={wheelData.userMonthDeposit}
            onChange={(e) => handleOnChange(e, FIELD_NAME.USER_MONTH_DEPOSIT)}
          />
          <Box sx={{ m: 2 }} >
            <Typography align='center' component={'p'}> {siteSettingsT('wheelValue')}</Typography>
          </Box>
          <Box className={styles.wheel_items}>
            {wheelData?.values?.map((item: string, index: number) => {
              return (
                <TextField
                  sx={{ m: 2 }}
                  fullWidth
                  key={index}
                  margin="normal"
                  label={siteSettingsT('value')}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={item}
                  onChange={(e) => handleOnChangeWheelItems(e, index)}
                />
              );
            })}
          </Box>
          <Box className={styles.actions}>
            <Button fullWidth type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
              {siteSettingsT('saveSettings')}
            </Button>
          </Box>
          {(success || error) && (
            <Result style={{ mb: 2 }} message={success || error} type={type} isShow />
          )}
        </Box>
      </Box>
    </>
  );
}

export default MainSettings;
