import { Box, Button, Modal, Typography } from '@mui/material';
import { IStatisticSite } from '../statistics';
import { ReactComponent as Profit } from 'assets/icons/profit.svg';
import { ReactComponent as ActiveUser } from 'assets/icons/activeUser.svg';
import { ReactComponent as NewUser } from 'assets/icons/newUser.svg';
import BalanceIcon from '@mui/icons-material/Balance';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import PasswordIcon from '@mui/icons-material/Password';
import RedeemIcon from '@mui/icons-material/Redeem';
import { useEffect, useMemo, useState } from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { preparedColumnNames, prepareTableData } from 'utils/helpers/dataHelper/prepareDataHelper';
import { useTheme } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import FilterField from 'components/filter/columnFilter';
import { useTranslation } from 'react-i18next';
import { IconClose } from 'components/closeButton/closeButton';
import { style } from 'components/modal/modal';
import ListTable from '../listTable/listTable';
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import AttractionsIcon from '@mui/icons-material/Attractions';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { FIELD_NAME } from 'types/sites';
import { statisticsfinanceProviderColumns } from 'utils/helpers/columnsNameOrdering';
import styles from './statisticsResult.module.scss';

export interface ITimeData {
  from: Dayjs | null;
  to: Dayjs | null;
}

interface StatisticsResultProps {
  siteStatistics: IStatisticSite;
  timeData: ITimeData;
  siteId: string;
}
/** TODO: It is necessary to refactor this component using a map and split it into other components. */

function StatisticsResult({ siteStatistics, timeData, siteId }: StatisticsResultProps) {
  const theme = useTheme();
  const { t } = useTranslation('sites');
  const [openModal, setOpenModal] = useState(false);
  const [type, setType] = useState('');
  const [financeDataList, setFinanceDataList] = useState<any[]>([]);

  const [columnNamesFinanceProviders, setFinanceProvidersColumnNames] = useState<any[]>([]);
  const [columnNamesGameProviders, setGameProvidersColumnNames] = useState<any[]>([]);

  useEffect(() => {
    const preparedDataFinance = prepareTableData(
      siteStatistics.financeProviders,
      'statisticsFinance',
    );
    const columnsFinProviders = preparedColumnNames(
      siteStatistics.financeProviders,
      statisticsfinanceProviderColumns,
    );
    const columnsGameProviders = preparedColumnNames(siteStatistics.gameProviders);
    setFinanceProvidersColumnNames(columnsFinProviders);
    setGameProvidersColumnNames(columnsGameProviders);
    setFinanceDataList(preparedDataFinance);
  }, [siteStatistics]);

  const generateFinanceDinamicColumns = useMemo(() => {
    const columns = columnNamesFinanceProviders.map((el) => {
      return {
        field: el,
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => <FilterField fieldName={el} isDisabled isCamelCase />,
        valueGetter: (params: GridValueGetterParams) => {
          return typeof params.value === 'number' ? params.value.toFixed(2) : params.value;
        },
      };
    });

    return columns;
  }, [columnNamesFinanceProviders]);

  const generateGameDinamicColumns = useMemo(() => {
    const columns = columnNamesGameProviders.map((el) => {
      return {
        field: el,
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => <FilterField fieldName={el} isDisabled isCamelCase />,
        valueGetter: (params: GridValueGetterParams) => {
          return typeof params.value === 'number' ? params.value.toFixed(2) : params.value;
        },
      };
    });

    return columns;
  }, [columnNamesFinanceProviders]);

  const columnsFinProviders = generateFinanceDinamicColumns as GridColDef[];
  const columnsGameProviders = generateGameDinamicColumns as GridColDef[];

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleOnClick = (event: React.MouseEvent<HTMLElement>, type: string) => {
    setOpenModal(true);
    setType(type);
  };

  return (
    <Box className={styles.main}>
      <Typography sx={{ textAlign: 'center', fontWeight: 'bolder' }} variant="h4">
        {t('statisticsTitle')} {dayjs(timeData.from).format('DD/MM/YYYY HH:mm')} -{' '}
        {dayjs(timeData.to).format('DD/MM/YYYY HH:mm')}
      </Typography>

      <Box className={styles.statistics_main_card}>
        <Box
          className={styles.card}
          style={{
            backgroundColor: theme.palette.mode === 'dark' ? '#2e313e' : '#3e4168',
            minWidth: '600px',
          }}
        >
          <Profit />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography component={'span'} fontSize={'1.1rem'} fontWeight={'bolder'}>
              {t('profit')}
            </Typography>
            <Typography component={'span'}>{siteStatistics.profit.toFixed(2)}</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography component={'span'} fontSize={'1.1rem'} fontWeight={'bolder'}>
              {t('whiteProfit')}
            </Typography>
            <Typography component={'span'}>{siteStatistics.whiteProfit.toFixed(2)}</Typography>
          </Box>
        </Box>
      </Box>
      <Box className={styles.statistics_card_section}>
        <Box className={styles.statistics}>
          <Box
            className={styles.card}
            style={{ backgroundColor: theme.palette.mode === 'dark' ? '#2e313e' : '#3e4168' }}
          >
            <ActiveUser />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography component={'span'} fontSize={'1.1rem'} fontWeight={'bolder'}>
                {t('activeUsers')}: {siteStatistics.activeUsers}
              </Typography>
            </Box>
          </Box>

          <Box
            className={styles.card}
            style={{ backgroundColor: theme.palette.mode === 'dark' ? '#2e313e' : '#3e4168' }}
          >
            <NewUser />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography component={'span'} fontSize={'1.1rem'} fontWeight={'bolder'}>
                {t('newUsers')}: {siteStatistics.newUsers}
              </Typography>
              <Button
                variant="contained"
                color="success"
                // onClick={(e) => handleOnClick(e, FIELD_NAME.LIST_OF_NEW_USERS)}
              >
                {t('listOfUsers')}
              </Button>
            </Box>
          </Box>
          <Box
            className={styles.card}
            style={{ backgroundColor: theme.palette.mode === 'dark' ? '#2e313e' : '#3e4168' }}
          >
            <BalanceIcon />
            <Box sx={{ display: 'flex', flexDirection: 'column', flexBasis: '50%' }}>
              <Typography
                component={'span'}
                sx={{ fontSize: '1.1rem', fontWeight: 'bolder', textTransform: 'uppercase' }}
              >
                {t('onBalance')}: {siteStatistics.balance}
              </Typography>
              <Typography component={'span'} fontSize={'1.1rem'} fontWeight={'bolder'}>
                {t('wallet')}: {'*'}
              </Typography>
              <Typography component={'span'} fontSize={'1.1rem'} fontWeight={'bolder'}>
                {t('game')}: {'*'}
              </Typography>
              <Typography component={'span'} fontSize={'1.1rem'} fontWeight={'bolder'}>
                {t('bonusBalance')}: {'*'}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={styles.statistics}>
          <Box
            className={styles.card}
            style={{ backgroundColor: theme.palette.mode === 'dark' ? '#2e313e' : '#3e4168' }}
          >
            <VideogameAssetIcon />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography component={'span'} fontSize={'1.1rem'} fontWeight={'bolder'}>
                {t('playedGames')}: {siteStatistics.playedGames}
              </Typography>
            </Box>
          </Box>
          <Box
            className={styles.card}
            style={{ backgroundColor: theme.palette.mode === 'dark' ? '#2e313e' : '#3e4168' }}
          >
            <AddIcon />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography component={'span'} fontSize={'1.1rem'} fontWeight={'bolder'}>
                {t('payInAmount')}: {siteStatistics.payInAmount}
              </Typography>
              <Button
                variant="contained"
                color="warning"
                onClick={(e) => handleOnClick(e, FIELD_NAME.DEPOSIT)}
              >
                {t('listOfUsers')}
              </Button>
            </Box>
          </Box>

          <Box
            className={styles.card}
            style={{ backgroundColor: theme.palette.mode === 'dark' ? '#2e313e' : '#3e4168' }}
          >
            <RemoveIcon />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                component={'span'}
                sx={{ fontSize: '1.1rem', fontWeight: 'bolder', textTransform: 'uppercase' }}
              >
                {t('payOutAmount')}: {siteStatistics.payOutAmount}
              </Typography>
              <Typography component={'span'} fontSize={'1.1rem'} fontWeight={'bolder'}>
                {t('completed')}: {'*'}
              </Typography>
              <Typography component={'span'} fontSize={'1.1rem'} fontWeight={'bolder'}>
                {t('pending')}: {'*'}
              </Typography>
              <Button
                variant="contained"
                color="error"
                onClick={(e) => handleOnClick(e, FIELD_NAME.WITHDRAW)}
              >
                {t('listOfUsers')}
              </Button>
            </Box>
          </Box>
        </Box>
        <Box className={styles.statistics}>
          <Box
            className={styles.card}
            style={{ backgroundColor: theme.palette.mode === 'dark' ? '#2e313e' : '#3e4168' }}
          >
            <FindReplaceIcon />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                component={'span'}
                fontSize={'1.1rem'}
                fontWeight={'bolder'}
                sx={{ mb: 2 }}
              >
                {t('siteFinanceFeeAmount')}: {siteStatistics?.siteFinanceFeeAmount.toFixed(2) ?? 0}
              </Typography>
              <Typography component={'span'} fontSize={'1.1rem'} fontWeight={'bolder'}>
                {t('siteGamesFeeAmount')}: {siteStatistics?.siteGamesFeeAmount.toFixed(2) ?? 0}
              </Typography>
            </Box>
          </Box>
          <Box
            className={styles.card}
            style={{ backgroundColor: theme.palette.mode === 'dark' ? '#2e313e' : '#3e4168' }}
          >
            <RedeemIcon />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography component={'span'} fontSize={'1.1rem'} fontWeight={'bolder'}>
                {t('bonuses')}: {siteStatistics?.bonusesAmount ?? 0} ({t('withoutWheel')})
              </Typography>
              <Button
                variant="contained"
                color="info"
                onClick={(e) => handleOnClick(e, FIELD_NAME.LIST_OF_USERS_BONUSES)}
              >
                {t('listOfUsers')}
              </Button>
            </Box>
          </Box>
          <Box
            className={styles.card}
            style={{
              backgroundColor: theme.palette.mode === 'dark' ? '#2e313e' : '#3e4168',
            }}
          >
            <PasswordIcon />
            <Box sx={{ display: 'flex', flexDirection: 'column', flexBasis: '100%' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', mb: 0.5 }}>
                <Typography
                  component={'span'}
                  sx={{ fontSize: '1.1rem', fontWeight: 'bolder', textTransform: 'uppercase' }}
                >
                  {t('promocodes')}
                </Typography>
                <Box sx={{ display: 'flex' }}>
                  <Typography component={'span'} sx={{ fontSize: '0.9rem', fontWeight: 'bolder' }}>
                    {t('count')}: {siteStatistics.promocodes}
                  </Typography>
                  <Typography
                    component={'span'}
                    sx={{ fontSize: '0.9rem', fontWeight: 'bolder', ml: 1 }}
                  >
                    {t('forAmount')}: {siteStatistics?.forAmount ?? '*'}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', mb: 0.5 }}>
                <Typography
                  component={'span'}
                  sx={{ fontSize: '1.1rem', fontWeight: 'bolder', textTransform: 'uppercase' }}
                >
                  {t('activated')}
                </Typography>
                <Box sx={{ display: 'flex' }}>
                  <Typography component={'span'} sx={{ fontSize: '0.9rem', fontWeight: 'bolder' }}>
                    {t('count')}: {'*'}
                  </Typography>
                  <Typography
                    component={'span'}
                    sx={{ fontSize: '0.9rem', fontWeight: 'bolder', ml: 1 }}
                  >
                    {t('forAmount')}: {'*'}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  component={'span'}
                  sx={{ fontSize: '1.1rem', fontWeight: 'bolder', textTransform: 'uppercase' }}
                >
                  {t('notActivated')}
                </Typography>
                <Box sx={{ display: 'flex' }}>
                  <Typography component={'span'} sx={{ fontSize: '0.9rem', fontWeight: 'bolder' }}>
                    {t('count')}: {'*'}
                  </Typography>
                  <Typography
                    component={'span'}
                    sx={{ fontSize: '0.9rem', fontWeight: 'bolder', ml: 1 }}
                  >
                    {t('forAmount')}: {'*'}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={styles.statistics}>
          <Box
            className={styles.card}
            style={{ backgroundColor: theme.palette.mode === 'dark' ? '#2e313e' : '#3e4168' }}
          >
            <CurrencyExchangeIcon />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography component={'span'} fontSize={'1.1rem'} fontWeight={'bolder'}>
                {t('cashback')}: {siteStatistics?.cashbackSum ?? '*'}
              </Typography>
              <Button
                variant="contained"
                color="success"
                onClick={(e) => handleOnClick(e, FIELD_NAME.LIST_OF_USERS_CASHBACKS)}
              >
                {t('listOfUsers')}({t('cashback')})
              </Button>
            </Box>
          </Box>
          <Box
            className={styles.card}
            style={{ backgroundColor: theme.palette.mode === 'dark' ? '#2e313e' : '#3e4168' }}
          >
            <AttractionsIcon />
            <Box sx={{ display: 'flex', flexDirection: 'column', flexBasis: '100%' }}>
              <Typography
                component={'span'}
                sx={{ fontSize: '1.1rem', fontWeight: 'bolder', textTransform: 'uppercase' }}
              >
                {t('wheelBonus')}: {'----'}
              </Typography>
              <Typography component={'span'} fontSize={'1.1rem'} fontWeight={'bolder'}>
                {t('activated')}: {'*'}
              </Typography>
              <Typography component={'span'} fontSize={'1.1rem'} fontWeight={'bolder'}>
                {t('notActivated')}: {'*'}
              </Typography>
              <Button
                variant="contained"
                color="error"
                // onClick={(e) => handleOnClick(e, FIELD_NAME.LIST_OF_USERS_WHEEL)}
              >
                {t('listOfUsers')}
              </Button>
            </Box>
          </Box>

          <Box
            className={styles.card}
            style={{
              backgroundColor: theme.palette.mode === 'dark' ? '#2e313e' : '#3e4168',
            }}
          >
            <PasswordIcon />
            <Box sx={{ display: 'flex', flexDirection: 'column', flexBasis: '100%' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', mb: 0.5 }}>
                <Typography
                  component={'span'}
                  sx={{ fontSize: '1.1rem', fontWeight: 'bolder', textTransform: 'uppercase' }}
                >
                  {t('multi')} {'-'} {t('promocodes')}
                </Typography>
                <Box sx={{ display: 'flex' }}>
                  <Typography component={'span'} sx={{ fontSize: '0.9rem', fontWeight: 'bolder' }}>
                    {t('count')}: {siteStatistics.promocodes}
                  </Typography>
                  <Typography
                    component={'span'}
                    sx={{ fontSize: '0.9rem', fontWeight: 'bolder', ml: 1 }}
                  >
                    {t('forAmount')}: {siteStatistics?.forAmount ?? '*'}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', mb: 0.5 }}>
                <Typography
                  component={'span'}
                  sx={{ fontSize: '1.1rem', fontWeight: 'bolder', textTransform: 'uppercase' }}
                >
                  {t('activated')}
                </Typography>
                <Box sx={{ display: 'flex' }}>
                  <Typography component={'span'} sx={{ fontSize: '0.9rem', fontWeight: 'bolder' }}>
                    {t('count')}: {'*'}
                  </Typography>
                  <Typography
                    component={'span'}
                    sx={{ fontSize: '0.9rem', fontWeight: 'bolder', ml: 1 }}
                  >
                    {t('forAmount')}: {'*'}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  component={'span'}
                  sx={{ fontSize: '1.1rem', fontWeight: 'bolder', textTransform: 'uppercase' }}
                >
                  {t('notActivated')}
                </Typography>
                <Box sx={{ display: 'flex' }}>
                  <Typography component={'span'} sx={{ fontSize: '0.9rem', fontWeight: 'bolder' }}>
                    {t('count')}: {'*'}
                  </Typography>
                  <Typography
                    component={'span'}
                    sx={{ fontSize: '0.9rem', fontWeight: 'bolder', ml: 1 }}
                  >
                    {t('forAmount')}: {'*'}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={styles.content_table}>
        <Box sx={{ width: '100%' }}>
          <Typography component={'span'} sx={{ fontSize: '1rem', fontWeight: 'bolder' }}>
            {t('paymentSystems')}
          </Typography>
          <DataGrid
            autoHeight
            rows={financeDataList}
            columns={siteStatistics.financeProviders.length ? columnsFinProviders : []}
            hideFooterPagination={true}
            getRowId={(row) => row.name}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  balance: false,
                },
              },
            }}
            sx={{
              mt: 3,
              '.MuiDataGrid-iconButtonContainer': {
                visibility: 'visible',
              },
              '.MuiDataGrid-sortIcon': {
                opacity: 'inherit !important',
              },
              '.MuiDataGrid-cell': {
                '&:last-child': {
                  justifyContent: 'center !important',
                },
              },
              '.MuiDataGrid-footerContainer': {
                display: 'none',
              },
            }}
          />
        </Box>
        <Box sx={{ width: '100%' }}>
          <Typography component={'span'} sx={{ fontSize: '1rem', fontWeight: 'bolder' }}>
            {t('gamesSystems')}
          </Typography>
          <DataGrid
            autoHeight
            rows={siteStatistics.gameProviders}
            columns={siteStatistics.gameProviders.length ? columnsGameProviders : []}
            hideFooterPagination={true}
            getRowId={(row) => row.name}
            sx={{
              mt: 3,
              '.MuiDataGrid-iconButtonContainer': {
                visibility: 'visible',
              },
              '.MuiDataGrid-sortIcon': {
                opacity: 'inherit !important',
              },
              '.MuiDataGrid-cell': {
                '&:last-child': {
                  justifyContent: 'center !important',
                },
              },
              '.MuiDataGrid-footerContainer': {
                display: 'none',
              },
            }}
          />
        </Box>
      </Box>
      {openModal && (
        <Modal open={openModal} onClose={handleClose} sx={{ minWidth: 400 }}>
          <Box
            sx={{
              ...style,
              bgcolor: `${theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff'}`,
            }}
          >
            <ListTable siteId={siteId} timeData={timeData} type={type} />
            <IconClose onClose={handleClose} />
          </Box>
        </Modal>
      )}
    </Box>
  );
}

export default StatisticsResult;
