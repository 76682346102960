import { actionTypes } from 'redux/constants/actionTypes';

const initial = [];

const updateState = (state, payload) => {
  const temp = state.filter((el) => el.id !== payload.id);
  return temp.length ? [...temp] : [...state];
};

export const promotionPromoCodeReducer = (state = initial, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_PROMOTION_PROMO:
      return [...payload];
    case actionTypes.ADD_CREATED_PROMOTION_PROMO:
      return [...payload, ...state];
    case actionTypes.UPDATE_PROMO_CODES:
      return updateState(state, payload);
    default:
      return state;
  }
};
