import { actionTypes } from 'redux/constants/actionTypes';

export const setTransactionStatuses = (statuses) => {
  return {
    type: actionTypes.SET_TRANSACTIONS,
    payload: statuses,
  };
};

export const setTransactionActionsStatuses = (actions) => {
  return {
    type: actionTypes.SET_TRANSACTIONS_ACTIONS,
    payload: actions,
  };
};
