import isEmail from 'validator/lib/isEmail';
import isUrl from 'validator/lib/isURL';

export const isValidUrl = (value) => value && isUrl(value);

export const isValidUrlWithPrefix = (value) => value && isUrl(value) && value.match("^(https?|ftp)://[^\\s/$.?#].*$");

export const isGreaterThan = (num) => (value) => value && value > num;

export const isValidEmail = (value) => value && isEmail(value);

export const isNumber = (value) => !(value && Number.isNaN(Number(value)));

export const minLength = (min) => (value) => value && value.length > min;

export const isValidPhonePattern =(value) => {
  const phone = /^(\+374\d{8}|\+7\d{10}|\+380\d{9})$/;
  return value.match(phone);
};

export const isAlphanumericWithSpace = (inputtxt) => {
  const letterNumber = /^[0-9a-zA-Z _]+$/;
  return inputtxt.match(letterNumber);
};

export const isAlphanumeric = (inputtxt) => {
  const letterNumber = /^[0-9a-zA-Z]+$/;
  return inputtxt.match(letterNumber);
};
