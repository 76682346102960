export const auth = {
  AUTHORIZATION_HEADER_REQUIRED: 'authorization_header_required',
  AUTHORIZATION_TOKEN_EXPIRED: 'authorization_token_expired',
  AUTHORIZATION_INTERNAL_ERROR: 'authorization_internal_error',
  REFRESH_TOKEN_SUCCESS: 'refresh_token_success',
  REFRESH_TOKEN_BAD_REQUEST: 'refresh_token_bad_request',
  REFRESH_TOKEN_EXPIRED: 'refresh_token_expired',
  REFRESH_TOKEN_INTERNAL_ERROR: 'refresh_token_internal_error',
  REFRESH_TOKEN_WRONG: 'refresh_token_wrong',
  AUTHORIZATION_WRONG_TOKEN:"authorization_wrong_token",
  REQUEST_REFRESH_EXPIRED: 'Request failed with status code 403',
  REQUEST_REFRESH_WRONG: 'Request failed with status code 401'
};

export const user = {
  USER_NOT_FOUND: 'get_user_by_id_not_found',
};

type tpLoginOptions = {
  [key: string]: string
} 
export const login : tpLoginOptions = {
  INCORRECT_LENGTH: 'Incorrect length',
  login_account_not_found: 'Login account not found',
  login_account_wrong_password : 'Wrong password',
  login_account_wrong_two_factor_token: 'Wrong verification code'
};