import { GridValueGetterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import FilterField from 'components/filter/columnFilter';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { IFilterData } from 'types/accounts';
import { columnsName, ROW_PER_PAGE } from 'constants/common';
import moment from 'moment';
import { isEmpty, makeQueryUrl } from 'utils/utils';
import { Box, Button, TablePagination } from '@mui/material';
import DropDownSelect from 'components/select/select';
import { useDispatch } from 'react-redux';
import { setResetAll } from 'redux/actions/commonActions';
import { useTranslation } from 'react-i18next';
import { useClientGameSettings } from 'hooks/clients/useClientGameSettings';
import { rendermetaCell } from 'components/pageLayouts/client/gameLogs/gameLogs';
import styles from '../table.module.scss';

interface ClientGameSettingsHistoryTableProps {
  clientId: string;
  needUpdate: boolean;
}

export default function ClientGameSettingsHistoryTable({
  clientId,
  needUpdate,
}: ClientGameSettingsHistoryTableProps) {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const {
    getClientGameSettingsList,
    settingsList,
    totalItems,
    columns: columnNames,
    pageLists,
  } = useClientGameSettings();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROW_PER_PAGE);
  const [resetGoToPage, setResetGoToPage] = useState(false);
  const [filterData, setFilterData] = useState<IFilterData | object>({});
  const [sortData, setSortData] = useState<IFilterData | object>({});

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (newPage >= +pageLists[pageLists.length - 1].val) {
      setPage(+pageLists[pageLists.length - 1].val);
    } else {
      setPage(newPage);
    }
    setResetGoToPage((prev) => !prev);
  };

  const handleFilter = (name: string, value: string) => {
    setFilterData((filterby) => ({ ...filterby, [name]: value }));
  };

  const handleSortBy = (sortBy: string, sortDir: string) => {
    setSortData({ [sortBy]: sortDir });
  };

  const handleResetAll = () => {
    setPage(0);
    dispatch(setResetAll(true));
    setFilterData({});
    setSortData({});
  };

  const handleSelectPage = (page: string) => {
    setPage(+page - 1);
  };

  const generateDinamicColumns = useMemo(() => {
    const columns = columnNames.map((el) => {
      if (el === columnsName.CREATED_AT) {
        return {
          field: el,
          width: Math.ceil(window.innerWidth / (columnNames.length + 1)),
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
            />
          ),
          valueGetter: (params: GridValueGetterParams) =>
            `${moment(params.row.createdAt).format('DD/MM/Y HH:mm:ss')}`,
        };
      } else if (el === columnsName.META_DATA) {
        return {
          field: el,
          width: 'auto',
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isCamelCase
              isDisabled
              style={{ minWidth: '550px' }}
            />
          ),
          renderCell: (params: GridValueGetterParams) => rendermetaCell(params.row.metadata),
        };
      }
      return {
        field: el,
        width: el === columnsName.ID ? 100 : 200,
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => (
          <FilterField
            fieldName={el}
            onBlur={(value) => handleFilter(el, value)}
            onSort={handleSortBy}
            sortData={sortData}
            isCamelCase
          />
        ),
      };
    });

    return [...columns];
  }, [columnNames, sortData, settingsList]);

  const columns = generateDinamicColumns as GridColDef[];

  useEffect(() => {
    const query = makeQueryUrl(filterData);
    const sortBy = Object.entries(sortData)[0];

    let paramsQuery = isEmpty(sortData)
      ? query
      : `${query}&sortBy=${sortBy[0]}&sortDir=${sortBy[1]}`;

    paramsQuery += `&page=${page + 1}&pageSize=${rowsPerPage}`;

    getClientGameSettingsList(clientId, paramsQuery);
  }, [filterData, sortData, page, rowsPerPage]);

  useEffect(() => {
    if (needUpdate) handleResetAll();
  }, [needUpdate]);

  return (
    <Box style={{ width: '100%' }}>
      <Box className={styles.filter_box}>
        <div className={styles.filter_btn}>
          <Box sx={{ display: 'flex', mt: 2, mb: 2 }}>
            <DropDownSelect
              totalPages={pageLists}
              onSelectPage={handleSelectPage}
              reset={resetGoToPage}
            />
            {Object.values(filterData).join() || Object.values(sortData).join() ? (
              <Button className={styles.reset_all_btn} variant="contained" onClick={handleResetAll}>
                {t('ressetAll')}
              </Button>
            ) : null}
          </Box>
        </div>
      </Box>

      <TablePagination
        component="div"
        count={totalItems}
        page={page}
        labelRowsPerPage={t('rowsPerPage')}
        labelDisplayedRows={({ from, to, count }) =>
          `${from >= to ? to : from}-${to} ${t('of')} ${count}`
        }
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <DataGrid
        autoHeight
        getRowHeight={() => 'auto'}
        rows={settingsList}
        columns={columns}
        hideFooterPagination={true}
        sx={{
          '.MuiDataGrid-iconButtonContainer': {
            visibility: 'visible',
          },
          '.MuiDataGrid-sortIcon': {
            opacity: 'inherit !important',
          },
          '.MuiDataGrid-cell': {
            '&:last-child': {
              justifyContent: 'center !important',
            },
          },
          '.MuiDataGrid-footerContainer': {
            display: 'none',
          },
        }}
      />
    </Box>
  );
}
