import { GridValueGetterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import FilterField from 'components/filter/columnFilter';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { IFilterData } from 'types/accounts';
import { columnsName, common, ROW_PER_PAGE } from 'constants/common';
import { dateFormat, isEmpty, makeQueryUrl } from 'utils/utils';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Link,
  TablePagination,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { setResetAll } from 'redux/actions/commonActions';
import SitesList from 'components/filter/siteLists';
import { useTranslation } from 'react-i18next';
import { useCashBacks } from 'hooks/clients/useCashBacks';
import moment from 'moment';
import styles from '../table.module.scss';

export default function DepositsTable() {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const {
    isLoading,
    columns: columnNames,
    pageLists,
    cashBackList,
    totalItems,
    getCashbacks,
  } = useCashBacks();

  const [page, setPage] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(ROW_PER_PAGE);
  const [filterData, setFilterData] = useState<IFilterData>({} as IFilterData);
  const [sortData, setSortData] = useState<IFilterData>({ isUsed: 'ASC' } as IFilterData);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (newPage >= +pageLists[pageLists.length - 1].val) {
      setPage(+pageLists[pageLists.length - 1].val);
    } else {
      setPage(newPage);
    }
  };

  const handleOnCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setFilterData({ ...filterData, isAppliableCashback: checked });
    setIsChecked(checked);
  };

  const handleFilter = (name: string, value: string) => {
    if (value !== '') {
      setFilterData((filterby) => ({ ...filterby, [name]: value }));
    } else {
      if (filterData?.[name]) {
        delete filterData[name];
        setFilterData({ ...filterData });
      }
    }
  };

  const handleSortBy = (sortBy: string, sortDir: string) => {
    setSortData({ [sortBy]: sortDir });
  };

  const handleResetAll = () => {
    dispatch(setResetAll(true));
    setFilterData({ siteId: filterData.siteId } as IFilterData);
    setSortData({});
  };

  const generateDynamicColumns = useMemo(() => {
    const columns = columnNames.map((el) => {
      if (el === columnsName.DAY) {
        return {
          field: el,
          minWidth: el.length * 6 + 120,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isCamelCase
            />
          ),
          valueGetter: (params: GridValueGetterParams) => moment(params.row.day).format('DD/MM/Y'),
        };
      } else if (el === columnsName.USE_DATE) {
        return {
          field: el,
          minWidth: el.length * 6 + 120,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isCamelCase
            />
          ),
          valueGetter: (params: GridValueGetterParams) => dateFormat(params.row.useDate),
        };
      } else if (el === columnsName.IS_USED) {
        return {
          field: el,
          width: 150,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => <FilterField fieldName={el} isDisabled />,
          valueGetter: (params: GridValueGetterParams) =>
            `${params.row.isUsed ? t(common.YES) : t(common.NO)}`,
        };
      } else if (el == columnsName.USER) {
        return {
          field: el,
          minWidth: el.length * 6 + 140,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isCamelCase
            />
          ),
          renderCell: (params: GridValueGetterParams) => (
            <Link
              color={'text.primary'}
              component="a"
              target="blank"
              onClick={() => {
                window.open(`/clients/details/${params.row.user.id}`);
              }}
            >
              {params.row.user.login}
            </Link>
          ),
        };
      }
      return {
        field: el,
        minWidth: el.length * 6 + 100,
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => (
          <FilterField
            fieldName={el}
            onBlur={(value) => handleFilter(el, value)}
            onSort={handleSortBy}
            sortData={sortData}
            isCamelCase
            isDisabled={el === columnsName.BALANCE_TYPE}
          />
        ),
      };
    });

    return [...columns];
  }, [columnNames, sortData]);

  const columns = generateDynamicColumns as GridColDef[];

  useEffect(() => {
    const query = makeQueryUrl(filterData);
    const sortBy = Object.entries(sortData)[0];
    let paramsQuery = isEmpty(sortData)
      ? query && query !== '&siteId=1'
        ? query
        : ''
      : `${query}&sortBy=${sortBy[0]}&sortDir=${sortBy[1]}`;

    paramsQuery += `&page=${page + 1}&pageSize=${rowsPerPage}`;
    if (filterData.siteId) getCashbacks(filterData.siteId, paramsQuery);
  }, [filterData, sortData, page, rowsPerPage]);

  return (
    <div style={{ width: '100%' }}>
      <Box className={styles.filter_box}>
        <div className={styles.filter_btn}>
          <Box sx={{ display: 'flex', mt: 2, mb: 2 }}>
            {Object.values(filterData).join() || Object.values(sortData).join() ? (
              <Button className={styles.reset_all_btn} variant="contained" onClick={handleResetAll}>
                {t('resetAll')}
              </Button>
            ) : null}
          </Box>
        </div>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Box>
          <SitesList onSelectSite={handleFilter} margin="0 24px 24px 0" />
        </Box>
        <Box>
          <FormGroup>
            <FormControlLabel
              sx={{ mr: 3 }}
              control={
                <Checkbox
                  checked={isChecked}
                  onChange={handleOnCheck}
                  sx={{
                    '& .MuiSvgIcon-root': { fontSize: 14, color: 'text.primary' },
                  }}
                />
              }
              label={t('exceptToday')}
              labelPlacement="end"
            />
          </FormGroup>
        </Box>
      </Box>
      {!isLoading && filterData?.siteId ? (
        <>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            labelRowsPerPage={t('rowsPerPage')}
            labelDisplayedRows={({ from, to, count }) =>
              `${from >= to ? to : from}-${to} ${t('of')} ${count}`
            }
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <DataGrid
            autoHeight
            rows={filterData?.siteId ? cashBackList : []}
            columns={columns}
            hideFooterPagination={true}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  userId: false,
                  newBalance: false,
                  oldBalance: false,
                },
              },
            }}
            sx={{
              '.MuiDataGrid-iconButtonContainer': {
                visibility: 'visible',
              },
              '.MuiDataGrid-sortIcon': {
                opacity: 'inherit !important',
              },
              '.MuiDataGrid-cell': {
                '&:last-child': {
                  justifyContent: 'center !important',
                },
              },
              '.MuiDataGrid-footerContainer': {
                display: 'none',
              },
            }}
          />
        </>
      ) : (
        <Box> {t('loading')}</Box>
      )}
    </div>
  );
}
