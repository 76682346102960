import { AlertColor, Box, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';
import Result from 'components/alert/alert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

interface AboutUsForLanguageProps {
  languageCode: string;
  saveAboutUs: () => void;
  updateAboutUs: (languageCode: string, aboutUs: any) => void;
  handleOnClose: () => void;
  result: string;
  type: AlertColor;
  selectedItem: any;
}

function AboutUsForLanguage({
  languageCode,
  updateAboutUs,
  saveAboutUs,
  handleOnClose,
  result,
  type,
  selectedItem,
}: AboutUsForLanguageProps) {
  const { t: siteSettingsT } = useTranslation('siteSettings');
  const [contentState, setContentState] = useState(selectedItem ? selectedItem.about : undefined);

  const onContentStateChange = (contentState: any) => {
    setContentState(contentState);
    updateAboutUs(languageCode, contentState);
  };

  const uploadImagOnEditor = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const dataURL = reader.result;
        resolve({ data: { link: dataURL } });
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    if (selectedItem) {
      setContentState(selectedItem.about);
    }
  }, [selectedItem]);

  return (
    <Box>
      <Editor
        editorStyle={{
          borderBottom: '2px solid gray',
          borderRight: '2px solid gray',
          borderLeft: '2px solid gray',
          padding: '5px',
          backgroundColor: 'white',
          color: 'black',
          minHeight: 300,
          maxHeight: 600,
        }}
        toolbar={{
          image: {
            alignmentEnabled: true,
            previewImage: true,
            uploadCallback: uploadImagOnEditor,
            alt: { present: true, mandatory: false },
          },
        }}
        onContentStateChange={onContentStateChange}
        contentState={selectedItem ? selectedItem.about : contentState}
      />
      {result && (
        <Result
          style={{ mt: 2 }}
          message={result}
          type={type}
          isShow
          hasAction
          onClose={handleOnClose}
        />
      )}
      <Button
        sx={{ mt: 3 }}
        style={{ float: 'right', width: 150 }}
        variant="contained"
        onClick={saveAboutUs}
      >
        {siteSettingsT('save')}
      </Button>
    </Box>
  );
}

export default AboutUsForLanguage;
