import InsightsIcon from '@mui/icons-material/Insights';
import LanguageIcon from '@mui/icons-material/Language';
import PeopleIcon from '@mui/icons-material/People';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import TelegramIcon from '@mui/icons-material/Telegram';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

/** Sub Menu icons for Clients */
import ChecklistIcon from '@mui/icons-material/Checklist';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction';
import FingerprintIcon from '@mui/icons-material/Fingerprint';

export const SubClientsList = ChecklistIcon;
export const SubClientsSearch = PersonSearchIcon;
export const SubClientsOnline = OnlinePredictionIcon;
export const SubClientsFinger = FingerprintIcon;

/** Sub Menu icons for Finance */
import SavingsIcon from '@mui/icons-material/Savings';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import TerminalIcon from '@mui/icons-material/Terminal';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import RedeemIcon from '@mui/icons-material/Redeem';
import AddCardIcon from '@mui/icons-material/AddCard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

export const SubFinanceSavings = SavingsIcon;
export const SubFinanceMonetization = MonetizationOnIcon;
export const SubFinanceTerminal = TerminalIcon;
export const SubFinanceSwitch = SwitchAccountIcon;
export const SubFinanceRedeem = RedeemIcon;
export const SubFinanceAdd = AddCardIcon;
export const SubFinanceAccount = AccountBalanceIcon;
export const CashBacks = SettingsBackupRestoreIcon;

/** Sub Menu icons for Managment */
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';

export const SubManagmentSupport = SupportAgentIcon;
export const SubManagmentRecord = RecordVoiceOverIcon;
export const SubManagmentHelp = HelpCenterIcon;
export const SubManagmentManage = ManageHistoryIcon;

/** Sub Menu icons for Game */
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import SettingsInputSvideoIcon from '@mui/icons-material/SettingsInputSvideo';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import SportsVolleyballIcon from '@mui/icons-material/SportsVolleyball';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';

export const SubGameVideoSettings = VideoSettingsIcon;
export const SubGameettings = SettingsSuggestIcon;
export const SubGameSports = SportsVolleyballIcon;
export const SubGameSignal = SignalCellularAltIcon;
export const SubGameSettingGame = SettingsInputSvideoIcon;
export const SubGameSettingProvider = SettingsApplicationsIcon;

/** Sub Menu icons for Telegram */
import SmsIcon from '@mui/icons-material/Sms';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

export const SubTelegramSms = SmsIcon;
export const SubTelegramCurrency = CurrencyExchangeIcon;

export const Statistics = InsightsIcon;
export const Sites = LanguageIcon;
export const Clients = PeopleIcon;
export const Finance = PriceChangeIcon;
export const Game = SportsEsportsIcon;
export const Yammer = FeedbackIcon;
export const Managment = ManageAccountsIcon;
export const Massages = ForwardToInboxIcon;
export const Telegram = TelegramIcon;

/** Sub Menu icons for Promocode */
import ConnectWithoutContactOutlinedIcon from '@mui/icons-material/ConnectWithoutContactOutlined';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';

export const PromoCode = Diversity3OutlinedIcon;

export const SubPromoCodeMultiUser = ConnectWithoutContactOutlinedIcon;
export const SubPromoCodePromotion = ContactMailOutlinedIcon;
