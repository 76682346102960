import { TabContext, TabList, TabPanel } from '@mui/lab';
import { AlertColor, Box, Tab } from '@mui/material';
import { useEffect, useState } from 'react';
import TermsLanguage from './termsLanguage';
import { useSelector } from 'react-redux';
import { IEditSite } from 'types/sites';
import { ITerms, useTerms } from 'hooks/terms/useTerms';
import { useTranslation } from 'react-i18next';
import LoaderWithBg from 'components/loader/loader';
import { ERROR, SUCCESS } from 'constants/common';

interface TermsProps {
  siteId: string;
}

function Terms({ siteId }: TermsProps) {
  const { t } = useTranslation('common');
  const { languages } = useSelector<any>((state) => state.currentSite) as IEditSite;
  const { termsList, updateTermsData, isLoading, resetError, error } = useTerms(siteId);
  const [success, setSuccess] = useState('');
  const [type, setType] = useState<AlertColor>(SUCCESS);
  const [value, setValue] = useState(languages?.[0]?.code ?? '');
  const [currentTermsList, serCurrentTermsList] = useState<ITerms[] | []>([]);
  const [selectedItem, setSelectedItem] = useState(termsList[0]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleOnSave = async () => {
    const body: { id: number; term: any }[] = [];
    currentTermsList.map((el) => {
      if (el.term) {
        body.push({ id: el.id, term: el.term });
      }
    });

    setSuccess('');
    setType(SUCCESS);
    resetError();

    const res = await updateTermsData(body, siteId);
    const { status } = res ?? {};
    if (status === 200) {
      setSuccess(t('savedSuccess'));
      setType(SUCCESS);
    } else {
      setType(ERROR);
    }
  };

  const handleOnEdit = (code: string, term: any) => {
    serCurrentTermsList((currentTermsList: any) =>
      currentTermsList.map((elm: any) => (elm.code === code ? { ...elm, term } : elm)),
    );
  };

  const handleOnClose = () => {
    setSuccess('');
    setType(SUCCESS);
    resetError();
  };

  useEffect(() => {
    if (termsList) {
      serCurrentTermsList(termsList);
      const filterData = termsList.filter((elm: any) => elm.code === value);
      setSelectedItem(filterData[0]);
    }
  }, [termsList]);

  useEffect(() => {
    const filterData = currentTermsList.filter((elm: any) => elm.code === value);
    setSelectedItem(filterData[0]);
    handleOnClose();
  }, [value]);

  return (
    <>
      {isLoading ? (
        <LoaderWithBg isOpen={isLoading} />
      ) : (
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                {termsList?.map((elm, i: number) => {
                  return <Tab label={elm.language} value={elm.code} key={i} />;
                })}
              </TabList>
            </Box>
            <TabPanel value={value}>
              <TermsLanguage
                value={value}
                onSave={handleOnSave}
                onEdit={handleOnEdit}
                handleOnClose={handleOnClose}
                result={success || error}
                type={type}
                selectedItem={selectedItem}
              />
            </TabPanel>
          </TabContext>
        </Box>
      )}
    </>
  );
}

export default Terms;
