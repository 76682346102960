import {
  AlertColor,
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { FIELD_NAME } from 'types/sitesSettings';
import { ERROR, SUCCESS } from 'constants/common';
import { isValidForPaymentProvidersSettings } from 'utils/helpers/inputHelper';
import { useFinance } from 'hooks/finances/useFinance';
import Result from 'components/alert/alert';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { updateSitePaymentProvidersSettings } from 'redux/actions/sitesActions';
import { useTranslation } from 'react-i18next';

interface IProviderProps {
  providerId: string;
  index: number;
}

const fields = {
  [FIELD_NAME.PRIMARY_PAYIN_LIMIT]: true,
  [FIELD_NAME.PRIMARY_ACTIVE_DATE]: true,
};

function Provider({ providerId }: IProviderProps) {
  const { updatePaymentProviderSettings } = useFinance();
  const { paymentProviders } = useSelector<any>((state) => state.siteSettings) as any;
  const [type, setType] = useState<AlertColor>(SUCCESS);
  const [formData, setFormData] = useState(
    paymentProviders.find((item: any) => item.id === providerId),
  );
  const [result, setResult] = useState('');
  const [validFields, setValidFields] = useState(fields);
  const dispatch = useDispatch();
  const { t } = useTranslation('siteSettings');

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const body = formData.isPrimary
      ? {
          isPrimary: formData.isPrimary,
          primaryPayinLimit: null,
          primaryActiveDate: null,
        }
      : {
          isPrimary: formData.isPrimary,
          primaryPayinLimit: formData.primaryPayinLimit,
          primaryActiveDate: formData.primaryActiveDate,
        };
    const result = await updatePaymentProviderSettings(body, providerId);
    if (result) {
      dispatch(updateSitePaymentProvidersSettings(result.data));
      setType(SUCCESS);
      setResult(SUCCESS);
    } else {
      setType(ERROR);
      setResult(ERROR);
    }
  };

  const handleOnClose = () => {
    setResult('');
    setType(SUCCESS);
  };

  const handleOnSwicth = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const { checked } = e.target;

    const data = { ...formData, [key]: checked };
    setFormData(data);
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string,
  ) => {
    const { value } = e.target;
    setValidFields({ ...validFields, [key]: isValidForPaymentProvidersSettings(value, key) });

    const data = { ...formData, [key]: value };
    setFormData(data);
  };

  const dateTimePickerChange = (value: Dayjs | null) => {
    if (value) {
      const data = { ...formData, [FIELD_NAME.PRIMARY_ACTIVE_DATE]: value.toISOString() };
      setFormData(data);
    }
  };

  return (
    <Box sx={{ minWidth: '400px' }}>
      <Box component={'form'} onSubmit={handleSubmit}>
        {!formData.isPrimary ? (
          <>
            <TextField
              fullWidth
              value={formData.primaryPayinLimit}
              type="number"
              margin="normal"
              name="title"
              label={t('primaryPayinLimit')}
              variant="standard"
              onChange={(e) => handleOnChange(e, FIELD_NAME.PRIMARY_PAYIN_LIMIT)}
              error={!validFields[FIELD_NAME.PRIMARY_PAYIN_LIMIT]}
              helperText={validFields[FIELD_NAME.PRIMARY_PAYIN_LIMIT] ? '' : 'min 0.'}
              sx={{ mb: 2 }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DateTimePicker']} sx={{ mb: 2 }}>
                <DateTimePicker
                  ampm={false}
                  sx={{ width: '300px', mt: 2, mb: 3 }}
                  slotProps={{
                    layout: { sx: { textTransform: 'capitalize' } },
                    textField: { size: 'small' },
                  }}
                  label={t('primaryActiveDate')}
                  disablePast
                  value={formData.primaryActiveDate ? dayjs(formData.primaryActiveDate) : null}
                  onChange={(newValue) => dateTimePickerChange(newValue)}
                />
              </DemoContainer>
            </LocalizationProvider>
          </>
        ) : null}

        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={formData.isPrimary}
                onChange={(e) => handleOnSwicth(e, 'isPrimary')}
              />
            }
            label={!formData.isPrimary ? t('makePrimary') : t('isPrimary')}
          />
        </FormGroup>
        <Button
          fullWidth
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={!validFields[FIELD_NAME.PRIMARY_PAYIN_LIMIT]}
        >
          {t('save')}
        </Button>
      </Box>
      {result && (
        <Result
          style={{ mb: 2, mt: 2 }}
          message={result}
          type={type}
          isShow
          hasAction
          onClose={handleOnClose}
        />
      )}
    </Box>
  );
}

export default Provider;
