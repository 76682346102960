import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importing translation files

import translationAccountMenuEN from './locales/en/accountMenu.json';
import traslationMainMenuEN from './locales/en/mainMenu.json';
import translationCommonEN from './locales/en/common.json';
import translationSitesEN from './locales/en/sites.json';
import translationSiteSettingsEN from './locales/en/siteSettings.json';
import translationFinanceDepositEN from './locales/en/financeDeposit.json';
import translationFinanceWithdrawalsEN from './locales/en/financeWithdrawals.json';
import translationAccountRolesEN from './locales/en/accountRoles.json';
import translationClientsEN from './locales/en/clients.json';
import translationClientsDetailsEN from './locales/en/clientsDetails.json';
import translationTelegramEN from './locales/en/telegram.json';
import translationPromoCodesEN from './locales/en/promoCodes.json';
import tralationAddBonuseForClientsEN from './locales/en/addBonusForClients.json';
import translationColumnsEN from './locales/en/columns.json';

import translationAccountMenuRU from './locales/ru/accountMenu.json';
import traslationMainMenuRU from './locales/ru/mainMenu.json';
import translationCommonRU from './locales/ru/common.json';
import translationSitesRU from './locales/ru/sites.json';
import translationSiteSettingsRU from './locales/ru/siteSettings.json';
import translationFinanceDepositRU from './locales/ru/financeDeposit.json';
import translationFinanceWithdrawalsRU from './locales/ru/financeWithdrawals.json';
import translationAccountRolesRU from './locales/ru/accountRoles.json';
import translationClientsRU from './locales/ru/clients.json';
import translationClientsDetailsRU from './locales/ru/clientsDetails.json';
import translationTelegramRU from './locales/ru/telegram.json';
import translationPromoCodesRU from './locales/ru/promoCodes.json';
import translationvalidationRU from './locales/ru/validations.json';
import translationvalidationEN from './locales/en/validations.json';
import tralationAddBonuseForClientsRU from './locales/ru/addBonusForClients.json';
import translationColumnsRU from './locales/ru/columns.json';


import { STORAGE_KEYS, localStorageService } from 'utils/storageService';


//Creating object with the variables of imported translation files
const resources = {
  en: {
    accountMenu: translationAccountMenuEN,
    mainMenu: traslationMainMenuEN,
    common: translationCommonEN,
    sites: translationSitesEN,
    siteSettings: translationSiteSettingsEN,
    financeDeposit: translationFinanceDepositEN,
    financeWithdrawals: translationFinanceWithdrawalsEN,
    accountRoles: translationAccountRolesEN,
    clients: translationClientsEN,
    clientsDetails: translationClientsDetailsEN,
    telegram: translationTelegramEN,
    promoCodes: translationPromoCodesEN,
    validations: translationvalidationEN,
    addBonusForClients: tralationAddBonuseForClientsEN,
    columns: translationColumnsEN
  },
  ru: {
    accountMenu: translationAccountMenuRU,
    mainMenu: traslationMainMenuRU,
    common: translationCommonRU,
    sites: translationSitesRU,
    siteSettings: translationSiteSettingsRU,
    financeDeposit: translationFinanceDepositRU,
    financeWithdrawals: translationFinanceWithdrawalsRU,
    accountRoles: translationAccountRolesRU,
    clients: translationClientsRU,
    clientsDetails: translationClientsDetailsRU,
    telegram: translationTelegramRU,
    promoCodes: translationPromoCodesRU,
    validations: translationvalidationRU,
    addBonusForClients: tralationAddBonuseForClientsRU,
    columns: translationColumnsRU
  },
};

//i18N Initialization

i18n.use(initReactI18next).init({
  resources,
  lng: localStorageService.read(STORAGE_KEYS.LANGUAGE_KEY)
    ? localStorageService.read(STORAGE_KEYS.LANGUAGE_KEY)
    : 'ru', //default language
  fallbackLng: "ru", // use en if detected lng is not available
  keySeparator: true,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
