import { createTheme } from '@mui/material';
import grey from '@mui/material/colors/grey';

export const light = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#3e4168',
    },
    background: {
      default: '#f9f4f4',
      paper: '#2a2e5c',
    },
    text: {
      primary: '#2a2e5c',
      secondary: grey[500],
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
              backgroundColor: '#344165',
              color: '#c0e1fc',
              fontSize: '12px',
              padding: '6px',
            }),
        }),
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: () => ({
          backgroundColor: '#c0e1fc',
          svg: { color: '#3e4168' },
          color: '#3e4168',
        }),
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: () => ({
          color: '#c0e1fc',
          fontSize: '12px',
          padding: '6px',
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: () => ({
          input: {
            textIndent: 6,
          },
          label: {
            fontSize: '8px',
          },
        }),
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          display: 'flex',
          width: '100%',
          fontSize: '12px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'h5' && {
            color: '#c0e1fc',
          }),
          ...(ownerState.variant === 'h6' && {
            color: '#2a2e5c',
          }),
          fontSize: '12px',
        }),
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: () => ({
          minWidth: '30px',
        }),
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: () => ({
          color: '#c0e1fc',
          width: '16px',
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: () => ({
          span: { fontSize: '0.8rem' },
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: () => ({
          fontSize: '0.7rem',
          minHeight: '20px',
          padding: '8px 12px',
          span: { fontSize: '12px' },
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: () => ({
          fontSize: '0.7rem',
          minHeight: '20px',
          padding: '8px 12px',
          span: { fontSize: '12px' },
        }),
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: () => ({
          fontSize: '13px',
        }),
      },
    },
  },
});
