import { useApiErrors } from 'hooks/errors/useApiErrors';
import { useStorage } from 'hooks/authHooks/useStorage';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentId } from 'redux/actions/commonActions';
import { setCurrentSiteData, setSiteSettings, setSitesData } from 'redux/actions/sitesActions';
import SiteService from 'services/site.service';
import { ISite } from 'types/signInForm';
import { ICreateSite, IGotoPage } from 'types/sites';
import {
  prepareDataForSelectPages,
  prepareItemData,
  prepareTableData,
  preparedColumnNames,
} from 'utils/helpers/dataHelper/prepareDataHelper';
import { generateHeaders, joinMessage } from 'utils/utils';
import { ICurrency } from 'hooks/currency/useCurrency';
import {
  usersBonusStatisticstColumns,
  usersCashbackStatisticstColumns,
} from 'utils/helpers/columnsNameOrdering';

export const useSites = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [siteList, setSiteList] = useState<ISite[]>([]);
  const [siteCurrencies, setSiteCurrencies] = useState<ICurrency[]>([]);
  const [columns, setColumns] = useState<string[] | []>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [usersCount, setUsersCount] = useState(0);
  const [pageLists, setPageLists] = useState<IGotoPage[] | []>([]);
  const [error, setError] = useState('');
  const { logout, userToken } = useStorage();
  const { errorHandler } = useApiErrors();
  const initHeaders = generateHeaders(userToken());

  const getCashbacks: any = async (siteId: string, query: string, headers = initHeaders) => {
    setIsLoading(true);
    try {
      const {
        data: {
          data: { usersCashback, totalPages, totalItems },
          status,
        },
      } = await SiteService.getCashbackLists(headers, siteId, query);
      let preparedData = [];
      let pageLists: any = [];
      if (usersCashback.length) {
        preparedData = prepareTableData(usersCashback, '');
        pageLists = prepareDataForSelectPages(totalPages);
        const columns = preparedColumnNames(preparedData, usersCashbackStatisticstColumns);
        setColumns(columns);
      }
      setTotalPages(totalPages);
      setPageLists(pageLists as IGotoPage[]);
      setTotalItems(totalItems);
      setSiteList(preparedData);
      return { status };
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getCashbacks(siteId, query, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getAllSites: any = async (headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: {
          data: { sites, totalPages, totalItems },
        },
      } = await SiteService.getAll(headers);

      const preparedData = prepareTableData(sites, 'sites');
      const columns = preparedColumnNames(preparedData);
      const pageLists = prepareDataForSelectPages(totalPages);
      setColumns(columns);
      dispatch(setSitesData(preparedData));
      setSiteList(preparedData);
      setTotalPages(totalPages);
      setPageLists(pageLists as IGotoPage[]);
      setTotalItems(totalItems);
    } catch (err: any) {
      if (err.response) {
        const {
          response: {
            data: { error, message, status },
          },
        } = err;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          logout();
        } else if (!needLogout && newHeader) {
          return await getAllSites(newHeader);
        } else {
          console.log('LOG____>', message);
          setError(joinMessage(error));
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const queryParamsQuery: any = async (query = 'pageSize=100', headers = initHeaders) => {
    try {
      const {
        data: {
          data: { sites, totalPages, totalItems },
        },
      } = await SiteService.getSiteByQuery(headers, query);

      if (sites) {
        const preparedData = prepareTableData(sites, 'sites');
        const columns = preparedColumnNames(preparedData);
        const pageLists = prepareDataForSelectPages(totalPages);
        dispatch(setSitesData(preparedData));
        setSiteList(preparedData);
        setColumns(columns);
        setTotalPages(totalPages);
        setPageLists(pageLists as IGotoPage[]);
        setTotalItems(totalItems);
      } else if (sites === null) {
        dispatch(setSitesData([]));
      }
    } catch (err: any) {
      if (err.response) {
        const {
          response: {
            data: { error, message, status },
          },
        } = err;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          logout();
        } else if (!needLogout && newHeader) {
          return await queryParamsQuery(query, newHeader);
        } else {
          console.log('LOG____>', message);
          setError(joinMessage(error));
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getAllPayInStatistics: any = async (
    siteId: string,
    query: string,
    headers = initHeaders,
  ) => {
    try {
      const {
        data: {
          data: { payInTransactions, totalPages, totalItems, usersCount },
          status,
        },
      } = await SiteService.getAllPayInStatistics(headers, siteId, query);
      const preparedData = prepareTableData(payInTransactions, '');
      const columns = preparedColumnNames(preparedData);
      const pageLists = prepareDataForSelectPages(totalPages);
      setSiteList(preparedData);
      setColumns(columns);
      setTotalPages(totalPages);
      setPageLists(pageLists as IGotoPage[]);
      setTotalItems(totalItems);
      setUsersCount(usersCount);
      return { status };
    } catch (err: any) {
      if (err.response) {
        const {
          response: {
            data: { error, message, status },
          },
        } = err;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          logout();
        } else if (!needLogout && newHeader) {
          return await getAllPayInStatistics(siteId, query, newHeader);
        } else {
          console.log('LOG____>', message);
          setError(joinMessage(error));
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getSitesAllUsersBonuses: any = async (
    siteId: string,
    query: string,
    headers = initHeaders,
  ) => {
    try {
      setIsLoading(true);
      const {
        data: {
          data: { userBonuses, totalPages, totalItems, usersCount },
          status,
        },
      } = await SiteService.getSitesAllUsersBonuses(headers, siteId, query);

      if (userBonuses.length) {
        const preparedData = prepareTableData(userBonuses, '');
        const columns = preparedColumnNames(preparedData, usersBonusStatisticstColumns);
        const pageLists = prepareDataForSelectPages(totalPages);
        setSiteList(preparedData);
        setColumns(columns);
        setTotalPages(totalPages);
        setPageLists(pageLists as IGotoPage[]);
        setTotalItems(totalItems);
        setUsersCount(usersCount);
      }
      return { status };
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;
      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getSitesAllUsersBonuses(siteId, query, newHeader);
      } else {
        console.log('LOG____>getSitesAllUsersBonuses', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getAllPayOutStatistics: any = async (
    siteId: string,
    query: string,
    headers = initHeaders,
  ) => {
    try {
      setIsLoading(true);
      const {
        data: {
          data: { payOutTransactions, totalPages, totalItems, usersCount },
          status,
        },
      } = await SiteService.getAllPayOutStatistics(headers, siteId, query);
      const preparedData = prepareTableData(payOutTransactions, '');
      const columns = preparedColumnNames(preparedData);
      const pageLists = prepareDataForSelectPages(totalPages);
      setSiteList(preparedData);
      setColumns(columns);
      setTotalPages(totalPages);
      setPageLists(pageLists as IGotoPage[]);
      setTotalItems(totalItems);
      setUsersCount(usersCount);
      return { status };
    } catch (err: any) {
      if (err.response) {
        const {
          response: {
            data: { error, message, status },
          },
        } = err;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          logout();
        } else if (!needLogout && newHeader) {
          return await getAllPayOutStatistics(siteId, query, newHeader);
        } else {
          console.log('LOG____>', message);
          setError(joinMessage(error));
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const createSite: any = async (body: ICreateSite, headers = initHeaders) => {
    try {
      const { status } = await SiteService.create(headers, body);
      if (status === 201) {
        return { status };
      }
    } catch (error: any) {
      if (error.response) {
        const {
          response: {
            data: { message, status, error: errorMessage },
          },
        } = error;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          logout();
        } else if (!needLogout && newHeader) {
          return await createSite(body, newHeader);
        } else {
          console.log('LOG____joinedMessage_>', joinMessage(errorMessage));
          setError(joinMessage(errorMessage));
        }
      }
    }
  };

  const getSiteStatistics: any = async (siteId: string, query?: any, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await SiteService.getSiteStatistics(headers, siteId, query);

      if (status === 200) {
        return data;
      }
    } catch (err: any) {
      const {
        response: {
          data: { error, status, message },
        },
      } = err;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};

      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getSiteStatistics(siteId, newHeader);
      } else {
        const errorMessage = joinMessage(error);
        console.log('LOG____joinedMessage_>', errorMessage);
        setError(errorMessage);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getSiteById: any = async (id: string, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data },
      } = await SiteService.getOneById(headers, id);
      dispatch(setCurrentId({ siteId: data.id }));
      const preparedData = prepareItemData(data, 'sites') as { [x: string]: any; currencies: [] };
      dispatch(setCurrentSiteData(preparedData));
      setSiteCurrencies(preparedData?.currencies);
    } catch (err: any) {
      const {
        response: {
          data: { error, message, status },
        },
      } = err;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};

      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getSiteById(id, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(joinMessage(error));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const saveSite: any = async (id: string, body: any, headers = initHeaders) => {
    try {
      const { data } = await SiteService.editSite(headers, id, body);
      if (data && data.status === 200) {
        const preparedData = prepareItemData(data.data, 'sites');
        dispatch(setCurrentSiteData(preparedData));
        return { status: data.status };
      }
    } catch (err: any) {
      const {
        response: {
          data: { error, status, message },
        },
      } = err;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};

      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await saveSite(id, body, newHeader);
      } else {
        const errorMessage = joinMessage(error);
        console.log('LOG____joinedMessage_>', errorMessage);
        setError(errorMessage);
      }
    }
  };

  const getSiteSettings: any = async (headers = initHeaders) => {
    setIsLoading(true);
    try {
      const {
        data: { data },
      } = await SiteService.getSiteSettings(headers);
      setIsLoading(false);
      dispatch(setSiteSettings(data));
    } catch (err: any) {
      const {
        response: {
          data: { error, status, message },
        },
      } = err;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};

      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getSiteSettings(newHeader);
      } else {
        const errorMessage = joinMessage(error);
        console.log('LOG____joinedMessage_>', errorMessage);
        setError(errorMessage);
      }
    }
  };

  return {
    siteList,
    getAllSites,
    getSiteSettings,
    getAllPayInStatistics,
    getAllPayOutStatistics,
    getSitesAllUsersBonuses,
    getSiteById,
    createSite,
    saveSite,
    columns,
    totalPages,
    pageLists,
    totalItems,
    isLoading,
    error,
    queryParamsQuery,
    siteCurrencies,
    getSiteStatistics,
    getCashbacks,
    usersCount,
  };
};
