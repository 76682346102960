import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from '@mui/material';
import { Editor, RawDraftContentState } from 'react-draft-wysiwyg';
import { useEffect, useState } from 'react';
import Result from 'components/alert/alert';
import common from 'styles/common.module.scss';
import { useTranslation } from 'react-i18next';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import UploadButtons from 'components/imageUpload/imageUpload';
import { FIELD_NAME } from 'types/sites';
import uuid from 'react-uuid';
import AutoBonus from 'components/creatingAutoBonus/creatingAutoBonus';
import { isValidValueForClientsLabels } from 'utils/helpers/inputHelper';
import { useLevels } from 'hooks/sites/useLevels';
import styles from './modal.module.scss';
import { isEmpty } from 'lodash';
import LoaderWithBg from 'components/loader/loader';
import { LevelAdvantages } from 'components/checkbox/levelAdvantages';

interface ClientsLevelsCreateEditProps {
  title?: string;
  mode: string;
  siteId: string;
  item?: any;
  onClose: () => void;
}

export interface ILevelAdv {
  id: number;
  key: string;
  isActive: boolean;
}

interface IClientsLevel {
  name: string;
  image: string;
  minDepositBorder: string;
  maxDepositBorder: string;
  cashbackPercent: number;
  wheelCountPerDay: number;
  bonusText: RawDraftContentState;
  isBirthdayBonus?: boolean;
  autoBonuses: any[];
  properties: ILevelAdv[];
  withdrawBorder: number;
}

const style = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'left',
};

const editor = {
  border: '2px solid gray',
  borderLeft: '2px solid gray',
  padding: '5px',
  backgroundColor: 'white',
  color: 'black',
  height: 200,
};

const fields = {
  [FIELD_NAME.NAME]: true,
  [FIELD_NAME.CASHBACK_PERCENT]: true,
  [FIELD_NAME.MIN_DEPOSIT]: true,
  [FIELD_NAME.MAX_DEPOSIT]: true,
  [FIELD_NAME.IMAGE]: true,
  [FIELD_NAME.CREDIT_BONUS_AMOUNT]: true,
  [FIELD_NAME.WITHDRAW_BORDER]: true,
};

function ClientsLevelsCreateEdit({ onClose, mode, item, siteId }: ClientsLevelsCreateEditProps) {
  const { t } = useTranslation('common');
  const { t: validationT } = useTranslation('validations');
  const { t: siteSettingsT } = useTranslation('siteSettings');
  const { editLevel, createLevel, error, uploadLevelImage, isLoading } = useLevels();
  const [validFields, setValidFields] = useState({ ...fields });
  const [formData, setFormData] = useState({} as IClientsLevel);
  const [contentState, setContentState] = useState<any>();
  const [pendingImageUrl, setPendingImageUrl] = useState<string | Blob>('');

  const [validationError, setValidationError] = useState({
    imageError: false,
    maxAmountError: false,
    withdrawBorder: false,
  });

  const isValidData = (data: any) => {
    if (+data.maxDepositBorder < +data.minDepositBorder) {
      setValidationError((error) => ({
        ...error,
        maxAmountError: true,
      }));
    }
    return !(+data.maxDepositBorder < +data.minDepositBorder) && !validationError.imageError;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (isValidData(formData)) {
      const body = {
        name: formData.name,
        minDepositBorder: formData.minDepositBorder,
        maxDepositBorder: formData.maxDepositBorder,
        cashbackPercent: formData.cashbackPercent ?? 0,
        bonusText: contentState ?? {},
        isBirthdayBonus: formData.isBirthdayBonus ?? false,
        autoBonuses:
          formData.autoBonuses?.map((el) => ({
            amount: el.amount,
            bonusDay: el.bonusDay,
          })) ?? [],
        properties: formData.properties,
        withdrawBorder: formData.withdrawBorder,
        siteId,
      };

      try {
        let result;
        if (mode === 'edit') {
          result = await editLevel(item?.id, body);
        } else {
          result = await createLevel({ ...body, siteId });
        }
        if (result?.status === 200 || result?.status === 201) {
          if (pendingImageUrl) {
            const res = await uploadLevelImage(result?.data?.id, pendingImageUrl);
            if (res?.status === 200) {
              setFormData((formData) => ({ ...formData, image: res?.data ?? '' }));
            }
          }
          onClose();
        }
      } catch (error: any) {
        console.log(error, 'error');
      }
    }
  };

  const onImageUpload = async (imageUrl: string | Blob, prevUrl?: string) => {
    if (mode === 'edit') {
      const res = await uploadLevelImage(item?.id, imageUrl);
      if (res?.status === 200) {
        setFormData((formData) => ({ ...formData, image: res?.data ?? '' }));
      }
    } else {
      setPendingImageUrl(imageUrl);
      setFormData((formData) => ({ ...formData, image: prevUrl ?? '' }));
    }
  };

  const handleCreditionChange = (value: string, index: number, name: string) => {
    const temp = [...formData.autoBonuses];
    temp.splice(index, 1, { ...formData.autoBonuses[index], [name]: +value });
    setFormData({ ...formData, autoBonuses: temp });
  };

  const handleCreditionDelete = (id: string) => {
    const temp = [...formData.autoBonuses];
    const index = temp.findIndex((el) => el.id === id);
    temp.splice(index, 1);
    formData.autoBonuses = temp;
    setFormData({ ...formData, autoBonuses: temp });
  };

  const handleClickAdd = () => {
    const data = {
      ...formData,
      autoBonuses: formData?.autoBonuses
        ? [...formData.autoBonuses, { id: uuid(), amount: '', bonusDay: '' }]
        : [{ id: uuid(), amount: '', bonusDay: '' }],
    };

    setFormData(data);
  };

  const handleOnChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    const data = { ...formData, isBirthdayBonus: checked };
    setFormData(data);
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: string,
  ) => {
    const { value } = e.target;
    const data = { ...formData, [name]: value };
    setFormData(data);
  };

  const removeImage = () => {
    const data = { ...formData, image: '' };
    setFormData(data);
  };

  const handleOnError = (hasError: boolean, name: string) => {
    setValidFields((fields) => ({
      ...fields,
      [name]: !hasError,
    }));
  };

  const onContentStateChange = (contentState: any) => {
    setContentState(contentState);
  };

  const validate = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, name: string) => {
    const { value } = e.target;
    setValidFields((fields) => ({
      ...fields,
      [name]: isValidValueForClientsLabels(value, name),
    }));

    if (validationError.maxAmountError) {
      setValidationError((error) => ({
        ...error,
        maxAmountError: false,
      }));
    }
  };

  const uploadImagOnEditor = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const dataURL = reader.result;
        resolve({ data: { link: dataURL } });
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleCheckboxChange = (list: ILevelAdv[]) => {
    const data = { ...formData, properties: [...list] };
    setFormData(data);
  };

  useEffect(() => {
    if (item.id !== undefined) {
      setFormData({ ...item });
    }
  }, [item]);

  return (
    <>
      {isLoading ? <LoaderWithBg isOpen={isLoading} /> : null}
      <Box sx={{ mt: 2 }}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {t(mode)}
        </Typography>
      </Box>
      <Box sx={style}>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
          <TextField
            margin="normal"
            id="Name"
            label={siteSettingsT(FIELD_NAME.NAME)}
            fullWidth
            required
            variant="standard"
            value={formData?.name ?? ''}
            onChange={(e) => handleOnChange(e, FIELD_NAME.NAME)}
            onBlur={(e) => validate(e, FIELD_NAME.NAME)}
            error={!validFields[FIELD_NAME.NAME]}
            helperText={validFields[FIELD_NAME.NAME] ? '' : validationT('siteNameValidation')}
          />
          <Box className={styles.content_part}>
            <UploadButtons
              initialImage={formData?.image ?? null}
              onError={handleOnError}
              needFormDataImage
              needFormDataImageWithPreview
              onImageUpload={(url, prevUrl) => onImageUpload(url, prevUrl)}
            />
            {formData?.image ? (
              <DeleteForeverIcon
                sx={{ color: common.mainwarning, fontSize: 28, cursor: 'pointer' }}
                onClick={removeImage}
              />
            ) : null}
          </Box>
          <TextField
            margin="normal"
            label={siteSettingsT('minAmountDeposit')}
            fullWidth
            required
            type="number"
            variant="standard"
            value={formData?.minDepositBorder ?? ''}
            onChange={(e) => handleOnChange(e, FIELD_NAME.MIN_DEPOSIT)}
            onBlur={(e) => validate(e, FIELD_NAME.MIN_DEPOSIT)}
            error={!validFields[FIELD_NAME.MIN_DEPOSIT]}
            helperText={validFields[FIELD_NAME.MIN_DEPOSIT] ? '' : validationT('onlyPositive')}
          />
          <TextField
            margin="normal"
            label={siteSettingsT('maxAmountDeposit')}
            fullWidth
            required
            type="number"
            variant="standard"
            value={formData?.maxDepositBorder ?? ''}
            onChange={(e) => handleOnChange(e, FIELD_NAME.MAX_DEPOSIT)}
            onBlur={(e) => validate(e, FIELD_NAME.MAX_DEPOSIT)}
            error={!validFields[FIELD_NAME.MAX_DEPOSIT] || validationError.maxAmountError}
            helperText={
              validFields[FIELD_NAME.MAX_DEPOSIT]
                ? validationError.maxAmountError
                  ? validationT('minDeposit')
                  : ''
                : validationT('onlyPositive')
            }
          />
          <TextField
            margin="normal"
            label={siteSettingsT('withdrawBorder')}
            fullWidth
            required
            type="number"
            variant="standard"
            value={formData?.withdrawBorder ?? ''}
            onChange={(e) => handleOnChange(e, FIELD_NAME.WITHDRAW_BORDER)}
            onBlur={(e) => validate(e, FIELD_NAME.WITHDRAW_BORDER)}
            error={!validFields[FIELD_NAME.WITHDRAW_BORDER] || validationError.withdrawBorder}
            helperText={
              validFields[FIELD_NAME.WITHDRAW_BORDER]
                ? ''
                : validationT('greaterThan', { value: 1 })
            }
          />
          <TextField
            margin="normal"
            fullWidth
            type="number"
            name="cashback"
            label={siteSettingsT('inputForCashbackPercent')}
            variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
            value={formData?.cashbackPercent ?? 0}
            onChange={(e) => handleOnChange(e, FIELD_NAME.CASHBACK_PERCENT)}
            onBlur={(e) => validate(e, FIELD_NAME.CASHBACK_PERCENT)}
            error={!validFields[FIELD_NAME.CASHBACK_PERCENT]}
            helperText={
              validFields[FIELD_NAME.CASHBACK_PERCENT]
                ? ''
                : validationT('greaterThan', { value: 1 })
            }
          />
          <FormGroup>
            <FormControlLabel
              checked={!!formData?.isBirthdayBonus}
              control={<Checkbox onChange={handleOnChecked} />}
              label={siteSettingsT('birthDay')}
            />
          </FormGroup>
          <Box>
            <LevelAdvantages list={formData?.properties || []} onChange={handleCheckboxChange} />
          </Box>

          <Box className={styles.textBonus}>
            <Typography sx={{ margin: '6px !important', opacity: 0.8 }}>
              {siteSettingsT('bonusText')}
            </Typography>

            <Editor
              editorStyle={editor}
              toolbarStyle={{
                color: 'black',
              }}
              toolbar={{
                image: {
                  previewImage: true,
                  uploadCallback: uploadImagOnEditor,
                  alt: { present: true, mandatory: false },
                },
              }}
              onContentStateChange={onContentStateChange}
              contentState={isEmpty(formData?.bonusText) ? undefined : formData?.bonusText}
            />
          </Box>
          <Divider />
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mt: 3,
              }}
            >
              <Typography sx={{ fontSize: '14px', opacity: '0.5', mr: 3 }}>
                {siteSettingsT('autoCrediting')}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Button
                  variant="contained"
                  sx={{ ml: 1, color: '#fff' }}
                  title="add"
                  color="success"
                  onClick={handleClickAdd}
                >
                  {t('add')}
                </Button>
              </Box>
            </Box>
            <Box>
              {formData?.autoBonuses?.map((item, index) => {
                return (
                  <Box key={index}>
                    <AutoBonus
                      item={item}
                      index={index}
                      onChange={handleCreditionChange}
                      onDelete={handleCreditionDelete}
                      onError={handleOnError}
                    />
                  </Box>
                );
              })}
            </Box>
          </>
          <Box sx={{ mt: 2 }}>
            {error && <Result style={{ mb: 2 }} message={error} type={'error'} isShow />}
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Button
              fullWidth
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={
                !validFields[FIELD_NAME.CASHBACK_PERCENT] ||
                !validFields[FIELD_NAME.MIN_DEPOSIT] ||
                !validFields[FIELD_NAME.NAME] ||
                !validFields[FIELD_NAME.MAX_DEPOSIT] ||
                !validFields[FIELD_NAME.IMAGE] ||
                !validFields[FIELD_NAME.CREDIT_BONUS_AMOUNT] ||
                !validFields[FIELD_NAME.WITHDRAW_BORDER] ||
                validationError.maxAmountError ||
                isLoading
              }
            >
              {mode === 'edit' ? t('save') : t(mode)}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ClientsLevelsCreateEdit;
