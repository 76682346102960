import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import { StrictModeDroppable } from '../strictModeDroppable';
import { AlertColor, Box, Button, Modal, Typography, useTheme } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import EditIcon from '@mui/icons-material/Edit';
import { grey } from '@material-ui/core/colors';
import Dialogs from 'components/dialog/dialogs';
import { SUCCESS, dialog } from 'constants/common';
import { Game } from 'components/menu';
import { useSiteCategory } from 'hooks/sites/useSiteCategory';
import { ISiteCategoryItem, MODE } from 'types/sites';
import CreateCategory from 'components/modal/createCategory';
import EditCategory from 'components/modal/editCategory';
import { useTranslation } from 'react-i18next';
import { IconClose } from 'components/closeButton/closeButton';
import { setSiteCategories } from 'redux/actions/sitesActions';
import Result from 'components/alert/alert';
import { useDispatch } from 'react-redux';
import common from 'styles/common.module.scss';
import { style } from 'components/modal/modal';
import styles from './categoryItems.module.scss';

interface MenuItemsProps {
  onAction: (item: ISiteCategoryItem, mode: modeType) => void;
  categoryList: ISiteCategoryItem[];
  siteId: string;
}

export type modeType = 'edit' | 'delete' | 'editContent';

const initial = { title: '', id: '', image: null, gamesCount: 0 };

function SitesCategoryItems({ onAction, categoryList, siteId }: MenuItemsProps) {
  const dispatch = useDispatch();
  const { saveSiteCategories } = useSiteCategory();
  const { t: siteSettingsT } = useTranslation('siteSettings');
  const { t } = useTranslation('common');
  const theme = useTheme();
  const { error, deleteCategory, getAllCategoryForSite } = useSiteCategory();
  const [openModal, setOpenModal] = useState(false);
  const [success, setSuccess] = useState('');
  const [type, setType] = useState<AlertColor>(SUCCESS);
  const [mode, setMode] = useState('');
  const [open, setOpen] = useState(false);
  const [activeItem, setActiveItem] = useState<ISiteCategoryItem>(initial);
  const orderRef = useRef({ hasOrdered: false });
  let timeOutId: ReturnType<typeof setTimeout>;

  const handleOnDelete = async (categoryId: string | number | null) => {
    const result = await deleteCategory(siteId, categoryId);
    if (result.status === 200) {
      setOpen(false);
      await getAllCategoryForSite(siteId);
    }
  };

  const handleClose = async () => {
    setOpenModal(false);
  };

  const handleOnSave = async () => {
    const categoryOrder = categoryList.map((el: any) => +el.id);
    let saveResult;
    if (orderRef.current.hasOrdered) {
      saveResult = await saveSiteCategories(siteId, { categoryOrder });
      orderRef.current.hasOrdered = false;
    }

    if (saveResult?.status === 200) {
      setSuccess(t('savedSuccess'));
      setType(SUCCESS);
      timeOutId = setTimeout(() => {
        setSuccess('');
      }, 4000);
    }
  };

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    orderRef.current.hasOrdered = true;

    const items = Array.from(categoryList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    dispatch(setSiteCategories(items));
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>, title: string, mode: modeType) => {
    const { id } = e.currentTarget;
    if (mode === MODE.DELETE) {
      setOpen(true);
      setActiveItem((item) => ({ ...item, title, id }));
    } else {
      const editedItem = categoryList.filter((el) => +el.id === +id)?.[0];
      if (mode === MODE.EDIT) {
        setActiveItem({ ...editedItem });
        setMode(MODE.EDIT);
        setOpenModal(true);
      } else {
        onAction(editedItem, mode);
      }
    }
  };

  const hnadleOnAddCategory = () => {
    setMode('add');
    setOpenModal(true);
  };

  useEffect(() => {
    return () => clearTimeout(timeOutId);
  }, []);

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="contained" sx={{ mt: 3, mb: 2, mr: 4 }} onClick={hnadleOnAddCategory}>
          {siteSettingsT('addCategory')}
        </Button>
      </Box>
      {!categoryList.length ? (
        <Box>
          <Typography variant="subtitle1" color="text.primary" component="div">
            {siteSettingsT('notFoundCategory')}
          </Typography>
        </Box>
      ) : (
        <>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <StrictModeDroppable droppableId="menuItems">
              {(provided) => (
                <ul
                  className={styles.menuItems}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {categoryList.map(({ id, title, isMobile, isDesktop, gamesCount }, index) => (
                    <Draggable key={id} draggableId={id + ''} index={index}>
                      {(provided) => (
                        <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Box color={'primary.main'}>
                            <p>{title}</p>
                            <Typography variant="body2" color={grey[400]}>
                              {siteSettingsT('countOfGames')}: {gamesCount}
                            </Typography>
                          </Box>
                          <p className={styles.actions}>
                            <Box sx={{ mr: 3 }}>
                              {isMobile ? (
                                <InstallMobileIcon sx={{ mr: 3, color: 'primary.main' }} />
                              ) : null}
                              {isDesktop ? (
                                <InstallDesktopIcon sx={{ color: 'primary.main' }} />
                              ) : null}
                            </Box>
                            <Button
                              id={id}
                              onClick={(e) => handleOnClick(e, title, MODE.EDIT_CONTENT)}
                            >
                              <Game sx={{ color: 'primary.main' }} className={styles.icon} />
                            </Button>
                            <Button id={id} onClick={(e) => handleOnClick(e, title, MODE.EDIT)}>
                              <EditIcon sx={{ color: 'primary.main' }} className={styles.icon} />
                            </Button>
                            <Button id={id} onClick={(e) => handleOnClick(e, title, MODE.DELETE)}>
                              <DeleteForeverIcon
                                sx={{ color: common.mainwarning }}
                                className={styles.icon}
                              />
                            </Button>
                          </p>
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </StrictModeDroppable>
          </DragDropContext>
          <Dialogs
            id={activeItem.id}
            title={t(dialog.WANT_DELETE)}
            content={`Menu item "${activeItem.title}"`}
            btnText="delete"
            isOpen={open}
            onClose={() => setOpen(false)}
            onConfirm={handleOnDelete}
          />
          <Box className={styles.actions_btn}>
            {(success || error) && <Result message={success || error} type={type} isShow />}
            <Button
              onClick={handleOnSave}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={!orderRef.current.hasOrdered}
            >
              {t('save')}
            </Button>
          </Box>
        </>
      )}
      {openModal && (
        <Modal open={openModal} onClose={handleClose} sx={{ minWidth: 500 }}>
          <Box
            sx={{
              ...style,
              bgcolor: `${theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff'}`,
            }}
          >
            <IconClose onClose={handleClose} />
            {mode === 'add' ? (
              <CreateCategory
                onClose={handleModalClose}
                title={siteSettingsT('addCategory')}
                siteId={siteId}
              />
            ) : (
              <EditCategory
                onClose={handleModalClose}
                title={siteSettingsT('editCategory')}
                siteId={siteId}
                item={activeItem}
              />
            )}
          </Box>
        </Modal>
      )}
    </Box>
  );
}

export default SitesCategoryItems;
