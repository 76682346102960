import { GridValueGetterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import FilterField from 'components/filter/columnFilter';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { IFilterData } from 'types/accounts';
import { columnsName, common, dialog, ROW_PER_PAGE } from 'constants/common';
import moment from 'moment';
import { dateFormat, isEmpty, makeQueryUrl } from 'utils/utils';
import { Box, Button, Modal, TablePagination, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setResetAll } from 'redux/actions/commonActions';
import Dialogs from 'components/dialog/dialogs';
import LoaderWithBg from 'components/loader/loader';
import SitesList from 'components/filter/siteLists';
import { IconClose } from 'components/closeButton/closeButton';
import { usePromoCodes } from 'hooks/sites/usePromoCodes';
import CreateMultiUserPromotion from 'components/modal/createMultiUserPromotion';
import { style } from 'components/modal/modal';
import { updatePromoCodes } from 'redux/actions/sitesActions';
import { FIELD_NAME, MODE } from 'types/sites';
import ListPromoTable from './listTable';
import styles from 'components/tables/table.module.scss';

interface IActivePromo {
  id: string;
}

export default function MultiUserPromoCodeTable() {
  const { t } = useTranslation('common');
  const { t: promoCodesT } = useTranslation('promoCodes');
  const theme = useTheme();
  const title = `${t(common.CREATE)} ${promoCodesT('multiuserPromocodes')}`;
  const {
    isLoading,
    columns: columnNames,
    totalItems,
    getAllMultiUserPromoCode,
    deletePromoCode,
  } = usePromoCodes();
  const [openModal, setOpenModal] = useState(false);
  const [activeItem, setActiveItem] = useState<IActivePromo>({ id: '' });
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROW_PER_PAGE);
  const [filterData, setFilterData] = useState<IFilterData | object>({});
  const [sortData, setSortData] = useState<IFilterData | object>({});
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState('');
  const promotionPromo = useSelector<any>((state) => state.promotionPromo) as any;

  const handleOnDelete = async (id: string) => {
    const result = await deletePromoCode(id);
    dispatch(updatePromoCodes(result.data));
  };

  const onButtonClick = (id: string, action: string) => {
    if (action === MODE.DELETE) {
      setOpen(true);
    } else {
      setOpenModal(true);
    }
    setActiveItem({ id });
    setMode(action);
  };

  const handleClose = () => {
    setOpenModal(false);
    setMode('');
  };

  const handleOpen = () => {
    setOpenModal(true);
    setMode(MODE.CREATE);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleFilter = (name: string, value: string) => {
    setFilterData((filterby) => ({ ...filterby, [name]: value }));
  };

  const handleSortBy = (sortBy: string, sortDir: string) => {
    setSortData({ [sortBy]: sortDir });
  };

  const handleResetAll = () => {
    dispatch(setResetAll(true));
    setFilterData({});
    setSortData({});
  };

  const generateDinamicColumns = useMemo(() => {
    const columns = columnNames.map((el) => {
      if (el === columnsName.CREATED_AT) {
        return {
          field: el,
          minWidth: el.length * 6 + 110,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isCamelCase
            />
          ),
          valueGetter: (params: GridValueGetterParams) => dateFormat(params.row.createdAt),
        };
      } else if (el === columnsName.USE_COUNT) {
        return {
          field: el,
          minWidth: el.length * 6 + 100,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => <FilterField fieldName={el} isDisabled />,
          valueGetter: (params: GridValueGetterParams) =>
            `${params.row.useCount === 0 ? t(common.NO) : params.row.useCount}`,
        };
      } else if (el === columnsName.END_DATE) {
        return {
          field: el,
          minWidth: el.length * 6 + 120,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isCamelCase
            />
          ),
          valueGetter: (params: GridValueGetterParams) =>
            params.row.endDate ? `${moment(params.row.endDate).format('DD/MM/Y HH:mm:ss')}` : '',
        };
      } else if (el === columnsName.SITE) {
        return {
          field: el,
          minWidth: el.length * 6 + 140,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isDisabled
            />
          ),
          valueGetter: (params: GridValueGetterParams) => params.row.site.name,
        };
      } else if (el === columnsName.CURRENCY) {
        return {
          field: el,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isDisabled
            />
          ),
          valueGetter: (params: GridValueGetterParams) => params.row.currency.code,
        };
      } else if (el === columnsName.ACCOUNT) {
        return {
          field: el,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isDisabled
            />
          ),
          valueGetter: (params: GridValueGetterParams) => params.row.account.login,
          // `${params.row.isActive ? t(common.YES) : t(common.NO)}`,
        };
      }
      return {
        field: el,
        width: el === columnsName.ID ? el.length * 6 + 100 : el.length * 6 + 140,
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => (
          <FilterField
            fieldName={el}
            onBlur={(value) => handleFilter(el, value)}
            onSort={handleSortBy}
            sortData={sortData}
          />
        ),
      };
    });

    const actionFields = columns.length
      ? [
          {
            field: t('action'),
            headerName: t('action'),
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params: GridValueGetterParams) => (
              <Button
                sx={{ display: 'flex' }}
                fullWidth
                variant="contained"
                onClick={() => onButtonClick(params.row.id, 'delete')}
              >
                {t('delete')}
              </Button>
            ),
          },
          {
            field: t('details'),
            headerName: '',
            width: 150,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params: GridValueGetterParams) => {
              if (params.row.useCount !== 0) {
                return (
                  <Button
                    sx={{ display: 'flex' }}
                    fullWidth
                    variant="contained"
                    onClick={() => onButtonClick(params.row.id, 'details')}
                  >
                    {t('details')}
                  </Button>
                );
              }

              return '';
            },
          },
        ]
      : [];

    return [...columns, ...actionFields];
  }, [columnNames, sortData, promotionPromo]);

  const columns = generateDinamicColumns as GridColDef[];

  useEffect(() => {
    const query = makeQueryUrl(filterData);
    const sortBy = Object.entries(sortData)[0];

    let paramsQuery = isEmpty(sortData)
      ? query
      : `${query}&sortBy=${sortBy[0]}&sortDir=${sortBy[1]}`;

    paramsQuery += `&page=${page + 1}&pageSize=${rowsPerPage}`;
    getAllMultiUserPromoCode(paramsQuery);
  }, [filterData, sortData, page, rowsPerPage]);

  return (
    <div style={{ width: '100%' }}>
      <>
        <Box className={styles.filter_box}>
          <div className={styles.filter_btn}>
            {Object.values(filterData).join() || Object.values(sortData).join() ? (
              <Button sx={{ mr: 3 }} variant="contained" onClick={handleResetAll}>
                {t('ressetAll')}
              </Button>
            ) : null}
            <Button sx={{ m: 3 }} variant="contained" onClick={handleOpen}>
              {title}
            </Button>
          </div>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ mr: 6 }}>
            <SitesList onSelectSite={handleFilter} initValue={isEmpty(filterData)} />
          </Box>
        </Box>
        {isLoading ? (
          <LoaderWithBg isOpen={isLoading} />
        ) : (
          <>
            <TablePagination
              component="div"
              count={totalItems}
              page={page}
              labelRowsPerPage={t('rowsPerPage')}
              labelDisplayedRows={({ from, to, count }) =>
                `${from >= to ? to : from}-${to} ${t('of')} ${count}`
              }
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <DataGrid
              autoHeight
              rows={promotionPromo}
              columns={columns}
              hideFooterPagination={true}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    isUsed: false,
                    user: false,
                  },
                },
              }}
              sx={{
                '.MuiDataGrid-iconButtonContainer': {
                  visibility: 'visible',
                },
                '.MuiDataGrid-sortIcon': {
                  opacity: 'inherit !important',
                },
                '.MuiDataGrid-cell': {
                  '&:last-child': {
                    justifyContent: 'center !important',
                  },
                },
                '.MuiDataGrid-footerContainer': {
                  display: 'none',
                },
              }}
            />
          </>
        )}
        {openModal && (
          <Modal open={openModal} onClose={handleClose} sx={{ minWidth: 400 }}>
            <Box
              sx={{
                ...style,
                bgcolor: `${
                  theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff'
                }`,
              }}
            >
              {mode === MODE.CREATE ? (
                <CreateMultiUserPromotion onClose={handleClose} mode={mode} />
              ) : (
                <ListPromoTable id={activeItem.id} type={FIELD_NAME.PROMO_USERS} />
              )}
              <IconClose onClose={handleClose} />
            </Box>
          </Modal>
        )}
        <Dialogs
          id={activeItem.id}
          title={t(dialog.WANT_DELETE)}
          content={''}
          isOpen={open}
          onClose={() => setOpen(false)}
          btnText={t('yes')}
          onConfirm={handleOnDelete}
        />
      </>
    </div>
  );
}
