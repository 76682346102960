import { GridValueGetterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import FilterField from 'components/filter/columnFilter';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useMemo, useState } from 'react';
import { columnsName, common, ROW_PER_PAGE } from 'constants/common';
import moment from 'moment';
import { TablePagination, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { useMessage } from 'hooks/message/useMessage';
import LoaderWithBg from 'components/loader/loader';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from '@material-ui/core';

export default function MultiUserPromocodesList() {
  const { t: promoCodesT } = useTranslation('promoCodes');
  const { t } = useTranslation('common');
  const theme = useTheme();
  const navigate = useNavigate();

  /**TODO remove useMessage('24') -> when backend be ready */
  const { isLoading, columns: columnNames, totalItems } = useMessage('24');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROW_PER_PAGE);
  const { messages } = useSelector<any>((state) => state.clients) as any;

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const generateDinamicColumns = useMemo(() => {
    const columns = columnNames.map((el) => {
      if (el === columnsName.CREATED_AT) {
        return {
          field: el,
          width: Math.ceil(window.innerWidth / (columnNames.length + 1)),
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField fieldName={columnsName.CREATED.toLocaleUpperCase()} isDisabled />
          ),
          valueGetter: (params: GridValueGetterParams) =>
            `${moment(params.row.createdAt).format('DD/MM/Y HH:mm:ss')}`,
        };
      } else if (el === columnsName.IS_USED) {
        return {
          field: el,
          width: 150,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => <FilterField fieldName={el} isDisabled />,
          valueGetter: (params: GridValueGetterParams) =>
            `${params.row.isUsed ? common.YES : common.NO}`,
        };
      } else if (el === columnsName.USER) {
        return {
          field: el,
          width: 150,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => <FilterField fieldName={el} isDisabled />,
          renderCell: (params: GridValueGetterParams) => (
            <Link
              component="a"
              variant="body2"
              onClick={() => {
                navigate(`/clients/details/${params.row.id}`, {
                  state: { login: params.row.name, site: '' },
                });
              }}
              target="blank"
            >
              {params.row.login}
            </Link>
          ),
        };
      }
      return {
        field: el,
        width:
          el === columnsName.ID ? 100 : Math.ceil(window.innerWidth / (columnNames.length + 1)),
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => <FilterField fieldName={el.toLocaleUpperCase()} isDisabled />,
      };
    });

    return [...columns];
  }, [columnNames, messages]);

  const columns = generateDinamicColumns as GridColDef[];

  return (
    <div style={{ width: '100%' }}>
      {isLoading ? (
        <LoaderWithBg isOpen={isLoading} />
      ) : (
        <>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            labelRowsPerPage={t('rowsPerPage')}
            labelDisplayedRows={({ from, to, count }) =>
              `${from >= to ? to : from}-${to} ${t('of')} ${count}`
            }
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <DataGrid
            autoHeight
            rows={messages}
            columns={columns}
            hideFooterPagination={true}
            sx={{
              '.MuiDataGrid-iconButtonContainer': {
                visibility: 'visible',
              },
              '.MuiDataGrid-sortIcon': {
                opacity: 'inherit !important',
              },
              '.MuiDataGrid-cell': {
                '&:last-child': {
                  justifyContent: 'center !important',
                },
              },
              '.MuiDataGrid-footerContainer': {
                display: 'none',
              },
            }}
          />
        </>
      )}
    </div>
  );
}
