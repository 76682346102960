import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ClientMainInfo from './clientInfoPage';
import ClientMessages from 'components/pageLayouts/client/clientMessagesPage';
import { ClientFinances } from './clientFinancesPage';
import ClientsHistory from './clientHistoryPage';
import GamblingSettingsForm from 'components/clientGamblingSettingsForm/gamblingSettingsForm';
import GameHistoryTable from 'components/tables/client/clientsGameHistroy';
import { ClientFreeSpins } from './clientFreeSpins';
import { useTranslation } from 'react-i18next';
import NotFound from 'components/notFound/notFound';
import ClientsDublicates from './clientDublicatePage';
import { useClients } from 'hooks/clients/useClients';
import { CircularProgress, IconButton, useTheme } from '@mui/material';
import { SMTH_WENT_WRONG } from 'constants/common';
import { tabPermissionsVertical } from 'permissions/tabs';
import { useSelector } from 'react-redux';
import { BalanceTransactionsHistory } from './financeSettings/transactionsHistory/transactionsHistory';
import common from 'styles/common.module.scss';
import styles from './clientInfoPage.module.scss';
import { grey } from '@mui/material/colors';
import { STORAGE_KEYS } from 'utils/storageService';
import BonusesTable from 'components/tables/finance/bonusesTable';

type TabContent = {
  [key: string]: JSX.Element;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      style={{ width: index === 3 ? '90vw' : '100%' }}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: '0 24px', flexBasis: '60%' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ClientDetailsMainPage() {
  const theme = useTheme();
  const { t: clientsT } = useTranslation('clients');
  const { t: clientDetailsT } = useTranslation('clientsDetails');
  const openedTabValue = sessionStorage.getItem(STORAGE_KEYS.TAB_VALUE) ?? '0';

  const params = useParams();
  const [value, setValue] = useState(+openedTabValue);
  const [currentClientId, setCurrentClientId] = useState('');
  const { isLoading, getClientById, error } = useClients();
  const { role: mainRole } = useSelector((state: any) => state.siteRole) as { role: '' };
  const user = useSelector<any>((state) => state.currentUser) as any;

  const tabContent: TabContent = React.useMemo(
    () => ({
      mainInfo: <ClientMainInfo />,
      financialSettings: <ClientFinances clientId={currentClientId} />,
      gameHistory: <GameHistoryTable clientId={currentClientId} />,
      messagesAndConversation: <ClientMessages clientId={currentClientId} />,
      gamblingSettings: <GamblingSettingsForm clientId={currentClientId} />,
      authorizationHistory: <ClientsHistory clientId={currentClientId} />,
      balanceChangeHistory: <BalanceTransactionsHistory clientId={currentClientId} />,
      freeSpins: <ClientFreeSpins clientId={currentClientId} />,
      dublicates: <ClientsDublicates clientId={currentClientId} />,
      wheelBonuses: <ClientsDublicates clientId={currentClientId} />,
      bonuses: <BonusesTable clientId={currentClientId} />,
    }),
    [currentClientId],
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    sessionStorage.setItem(STORAGE_KEYS.TAB_VALUE, String(newValue));
  };

  const getData = async (clientId: string) => {
    await getClientById(clientId);
  };

  useEffect(() => {
    if (params.clientId) {
      setCurrentClientId(params.clientId);
      getData(params.clientId);
    }
  }, [params.clientId]);

  if (isLoading)
    return (
      <Box className={styles.content}>
        <CircularProgress />
      </Box>
    );

  if (error === user.USER_NOT_FOUND || error === SMTH_WENT_WRONG) return <NotFound />;

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          bgcolor: `${theme.palette.mode === 'dark' ? '#344266' : grey[300]}`,
          padding: '0 6px',
          mb: 1,
        }}
      >
        <Typography component="h2">
          {clientDetailsT('client')}: {user?.login} ( {clientDetailsT('id')}:{user?.id},{' '}
          {clientDetailsT('siteId')}: {user?.site?.id} )
          <IconButton
            sx={{ ml: 1, svg: { color: 'text.primary' } }}
            title="copy to clipboard"
            onClick={() => {
              navigator.clipboard.writeText(
                `Client: ${user.login} ( id:${user?.id}, siteId: ${user?.site} )`,
              );
            }}
          >
            <ContentCopyIcon color="success" />
          </IconButton>
        </Typography>
      </Box>
      <Box sx={{ width: '100%', display: 'flex', alignItems: 'start' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs orientation="vertical" variant="scrollable">
            {tabPermissionsVertical.userDetails.map((el, index) => {
              if (el.role?.includes(mainRole) || el.role?.[0] === '*') {
                const innerstyle = el.style
                  ? { ...el.style, alignItems: 'start' }
                  : { alignItems: 'start', textAlign: 'left' };
                return (
                  <Tab
                    value={value}
                    onClick={(e) => handleChange(e, el.index)}
                    key={index}
                    label={clientsT(el.name)}
                    {...a11yProps(el.index)}
                    sx={
                      value === el.index
                        ? { ...innerstyle, color: common.mainblue }
                        : { ...innerstyle }
                    }
                  />
                );
              }
            })}
          </Tabs>
        </Box>

        {tabPermissionsVertical.userDetails.map((el, index) => {
          if (el.role?.includes(mainRole) || el.role?.[0] === '*') {
            return (
              <CustomTabPanel key={index} value={value} index={index}>
                {tabContent[el.name]}
              </CustomTabPanel>
            );
          }
        })}
      </Box>
    </>
  );
}
