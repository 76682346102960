import { useEffect, useState } from 'react';
import { generateHeaders } from 'utils/utils';
import { useStorage } from '../authHooks/useStorage';
import PermissionsService from 'services/permission.service';
import { IEntities } from 'types/permissions';
import { useApiErrors } from 'hooks/errors/useApiErrors';

export const usePermissionManagment = (id: string) => {
  const { logout, userToken } = useStorage();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<IEntities[] | []>([]);
  const { errorHandler } = useApiErrors();
  const [error, setError] = useState('');
  const initHeaders = generateHeaders(userToken());

  const getAllEntities: any = async (id: string, headers = initHeaders) => {
    if (id) {
      setIsLoading(true);
      try {
        const {
          data: { data },
        } = await PermissionsService.getAll(id, headers);
        setData(data);
        setIsLoading(false);
      } catch (err: any) {
        const {
          response: {
            data: { message, error, status },
          },
        } = err;
        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          logout();
        } else if (!needLogout && newHeader) {
          return await getAllEntities(id, newHeader);
        } else {
          console.log('LOG____>', message);
          setError(error);
        }
      }
    }
  };

  useEffect(() => {
    if (id) getAllEntities(id);
  }, [id]);

  const savePermissions: any = async (body: any, headers = initHeaders) => {
    setIsLoading(true);

    try {
      const {
        data: { data, status },
      } = await PermissionsService.update(headers, body);
      if (status === 200) {
        setIsLoading(false);

        setData(data);
        return { status };
      }
    } catch (err: any) {
      const {
        response: {
          data: { status, message },
        },
      } = err;
      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await savePermissions(body, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    }
  };

  const deletePermissions: any = async (id: string, headers = initHeaders) => {
    setIsLoading(true);

    try {
      const {
        data: { data, status },
      } = await PermissionsService.delete(headers, id);
      if (status === 200) {
        setIsLoading(false);
        setData(data);
        return { status };
      }
    } catch (err: any) {
      const {
        response: {
          data: { status, message },
        },
      } = err;
      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await deletePermissions(id, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    }
  };

  return {
    isLoading,
    error,
    data,
    savePermissions,
    deletePermissions,
    resetError: () => setError(''),
  };
};
