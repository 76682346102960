import http from '../http-common';

class AccountDataService {
  create = async (headers, body) => {
    /**TODO: write wrapper function for all request, due to avoiding nested data property */
    const { data:{data}, status } = await http.post('/account', body, {headers});
    return {...data, status};
  };

  getAllByRole = (headers, role) => {
    return http.get(`/account/all/${role}`, { headers });
  };

  getAllById = (headers, id) => {
    return http.get(`/account/${id}`, { headers });
  };

  editAccount = (headers, id, body) => {
    return http.put(`/account/${id}`, body, { headers });
  };
  
  getAccountByQuery = (headers, role, query = '') => {
    return http.get(`/account/all/${role}?${query}`, { headers });
  };
}

const AccountService = new AccountDataService();

export default AccountService;
