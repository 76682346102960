import {
  Statistics,
  Sites,
  Game,
  Clients,
  Managment,
  Telegram,
  Yammer,
  Massages,
  Finance,
  SubClientsList,
  SubClientsSearch,
  SubClientsOnline,
  SubClientsFinger,
  SubFinanceSavings,
  SubFinanceMonetization,
  SubFinanceRedeem,
  SubFinanceAdd,
  SubManagmentSupport,
  SubManagmentRecord,
  SubManagmentHelp,
  SubManagmentManage,
  SubGameSports,
  SubGameSignal,
  SubGameettings,
  SubTelegramSms,
  SubTelegramCurrency,
  SubPromoCodeMultiUser,
  SubPromoCodePromotion,
  PromoCode,
  SubGameSettingProvider,
  SubGameSettingGame,
  CashBacks
} from './index';


export const primaryMenu = [
  { id: '1', menu: 'statistics', icon: Statistics, link: '/statistics', role: ['main'] },
  { id: '2', menu: 'sites', icon: Sites, link: '/sites', role: ['main', 'agent'] },
  {
    id: '3',
    menu: 'clients',
    icon: Clients,
    link: '',
    role: ['main', 'support','operator', 'agent'],
    subMenu: [
      {
        id: '3',
        menu: 'clientsList',
        icon: SubClientsList,
        link: '/clients',
        role: ['main', 'support', 'operator','agent'],
      },
      { id: '3', menu: 'clientsSearch', icon: SubClientsSearch, link: '', role: ['main', 'agent'] },
      { id: '3', menu: 'onlineClients', icon: SubClientsOnline, link: '', role: ['main', 'agent'] },
      {
        id: '3',
        menu: 'addBonusForClients',
        icon: SubClientsFinger,
        link: '/clients/addBonuses',
        role: ['main', 'agent'],
      },
      {
        id: '3',
        menu: 'awaitingIdentification',
        icon: SubClientsFinger,
        link: '/clients/identifications',
        role: ['main', 'agent'],
      },
    ],
  },
  {
    id: '4',
    menu: 'finance',
    icon: Finance,
    link: '',
    role: ['main', 'support','operator', 'agent'],
    subMenu: [
      {
        id: '4',
        menu: 'deposits',
        icon: SubFinanceSavings,
        link: '/finance/deposits',
        role: ['main', 'support', 'operator','agent'],
      },
      {
        id: '4',
        menu: 'withdrawals',
        icon: SubFinanceMonetization,
        link: '/finance/withdrawals',
        role: ['main', 'support','operator', 'agent'],
      },
      {
        id: '4',
        menu: 'bonuses',
        icon: SubFinanceRedeem,
        link: '/finance/bonuses',
        role: ['main', 'support', 'operator','agent'],
      },
      {
        id: '4',
        menu: 'finantialLogs',
        icon: SubFinanceMonetization,
        link: '/finance/logs',
        role: ['main', 'support','operator', 'agent'],
      },
      {
        id: '4',
        menu: 'paymentMethod',
        icon: SubFinanceAdd,
        link: '',
        role: ['main', 'support','operator', 'agent'],
      },
       {
        id: '4',
        menu: 'cashBack',
        icon: CashBacks,
        link: '/finance/cashbacks',
        role: ['main', 'support','operator', 'agent'],
      },
    ],
  },
  {
    id: '5',
    menu: 'management',
    icon: Managment,
    link: '',
    role: ['main'],
    subMenu: [
      {
        id: '5',
        menu: 'agents',
        icon: SubManagmentSupport,
        link: '/account/agent',
        role: ['main'],
      },
      {
        id: '5',
        menu: 'operators',
        icon: SubManagmentRecord,
        link: '/account/operator',
        role: ['main'],
      },
      {
        id: '5',
        menu: 'support',
        icon: SubManagmentHelp,
        link: '/account/support',
        role: ['main'],
      },
      {
        id: '5',
        menu: 'managers',
        icon: SubManagmentManage,
        link: '/account/manager',
        role: ['main'],
      },
    ],
  },
  {
    id: '6',
    menu: 'gamblingSystems',
    icon: Game,
    link: '',
    role: ['main', 'agent'],
    subMenu: [
      {
        id: '6',
        menu: 'gameSettings',
        icon: SubGameSettingGame,
        link: '/gambling/gameSettings',
        role: ['main', 'agent'],
      },
      {
        id: '6',
        menu: 'providerSettings',
        icon: SubGameSettingProvider,
        link: '/gambling/providerSettings',
        role: ['main', 'agent'],
      },
      { id: '6', menu: 'groupSettings', icon: SubGameettings, link: '', role: ['main'] },
      { id: '6', menu: 'wheelOfFortune', icon: SubGameSports, link: '', role: ['main'] },
      {
        id: '6',
        menu: 'statistics',
        icon: SubGameSignal,
        link: '/gambling/statistics',
        role: ['main'],
      },
      {
        id: '6',
        menu: 'gameNames',
        icon: SubGameSignal,
        link: '/gambling/gameNames',
        role: ['main', 'agent'],
      },
    ],
  },
  {
    id: '7',
    menu: 'telegram',
    icon: Telegram,
    link: '',
    role: ['main', 'agent'],
    subMenu: [
      {
        id: '7',
        menu: 'channels',
        icon: SubTelegramSms,
        link: '/telegram/channels',
        role: ['main','agent'],
      },
      {
        id: '7',
        menu: 'raffles',
        icon: SubTelegramCurrency,
        link: '/telegram/raffles',
        role: ['main', 'agent'],
      },
    ],
  },
  {
    id: '8',
    menu: 'promoCodes',
    icon: PromoCode,
    link: '',
    role: ['main', 'agent'],
    subMenu: [
      {
        id: '8',
        menu: 'promotionPromoCodes',
        icon: SubPromoCodePromotion,
        link: '/promocode/promotion',
        role: ['main','agent'],
      },
      {
        id: '8',
        menu: 'multiUserPromoCodes',
        icon: SubPromoCodeMultiUser,
        link: '/promocode/multiuser',
        role: ['main','agent'],
      },
    ],
  },
];

export const secondaryMenu = [
  { id: '9', menu: 'Complain', icon: Yammer, link: '', role: ['main'] },
  { id: '10', menu: 'Massages', icon: Massages, link: '', role: ['main'] },
];
