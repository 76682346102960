import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useState } from 'react';
import { isValidValue } from 'utils/helpers/inputHelper';
import { FIELD_NAME, IFormData } from 'types/signInForm';
import Result from 'components/alert/alert';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../services/auth.service';
import LoaderWithBg from '../loader/loader';
import { useStorage } from 'hooks/authHooks/useStorage';
import { routing } from 'constants/routing';
import { login } from 'constants/errors';
import { setRole } from 'redux/actions/loginActions';
import { useDispatch } from 'react-redux';

const initialData = {
  login: '',
  password: '',
  twoFactorToken: '',
};

const fields = {
  [FIELD_NAME.LOGIN]: true,
  [FIELD_NAME.PASSWORD]: true,
  [FIELD_NAME.CODE]: true,
};

export default function SignInForm() {
  const [formData, setFormData] = useState<IFormData>(initialData);
  const [apiError, setApiError] = useState<string>('');
  const [showLoader, setShowLoader] = useState(false);
  const [validFields, setValidFields] = useState({ ...fields });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setStorageData } = useStorage();

  const handleOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: string,
  ) => {
    const { value } = e.target;
    const data = { ...formData, [name]: value } as IFormData;
    if (apiError) setApiError('');
    setFormData(data);
  };

  const validate = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, name: string) => {
    const { value } = e.target;
    setValidFields((fields) => ({
      ...fields,
      [name]: isValidValue(value, name),
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setShowLoader(true);
    try {
      const {
        data: { data, status },
      } = await AuthService.login(formData);
      if (status === 200) {
        setShowLoader(false);
        navigate(data.account.role === 'main' ? routing.admin : routing.home);
        setStorageData({ ...data });
        dispatch(setRole(data.account.role));
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const {
        response: {
          data: { message },
        },
      } = error;
      console.log(message, 'message');
      setApiError(login[message]);
      setShowLoader(false);
    }
  };

  return (
    <>
      {showLoader && <LoaderWithBg isOpen={showLoader} />}
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Login"
              name="login"
              autoComplete="login"
              autoFocus
              onChange={(e) => handleOnChange(e, FIELD_NAME.LOGIN)}
              onBlur={(e) => validate(e, FIELD_NAME.LOGIN)}
              error={!validFields[FIELD_NAME.LOGIN]}
              helperText={validFields[FIELD_NAME.LOGIN] ? '' : login.INCORRECT_LENGTH}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              onChange={(e) => handleOnChange(e, FIELD_NAME.PASSWORD)}
              onBlur={(e) => validate(e, FIELD_NAME.PASSWORD)}
              error={!validFields[FIELD_NAME.PASSWORD]}
              helperText={validFields[FIELD_NAME.PASSWORD] ? '' : login.INCORRECT_LENGTH}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="code"
              label="Verification Code"
              type="text"
              onChange={(e) => handleOnChange(e, FIELD_NAME.CODE)}
              onBlur={(e) => validate(e, FIELD_NAME.CODE)}
              error={!validFields[FIELD_NAME.CODE]}
              helperText={validFields[FIELD_NAME.CODE] ? '' : login.INCORRECT_LENGTH}
            />
            {apiError && (
              <Result style={{ mb: 2, mt: 1 }} message={apiError} type={'error'} isShow />
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2, bgcolor: 'background.paper' }}
              disabled={
                !validFields[FIELD_NAME.LOGIN] ||
                !validFields[FIELD_NAME.PASSWORD] ||
                !validFields[FIELD_NAME.CODE]
              }
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
}
