import { Box } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

interface ILoader {
  isOpen: boolean;
  whithoutBg?: boolean;
}

export default function LoaderWithBg({ isOpen, whithoutBg }: ILoader) {
  return (
    <div>
      {whithoutBg ? (
        <Box sx={{ position: 'absolute', top: '50%', left: '50%' }}>
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isOpen}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </div>
  );
}
