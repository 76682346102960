import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import Result from 'components/alert/alert';
import { IManager } from 'hooks/accounts/useAccounts';
import SitesList from 'components/filter/siteLists';
import { useTranslation } from 'react-i18next';
import { useSites } from 'hooks/sites/useSites';
import common from 'styles/common.module.scss';
import { usePromoCodes } from 'hooks/sites/usePromoCodes';
import { isValidForPromo } from 'utils/helpers/inputHelper';
import { FIELD_NAME } from 'types/sites';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useGameProvidersSettings } from 'hooks/providers/useGameProvidersSettings';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

interface CreatePromotionPromocodeProps {
  mode: string;
  managerList: IManager[];
  onClose: () => void;
}

export const initialData = {
  accountId: '',
  amount: '',
  count: '',
  currencyId: '',
  isPromotion: false,
  gameSettingsList: [],
  endDate: null,
  wheelCustomSpinList: [],
  wheelCustomWinList: [],
  isCustomWheelEnabled: false,
  customWheelEndDate: null,
  weidger: null,
};

const style = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
};

const fields = {
  [FIELD_NAME.AMOUNT]: true,
  [FIELD_NAME.COUNT]: true,
  [FIELD_NAME.ADDITIONAL_BONUS]: true,
  [FIELD_NAME.PROMO_WEIDGER]: true,
};

const mocked_Wheels = [
  { id: '1', val: '50(win_50)' },
  { id: '2', val: '75(win_75)' },
];

function CreatePromotionPromocode({ onClose, mode, managerList }: CreatePromotionPromocodeProps) {
  const { t } = useTranslation('common');
  const { t: validationT } = useTranslation('validations');
  const { isLoading, getSiteById, siteCurrencies } = useSites();
  const { t: promoCodesT } = useTranslation('promoCodes');
  const [formData, setFormData] = useState<{ [x: string]: any }>({ ...initialData });
  const [selectedManager, setSelectedManager] = useState('');
  const [selectedSiteId, setSelectedSiteId] = useState('');
  const [providerSettingsList, setProviderSettingsList] = useState<any>([]);
  const [validFields, setValidFields] = useState({ ...fields });
  const { error, createPromotionPromoCodes } = usePromoCodes();
  const { getAllSettingsByGroup } = useGameProvidersSettings();

  const handleOnSwicthWheel = async (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    const { checked } = e.target;

    let data;
    if (!checked) {
      if (name === 'hasWheelBonus') {
        formData.wheelCustomWinList.length = 0;
      } else {
        formData.customWheelEndDate = null;
      }
      data = { ...formData, [name]: checked };
    } else {
      data =
        name === 'hasWheelBonus'
          ? { ...formData, wheelCustomWinList: [{ id: '' }], [name]: checked }
          : { ...formData, [name]: checked };
    }

    setFormData(data);
  };

  const handleOnChangeDateTime = (valueFrom: Dayjs | null, name: string) => {
    const data = { ...formData, [name]: valueFrom };
    setFormData(data);
  };

  const handleOnSelectSettings = (e: SelectChangeEvent, providerId: string) => {
    const { value } = e.target;

    const findedItem = providerSettingsList?.filter((el: any) => +el.providerId === +providerId);

    if (findedItem?.length && findedItem[0]?.groups) {
      formData?.gameSettingsList.push({
        providerId,
        groups: [
          {
            groupId: value,
            settingId: findedItem[0]?.groups.filter((el: any) => +el.id === +value)?.[0]?.settingId,
          },
        ],
      });
    } else if (findedItem[0]?.groups === null) {
      formData?.gameSettingsList.push({
        providerId,
        groups: null,
        settings: [
          {
            settingId: providerId,
            id: value,
          },
        ],
      });
    }
    setFormData({ ...formData });
  };

  const renderAdditionalBonusList = () => {
    return (
      <>
        {formData.wheelCustomSpinList.length
          ? formData.wheelCustomSpinList.map((el: any, index: number) => {
              return (
                <TextField
                  key={index}
                  required
                  type="number"
                  value={el}
                  fullWidth
                  sx={{ width: '150px', mr: 2 }}
                  margin="normal"
                  name="amount"
                  label={`${promoCodesT('day')} ${index + 1}`}
                  variant="standard"
                  onChange={(e) => handleOnChangeDay(e, index)}
                />
              );
            })
          : null}
      </>
    );
  };

  const renderWheelBonusList = () => {
    return (
      <>
        {formData.wheelCustomWinList.length
          ? formData.wheelCustomWinList?.map((el: any, index: number) => {
              return (
                <Box
                  sx={{ minWidth: 200, display: 'flex', alignItems: 'center', m: 2 }}
                  key={index}
                >
                  <FormControl fullWidth size="small">
                    <InputLabel id="wheel-label">{promoCodesT('amountBonus')}</InputLabel>
                    <Select
                      required
                      labelId="wheel-label"
                      id="wheel-select"
                      label={promoCodesT('amountBonus')}
                      value={el.id}
                      onChange={(e) => handleOnSelectWheel(e, index)}
                    >
                      <MenuItem value="">
                        <em>{t('none')}</em>
                      </MenuItem>
                      {mocked_Wheels?.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.id}>
                            {item.val}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <DeleteForeverIcon
                    sx={{
                      color: common.mainwarning,
                      cursor: 'pointer',
                      ml: 1,
                    }}
                    onClick={() => removeItem(index)}
                  />
                </Box>
              );
            })
          : null}
      </>
    );
  };

  const renderProviderSettings = (name: string, providerId: string, settings: any[]) => {
    const filteredSEttings = formData.gameSettingsList.filter(
      (el: any) => +el.providerId === +providerId,
    )?.[0];
    const value = filteredSEttings?.groups
      ? filteredSEttings?.groups[0].groupId
      : filteredSEttings?.settings[0].id;
    return (
      <Box
        sx={{
          m: 2,
          minWidth: 200,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        <Typography>{name}:</Typography>
        <FormControl
          sx={{
            minWidth: '180px',
          }}
          size="small"
        >
          <InputLabel id="setting-label">{promoCodesT('gameSettings')}</InputLabel>
          <Select
            required
            labelId="setting-label"
            id="setting-select"
            label={promoCodesT('gameSettings')}
            value={value}
            onChange={(e) => handleOnSelectSettings(e, providerId)}
          >
            <MenuItem value="">
              <em>{t('none')}</em>
            </MenuItem>
            {settings.map((item, index) => {
              return (
                <MenuItem key={index} value={item.id}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    );
  };

  const validate = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, name: string) => {
    const { value } = e.target;

    setValidFields((fields) => ({
      ...fields,
      [name]: isValidForPromo(value, name) || value === '',
    }));
  };

  const handleFilter = async (name: string, id: string) => {
    if (id) {
      await getSiteById(id);
    }
    setSelectedSiteId(id);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const body = { ...formData };
    delete body.hasWheelBonus;
    body.gameSettingsList = formData.gameSettingsList.map((el: any) => {
      if (!el.groups) return { groupId: null, settingId: el.settings[0].id };
      return { ...el.groups[0] };
    });

    const result = await createPromotionPromoCodes(body, selectedSiteId);
    if (result?.status === 201) {
      onClose();
    }
  };

  const addWheelAmount = () => {
    const data = { ...formData, wheelCustomWinList: [...formData.wheelCustomWinList, { id: '' }] };
    setFormData(data);
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: string,
  ) => {
    const { value } = e.target;

    let data;
    if (name === 'additionalBonus' && value === '') {
      formData.wheelCustomSpinList.length = 0;
      data = { ...formData };
    } else {
      data =
        name === 'additionalBonus' && +value > 0
          ? { ...formData, wheelCustomSpinList: Array(+value).fill('') }
          : { ...formData, [name]: value };
    }
    setFormData(data);
  };

  const handleOnChangeDay = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    index: number,
  ) => {
    const { value } = e.target;
    formData.wheelCustomSpinList[index] = value;
    console.log(formData, 'wheelCustomSpinList');
    setFormData({ ...formData });
  };

  const handleOnSelectManager = (e: SelectChangeEvent) => {
    const { value } = e.target;
    const data = { ...formData, accountId: value };
    setFormData(data);
    setSelectedManager(value);
  };

  const handleOnSelectWheel = (e: SelectChangeEvent, index: number) => {
    const { value } = e.target;
    formData.wheelCustomWinList[index].id = mocked_Wheels.filter((el) => el.id === value)?.[0].id;
    setFormData({ ...formData });
  };

  const removeItem = (index: number) => {
    formData.wheelCustomWinList.splice(index, 1);
    setFormData({ ...formData });
  };

  const handleOnRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = event.target;

    const data = { ...formData, currencyId: id };
    setFormData(data);
  };

  const getData = async (siteId: string) => {
    const result = await getAllSettingsByGroup(siteId);
    if (result) {
      setProviderSettingsList(result.data);
    }
  };

  useEffect(() => {
    if (!isLoading) getData(selectedSiteId);
  }, [selectedSiteId]);

  return (
    <>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        {t(mode)} {promoCodesT('promocode')}
      </Typography>
      <Box sx={style}>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
          <Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                required
                type="number"
                value={formData.amount ?? ''}
                fullWidth
                margin="normal"
                name="amount"
                label={promoCodesT('amount')}
                variant="standard"
                onChange={(e) => handleOnChange(e, 'amount')}
                onBlur={(e) => validate(e, FIELD_NAME.AMOUNT)}
                error={!validFields[FIELD_NAME.AMOUNT]}
                helperText={
                  validFields[FIELD_NAME.AMOUNT] ? '' : validationT('greaterThan', { value: 0 })
                }
              />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                margin="normal"
                type="number"
                id="promoWeidger"
                label={promoCodesT('weidger')}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ width: '300px' }}
                defaultValue={formData.weidger}
                onChange={(e) => handleOnChange(e, FIELD_NAME.PROMO_WEIDGER)}
                onBlur={(e) => validate(e, FIELD_NAME.PROMO_WEIDGER)}
                error={!validFields[FIELD_NAME.PROMO_WEIDGER]}
                helperText={
                  validFields[FIELD_NAME.PROMO_WEIDGER]
                    ? ''
                    : validationT('greaterThan', { value: 1 })
                }
              />
            </Box>
            <Box sx={{ mb: 4 }}>
              <TextField
                required
                type="number"
                value={formData.count ?? ''}
                fullWidth
                margin="normal"
                name="count"
                label={promoCodesT('count')}
                variant="standard"
                onChange={(e) => handleOnChange(e, 'count')}
                onBlur={(e) => validate(e, FIELD_NAME.COUNT)}
                error={!validFields[FIELD_NAME.COUNT]}
                helperText={
                  validFields[FIELD_NAME.COUNT] ? '' : validationT('greaterThan', { value: 0 })
                }
              />
              <Box sx={{ mt: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']}>
                    <DateTimePicker
                      label={promoCodesT('endDatePromo')}
                      value={formData?.wheelGlobalEndDate ?? null}
                      ampm={false}
                      onChange={(newValue) => handleOnChangeDateTime(newValue, 'endDate')}
                      disablePast
                      slotProps={{
                        textField: { size: 'small', required: true },
                        layout: {
                          sx: {
                            textTransform: 'capitalize',
                            color: 'white',
                          },
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Box>
            </Box>
            <Box sx={{ minWidth: 200 }}>
              <FormControl fullWidth size="small">
                <InputLabel id="manager-label">{promoCodesT('selectManager')}</InputLabel>
                <Select
                  required
                  labelId="manager-label"
                  id="manager-select"
                  label={promoCodesT('selectManager')}
                  value={selectedManager}
                  onChange={handleOnSelectManager}
                >
                  <MenuItem value="">
                    <em>{t('none')}</em>
                  </MenuItem>
                  {managerList?.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.id}>
                        {item.login}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ mb: 2, mt: 2 }}>
              <SitesList onSelectSite={handleFilter} width={'100%'} />
              {siteCurrencies.length && selectedSiteId ? (
                <Box
                  sx={{
                    display: 'flex',
                    input: { margin: 0 },
                    width: '100%',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  {siteCurrencies.map((el: any, index: number) => {
                    return (
                      <Box sx={{ mr: 1, display: 'flex', alignItems: 'center' }} key={index}>
                        <input
                          type="radio"
                          name="gameOfDay"
                          id={el.id}
                          onChange={handleOnRadioChange}
                        />
                        <Typography variant="subtitle1" sx={{ ml: 1, fontSize: '13px' }}>
                          {el.code}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              ) : selectedSiteId ? (
                <Typography
                  variant="subtitle1"
                  color={common.mainwarning}
                  sx={{ ml: 2, fontSize: '13px', opacity: 0.8 }}
                >
                  {promoCodesT('noCurrencyHas')}
                </Typography>
              ) : null}
              <Box>
                {providerSettingsList.length
                  ? providerSettingsList.map((el: any) => {
                      return renderProviderSettings(
                        el.providerName,
                        el.providerId,
                        !el.groups ? el.settings : el.groups,
                      );
                    })
                  : null}
              </Box>
            </Box>

            <Box sx={{ mb: 4, display: 'flex', alignItems: 'center' }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.isCustomWheelEnabled}
                      onChange={(e) => handleOnSwicthWheel(e, 'isCustomWheelEnabled')}
                    />
                  }
                  label={promoCodesT('whiteList')}
                />
              </FormGroup>
              {formData.isCustomWheelEnabled ? (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']}>
                    <DateTimePicker
                      label={promoCodesT('endDate')}
                      value={formData.customWheelEndDate}
                      ampm={false}
                      onChange={(newValue) =>
                        handleOnChangeDateTime(newValue, 'customWheelEndDate')
                      }
                      disablePast
                      slotProps={{
                        textField: { size: 'small' },
                        layout: {
                          sx: {
                            textTransform: 'capitalize',
                            color: 'white',
                          },
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              ) : null}
            </Box>
            <Box sx={{ mb: 4 }}>
              <TextField
                type="number"
                value={
                  formData.wheelCustomSpinList.length ? formData.wheelCustomSpinList.length : ''
                }
                fullWidth
                margin="normal"
                name="count"
                label={promoCodesT('additionalBonus')}
                variant="standard"
                onChange={(e) => handleOnChange(e, 'additionalBonus')}
                onBlur={(e) => validate(e, FIELD_NAME.ADDITIONAL_BONUS)}
                error={!validFields[FIELD_NAME.ADDITIONAL_BONUS]}
                helperText={
                  validFields[FIELD_NAME.ADDITIONAL_BONUS]
                    ? ''
                    : validationT('greaterThan', { value: 0 })
                }
              />
              <Typography align="left" sx={{ fontSize: '12px' }}>
                {promoCodesT('additionalBonusSubText')}
              </Typography>
              {formData.wheelCustomSpinList.length ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                  {renderAdditionalBonusList()}
                </Box>
              ) : null}
            </Box>
            <Box sx={{ mb: 4, display: 'flex', alignItems: 'center' }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.hasWheelBonus}
                      onChange={(e) => handleOnSwicthWheel(e, 'hasWheelBonus')}
                    />
                  }
                  label={promoCodesT('addWheelBonus')}
                />
              </FormGroup>

              {formData.hasWheelBonus ? (
                <Button variant="contained" sx={{ mt: 3, mb: 2 }} onClick={addWheelAmount}>
                  {t('add')}
                </Button>
              ) : null}
            </Box>
            {formData.hasWheelBonus ? <Box sx={{ mb: 4 }}>{renderWheelBonusList()}</Box> : null}
          </Box>
          {error && <Result style={{ mb: 2 }} message={error} type={'error'} isShow />}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: 2,
            }}
          >
            <Button
              fullWidth
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={
                !selectedSiteId ||
                !formData.currencyId ||
                !formData.accountId ||
                !validFields[FIELD_NAME.AMOUNT] ||
                !validFields[FIELD_NAME.PROMO_WEIDGER] ||
                !validFields[FIELD_NAME.COUNT]
              }
            >
              {t(mode)}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default CreatePromotionPromocode;
