import { TextField, Button } from '@mui/material';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { FIELD_NAME } from 'types/sitesSettings';
import { useTranslation } from 'react-i18next';
import styles from './paymentSettings.module.scss';

const initialData = {
  merchant_id: '',
  secret_1: '',
  secret_2: '',
  apiKey: '',
};

const mock = {
  merchant_id: '25',
  secret_1: 'secret one',
  secret_2: 'secret two',
  apiKey: 'zaCELgL.0imfnc8mVLWwsAawjYr4Rx-Af50DDqtlx',
};

function FreeKassa() {
  const { t: siteSettingsT } = useTranslation('siteSettings');
  const [data, setData] = useState({ ...initialData, ...mock });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const submitData = { ...data };
    console.log(submitData, 'dataSubmit');
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: string,
  ) => {
    const { value } = e.target;
    const newData = { ...data, [name]: value };

    setData(newData);
  };

  return (
    <>
      <Box className={styles.main}>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            id="merchant_id"
            label={siteSettingsT('merchantId')}
            fullWidth
            required
            variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
            value={data.merchant_id}
            onChange={(e) => handleOnChange(e, FIELD_NAME.MERCHANT_ID)}
          />
          <TextField
            margin="normal"
            id="secret_1"
            label={siteSettingsT('secret1')}
            fullWidth
            required
            variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
            value={data.secret_1}
            onChange={(e) => handleOnChange(e, FIELD_NAME.SECRET_1)}
          />
          <TextField
            margin="normal"
            id="secret_2"
            label={siteSettingsT('secret2')}
            fullWidth
            required
            variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
            value={data.secret_2}
            onChange={(e) => handleOnChange(e, FIELD_NAME.SECRET_2)}
          />
          <TextField
            margin="normal"
            id="apiKey"
            label={siteSettingsT('apiKey')}
            fullWidth
            required
            variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
            value={data.apiKey}
            onChange={(e) => handleOnChange(e, FIELD_NAME.APIKEY)}
          />
          <Box className={styles.actions}>
            <Button fullWidth type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
              {siteSettingsT('saveSettings')}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default FreeKassa;
