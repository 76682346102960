import { TextField, Button, AlertColor } from '@mui/material';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoaderWithBg from 'components/loader/loader';
import Result from 'components/alert/alert';
import { SUCCESS } from 'constants/common';
import { useSitePercents } from 'hooks/sites/useSitePercents';
import styles from './paymentSettings.module.scss';

interface PaymentPercentageProps {
  siteId: string;
}

interface IPercent {
  id: number;
  name: string;
  sitePercent: number | string;
}

function PaymentPercentage({ siteId }: PaymentPercentageProps) {
  const { t } = useTranslation('common');
  const { t: siteSettingsT } = useTranslation('siteSettings');
  const { t: validationT } = useTranslation('validations');
  const { isLoading, error, getAllFinancePercents, updateFinancePercents } = useSitePercents();
  const [data, setData] = useState<IPercent[]>([]);
  const [notValidIdList, setNotValidIdList] = useState<string[]>([]);
  const [success, setSuccess] = useState('');
  const [type, setType] = useState<AlertColor>(SUCCESS);
  let timeOutId: ReturnType<typeof setTimeout>;

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!notValidIdList.length) {
      const submitData = { siteFinancePercents: [...data] };
      const result = await updateFinancePercents(siteId, submitData);

      if (result?.status === 200) {
        setSuccess(t('savedSuccess'));
        setType(SUCCESS);
        timeOutId = setTimeout(() => {
          setSuccess('');
        }, 1500);
      }
    }
  };

  const validate = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, id: string) => {
    const { value } = e.target;
    if (+value < 1) {
      if (!notValidIdList.includes(id)) {
        setNotValidIdList((data) => [...data, id])
      }
    } else {
      const index = notValidIdList.findIndex((el) => el === id)
      if (index != -1) {
        notValidIdList.splice(index, 1)
        setNotValidIdList([...notValidIdList])
      }
    }

  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    id: number,
  ) => {
    const { value } = e.target;
    const item = data?.filter(el => el.id === id);
    if (item?.length) {
      item[0].sitePercent = value;
    }
    setData([...data]);
  };

  const getData = async (id: string) => {
    const result = await getAllFinancePercents(id);
    if (result) {
      setData(result.data)
    }
  }

  useEffect(() => {
    getData(siteId)
    return () => {
      clearTimeout(timeOutId);
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <LoaderWithBg isOpen={isLoading} />
      ) : (
        <Box className={styles.main}>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, minWidth: '500px' }}>
            {data?.map((el: any, index: number) => {
              return (
                <TextField
                  key={index}
                  margin="normal"
                  id={el.name}
                  label={el.name}
                  fullWidth
                  required
                  variant="standard"
                  value={el.sitePercent}
                  onChange={(e) => handleOnChange(e, el.id)}
                  onBlur={(e) => validate(e, el.id)}
                  error={notValidIdList.includes(el.id)}
                  helperText={
                    !notValidIdList.includes(el.id)
                      ? ''
                      : validationT('greaterThan', { value: 1 })
                  }
                />
              );
            })}

            <Box className={styles.actions}>
              <Button fullWidth type="submit" variant="contained" sx={{ mt: 3, mb: 2 }} disabled={!!notValidIdList.length}>
                {siteSettingsT('saveSettings')}
              </Button>
            </Box>
            {(success || error) && (
              <Result style={{ mb: 2 }} message={success || error} type={type} isShow />
            )}
          </Box>
        </Box>
      )}
    </>
  );
}

export default PaymentPercentage;
