import { useStorage } from 'hooks/authHooks/useStorage';
import { useApiErrors } from 'hooks/errors/useApiErrors';
import { useState } from 'react';
import LanguageService from 'services/language.service';
import { generateHeaders } from 'utils/utils';

export interface ILanguage {
  id: number;
  name: string;
  code: string;
}
export const useSiteLanguage = () => {
  const { errorHandler } = useApiErrors();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [languageList, setLanguageList] = useState<ILanguage[] | []>([]);
  const [error, setError] = useState(null);

  const { logout, userToken } = useStorage();
  const initHeaders = generateHeaders(userToken());

  const getAllLanguages: any = async (headers = initHeaders) => {
    try {
      const {
        data: { data },
      } = await LanguageService.getAllLanguages(headers);
      setLanguageList(data);
      setIsLoading(false);
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;
      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getAllLanguages(newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    }
  };


  return { isLoading, error, languageList, getAllLanguages };
};
