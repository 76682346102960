import http from '../http-common';

class TransactionDataService {
  
  getAllTransactions = (headers, id, query='') => {
    return http.get(`/users/${id}/balance/transaction/all/?${query}`, { headers });
  };
   
  getAllPayOutTransactions = (headers, query='') => {
    return http.get(`/finance-providers/pay-out/transactions/all?${query}`, { headers });
  };

  getAllTransactionStatuses = (headers) => {
    return http.get(`/finance-providers/pay-out/transactions/actions`, { headers });
  };

  getAllReasons = (headers) => {
    return http.get(`/transaction/reason/all`, { headers });
  };

  updateClientTransactionStatus = (headers, id,  actionId) => {
    return http.post(`/finance-providers/pay-out/transactions/${id}/action/${actionId}`, {}, { headers });
  };

}

const TransactionService = new TransactionDataService();

export default TransactionService;
