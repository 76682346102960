import { useEffect, useState } from 'react';
import { generateHeaders, joinMessage } from 'utils/utils';
import { useStorage } from '../authHooks/useStorage';
import { useApiErrors } from 'hooks/errors/useApiErrors';
import TransactionService from 'services/transaction.service';
import BalanceService from 'services/balance.service';
import { useDispatch } from 'react-redux';
import { setTransactionActionsStatuses, setTransactionStatuses } from 'redux/actions/transactionActions';

interface IReason {
  id: string;
  text: string;
}

interface IBalance {
  id: string;
  name: string;
}
interface IStatus {
  id: string;
  value: string;
}

export const useBalances = (needBalance = false) => {
  const { errorHandler } = useApiErrors();
  const dispatch = useDispatch()
  const { logout, userToken } = useStorage();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [reasonsList, setReasonsList] = useState<IReason[]>([]);
  const [balanceList, setBalanceList] = useState<IBalance[]>([]);
  const [statusList, setStatusList] = useState<IStatus[]>([]);

  const initHeaders = generateHeaders(userToken());

  const getAllReasons: any = async (headers = initHeaders) => {
    try {
      const {
        data: {
          data: { balanceTransactionReasons },
          status,
        },
      } = await TransactionService.getAllReasons(headers);
      setReasonsList(balanceTransactionReasons);
      setIsLoading(false);
      return { status };
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getAllReasons(newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    }
  };

  const getAllBalance: any = async (headers = initHeaders) => {
    try {
      const {
        data: {
          data: { balances },
          status,
        },
      } = await BalanceService.getAllBalance(headers);
      setBalanceList(balances);
      setIsLoading(false);
      return { status };
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getAllBalance(newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    }
  };

  const getAllStatus: any = async (headers = initHeaders) => {
    try {
      const {
        data: {
          data: { transactionStatuses },
          status,
        },
      } = await BalanceService.getAllStatus(headers);
      dispatch(setTransactionStatuses(transactionStatuses))
      setStatusList(transactionStatuses);
      setIsLoading(false);

      return { status };
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getAllStatus(newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    }
  };

  const updateClientBalance: any = async (
    id: string,
    body: any,
    query: string,
    headers = initHeaders,
  ) => {
    try {
      const {
        data: { data, status },
      } = await BalanceService.updateClientBalance(headers, id, body, query);
      if (status === 200) {
        return { status };
      }
      /**TODO after ready BE */
      console.log(data, 'updateClientBalance');
      setIsLoading(false);
    } catch (err: any) {
      const {
        response: {
          data: { error, message, status },
        },
      } = err;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await updateClientBalance(id, body, query, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(joinMessage(error));
      }
    }
  };

  const updateBonusBalance: any = async (
    id: string,
    body: any,
    headers = initHeaders,
  ) => {
    try {
      const {
        data: { status },
      } = await BalanceService.updateClientBonus(headers, id, body);
      if (status === 201) {
        return { status };
      }

    } catch (err: any) {
      const {
        response: {
          data: { error, message, status },
        },
      } = err;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await updateBonusBalance(id, body, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(joinMessage(error));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getAllTransactionStatuses: any = async (headers = initHeaders) => {
    try {
      const {
        data: { data, status },
      } = await TransactionService.getAllTransactionStatuses(headers);
      if (status === 200) {
        dispatch(setTransactionActionsStatuses(data))
      }
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getAllTransactionStatuses(newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };


  const getData = async () => {
    const result = await getAllReasons();
    if (result?.status === 200) {
      const result = await getAllBalance();
      if (result?.status === 200) {
        await getAllStatus();
      }
    }
  };

  useEffect(() => {
    if (needBalance) {
      getData();
    }
  }, [needBalance]);

  return {
    getAllReasons,
    updateClientBalance,
    updateBonusBalance,
    getAllTransactionStatuses,
    reasonsList,
    balanceList,
    statusList,
    getAllStatus,
    isLoading,
    resetError: () => setError(''),
    error,
  };
};
