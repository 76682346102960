import { createContext, ReactNode, useState } from 'react';
import { localStorageService, STORAGE_KEYS } from 'utils/storageService';

interface IMainThemeContext {
  mode?: string;
  toggleDarkMode?: () => void;
}
const defaultMode = 'light';
/**TODO: review later */
// const defaultMode =
//   (localStorageService.read(STORAGE_KEYS.MODE)
//     ? localStorageService.read(STORAGE_KEYS.MODE)
//     : 'light') ?? 'light';

const MainThemeContext = createContext<IMainThemeContext>({ mode: defaultMode });

function MainThemeProvider({ children }: { children?: ReactNode }) {
  const [mode, setMode] = useState(defaultMode);
  const toggleDarkMode = () => {
    localStorageService.put(STORAGE_KEYS.MODE, toggle(mode));
    setMode((prev) => toggle(prev));
  };

  const toggle = (prevMode: string) => {
    if (prevMode === 'dark') return 'light';
    return 'dark';
  };

  return (
    <MainThemeContext.Provider value={{ mode, toggleDarkMode }}>
      {children}
    </MainThemeContext.Provider>
  );
}

export { MainThemeContext, MainThemeProvider };
