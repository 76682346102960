import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FiltersForAddBonuses from './filtersForAddBonuses';
import { useState } from 'react';
import SitesList from 'components/filter/siteLists';

function AddBonusesForClients() {
  const [chosenSiteId, setChosenSiteId] = useState('');
  const [resetVal, setResetVal] = useState(false);
  const { t: accountRolesT } = useTranslation('accountRoles');

  const handleFilter = (name: string, value: string) => {
    setChosenSiteId(value);
    setResetVal(false);
  };

  const resetFilter = () => {
    setResetVal(true);
    setChosenSiteId('');
  };

  return (
    <Box>
      <Box>
        <Typography variant="subtitle1" sx={{ mt: 2, pl: 1 }}>
          {accountRolesT('chooseTheSite')}
        </Typography>
        <Box sx={{ mr: 3, mt: 3 }}>
          <SitesList onSelectSite={handleFilter} initValue={resetVal ? 'reset' : undefined} />
        </Box>
      </Box>
      <FiltersForAddBonuses chosenSiteId={chosenSiteId} reset={resetFilter} />
    </Box>
  );
}

export default AddBonusesForClients;
