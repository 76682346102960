import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { IItem } from 'components/pageLayouts/gamblingSystems/statistics/gamblingStatistics';

interface CardImageProps {
  item: IItem;
  isPopular?: boolean;
}

export default function CardImage({ item, isPopular }: CardImageProps) {
  const theme = useTheme();

  return (
    <Card
      sx={{
        display: 'flex',
        marginBottom: '10px',
        maxHeight: '90px',
        color: '#000',
        bgcolor: `${theme.palette.mode === 'dark' ? theme.palette.background.paper : '#7679ab'}`,
      }}
    >
      <CardMedia component="img" sx={{ width: 151 }} image={item.img} alt="Game image" />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h5">
            {item.gameName}
          </Typography>
          {isPopular ? (
            <Typography variant="subtitle1" color="text.primary" component="div">
              Played Time: {item.playedCount}
            </Typography>
          ) : (
            <Typography variant="subtitle1" color="text.primary" component="div">
              Profit: {item.playedCount * 1.3}
            </Typography>
          )}
        </CardContent>
      </Box>
    </Card>
  );
}
