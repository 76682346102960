import http from '../http-common';

class ClientDataService {
  getClientById = (headers, id) => {
    return http.get(`/users/${id}`, { headers });
  };

  getAllClients = (headers, query = '') => {
    return http.get(`/users/all/?${query}`, { headers });
  };

  getClientHistory = (headers, id, query = '') => {
    return http.get(`/users/${id}/auth-history/?${query}`, { headers });
  };

  editClient = (headers, id, body) => {
    return http.put(`/users/${id}`, body, { headers });
  };

  getClientProfit = (headers, id, query = '') => {
    return http.get(`/users/${id}/profit?${query}`, { headers });
  };

  getClientGamesHistory = (headers, id, query = '') => {
    return http.get(`/users/${id}/session/all?${query}`, { headers });
  };

  updateConfirmedStatus = (headers, id, body) => {
    return http.put(`users/${id}`, body, { headers })
  }

  requiredVerification = (headers, id, body) => {
    return http.put(`users/${id}`, body, { headers })
  }

  getAllClientsByVerifyStatus = (headers, status, query = '') => {
    return http.get(`users/all?verificationStatus=${status}${query}`, { headers })
  }

  approveOrRejectVerification = (headers, id, status, body) => {
    return http.post(`/users/${id}/verification/${status}`, body, { headers })
  }

  closeUserGameSession = (headers, id, sessionId) => {
    return http.delete(`/users/${id}/session/${sessionId}`, { headers });
  };

  getClientSocialLinks = (headers, userId) => {
    return http.get(`/users/${userId}/social`, { headers })
  }

  addSocialLink = (headers, userId, type, body) => {
    return http.post(`/users/${userId}/social/${type}`, body, { headers })
  }

  removeSocialLink = (headers, linkId) => {
    return http.delete(`/users/social/${linkId}`, { headers })
  }

  saveClientGameSettings = (headers, userId,  body) => {
    return http.put(`/users/${userId}/game-settings`, body, { headers })
  }

  getClientGameSettings = (headers, userId) => {
    return http.get(`/users/${userId}/game-settings`, { headers });
  };

  getClientGameSettingsList = (headers, userId, query='') => {
    return http.get(`/users/${userId}/game-setting-changes?${query}`, { headers });
  };

  getClientAllBalanceChanges = (headers, id, query='') => {
    return http.get(`/users/${id}/balance-changes?${query}`, { headers });
  };
  
}

const ClientService = new ClientDataService();

export default ClientService;
