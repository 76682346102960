import http from '../http-common';

class LevelDataService {
  createLevel = async (headers, body) => {
    return await http.post(`/user-level/`, body, { headers });
  };

  getAllLevels = (headers, query = '') => {
    return http.get(`/user-level/all?${query}`, { headers });
  };

  getAllLevelsWithoutPagination = (headers) => {
    return http.get(`/user-level`, { headers })
  }

  getLevelById = (headers, id) => {
    return http.get(`/user-level/${id}`, { headers });
  };

  editLevel = (headers, id, body) => {
    return http.put(`/user-level/${id}`, body, { headers });
  };

  uploadLevelImage = (headers, id, body) => {
    return http.put(`/user-level/${id}/image`, body, { headers });
  };
}

const LevelService = new LevelDataService();

export default LevelService;
