import { GridValueGetterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import FilterField from 'components/filter/columnFilter';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { IFilterData } from 'types/accounts';
import { columnsName, ROW_PER_PAGE } from 'constants/common';
import moment from 'moment';
import { isEmpty, makeQueryUrl } from 'utils/utils';
import { Box, Button, Modal, TablePagination, useTheme } from '@mui/material';
import DropDownSelect from 'components/select/select';
import { useDispatch, useSelector } from 'react-redux';
import { setResetAll } from 'redux/actions/commonActions';
import { useLevels } from 'hooks/sites/useLevels';
import { IconClose } from 'components/closeButton/closeButton';
import { useTranslation } from 'react-i18next';
import { style } from 'components/modal/modal';
import ClientsLevelsCreateEdit from 'components/modal/clientsLevelsCreateEdit';
import { ILevel } from 'types/sites';
import styles from '../../table.module.scss';

interface ClientsLevelsProps {
  siteId: string;
}

export default function ClientsLevels({ siteId }: ClientsLevelsProps) {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    getAllLevels,
    isLoading,
    getLevelById,
    columns: columnNames,
    pageLists,
    totalItems,
  } = useLevels();

  const [page, setPage] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(ROW_PER_PAGE);
  const [resetGoToPage, setResetGoToPage] = useState(false);
  const [editedItem, setEditedItem] = useState<ILevel>({} as ILevel);
  const [filterData, setFilterData] = useState<IFilterData | object>({});
  const [sortData, setSortData] = useState<IFilterData | object>({});

  const levelsList = useSelector<any>((state) => state.levels) as ILevel[];

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (newPage >= +pageLists[pageLists.length - 1].val) {
      setPage(+pageLists[pageLists.length - 1].val);
    } else {
      setPage(newPage);
    }
    setResetGoToPage((prev) => !prev);
  };

  const handleFilter = (name: string, value: string) => {
    setFilterData((filterby) => ({ ...filterby, [name]: value }));
  };

  const handleSortBy = (sortBy: string, sortDir: string) => {
    setSortData({ [sortBy]: sortDir });
  };

  const handleResetAll = () => {
    getAllLevels();
    dispatch(setResetAll(true));
    setFilterData({});
    setSortData({});
  };

  const handleSelectPage = (page: string) => {
    setPage(+page - 1);
  };

  const getData = async (id: string) => {
    const result = await getLevelById(id);
    if (result?.status === 200) {
      setEditedItem({ ...result?.data });
      setOpenModal(true);
    }
  };

  const onButtonClick = (id: string, mode: string) => {
    if (mode === 'create') {
      setEditedItem({} as ILevel);
      setOpenModal(true);
    } else {
      getData(id);
    }
  };

  const handleClose = () => {
    setOpenModal(false);
    setEditedItem({} as ILevel);
  };

  const generateDinamicColumns = useMemo(() => {
    const columns = columnNames.map((el) => {
      if (el === columnsName.CREATED_AT) {
        return {
          field: el,
          width: Math.ceil(window.innerWidth / (columnNames.length + 1)),
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
            />
          ),
          valueGetter: (params: GridValueGetterParams) =>
            `${moment(params.row.createdAt).format('DD/MM/Y HH:mm:ss')}`,
        };
      }
      return {
        field: el,
        width: el === columnsName.ID ? 100 : 200,
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => (
          <FilterField
            fieldName={el}
            onBlur={(value) =>
              el === 'isBirthdayBonus'
                ? handleFilter(
                    el,
                    value === t('yes') ? 'true' : value === t('no') ? 'false' : value,
                  )
                : handleFilter(el, value)
            }
            onSort={handleSortBy}
            sortData={sortData}
            isCamelCase
          />
        ),
        valueGetter: (params: GridValueGetterParams): any =>
          el === 'isBirthdayBonus' ? (params.row[el] ? t('yes') : t('no')) : `${params.row[el]}`,
      };
    });

    const actionFields = columns.length
      ? [
          {
            field: 'edit',
            headerName: '',
            width: 180,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params: GridValueGetterParams) => (
              <Button
                fullWidth
                variant="contained"
                onClick={() => onButtonClick(params.row.id, 'edit')}
              >
                {t('edit')}
              </Button>
            ),
          },
        ]
      : [];

    return [...columns, ...actionFields];
  }, [columnNames, sortData, levelsList]);

  const columns = generateDinamicColumns as GridColDef[];

  useEffect(() => {
    const query = makeQueryUrl(filterData);
    const sortBy = Object.entries(sortData)[0];
    let paramsQuery = isEmpty(sortData)
      ? query
      : `${query}&sortBy=${sortBy[0]}&sortDir=${sortBy[1]}`;

    paramsQuery = paramsQuery
      ? `page=${page + 1}&pageSize=${rowsPerPage}` + paramsQuery
      : `page=${page + 1}&pageSize=${rowsPerPage}`;

    if (!isLoading) getAllLevels(paramsQuery);
  }, [filterData, sortData, page, rowsPerPage]);

  return (
    <>
      <Box style={{ width: '100%' }}>
        <Box className={styles.filter_box}>
          <Box className={styles.filter_btn}>
            <Box sx={{ mt: 2, mb: 2 }}>
              <Button variant="contained" onClick={() => onButtonClick('', 'create')}>
                {t('addLevels')}
              </Button>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <DropDownSelect
                totalPages={pageLists}
                onSelectPage={handleSelectPage}
                reset={resetGoToPage}
              />
              {Object.values(filterData).join() || Object.values(sortData).join() ? (
                <Button
                  className={styles.reset_all_btn}
                  variant="contained"
                  onClick={handleResetAll}
                >
                  {t('ressetAll')}
                </Button>
              ) : null}
            </Box>
          </Box>
        </Box>
        <TablePagination
          sx={{ mt: 2, mb: 2 }}
          component="div"
          count={totalItems}
          page={page}
          labelRowsPerPage={t('rowsPerPage')}
          labelDisplayedRows={({ from, to, count }) =>
            `${from >= to ? to : from}-${to} ${t('of')} ${count}`
          }
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <DataGrid
          autoHeight
          rows={levelsList}
          columns={levelsList.length ? columns : []}
          hideFooterPagination={true}
          initialState={{
            columns: {
              columnVisibilityModel: {
                // Hide column properties, the other columns will remain visible
                properties: false,
              },
            },
          }}
          sx={{
            '.MuiDataGrid-iconButtonContainer': {
              visibility: 'visible',
            },
            '.MuiDataGrid-sortIcon': {
              opacity: 'inherit !important',
            },
            '.MuiDataGrid-cell': {
              '&:last-child': {
                justifyContent: 'center !important',
              },
            },
            '.MuiDataGrid-footerContainer': {
              display: 'none',
            },
          }}
        />
        {openModal && (
          <Modal open={openModal} onClose={handleClose}>
            <Box
              sx={{
                ...style,
                width: 'calc(100%-600px)',
                minWidth: '500px',
                bgcolor: `${
                  theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff'
                }`,
              }}
            >
              <ClientsLevelsCreateEdit
                onClose={handleClose}
                mode={!isEmpty(editedItem) ? 'edit' : 'create'}
                item={editedItem}
                siteId={siteId}
              />
              <IconClose onClose={handleClose} />
            </Box>
          </Modal>
        )}
      </Box>
    </>
  );
}
