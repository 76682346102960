import { useState } from 'react';
import { generateHeaders } from 'utils/utils';
import { useStorage } from '../authHooks/useStorage';
import { useApiErrors } from 'hooks/errors/useApiErrors';
import { useDispatch } from 'react-redux';
import { addSocialLink, setSocialLinks, deleteSocialLink } from 'redux/actions/clientsActions';
import ClientService from 'services/client.service';

export const useClientSocial = () => {
  const { errorHandler } = useApiErrors();
  const { logout, userToken } = useStorage();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const initHeaders = generateHeaders(userToken());

  const getSocialLinksData: any = async (userId: string, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data },
        status,
      } = await ClientService.getClientSocialLinks(headers, userId);

      if (status === 200) {
        dispatch(setSocialLinks(data));
      }
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getSocialLinksData(userId, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const addNewSocialLink: any = async (
    userId: string,
    type: string,
    body: any,
    headers = initHeaders,
  ) => {
    try {
      setIsLoading(true);
      const {
        data: { data },
        status,
      } = await ClientService.addSocialLink(headers, userId, type, body);
      if (status === 201) {
        dispatch(addSocialLink(data));
        return { status };
      }
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await addNewSocialLink(userId, type, body, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const removeSocialLink: any = async (linkId: string, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data },
        status,
      } = await ClientService.removeSocialLink(headers, linkId);
      if (status === 200) {
        dispatch(deleteSocialLink(data));
      }
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await removeSocialLink(linkId, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    getSocialLinksData,
    addNewSocialLink,
    removeSocialLink,
    error,
  };
};
