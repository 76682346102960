export const tabList = [
  'tabMainSettings',
  'tabGameComposer',
  'tabWheelSettings',
  'tabGamblingSystem',
  'tabPaymentSettings',
  'tabDepositMethods',
  'tabWithdrawMethdods',
  'sitePercentage',
  'tabTerms',
  'clientsLevels',
  'tabBonuses',
  'tabAboutUs',
  'tabSitesSupport',
  'return',
  'paymentProviderSettings',
];
