import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { IItem } from 'components/pageLayouts/gamblingSystems/statistics/gamblingStatistics';
import style from './gameCard.module.scss';

interface CardImageProps {
  item: IItem;
  isPopular?: boolean;
}

export default function GameCard({ item }: CardImageProps) {
  const handleOnGameOfDay = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = event.target;
    /**TODO write logic for using id */
    console.log(id, 'id');
  };

  return (
    <Card className={style.card}>
      <Box sx={{ display: 'flex', input: { mr: 1 } }}>
        <input type="radio" name="gameOfDay" id={item.id} onChange={handleOnGameOfDay} />
        <Typography
          component="div"
          sx={{
            color: '#fff',
            m: 0.3,
          }}
        >
          {item.gameName}
        </Typography>
      </Box>
      <CardMedia
        component="img"
        sx={{ width: 151, height: 'inherit' }}
        image={item.img}
        alt="Game image"
      />
    </Card>
  );
}
