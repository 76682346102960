import { actionTypes } from 'redux/constants/actionTypes';

export const setBonusesData = (bonuses) => {
    return {
        type: actionTypes.SET_BONUSES_LIST,
        payload: bonuses,
    };
};

export const updateBonusData = (bonus) => {
    return {
        type: actionTypes.UPDATE_BONUS_DATA,
        payload: bonus,
    };
};

export const createdSiteBonus = (bonus) => {
    return {
        type: actionTypes.CREATED_BONUS,
        payload: bonus,
    };
};

export const deleteSiteBonus = (bonus) => {
    return {
        type: actionTypes.DELETE_BONUS_DATA,
        payload: bonus,
    };
};