import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useMemo, useState } from 'react';
import { FIELD_NAME } from 'types/sites';
import common from 'styles/common.module.scss';
import { useTranslation } from 'react-i18next';

interface AutoBonusProps {
  onChange?: (val: string, index: number, name: string) => void;
  onDelete?: (id: string) => void;
  onError?: (hasError: boolean, name: string) => void;
  item: IAutoBonus;
  index: number;
}

export const initialData = {
  id: '',
  amount: '',
  day: '',
};

export interface IAutoBonus {
  id: string;
  amount: string;
  bonusDay: string;
}

const style = {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center',
  margin: '16px 0',
  textAlign: 'left',
};

const fields = {
  [FIELD_NAME.AMOUNT]: true,
};

function AutoBonus({ onChange, item, index, onDelete, onError }: AutoBonusProps) {
  const { t } = useTranslation('common');
  const { t: validationT } = useTranslation('validations');
  const { t: siteSettingsT } = useTranslation('siteSettings');
  const [validFields, setValidFields] = useState({ ...fields });

  const generateSelect = useMemo(() => {
    const dayList = [];
    for (let i = 1; i <= 31; i++) {
      dayList.push({ id: i, name: i + '' });
    }
    return dayList;
  }, [item]);

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;
    onChange?.(value, index, 'amount');
  };

  const handleDayChange = (e: SelectChangeEvent) => {
    const { value } = e.target;
    onChange?.(value, index, 'bonusDay');
  };

  const handleOnDelete = (id: string) => {
    onDelete?.(id);
  };

  const validate = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, name: string) => {
    const { value } = e.target;

    setValidFields((fields) => ({
      ...fields,
      [name]: +value >= 0,
    }));
    onError?.(+value < 0, 'creditBonusAmount');
  };

  return (
    <Box sx={style}>
      <TextField
        margin="none"
        required
        name="phone"
        label={siteSettingsT('amount')}
        type="number"
        variant="standard"
        value={item.amount}
        onChange={handleOnChange}
        onBlur={(e) => validate(e, FIELD_NAME.AMOUNT)}
        error={!validFields[FIELD_NAME.AMOUNT]}
        helperText={validFields[FIELD_NAME.AMOUNT] ? '' : validationT('onlyPositive')}
      />
      <FormControl sx={{ maxWidth: '200px' }} fullWidth size="small">
        <InputLabel id="main-label">{siteSettingsT('monthDay')}</InputLabel>
        <Select
          labelId="main-label"
          id="main-label-select"
          label={siteSettingsT('monthDay')}
          value={item.bonusDay}
          onChange={handleDayChange}
        >
          <MenuItem value="">
            <em>{t('none')}</em>
          </MenuItem>
          {generateSelect.map((item, index) => {
            return (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Box>
        <DeleteForeverIcon
          onClick={() => handleOnDelete(item.id)}
          sx={{ color: common.mainwarning, fontSize: 28, cursor: 'pointer' }}
        />
      </Box>
    </Box>
  );
}

export default AutoBonus;
