import { useEffect, useState } from 'react';
import {
  AlertColor,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import cx from 'classnames';
import { useParams } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import { isEmpty } from 'utils/utils';
import Dialogs from 'components/dialog/dialogs';
import Result from 'components/alert/alert';
import { ERROR, SUCCESS, dialog } from 'constants/common';
import { usePermissionManagment } from 'hooks/permissionManagment/usePermissionManagment';
import { IEntities, IProperties } from 'types/permissions';
import LoaderWithBg from 'components/loader/loader';
import { useTranslation } from 'react-i18next';
import styles from './permissionPage.module.scss';

declare type FunctionA = (
  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  key: string,
) => void;

const protectionList = [
  { id: '1', name: 'start' },
  { id: '2', name: 'end' },
  { id: '3', name: 'all' },
];

const PROTECTION_TYPES = {
  CUT: 'cut',
  HIDE: 'hide',
  HASH: 'hash',
};

const needShow = (type: string, item: IProperties) =>
  item?.action?.protection === type && item?.action?.direction && item?.action?.direction !== 'all';

const generateValue = (type: string, item: IProperties) =>
  item?.action?.protection === type && item?.action?.direction ? item.action.direction : '';

const renderProtectionTypes = (item: IProperties, handleOnClick: FunctionA) => {
  return (
    <>
      <Box className={styles.protection_types} component="form">
        <TextField
          id={item.name}
          size="small"
          label="count"
          onChange={(e) => handleOnClick(e, 'count')}
          value={item?.action?.count}
        />
      </Box>
      <Box className={styles.protection_types} component="form">
        <TextField
          id={item.name}
          size="small"
          label="offset"
          onChange={(e) => handleOnClick(e, 'offset')}
          value={item?.action?.offset === 0 ? '' : item?.action?.offset}
        />
      </Box>
    </>
  );
};

const PermissionsPage = () => {
  const { t } = useTranslation('common');
  const theme = useTheme();
  let timeOutId: ReturnType<typeof setTimeout>;
  const params = useParams();
  const {
    data: allEntities,
    isLoading,
    savePermissions,
    deletePermissions,
    error,
    resetError,
  } = usePermissionManagment(params.id ?? '');
  const [activeEntity, setActiveEntity] = useState({} as IEntities);
  const [updatedItemIds, setUpdatedItemIds] = useState<number[]>([]);
  const [result, setResult] = useState('');
  const [type, setType] = useState<AlertColor>(SUCCESS);

  const [open, setOpen] = useState(false);
  const [deletedId, setDeletedId] = useState('');

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    key: string,
  ) => {
    const regex = /^[0-9\b]+$/;
    const { value } = e.target;
    const { id: name } = e.currentTarget;

    if (value === '' || regex.test(value)) {
      const findItem =
        activeEntity.properties.filter((el: IProperties) => el.name === name)?.[0] ?? {};
      if (!isEmpty(findItem)) {
        const findItemindex = activeEntity.properties.findIndex(
          (el: IProperties) => el.name === name,
        );
        const newItem = { ...findItem, action: { ...findItem.action, [key]: +value } };
        activeEntity.properties.splice(findItemindex, 1, newItem);

        const idList = [...updatedItemIds];
        idList.push(findItem.id);
        setUpdatedItemIds(idList);

        setActiveEntity({ ...activeEntity });
      }
    }
  };

  const handleOnChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    event.stopPropagation();
    if (!checked) {
      setOpen(true);
    }
  };

  const handleOnClose = () => {
    setResult('');
    resetError();
  };

  const removeAllPermisions = (id: string) => {
    const findItem = allEntities.filter((el: any) => el.id === +id)?.[0];
    const cloneItem = cloneDeep(findItem);
    cloneItem.isActionApplied = false;

    setActiveEntity({
      ...cloneItem,
      properties: cloneItem?.properties?.map((el) => ({ ...el, action: null })) ?? [],
    });
    setOpen(false);
    setDeletedId(id);
  };

  const onHandleSave = async () => {
    if (deletedId === '') {
      const filtered = activeEntity.properties.filter((el: IProperties) =>
        updatedItemIds.includes(el.id),
      );
      const actions = filtered.map((el) => ({
        protection: el.action?.protection,
        direction: el.action?.direction,
        count: el.action?.count ?? 0,
        propertyId: el.id,
        offset: el.action?.offset ?? 0,
      }));

      if (actions.length) {
        const res = await savePermissions({
          accountId: params.id,
          actions,
        });

        if (res?.status === 200) {
          setUpdatedItemIds([]);
          setResult(SUCCESS);
          setType(SUCCESS);
        }
      }
    } else {
      deletePermissions(deletedId);
    }
  };

  const handleSelectChange = (e: SelectChangeEvent, type: string, name: string) => {
    const { value } = e.target;
    let newItem;
    const findItem = activeEntity.properties.filter((el: any) => el.name === name)?.[0];
    const findItemindex = activeEntity.properties.findIndex((el: any) => el.name === name);

    if (value !== '') {
      if (findItem.action?.protection && type === findItem.action?.protection) {
        const action =
          value === 'all'
            ? { protection: findItem.action.protection, direction: value, count: null }
            : {
                protection: findItem.action.protection,
                direction: value,
                count: findItem.action.count,
              };
        newItem = { ...findItem, action };
      } else {
        newItem = { ...findItem, action: { protection: type, direction: value } };
      }
    } else {
      newItem = {
        ...findItem,
        action: { propertyId: findItem.id, protection: null, count: null, direction: null },
      };
    }
    activeEntity.properties[findItemindex] = newItem;
    const idList = [...updatedItemIds];
    idList.push(newItem.id);
    setUpdatedItemIds(idList);
    setActiveEntity({ ...activeEntity });
    setDeletedId('');
  };

  const handleOpenDetails = (id: number) => {
    const selectedItem = allEntities.filter((el) => el.id === id);
    setActiveEntity(cloneDeep(selectedItem[0]));
  };

  useEffect(() => {
    if (error) {
      setResult(error);
      setType(ERROR);
    }
  }, [error]);

  return (
    <Box className={styles.content}>
      <Box className={styles.permission_list}>
        {allEntities.map(({ id, name, isActionApplied }, index) => {
          return (
            <Box
              className={cx(styles.permission, { [styles.active]: id === activeEntity.id })}
              key={index}
              onClick={() => handleOpenDetails(+id)}
            >
              <Checkbox
                checked={isActionApplied}
                id={id.toString()}
                onChange={handleOnChecked}
                sx={{ '& .MuiSvgIcon-root': { fontSize: 16 }, m: 0, p: 0.5 }}
                inputProps={{ readOnly: true }}
                disabled={!isActionApplied}
              />
              <Typography component="p">{name}</Typography>
            </Box>
          );
        })}
      </Box>
      {isEmpty(activeEntity) || open ? (
        <Box sx={{ m: 3, fontSize: '16px' }}>Please, select an entity you want to manage...</Box>
      ) : (
        <>
          <Divider sx={{ m: 3 }} />
          <Box
            className={styles.permission_details}
            sx={{
              bgcolor: `${
                theme.palette.mode === 'dark' ? theme.palette.background.paper : '#1f274012'
              }`,
            }}
          >
            {activeEntity?.properties?.map((item: IProperties, index: number) => {
              return (
                <Box className={styles.entity} key={index}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>{item.name}</Box>
                  <Box className={styles.box}>
                    <FormControl className={styles.form} size="small">
                      <InputLabel>Hash</InputLabel>
                      <Select
                        value={generateValue(PROTECTION_TYPES.HASH, item)}
                        label="Hash"
                        onChange={(e) => handleSelectChange(e, PROTECTION_TYPES.HASH, item.name)}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {protectionList.map((item) => {
                          return (
                            <MenuItem key={item.id} value={item.name}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    {needShow(PROTECTION_TYPES.HASH, item)
                      ? renderProtectionTypes(item, handleChange)
                      : null}
                  </Box>
                  <Box className={styles.box}>
                    <FormControl className={styles.form} size="small">
                      <InputLabel>Cut</InputLabel>
                      <Select
                        value={generateValue(PROTECTION_TYPES.CUT, item)}
                        label="Cut"
                        onChange={(e) => handleSelectChange(e, PROTECTION_TYPES.CUT, item.name)}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {protectionList.map((item) => {
                          return (
                            <MenuItem key={item.id} value={item.name}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    {needShow(PROTECTION_TYPES.CUT, item)
                      ? renderProtectionTypes(item, handleChange)
                      : null}
                  </Box>
                  <Box className={styles.box}>
                    <FormControl className={styles.form} size="small">
                      <InputLabel>Hide</InputLabel>
                      <Select
                        value={generateValue(PROTECTION_TYPES.HIDE, item)}
                        label="Hide"
                        onChange={(e) => handleSelectChange(e, PROTECTION_TYPES.HIDE, item.name)}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {protectionList.map((item) => {
                          return (
                            <MenuItem key={item.id} value={item.name}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    {needShow(PROTECTION_TYPES.HIDE, item)
                      ? renderProtectionTypes(item, handleChange)
                      : null}
                  </Box>
                </Box>
              );
            })}
          </Box>

          <Box className={styles.action_save}>
            {result && (
              <Result
                style={{ mb: 2 }}
                message={result}
                type={type}
                isShow
                hasAction
                onClose={handleOnClose}
              />
            )}

            <Button variant="contained" sx={{ ml: 3 }} onClick={onHandleSave}>
              {t('save')}
            </Button>
          </Box>
        </>
      )}
      <Dialogs
        id={activeEntity?.id?.toString()}
        title={t(dialog.REMOVE_ALL_PERMISSIONS)}
        content={t(dialog.CONTENT_ALL_PERMISSIONS)}
        isOpen={open}
        onClose={() => setOpen(false)}
        onConfirm={removeAllPermisions}
        btnText={t('continue')}
      />
      {isLoading ? <LoaderWithBg isOpen={isLoading} /> : null}
    </Box>
  );
};

export default PermissionsPage;
