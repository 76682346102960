import { Box, Button, Modal, useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import FilterField from 'components/filter/columnFilter';
import { style } from 'components/modal/modal';
import { IconClose } from 'components/closeButton/closeButton';
import { isEmpty, makeQueryUrl } from 'utils/utils';
import { useTranslation } from 'react-i18next';
import { useSiteBonuses } from 'hooks/sites/useSiteBonuses';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { columnsName } from 'constants/common';
import { IFilterData } from 'types/accounts';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { IBonusCreating } from 'types/sitesSettings';
import CreateSiteBonus from './createBonus/siteBonus';

interface BonusesTableProps {
  siteId: string;
}

function BonusesTable({ siteId }: BonusesTableProps) {
  const theme = useTheme();
  const { t: siteSettingsT } = useTranslation('siteSettings');
  const { t } = useTranslation('common');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [editedItem, setEditedItem] = useState({});
  const [filterData, setFilterData] = useState<IFilterData | object>({});
  const [sortData, setSortData] = useState<IFilterData | object>({});
  const {
    getAllBonuses,
    getSiteBonusById,
    columns: columnNames,
    removeBonus,
    isLoading,
  } = useSiteBonuses();

  const bonusesList = useSelector<any>((state) => state.bonuses) as IBonusCreating[];

  const handleClose = () => {
    setEditedItem({});
    setOpenModal(false);
  };

  const getData = async (id: string) => {
    const result = await getSiteBonusById(siteId, id);
    if (result?.status === 200) {
      setEditedItem({ ...result?.data });
      setOpenModal(true);
    }
  };

  const onButtonClick = (id: string, mode: string) => {
    if (mode === 'create') {
      setEditedItem({});
      setOpenModal(true);
    } else if (mode === 'delete') {
      removeBonus(siteId, id);
    } else {
      getData(id);
    }
  };

  const handleFilter = (name: string, value: string) => {
    setFilterData((filterby) => ({ ...filterby, [name]: value }));
  };

  const handleSortBy = (sortBy: string, sortDir: string) => {
    setSortData({ [sortBy]: sortDir });
  };

  const generateDinamicColumns = useMemo(() => {
    const columns = columnNames.map((el) => {
      if (el === columnsName.CREATED_AT) {
        return {
          field: el,
          width: Math.ceil(window.innerWidth / (columnNames.length + 1)),
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isDisabled
            />
          ),
          valueGetter: (params: GridValueGetterParams) =>
            `${moment(params.row.createdAt).format('DD/MM/Y HH:mm:ss')}`,
        };
      }
      if (el === columnsName.LEVEL_GROUP) {
        return {
          field: el,
          width: Math.ceil(window.innerWidth / (columnNames.length + 1)),
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isDisabled
            />
          ),
          valueGetter: (params: GridValueGetterParams) => `${params.row.levelGroup.name}`,
        };
      }
      return {
        field: el,
        width: el === columnsName.ID ? 100 : 200,
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => (
          <FilterField
            fieldName={el}
            onBlur={(value) =>
              el === 'isAvailable'
                ? handleFilter(
                    el,
                    value === t('yes') ? 'true' : value === t('no') ? 'false' : value,
                  )
                : handleFilter(el, value)
            }
            onSort={handleSortBy}
            sortData={sortData}
            isDisabled
            isCamelCase
          />
        ),
        valueGetter: (params: GridValueGetterParams): any =>
          el === 'isAvailable' ? (params.row[el] ? t('yes') : t('no')) : `${params.row[el]}`,
      };
    });

    const actionFields = columns.length
      ? [
          {
            headerName: '',
            field: 'Edit',
            width: 180,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params: GridValueGetterParams) => (
              <Button
                fullWidth
                variant="contained"
                onClick={() => onButtonClick(params.row.id, 'edit')}
              >
                {t('edit')}
              </Button>
            ),
          },
          {
            headerName: '',
            field: 'Delete',
            width: 180,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params: GridValueGetterParams) => (
              <Button
                fullWidth
                variant="contained"
                onClick={() => onButtonClick(params.row.id, 'delete')}
              >
                {t('delete')}
              </Button>
            ),
          },
        ]
      : [];

    return [...columns, ...actionFields];
  }, [columnNames, sortData]);

  const columns = generateDinamicColumns as GridColDef[];

  useEffect(() => {
    const query = makeQueryUrl(filterData);
    const sortBy = Object.entries(sortData)[0];
    const paramsQuery = isEmpty(sortData)
      ? query
      : `${query}&sortBy=${sortBy[0]}&sortDir=${sortBy[1]}`;

    if (!isLoading) getAllBonuses(siteId, paramsQuery);
  }, [filterData, sortData]);

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          sx={{ mt: 3, mb: 2, mr: 4 }}
          onClick={() => onButtonClick('', 'create')}
        >
          {siteSettingsT('addSiteBonus')}
        </Button>
      </Box>
      <DataGrid
        autoHeight
        rows={bonusesList}
        columns={bonusesList.length ? columns : []}
        hideFooterPagination={true}
        sx={{
          '.MuiDataGrid-iconButtonContainer': {
            visibility: 'visible',
          },
          '.MuiDataGrid-sortIcon': {
            opacity: 'inherit !important',
          },
          '.MuiDataGrid-cell': {
            '&:last-child': {
              justifyContent: 'center !important',
            },
          },
          '.MuiDataGrid-footerContainer': {
            display: 'none',
          },
        }}
      />
      {openModal && (
        <Modal open={openModal} onClose={handleClose}>
          <Box
            sx={{
              ...style,
              width: 'calc(100%-600px)',
              minWidth: '500px',
              bgcolor: `${theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff'}`,
            }}
          >
            <CreateSiteBonus
              siteId={siteId}
              item={editedItem}
              mode={!isEmpty(editedItem) ? 'edit' : 'create'}
              onClose={handleClose}
            />
            <IconClose onClose={handleClose} />
          </Box>
        </Modal>
      )}
    </Box>
  );
}

export default BonusesTable;
