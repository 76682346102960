import { Box, Button, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useLabels } from 'hooks/clients/useLabels';
import Result from 'components/alert/alert';
import { ILabel } from 'types/clients';
import styles from './modal.module.scss';
import { useTranslation } from 'react-i18next';
import { minLength } from 'utils/validations/validationTypes';

interface LabelCreateEditProps {
  title?: string;
  mode: string;
  item?: ILabel;
  onClose: () => void;
}

export const initialData = {
  name: '',
};

const style = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
};

function LabelCreateEdit({ onClose, mode, item }: LabelCreateEditProps) {
  const { t: clientsT } = useTranslation('clients');
  const { t } = useTranslation('common');
  const { t: validationT } = useTranslation('validations');
  const [labelName, setLabelName] = useState(item?.name);
  const [formData, setFormData] = useState({ ...initialData });
  const [isValidName, setIsValidName] = useState(true);
  const { createLabel, editLabel, error } = useLabels();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isValidName) {
      try {
        let result;

        if (mode === 'edit') {
          result = await editLabel(item?.id, formData);

          console.log(result, 'result editLabel');
        } else {
          result = await createLabel(formData);
          console.log(result, 'result createLabel');
        }
        if (result?.status === 200 || result?.status === 201) {
          onClose();
        }
      } catch (error: any) {
        console.log(error, 'error');
      }
    }
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: string,
  ) => {
    const { value } = e.target;
    const data = { ...formData, [name]: value };
    setLabelName(value);
    setFormData(data);
  };

  const validate = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;
    setIsValidName(minLength(2)(value));
  };

  return (
    <>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        {t(mode)} {clientsT('label')}
      </Typography>
      <Box sx={style}>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
          <Box>
            <Box className={styles.textArea}>
              <TextField
                margin="normal"
                id="Name"
                label={clientsT('labelName')}
                fullWidth
                required
                variant="standard"
                value={labelName ? labelName : formData.name}
                onChange={(e) => handleOnChange(e, 'name')}
                onBlur={(e) => validate(e)}
                error={!isValidName}
                helperText={isValidName ? '' : validationT('minLength', { length: 3 })}
              />
            </Box>
          </Box>
          {error && <Result style={{ mb: 2 }} message={error} type={'error'} isShow />}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Button
              fullWidth
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={!isValidName}
            >
              {t(mode)} {clientsT('label')}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default LabelCreateEdit;
