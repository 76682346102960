import http from '../http-common';

class TermsDataService {
  getAllTerms = (headers, id) => {
    return http.get(`/site/${id}/terms`, { headers });
  };

  updateTermsData = (headers, id, body) => {
    return http.put(`/site/${id}/terms`, body, { headers });
  };
}

const TermsService = new TermsDataService();

export default TermsService;
