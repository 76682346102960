import http from '../http-common';

class ProviderSettingsDataService {

  createProviderSettings = (headers, body, providerId) =>
    http.post(`/game-providers/${providerId}/settings`, body, { headers });

  createProviderSubSettings = (headers, body, providerId, settingId) =>
    http.post(`/game-providers/${providerId}/settings/${settingId}/groups`, body, { headers });

  getProviderSettings = (headers, providerId, siteId) =>
    http.get(`/game-providers/${providerId}/settings?siteId=${siteId}`, { headers });

  getAllSettings = (headers, siteId = '', query = '') => {
    const siteIdQuery = siteId ? `siteId=${siteId}` : ''
    return http.get(`/game-providers/settings/all?${siteIdQuery}${query}`, { headers });
  }

  getAllSettingsByGroup = (headers, siteId = '') => {
    const siteIdQuery = siteId ? `siteId=${siteId}` : ''
    return http.get(`game-providers/settings/grouped?${siteIdQuery}`, { headers });
  }

  updateProviderSettings = (headers, providerId, settingId, body) =>
    http.put(`/game-providers/${providerId}/settings/${settingId}`, body, { headers });
}

const GameProviderSettingsService = new ProviderSettingsDataService();

export default GameProviderSettingsService;
