import { useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ProviderSetting from './providerSetting';
import { useProviders } from 'hooks/providers/useProviders';

interface GamblingSystemProps {
  siteId: string;
}

function GamblingSystem({ siteId }: GamblingSystemProps) {
  const { providersList } = useProviders();

  const [value, setValue] = useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              {providersList.map((el, index) => {
                return <Tab key={index} label={el.name} value={index + 1 + ''} />;
              })}
            </TabList>
          </Box>
          {providersList.map((el, index) => {
            return (
              <TabPanel key={index} value={index + 1 + ''}>
                <ProviderSetting providerId={el.id} siteId={siteId} />
              </TabPanel>
            );
          })}
        </TabContext>
      </Box>
    </>
  );
}

export default GamblingSystem;
