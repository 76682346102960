import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ILevelAdv } from 'components/modal/clientsLevelsCreateEdit';
import styles from './checkbox.module.scss';

interface LevelAdvantagesProps {
  idList?: number[];
  onChange?: (list: ILevelAdv[]) => void;
  list: ILevelAdv[];
}

export const LevelAdvantages = ({ list, onChange }: LevelAdvantagesProps) => {
  const { t } = useTranslation('siteSettings');
  const [properties, setProperties] = useState([...list]);

  const handleOnChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = +event.currentTarget.id;
    const { checked } = event.target;
    const findItem = properties.filter((el) => el.id === id)?.[0];
    if (findItem) {
      findItem.isActive = checked;
    }
    setProperties(properties);
    onChange?.(properties);
  };

  useEffect(() => {
    if (list.length) setProperties([...list]);
  }, [list]);

  return (
    <FormGroup sx={{ mt: 2, mb: 2 }} className={styles.lavels}>
      {properties?.map(({ key, id, isActive }, index) => {
        return (
          <FormControlLabel
            sx={{ minWidth: 'max-content' }}
            key={index}
            control={
              <Checkbox
                checked={isActive}
                id={id + ''}
                onChange={handleOnChecked}
                sx={{ '& .MuiSvgIcon-root': { fontSize: 16 }, m: 0, p: 0.5 }}
                inputProps={{ readOnly: true }}
              />
            }
            label={t(key)}
          />
        );
      })}
    </FormGroup>
  );
};
