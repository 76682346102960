import AccountsPage from 'components/pageLayouts/account/accountsPage';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuthentication } from 'hooks/authHooks/useAuthentication';
import { roleTypes } from 'types/accounts';
import AccountMenu from 'components/menu/accountMenu/accountMenu';
import MainMenu from 'components/menu/mainMenu';
import SitesPage from '../sites/sitesPage';
import { routing } from 'constants/routing';
import WelcomePage from '../welcome/welcomePageForAdmins';
import { routeSwitcher } from 'constants/common';
import SiteSettings from '../sites/settings/settingsPage';
import PermissionsPage from '../management/permissions/permissionsPage';
import Statistics from '../statistics/statistics';
import Channels from 'components/tables/channelsTable';
import Raffles from 'components/tables/rafflesTable';
import GamblingStatistics from '../gamblingSystems/statistics/gamblingStatistics';
import ClientsTable from 'components/tables/client/clientsTable';
import ClientDetailsPage from '../client/clientDetailsMainPage';
import LabelsTable from 'components/tables/client/labelsTable';
import { STORAGE_KEYS, localStorageService } from 'utils/storageService';
import Deposits from '../finance/deposits';
import Withdrawals from '../finance/withdrawals';
import Promotion from '../promocodes/promotion';
import MultiUser from '../promocodes/multiUser';
import MultiUserPromocodesList from 'components/tables/promocodes/multiUserPromocodesList';
import Bonuses from '../finance/bonuses';
import FinancialLogs from '../finance/financialLogs/finacialLogsPage';
import AddBonusesForClients from 'components/tables/client/addBonusesForClients';
import { AwaitIdentifications } from '../client/awaitIdentifications/identifications';
import GamblingGameSettings from '../gamblingSystems/gameSettings/gameSettings';
import GamblingProviderSettings from '../gamblingSystems/providerSettings/providerSettings';
import GameLogs from '../client/gameLogs/gameLogs';
import WelcomePageCommon from '../welcome/welcomePageCommon';
import CashBacks from '../finance/cashBacks';
import GamblingGameNames from '../gamblingSystems/gameNames/gameNames';

interface MainContainerProps {
  componentName?: string;
}

function MainContainer({ componentName }: MainContainerProps) {
  const [role, setRole] = useState<roleTypes>('');
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { hasToken } = useAuthentication();

  const renderContent = (component?: string) => {
    switch (component) {
      case routeSwitcher.ACCOUNT_PAGE:
        return <AccountsPage role={role} />;
      case routeSwitcher.SITE_PAGE:
        return <SitesPage />;
      case routeSwitcher.SITE_SETTINGS:
        return <SiteSettings />;
      case routeSwitcher.WELCOME_PAGE:
        return <WelcomePage />;
      case routeSwitcher.WELCOME_PAGE_COMMON:
        return <WelcomePageCommon />;
      case routeSwitcher.PERMISSON_MANAGMENT:
        return <PermissionsPage />;
      case routeSwitcher.STATISTICS:
        return <Statistics />;
      case routeSwitcher.CASHBACKS:
        return <CashBacks />;
      case routeSwitcher.CHANNEL:
        return <Channels />;
      case routeSwitcher.RAFFLE:
        return <Raffles />;
      case routeSwitcher.GAMBLING_STATISTICS:
        return <GamblingStatistics />;
      case routeSwitcher.GAME_SETTINGS:
        return <GamblingGameSettings />;
      case routeSwitcher.GAME_NAMES:
        return <GamblingGameNames />;
      case routeSwitcher.PROVIDER_SETTINGS:
        return <GamblingProviderSettings />;
      case routeSwitcher.CLIENT_LISTS:
        return <ClientsTable />;
      case routeSwitcher.ADD_BONUS:
        return <AddBonusesForClients />;
      case routeSwitcher.LABELS:
        return <LabelsTable />;
      case routeSwitcher.CLIENT_DETAILS:
        return <ClientDetailsPage />;
      case routeSwitcher.DEPOSIT:
        return <Deposits />;
      case routeSwitcher.BONUSES:
        return <Bonuses />;
      case routeSwitcher.WITHDRAWALS:
        return <Withdrawals />;
      case routeSwitcher.PROMOTION:
        return <Promotion />;
      case routeSwitcher.MULTIUSER:
        return <MultiUser />;
      case routeSwitcher.MULTIUSER_LIST:
        return <MultiUserPromocodesList />;
      case routeSwitcher.FINANCE_LOGS:
        return <FinancialLogs />;
      case routeSwitcher.GAME_LOGS:
        return <GameLogs />;
      case routeSwitcher.AWAIT_IDENTIFICATIONS:
        return <AwaitIdentifications />;
      default:
        null;
    }
  };

  const value = localStorageService.read(STORAGE_KEYS.IS_OPEN);
  const itemValue = localStorageService.read(STORAGE_KEYS.OPENED_ITEM_INDEX);
  const languageValue = localStorageService.read(STORAGE_KEYS.LANGUAGE_KEY);

  useEffect(() => {
    if (!hasToken) {
      navigate(routing.home);
    }
  }, []);

  useEffect(() => {
    const account = location.pathname.split('/');
    const role = account.length ? account[account.length - 1] : '';
    if (role) {
      setRole(role as roleTypes);
    }
  }, [params]);

  return (
    <>
      <AccountMenu languageValue={languageValue} />
      <MainMenu isOpen={value} openedItemIndex={itemValue}>
        {renderContent(componentName)}
      </MainMenu>
    </>
  );
}

export default MainContainer;
