import { Box, TextField, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { FIELD_NAME, ICreateSite } from 'types/sites';
import { useSites } from 'hooks/sites/useSites';
import Result from 'components/alert/alert';
import EmptyTextarea from 'components/textArea/textArea';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import UploadButtons from 'components/imageUpload/imageUpload';
import DropDown from 'components/channelDropdown/dropDown';
import { useTranslation } from 'react-i18next';
import styles from './modal.module.scss';

interface CreateRaffleProps {
  title: string;
  onClose: () => void;
}

export const initialData = {
  name: '',
  title: '',
  url: '',
  currencyIds: [],
  languageIds: [],
  isActive: true,
};

const style = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
};

function CreateRaffle({ title, onClose }: CreateRaffleProps) {
  const { t: telegramT } = useTranslation('telegram');
  const { t } = useTranslation('common');
  const [formData, setFormData] = useState<ICreateSite>({ ...initialData });
  const { error } = useSites();
  const handleOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: string,
  ) => {
    const { value } = e.target;
    const data = { ...formData, [name]: value };
    setFormData(data);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onClose();
  };

  const handleOnChangeTextArea = (val: string, key: string) => {
    const data = { ...formData, [key]: val };
    setFormData(data);
  };
  const handleOnSelect = (name: string, value: string) => {
    console.log({ name, value }, 'ggggg');
  };

  return (
    <>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        {title}
      </Typography>
      <Box sx={style}>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <DropDown onSelect={handleOnSelect} />
          <Box>
            <TextField
              fullWidth
              margin="normal"
              name="title"
              label={telegramT('title')}
              variant="standard"
              onChange={(e) => handleOnChange(e, 'title')}
            />
            <TextField
              fullWidth
              margin="normal"
              name="winCount"
              label={telegramT('winnersCount')}
              variant="standard"
              onChange={(e) => handleOnChange(e, 'winCount')}
            />
            <TextField
              fullWidth
              margin="normal"
              name="amount"
              label={telegramT('Amount')}
              variant="standard"
              onChange={(e) => handleOnChange(e, 'Amount')}
            />

            <Box className={styles.content_part}>
              <UploadButtons />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexBasis: '80%',
                  mb: 2,
                }}
              >
                <Typography sx={{ fontSize: '14px', opacity: '0.5' }}>
                  {telegramT('finishedDate')}
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer sx={{ borderColor: 'black' }} components={['DateTimePicker']}>
                    <DateTimePicker
                      defaultValue={null}
                      views={['year', 'month', 'day', 'hours', 'minutes']}
                      disablePast
                      ampm={false}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Box>
            </Box>

            <Box className={styles.textArea}>
              <Typography variant="subtitle1" sx={{ fontSize: '14px', mr: 2, opacity: '0.5' }}>
                {telegramT('text')}
              </Typography>
              <EmptyTextarea
                isModal
                defaultValue={''}
                onChange={(val) => handleOnChangeTextArea(val, FIELD_NAME.DISABLE_TEXT)}
              />
            </Box>
          </Box>
          {error && <Result style={{ mb: 2 }} message={error} type={'error'} isShow />}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Button fullWidth type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
              {t('add')}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default CreateRaffle;
