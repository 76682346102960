import {
  Box,
  TextField,
  Button,
  Typography,
  Switch,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import { useEffect } from 'react';
import { roleTypes } from 'types/accounts';
import { SiteCheckboxes } from 'components/checkbox/sites';
import { useUser } from 'hooks/user/useUser';
import { useTranslation } from 'react-i18next';
import Result from 'components/alert/alert';
import LoaderWithBg from 'components/loader/loader';
import styles from '../editAccount/editAccount.module.scss';

const style = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
};
interface DetailsAccountProps {
  currentId: string;
  closeModal: () => void;
  role: roleTypes | '';
}

function DetailsAccount({ currentId, closeModal, role }: DetailsAccountProps) {
  const { t: accountRolesT } = useTranslation('accountRoles');
  const { user, getUser, error, isLoading } = useUser(role);

  useEffect(() => {
    getUser(currentId);
  }, [currentId]);

  return (
    <Box sx={style}>
      {!isLoading ? (
        <>
          {accountRolesT('detailsUser')}:{currentId}
          <Box component="form" sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              id="login"
              label={accountRolesT('loginInput')}
              fullWidth
              variant="standard"
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              value={user?.login}
            />
            <TextField
              fullWidth
              margin="normal"
              name="password"
              label={accountRolesT('passwordInput')}
              type="password"
              variant="standard"
              value={user?.password}
              disabled
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              margin="normal"
              fullWidth
              name="phone"
              label={accountRolesT('phoneInput')}
              type="text"
              variant="standard"
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              value={user?.phone}
            />
            <TextField
              margin="normal"
              id="email"
              label={accountRolesT('emailInput')}
              fullWidth
              variant="standard"
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              value={user?.email}
            />
            <Box className={styles.active}>
              <FormGroup>
                <FormControlLabel
                  control={<Switch checked={!!user?.isActive} disabled />}
                  label={accountRolesT('active')}
                />
              </FormGroup>
            </Box>
            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              {accountRolesT('userSitesList')}
            </Typography>
            <Box className={styles.sites}>
              <SiteCheckboxes disabled siteIds={user?.siteIds} />
            </Box>
            {error && <Result style={{ mb: 2 }} message={error} type={'error'} isShow />}
            <Box className={styles.actions}>
              <Button fullWidth variant="contained" sx={{ mt: 3 }} onClick={closeModal}>
                {accountRolesT('closeDetails')}
              </Button>
            </Box>
          </Box>
        </>
      ) : (
        <LoaderWithBg isOpen={isLoading} />
      )}
    </Box>
  );
}

export default DetailsAccount;
