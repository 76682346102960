import { useState } from 'react';
import { generateHeaders, joinMessage } from 'utils/utils';
import { useStorage } from '../authHooks/useStorage';
import {
  prepareDataForSelectPages,
  prepareTableData,
  preparedColumnNames,
} from 'utils/helpers/dataHelper/prepareDataHelper';
import { useDispatch } from 'react-redux';
import { useApiErrors } from 'hooks/errors/useApiErrors';
import MessageService from 'services/message.service';
import { setMessagessData } from 'redux/actions/clientsActions';
import { IGotoPage } from 'types/sites';

export const useMessage = (id: string) => {
  const dispatch = useDispatch();
  const [error, setError] = useState<string>('');
  const { errorHandler } = useApiErrors();
  const { logout, userToken } = useStorage();
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [columns, setColumns] = useState<string[] | []>([]);
  const [messageList, setMessageList] = useState([]);
  const [pageLists, setPageLists] = useState<IGotoPage[] | []>([]);
  const initHeaders = generateHeaders(userToken());

  const getUserAllMessages: any = async (id: string, query = '', headers = initHeaders) => {
    try {
      const {
        data: {
          data: { userMessages, totalPages, totalItems },
        },
      } = await MessageService.getUserAllMessages(headers, id, query);
      let preparedData = [];
      let pageLists: any = [];
      if (userMessages.length) {
        preparedData = prepareTableData(userMessages, 'clients');
        pageLists = prepareDataForSelectPages(totalPages);
        const columns = preparedColumnNames(preparedData);
        setColumns(columns);
      }
      dispatch(setMessagessData(preparedData));
      setMessageList(messageList);
      setTotalPages(totalPages);
      setTotalItems(totalItems);
      setPageLists(pageLists as IGotoPage[]);
      setIsLoading(false);
    } catch (err: any) {
      const {
        response: {
          data: { error, message, status },
        },
      } = err;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getUserAllMessages(id, query, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(joinMessage(error));
      }
    }
  };
  
  const createUserMessage: any = async (
    body: { accountId: string; message: string },
    headers = initHeaders,
  ) => {
    try {
      const data = await MessageService.createUserMessage(headers, id, body);
      if (data.status === 201) {
        await getUserAllMessages(id);
        return { status: data.status };
      } else {
        setError('Something went wrong');
      }
    } catch (err: any) {
      const {
        response: {
          data: { error, message, status },
        },
      } = err;
      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await createUserMessage(body, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(joinMessage(error));
      }
    }
  };

  const editUserMessage: any = async (
    id: string,
    messageId: string,
    body: any,
    headers = initHeaders,
  ) => {
    try {
      const data = await MessageService.updateUserMessage(headers, id, messageId, body);
      if (data.status === 200) {
        getUserAllMessages(id);
        return { status: data.status };
      } else {
        setError('Something went wrong');
      }
    } catch (err: any) {
      const {
        response: {
          data: { error, message, status },
        },
      } = err;
      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await editUserMessage(id, messageId, body, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(joinMessage(error));
      }
    }
  };

  const deleteUserMessage: any = async (id: string, messageId: string, headers = initHeaders) => {
    try {
      const data = await MessageService.deleteUserMessage(headers, id, messageId);
      if (data.status === 200) {
        getUserAllMessages(id);
        return { status: data.status };
      } else {
        setError('Something went wrong');
      }
    } catch (err: any) {
      const {
        response: {
          data: { error, message, status },
        },
      } = err;
      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await deleteUserMessage(id, messageId, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(joinMessage(error));
      }
    }
  };

  return {
    getUserAllMessages,
    createUserMessage,
    deleteUserMessage,
    editUserMessage,
    pageLists,
    columns,
    isLoading,
    totalPages,
    messageList,
    totalItems,
    error,
  };
};
