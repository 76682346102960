import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import light from 'styles/light.module.scss';
import DateTimePickerValue from 'components/datePicker/dateTimeRangePicker';
import { useClientProfit } from 'hooks/clients/useClientProfit';
import { makeQueryUrl } from 'utils/utils';
import styles from './profitFromClient.module.scss';
import { useTranslation } from 'react-i18next';

interface ProfitFromClientProps {
  title: string;
  clientId: string;
}

interface IProfitFilterData {
  from: string;
  to: string;
}

export const ProfitFromClient = ({ title, clientId }: ProfitFromClientProps) => {
  const { t: clientDetailsT } = useTranslation('clientsDetails');
  const { getClientProfit, clientProfit, isLoading } = useClientProfit(clientId);
  const [filterData, setFilterData] = useState<IProfitFilterData | object>({
    from: dayjs().subtract(1, 'day'),
    to: dayjs(),
  });

  const handleFilterDate = (valueFrom: Dayjs | null, valueTo: Dayjs | null) => {
    setFilterData((filterby) => ({
      ...filterby,
      from: dayjs(valueFrom),
      to: dayjs(valueTo),
    }));
  };

  useEffect(() => {
    const query = makeQueryUrl(filterData);

    getClientProfit(clientId, query);
  }, [filterData]);

  return (
    <Box className={styles.main_content}>
      <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
        {title}
      </Typography>
      <DateTimePickerValue onShow={handleFilterDate} />

      <Box className={styles.result} sx={{ mt: 3, backgroundColor: light.main }}>
        {isLoading ? clientDetailsT('loading') : `${clientDetailsT('total')} ${clientProfit}`}
      </Box>
    </Box>
  );
};
