import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DepositForm from 'components/clientFinancialForms/balanceFrom';
import WithdrawForm from 'components/clientFinancialForms/balanceFrom';
import AnulationForm from 'components/clientFinancialForms/balanceFrom';
import TransferForm from 'components/clientFinancialForms/balanceFrom';
import { useBalances } from 'hooks/clients/useBalances';
import { useSelector } from 'react-redux';
import styles from './balanceSettings.module.scss';


export const BalanceSettings = () => {
  const { t: clientDetailsT } = useTranslation('clientsDetails');
  const { reasonsList, balanceList } = useBalances(true);
  const user = useSelector<any>((state) => state.currentUser) as any;

  return (
    <>
      <Box className={styles.main_content}>
        <DepositForm
          title={clientDetailsT('depositBalance')}
          btnTitle={'deposit'}
          action={'increase'}
          isBonus
          data={{ reasonsList, balanceList, user }}
        />
        <WithdrawForm
          title={clientDetailsT('withdrawFromBalance')}
          btnTitle={'withdraw'}
          action={'decrease'}
          data={{ reasonsList, balanceList, user }}
        />
        <AnulationForm
          title={clientDetailsT('partialAnulation')}
          btnTitle={'anulate'}
          action={'decrease'}
          data={{ reasonsList, balanceList, user }}
        />
        <TransferForm
          title={clientDetailsT('balanceTransfer')}
          btnTitle={'Transfer'}
          action={'increase'}
          isTransfer
          data={{ reasonsList, balanceList, user }}
        />
      </Box>
    </>
  );
};
