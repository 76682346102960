import {  useState } from 'react';
import { generateHeaders, joinMessage } from 'utils/utils';
import { useStorage } from '../authHooks/useStorage';
import {
  prepareDataForSelectPages,
  prepareTableData,
  preparedColumnNames,
} from 'utils/helpers/dataHelper/prepareDataHelper';
import { useDispatch } from 'react-redux';
import { useApiErrors } from 'hooks/errors/useApiErrors';
import { ILabel } from 'types/clients';
import LabelService from 'services/labels.service';
import { setLabelsData } from 'redux/actions/clientsActions';
import { IGotoPage } from 'types/sites';

export const useLabels = () => {
  const dispatch = useDispatch();
  const { errorHandler } = useApiErrors();
  const { logout, userToken } = useStorage();
  const [isLoading, setIsLoading] = useState(true);
  const [allLabels, setAllLabels] = useState<ILabel[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [labelsList, setLabelsList] = useState<ILabel[]>([]);
  const [columns, setColumns] = useState<string[] | []>([]);
  const [pageLists, setPageLists] = useState<IGotoPage[] | []>([]);
  const [error, setError] = useState('');

  const initHeaders = generateHeaders(userToken());

  const getAllLabels: any = async (query: string, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: {
          data: { userLabels, totalPages, totalItems },
        },
      } = await LabelService.getAllLabels(headers, query);

      const preparedData = prepareTableData(userLabels, 'labels');
      const columns = preparedColumnNames(preparedData);
      const pageLists = prepareDataForSelectPages(totalPages); 
      dispatch(setLabelsData(preparedData));
      setLabelsList(preparedData);
      setColumns(columns);
      setTotalPages(totalPages);
      setTotalItems(totalItems);
      setPageLists(pageLists as IGotoPage[]);
    } catch (err: any) {
      const {
        response: {
          data: { message, status, error},
        },
      } = err;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getAllLabels(query, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(joinMessage(error));
      }
    }finally{
      setIsLoading(false);
    }

  };

  const createLabel: any = async (body: any, headers = initHeaders) => {
    try {
      const data = await LabelService.createLabel(headers, body);
      if (data.status === 201) {
        getAllLabels();
      } else {
        setError('Something went wrong');
      }
      return { status: data.status };
    } catch (err: any) {
      const {
        response: {
          data: { error, message, status },
        },
      } = err;
      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await createLabel(body, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(joinMessage(error));
      }
    }
  };

  const editLabel: any = async (id: string, body: any, headers = initHeaders) => {
    try {
      const data = await LabelService.editLabel(headers, id, body);
      if (data.status === 200) {
        getAllLabels();
      } else {
        setError('Something went wrong');
      }
      return { status: data.status };
    } catch (err: any) {
      const {
        response: {
          data: { error, message, status },
        },
      } = err;
      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await editLabel(id, body, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(joinMessage(error));
      }
    }
  };

  const deleteLabel: any = async (id: string, headers = initHeaders) => {
    try {
      const data = await LabelService.deleteLabel(headers, id);
      if (data.status === 200) {
        getAllLabels();
        return { status: data.status };
      } else {
        setError('Something went wrong');
      }
    } catch (err: any) {
      const {
        response: {
          data: { error, message, status },
        },
      } = err;
      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await deleteLabel(id, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(joinMessage(error));
      }
    }
  };

  const getLabelsList: any = async (headers = initHeaders) => {
    setIsLoading(true);
    try {
      const {
        data: { data },
      } = await LabelService.getLabelsList(headers);
      dispatch(setLabelsData(data));
      setAllLabels(data);
      setIsLoading(false);
    } catch (err: any) {
      const {
        response: {
          data: { message, status, error },
        },
      } = err;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getLabelsList(newHeader);
      } else {
        console.log('LOG____>', message);
        setError(joinMessage(error));
      }
    }
  };

  return {
    getAllLabels,
    getLabelsList,
    allLabels,
    deleteLabel,
    createLabel,
    pageLists,
    columns,
    isLoading,
    totalPages,
    labelsList,
    editLabel,
    totalItems,
    error,
  };
};
