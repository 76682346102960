import {
  Box,
  TextField,
  Button,
  Typography,
  FormControlLabel,
  FormGroup,
  Switch,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useState } from 'react';
import { useSites } from 'hooks/sites/useSites';
import Result from 'components/alert/alert';
import dayjs, { Dayjs } from 'dayjs';
import GameCard from 'components/card/gameCard';
import DateTimePickerValue from 'components/datePicker/dateTimeRangePicker';
import styles from './modal.module.scss';
import { useTranslation } from 'react-i18next';

interface CreateFreeSpinsSlotegrator {
  title: string;
  onClose: () => void;
}

export const initialData = {
  name: '',
  title: '',
  url: '',
  currencyIds: [],
  isActive: true,
  denominacia: '',
  level: '',
};

const style = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
};

function CreateFreeSpinsSlotegrator({ title, onClose }: CreateFreeSpinsSlotegrator) {
  const { t: clientDetailsT } = useTranslation('clientsDetails');
  const { t: clientsT } = useTranslation('clients');
  const { t } = useTranslation('common');
  const [isSpecific, setIsSpecific] = useState(false);
  const [formData, setFormData] = useState({ ...initialData });
  const { error } = useSites();
  const today = dayjs();
  const [dateList, setDateList] = useState([today]);

  const handleChange = (event: SelectChangeEvent, name: string) => {
    const { value } = event.target;

    const data = { ...formData, [name]: value };
    setFormData(data);
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: string,
  ) => {
    const { value } = e.target;
    const data = { ...formData, [name]: value };
    setFormData(data);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onClose();
  };

  const handleOnSwicth = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const { checked } = e.target;
    /**TODO: write normal ) */
    if (key === 'isSetUseDate') {
      setDateList([today]);
    } else {
      setIsSpecific(checked);
    }
    console.log(checked, 'handleOnSwicth');
    // const data = { ...formData, [key]: checked };
    // setFormData(data);
  };

  const handleOnChangeDate = (valueFrom: Dayjs | null, valueTo: Dayjs | null) => {
    // setUserDateBrth(newValue);
    // setShowBtn(true);
  };

  return (
    <>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        {title}
      </Typography>
      <Box sx={style}>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Box className={styles.leftPart}>
              <Box>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isSpecific}
                        onChange={(e) => handleOnSwicth(e, 'isSpecific')}
                      />
                    }
                    label={clientDetailsT('addSpecificGame')}
                  />
                </FormGroup>
              </Box>
              <TextField
                fullWidth
                type="number"
                margin="normal"
                name="title"
                label={clientDetailsT('countOfSpins')}
                variant="standard"
                onChange={(e) => handleOnChange(e, 'spinsCount')}
              />
              <Box sx={{ minWidth: 100, mt: 3, mb: 3 }}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">
                    {clientDetailsT('denominacia')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formData?.denominacia ? formData?.denominacia : '1'}
                    label="otdacha"
                    fullWidth
                    variant="standard"
                    onChange={(e) => handleChange(e, 'denominacia')}
                  >
                    <MenuItem value="">
                      <em>{clientsT('none')}</em>
                    </MenuItem>
                    {[
                      { id: '1', name: '1' },
                      { id: '2', name: 'Denominacia-2' },
                      { id: '3', name: 'Denominacia-3' },
                    ].map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.name}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ minWidth: 100, mt: 3, mb: 3 }}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">
                    {clientDetailsT('betLevel')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formData?.level ? formData?.level : '1'}
                    label="otdacha"
                    fullWidth
                    variant="standard"
                    onChange={(e) => handleChange(e, 'level')}
                  >
                    <MenuItem value="">
                      <em>{clientsT('none')}</em>
                    </MenuItem>
                    {[
                      { id: '1', name: '1' },
                      { id: '2', name: 'Level Stavki-2' },
                      { id: '3', name: 'Level Stavki-3' },
                    ].map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.name}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ minWidth: 100, mt: 3, mb: 3 }}>
                <DateTimePickerValue onShow={handleOnChangeDate} />
              </Box>
            </Box>
            <Box className={styles.rightPart}>
              {/* Game list is empty */}
              {isSpecific ? (
                <>
                  {/* TODO: do mapping with real data */}
                  <Box className={styles.items}>
                    <GameCard
                      item={{
                        id: '1',
                        gameName: 'Shining Crown',
                        img: 'https://newstatic.adjarabet.com/static/desktop/cms-nova/img/games/common-new/554490_en.jpg',
                        playedCount: 1245,
                      }}
                    />
                    <GameCard
                      item={{
                        id: '2',
                        gameName: 'Red Poker',
                        img: 'https://www.inventiva.co.in/wp-content/uploads/2022/09/JB-COMP-ONLINE-CASINO-DEC-15-2.webp',
                        playedCount: 1245,
                      }}
                    />
                    <GameCard
                      item={{
                        id: '2',
                        gameName: 'Zodiac Wheels',
                        img: 'https://newstatic.adjarabet.com/static/desktop/cms-nova/img/games/common-new/557935_en.jpg',
                        playedCount: 1245,
                      }}
                    />
                  </Box>
                </>
              ) : (
                clientDetailsT('gameListIsEmpty')
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {error && <Result style={{ mb: 2 }} message={error} type={'error'} isShow />}
            <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
              {clientDetailsT('add')}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default CreateFreeSpinsSlotegrator;
