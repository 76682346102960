// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Autocomplete, TextField, useTheme } from '@mui/material';
import { IGotoPage } from 'hooks/clients/useClients';
import { makeStyles } from '@material-ui/core/styles';

interface DropDownSelectProps {
  onSelectPage: (value: string) => void;
  totalPages: IGotoPage[] | [];
  reset?: boolean;
}

export default function DropDownSelect({ onSelectPage, totalPages, reset }: DropDownSelectProps) {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const [page, setPage] = useState('');

  const useStyles = makeStyles({
    paper: {
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff',
    },
  });

  const classes = useStyles();

  useEffect(() => {
    setPage('');
  }, [reset]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;
    if (value.length <= 6) {
      setPage(value);
      onSelectPage(
        value == ''
          ? '1'
          : +value > +totalPages[totalPages.length - 1]?.val
          ? +totalPages[totalPages.length - 1]?.val + 1
          : value,
      );
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const { keyCode: k } = e.nativeEvent;
    if ((k < 48 || k > 57) && (k < 96 || k > 105) && k != 8) {
      e.preventDefault();
      return false;
    }
  };

  return (
    <Stack sx={{ width: t('goToPage').length * 10 + 10 }}>
      <Autocomplete
        color="white"
        size="small"
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        options={totalPages.map((option) => option.val)}
        value={page}
        classes={{ paper: classes.paper }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('goToPage')}
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
            onChange={(e) => handleChange(e)}
            onBlur={(e) => handleChange(e)}
            onKeyDown={(e) => handleKeyDown(e)}
          />
        )}
      />
    </Stack>
  );
}
