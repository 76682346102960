import { useEffect, useState } from 'react';
import AccountService from 'services/account.service';
import { generateHeaders } from 'utils/utils';
import { useStorage } from '../authHooks/useStorage';
import {
  prepareDataForSelectPages,
  prepareTableData,
  preparedColumnNames,
} from 'utils/helpers/dataHelper/prepareDataHelper';
import { roleTypes } from 'types/accounts';
import { useDispatch } from 'react-redux';
import { setAccountsData } from 'redux/actions/accountsActions';
import { useApiErrors } from 'hooks/errors/useApiErrors';
import { IGotoPage } from 'types/sites';

export interface IManager {
  id: string;
  login: string;
}

export const useAccounts = (role: roleTypes | '', onlyManagersList = false) => {
  const dispatch = useDispatch();
  const { errorHandler } = useApiErrors();
  const { logout, userToken } = useStorage();
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [managerList, setManagerList] = useState<IManager[]>([]);
  const [columns, setColumns] = useState<string[] | []>([]);
  const [pageLists, setPageLists] = useState<IGotoPage[] | []>([]);
  const [error, setError] = useState(null);

  const initHeaders = generateHeaders(userToken());

  const getAccountsData: any = async (headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: {
          data: { accounts, totalPages, totalItems },
        },
      } = await AccountService.getAllByRole(headers, role);
      const preparedData = prepareTableData(accounts, role);
      const columns = preparedColumnNames(preparedData);
      const pageLists = prepareDataForSelectPages(totalPages);
      dispatch(setAccountsData(preparedData));
      setColumns(columns);
      setTotalPages(totalPages);
      setPageLists(pageLists as IGotoPage[]);
      setTotalItems(totalItems);

      if (onlyManagersList) {
        const list = preparedData.map((el: { id: string; login: string }) => ({
          id: el.id,
          login: el.login,
        }));
        setManagerList(list);
      }
      // else{
      //   dispatch(setAccountsData(preparedData));
      //   setColumns(columns);
      //   setTotalPages(totalPages);
      //   setPageLists(pageLists);
      //   setIsLoading(false);
      // }
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getAccountsData(newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (role) getAccountsData();
  }, [role]);

  const queryParamsQuery: any = async (query: string, headers = initHeaders) => {
    if (role)
      try {
        const {
          data: {
            data: { accounts, totalPages, totalItems },
          },
        } = await AccountService.getAccountByQuery(headers, role, query);
        dispatch(setAccountsData(accounts));
        setTotalPages(totalPages);
        setTotalItems(totalItems);
        setIsLoading(false);
      } catch (error: any) {
        const {
          response: {
            data: { message, status },
          },
        } = error;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          logout();
        } else if (!needLogout && newHeader) {
          return await queryParamsQuery(query, newHeader);
        } else {
          console.log('LOG____>', message);
          setError(message);
        }
      }
  };

  return {
    role,
    pageLists,
    columns,
    isLoading,
    totalPages,
    error,
    queryParamsQuery,
    getAccountsData,
    managerList,
    totalItems,
  };
};
