export enum FIELD_NAME {
  AMOUNT = 'amount',
  REASON_ID = 'reasonId',
  TYPE_ID = 'typeId',
  COMMENT = 'comment',
  FROM = 'fromTypeId',
  TO = 'toTypeId',
  WEIGER= 'weiger',
  NAME = 'name'
}
