import { actionTypes } from 'redux/constants/actionTypes';

const initial = {
  clients: [],
  messages: [],
  labels: [],
  socialLinks: []
};

export const clientsReducer = (state = initial, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_CLIENTS_LIST:
      return { ...state, clients: payload ?? [] };
    case actionTypes.SET_MESSAGES_LIST:
      return { ...state, messages: payload ?? [] };
    case actionTypes.SET_LABELS:
      return { ...state, labels: payload ?? [] };
    case actionTypes.SET_SOCIAL_LINKS_DATA:
      return { ...state, socialLinks: payload ?? [] }
    case actionTypes.ADD_SOCIAL_LINK:
      return {
        ...state, socialLinks:
          state.socialLinks.map(socialLink => socialLink.type === payload.type ? { ...socialLink, links: [...socialLink.links, { id: payload.id, link: payload.link }] } : socialLink)
      }
    case actionTypes.REMOVE_SOCIAL_LINK:
      return {
        ...state, socialLinks:
          state.socialLinks.map(socialLink => socialLink.type === payload.type ? { ...socialLink, links: socialLink.links.filter(elm => elm.id !== payload.id) } : socialLink)
      }
    default:
      return state;
  }
};
