import { useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Piastrix from './piastrix';
import FreeKassa from './freeKassa';
import { useTranslation } from 'react-i18next';

function PaymentSettings() {
  const { t: siteSettingsT } = useTranslation('siteSettings');
  const [value, setValue] = useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label={'piastrix'} value="1" />
              <Tab label={'freeKassa'} value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Piastrix />
          </TabPanel>
          <TabPanel value="2">
            <FreeKassa />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}

export default PaymentSettings;
