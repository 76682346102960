import { Box, Button, Typography, TextareaAutosize, IconButton, TextField } from '@mui/material';
import { useState } from 'react';
import UploadButtons from 'components/imageUpload/imageUpload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useMessage } from 'hooks/message/useMessage';
import Result from 'components/alert/alert';
import common from 'styles/common.module.scss';
import styles from './modal.module.scss';
import shatStyle from '../chat/chat.module.scss';
import { useTranslation } from 'react-i18next';
import { minLength } from 'utils/validations/validationTypes';
import { FIELD_NAME } from 'types/clients';

interface MessageCreateEditProps {
  clientId: string;
  mode: string;
  item?: any;
  onClose: () => void;
}

export const initialData = {
  message: '',
  title: '',
  // image: '',
};

const style = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
};

const fields = {
  [FIELD_NAME.TITLE]: true,
  [FIELD_NAME.IMAGE]: true,
};

function MessageCreateEdit({ onClose, mode, item, clientId }: MessageCreateEditProps) {
  const { t } = useTranslation('common');
  const { t: clientDetailsT } = useTranslation('clientsDetails');
  const { t: validationT } = useTranslation('validations');
  const [validationError, setValidationError] = useState({
    message: false,
    image: '',
  });
  const [formData, setFormData] = useState({ ...initialData });
  const { createUserMessage, editUserMessage, error } = useMessage(clientId);
  const [validFields, setValidFields] = useState({ ...fields });

  const isValidData = (data: any) => {
    if (data.message === '' || data.message.length < 5) {
      setValidationError((error) => ({
        ...error,
        message: true,
      }));
    }
    return !(data.message === '' || data.message.length < 5);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let result;
    if (isValidData(formData)) {
      if (mode === 'edit') {
        result = await editUserMessage(clientId, item.id, formData);
      } else {
        result = await createUserMessage(formData);
      }
      if (result?.status === 200 || result?.status === 201) onClose();
    }
  };

  const onImageUpload = (data: string) => {
    setFormData((formData) => ({ ...formData, image: data }));
    setValidationError((error) => ({
      ...error,
      image: '',
    }));
  };

  const removeImage = () => {
    const data = { ...formData, image: '' };
    setFormData(data);
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: string,
  ) => {
    const { value } = e.target;
    const data = { ...formData, [name]: value };
    setFormData(data);
    if (name === 'message' && validationError[name]) {
      setValidationError((error) => ({
        ...error,
        message: false,
      }));
    }
  };

  // const handleExternalErrorImage = (name: string, message: string) => {
  //   console.log(message, 'message');
  //   setValidationError((error) => ({
  //     ...error,
  //     [name]: message,
  //   }));
  // };

  const validate = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, name: string) => {
    const { value } = e.target;
    setValidFields((fields) => ({
      ...fields,
      [name]: minLength(4)(value),
    }));
  };

  return (
    <>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        {mode === 'send' ? t('create') : t(mode)} {clientDetailsT('conversation')}
      </Typography>
      <Box sx={style}>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
          <Box>
            <Box sx={{ mb: 4 }}>
              <TextField
                required
                value={formData.title ?? ''}
                fullWidth
                margin="normal"
                name="title"
                label={clientDetailsT('title')}
                variant="standard"
                onChange={(e) => handleOnChange(e, 'title')}
                onBlur={(e) => validate(e, FIELD_NAME.TITLE)}
                error={!validFields[FIELD_NAME.TITLE]}
                helperText={
                  validFields[FIELD_NAME.TITLE] ? '' : validationT('minLength', { length: 5 })
                }
              />
            </Box>
            <Box className={styles.textArea} sx={{ mb: 4 }}>
              <Box sx={{ width: '100%' }}>
                <TextareaAutosize
                  required
                  value={formData.message ?? ''}
                  style={{
                    width: '100%',
                    height: '80px',
                    padding: '8px',
                    borderRadius: '10px',
                  }}
                  onChange={(e) => handleOnChange(e, 'message')}
                />
                {validationError.message ? (
                  <Typography color={common.mainwarning} component="span" className={styles.error}>
                    {t('required')}. {validationT('minLength', { length: 5 })}
                  </Typography>
                ) : null}
              </Box>
              {/* <Box
                className={shatStyle.buttonArea}
                sx={{ display: 'flex', alignItems: 'center', mt: 0 }}
              >
                <UploadButtons
                  onImageUpload={onImageUpload}
                  initialImage={formData?.image ?? null}
                  forChat={true}
                  width={'100'}
                  height={'60'}
                  onExternalErrorMessage={handleExternalErrorImage}
                  hideError
                />
                {formData?.image ? (
                  <DeleteForeverIcon
                    sx={{
                      color: common.mainwarning,
                      fontSize: 24,
                      cursor: 'pointer',
                    }}
                    onClick={removeImage}
                  />
                ) : null}
              </Box> */}
            </Box>
          </Box>
          {validationError.image ? (
            <Typography color={common.mainwarning} component="span" className={styles.error}>
              {t(validationError.image)}
            </Typography>
          ) : null}
          {error && <Result style={{ mb: 2 }} message={error} type={'error'} isShow />}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Button
              fullWidth
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={
                !validFields[FIELD_NAME.TITLE] ||
                !validFields[FIELD_NAME.TITLE] ||
                validationError.message ||
                !!validationError.image
              }
            >
              {t(mode)}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default MessageCreateEdit;
