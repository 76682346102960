import { Box, Typography } from '@mui/material';
import _ from 'lodash';
import UploadButtons from 'components/imageUpload/imageUpload';
import { useProviders } from 'hooks/providers/useProviders';
import LoaderWithBg from 'components/loader/loader';
import { useEffect, useState } from 'react';
import noImage from 'assets/image/no-image.png';
import { useTranslation } from 'react-i18next';

interface subProvidersProps {
  providerId: string;
}

function SubProviders({ providerId }: subProvidersProps) {
  const { t } = useTranslation('common');
  const { getAllSubProviders, uploadProviderImage, isLoading } = useProviders();
  const [subProviderList, setSubProviderList] = useState<any>([]);

  const onImageUpload = async (imageUrl: string | Blob, id?: string) => {
    const res = await uploadProviderImage(id, imageUrl);

    if (res?.status === 200) {
      const temp = _.cloneDeep(subProviderList);

      const findElement = temp.find((el: any) => el.id + '' === id);
      if (findElement) {
        findElement.image = res?.data?.image ?? '';
      }
      setSubProviderList(temp);
    }
  };

  useEffect(() => {
    const getSubProviders = async () => {
      const { data } = await getAllSubProviders(providerId);
      setSubProviderList(data);
    };

    if (providerId) {
      getSubProviders();
    }
  }, [providerId]);

  return (
    <>
      {isLoading ? <LoaderWithBg isOpen={isLoading} /> : null}
      <Box
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: '20px',
        }}
      >
        {subProviderList.map((elm: any, i: number) => {
          return (
            <Box key={i} sx={{ textAlign: 'center' }}>
              <img
                src={elm.image ?? noImage}
                width="250px"
                height="200px"
                onError={({ currentTarget }) => (currentTarget.src = noImage)}
              />
              <Typography>{elm.name}</Typography>
              <Box>
                <UploadButtons onImageUpload={onImageUpload} keys={elm.id + ''} needFormDataImage />
              </Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
}

export default SubProviders;
