import http from '../http-common';

class PromoDataService {

  createPromotionPromoCode = async (headers, siteId,  body) => {
    return await http.post(`/site/${siteId}/promo-code`, body, { headers });
  };

  getAllPromotionPromoCode = (headers, query = '') => {
    return http.get(`site/promo-code/all?isPromotion=false${query}`, { headers });
  };

  getAllMultiUserPromoCode = (headers, query = '') => {
    return http.get(`site/promo-code/all?isPromotion=true${query}`, { headers });
  };

   getAllMultiUserAppliedPromoCode = (headers, id, query = '') => {
    return http.get(`site/promo-code/${id}/promotions?${query}`, { headers });
  };

  deletePromoCode = (headers, id) => {
    return http.delete(`/site/promo-code/${id}`, { headers });
  };
}

const PromoCodeService = new PromoDataService();

export default PromoCodeService;
