export const columnsName = {
  CREATED_AT: 'createdAt',
  END_DATE: 'endDate',
  FINISHED_AT: 'finishedAt',
  CREATED: 'created at',
  FINISHED: 'finished at',
  ID: 'id',
  LEVEL_GROUP: 'levelGroup',
  IS_ACTIVE: 'isActive',
  IS_BLOCKED: 'isBlocked',
  BLOCKED: 'blocked',
  ACTIVE: 'active',
  ACTIVATED_AT: 'activatedAt',
  CURRENCIES: 'currencies',
  WIN_COUNT: 'winCount',
  WIN: 'winners count',
  TEXT: 'text',
  IMAGE: 'image',
  DATE: 'date',
  LANGUAGES: 'languages',
  LOGINDATE: 'loginDate',
  LOGIN_DATE: 'Login Date',
  IPADDRESS: 'ipAddress',
  IP_ADDRESS: 'Ip Address',
  SITE: 'site',
  CURRENCY: 'currency',
  ACCOUNT: 'account',
  STARTED_AT: 'startedAt',
  END_AT: 'endedAt',
  META_DATA: 'metadata',
  BALANCE_TYPE:'balanceType',
  USER:'user',
  LOGS:'logs',
  LOGIN:'login',
  IS_USED : 'isUsed',
  DAY: 'day',
  USE_DATE: "useDate",
  USE_COUNT: "useCount"
};

export const common = {
  YES: 'yes',
  NO: 'no',
  TRUE: 'true',
  FALSE: 'false',
  ASC: 'ASC',
  DESC: 'DESC',
  SITE: 'site',
  NEW: 'new',
  ADD: 'add',
  CREATE: 'create',
  EDIT: 'edit',
  APPROVE:'approve',
};

export const SUCCESS = 'success';
export const ERROR = 'error';
export const SMTH_WENT_WRONG = 'Something went wrong';


export const routeSwitcher = {
  ACCOUNT_PAGE: 'accountsPage',
  SITE_PAGE: 'sitesPage',
  SITE_SETTINGS: 'sitesSettingsPage',
  WELCOME_PAGE: 'welcomePage',
  PERMISSON_MANAGMENT: 'permission managment',
  CLIENT_LISTS: 'client lists',
  CLIENT_DETAILS: 'client details',
  AWAIT_IDENTIFICATIONS: 'await identifications',
  STATISTICS: 'statistics',
  CHANNEL: 'telegram channel',
  RAFFLE: 'telegram raffle',
  GAMBLING_STATISTICS: 'gambling statistics',
  GAME_SETTINGS: 'gameSettings',
  GAME_NAMES: 'gameNames',
  PROVIDER_SETTINGS: 'providerSettings',
  LABELS: 'labels',
  DEPOSIT: 'finance deposit',
  WITHDRAWALS: 'finance withdrawals',
  PROMOTION: 'promotion',
  MULTIUSER: 'multiuser',
  MULTIUSER_LIST: 'multiuser promocode list',
  BONUSES: 'finance bonuses',
  FINANCE_LOGS: 'finance logs',
  ADD_BONUS: 'add bonus for clients',
  GAME_LOGS: 'game logs',
  CASHBACKS: 'cashbacks',
  WELCOME_PAGE_COMMON: 'welcome page common'
};

export const dialog = {
  REMOVE_ALL_PERMISSIONS: 'removeAllPermisssions',
  WANT_DELETE: 'wantDelete',
  CLOSE_SESSION: 'closeSession',
  CONTENT_ALL_PERMISSIONS: 'contentOfDialogPermisssions',
  CLINET_VARIFICATION: 'clientVarification',
  CLINET_VARIFICATION_CONTENT: 'clientVarificationContent',
  APPROVE_TEXT: 'approveText',
  CANCEL_TEXT:'cancelText',
  CANCEL_REFUND_TEXT:'cancelAndRefundText',
};

export const sitesSettings = {
  DEPOSIT_TITLE: 'depositTitle',
  WITHDRAW_TITLE: 'withdrawTitle',
  EDIT_SITE: 'editSite',
  BTN_TEXT: 'btnText',
  SHOW_STATISTICS: 'showStatistics',
};

export const ROW_PER_PAGE = 25

export const bonusStatus = {
  USED: "USED",
  ACTIVE: "ACTIVE",
  CANCELED: "CANCELED",
  NEW:"NEW"
}