import { auth } from 'constants/errors';
import AuthService from 'services/auth.service';
import { useStorage } from '../authHooks/useStorage';
import { generateHeaders } from 'utils/utils';

export const useApiErrors = () => {
  const { userRefresh, setStorageData } = useStorage();

  const errorHandler = async (status: number, message: string) => {
    if (status === 403 && message === auth.AUTHORIZATION_TOKEN_EXPIRED ) {
      try {
        const {
          data: { data, status, message:mess },
        } = await AuthService.refreshToken({ refreshToken: userRefresh() });
        if (status === 200) {
          setStorageData(data);
          const header = generateHeaders(data.accessToken);
          return { newHeader: header, needLogout: false };
        }
      } catch (err: any) {
        return {  newHeader: null, needLogout: err.message === auth.REQUEST_REFRESH_EXPIRED || err.message === auth.REQUEST_REFRESH_WRONG  };
      }
    }
     else if(message === auth.REFRESH_TOKEN_WRONG || message === auth.AUTHORIZATION_WRONG_TOKEN){
      return {  newHeader: null, needLogout: true};
    }
    return null;
  };

  return { errorHandler };
};
