import { Typography } from '@material-ui/core';
import { Box, Divider } from '@mui/material';
import Chart from 'components/chart/chart';
import { useSites } from 'hooks/sites/useSites';
import { useEffect } from 'react';

const style = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
};

function WelcomePageCommon() {
  const { getSiteSettings } = useSites();

  useEffect(() => {
    getSiteSettings();
  }, []);

  return (
    <Box sx={style}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography component="h1">Welcome to our dashboard</Typography>
      </Box>
    </Box>
  );
}

export default WelcomePageCommon;
