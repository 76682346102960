import { GridValueGetterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import moment from 'moment';
import FilterField from 'components/filter/columnFilter';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useMemo, useState } from 'react';
import { IFilterData } from 'types/accounts';
import { columnsName, common, dialog, ROW_PER_PAGE } from 'constants/common';
import { Box, Button, Link, Modal, TablePagination, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setResetAll } from 'redux/actions/commonActions';
import styles from './siteTable.module.scss';
import CreateChannel from 'components/modal/createChannel';
import EditChannel from 'components/modal/editChannel';
import { isEmpty } from 'utils/utils';
import { IconClose } from 'components/closeButton/closeButton';
import Dialogs from 'components/dialog/dialogs';
import { useTranslation } from 'react-i18next';
import { style } from 'components/modal/modal';
import { useNavigate } from 'react-router-dom';

const mocked_data = [
  {
    id: '17',
    name: '@segaSofficial',
    status: 'Active',
    siteName: 'Sega Slot',
  },
  {
    id: '16',
    name: '@zaraslot_official',
    status: 'Active',
    siteName: 'Zara Slot',
  },
];

export interface IChannel {
  id: string;
  name: string;
  status?: string;
  siteName: string;
}

const columnNames = Object.keys(mocked_data[0]);

export default function ChannelsTable() {
  const { t } = useTranslation('common');
  const { t: telegramT } = useTranslation('telegram');
  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [filterData, setFilterData] = useState<IFilterData | object>({});
  const [activeItem, setActiveItem] = useState<IChannel>({} as IChannel);
  const [sortData, setSortData] = useState<IFilterData | object>({});
  const [rowsPerPage, setRowsPerPage] = useState(ROW_PER_PAGE);
  const [editedItem, setEditedItem] = useState<IChannel | object>({});

  /** TODO remove all mocked data   */
  const totalPages = 1;

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const title = `${t(common.CREATE)} ${telegramT('channel')}`;

  const handleClose = () => {
    setOpenModal(false);
    setEditedItem({});
  };
  const handleOpen = () => setOpenModal(true);

  const onButtonClick = (id: string, mode: string) => {
    const item = mocked_data.filter((el) => el.id === id);
    if (mode === 'edit') {
      if (item.length) {
        setEditedItem({ ...item[0] });
      }
      setOpenModal(true);
    } else {
      setOpen(true);
      setActiveItem({ ...item[0] });
    }
  };

  const handleFilter = (name: string, value: string) => {
    setFilterData((filterby) => ({ ...filterby, [name]: value }));
  };

  const handleSortBy = (sortBy: string, sortDir: string) => {
    setSortData({ [sortBy]: sortDir });
  };

  const handleResetAll = () => {
    // getAllSites();
    dispatch(setResetAll(true));
    setFilterData({});
    setSortData({});
  };

  const actionFields = [
    {
      field: 'edit',
      headerName: 'Edit',
      width: Math.ceil(window.innerWidth / (columnNames.length + 3)),
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridValueGetterParams) => (
        <Button variant="contained" onClick={() => onButtonClick(params.row.id, 'edit')}>
          {telegramT('edit')}
        </Button>
      ),
    },
    {
      field: 'delete',
      headerName: 'Delete',
      width: Math.ceil(window.innerWidth / (columnNames.length + 3)),
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridValueGetterParams) => (
        <Button variant="contained" onClick={() => onButtonClick(params.row.id, 'delete')}>
          {t('delete')}
        </Button>
      ),
    },
  ];

  const generateDinamicColumns = useMemo(() => {
    const columns = columnNames.map((el) => {
      if (el === columnsName.CREATED_AT) {
        return {
          field: el,
          width: Math.ceil(window.innerWidth / (columnNames.length - 1)),
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={columnsName.CREATED.toLocaleUpperCase()}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
            />
          ),
          valueGetter: (params: GridValueGetterParams) =>
            `${moment(params.row.createdAt).format('DD/MM/Y HH:mm:ss')}`,
        };
      }
      if (el === columnsName.CURRENCIES) {
        return {
          field: el,
          width: Math.ceil(window.innerWidth / columnNames.length),
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={columnsName.CURRENCIES.toLocaleUpperCase()}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
            />
          ),
          valueGetter: (params: GridValueGetterParams) =>
            `${params.row.currencies?.map((el: any) => {
              let curr = '';
              curr = curr + ' ' + el?.code.toLowerCase();
              return curr;
            })}`,
        };
      }
      if (el === columnsName.IS_ACTIVE) {
        return {
          field: el,
          width: 150,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={columnsName.ACTIVE.toLocaleUpperCase()}
              onBlur={(value) =>
                handleFilter(
                  el,
                  value === t(common.YES)
                    ? common.TRUE
                    : value === t(common.NO)
                    ? common.FALSE
                    : value,
                )
              }
              onSort={handleSortBy}
              sortData={sortData}
            />
          ),
          valueGetter: (params: GridValueGetterParams) =>
            `${params.row.isActive ? t(common.YES) : t(common.NO)}`,
        };
      }
      if (el === 'name') {
        return {
          field: el,
          width: 150,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el.toLocaleUpperCase()}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
            />
          ),
          renderCell: (params: GridValueGetterParams) => (
            <Link
              color={'text.primary'}
              component="a"
              onClick={() => {
                window.open(`https://t.me/${params.row.name}`);
              }}
              target="blank"
            >
              {params.row.name}
            </Link>
          ),
        };
      }

      return {
        field: el,
        width:
          el === columnsName.ID ? 100 : Math.ceil(window.innerWidth / (columnNames.length + 1)),
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => (
          <FilterField
            fieldName={el.toLocaleUpperCase()}
            onBlur={(value) => handleFilter(el, value)}
            onSort={handleSortBy}
            sortData={sortData}
          />
        ),
      };
    });
    return [...columns, ...actionFields];
  }, [columnNames, sortData]);

  const columns = generateDinamicColumns as GridColDef[];

  const handleOnDelete = async (id: string) => {
    console.log(id, 'handleOnDelete');
  };

  // useEffect(() => {
  //   const query = makeQueryUrl(filterData);
  //   const sortBy = Object.entries(sortData)[0];

  //   let paramsQuery = isEmpty(sortData)
  //     ? query
  //     : `${query}&sortBy=${sortBy[0]}&sortDir=${sortBy[1]}`;

  //   paramsQuery += `&page=${page + 1}&pageSize=${rowsPerPage}`;

  //   queryParamsQuery(paramsQuery);
  // }, [filterData, sortData, page, rowsPerPage]);

  return (
    <div style={{ width: '100%' }}>
      <Box
        sx={{ mb: 3, justifyContent: 'flex-end', alignItems: 'center' }}
        className={styles.filter_box}
      >
        <div className={styles.filter_btn}>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Button variant="contained" onClick={handleOpen}>
              {title}
            </Button>
          </Box>
          <Box sx={{ display: 'flex' }}>
            {Object.values(filterData).join() || Object.values(sortData).join() ? (
              <Button className={styles.reset_all_btn} variant="contained" onClick={handleResetAll}>
                {t('ressetAll')}
              </Button>
            ) : null}
          </Box>
        </div>
      </Box>
      <TablePagination
        component="div"
        count={0}
        page={page}
        labelRowsPerPage={t('rowsPerPage')}
        labelDisplayedRows={({ from, to, count }) =>
          `${from >= to ? to : from}-${to} ${t('of')} ${count}`
        }
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <DataGrid
        autoHeight
        rows={mocked_data}
        columns={columns}
        hideFooterPagination={true}
        sx={{
          width: '100%',
          overflow: 'auto',
          '.MuiDataGrid-iconButtonContainer': {
            visibility: 'visible',
          },
          '.MuiDataGrid-sortIcon': {
            opacity: 'inherit !important',
          },
          '.MuiDataGrid-footerContainer': {
            display: 'none',
          },
        }}
      />

      {openModal && (
        <Modal open={openModal} onClose={handleClose} sx={{ minWidth: 400 }}>
          <Box
            sx={{
              ...style,
              bgcolor: `${theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff'}`,
            }}
          >
            {!isEmpty(editedItem) ? (
              <EditChannel
                onClose={handleModalClose}
                item={editedItem}
                title={telegramT('editChannel')}
              />
            ) : (
              <CreateChannel onClose={handleModalClose} title={telegramT('createChannel')} />
            )}
            <IconClose onClose={handleClose} />
          </Box>
        </Modal>
      )}
      <Dialogs
        id={activeItem.id}
        title={t(dialog.WANT_DELETE)}
        isOpen={open}
        onClose={() => setOpen(false)}
        onConfirm={handleOnDelete}
        btnText="yes"
      />
    </div>
  );
}
