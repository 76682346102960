import { Dayjs } from 'dayjs';

export interface IMessage {
  createdAt: string;
  id: string;
  isViewed: boolean;
  login: string;
  message: string;
  role: string;
}

export interface IAddBonusesFilterData {
  date: any;
  minDepositSum: string;
  clientsLevel: string;
  currency: string;
  maxDepositSum?: string;
  maxProfitInPeriod?: string;
}

export interface IAddClientsBonuses {
  timeConfirmation: Dayjs | null;
  percentVal: string;
  clientsId: any;
  messageForClients?: any;
}

export interface ILabel {
  createdAt: string;
  id: string;
  name: string;
}
export interface IBalances {
  type: string;
  amount: string;
}

export interface IClient {
  unverifiedReason: string | null;
  passportNumber: string | null;
  rearView: string;
  frontView: string;
  verificationStatus: string;
  site: any;
  balances: IBalances[];
  birthdayAt: any;
  blockMessage: string;
  blockedUntil: string;
  clientBlockMessage: string;
  createdAt: string;
  currencyId: string;
  deletedAt: string;
  email: string;
  id: string;
  isBlocked: boolean;
  isEmailConfirmed: boolean;
  isPhoneConfirmed: boolean;
  label: ILabel;
  lastActiveAt: string;
  levelChangedAt: string;
  levelGroupId: string;
  login: string;
  managerId: string;
  name: string;
  password: string;
  phone: string;
  promo: string;
  siteId: string;
  profit: string;
  currency: string;
  updatedAt: string;
}

export interface IEditMessage {
  id: string;
  message: string;
  [key: string]: string | null | number | boolean;
}

export interface IData {
  ipAddress: string;
  headers: string;
}

export interface IHistory {
  id: string;
  createdAt: string;
  data: IData;
}

export interface IGameHistory {
  balanceType: string;
  denomination: string;
  endAmount: number;
  endedAt: string;
  gameName: string;
  id: number;
  isAlive: boolean;
  startAmount: number;
  startedAt: string;
}
export interface IGameSettings {
  id: string;
  [x: string]: string;
}

export interface IGroups {
  id:  string;
  settingId: string ;
  name?: string;
}
export interface gameSettingsList {
  providerId: number | string;
  providerName: string;
  settings: IGameSettings[];
  groups: IGroups[];
}

export interface IGamblingForm {
  reason: string;
  isAutochange: boolean;
  gameSettingsList: gameSettingsList[];
}

export enum FIELD_NAME {
  DATE_FROM = 'dateFrom',
  DATE_TO = 'dateTo',
  MIN_SUM = 'minDepositSum',
  MAX_SUM = 'maxDepositSum',
  CLIENT_LEVEL = 'clientsLevel',
  MAX_PROFIT = 'maxProfitInPeriod',
  CLIENTS_ID = 'clientsId',
  PERCENT_VAL = 'percentVal',
  MESSAGE_FOR_CLIENTS = 'messageForClients',
  TIME_CONFIRMATION = 'timeConfirmation',
  CURRENCY = 'currency',
  TITLE = 'title',
  IMAGE = 'image',
}
