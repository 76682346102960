import { useState } from 'react';
import { generateHeaders } from 'utils/utils';
import { useStorage } from '../authHooks/useStorage';
import { useApiErrors } from 'hooks/errors/useApiErrors';
import {
  prepareDataForSelectPages,
  prepareTableData,
  preparedColumnNames,
} from 'utils/helpers/dataHelper/prepareDataHelper';
import { IGotoPage } from 'types/sites';
import SiteService from 'services/site.service';

export const useCashBacks = () => {
  const { errorHandler } = useApiErrors();
  const { logout, userToken } = useStorage();
  const [isLoading, setIsLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(1);
  const [error, setError] = useState('');
  const [columns, setColumns] = useState<string[] | []>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [pageLists, setPageLists] = useState<IGotoPage[] | []>([]);
  const [cashBackList, setCashBackList] = useState<any[]>([]);
  const initHeaders = generateHeaders(userToken());

  const getCashbacks: any = async (
    siteId: string,
    query: string,
    headers = initHeaders,
  ) => {
    setIsLoading(true);
    try {
      const {
        data: {
          data: { usersCashback, totalPages, totalItems },
        },
      } = await SiteService.getCashbackLists(headers, siteId, query);
      let preparedData = [];
      let pageLists: any = [];
      if (usersCashback.length) {
        preparedData = prepareTableData(usersCashback, '');
        pageLists = prepareDataForSelectPages(totalPages);
        const columns = preparedColumnNames(preparedData);
        setColumns(columns);
      }
      setTotalPages(totalPages);
      setPageLists(pageLists as IGotoPage[]);
      setTotalItems(totalItems);
      setCashBackList(preparedData);
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getCashbacks(siteId, query, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    }finally{
      setIsLoading(false);
    }
  };

  return {
    getCashbacks,
    cashBackList,
    isLoading,
    columns,
    totalPages,
    pageLists,
    totalItems,
    error,
  };
};
