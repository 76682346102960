import { useStorage } from 'hooks/authHooks/useStorage';
import { useApiErrors } from 'hooks/errors/useApiErrors';
import { useState } from 'react';
import SitePercentsService from 'services/sitePercents.service';
import { generateHeaders } from 'utils/utils';

export const useSitePercents = () => {
  const { logout, userToken } = useStorage();
  const { errorHandler } = useApiErrors();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const initHeaders = generateHeaders(userToken());

  const getAllProviderPercents: any = async (siteId: string, query: string, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: {
          data, status
        },
      } = await SitePercentsService.getAllProviderPercents(headers, siteId);
      if (status === 200) {
        return { data, status }
      }
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};

      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getAllProviderPercents(siteId, query, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const updateProviderPercents: any = async (
    siteId: string,
    body: any,
    headers = initHeaders,
  ) => {
    try {
      setIsLoading(true);
      const {
        data: {
          data,
          status,
        },
      } = await SitePercentsService.updateProviderPercents(headers, siteId, body);
      return { data, status };
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};

      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await updateProviderPercents(siteId, body, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getAllFinancePercents: any = async (siteId: string, query: string, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: {
          data, status
        },
      } = await SitePercentsService.getAllFinancePercents(headers, siteId);
      if (status === 200) {
        return { data, status }
      }
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};

      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getAllFinancePercents(siteId, query, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const updateFinancePercents: any = async (
    siteId: string,
    body: any,
    headers = initHeaders,
  ) => {
    try {
      setIsLoading(true);
      const {
        data: {
          data,
          status,
        },
      } = await SitePercentsService.updateFinancePercents(headers, siteId, body);
      return { data, status };
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};

      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await updateFinancePercents(siteId, body, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    getAllProviderPercents,
    updateProviderPercents,
    getAllFinancePercents,
    updateFinancePercents,
    error,
  };
};
