import { GridValueGetterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import FilterField from 'components/filter/columnFilter';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { IFilterData } from 'types/accounts';
import { columnsName, ROW_PER_PAGE } from 'constants/common';
import { dateFormat, isEmpty, makeQueryUrl } from 'utils/utils';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  TablePagination,
} from '@mui/material';
import DropDownSelect from 'components/select/select';
import { useDispatch } from 'react-redux';
import { setResetAll } from 'redux/actions/commonActions';
import SitesList from 'components/filter/siteLists';
import { useFinance } from 'hooks/finances/useFinance';
import { useTranslation } from 'react-i18next';
import CurrencyList from 'components/filter/currencyLists';
import styles from '../table.module.scss';

export const depositTypeObj: { [x: string]: string } = {
  1: 'real',
  2: 'bonus',
};

export const balanceTypeObj: { [x: string]: string } = {
  1: 'wallet',
  2: 'game',
  3: 'bonus',
};

export default function DepositsTable() {
  const { t } = useTranslation('common');
  const { t: clientDetailsT } = useTranslation('clientsDetails');

  const dispatch = useDispatch();
  const {
    isLoading,
    columns: columnNames,
    pageLists,
    totalItems,
    depositList: data,
    getAllDepositChanges,
  } = useFinance();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROW_PER_PAGE);
  const [resetGoToPage, setResetGoToPage] = useState(false);
  const [filterData, setFilterData] = useState<IFilterData>({} as IFilterData);
  const [sortData, setSortData] = useState<IFilterData>({} as IFilterData);
  const [selectedStatus, setSelectedStatus] = useState('');

  const handleStatusChange = (e: SelectChangeEvent) => {
    const { value } = e.target;
    const depositType = depositTypeObj[value] ?? '';
    setFilterData((filterby) => ({ ...filterby, depositType }));
    setSelectedStatus(value);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (newPage >= +pageLists[pageLists.length - 1].val) {
      setPage(+pageLists[pageLists.length - 1].val);
    } else {
      setPage(newPage);
    }
    setResetGoToPage((prev) => !prev);
  };

  const handleFilter = (name: string, value: string) => {
    if (value !== '') {
      setFilterData((filterby) => ({ ...filterby, [name]: value }));
    } else {
      if (filterData?.[name]) {
        delete filterData[name];
        setFilterData({ ...filterData });
      }
    }
  };

  const handleSortBy = (sortBy: string, sortDir: string) => {
    setSortData({ [sortBy]: sortDir });
  };

  const handleResetAll = () => {
    dispatch(setResetAll(true));
    setFilterData({ siteId: filterData.siteId } as IFilterData);
    setSortData({});
    setSelectedStatus('');
  };

  const handleSelectPage = (page: string) => {
    setPage(+page - 1);
  };

  const generateDynamicColumns = useMemo(() => {
    const columns = columnNames.map((el) => {
      if (el === columnsName.CREATED_AT || el === columnsName.DATE) {
        return {
          field: el,
          minWidth: el.length * 6 + 120,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isCamelCase
            />
          ),
          valueGetter: (params: GridValueGetterParams) =>
            dateFormat(params.row.createdAt || params.row.date),
        };
      } else if (el === 'info') {
        return {
          field: el,
          width: el.length * 6 + 200,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isCamelCase
            />
          ),
          renderCell: (params: GridValueGetterParams) => {
            return (
              <Box
                style={{ minHeight: '60px', display: 'flex', alignItems: 'center' }}
                component="div"
              >
                {params?.row?.info?.includes('Withdraw') ? t('withdraw') : params?.row?.info ?? ''}
              </Box>
            );
          },
        };
      } else if (el == columnsName.USER) {
        return {
          field: el,
          minWidth: el.length * 6 + 100,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isCamelCase
            />
          ),
          renderCell: (params: GridValueGetterParams) => (
            <Link
              color={'text.primary'}
              component="a"
              target="blank"
              onClick={() => {
                window.open(`/clients/details/${params.row.userId}`);
              }}
            >
              {params.row.user}
            </Link>
          ),
        };
      }
      return {
        field: el,
        minWidth: el.length * 6 + 100,
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => (
          <FilterField
            fieldName={el}
            onBlur={(value) => handleFilter(el, value)}
            onSort={handleSortBy}
            sortData={sortData}
            isCamelCase
            isDisabled={el === columnsName.BALANCE_TYPE}
          />
        ),
      };
    });

    return [...columns];
  }, [columnNames, sortData]);

  const columns = generateDynamicColumns as GridColDef[];

  useEffect(() => {
    const query = makeQueryUrl(filterData);
    const sortBy = Object.entries(sortData)[0];
    let paramsQuery = isEmpty(sortData)
      ? query
      : `${query}&sortBy=${sortBy[0]}&sortDir=${sortBy[1]}`;

    paramsQuery += `&page=${page + 1}&pageSize=${rowsPerPage}`;
    if (filterData.siteId) getAllDepositChanges(filterData.siteId, paramsQuery);
  }, [filterData, sortData, page, rowsPerPage]);

  return (
    <div style={{ width: '100%' }}>
      <Box className={styles.filter_box}>
        <div className={styles.filter_btn}>
          <Box sx={{ display: 'flex', mt: 2, mb: 2 }}>
            <DropDownSelect
              totalPages={pageLists}
              onSelectPage={handleSelectPage}
              reset={resetGoToPage}
            />
            {Object.values(filterData).join() || Object.values(sortData).join() ? (
              <Button className={styles.reset_all_btn} variant="contained" onClick={handleResetAll}>
                {t('resetAll')}
              </Button>
            ) : null}
          </Box>
        </div>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Box>
          <SitesList onSelectSite={handleFilter} margin="0 24px 24px 0" />
        </Box>
        <Box sx={{ minWidth: 100, mr: 3 }}>
          <CurrencyList
            initValue={isEmpty(filterData) || !filterData?.currencyId}
            onSelectSite={handleFilter}
          />
        </Box>
        <Box sx={{ minWidth: 200, mr: 3 }}>
          <FormControl fullWidth size="small">
            <InputLabel id="deposit-label">{clientDetailsT('depositTypes')}</InputLabel>
            <Select
              labelId="deposit-label"
              id="deposit-select"
              label={clientDetailsT('depositTypes')}
              value={selectedStatus}
              onChange={handleStatusChange}
            >
              <MenuItem value="">
                <em>{t('none')}</em>
              </MenuItem>
              {[
                { id: 1, name: 'real' },
                { id: 2, name: 'bonus' },
              ].map((item, index) => {
                return (
                  <MenuItem key={index} value={item.id}>
                    {clientDetailsT(item.name)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      </Box>
      {!isLoading && filterData?.siteId ? (
        <>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            labelRowsPerPage={t('rowsPerPage')}
            labelDisplayedRows={({ from, to, count }) =>
              `${from >= to ? to : from}-${to} ${t('of')} ${count}`
            }
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <DataGrid
            autoHeight
            rows={filterData?.siteId ? data : []}
            columns={columns}
            hideFooterPagination={true}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  userId: false,
                  newBalance: false,
                  oldBalance: false,
                },
              },
            }}
            sx={{
              '.MuiDataGrid-iconButtonContainer': {
                visibility: 'visible',
              },
              '.MuiDataGrid-sortIcon': {
                opacity: 'inherit !important',
              },
              '.MuiDataGrid-cell': {
                '&:last-child': {
                  justifyContent: 'center !important',
                },
              },
              '.MuiDataGrid-footerContainer': {
                display: 'none',
              },
            }}
          />
        </>
      ) : (
        <Box> {t('loading')}</Box>
      )}
    </div>
  );
}
