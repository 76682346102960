import { Box, TextField, Button, Typography, Divider, Switch, AlertColor } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useEffect, useState } from 'react';
import { FIELD_NAME, IEditSite } from 'types/sites';
import EmptyTextarea from 'components/textArea/textArea';
import { useSelector } from 'react-redux';
import { CurrencyCheckboxes } from 'components/checkbox/currencies';
import { useSites } from 'hooks/sites/useSites';
import { useTranslation } from 'react-i18next';
import Result from 'components/alert/alert';
import { ICurrency } from 'hooks/currency/useCurrency';
import { useSiteLanguage } from 'hooks/language/useLanguage';
import { isValidValueForEditAddSite } from 'utils/helpers/inputHelper';
import common from 'styles/common.module.scss';
import { ERROR, SUCCESS } from 'constants/common';
import styles from './editSites.module.scss';

export const initialData = {
  name: '',
  title: '',
  url: '',
  isActive: true,
  metaDescription: '',
  metaName: '',
  disabledText: '',
  isPayinsVisible: true,
  isBonusesVisible: true,
  isPayoutsVisible: true,
  cashbackPercent: null,
  currencyIds: [],
  languageIds: [],
  phoneConfirmMethod: [],
  isWheelEnabled: true,
  canAgentWithdraw: true,
};

const style = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
};

const fields = {
  [FIELD_NAME.NAME]: true,
  [FIELD_NAME.TITLE]: true,
  [FIELD_NAME.URL]: true,
  [FIELD_NAME.CASHBACK_PERCENT]: true,
  [FIELD_NAME.CALL]: true,
  [FIELD_NAME.DISABLE_TEXT]: true,
  [FIELD_NAME.META_DESCRIPTION]: true,
  [FIELD_NAME.META_NAME]: true,
};

function EditSite() {
  const { isLoading: loadLanguage, languageList, getAllLanguages } = useSiteLanguage();
  const { t: siteSettingsT } = useTranslation('siteSettings');
  const { t: siteT } = useTranslation('sites');
  const { t: validationT } = useTranslation('validations');
  const { t: commonT } = useTranslation('common');
  const { saveSite, error } = useSites();
  const [success, setSuccess] = useState('');
  const [currencylist, serCurrencylist] = useState<ICurrency[]>([]);
  const [type, setType] = useState<AlertColor>(SUCCESS);
  const [formData, setFormData] = useState<IEditSite>({ ...initialData });
  const [validFields, setValidFields] = useState({ ...fields });
  const [validationError, setValidationError] = useState({
    phoneConfirmMethod: false,
    currencyIds: false,
    languageIds: false,
  });
  const site = useSelector<any>((state) => state.currentSite) as IEditSite;
  const { currentId } = useSelector<any>((state) => state.currentId) as {
    currentId: { [key: string]: string };
  };

  let timeOutId: ReturnType<typeof setTimeout>;

  const handleOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: string,
  ) => {
    const { value } = e.target;
    const data = { ...formData, [name]: value };
    setFormData(data);
  };

  const handleCheckboxChange = (id: number, name: string) => {
    setValidationError((error) => ({
      ...error,
      [name]: false,
    }));

    const checkboxList = [...formData[name]];

    if (checkboxList.includes(id)) {
      const index = checkboxList.findIndex((el) => el == id);
      checkboxList.splice(index, 1);
      const data = { ...formData, [name]: [...checkboxList] };
      setFormData(data);
    } else {
      checkboxList.push(+id);
      const data = { ...formData, [name]: [...checkboxList] };
      setFormData(data);
    }
  };

  const handleOnChangeTextArea = (val: string, key: string) => {
    const data = { ...formData, [key]: val };
    setValidFields((fields) => ({
      ...fields,
      [key]: true,
    }));
    setFormData(data);
  };

  const isValidData = (data: IEditSite) => {
    setValidationError((error) => ({
      ...error,
      phoneConfirmMethod: !data.phoneConfirmMethod.length,
      currencyIds: !data.currencyIds.length,
      languageIds: !data.languageIds.length,
    }));

    if (data.disabledText === '' || data.disabledText === null) {
      setValidFields((fields) => ({
        ...fields,
        [FIELD_NAME.DISABLE_TEXT]: false,
      }));
    }
    if (data.metaName === '' || data.metaName === null) {
      setValidFields((fields) => ({
        ...fields,
        [FIELD_NAME.META_NAME]: false,
      }));
    }
    if (data.metaDescription === '' || data.metaDescription === null) {
      setValidFields((fields) => ({
        ...fields,
        [FIELD_NAME.META_DESCRIPTION]: false,
      }));
    }
    return (
      data.metaDescription !== '' &&
      data.metaDescription !== null &&
      data.metaName !== '' &&
      data.metaName !== null &&
      data.disabledText !== '' &&
      data.disabledText !== null &&
      data.phoneConfirmMethod.length &&
      data.currencyIds.length &&
      data.languageIds.length
    );
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { currencies: _, languages: __, ...data } = { ...formData, currencies: [] };
    isValidData(data);
    if (isValidData(data)) {
      const res = await saveSite(currentId.siteId, data);

      if (res?.status === 200) {
        setSuccess(commonT('savedSuccess'));
        setType(SUCCESS);
        timeOutId = setTimeout(() => {
          setSuccess('');
        }, 3000);
      } else {
        setType(ERROR);
      }
    }
  };

  const handleOnSwicth = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const { checked } = e.target;

    const data = { ...formData, [key]: checked };
    setFormData(data);
  };

  const handlePhoneConfirmMethod = (e: React.ChangeEvent<HTMLInputElement>, method: string) => {
    const { checked } = e.target;
    let data = null;
    if (checked) {
      data = { ...formData, phoneConfirmMethod: [...formData.phoneConfirmMethod, method] };
    } else {
      data = {
        ...formData,
        phoneConfirmMethod: [...formData.phoneConfirmMethod.filter((el) => el !== method)],
      };
    }

    setValidationError((error) => ({
      ...error,
      phoneConfirmMethod: false,
    }));
    setFormData(data);
  };

  const handleTransactionOptions = (e: React.ChangeEvent<HTMLInputElement>, method: string) => {
    const { checked } = e.target;
    setFormData({ ...formData, [method]: checked });
  };

  const validate = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, name: string) => {
    const { value } = e.target;
    setValidFields((fields) => ({
      ...fields,
      [name]: isValidValueForEditAddSite(value, name),
    }));
  };

  useEffect(() => {
    setFormData({ ...site });
    serCurrencylist(site?.currencies ?? []);
    if (!languageList.length) getAllLanguages();
    return () => {
      clearTimeout(timeOutId);
    };
  }, [site]);

  return (
    <>
      <Box sx={style}>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, maxWidth: '600px' }}>
          <TextField
            margin="normal"
            id="Name"
            label={siteSettingsT('inputForName')}
            fullWidth
            variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
            value={formData?.name ?? site?.name}
            onChange={(e) => handleOnChange(e, FIELD_NAME.NAME)}
            onBlur={(e) => validate(e, FIELD_NAME.NAME)}
            error={!validFields[FIELD_NAME.NAME]}
            helperText={validFields[FIELD_NAME.NAME] ? '' : validationT('siteNameValidation')}
          />
          <TextField
            fullWidth
            margin="normal"
            name="URL"
            label={siteSettingsT('inputForSiteUrl')}
            variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
            value={formData?.url ?? site?.url}
            onChange={(e) => handleOnChange(e, FIELD_NAME.URL)}
            onBlur={(e) => validate(e, FIELD_NAME.URL)}
            error={!validFields[FIELD_NAME.URL]}
            helperText={validFields[FIELD_NAME.URL] ? '' : validationT('invalidUrl')}
          />
          <TextField
            margin="normal"
            fullWidth
            name="title"
            label={siteSettingsT('inputForSiteTitle')}
            variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
            value={formData?.title ?? site?.title}
            onChange={(e) => handleOnChange(e, FIELD_NAME.TITLE)}
            onBlur={(e) => validate(e, FIELD_NAME.TITLE)}
            error={!validFields[FIELD_NAME.TITLE]}
            helperText={validFields[FIELD_NAME.TITLE] ? '' : validationT('siteNameValidation')}
          />

          <Box sx={{ m: 1, display: 'flex', flexDirection: 'column' }}>
            <Typography variant="subtitle1" sx={{ mt: 1 }}>
              {siteSettingsT('currency')}
            </Typography>
            <Divider sx={{ m: 1 }} />
            {!currencylist.length ? (
              <Typography>{commonT('loading')}</Typography>
            ) : (
              <>
                <CurrencyCheckboxes
                  list={currencylist}
                  idList={formData?.currencyIds ?? site?.currencyIds}
                  onChange={(e) => handleCheckboxChange(e, 'currencyIds')}
                />
                {validationError.currencyIds ? (
                  <Typography
                    variant="subtitle1"
                    sx={{ fontSize: '12px', color: common.mainwarning }}
                  >
                    {commonT('requiredCyrrency')}
                  </Typography>
                ) : null}
              </>
            )}
            <Typography variant="subtitle1" sx={{ mt: 1 }}>
              {siteT('languages')}
            </Typography>
            <Divider sx={{ m: 1 }} />
            {loadLanguage ? (
              <Typography>{commonT('loading')}</Typography>
            ) : (
              <>
                <CurrencyCheckboxes
                  isLanguage
                  list={languageList}
                  idList={formData?.languageIds ?? site?.languageIds}
                  onChange={(e) => handleCheckboxChange(e, 'languageIds')}
                />
                {validationError.languageIds ? (
                  <Typography
                    variant="subtitle1"
                    sx={{ fontSize: '12px', color: common.mainwarning }}
                  >
                    {commonT('requiredLang')}
                  </Typography>
                ) : null}
              </>
            )}
            <Divider sx={{ m: 3 }} />
            <Box className={styles.active}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!(formData?.isActive ?? site?.isActive)}
                      onChange={(e) => handleOnSwicth(e, FIELD_NAME.IS_ACTIVE)}
                    />
                  }
                  label={siteSettingsT('isActive')}
                />
              </FormGroup>
            </Box>
            <Box className={styles.active}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!(formData?.canAgentWithdraw ?? site?.canAgentWithdraw)}
                      onChange={(e) => handleOnSwicth(e, FIELD_NAME.CAN_AGENT_WITHDRAW)}
                    />
                  }
                  label={siteSettingsT('canWithdraw')}
                />
              </FormGroup>
            </Box>
            <Divider sx={{ m: 1 }} />

            <Box>
              <FormGroup>
                <Typography>{siteSettingsT('transactionDisplayOptions')}</Typography>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.isPayinsVisible}
                      onChange={(e) =>
                        handleTransactionOptions(e, FIELD_NAME.DISPLAY_DEPOSIT_HISTORY)
                      }
                    />
                  }
                  label={siteSettingsT('displayDepositHistory')}
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.isPayoutsVisible}
                      onChange={(e) =>
                        handleTransactionOptions(e, FIELD_NAME.DISPLAY_WITHDRAW_HISTORY)
                      }
                    />
                  }
                  label={siteSettingsT('displayWithdrawHistory')}
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.isBonusesVisible}
                      onChange={(e) =>
                        handleTransactionOptions(e, FIELD_NAME.DISPLAY_BONUS_HISTORY)
                      }
                    />
                  }
                  label={siteSettingsT('displayBonusHistory')}
                />
              </FormGroup>
            </Box>
            <Divider />

            <Box className={styles.controlMethod}>
              <FormGroup>
                <Typography>{siteSettingsT('confirmMethod')}</Typography>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        formData?.phoneConfirmMethod?.includes(FIELD_NAME.CALL) ??
                        site?.phoneConfirmMethod?.includes(FIELD_NAME.CALL)
                      }
                      onChange={(e) => handlePhoneConfirmMethod(e, FIELD_NAME.CALL)}
                    />
                  }
                  label={siteSettingsT('call')}
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        formData?.phoneConfirmMethod?.includes(FIELD_NAME.SMS) ??
                        site?.phoneConfirmMethod?.includes(FIELD_NAME.SMS)
                      }
                      onChange={(e) => handlePhoneConfirmMethod(e, FIELD_NAME.SMS)}
                    />
                  }
                  label={siteSettingsT('sms')}
                />
                {validationError.phoneConfirmMethod ? (
                  <Typography
                    variant="subtitle1"
                    sx={{ fontSize: '12px', color: common.mainwarning }}
                  >
                    {commonT('requiredCall')}
                  </Typography>
                ) : null}
              </FormGroup>
            </Box>
            <Divider sx={{ m: 1 }} />

            <Box className={styles.meta_name}>
              <Typography variant="subtitle1">{siteSettingsT('inactiveSiteText')}</Typography>
              <EmptyTextarea
                defaultValue={formData?.disabledText ?? ''}
                onChange={(val) => handleOnChangeTextArea(val, FIELD_NAME.DISABLE_TEXT)}
                error={validFields[FIELD_NAME.DISABLE_TEXT] ? '' : commonT('required')}
              />
            </Box>
            <Box className={styles.meta_name}>
              <Typography variant="subtitle1" sx={{ mr: 2 }}>
                {siteSettingsT('metaName')}
              </Typography>
              <EmptyTextarea
                defaultValue={formData?.metaName ?? ''}
                onChange={(val) => handleOnChangeTextArea(val, FIELD_NAME.META_NAME)}
                error={validFields[FIELD_NAME.META_NAME] ? '' : commonT('required')}
              />
            </Box>
            <Box className={styles.meta_name}>
              <Typography variant="subtitle1" sx={{ mr: 2 }}>
                {siteSettingsT('metaDescription')}
              </Typography>
              <Box>
                <EmptyTextarea
                  defaultValue={formData?.metaDescription ?? ''}
                  onChange={(val) => handleOnChangeTextArea(val, FIELD_NAME.META_DESCRIPTION)}
                  error={validFields[FIELD_NAME.META_DESCRIPTION] ? '' : commonT('required')}
                />
              </Box>
            </Box>
            <Divider sx={{ m: 1 }} />
            <TextField
              margin="normal"
              fullWidth
              type="number"
              name="cashback"
              label={siteSettingsT('inputForCashbackPercent')}
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
              value={formData?.cashbackPercent ?? site?.cashbackPercent}
              onChange={(e) => handleOnChange(e, FIELD_NAME.CASHBACK_PERCENT)}
              onBlur={(e) => validate(e, FIELD_NAME.CASHBACK_PERCENT)}
              error={!validFields[FIELD_NAME.CASHBACK_PERCENT]}
              helperText={
                validFields[FIELD_NAME.CASHBACK_PERCENT] ? '' : validationT('onlyPositive')
              }
            />
          </Box>
          {(success || error) && (
            <Result style={{ mb: 2 }} message={success || error} type={type} isShow />
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Button
              fullWidth
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={
                !validFields[FIELD_NAME.CASHBACK_PERCENT] ||
                !validFields[FIELD_NAME.NAME] ||
                !validFields[FIELD_NAME.TITLE] ||
                !validFields[FIELD_NAME.URL] ||
                !validFields[FIELD_NAME.META_DESCRIPTION] ||
                validationError.currencyIds ||
                validationError.languageIds ||
                validationError.phoneConfirmMethod
              }
            >
              {siteSettingsT('save')}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default EditSite;
