import http from '../http-common';

class AuthDataService {
  login = (data) => http.post('/auth/login', data);
  refreshToken = (data) => http.post('/auth/refresh', data);
}

const AuthService = new AuthDataService();

export default AuthService;
