import { Box, IconButton, TextareaAutosize } from '@mui/material';
import { useState, useRef, useEffect } from 'react';
import { IChatLetter } from 'types/chat';
import UploadButtons from 'components/imageUpload/imageUpload';
import SendIcon from '@mui/icons-material/Send';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import common from 'styles/common.module.scss';
import moment from 'moment';
import Dialogs from 'components/dialog/dialogs';
import style from './chat.module.scss';

const moked_data = [
  {
    id: '1',
    from: 'admin',
    text: 'aaaaaaaaaaaaa',
    image: '',
    createdAt: '2023-09-01T17:12:30.173Z',
  },
  {
    id: '2',
    from: 'user',
    text: 'bbbbbbbbbbbbb',
    image: '',
    createdAt: '2023-09-01T17:12:27.720Z',
  },
  {
    id: '3',
    from: 'admin',
    text: 'ccccccccccccc',
    image: '',
    createdAt: '2023-09-01T17:12:32.293Z',
  },
  {
    id: '4',
    from: 'user',
    text: 'ddddddddddddddddddddddddddddddddddddd',
    image: '',
    createdAt: '2023-09-01T17:12:26.829Z',
  },
];

moked_data.sort((a, b) => a.createdAt.localeCompare(b.createdAt));

const messageData = {
  id: '',
  image: '',
  from: '',
  text: '',
  createdAt: '',
};

function ChatArea() {
  const [chatData, setChatData] = useState(moked_data);
  const [textValue, setTextValue] = useState('');
  const [open, setOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  const [formData, setFormData] = useState<IChatLetter>({ ...messageData });

  const chatEndRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatData]);

  const handleOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    key: string,
  ) => {
    const { value } = e.target;
    const data = { ...formData, [key]: value };
    setFormData(data);
    setTextValue(value);
  };

  const onImageUpload = (data: string) => {
    setFormData((formData) => ({ ...formData, image: data }));
  };

  const removeImage = () => {
    const data = { ...formData, image: '' };
    setFormData(data);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const dates = new Date();
    const data = {
      ...formData,
      id: `${Date.now()}`,
      from: 'admin',
      image: formData.image,
      createdAt: `${moment(dates).format('DD/MM/Y HH:mm:ss')}`,
    };

    setFormData(messageData);
    setTextValue('');
    if (data.image != '' || data.text != '') {
      setChatData([...chatData, data]);
    }
  };

  return (
    <>
      <div className={style.mainArea}>
        <div className={style.chatArea}>
          {chatData.map((elm, index) => {
            return (
              <div
                className={elm.from == 'admin' ? style.adminArea : style.userArea}
                key={index}
                style={{ width: elm.text.length }}
                ref={(ref) => {
                  if (index === chatData.length - 1) {
                    chatEndRef.current = ref;
                  }
                }}
              >
                {elm.from != chatData[index - 1]?.from ? (
                  <p>
                    {elm.from.substring(0).toUpperCase()}:{elm.text}
                  </p>
                ) : (
                  <p>{elm.text}</p>
                )}
                {elm.image && (
                  <a
                    onClick={() => {
                      setOpen(true);
                      setImageUrl(elm.image);
                    }}
                    href={elm.image}
                    title="ImageName"
                  >
                    <img width={190} height={50} alt="ImageName" src={elm.image} />
                  </a>
                )}
                {<span>{elm.createdAt}</span>}
              </div>
            );
          })}
        </div>

        <div className={style.latterArea}>
          <Box component="form" onSubmit={handleSubmit} sx={{ margin: '5px', display: 'flex' }}>
            <TextareaAutosize
              value={textValue}
              style={{
                width: '100%',
                height: '80px',
                padding: '20px',
                marginTop: '30px',
                borderRadius: '10px',
              }}
              onChange={(e) => handleOnChange(e, 'text')}
            />
            <Box className={style.buttonArea} sx={{ display: 'flex', alignItems: 'center' }}>
              <UploadButtons
                onImageUpload={onImageUpload}
                initialImage={formData?.image ?? null}
                forChat={true}
                width={'100'}
                height={'60'}
              />
              {formData?.image ? (
                <DeleteForeverIcon
                  sx={{
                    color: common.mainwarning,
                    fontSize: 17,
                    marginTop: 3.8,
                    cursor: 'pointer',
                  }}
                  onClick={removeImage}
                />
              ) : null}
              <IconButton
                aria-label="delete"
                size="large"
                type="submit"
                sx={{
                  ':hover': {
                    backgroundColor: 'white',
                  },
                }}
              >
                <SendIcon
                  sx={{
                    color: common.mainBlue,
                    marginTop: 2,
                  }}
                />
              </IconButton>
            </Box>
          </Box>
        </div>
      </div>
      <Dialogs
        imageContent={imageUrl}
        isOpen={open}
        onClose={() => setOpen(false)}
        forChat={true}
      />
    </>
  );
}

export default ChatArea;
