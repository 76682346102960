import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  TextField,
  Select,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FIELD_NAME } from 'types/sitesSettings';
import { ICurrencyMethods } from 'types/sites';
import { isNumber } from 'utils/validations/validationTypes';
import styles from './inputCell.module.scss';
import { PAY_MAX, PAY_MIN } from 'constants/payment';

const fields = {
  [FIELD_NAME.MINVALUE]: true,
  [FIELD_NAME.MAXVALUE]: true,
} as { [x: string]: boolean };

interface InputCellProps {
  onChange: (data: any) => void;
  siteCurrencies: ICurrencyMethods[];
  type: string;
  id: string;
}

interface IMinMax {
  min: number;
  max: number;
}

export function InputCell({ onChange, siteCurrencies, type, id }: InputCellProps) {
  const { t: siteSettingsT } = useTranslation('siteSettings');
  const { t: commonT } = useTranslation('common');
  const { t: validationT } = useTranslation('validations');
  const [value, setValue] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState(
    siteCurrencies.length === 1 ? siteCurrencies?.[0]?.currencyId + '' : '',
  );
  const [validFields, setValidFields] = useState({ ...fields });
  const [minMax] = useState<IMinMax>({ min: PAY_MIN, max: PAY_MAX });

  const [cellData, setCellData] = useState(
    siteCurrencies.length === 1
      ? {
          amount: siteCurrencies?.[0]?.[type] ?? '',
          payId: siteCurrencies?.[0]?.id ?? '',
          id: '',
        }
      : {
          amount: '',
          payId: '',
          id: '',
        },
  );

  const validate = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, name: string) => {
    const { value } = e.target;
    if (selectedCurrency) {
      setValidFields((fields) => ({
        ...fields,
        [name]: +value >= minMax.min && +value <= minMax.max,
      }));
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;
    if (isNumber(value) || value === '' || value === '-') {
      setCellData((data: any) => ({ ...data, amount: value }));
      setValue(value);
    }
  };

  const handleOnClick = () => {
    const requetsData = value !== '' ? { ...cellData, amount: value, type } : { ...cellData, type };
    const isValid = +requetsData.amount >= minMax.min && +requetsData.amount <= minMax.max;

    if (requetsData.amount && isValid && selectedCurrency) {
      onChange(requetsData);
    } else if (!isValid && selectedCurrency) {
      setValidFields((fields) => ({
        ...fields,
        [type]: false,
      }));
    }
  };

  const handleCurrencyChange = (e: SelectChangeEvent) => {
    const { value } = e.target;
    const filteredData = siteCurrencies.filter((el: any) => el.currencyId === value);
    setCellData((data: any) => ({
      ...data,
      amount: filteredData?.[0]?.[type] ?? '',
      payId: filteredData?.[0]?.id ?? '',
      id,
    }));
    setSelectedCurrency(value);
  };

  return (
    <Box className={styles.amount_part}>
      <FormControl
        size="small"
        sx={{
          minWidth: '110px',
        }}
      >
        <InputLabel id="client-currency-label">{siteSettingsT('currency')}</InputLabel>
        <Select
          fullWidth
          labelId="client-currency-label"
          label={siteSettingsT('currency')}
          id="currency-select"
          value={selectedCurrency}
          onChange={handleCurrencyChange}
        >
          <MenuItem value="">
            <em>{commonT('none')}</em>
          </MenuItem>
          {siteCurrencies?.map((item: any, index: number) => {
            return (
              <MenuItem key={index} value={item.currencyId}>
                {item.currencyCode}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <Box sx={{ m: 1, minWidth: '100px' }}>
        <TextField
          size="small"
          margin="normal"
          fullWidth
          required
          variant="standard"
          InputLabelProps={{
            shrink: true,
          }}
          value={value ? value : cellData.amount}
          onChange={handleOnChange}
          onBlur={(e) => validate(e, type)}
          error={!validFields[type]}
          helperText={validFields[type] ? '' : validationT('minMaxForMethods', { ...minMax })}
        />
      </Box>

      <Button variant="contained" onClick={handleOnClick} sx={{ minWidth: '100px' }}>
        {siteSettingsT('save')}
      </Button>
    </Box>
  );
}
