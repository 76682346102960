import { actionTypes } from 'redux/constants/actionTypes';

const initial = [];

export const wheelsReducer = (state = initial, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_WHEELS_LIST:
      return [...payload];
    case actionTypes.ADD_WHEEL:
      return [payload, ...state];
    default:
      return state;
  }
};
