const unUsedDataMock = {
  support: ['updatedAt', 'siteIds', 'deletedAt', 'role'],
  agent: ['updatedAt', 'siteIds', 'deletedAt'],
  main: ['updatedAt', 'siteIds', 'deletedAt'],
  operator: ['updatedAt', 'siteIds', 'deletedAt'],
  manager: ['updatedAt', 'siteIds', 'deletedAt'],
  clientHistory: ['updatedAt', 'userId', 'deletedAt'],
  labels: ['updatedAt', 'deletedAt'],
  wheelsGroups: ['updatedAt', 'deletedAt', 'settingId'],
  siteBonuses: ['description', 'deletedAt', 'siteId', 'updatedAt', 'weidgerDate'],
  siteLevels: ['updatedAt', 'deletedAt', 'siteId', 'additionalText', 'bonusText'],
  promotion: ['updatedAt', 'deletedAt'],
  promotionMulti: ['updatedAt', 'deletedAt', 'account'],
  sites: ['disabledText', 'metaDescription', 'deletedAt', 'metaName', 'updatedAt'],
  clients: [
    'updatedAt',
    'deletedAt',
    'name',
    'isEmailConfirmed',
    'isPhoneConfirmed',
    'managerId',
    'labelId',
    'promo',
    'levelGroupId',
    'blockMessage',
    'clientBlockMessage',
    'levelChangedAt',
    'birthdayAt',
    'blockedUntil',
    'lastActiveAt',
  ],
  gameSessions: ['isAlive'],
  providerPayIns: ['methodId'],
  transitionPayOut: ['methodId', 'image'],
  gameLogs: ['updatedAt', 'deletedAt'],
  statisticsFinance:['balance']
};

const itemUnUsedDataMock = {
  support: ['id', 'updatedAt', 'createdAt', 'deletedAt'],
  agent: ['id', 'updatedAt', 'createdAt', 'deletedAt'],
  main: ['id', 'updatedAt', 'createdAt', 'deletedAt'],
  operator: ['id', 'updatedAt', 'createdAt', 'deletedAt'],
  manager: ['id', 'updatedAt', 'createdAt', 'deletedAt'],
  sites: ['id', 'updatedAt', 'createdAt', 'deletedAt', 'siteId'],
};

export const prepareTableData = (data, role = '') => {
  const unUsedArrList = role ? unUsedDataMock[role] : [];
  const preparedData = data.map((el) => {
    let temp = {};
    for (const key in el) {
      if (!unUsedArrList.includes(key)) {
        temp = { ...temp, [key]: el[key] };
      }
    }
    return temp;
  });

  return preparedData;
};

export const prepareItemData = (data, role) => {
  const unUsedArrList = role ? itemUnUsedDataMock[role] : [];
  let currencyIds = [];
  let languageIds = [];
  let temp = {};

  if ((role === 'sites' && data.currencies) || data.languages) {
    data?.currencies?.reduce((acc, el) => {
      acc.push(el.id);
      return acc;
    }, currencyIds);

    data?.languages?.reduce((acc, el) => {
      acc.push(el.id);
      return acc;
    }, languageIds);

    temp = { ...temp, currencyIds, languageIds };
  }

  for (const key in data) {
    if (!unUsedArrList.includes(key)) {
      temp = { ...temp, [key]: data[key] };
    }
  }
  return temp;
};


export const preparedColumnNames = (data, orderingObject = {}) => {
  /**TODO: later put this in useMemo for optimization */
  const columns =  data.length ? Object.keys(data[0]) : [];
  if (Object.keys(orderingObject).length) {
    for (const el in orderingObject) {
      const index = orderingObject[el];
      columns.splice(index, 0, `${el}`);
    }
    const set = new Set(columns);
    return [...set];
  }
  return  columns;
};

export const prepareDataForSelectPages = (totalPages) => {
  const temp = [];
  for (let i = 0; i < totalPages; i = i + 2) {
    temp.push({ val: i + 1 + '', id: i });
  }
  return !temp.length ? ['1'] : temp;
};
