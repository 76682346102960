import { Box, TextField, Button, Typography, Divider, Switch } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Result from 'components/alert/alert';
import { useEffect, useState } from 'react';
import { FIELD_NAME, ICreateSite } from 'types/sites';
import { useCurrency } from 'hooks/currency/useCurrency';
import { useSites } from 'hooks/sites/useSites';
import { useTranslation } from 'react-i18next';
import common from 'styles/common.module.scss';
import { useSiteLanguage } from 'hooks/language/useLanguage';
import { isValidValueForEditAddSite } from 'utils/helpers/inputHelper';
import styles from './modal.module.scss';

interface CreateSiteProps {
  title: string;
  onClose: () => void;
}

export const initialData = {
  name: '',
  title: '',
  url: '',
  currencyIds: [],
  languageIds: [],
  isActive: true,
};

const style = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
};

const fields = {
  [FIELD_NAME.NAME]: true,
  [FIELD_NAME.TITLE]: true,
  [FIELD_NAME.URL]: true,
};

function CreateSite({ title, onClose }: CreateSiteProps) {
  const { t } = useTranslation('sites');
  const { t: validationT } = useTranslation('validations');
  const { t: commonT } = useTranslation('common');

  const [formData, setFormData] = useState<ICreateSite>({ ...initialData });
  const { currencylist, getCurrency } = useCurrency();
  const { languageList, getAllLanguages } = useSiteLanguage();
  const { getAllSites, createSite, error } = useSites();
  const [validFields, setValidFields] = useState({ ...fields });
  const [validationError, setValidationError] = useState({
    currencyIds: false,
    languageIds: false,
  });

  const isValidData = (data: ICreateSite) => {
    setValidationError((error) => ({
      ...error,
      currencyIds: !data.currencyIds.length,
      languageIds: !data.languageIds.length,
    }));

    return data.currencyIds.length && data.languageIds.length;
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: string,
  ) => {
    const { value } = e.target;
    const data = { ...formData, [name]: value };
    setFormData(data);
  };

  const handleOnChecked = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
    const id = +event.currentTarget.id;
    const { checked } = event.target;
    const { currencyIds, languageIds } = formData;
    let data = null;

    if (name === 'currencyIds') {
      if (checked) {
        currencyIds.push(+id);
      } else if (currencyIds.includes(+id)) {
        const index = currencyIds.findIndex((el) => el == id);
        currencyIds.splice(index, 1);
      }
      data = { ...formData, currencyIds: [...currencyIds] };
    } else {
      if (checked) {
        languageIds.push(+id);
      } else if (languageIds.includes(+id)) {
        const index = languageIds.findIndex((el) => el == id);
        languageIds.splice(index, 1);
      }
    }
    data = { ...formData, languageIds: [...languageIds] };
    setFormData(data);
    setValidationError((error) => ({
      ...error,
      [name]: false,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isValidData(formData)) {
      const res = await createSite(formData);
      if (res?.status === 201) {
        getAllSites();
        onClose();
      }
    }
  };

  const handleOnSwicth = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    const data = { ...formData, isActive: checked };
    setFormData(data);
  };

  const validate = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, name: string) => {
    const { value } = e.target;
    setValidFields((fields) => ({
      ...fields,
      [name]: isValidValueForEditAddSite(value, name),
    }));
  };

  const getData = async () => {
    await getCurrency();
    getAllLanguages();
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        {title}
      </Typography>
      <Box sx={style}>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, minWidth: '600px' }}>
          <TextField
            margin="normal"
            id="Name"
            label={t('inputForName')}
            fullWidth
            required
            variant="standard"
            onChange={(e) => handleOnChange(e, FIELD_NAME.NAME)}
            onBlur={(e) => validate(e, FIELD_NAME.NAME)}
            error={!validFields[FIELD_NAME.NAME]}
            helperText={validFields[FIELD_NAME.NAME] ? '' : validationT('siteNameValidation')}
          />
          <TextField
            required
            fullWidth
            margin="normal"
            name="URL"
            label={t('inputForUrl')}
            variant="standard"
            onChange={(e) => handleOnChange(e, FIELD_NAME.URL)}
            onBlur={(e) => validate(e, FIELD_NAME.URL)}
            error={!validFields[FIELD_NAME.URL]}
            helperText={validFields[FIELD_NAME.URL] ? '' : validationT('invalidUrl')}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="title"
            label={t('inputForTitle')}
            variant="standard"
            onChange={(e) => handleOnChange(e, FIELD_NAME.TITLE)}
            onBlur={(e) => validate(e, FIELD_NAME.TITLE)}
            error={!validFields[FIELD_NAME.TITLE]}
            helperText={validFields[FIELD_NAME.TITLE] ? '' : validationT('siteNameValidation')}
          />
          <Box sx={{ m: 3, display: 'flex', flexDirection: 'column' }}>
            <Box>
              <Typography variant="subtitle1" sx={{ mt: 2 }}>
                {t('currency')}
              </Typography>
              <Divider />
              <FormGroup
                sx={{ m: 1, display: 'flex', flexDirection: 'row' }}
                className={styles.currencies}
              >
                {currencylist.map(({ id, code }, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          id={id + ''}
                          onChange={(e) => handleOnChecked(e, 'currencyIds')}
                          sx={{ '& .MuiSvgIcon-root': { fontSize: 16 }, m: 0, p: 0.5 }}
                        />
                      }
                      label={code}
                    />
                  );
                })}
              </FormGroup>
              {validationError.currencyIds ? (
                <Typography
                  variant="subtitle1"
                  sx={{ fontSize: '12px', color: common.mainwarning }}
                >
                  {commonT('requiredCyrrency')}
                </Typography>
              ) : null}
            </Box>
            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              {t('languages')}
            </Typography>
            <Divider />
            <Box>
              <FormGroup
                sx={{ m: 1, display: 'flex', flexDirection: 'row' }}
                className={styles.currencies}
              >
                {languageList.map(({ id, code }, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          id={id + ''}
                          onChange={(e) => handleOnChecked(e, 'languageIds')}
                          sx={{ '& .MuiSvgIcon-root': { fontSize: 16 }, m: 0, p: 0.5 }}
                        />
                      }
                      label={code.toUpperCase()}
                    />
                  );
                })}
              </FormGroup>
              {validationError.languageIds ? (
                <Typography
                  variant="subtitle1"
                  sx={{ fontSize: '12px', color: common.mainwarning }}
                >
                  {commonT('requiredLang')}
                </Typography>
              ) : null}
            </Box>
            <Box className={styles.active}>
              <FormGroup>
                <FormControlLabel
                  control={<Switch checked={!!formData?.isActive} onChange={handleOnSwicth} />}
                  label={t('isSiteActive')}
                />
              </FormGroup>
            </Box>
          </Box>
          {error && <Result style={{ mb: 2 }} message={error} type={'error'} isShow />}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Button
              fullWidth
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={
                !validFields[FIELD_NAME.NAME] ||
                !validFields[FIELD_NAME.TITLE] ||
                !validFields[FIELD_NAME.URL] ||
                validationError.currencyIds ||
                validationError.languageIds
              }
            >
              {t('add')}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default CreateSite;
