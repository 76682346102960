import { actionTypes } from 'redux/constants/actionTypes';

const initial = {
  currentId: { siteId: null, accountId: null },
};

const initialResetAll = {
  isResetAllClicked: false,
};

export const currentIdReducer = (state = initial, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_CURRENT_ID:
      return {
        ...state,
        currentId: {
          ...payload,
        },
      };
    default:
      return state;
  }
};

export const resetAllReducer = (state = initialResetAll, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_RESET_ALL:
      return {
        ...state,
        isResetAllClicked: payload,
      };
    default:
      return state;
  }
};
