import { actionTypes } from 'redux/constants/actionTypes';

export const setAccountsData = (accounts) => {
  return {
    type: actionTypes.SET_ACCOUNTS_DATA,
    payload: accounts,
  };
};


export const addAccountData = (account) => {
  return {
    type: actionTypes.ADD_ACCOUNT_DATA,
    payload: account,
  };
};
