import { GridValueGetterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import FilterField from 'components/filter/columnFilter';
import { useClients } from 'hooks/clients/useClients';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { IFilterData } from 'types/accounts';
import { columnsName, common, ROW_PER_PAGE } from 'constants/common';
import { dateFormat, isEmpty, makeQueryUrl } from 'utils/utils';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  TablePagination,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DropDownSelect from 'components/select/select';
import LoaderWithBg from 'components/loader/loader';
import { useDispatch, useSelector } from 'react-redux';
import { setResetAll } from 'redux/actions/commonActions';
import CreateFreeSpinsOutcombet from 'components/modal/createFreeSpinsOutcombet';
import styles from './outcombetTable.module.scss';
import { IconClose } from 'components/closeButton/closeButton';
import { style } from 'components/modal/modal';

export default function OutcombetTable() {
  const { t } = useTranslation('common');
  const { t: clientsT } = useTranslation('clients');
  const { t: clientDetailsT } = useTranslation('clientsDetails');
  const theme = useTheme();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const {
    isLoading,
    totalPages,
    columns: columnNames,
    pageLists,
    getClientsData,
    totalItems,
  } = useClients();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROW_PER_PAGE);

  const clietsList = useSelector<any>((state) => state.clients) as {
    clients: any[];
  };
  const { clients: data } = clietsList;
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [resetGoToPage, setResetGoToPage] = useState(false);
  const [filterData, setFilterData] = useState<IFilterData | object>({});
  const [sortData, setSortData] = useState<IFilterData | object>({});

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (newPage >= +pageLists[pageLists.length - 1].val) {
      setPage(+pageLists[pageLists.length - 1].val);
    } else {
      setPage(newPage);
    }
    setResetGoToPage((prev) => !prev);
  };

  const handleFilter = (name: string, value: string) => {
    setFilterData((filterby) => ({ ...filterby, [name]: value }));
  };

  const handleSortBy = (sortBy: string, sortDir: string) => {
    setSortData({ [sortBy]: sortDir });
  };

  const handleResetAll = () => {
    getClientsData();
    dispatch(setResetAll(true));
    setFilterData({});
    setSortData({});
    setSelectedCurrency('');
  };

  const handleSelectPage = (page: string) => {
    setPage(+page - 1);
  };

  const handleAddFreeSpins = () => {
    setOpenModal(true);
    console.log('handleAddFreeSpins');
  };

  const generateDinamicColumns = useMemo(() => {
    const columns = columnNames.map((el) => {
      if (el === columnsName.CREATED_AT) {
        return {
          field: el,
          width: Math.ceil(window.innerWidth / columnNames.length),
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={columnsName.CREATED.toLocaleUpperCase()}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
            />
          ),
          valueGetter: (params: GridValueGetterParams) => dateFormat(params.row.createdAt),
        };
      } else if (el === 'isBlocked') {
        return {
          field: el,
          width: 150,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={columnsName.IS_BLOCKED.toLocaleUpperCase()}
              onBlur={(value) =>
                handleFilter(
                  el,
                  value === t(common.YES)
                    ? common.TRUE
                    : value === t(common.NO)
                    ? common.FALSE
                    : value,
                )
              }
              onSort={handleSortBy}
              sortData={sortData}
            />
          ),
          valueGetter: (params: GridValueGetterParams) =>
            `${params.row.isBlocked ? t(common.YES) : t(common.NO)}`,
        };
      } else if (el === 'balance') {
        return {
          field: el,
          width: 150,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el.toLocaleUpperCase()}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isDisabled
            />
          ),
          valueGetter: (params: GridValueGetterParams) =>
            `${params.row.balance}(${params.row.currency.code})`,
        };
      }

      return {
        field: el,
        width: el === columnsName.ID ? 100 : Math.ceil(window.innerWidth / columnNames.length),
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => (
          <FilterField
            fieldName={el.toLocaleUpperCase()}
            onBlur={(value) => handleFilter(el, value)}
            onSort={handleSortBy}
            sortData={sortData}
          />
        ),
        valueGetter: (params: GridValueGetterParams): any =>
          el === 'site' ? `${params.row[el].name}` : `${params.row[el]}`,
      };
    });
    return [...columns];
  }, [columnNames, sortData]);

  const columns = generateDinamicColumns as GridColDef[];

  /** TODO remove the second function "handleModalClose" conserning logic  */
  const handleClose = () => {
    setOpenModal(false);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleCurrencyChange = (e: SelectChangeEvent) => {
    const { value } = e.target;
    setFilterData((filterby) => ({ ...filterby, currencyId: value }));
    setSelectedCurrency(value);
  };

  useEffect(() => {
    const query = makeQueryUrl(filterData);
    const sortBy = Object.entries(sortData)[0];

    let paramsQuery = isEmpty(sortData)
      ? query
      : `${query}&sortBy=${sortBy[0]}&sortDir=${sortBy[1]}`;

    paramsQuery += `&page=${page + 1}&pageSize=${rowsPerPage}`;

    getClientsData(paramsQuery);
  }, [filterData, sortData, page, rowsPerPage]);

  return (
    <div style={{ width: '100%' }}>
      {isLoading ? (
        <LoaderWithBg isOpen={isLoading} />
      ) : (
        <>
          <Box className={styles.filter_box}>
            <div className={styles.filter_btn}>
              <DropDownSelect
                totalPages={pageLists}
                onSelectPage={handleSelectPage}
                reset={resetGoToPage}
              />
              {Object.values(filterData).join() || Object.values(sortData).join() ? (
                <Button sx={{ mr: 3 }} variant="contained" onClick={handleResetAll}>
                  {t('ressetAll')}
                </Button>
              ) : null}
            </div>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ minWidth: 200, mr: 3 }}>
              <FormControl sx={{ width: 300, mr: 3 }} size="small">
                <InputLabel id="currency-label">{clientDetailsT('allStatuses')}</InputLabel>
                <Select
                  labelId="currency-label"
                  id="currency-select"
                  label={clientDetailsT('allStatuses')}
                  value={selectedCurrency}
                  onChange={handleCurrencyChange}
                >
                  <MenuItem value="">
                    <em>{clientsT('none')}</em>
                  </MenuItem>
                  {[
                    { id: '1', name: clientDetailsT('activated') },
                    { id: '2', name: t('new') },
                  ].map((item, index) => {
                    return (
                      <MenuItem key={index} value={item?.id}>
                        {item?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box>
              <Button sx={{ mr: 3 }} variant="contained" onClick={handleAddFreeSpins}>
                {clientDetailsT('addFreeSpins')}
              </Button>
            </Box>
          </Box>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            labelRowsPerPage={t('rowsPerPage')}
            labelDisplayedRows={({ from, to, count }) =>
              `${from >= to ? to : from}-${to} ${t('of')} ${count}`
            }
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <DataGrid
            autoHeight
            rows={data}
            columns={columns}
            hideFooterPagination={true}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  // Hide column currency, the other columns will remain visible
                  currency: false,
                },
              },
            }}
            sx={{
              '.MuiDataGrid-iconButtonContainer': {
                visibility: 'visible',
              },
              '.MuiDataGrid-sortIcon': {
                opacity: 'inherit !important',
              },
              '.MuiDataGrid-cell': {
                '&:last-child': {
                  justifyContent: 'center !important',
                },
              },
              '.MuiDataGrid-footerContainer': {
                display: 'none',
              },
            }}
          />
        </>
      )}

      {openModal && (
        <Modal open={openModal} onClose={handleClose} sx={{ minWidth: 400 }}>
          <Box
            sx={{
              ...style,
              bgcolor: `${theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff'}`,
            }}
          >
            <IconClose onClose={handleClose} />
            <CreateFreeSpinsOutcombet
              onClose={handleModalClose}
              title={clientDetailsT('addFreeSpinsOutcombet')}
            />
          </Box>
        </Modal>
      )}
    </div>
  );
}
