import { useState } from 'react';
import { generateHeaders } from 'utils/utils';
import { useStorage } from '../authHooks/useStorage';
import { useApiErrors } from 'hooks/errors/useApiErrors';
import BalanceService from 'services/balance.service';
import ClientService from 'services/client.service';

export const useClientProfit = (clientId: string) => {
  const { errorHandler } = useApiErrors();
  const { logout, userToken } = useStorage();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [profit, setProfit] = useState();
  const [clientProfit, setClientProfit] = useState();

  const initHeaders = generateHeaders(userToken());

  const getProfit: any = async (query: string, headers = initHeaders) => {
    setIsLoading(true);
    try {
      const {
        data: { data },
      } = await BalanceService.getProfit(headers, query);
      setProfit(data);
      setIsLoading(false);
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;

      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getProfit(query, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    }
  };

  const getClientProfit: any = async (id: string, query: string, headers = initHeaders) => {
    setIsLoading(true);
    try {
      const {
        data: { data },
      } = await ClientService.getClientProfit(headers, id, query);
      setIsLoading(false);
      setClientProfit(data);
    } catch (error: any) {
      console.log(error, 'errror');
      const {
        response: {
          data: { message, status },
        },
      } = error;
      if (status === 404) {
        setIsLoading(false);
      }
      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getClientProfit(id, query, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    }
  };

  return {
    profit,
    clientProfit,
    getClientProfit,
    isLoading,
    getProfit,
    error,
  };
};
