import {
  isNumber,
  minLength,
  isValidEmail,
  isValidPhonePattern,
  isValidUrl,
  isAlphanumeric,
  isAlphanumericWithSpace,
} from 'utils/validations/validationTypes';
import { FIELD_NAME } from 'types/signInForm';
import { FIELD_NAME as FIELD } from 'types/accounts';
import { FIELD_NAME as SITES_FIELD } from 'types/sites';
import { FIELD_NAME as ADD_BONUS_FIELD } from 'types/clients';
import { FIELD_NAME as SITE_SETTINGS_FIELD } from 'types/sitesSettings';

export const isValidValue = (value: string, name: string) => {
  if (value) {
    let isValid = true;
    switch (name) {
      case FIELD_NAME.LOGIN:
        isValid = minLength(7)(value);
        break;
      case FIELD_NAME.PASSWORD:
        isValid = minLength(7)(value);
        break;
      case FIELD_NAME.CODE:
        isValid = isNumber(value) && value.length === 6;
        break;
      default:
    }
    return isValid;
  }
};

export const isValidValueForEditAddAccount = (value: string, name: string) => {
  if (value) {
    let isValid = true;
    switch (name) {
      case FIELD.LOGIN:
        isValid = minLength(7)(value);
        break;
      case FIELD.PASSWORD:
        isValid = minLength(7)(value);
        break;
      case FIELD.EMAIL:
        isValid = isValidEmail(value);
        break;
      case FIELD.PHONE:
        isValid = isValidPhonePattern(value);
        break;
      default:
    }
    return isValid;
  }
};

export const isValidForPaymentProvidersSettings = (value: string, name: string) => {
  if (value) {
    let isValid = true;
    switch (name) {
      case SITE_SETTINGS_FIELD.PRIMARY_PAYIN_LIMIT:
        isValid = +value >= 0;
        break;
      default:
    }
    return isValid;
  }
};

export const isValidValueForEditAddSite = (value: string, name: string) => {
  if (value) {
    let isValid = true;
    switch (name) {
      case SITES_FIELD.NAME:
        isValid = isAlphanumeric(value) && minLength(3)(value);
        break;
      case SITES_FIELD.TITLE:
        isValid = isAlphanumeric(value) && minLength(3)(value);
        break;
      case SITES_FIELD.URL:
        isValid = isValidUrl(value);
        break;
      case SITES_FIELD.CASHBACK_PERCENT:
        isValid = +value >= 0;
        break;
      default:
    }
    return isValid;
  }
};

export const isValidValueForClientsLabels = (value: string, name: string) => {
  if (value) {
    let isValid = true;
    switch (name) {
      case SITES_FIELD.TITLE:
        isValid = isAlphanumericWithSpace(value) && minLength(3)(value);
        break;
      case SITES_FIELD.MIN_DEPOSIT:
        isValid = +value >= 0;
        break;
      case SITES_FIELD.CASHBACK_PERCENT:
        isValid = +value > 0;
        break;
      case SITES_FIELD.WITHDRAW_BORDER:
        isValid = +value >= 0;
        break;
      case SITES_FIELD.MAX_DEPOSIT:
        isValid = +value >= 0;
        break;
      default:
    }
    return isValid;
  }
};

export const isValidForSite = (value: string, name: string) => {
  if (value) {
    let isValid = true;
    switch (name) {
      case SITES_FIELD.TITLE:
        isValid = isAlphanumericWithSpace(value) && minLength(3)(value);
        break;
      case SITES_FIELD.BONUS_WEIDGER:
        isValid = +value > 1;
        break;
      case SITES_FIELD.MIN_AMOUNT:
        isValid = +value > 0 && isNumber(value);
        break;
      case SITES_FIELD.MAX_AMOUNT:
        isValid = +value > 0 && isNumber(value);
        break;
      case SITES_FIELD.DEFAULT:
        isValid = +value > 0;
        break;
      default:
    }
    return isValid;
  }
};

export const isValidForPromo = (value: string, name: string) => {
  if (value) {
    let isValid = true;
    switch (name) {
      case SITES_FIELD.CODE:
        isValid = value.length === 5;
        break;
      case SITES_FIELD.AMOUNT:
        isValid = +value > 0 && isNumber(value);
        break;
      case SITES_FIELD.COUNT:
        isValid = value !== '' ? +value > 0 && isNumber(value) : true;
        break;

      case SITES_FIELD.ADDITIONAL_BONUS:
        isValid = value !== '' ? +value > 0 && isNumber(value) : true;
        break;
      case SITES_FIELD.PROMO_WEIDGER:
        isValid = value !== '' ? +value > 1 && isNumber(value) : true;
        break;
      default:
    }
    return isValid;
  }
};

export const isValidValueForAddBonusForClients = (
  value: string,
  name: string,
  maxDepositSum?: string,
  minDepositSum?: string,
) => {
  if (value) {
    let isValid = true;

    switch (name) {
      case ADD_BONUS_FIELD.MIN_SUM:
        if (maxDepositSum) {
          isValid = +value >= 0 && +value < +maxDepositSum;
        } else {
          isValid = +value >= 0;
        }
        break;
      case ADD_BONUS_FIELD.MAX_SUM:
        if (minDepositSum) {
          isValid = +value >= 0 && +value > +minDepositSum;
        } else {
          isValid = +value >= 0;
        }
        break;
      case ADD_BONUS_FIELD.MAX_PROFIT:
        isValid = +value >= 0;
        break;
      case ADD_BONUS_FIELD.PERCENT_VAL:
        isValid = +value >= 0;
        break;
      default:
        break;
    }
    return isValid;
  } else {
    return true;
  }
};
