import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { isEmpty } from 'utils/utils';
import SitesList from 'components/filter/siteLists';
import TransactionList from 'components/filter/trasactionLists';
import { IFilterData } from 'types/accounts';
import DynamicTable from 'components/tables/dynamicTable';
import { useFinance } from 'hooks/finances/useFinance';

const init = { name: '', id: '' };

export interface IItem {
  name: string;
  id: string;
  [x: string]: string;
}

function FinancialLogs() {
  const [filterData, setFilterData] = useState<IFilterData | object>({});
  const [value, setValue] = useState('1');
  const [tabLists, setTabLists] = useState([] as IItem[]);
  const [activeItem, setActiveItem] = useState(init);
  const { getAllPaymentProviders } = useFinance();

  const getData = async () => {
    const result = await getAllPaymentProviders();
    if (result?.status) {
      setTabLists(result?.data ?? []);
      setActiveItem(result?.data?.[0]);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    const activeTab = tabLists?.filter((el: IItem) => +el.id === +newValue);
    if (activeTab.length) {
      setActiveItem(activeTab?.[0]);
    }
  };

  const handleFilter = (name: string, value: string) => {
    setFilterData((filterby) => ({ ...filterby, [name]: value }));
  };

  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} variant="scrollable">
                {tabLists.map((tab: IItem) => {
                  return <Tab key={tab?.id} label={tab?.name} value={tab?.id + ''} />;
                })}
              </TabList>
            </Box>
            <Box sx={{ mt: 3, display: 'flex' }}>
              <Box sx={{ minWidth: 100, mr: 3 }}>
                <TransactionList onSelectSite={handleFilter} initValue={isEmpty(filterData)} />
              </Box>
              <Box sx={{ mr: 3 }}>
                <SitesList onSelectSite={handleFilter} initValue={isEmpty(filterData)} />
              </Box>
            </Box>
          </Box>
          <TabPanel value={activeItem.id !== '' ? activeItem.id + '' : '3'}>
            <DynamicTable item={activeItem} outSideFilterData={filterData} />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}

export default FinancialLogs;
