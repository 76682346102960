import { TabContext, TabList, TabPanel } from '@mui/lab';
import { AlertColor, Tab } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IEditSite } from 'types/sites';
import AboutUsForLanguage from './aboutUsForLanguage/aboutUsForLanguage';
import { useAboutUs } from 'hooks/aboutUs/useAboutUs';
import { ERROR, SUCCESS } from 'constants/common';

interface AboutUsProps {
  siteId: string;
}

function AboutUs({ siteId }: AboutUsProps) {
  const { t } = useTranslation('common');
  const { languages } = useSelector<any>((state) => state.currentSite) as IEditSite;
  const { aboutUsForSite, updateSiteAboutUs, resetError, error } = useAboutUs(siteId);
  const [value, setValue] = useState(languages ? languages[0].code : '');
  const [success, setSuccess] = useState('');
  const [type, setType] = useState<AlertColor>(SUCCESS);
  const [aboutUsList, setAboutUsList] = useState(aboutUsForSite ? aboutUsForSite : []);
  const [selectedItem, setSelectedItem] = useState(aboutUsList[0]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const saveAboutUs = async () => {
    const body: { id: number; about: any }[] = [];
    aboutUsList.map((elm: any) => {
      if (elm.about) {
        body.push({ id: elm.id, about: elm.about });
      }
    });

    setSuccess('');
    setType(SUCCESS);
    resetError();

    const res = await updateSiteAboutUs(body, siteId);
    const { status } = res ?? {};

    if (status === 200) {
      setSuccess(t('savedSuccess'));
      setType(SUCCESS);
    } else {
      setType(ERROR);
    }
  };

  const updateAboutUs = (languageCode: string, aboutUs: any) => {
    setAboutUsList((aboutUsList: any) =>
      aboutUsList.map((elm: any) => (elm.code === languageCode ? { ...elm, about: aboutUs } : elm)),
    );
  };

  const handleOnClose = () => {
    setSuccess('');
    setType(SUCCESS);
    resetError();
  };

  useEffect(() => {
    if (aboutUsForSite) {
      setAboutUsList(aboutUsForSite);
      const myArray: any = aboutUsForSite;
      const filterData = myArray.filter((elm: any) => elm.code === value);
      setSelectedItem(filterData[0]);
    }
  }, [aboutUsForSite]);

  useEffect(() => {
    const filterData = aboutUsList.filter((elm: any) => elm.code === value);
    setSelectedItem(filterData[0]);
    handleOnClose();
  }, [value]);

  return (
    <>
      <TabContext value={value}>
        <TabList onChange={handleChange}>
          {languages?.map((elm, i) => {
            return <Tab key={i} label={elm.name} value={elm.code} />;
          })}
        </TabList>
        <TabPanel value={value}>
          <AboutUsForLanguage
            languageCode={value}
            saveAboutUs={saveAboutUs}
            updateAboutUs={updateAboutUs}
            selectedItem={selectedItem}
            handleOnClose={handleOnClose}
            result={success || error}
            type={type}
          />
        </TabPanel>
      </TabContext>
    </>
  );
}

export default AboutUs;
