import { actionTypes } from 'redux/constants/actionTypes';

const initial = {};

export const userReducer = (state = initial, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_CURRENT_USER:
      return payload;
      
    default:
      return state;
  }
};
