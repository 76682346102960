import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { useSites } from 'hooks/sites/useSites';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ISites } from 'types/sites';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './checkbox.module.scss';

interface CheckboxesProps {
  siteIds?: number[];
  onChange?: (id: number) => void;
  disabled?: boolean;
}
export const SiteCheckboxes = ({ siteIds, disabled, onChange }: CheckboxesProps) => {
  const { getAllSites } = useSites();
  const { t } = useTranslation('common');
  const [userSiteIds, setUserSiteIds] = useState(siteIds ? [...siteIds] : []);

  const sitesData = useSelector<any>((state) => state.allSites) as {
    sites: ISites[];
  };
  const { sites: siteList } = sitesData ?? [];

  const [filteredSiteList, setFilteredSiteList] = useState(siteList);

  const handleOnChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = +event.currentTarget.id;
    onChange?.(id);
  };

  useEffect(() => {
    if (!siteList.length) getAllSites();
    if (siteIds) setUserSiteIds([...siteIds]);

    if (disabled) {
      const a = siteList.filter((elm, i) => {
        return siteIds?.includes(elm.id);
      });
      setFilteredSiteList(a);
    }
  }, [siteIds]);

  if (!siteList?.length && siteIds) {
    return (
      <Box className={cx(styles.sitesCheckboxes, { [styles.disabled_checkboxes]: disabled })}>
        <Typography> {t('loading')}</Typography>
      </Box>
    );
  }

  return (
    <FormGroup
      sx={{ ml: 1 }}
      className={cx(styles.sitesCheckboxes, { [styles.disabled_checkboxes]: disabled })}
    >
      {filteredSiteList?.map(({ name, id }, index) => {
        return (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                disabled={disabled}
                checked={userSiteIds?.includes(+id)}
                id={id + ''}
                onChange={handleOnChecked}
                sx={{ '& .MuiSvgIcon-root': { fontSize: 16 }, m: 0, p: 0.5 }}
                inputProps={{ readOnly: true }}
              />
            }
            label={name}
          />
        );
      })}
    </FormGroup>
  );
};
