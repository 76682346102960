import { GridValueGetterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import FilterField from 'components/filter/columnFilter';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { IFilterData } from 'types/accounts';
import { columnsName, ROW_PER_PAGE } from 'constants/common';
import { dateFormat, isEmpty, makeQueryUrl } from 'utils/utils';
import light from 'styles/light.module.scss';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TablePagination,
} from '@mui/material';
import DropDownSelect from 'components/select/select';
import LoaderWithBg from 'components/loader/loader';
import { useDispatch } from 'react-redux';
import { setResetAll } from 'redux/actions/commonActions';
// import { useClientBalances } from 'hooks/clients/useClientBalances';
import DateTimePickerValue from 'components/datePicker/dateTimeRangePicker';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { depositTypeObj } from '../finance/depositsTable';
import { useFinance } from 'hooks/finances/useFinance';
import styles from '../table.module.scss';

interface DepositsTableProps {
  clientId: string;
}

export default function DepositsTable({ clientId }: DepositsTableProps) {
  const { t: clientDetailsT } = useTranslation('clientsDetails');
  const { t: clientsT } = useTranslation('clients');
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const {
    isLoading,
    balanceSum,
    columns: columnNames,
    pageLists,
    totalItems,
    depositList: balanceList,
    getAllDepositChanges,
  } = useFinance();
  const [timeData, setTimeData] = useState<any>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROW_PER_PAGE);
  const [isValidDateValue, setIsValidDateValue] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [resetGoToPage, setResetGoToPage] = useState(false);
  const [filterData, setFilterData] = useState<IFilterData>({} as IFilterData);
  const [sortData, setSortData] = useState<IFilterData | object>({});

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (newPage >= +pageLists[pageLists.length - 1].val) {
      setPage(+pageLists[pageLists.length - 1].val);
    } else {
      setPage(newPage);
    }
    setResetGoToPage((prev) => !prev);
  };

  const handleFilter = (name: string, value: string) => {
    setFilterData((filterby) => ({ ...filterby, [name]: value }));
  };

  const handleSortBy = (sortBy: string, sortDir: string) => {
    setSortData({ [sortBy]: sortDir });
  };

  const handleResetAll = () => {
    dispatch(setResetAll(true));
    setFilterData({});
    setSortData({});
    setSelectedStatus('');
  };

  const handleSelectPage = (page: string) => {
    setPage(+page - 1);
  };

  const generateDinamicColumns = useMemo(() => {
    const columns = columnNames.map((el) => {
      if (el === columnsName.CREATED_AT) {
        return {
          field: el,
          minWidth: el.length * 6 + 110,
          disableColumnMenu: true,
          sortable: false,
          renderHeader: () => (
            <FilterField
              fieldName={el}
              onBlur={(value) => handleFilter(el, value)}
              onSort={handleSortBy}
              sortData={sortData}
              isCamelCase
            />
          ),
          valueGetter: (params: GridValueGetterParams) => dateFormat(params.row.createdAt),
        };
      }
      return {
        field: el,
        width: el === columnsName.ID ? 100 : el.length * 6 + 130,
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => (
          <FilterField
            fieldName={el}
            onBlur={(value) => handleFilter(el, value)}
            onSort={handleSortBy}
            sortData={sortData}
            isCamelCase
          />
        ),
      };
    });
    return [...columns];
  }, [columnNames, sortData]);

  const columns = generateDinamicColumns as GridColDef[];

  const handleStatusChange = (e: SelectChangeEvent) => {
    const { value } = e.target;
    const depositType = depositTypeObj[value] ?? '';
    setFilterData((filterby) => ({ ...filterby, depositType }));
    setSelectedStatus(value);
  };

  const handleOnShow = (valueFrom: Dayjs | null | string, valueTo: Dayjs | null | string) => {
    if (valueFrom && valueTo) {
      const data = { from: valueFrom, to: valueTo };
      setTimeData(data);
    }
  };

  const isValidDateChanges = (value: boolean) => {
    setIsValidDateValue(value);
  };

  const getData = async (clientId: string, paramsQuery: string) => {
    await getAllDepositChanges(clientId, paramsQuery);
  };

  useEffect(() => {
    if (timeData) {
      const query = makeQueryUrl(filterData);
      const sortBy = Object.entries(sortData)[0];
      let timeDataQuery = '';
      if (isValidDateValue) {
        timeDataQuery =
          timeData?.from && timeData?.to ? '&' + new URLSearchParams(timeData).toString() : '';
      }

      let paramsQuery = isEmpty(sortData)
        ? query
        : `${query}&sortBy=${sortBy[0]}&sortDir=${sortBy[1]}`;

      paramsQuery += `&page=${page + 1}&userId=${clientId}&pageSize=${rowsPerPage}`;
      paramsQuery += timeDataQuery ? `${timeDataQuery}` : '';

      getData(filterData?.id ?? '1', paramsQuery);
    }
  }, [filterData, sortData, page, rowsPerPage, isValidDateValue, timeData]);

  return (
    <div style={{ width: '100%' }}>
      <Box className={styles.filter_box}>
        <div className={styles.filter_btn}>
          <Box sx={{ display: 'flex', mt: 2, mb: 2 }}>
            <DropDownSelect
              totalPages={pageLists}
              onSelectPage={handleSelectPage}
              reset={resetGoToPage}
            />
            {(Object.values(filterData).join() || Object.values(sortData).join()) &&
            (balanceList.length || columns.length) ? (
              <Button className={styles.reset_all_btn} variant="contained" onClick={handleResetAll}>
                {t('ressetAll')}
              </Button>
            ) : null}
          </Box>
        </div>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ minWidth: 200, mr: 3 }}>
          <DateTimePickerValue
            isValidChanges={isValidDateChanges}
            isSmall="small"
            onShow={handleOnShow}
            dateFrom={dayjs().subtract(1, 'year')}
            needDefaultDate
          />
        </Box>
        <Box sx={{ minWidth: 150, mr: 3 }}>
          <FormControl size="small" sx={{ width: '100%', mr: 3, height: '81px' }}>
            <InputLabel id="currency-label">{clientDetailsT('depositTypes')}</InputLabel>
            <Select
              labelId="currency-label"
              id="currency-select"
              label={clientDetailsT('depositTypes')}
              value={selectedStatus}
              onChange={handleStatusChange}
            >
              <MenuItem value="">
                <em>{clientsT('none')}</em>
              </MenuItem>
              {[
                { id: 1, name: 'real' },
                { id: 2, name: 'bonus' },
              ].map((item, index) => {
                return (
                  <MenuItem key={index} value={item.id}>
                    {clientDetailsT(item.name)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box className={styles.result} sx={{ backgroundColor: light.main }}>
        {balanceSum ?? 0}
      </Box>
      {isLoading ? (
        <LoaderWithBg isOpen={isLoading} />
      ) : (
        <>
          {balanceList.length || columns.length ? (
            <>
              <TablePagination
                component="div"
                count={totalItems}
                page={page}
                labelRowsPerPage={t('rowsPerPage')}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from >= to ? to : from}-${to} ${t('of')} ${count}`
                }
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              <DataGrid
                getRowHeight={() => 'auto'}
                rows={balanceList}
                columns={columns}
                hideFooterPagination={true}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      // Hide column currency, the other columns will remain visible
                      userId: false,
                      user: false,
                      newBalance: false,
                      oldBalance: false,
                    },
                  },
                }}
                sx={{
                  '.MuiDataGrid-iconButtonContainer': {
                    visibility: 'visible',
                  },
                  '.MuiDataGrid-sortIcon': {
                    opacity: 'inherit !important',
                  },
                  '.MuiDataGrid-cell': {
                    '&:last-child': {
                      justifyContent: 'center !important',
                    },
                  },
                  '.MuiDataGrid-footerContainer': {
                    display: 'none',
                  },
                  '.MuiDataGrid-row': {
                    minHeight: '55px!important',
                  },
                }}
              />
            </>
          ) : null}
        </>
      )}
    </div>
  );
}
