import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import style from './closeButton.module.scss';

interface CloseIconProps {
  onClose: () => void;
}

export function IconClose({ onClose }: CloseIconProps) {
  return (
    <IconButton className={style.icon} onClick={onClose}>
      <CloseIcon />
    </IconButton>
  );
}
