import { Box, Button, Modal, TextField, Typography, useTheme } from '@mui/material';
import { GridDeleteForeverIcon } from '@mui/x-data-grid';
import { ChangeEvent, useState } from 'react';
import common from 'styles/common.module.scss';
import { style } from 'components/modal/modal';
import { IconClose } from 'components/closeButton/closeButton';
import { useClientSocial } from 'hooks/clients/useClientSocial';
import { useTranslation } from 'react-i18next';
import styles from './clientSocialLinks.module.scss';

interface ClientSocialLinksProps {
  linkInfo: any;
  clientId: string;
  removeLink: (linkId: string) => void;
}

export default function ClientSocialLinks({
  linkInfo,
  clientId,
  removeLink,
}: ClientSocialLinksProps) {
  const { t: clientDetailsT } = useTranslation('clientsDetails');
  const theme = useTheme();

  const [openModal, setOpenModal] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const { addNewSocialLink } = useClientSocial();

  const handleOnInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;
    setInputValue(value);
  };

  const addSocialLink = async () => {
    const body = {
      link: inputValue,
    };

    const { status } = await addNewSocialLink(clientId, linkInfo.type, body);
    if (status === 201) {
      setOpenModal(false);
      setInputValue('');
    }
  };

  function renderAddSocialLinkModal() {
    return (
      <>
        <IconClose onClose={handleClose} />
        <Typography variant="h6">
          {clientDetailsT('addLinkFor')} {linkInfo.type}
        </Typography>
        <TextField
          margin="normal"
          fullWidth
          size="small"
          value={inputValue}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => handleOnInputChange(e)}
        />
        <Box sx={{ mt: 2 }}>
          <Button variant="contained" disabled={inputValue == ''} onClick={addSocialLink}>
            {clientDetailsT('add')}
          </Button>
        </Box>
      </>
    );
  }

  const openAddModal = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <Box className={styles.general_area}>
      <Box className={styles.links_and_button}>
        <Box className={styles.links_area}>
          {linkInfo.links.map((elm: any, i: any) => {
            return (
              <Box className={styles.link_info} key={i}>
                <Typography component={'span'}>{elm.link}</Typography>
                {i !== 0 ? (
                  <GridDeleteForeverIcon
                    sx={{
                      color: common.mainwarning,
                      alignItems: 'center',
                      fontSize: 30,
                      cursor: 'pointer',
                    }}
                    onClick={(e) => removeLink(elm.id)}
                  />
                ) : null}
              </Box>
            );
          })}
        </Box>
        <Button variant="contained" sx={{}} onClick={openAddModal}>
          {clientDetailsT('addLink')}
        </Button>
      </Box>
      {openModal && (
        <Modal open={openModal} onClose={handleClose} sx={{ minWidth: 400 }}>
          <Box
            sx={{
              ...style,
              bgcolor: `${
                theme.palette.mode === 'dark' ? theme.palette.background.paper : common.mainwhite
              }`,
            }}
          >
            {renderAddSocialLinkModal()}
          </Box>
        </Modal>
      )}
    </Box>
  );
}
