import { useStorage } from 'hooks/authHooks/useStorage';
import { useApiErrors } from 'hooks/errors/useApiErrors';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  createdSiteBonus,
  deleteSiteBonus,
  setBonusesData,
  updateBonusData,
} from 'redux/actions/bonusesActions';
import SiteService from 'services/site.service';
import { IBonusCreating } from 'types/sitesSettings';
import { prepareTableData, preparedColumnNames } from 'utils/helpers/dataHelper/prepareDataHelper';
import { generateHeaders } from 'utils/utils';

export const useSiteBonuses = () => {
  const { errorHandler } = useApiErrors();
  const { logout, userToken } = useStorage();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [columns, setColumns] = useState<string[]>([]);
  const dispatch = useDispatch();

  const initHeaders = generateHeaders(userToken());

  const getAllBonuses: any = async (siteId: string, query: string, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data },
        status,
      } = await SiteService.getSiteBonuses(headers, siteId, query);
      const preparedData = prepareTableData(data, 'siteBonuses');
      const columns = preparedColumnNames(preparedData);
      setColumns(columns);
      if (status === 200) {
        dispatch(setBonusesData(data));
      }
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;
      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getAllBonuses(siteId, query, newHeader);
      } else {
        console.log('LOG____>getAllBonuses', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getSiteBonusById: any = async (siteId: string, bonusId: string, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data },
        status,
      } = await SiteService.getSiteBonusById(headers, siteId, bonusId);

      if (status === 200) {
        return { data, status };
      }
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;
      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getSiteBonusById(siteId, bonusId, newHeader);
      } else {
        console.log('LOG____>getSiteBonusById', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const createBonus: any = async (siteId: string, body: IBonusCreating, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await SiteService.createSiteBonus(headers, siteId, body);
      console.log(status, data);

      if (status === 201) {
        dispatch(createdSiteBonus(data));
        return { data, status };
      }
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;
      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await createBonus(siteId, body, newHeader);
      } else {
        console.log('LOG____>createBonus', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const editeBonus: any = async (
    siteId: string,
    bonusId: string,
    body: IBonusCreating,
    headers = initHeaders,
  ) => {
    try {
      setIsLoading(true);
      const {
        data: { data },
        status,
      } = await SiteService.editSiteBonus(headers, siteId, bonusId, body);
      if (status === 200) {
        dispatch(updateBonusData(data));
        return { data, status };
      }
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;
      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await editeBonus(siteId, bonusId, body, newHeader);
      } else {
        console.log('LOG____>editeBonus', message);
        setError(message);
      }
    }
  };

  const removeBonus: any = async (siteId: string, bonusId: string, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data },
        status,
      } = await SiteService.deleteBonus(headers, siteId, bonusId);
      if (status === 200) {
        dispatch(deleteSiteBonus(data));
      }
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;
      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await removeBonus(siteId, bonusId, newHeader);
      } else {
        console.log('LOG____>removeBonus', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const setImageOrBannerImageForBonus: any = async (
    siteId: string,
    bonusId: string,
    imageType: string,
    body: any,
    headers = { ...generateHeaders(userToken()), 'Content-Type': 'multipart/form-data' },
  ) => {
    try {
      setIsLoading(true);
      const {
        data: { data },
        status,
      } = await SiteService.uploadImageOrBannerImageForBonus(
        headers,
        siteId,
        bonusId,
        imageType,
        body,
      );
      return { data, status };
    } catch (error: any) {
      const {
        response: {
          data: { message, status },
        },
      } = error;
      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await setImageOrBannerImageForBonus(siteId, bonusId, imageType, body, {
          ...newHeader,
          'Content-Type': 'multipart/form-data',
        });
      } else {
        console.log('LOG____>setImageOrBannerImageForBonus', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    getAllBonuses,
    getSiteBonusById,
    columns,
    editeBonus,
    removeBonus,
    setImageOrBannerImageForBonus,
    createBonus,
    error,
    isLoading,
  };
};
