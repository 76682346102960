import { actionTypes } from 'redux/constants/actionTypes';

export const setLevelsData = (levels) => {
  return {
    type: actionTypes.SET_LEVELS_LIST,
    payload: levels,
  };
};

export const updateLevelData = (level) => {
  return {
    type: actionTypes.UPDATE_LEVEL_DATA,
    payload: level,
  };
};

export const addCreatedLevel = (level) => {
  return {
    type: actionTypes.ADD_CREATED_LEVEL,
    payload: level,
  };
};


export const setLevelsDataWithoutPagination = (levels) => {
  return {
    type: actionTypes.SET_LEVELS_DATA_WITHOUT_PAGINATION,
    payload: levels
  }
}