import { useStorage } from 'hooks/authHooks/useStorage';
import { useApiErrors } from 'hooks/errors/useApiErrors';
import { useState } from 'react';
import GameSettingsDataService from 'services/gameImageSettings.service';
import { generateHeaders } from 'utils/utils';

export const useGame = () => {
  const [error, setError] = useState(null);
  const { errorHandler } = useApiErrors();
  const [gameList, setGameList] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageCount, setPageCount] = useState(0);

  const { logout, userToken } = useStorage();
  const initHeaders = generateHeaders(userToken());

  const getAllGameProvider: any = async (
    providerId: string,
    query: string,
    headers = initHeaders,
  ) => {
    setIsLoading(true);

    try {
      setIsLoading(true);
      const {
        data: { data, status, totalPage },
      } = await GameSettingsDataService.getAllProviderGame(headers, providerId, query);
      setGameList(data.games);
      setPageCount(data.totalPages);
      return { data, status, totalPage };
    } catch (err: any) {
      const {
        response: {
          data: { message, status },
        },
      } = err;
      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await getAllGameProvider(providerId, query, newHeader);
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const uploadGameImage: any = async (
    gameId: number,
    providerId: string,
    body: any,
    headers = { ...initHeaders, 'Content-Type': 'multipart/form-data' },
  ) => {
    setIsLoading(true);

    try {
      const {
        data: { data, status },
      } = await GameSettingsDataService.uploadGameImage(headers, gameId, providerId, body);
      if (status === 200) {
        return { data, status };
      }
    } catch (err: any) {
      const {
        response: {
          data: { message, status },
        },
      } = err;
      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        logout();
      } else if (!needLogout && newHeader) {
        return await uploadGameImage(gameId, providerId, body, {
          ...newHeader,
          'Content-Type': 'multipart/form-data',
        });
      } else {
        console.log('LOG____>', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    getAllGameProvider,
    uploadGameImage,
    gameList,
    pageCount,
    isLoading,
  };
};
